import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import ConfirmDialog from 'components/Shared/ConfirmDialog';
import EditTimeDialogFormView from './EditTimeDialogFormView';

import { Formik } from 'formik';
import { DATE_FORMAT, NEW_EVENT_INITIAL_HOURS, SICK_LEAVE_ID } from '../TimeTrackingConstants';
import { EMPLOYEE_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { validateDecimalInputPeriod, validateRequiredFields } from 'utils/formValidations';

import 'react-day-picker/lib/style.css';
import './EditTimeDialog.scss';

const EditTimeDialog = ({
    openDialog,
    selectedEvents,
    handleCloseDialog,
    handleTrackTime,
    projects,
    handleOpenUploadFileDialog,
    uploadFilesFormRef,
    trackedDays,
    showNotification,
    isAdmin,
}) => {
    const intl = useIntl();

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [eventHandlerArgs, setEventHandlerArgs] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);

    const selectedEvent = selectedEvents.find(x => x.selected);
    const selectedProject = projects.find(x => x._id === selectedEvent?.project?._id);
    const currentDate = moment(selectedEvent?.start, DATE_FORMAT);
    const currentDayTrackedProjects = trackedDays.filter(td => moment(td.start).format(DATE_FORMAT) === moment(currentDate).format(DATE_FORMAT));
    const hasTrackedSickLeaveForCurrentDay = currentDayTrackedProjects.find(x => x.project._id === SICK_LEAVE_ID);
    const currentFileName = uploadFilesFormRef.current?.values?.filesAttached[0]
        ? uploadFilesFormRef.current.values.filesAttached[0].name
        : selectedEvent?.fileInfo?.fileName
            ? selectedEvent.fileInfo?.fileName
            : '.pdf .jpeg .jpg';

    const editTimeDialogRef = useRef();

    const handleOpenConfirmDialog = (action, ...args) => {
        setSelectedAction(action);
        setEventHandlerArgs(...args);
        setIsConfirmDialogOpen(true);
    };

    const handleCloseConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
    };

    const handleSubmitConfirmDialog = () => {
        const isFromConfirmationDialog = true;

        if (selectedAction === 'remove') {
            handleRemoveEvent(...eventHandlerArgs, isFromConfirmationDialog);
        } else if (selectedAction === 'edit') {
            handleEventChange(...eventHandlerArgs, isFromConfirmationDialog);
        }
        handleCloseConfirmDialog();
    };

    const handleAddEvent = (events) => {
        const additionalProject = projects.find(project => !(events.some(event => event.project._id === project._id)));

        if (additionalProject) {
            const newEvents = events.map(event => ({ ...event, selected: false }));

            const newEvent = {
                start: currentDate,
                end: currentDate,
                project: additionalProject,
                hours: NEW_EVENT_INITIAL_HOURS,
                tasksDesc: '',
                selected: true,
            }

            editTimeDialogRef.current.setValues({
                ...editTimeDialogRef.current.values,
                tasksDesc: '',
                hours: NEW_EVENT_INITIAL_HOURS,
                project: additionalProject,
                events: [...newEvents, newEvent]
            });
        }
    };

    const handleSelectProject = (currEvent) => {
        const newEvents = editTimeDialogRef.current.values.events.map(event => ({ ...event, selected: event.project._id === currEvent.project._id }))
        const values = { events: newEvents, project: projects.find(project => project._id === currEvent.project._id), hours: currEvent.hours || '', tasksDesc: currEvent.tasksDesc || '' };
        editTimeDialogRef.current.resetForm({ values });
    };

    const handleRemoveEvent = (event, remove, index, isFromConfirmationDialog) => {
        if (hasTrackedSickLeaveForCurrentDay && event.project._id === SICK_LEAVE_ID && !isFromConfirmationDialog) {
            if (!isAdmin) {
                showNotification(EMPLOYEE_MESSAGES.DELETE_SICK_LEAVE_UNSUCCESSFUL, NOTIFICATION_TYPES.ERROR);
                return;
            }

            handleOpenConfirmDialog('remove', [event, remove, index]);
            return;
        }

        const newEvents = editTimeDialogRef.current.values.events;

        //If we want to remove current event
        if (event.selected) {
            newEvents[index].selected = false;

            //Check which one is free to be the new selected event and set it to
            if (newEvents[index + 1]) {
                newEvents[index + 1].selected = true;
            } else if (newEvents[index - 1]) {
                newEvents[index - 1].selected = true;
            }

            //Same way change current event fields
            const newCurrentEvent = {
                hours: newEvents[index + 1]?.hours || newEvents[index - 1]?.hours || '',
                tasksDesc: newEvents[index + 1]?.tasksDesc || newEvents[index - 1]?.tasksDesc || '',
                project:
                    projects.find(project => project._id === newEvents[index + 1]?.project._id) ||
                    projects.find(project => project._id === newEvents[index - 1]?.project._id) || '',
            };

            //reset form with new data and remove event from events array
            editTimeDialogRef.current.resetForm({ values: { ...editTimeDialogRef.current.values, ...newCurrentEvent, events: newEvents } });
            remove(index);
        } else {
            remove(index);
        }
    };

    const handleEventChange = (e, isFromConfirmationDialog) => {
        const propName = e.target.name;
        const propValue = e.target.value;

        const currentSelectedEvent = editTimeDialogRef.current.values.events.find(event => event.selected);
        if (currentSelectedEvent?.project._id === SICK_LEAVE_ID && hasTrackedSickLeaveForCurrentDay && isAdmin && !isFromConfirmationDialog) {
            handleOpenConfirmDialog('edit', [e]);
            return;
        }

        const newEvents = editTimeDialogRef.current.values.events.map(event => {
            if (event.selected) {
                return { ...event, [propName]: propValue };
            }
            return event;
        });

        if (propName === 'project') {
            editTimeDialogRef.current.setFieldValue('hours', 8);
            editTimeDialogRef.current.handleChange(e);
        }
        editTimeDialogRef.current.setFieldValue('events', newEvents);
    };

    //Validation on diferent projects
    const handleValidation = (values) => {
        if (values.events?.length < 1) {
            return {}
        } else if (values.project?.isSickLeave) {
            return {
                ...validateRequiredFields(values, ['project']),
            }
        } else {
            return {
                ...validateDecimalInputPeriod(values, [{ property: 'hours', maxLength: 24, minLength: 0 }]),
                ...validateRequiredFields(values, ['hours', 'project']),
            }
        }
    };

    //filter projects
    const filterSelectedProjects = (projects, events) => {
        return projects.filter(project => !events.some(event => project._id === event.project._id && !event.selected))
    };

    return (
        <>
            <Formik
                initialValues={{
                    events: selectedEvents,
                    project: selectedProject,
                    hours: selectedEvent?.hours,
                    tasksDesc: selectedEvent?.tasksDesc,
                }}
                innerRef={editTimeDialogRef}
                validate={(values) => handleValidation(values)}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    handleTrackTime(values);
                }}
            >
                {(props) =>
                    <CommonFormikDialog
                        className="track-time-dialog"
                        open={openDialog}
                        onClose={handleCloseDialog}
                        size={'md'}
                        maxWidth={'md'}
                        dialogTitle={moment(selectedEvents[0]?.start).format("DD MMM YYYY")}
                        onSave={props.handleSubmit}
                    >
                        <div className="dialog-content">
                            <EditTimeDialogFormView
                                {...props}
                                selectedEvents={selectedEvents}
                                projects={filterSelectedProjects(projects, props.values.events)}
                                handleOpenUploadFileDialog={handleOpenUploadFileDialog}
                                currentFileName={currentFileName}
                                isAdmin={isAdmin}
                                hasTrackedSickLeaveForCurrentDay={hasTrackedSickLeaveForCurrentDay}
                                handleEventChange={handleEventChange}
                                handleRemoveEvent={handleRemoveEvent}
                                handleSelectProject={handleSelectProject}
                                handleAddEvent={handleAddEvent}
                            />
                        </div>
                    </CommonFormikDialog >
                }
            </Formik>
            {(isAdmin && isConfirmDialogOpen) &&
                <ConfirmDialog
                    openDialog={isConfirmDialogOpen}
                    dialogTitle={intl.formatMessage({ id: "confirm" })}
                    dialogSubtitle={intl.formatMessage({ id: "delete-sick-leave-dialog-subtitle" })}
                    discardButtonName={intl.formatMessage({ id: "no" })}
                    confirmButtonName={intl.formatMessage({ id: "yes" })}
                    handleCloseDialog={handleCloseConfirmDialog}
                    handleConfirmDialog={handleSubmitConfirmDialog}
                />
            }
        </>
    );
}

export default EditTimeDialog;
