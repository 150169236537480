import React, { useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { CONTRACTS_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { ROLES } from 'constants/userConstants';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import { showNotification } from 'actions/notificationActions';
import { generateRawCv } from 'actions/rawCvActions';
import Paper from '@material-ui/core/Paper';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import RawCvFirstImage from "assets/raw-cv-1.svg";
import RawCvSecondImage from 'assets/raw-cv-2.svg';
import './ProfileUploadRawCvPdf.scss';
import { getDisplayMode } from 'reducers';
import { useDropzone } from 'react-dropzone';
import { logEvent } from 'utils/amplitude';

import Base64 from 'utils/base64';

const ACCEPTED_FORMAT = ['ppt', 'pptx', 'xlsx', 'csv', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'pdf'];

const ProfileUploadRawCvPdf = ({
    generateRawCv,
    agencyId,
    employeeId,
    showNotification,
    role
}) => {
    const inputFilesRef = useRef();

    const handleGenerateCV = files => {

        if (files.length <= 1) {
            files.forEach(file => {
                if (file.size === 0) {
                    showNotification(CONTRACTS_MESSAGES.ADD_EMPTY_FILE_FAIL, NOTIFICATION_TYPES.ERROR);
                } else {
                    const fileExt = file.name.split('.').pop();

                    if (!ACCEPTED_FORMAT.some(x => x === fileExt)) {
                        showNotification(CONTRACTS_MESSAGES.ADD_FILE_FORMAT_FAIL, NOTIFICATION_TYPES.ERROR);
                    } else {
                        const reader = new FileReader();

                        reader.onload = async function (event) {
                            const modifiedDate = (new Date(file.lastModified)).toISOString().substring(0, 10);
                            const base64Text = Base64.encodeArray(event.target.result);

                            const data = new FormData();

                            data.append(`attachedfile1`, file);
                            data.append(`documentAsBase64String`, base64Text);
                            data.append(`documentLastModified`, modifiedDate);

                            await generateRawCv(agencyId, employeeId, data, role);
                        }

                        reader.readAsArrayBuffer(file);
                    }
                }
            })
        } else {
            showNotification(CONTRACTS_MESSAGES.MAX_FILES_COUNT_REACHED, NOTIFICATION_TYPES.ERROR);
        }
        inputFilesRef.current.value = '';
    };

    const onDrop = useCallback((acceptedFiles) => handleGenerateCV(acceptedFiles));

    const { getRootProps } = useDropzone({ onDrop });

    return (
        <div className="raw-cv-profile-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Upload a CV file'
                />
            </SectionActionsWrapper>

            <Paper className="raw-cv-profile-content">
                <div className="raw-cv-steps-wrapper">
                    <div className="row">
                        <div className="cols">
                            <div className="col-1of3">
                                <div className="step-number">
                                    1
                                </div>
                            </div>
                            <div className="col-1of3">
                                <div className="step-number">
                                    2
                                </div>
                            </div>
                            <div className="col-1of3">
                                <div className="step-number">
                                    3
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cols">
                            <div className="col-1of3">
                                <div className="text-info">
                                    <ul>
                                        <li>Upload your raw CV file using the button below and we will save it in the system.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-1of3">
                                <div className="text-info">
                                    <ul>
                                        <li>We use an AI to extract all relevant information from the file you've uploaded.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-1of3">
                                <div className="text-info">
                                    <ul>
                                        <li>Review extracted profile information. Edit where necessary and add missing information.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cols">
                            <div className="col-1of3">
                                <div className="upload-file" {...getRootProps()}>
                                    <input hidden type="file" ref={inputFilesRef} onChange={(e) => { handleGenerateCV(Array.from(e.target.files)) }}></input>
                                    <div className="import-pdf-container">
                                        <PrimaryButton text="Import CV" type="file" handleClick={() => {
                                            logEvent(AMPLITUDE_EVENT_TYPES.CLICK_UPLOAD_RAW_CV, { rolesPermitted: [ROLES.ADMIN] });
                                            inputFilesRef.current.click()
                                        }} />
                                        <span>or drag it here</span>
                                    </div>
                                </div>

                            </div>
                            <div className="col-1of3">
                                <img className="image-info" src={RawCvSecondImage} />
                            </div>

                            <div className="col-1of3">
                                <img className="image-info" src={RawCvFirstImage} />
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    )
};

const mapStateToProps = state => ({ role: getDisplayMode(state) });
const mapDispatchtoProps = { generateRawCv, showNotification };

export default connect(mapStateToProps, mapDispatchtoProps)(ProfileUploadRawCvPdf);
