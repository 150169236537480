import React from 'react';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import UpcomingInterviewsCard from './UpcomingInterviewsCard';
import NothingToShow from 'components/NothingToShow';

import './UpcomingInterviewsTable.scss';
import { useIntl } from 'react-intl';

const UpcomingInterviewsTable = ({
    upcomingInterviews
}) => {
    const intl = useIntl();

    return (
        <div className="upcoming-interview-table-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "my-upcoming-interviews" })}
                />
            </SectionActionsWrapper>
            {upcomingInterviews && upcomingInterviews.length
                ?
                <div className="upcoming-interview-table-container">
                    {upcomingInterviews.map((interview, index) =>
                        <UpcomingInterviewsCard
                            key={index}
                            interview={interview}
                        />
                    )}
                </div>
                : <NothingToShow items="upcoming interviews" />
            }
        </div>
    )
};

export default UpcomingInterviewsTable;
