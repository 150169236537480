import api from './api';
import requester from './requester';

const contractService = {
    createContract: (agencyId, clientId, contractData) =>
        requester(api.createContract(agencyId, clientId)).uploadFile(contractData),
    signContract: (agencyId, clientId, contractId, contractData) =>
        requester(api.signContract(agencyId, clientId, contractId)).uploadFile(contractData),
    deleteContract: (agencyId, contractId, departmentId) =>
        requester(api.deleteContract(agencyId, contractId, departmentId)).delete(),
    fetchContracts: (agencyId, query) =>
        requester(api.fetchContracts(agencyId, query)).getMany(),
    downloadContractFiles: (agencyId, clientId, contractId, fileName) =>
        requester(api.downloadContractFiles(agencyId, clientId, contractId)).saveAs(fileName),
    fetchContractFilesUrl: (agencyId, clientId, contractId) =>
        requester(api.contractFilesUrl(agencyId, clientId, contractId)).getMany(),
    sendContractSignReminderEmail: (agencyId, employeeId, contractId) => requester(api.sendContractSignReminderEmail(agencyId, employeeId, contractId)).create(),
};

export default contractService;
