import React, { useEffect, useState, Fragment } from 'react';
import { Switch, Route, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { fetchPersonalInfo, exportEmployeeInformation } from 'actions/employeeActions';
import { resetEmployeeInformationStates } from 'actions/sectionsLoadersActions';
import { disableUsers, restoreUsers, terminateUserEmployment } from 'actions/userActions';
import { fetchAgencyPublicHolidays, fetchEmployeePublicHolidays } from 'actions/publicHolidaysActions';
import {
    getEmployeeBasicInfo,
    getEmploymentType,
    getAgencyId,
    isSupplierAdminMode,
    isRecruiterMode,
    isAdminMode,
    isClientProjectsModuleModified,
    getIsRawCvPdfGenerated,
    getProfileDetails,
    getGeneralInfo
} from 'reducers';

import { getStateAgencyName } from 'utils/helpers';

import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import PersonalInfo from './PersonalInfo';
import EmploymentInformation from './EmploymentInformation';
import PerformanceReview from './PerformanceReview';
import Profile from './Profile';
import TimeTracking from './TimeTracking';
import Tasks from './Tasks';
import Holidays from './Holidays';
import Benefits from './Benefits';
import Overview from './Overview';
import UpcomingInterviews from 'components/UpcomingInterviews';
import SubmenuContentLayout from 'layouts/SubmenuContentLayout';
import ConfirmRestoreUserDialog from 'components/Shared/ConfirmRestoreUserDialog/ConfirmRestoreUserDialog';
import EmployeeTerminationDialog from 'components/Shared/EmployeeTerminationDialog';
import ConfirmEmployeeTerminationDialog from 'components/Shared/ConfirmEmployeeTerminationDialog';
import SubmenuActionButton from 'components/Shared/SubmenuActionButton';
import PrimaryExportButton from 'components/Shared/Buttons/PrimaryExportButton';
import { ROLES } from 'constants/userConstants';
import { DATE_FORMAT_WITH_DASH, DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

const Person = ({
    match,
    departmentId,
    fetchPersonalInfo,
    basicInfo,
    employmentType,
    agencyId,
    userId,
    isSupplierAdminMode,
    isRecruiterMode,
    isAdminMode,
    disableUsers,
    isClientProjectsModuleModified,
    resetEmployeeInformationStates,
    fetchAgencyPublicHolidays,
    fetchEmployeePublicHolidays,
    displayMode,
    profileDetails,
    generalInformation,
    restoreUsers,
    terminateUserEmployment,
    exportEmployeeInformation,
}) => {
    const personItems = {
        overview: { text: 'Overview', path: '', langKey: 'submenu-overview' },
        personalInfo: { text: 'Personal Information', path: '/personal-information', langKey: 'submenu-personal-information' },
        employmentInfo: { text: 'Employment Information', path: '/employment-information', langKey: 'submenu-employment-information' },
        performanceReview: { text: 'Performance Review', path: '/performance-review', langKey: 'submenu-performance-review' },
        profileCV: { text: 'Profile/CV', path: '/profile-cv', langKey: 'submenu-profile-cv' },
        timeTracking: { text: 'Time Tracking', path: '/time-tracking', langKey: 'submenu-time-tracking' },
        holidays: { text: 'Holidays', path: '/holidays', langKey: 'menu-team-holidays' },
        tasksReminders: { text: 'Tasks/Reminders', path: '/tasks-reminders', langKey: 'submenu-tasks-and-reminders' },
        benefits: { text: 'Perks & Equipment', path: '/perks-and-equipment', langKey: 'submenu-perks-and-equipment' },
        upcomingInterviews: { text: 'Upcoming Interviews', path: '/upcoming-interviews', langKey: 'submenu-upcoming-interviews' }
    };

    const intl = useIntl();
    const history = useHistory();

    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
    const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
    const [openEmployeeTerminationDialog, setOpenEmployeeTerminationDialog] = useState(false);
    const [openConfirmEmployeeTerminationDialog, setOpenConfirmEmployeeTerminationDialog] = useState(false);
    const [employeeToRemove, setEmployeeToRemove] = useState('');
    const [terminationDate, setTerminationDate] = useState(null);

    const { id, memberId } = useParams();

    const employeeId = id ? id : memberId;
    const isUserEmployeeOrAdmin = [ROLES.ADMIN, ROLES.EMPLOYEE].includes(basicInfo.role);
    const isDepartmentView = !!departmentId;

    useEffect(() => {
        fetchPersonalInfo(agencyId, employeeId, false, departmentId).then((profileData) => {
            if (displayMode === ROLES.ADMIN && (
                profileData.role === ROLES.SUPPLIER_ADMIN ||
                profileData.role === ROLES.SUPPLIER_EMPLOYEE ||
                profileData.role === ROLES.SUPPLIER_ONLY
            )) {
                history.push(`/${getStateAgencyName()}/partners/${profileData.supplierId ? profileData.supplierId : employeeId}/team/people/${employeeId}`);
            }
        });

        fetchEmployeePublicHolidays(agencyId, employeeId);

        return () => {
            fetchAgencyPublicHolidays(agencyId, userId, displayMode);
            resetEmployeeInformationStates();
        };
    }, [id]);

    const handleCloseConfirmRemoveDialog = () => { setOpenConfirmRemoveDialog(false); setEmployeeToRemove(''); };

    const deleteProfileClickHandler = () => {
        setEmployeeToRemove(employeeId);
        if (isUserEmployeeOrAdmin) {
            setOpenEmployeeTerminationDialog(true);
        } else {
            setOpenConfirmRemoveDialog(true);
        }
    };

    const handleOpenRestoreDialog = () => setOpenRestoreDialog(true);
    const handleCloseRestoreDialog = () => setOpenRestoreDialog(false);

    const handleRemoveEmployee = () => {
        disableUsers([employeeToRemove], ROLES.EMPLOYEE)
            .then(() => {
                history.push(`/${getStateAgencyName()}/team/people`);
            });
        handleCloseConfirmRemoveDialog();
    };

    const handleCloseEmployeeTerminationDialog = () => { setOpenEmployeeTerminationDialog(false); setEmployeeToRemove(''); };
    const handleCloseConfirmEmployeeTerminationDialog = () => { setOpenConfirmEmployeeTerminationDialog(false); setEmployeeToRemove(''); };

    const handleEmployeeTerminationDialogSubmit = (values) => {
        setTerminationDate(values.terminationDate);
        setOpenEmployeeTerminationDialog(false);
        setOpenConfirmEmployeeTerminationDialog(true);
    };

    const handleConfirmEmployeeTerminationSubmit = () => {
        terminateUserEmployment(employeeToRemove, terminationDate);
        handleCloseConfirmEmployeeTerminationDialog();
    };

    const isProfileOfRecruiterEmployeeOrRecruiterDisplayMode = basicInfo.role === ROLES.RECRUITER_EMPLOYEE || isRecruiterMode;

    const filterSubmenuItems = () => {
        if (isSupplierAdminMode) {
            const { personalInfo, profileCV, timeTracking, holidays, upcomingInterviews } = personItems;
            return Object.values({ personalInfo, profileCV, timeTracking, holidays, upcomingInterviews });
        } else if (isProfileOfRecruiterEmployeeOrRecruiterDisplayMode) {
            const { personalInfo, profileCV } = personItems;
            return Object.values({ personalInfo, profileCV });
        } else if (departmentId) {
            const { overview, personalInfo, employmentInfo, profileCV, timeTracking, holidays } = personItems;
            return Object.values({ overview, personalInfo, employmentInfo, profileCV, timeTracking, holidays });
        } else {
            return Object.values(personItems);
        }
    }

    const restoreUser = () => {
        restoreUsers([id], basicInfo.role);
        handleCloseRestoreDialog();
    };

    const handleEmployeeExport = async () => {
        const fileName = `${moment().format(DATE_FORMAT_WITH_DASH)}-${basicInfo?.firstName || ''} ${basicInfo?.lastName || ''}`;
        await exportEmployeeInformation(agencyId, employeeId, fileName, departmentId);
    };

    const deleteButton = <SubmenuActionButton
        handleClick={deleteProfileClickHandler}
        text={intl.formatMessage({ id: "delete-profile" })}
        buttonStyle="delete"
    />;

    const restoreButton = <SubmenuActionButton
        handleClick={handleOpenRestoreDialog}
        text={intl.formatMessage({ id: "restore-profile" })}
        buttonStyle="save"
        helperText={isUserEmployeeOrAdmin
            ?  profileDetails?.terminationDate ? `${intl.formatMessage({ id: "terminated-on" })}: ${moment(profileDetails?.terminationDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}` : ''
            :  profileDetails?.dateDeleted ? `${intl.formatMessage({ id: "deleted-on" })}: ${moment(profileDetails?.dateDeleted).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}` : ''
        }
    />;

    const exportButton = <SubmenuActionButton
        component={<PrimaryExportButton />}
        handleClick={handleEmployeeExport}
        text={intl.formatMessage({ id: "export-button-text" })}
    />;

    const actionButtons = <>
        {(!isDepartmentView && !isSupplierAdminMode)
            ? (profileDetails?.terminationDate
                ? restoreButton
                : deleteButton)
            : null
        }
        {exportButton}
    </>

    return (
        <SubmenuContentLayout
            path={match.url}
            profileName={`${basicInfo.firstName} ${basicInfo.lastName}`}
            avatarUrl={basicInfo.profilePictureImgUrl}
            role={basicInfo.role}
            employmentType={employmentType}
            items={filterSubmenuItems()}
            actionButtons={actionButtons}
            displayMode={displayMode}
            showActionButtons={isAdminMode || isDepartmentView || isSupplierAdminMode}
        >
            <Switch>
                {
                    isAdminMode || departmentId
                        ? <Route path={match.path} exact render={(props) => (
                            <Overview {...props}
                                userId={employeeId}
                                agencyId={agencyId}
                                departmentId={departmentId}
                            />
                        )} />
                        : <Route path={match.path} exact component={() => <h1>Overview</h1>} />
                }
                <Route path={`${match.path}${personItems.personalInfo.path}`} render={(props) => (
                    <PersonalInfo {...props} userId={employeeId} agencyId={agencyId} isAdmin={true} departmentId={departmentId} >
                        <PersonalInfo.BasicForm isDepartmentView={isDepartmentView} />
                        {(isAdminMode || departmentId) && <PersonalInfo.HistoricalNotes />}
                        <PersonalInfo.ContactForm />
                        <PersonalInfo.PersonalForm />
                    </PersonalInfo>
                )} />
                <Route path={`${match.path}${personItems.employmentInfo.path}`} render={(props) => (
                    <>
                        {!isSupplierAdminMode && <EmploymentInformation {...props} userId={employeeId} agencyId={agencyId} departmentId={departmentId} />}
                    </>
                )} />

                <Route path={`${match.path}${personItems.profileCV.path}`} render={(props) => (
                    <Profile {...props} userId={employeeId} agencyId={agencyId} departmentId={departmentId} >
                        <Profile.Cv />
                    </Profile>
                )} />
                <Route
                    path={`${match.path}${personItems.timeTracking.path}`}
                    render={(props) => (
                        <TimeTracking
                            {...props}
                            userId={employeeId}
                            agencyId={agencyId}
                            departmentId={departmentId}
                        />
                    )}
                />

                <Route path={`${match.path}${personItems.holidays.path}`} render={(props) => (<Holidays {...props} userId={employeeId} departmentId={departmentId} />)} />
                <Route path={`${match.path}${personItems.upcomingInterviews.path}`} render={(props) => (<UpcomingInterviews {...props} userId={employeeId} agencyId={agencyId} />)} />

                {!isSupplierAdminMode &&
                    <Fragment>
                        <Route path={`${match.path}${personItems.performanceReview.path}`}
                            render={(props) => (<PerformanceReview {...props} employeeId={employeeId} agencyId={agencyId} />)} />
                        <Route path={`${match.path}${personItems.profileCV.path}`} render={(props) => (<Profile {...props} userId={employeeId} agencyId={agencyId} />)} />
                        <Route path={`${match.path}${personItems.timeTracking.path}`} render={(props) => (<TimeTracking {...props} userId={employeeId} agencyId={agencyId} />)} />
                        <Route path={`${match.path}${personItems.tasksReminders.path}`} component={Tasks} />
                        <Route path={`${match.path}${personItems.benefits.path}`} render={(props) => (<Benefits {...props} agencyId={agencyId} userId={employeeId} />)} />
                    </Fragment>
                }
            </Switch>

            <ConfirmDeleteDialog
                itemToDelete="this user"
                handleDeleteItem={handleRemoveEmployee}
                openDialog={openConfirmRemoveDialog}
                handleCloseDialog={handleCloseConfirmRemoveDialog}
            />

            <ConfirmRestoreUserDialog
                openDialog={openRestoreDialog}
                handleCloseDialog={handleCloseRestoreDialog}
                handleConfirmDialog={restoreUser}
                isUserEmployeeOrAdmin={isUserEmployeeOrAdmin}
            />

            <EmployeeTerminationDialog
                employmentStartDate={generalInformation?.startDate}
                isDialogOpen={openEmployeeTerminationDialog}
                handleCloseDialog={handleCloseEmployeeTerminationDialog}
                handleSubmit={handleEmployeeTerminationDialogSubmit}
            />

            <ConfirmEmployeeTerminationDialog
                openDialog={openConfirmEmployeeTerminationDialog}
                handleConfirmDialog={handleConfirmEmployeeTerminationSubmit}
                handleCloseDialog={handleCloseConfirmEmployeeTerminationDialog}
            />
        </SubmenuContentLayout >
    )
};

const mapStateToProps = state => ({
    basicInfo: getEmployeeBasicInfo(state),
    employmentType: getEmploymentType(state),
    agencyId: getAgencyId(state),
    isSupplierAdminMode: isSupplierAdminMode(state),
    isRecruiterMode: isRecruiterMode(state),
    isAdminMode: isAdminMode(state),
    isRawCvPdfGenerated: getIsRawCvPdfGenerated(state),
    isClientProjectsModuleModified: isClientProjectsModuleModified(state),
    profileDetails: getProfileDetails(state),
    generalInformation: getGeneralInfo(state),
});

const mapDispatchtoProps = {
    fetchPersonalInfo,
    disableUsers,
    resetEmployeeInformationStates,
    fetchAgencyPublicHolidays,
    fetchEmployeePublicHolidays,
    restoreUsers,
    terminateUserEmployment,
    exportEmployeeInformation,
};

export default connect(mapStateToProps, mapDispatchtoProps)(Person);
