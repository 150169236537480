import api from './api';
import requester from './requester';

const jobApplicationService = {
    addJobApplication: (agencyId, jobId, applicationDetails) => requester(api.jobApplicationJob(agencyId, jobId)).create(applicationDetails),
    fetchJobApplication: (agencyId, applicationId) => requester(api.jobApplication(agencyId, applicationId)).getOne(),
    updateJobApplicationStatusByAdmin: (agencyId, applicationId, statusData) => requester(api.jobApplicationStatusAdmin(agencyId, applicationId)).update(statusData),
    updateJobApplicationStatus: (agencyId, applicationId, statusData) => requester(api.jobApplicationStatus(agencyId, applicationId)).update(statusData),
    updateJobApplicationAssignee: (agencyId, applicationId, assignee) => requester(api.jobApplicationAssignee(agencyId, applicationId)).update(assignee),
    updateJobApplicationStartDate: (agencyId, applicationId, startDate) => requester(api.jobApplicationStartDate(agencyId, applicationId)).update(startDate),
    fetchCandidateApplications: (agencyId, candidateId) => requester(api.candidateApplications(agencyId, candidateId)).getMany(),
    fetchCandidateApplicationApproved: (agencyId, candidateId) => requester(api.candidateApplicationApproved(agencyId, candidateId)).getOne(),
    deleteJobApplication: (agencyId, applicationId) => requester(api.jobApplication(agencyId, applicationId)).delete(),
    sendJobApplicationStatusRequestEmail: (agencyId, applicationId) => requester(api.jobApplicationStatusRequest(agencyId, applicationId)).create(),
}

export default jobApplicationService;
