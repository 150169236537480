import api from './api';
import requester from './requester';

const documentService = {
    fetchAgencyDocuments: (agencyId, query) => requester(api.agencyDocuments(agencyId, query)).getMany(),
    exportDocumentsData: (agencyId, documentsData, fileName, query) => requester(api.agencyDocumentsExport(agencyId, query)).saveAsAction(fileName, documentsData),
    downloadDocumentsFiles: (agencyId, documentsData, fileName, query) => requester(api.agencyDocumentsFiles(agencyId, query)).saveAsAction(fileName, documentsData),
};

export default documentService;
