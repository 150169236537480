import React from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Dialog from 'components/Shared/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import {
    validateRequiredFields,
    validateInputLength,
} from 'utils/formValidations';

import AddEditBenefitDialogView from './AddEditBenefitDialogView'

import './AddEditBenefitDialog.scss'

const AddEditBenefitDialog = ({
    isEditMode,
    openAddEditDialog,
    handleCloseDialog,
    createBenefit,
    updateBenefit,
    setIsTemplate,
    isTemplate,
    createBenefitTemplate,
    agencyId,
    userId,
    templates,
    currentBenefit,
    handleSelectProjectTemplate,
    isEmployeeMode
}) => {
    const intl = useIntl();

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize
                initialValues={{
                    name: currentBenefit?.name || '',
                    details: currentBenefit?.details || '',
                }}
                validate={values => {
                    return {
                        ...validateInputLength(values, [
                            { property: 'name', maxLength: 100 },
                            { property: 'details', maxLength: 1000 },
                        ]),
                        ...validateRequiredFields(values, ['name']),
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {

                    setSubmitting(false);

                    if (isTemplate) {
                        createBenefitTemplate(values);
                    }

                    isEditMode ? updateBenefit(currentBenefit._id, values) : createBenefit(values);

                    handleCloseDialog();
                }}
            >
                {(props) =>
                    <Dialog className="add-edit-benefit-dialog" open={openAddEditDialog} onClose={handleCloseDialog} maxWidth={'md'}>
                        <MuiDialogTitle disableTypography className="dialog-title">
                            <Typography variant="body2" className="dialog-title-text">
                                {isEditMode
                                    ? <span>{intl.formatMessage({ id: "edit" })} {intl.formatMessage({ id: "submenu-perks-and-equipment" })}</span>
                                    : <span>{intl.formatMessage({ id: "add" })} {intl.formatMessage({ id: "submenu-perks-and-equipment" })}</span>
                                }
                            </Typography>
                            <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                                <CloseIcon />
                            </IconButton>
                        </MuiDialogTitle>
                        <DialogContent className="dialog-content">
                            <AddEditBenefitDialogView
                                {...props}
                                templates={templates}
                                setIsTemplate={setIsTemplate}
                                handleCloseDialog={handleCloseDialog}
                                handleSelectProjectTemplate={handleSelectProjectTemplate}
                                isEmployeeMode={isEmployeeMode}
                            />
                        </DialogContent>
                    </Dialog>
                }
            </Formik>
        </MuiPickersUtilsProvider>

    )
}

export default AddEditBenefitDialog;
