export const TABLE_COLUMNS = [
    { name: 'Name', value: 'name', isSortable: true },
    { name: 'Position', value: 'position', isSortable: true },
    { name: 'Experience', value: 'experience', isSortable: true },
];

export const DEFAULT_TABLE_COLUMNS = ['name', 'position', 'experience'];

export const SORT_QUERY_SELECTOR = 'sort';
export const PAGE_QUERY_SELECTOR = 'page';
export const DEFAULT_ITEMS_PER_PAGE = 25;

export const TABLE_INITIAL_QUERY = {
    page: 1,
    sortBy: 'name',
    sortByDir: 'desc',
};
