import { INITIAL_QUERY, SORT_QUERY_SELECTOR, TABS_QUERY_SELECTOR } from './CandidatesTableConstants';

export const getDataFromQuery = (parsedQuery, tableFilters) => {
    const sortBy = parsedQuery[SORT_QUERY_SELECTOR] ? parsedQuery[SORT_QUERY_SELECTOR][0] : INITIAL_QUERY.sortBy;
    const sortByDir = parsedQuery[`${SORT_QUERY_SELECTOR}Dir`] ? parsedQuery[`${SORT_QUERY_SELECTOR}Dir`][0] : INITIAL_QUERY.sortByDir;
    const tab = parsedQuery[TABS_QUERY_SELECTOR] ? parsedQuery[TABS_QUERY_SELECTOR][0] : INITIAL_QUERY.tab;
    const filters = parsedQuery.filters
        ? parsedQuery.filters.reduce((acc, currentFilter) => {
            const filter = tableFilters.find(x => x.value.includes(currentFilter));
            if (filter) {
                if (acc[filter.key]) {
                    acc[filter.key].push(currentFilter);
                } else {
                    acc[filter.key] = [currentFilter];
                }
            }
            return acc;
        }, {})
        : {};

    return { sortBy, sortByDir, tab, filters };
};
