import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';

import { fetchUserAsHottestTalent } from 'actions/userActions';
import {
    fetchMatchingJobs,
    updateJob,
    updateJobOpportunityPrivacy,
    deleteJob,
} from 'actions/jobOpportunityActions';

import {
    isAdmin,
    getTalent
} from 'reducers';

import MatchingJobsRedux from './MatchingJobsRedux';
import ExploreTalentsEmployeeCard from 'components/ExploreTalents/ExploreTalentsEmployeeCard';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import JobFormDialog from 'components/Shared/JobFormDialog';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import { CircularProgress } from '@material-ui/core';

import { JOB_FORM_TITLES } from 'constants/jobOpportunitiesConstants';
import { PROFILE_MATCHING_JOBS_FILTERS, PROFILE_MATCHING_JOBS_FILTER_LIST_ITEMS, NOT_UNSELECTABLE_FILTERS } from './ProfileMatchingJobsConstants';

import { parseJobData } from 'utils/jobUtils';
import { updateQuery, parseQueryString } from 'utils/helpers';
import { mapQueryToFilters } from './ProfileMatchingJobsUtils';

import './ProfileMatchingJobs.scss';

const ProfileMatchingJobs = ({
    agencyId,
    isAdmin,
    talent,
    fetchUserAsHottestTalent,
    fetchMatchingJobs,
    updateJob,
    updateJobOpportunityPrivacy,
    deleteJob,
}) => {
    const { userId } = useParams();
    const history = useHistory();
    const intl = useIntl();

    const [areJobsLoading, setAreJobsLoading] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState({});
    const [jobImgData, setJobImgData] = useState(null);
    const [jobFormTitle, setJobFormTitle] = useState('');
    const [closePopoverFunction, setClosePopoverFunction] = useState(() => () => void 0);

    useEffect(() => {
        if (!talent) {
            fetchUserAsHottestTalent(agencyId, userId);
        }

        fetchMatchingJobs(agencyId, userId).then(() => {
            setAreJobsLoading(false);
        });
    }, []);

    const [filters, setFilters] = useState(PROFILE_MATCHING_JOBS_FILTERS);

    useEffect(() => {
        const queryString = history.location.search;
        const parsedQuery = parseQueryString(queryString);

        if (Object.keys(parsedQuery).length > 0) {
            const newFilters = mapQueryToFilters(parsedQuery);
            setFilters({ ...newFilters });
        } else {
            setFilters(PROFILE_MATCHING_JOBS_FILTERS);
        }
    }, [history.location.search]);

    const handleSingleSelectFilterChange = (filterName, value) => {
        let filterObj = {};
        if (NOT_UNSELECTABLE_FILTERS.includes(filterName)) {
            filterObj = { [filterName]: value };
        } else {
            filterObj = filters[filterName] !== value
                ? { [filterName]: value }
                : { [filterName]: '' };
        }

        const updatedQuery = updateQuery(history.location.search, filterObj);

        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    const onEditClick = (job) => {
        setSelectedJob(job);
        setJobFormTitle(JOB_FORM_TITLES.EDIT);
        setIsDialogOpen(true);
        closePopoverFunction();
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedJob({});
        setJobImgData(null);
    };

    const handleOpenDeleteDialog = (job) => {
        setSelectedJob(job);
        setIsDeleteDialogOpen(true);
        closePopoverFunction();
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setSelectedJob({});
    };

    const handleDeleteJob = () => {
        deleteJob(agencyId, selectedJob._id);
        handleCloseDeleteDialog();
    };

    const handleSubmitJobForm = async (values) => {
        const parsedJob = parseJobData({ ...selectedJob, ...values }, isAdmin);

        const formData = new FormData();

        if (jobImgData) {
            formData.append('jobImageFile', jobImgData.file);
            formData.append('jobImageInfo', JSON.stringify(jobImgData.info));
        } else if (!jobImgData && !values.jobImgUrl) {
            formData.append('jobImageFile', null);
            formData.append('jobImageInfo', JSON.stringify({}));
        }

        formData.append('jobData', JSON.stringify({ ...parsedJob }));
        await updateJob(agencyId, selectedJob._id, formData);

        handleCloseDialog();
    }

    return (
        <div className="profile-request-wrapper">
            {talent && (
                <ExploreTalentsEmployeeCard employee={talent}>
                    <ExploreTalentsEmployeeCard.ProfileWrapper>
                        <ExploreTalentsEmployeeCard.ProfileWrapper.RoleBadge />
                        <div className="avatar-status-wrapper">
                            <ExploreTalentsEmployeeCard.ProfileWrapper.Avatar />
                            <ExploreTalentsEmployeeCard.ProfileWrapper.StatusOrb />
                        </div>
                        <ExploreTalentsEmployeeCard.ProfileWrapper.VettedProfile />
                    </ExploreTalentsEmployeeCard.ProfileWrapper>

                    <ExploreTalentsEmployeeCard.ProfileInfoWrapper employee={talent}>
                        <ExploreTalentsEmployeeCard.ProfileInfoWrapper />
                    </ExploreTalentsEmployeeCard.ProfileInfoWrapper>

                    <ExploreTalentsEmployeeCard.ButtonsWrapper>
                        <ExploreTalentsEmployeeCard.ButtonsWrapper.ViewProfile />
                        <ExploreTalentsEmployeeCard.ButtonsWrapper.EditProfile />
                    </ExploreTalentsEmployeeCard.ButtonsWrapper>
                </ExploreTalentsEmployeeCard>
            )}

            {areJobsLoading ? <CircularProgress size={50} className="matching-jobs-loader" /> :
                talent && (
                    <>
                        <SectionActionsWrapper>
                            <SectionActionsWrapper.SectionTitle sectionTitle={intl.formatMessage({ id: "jobs-that-fit-your-search" })} />
                            <SectionActionsWrapper.LeftAlignedActions>
                                <SectionActionsWrapper.LeftAlignedActions.GroupedSelectFilterListWrapper
                                    title={intl.formatMessage({ id: "filter-by" })}
                                    className="matching-jobs"
                                    filters={filters}
                                    groups={PROFILE_MATCHING_JOBS_FILTER_LIST_ITEMS}
                                    handleChange={handleSingleSelectFilterChange}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    closeMenuOnSelect={true}
                                />
                            </SectionActionsWrapper.LeftAlignedActions>
                        </SectionActionsWrapper>
                        <div className="matching-jobs-container">
                            <MatchingJobsRedux
                                agencyId={agencyId}
                                isAdmin={isAdmin}
                                onEditClick={onEditClick}
                                handleOpenDeleteDialog={handleOpenDeleteDialog}
                                updateJob={updateJob}
                                updateJobOpportunityPrivacy={updateJobOpportunityPrivacy}
                                setClosePopoverFunction={setClosePopoverFunction}
                                filters={filters}
                            />

                            <ConfirmDeleteDialog
                                openDialog={isDeleteDialogOpen}
                                handleCloseDialog={handleCloseDeleteDialog}
                                handleDeleteItem={handleDeleteJob}
                                itemToDelete="this job"
                            />

                            <JobFormDialog
                                title={jobFormTitle}
                                open={isDialogOpen}
                                clientId={selectedJob?.client?._id}
                                isClientDisabled={isAdmin && selectedJob?.client?._id}
                                onClose={handleCloseDialog}
                                onSubmit={handleSubmitJobForm}
                                agencyId={agencyId}
                                selectedItem={selectedJob}
                                isClientRequested={isAdmin}
                                isAdmin={isAdmin}
                                setJobImgData={setJobImgData}
                            />
                        </div>
                    </>
                )
            }
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    isAdmin: isAdmin(state),
    talent: getTalent(state, ownProps.match.params.userId),
});

const mapDispatchToProps = {
    fetchUserAsHottestTalent,
    fetchMatchingJobs,
    updateJob,
    updateJobOpportunityPrivacy,
    deleteJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMatchingJobs);
