import React, { useEffect, useState } from 'react';

import { Paper, TextField } from '@material-ui/core';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import AddFilesDialog from '../AddFilesDialog/AddFilesDialog';
import TrainingFilesTable from '../TrainingFilesTable/TrainingFilesTable';
import VideosField from './VideosField';

import { DIALOG_COMMENT_INPUT } from 'constants/commonConstants';

const Topic = ({
    match,
    topics,
    isAdmin,
    agencyId,
    updateTrainingTopicDescription,
}) => {

    const { trainingId, topicId } = match.params;
    const topic = topics.find(x => x._id === topicId);

    useEffect(() => {
        setDescription(topics.find(x => x._id === topicId)?.description || '')
    }, [topics]);


    const initialDescription = topic?.description || '';
    const [description, setDescription] = useState(null);
    const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);
    const [openUploadFilesDialog, setOpenUploadFilesDialog] = useState(false);

    const handleOpenUploadFiledDialog = () => setOpenUploadFilesDialog(true);

    const handleCloseUploadFilesDialog = () => setOpenUploadFilesDialog(false);

    const handleSaveDescription = () => {
        updateTrainingTopicDescription(agencyId, trainingId, topicId, { description });
    };

    return (
        <div className="topic-wrapper">
            <div className="topic-description-wrapper">
                <SectionActionsWrapper>
                    <SectionActionsWrapper.SectionTitle
                        sectionTitle='Description'
                    />

                    {isSaveButtonVisible && (
                        <SectionActionsWrapper.RightAlignedActions>
                            <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                                text='Save'
                                onClick={handleSaveDescription}
                                buttonStyle='save'
                            />
                        </SectionActionsWrapper.RightAlignedActions>
                    )
                    }
                </SectionActionsWrapper>

                <Paper className="description-content">
                    <TextField
                        minRows={DIALOG_COMMENT_INPUT.ROWS}
                        multiline
                        fullWidth
                        variant="outlined"
                        value={description || initialDescription}
                        label="Description"
                        inputProps={{
                            maxLength: 10000
                        }}
                        onChange={(e) => {
                            setIsSaveButtonVisible(true);
                            setDescription(e.target.value);
                        }}
                        className="description-text-field"
                    />
                </Paper>
            </div>

            <VideosField topicId={topicId} trainingId={trainingId} />

            <div className="topic-files-wrapper">
                <AddFilesDialog
                    topicId={topicId}
                    trainingId={trainingId}
                    openDialog={openUploadFilesDialog}
                    handleCloseDialog={handleCloseUploadFilesDialog}
                />

                <TrainingFilesTable
                    agencyId={agencyId}
                    topicId={topicId}
                    trainingId={trainingId}
                    isAdmin={isAdmin}
                    handleOpenUploadFiledDialog={handleOpenUploadFiledDialog}
                />
            </div>
        </div>
    )
}

export default Topic;
