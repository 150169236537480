import { push } from 'connected-react-router';

import commonService from 'services/commonService';

export const checkServerStatus = () => async dispatch => {
    await commonService.checkServerStatus();
};

export const serverDownRedirect = (state) => dispatch => {
    if (state?.router?.location.pathname === '/sorry') {
        return;
    }
    dispatch(push('/sorry'));
};
