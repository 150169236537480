import React, { useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { sendVerificationEmail, sendPasswordResetEmail } from 'actions/authActions';

import ProfileCreationCard from 'components/Shared/ProfileCreationCard';
import Typography from '@material-ui/core/Typography';

import { EXPIRED_LINK_PAGE_DATA, EXPIRED_LINK_TYPES } from './ExpiredLinkPageConstants';
import { SUPPORT_EMAIL } from 'constants/env';

import './ExpiredLinkPage.scss';

const ExpiredLinkPage = ({
    sendVerificationEmail,
    sendPasswordResetEmail
}) => {
    const history = useHistory();
    const location = useLocation();
    const { type } = useParams();
    const { email, role } = location?.state || {};

    const expiredLinkPageData = EXPIRED_LINK_PAGE_DATA[type];

    const handleButtonClick = () => {
        switch (type) {
            case EXPIRED_LINK_TYPES.SET_PASSWORD:
                sendVerificationEmail({ email, role });
                break;
            case EXPIRED_LINK_TYPES.RESET_PASSWORD:
                sendPasswordResetEmail(email);
                break;
            default:
                break;
        }
    };

    const isTypeValid = Object.values(EXPIRED_LINK_TYPES).includes(type);

    useEffect(() => {
        if (!email || !isTypeValid) {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    return (
        <ProfileCreationCard
            className="expired-link-wrapper"
        >
            <ProfileCreationCard.Header />
            <ProfileCreationCard.ContentWrapper>
                <ProfileCreationCard.ContentTitle className="normal-text">
                    {expiredLinkPageData?.title}
                </ProfileCreationCard.ContentTitle>
                <ProfileCreationCard.ContentDivider />
                <ProfileCreationCard.ContentBody className="full-width">
                    <Typography className="expired-link-content-text">
                        {expiredLinkPageData?.text}
                    </Typography>
                </ProfileCreationCard.ContentBody>
                <ProfileCreationCard.ContentAction text={expiredLinkPageData?.buttonName} handleClick={handleButtonClick} />
                <Typography className="expired-link-footer-text">
                    Didn't received the email? Please get in touch with us via <a className="email" href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                </Typography>
            </ProfileCreationCard.ContentWrapper>
        </ProfileCreationCard>
    )
}

const mapDispatchToProps = {
    sendVerificationEmail,
    sendPasswordResetEmail
};

export default connect(null, mapDispatchToProps)(ExpiredLinkPage);
