import moment from 'moment';

import { ROLES } from 'constants/userConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import {
    TABLE_COLUMNS,
    DEFAULT_TABLE_COLUMNS,
    TABLE_COLUMNS_NO_SIGN,
    DEFAULT_TABLE_COLUMNS_NO_SIGN,
    SORT_QUERY_SELECTOR,
    PAGE_QUERY_SELECTOR,
    DATE_QUERY_SELECTOR,
    TABLE_FILTERS,
    TABLE_INITIAL_QUERY,
} from './HistoryHolidaysTableConstants';

export const getTableColumnsAndFilters = (displayMode, isUsingEvrotrust) => {
    if (displayMode === ROLES.ADMIN || displayMode === ROLES.EMPLOYEE) {
        return isUsingEvrotrust
            ? { TABLE_COLUMNS: TABLE_COLUMNS, DEFAULT_TABLE_COLUMNS: DEFAULT_TABLE_COLUMNS, TABLE_FILTERS }
            : {
                TABLE_COLUMNS: TABLE_COLUMNS_NO_SIGN,
                DEFAULT_TABLE_COLUMNS: DEFAULT_TABLE_COLUMNS_NO_SIGN,
                TABLE_FILTERS: TABLE_FILTERS.filter(x => x.key !== 'signatureStatus')
            }
    }

    if (displayMode === ROLES.SUPPLIER_ADMIN || displayMode === ROLES.SUPPLIER_EMPLOYEE) {
        return {
            TABLE_COLUMNS: TABLE_COLUMNS_NO_SIGN,
            DEFAULT_TABLE_COLUMNS: DEFAULT_TABLE_COLUMNS_NO_SIGN,
            TABLE_FILTERS: TABLE_FILTERS.filter(x => x.key !== 'signatureStatus')
        }
    }
};

export const getDataFromQuery = (parsedQuery, tableFilters) => {
    const parsedQuerySortBy = parsedQuery[SORT_QUERY_SELECTOR] ? parsedQuery[SORT_QUERY_SELECTOR][0] : TABLE_INITIAL_QUERY.sortBy;
    const sortBy = TABLE_COLUMNS.find(x => x.value === parsedQuerySortBy).propertyName;
    const sortByDir = parsedQuery[`${SORT_QUERY_SELECTOR}Dir`] ? parsedQuery[`${SORT_QUERY_SELECTOR}Dir`][0] : TABLE_INITIAL_QUERY.sortByDir;
    const date = parsedQuery[DATE_QUERY_SELECTOR] ? parsedQuery[DATE_QUERY_SELECTOR][0] : null;
    const page = parsedQuery[PAGE_QUERY_SELECTOR] ? parsedQuery[PAGE_QUERY_SELECTOR][0] : 1;
    const filters = parsedQuery.filters
        ? parsedQuery.filters.reduce((acc, currentFilter) => {
            const filter = tableFilters.find(x => x.value.includes(currentFilter));
            if (filter) {
                if (acc[filter.key]) {
                    acc[filter.key].push(currentFilter);
                } else {
                    acc[filter.key] = [currentFilter];
                }
            }
            return acc;
        }, {})
        : {};

    return { sortBy, sortByDir, date, page, filters };
};

export const getHolidaysExportFileName = documentsData => {
    let fileName = '';

    if (documentsData.length === 1) {
        const document = documentsData[0];
        const employeeName = document.firstName ? `${document.firstName} ${document.lastName}` : '';
        const uploadDate = moment(document.requestedOn).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)
        fileName = `${employeeName ? `${employeeName}_` : ''}Holiday_requested_on_${uploadDate}`
    } else {
        fileName = `WIA_Holidays_Export_as_of_${moment().format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`;
    }

    return fileName;
};
