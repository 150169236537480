import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import './PieChart.scss';

const PieChart = ({
    title,
    data,
}) => {
    const pieChartConfig = {
        credits: {
            enabled: false
        },
        title: {
            text: undefined
        },
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            spacing: [0, 0, 0, 0],
        },
        plotOptions: {
            pie: {
                showInLegend: true,
                size: '100%',
                innerSize: '80%',
                center: ['50%', '50%'],
                dataLabels: {
                    enabled: false,
                }
            },
            series: {
                borderWidth: 0,
                point: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                }
            },
        },
        legend: {
            width: 170,
            itemMarginTop: 15,
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            x: 0,
            y: 0,
            symbolHeight: 0,
            symbolWidth: 0,
            symbolRadius: 0,
            squareSymbol: false,
            useHTML: true,
            labelFormatter: function () {
                return `
                    <div class="legend-item-wrapper">
                        <span style=${'background-color:' + this.color} class="legend-point"></span>
                        <span class="legend-text">${this.name}</span>
                    </div>
                `;
            },
        },
        tooltip: {
            useHTML: true,
            style: {
                display: 'none'
            },
            formatter: function () {
                return `
                    <div class="pie-chart-tooltip">
                        <span>${this.point.name}: ${this.point.y}</span>
                    </div>
                `;
            }
        }
    }

    return (
        <div className="pie-chart-wrapper">
            <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ className: "pie-chart" }}
                options={{
                    ...pieChartConfig,
                    series: [{
                        type: 'pie',
                        data
                    }]
                }}
            />
            <div className="background-circle-wrapper">
                <div className="background-circle">
                    <span className="title">{title}</span>
                </div>
            </div>
        </div>
    );
};

export default PieChart;
