import React from 'react';

import './DashboardSectionWrapper.scss';
import { Typography } from '@material-ui/core';

const DashboardSectionWrapper = ({
    children,
    classes = [],
}) => {

    return (
        <div className={`dashboard-section-wrapper ${classes.join(' ')}`}>
            {children}
        </div>
    )
};

const DashboardSectionTitle = ({
    children
}) => {

    return (
        <Typography variant="h1">{children}</Typography>
    );
};

const DashboardSectionSubTitle = ({
    children
}) => {

    return (
        <Typography variant="body1">{children}</Typography>
    );
};

const DashboardSectionContent = ({
    children,
    classes = [],
}) => {

    return (
        <div className={`dashboard-section-content ${classes.join(' ')}`}>
            {children}
        </div>
    );
};

DashboardSectionWrapper.SectionTitle = DashboardSectionTitle;
DashboardSectionWrapper.SectionSubTitle = DashboardSectionSubTitle;
DashboardSectionWrapper.SectionContent = DashboardSectionContent;

export default DashboardSectionWrapper;
