import React from 'react';

import { FormattedMessage } from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { ROLES } from 'constants/userConstants';

import './AdditionalMenuMobile.scss';

const AdditionalMenuMobile = ({
    handleLogout,
    isAdmin,
    displayMode,
    handleSwitchRoleMode,
    handleGoToTCLink,
    modeText,
    closeMobileNavigationHandler
}) => {
    const handleLogoutAndCloseMenu = () => {
        handleLogout();
        closeMobileNavigationHandler();
    }

    const handleGoToTCLinkAndCloseMenu = () => {
        handleGoToTCLink();
        closeMobileNavigationHandler();
    }

    const handleSwitchRoleModeAndCloseMenu = () => {
        handleSwitchRoleMode();
        closeMobileNavigationHandler();
    };

    const getSwitchModeLanguageKey = () => modeText === "Employee" ? "additional-menu-switch-to-employee-mode" : "additional-menu-switch-to-admin-mode";

    return (
        <div className="navigation-footer-mobile">
            <List className="navigation-footer-list">
                <ListItem button className="logout-list-item" onClick={handleLogoutAndCloseMenu}>
                    <ListItemIcon className="logout-list-icon">
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <FormattedMessage id="additional-menu-logout">
                        {(msg) => <ListItemText primary={msg} className="logout-list-item-text" />}
                    </FormattedMessage>
                </ListItem>

                <div className="divider-wrapper">
                    <Divider />
                </div>

                {displayMode !== ROLES.ADMIN && displayMode !== ROLES.RECRUITER &&
                    <ListItem button className="navigation-footer-list-item" onClick={handleGoToTCLinkAndCloseMenu}>
                        <FormattedMessage id="additional-menu-terms-and-conditions">
                            {(msg) => <ListItemText primary={msg} className="list-item-text" />}
                        </FormattedMessage>
                    </ListItem>
                }

                {isAdmin &&
                    <ListItem button className="navigation-footer-list-item" onClick={handleSwitchRoleModeAndCloseMenu}>
                        <FormattedMessage id={getSwitchModeLanguageKey()}>
                            {(msg) => <ListItemText primary={msg} className="list-item-text" />}
                        </FormattedMessage>
                    </ListItem>
                }
            </List>
        </div>
    );
}

export default AdditionalMenuMobile;
