import { ROLES } from "constants/userConstants";

export const VETTING_STATUS = {
    VETTED_ONLY: 'Vetted only'
}

export const VETTING_STATUS_FILTER_VALUES = [
    VETTING_STATUS.VETTED_ONLY,
];

export const AVAILABILITY_FILTERS = {
    AVAILABLE_ONLY: 'Available only'
}

export const AVAILABILITY_FILTER_VALUES = [
    AVAILABILITY_FILTERS.AVAILABLE_ONLY,
];

export const PROFILE_TYPES = {
    EMPLOYEE: 'Employees',
    PARTNER_PROFILE: 'Partner profiles',
    CANDIDATE: 'Candidates',
}

export const PROFILE_TYPE_FILTER_VALUES = [
    PROFILE_TYPES.EMPLOYEE,
    PROFILE_TYPES.PARTNER_PROFILE,
    PROFILE_TYPES.CANDIDATE,
];

export const INVITATION_STATUS = {
    INVITED: 'Include sent candidates',
    NOT_INVITED: 'Exclude sent candidates',
}

export const INVITATION_STATUS_FILTER_VALUES = [
    INVITATION_STATUS.INVITED,
    INVITATION_STATUS.NOT_INVITED,
];

export const LOCATION_TYPES = {
    MATCHING_JOB_REQUIREMENTS: 'Matching Job Requirements',
    ALL_LOCATIONS: 'All Locations',
};

export const LOCATION_FILTER_VALUES = [
    LOCATION_TYPES.MATCHING_JOB_REQUIREMENTS,
    LOCATION_TYPES.ALL_LOCATIONS,
];

export const MULTISELECT_GROUPS = ['profileType'];

export const NOT_UNSELECTABLE = ['invitationStatus', 'location'];

export const FILTER_DEFAULT_STATE = {
    vettingStatus: '',
    availability: '',
    profileType: [],
    invitationStatus: INVITATION_STATUS.NOT_INVITED,
    candidateLocation: LOCATION_TYPES.ALL_LOCATIONS,
}

export const MATCHING_CONSULTANTS_FILTER_LIST_ITEMS = {
    vettingStatus: { name: 'Vetting Status', values: VETTING_STATUS_FILTER_VALUES },
    availability: { name: 'Availability', values: AVAILABILITY_FILTER_VALUES },
    profileType: { name: 'Profile type', values: PROFILE_TYPE_FILTER_VALUES },
    invitationStatus: { name: 'Invitation status', values: INVITATION_STATUS_FILTER_VALUES },
    candidateLocation: { name: 'Candidate Location', values: LOCATION_FILTER_VALUES },
};

export const PROFILE_TYPE_MAPPER = {
    [PROFILE_TYPES.EMPLOYEE]: ROLES.EMPLOYEE,
    [PROFILE_TYPES.PARTNER_PROFILE]: ROLES.SUPPLIER_EMPLOYEE,
    [PROFILE_TYPES.CANDIDATE]: ROLES.CANDIDATE,
}

export const DEFAULT_FILTER_QUERY = '?invitationStatus=Exclude%20sent%20candidates&candidateLocation=All%20Locations';
