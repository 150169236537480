export const settingsItems = {
    'admin': {
        companySettings: { text: 'Company Settings', path: '', langKey: 'submenu-company-settings' },
        employeeSettings: { text: 'Employee Settings', path: '/employee-settings', langKey: 'submenu-employee-settings' },
        trainings: { text: 'Trainings', path: '/trainings', langKey: 'submenu-trainings' },
        userManagement: { text: 'User Management', path: '/user-management', langKey: 'submenu-user-management' },
        coaching: { text: 'Coaching & Mentoring', path: '/coaching', langKey: 'submenu-coaching-and-mentoring' },
    },
    'supplier-admin': {
        employeeSettings: { text: 'Employee Settings', path: '/employee-settings', langKey: 'submenu-employee-settings' },
    },
    'client': {
        userManagement: { text: 'User Management', path: '/user-management', langKey: 'submenu-user-management' },
    }
}
