import React from 'react';

import { ReactComponent as IconCandidateExperience } from 'assets/icon-candidate-experience.svg';
import { ReactComponent as IconTaxResidency } from 'assets/icon-tax-residency.svg';
import { ReactComponent as IconCalendar } from 'assets/icon-metro-calendar.svg';
import { ReactComponent as IconMoney } from 'assets/icon-material-attach-money.svg';
import { ReactComponent as IconProfileType } from 'assets/icon-profile-type.svg';
import { ReactComponent as IconSkills } from 'assets/icon-skills.svg';

export const ROWS_MAPPER = [
    {
        ICON: <IconCandidateExperience />,
        TITLE: 'Experience',
        VALUE: 'experienceSince',
    },
    {
        ICON: <IconTaxResidency />,
        TITLE: 'Tax Residency',
        VALUE: 'countryOfTax',
    },
    {
        ICON: <IconCalendar />,
        TITLE: 'Availability',
        VALUE: 'earliestDate',
    },
    {
        ICON: <IconMoney />,
        TITLE: 'Salary Expectation',
        VALUE: 'salaryValue',
    },
    {
        ICON: <IconProfileType />,
        TITLE: 'Profile Type',
        VALUE: 'jobType'
    },
    {
        ICON: <IconSkills />,
        TITLE: 'Preferred Tech Stack',
        VALUE: 'techStack',
    },
];

export const REQUIRED_FIELDS = {
    experienceSince: ['experienceSince'],
    countryOfTax: ['countryOfTax'],
    earliestDate: ['earliestDate'],
    salaryValue: ['salaryValue', 'salaryCurrency'],
    jobType: ['jobType'],
};

export const POSITIVE_FIELDS = {
    salaryValue: ['salaryValue'],
};
