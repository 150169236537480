import React from 'react';
import { useIntl } from 'react-intl';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { ErrorMessage } from 'formik';

import './AddRecruiterFormView.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const AddRecruiterFormView = ({
    values,
    handleChange,
    errors,
    touched,
    accountManagers,
    countries,
}) => {
    const intl = useIntl();

    return (<form className="add-recruiter-form" autoComplete="off">
        <div className="recruiter-name-field">
            <TextField
                required
                name="name"
                value={values.name}
                onChange={handleChange}
                label={intl.formatMessage({ id: "name" })}
                error={errors.name && touched.name}
                margin="normal"
                variant="outlined"
                fullWidth
            />
            <ErrorMessage name="name" component="div" className="invalid-field-message" />
        </div>
        <div className="email-field">
            <TextField
                name="email"
                value={values.email}
                onChange={handleChange}
                label={intl.formatMessage({ id: "email" })}
                error={errors.email && touched.email}
                margin="normal"
                variant="outlined"
                fullWidth
            />
            <ErrorMessage name="email" component="div" className="invalid-field-message" />
        </div>
        <div className="country-field">
            <TextField
                select
                name="country"
                value={values.country}
                onChange={handleChange}
                label={intl.formatMessage({ id: "country" })}
                error={errors.country && touched.country}
                margin="normal"
                variant="outlined"
                fullWidth
            >
                {countries.map((country, i) => (
                    <MenuItem native="true" key={i} value={country.name}>
                        {intl.formatMessage({ id: convertStringToLanguageKey(country.name) })}
                    </MenuItem>
                ))}
            </TextField>
            <ErrorMessage name="country" component="div" className="invalid-field-message" />
        </div>
        <div className="contact-person-field">
            <TextField
                name="contactPerson"
                value={values.contactPerson}
                onChange={handleChange}
                label={intl.formatMessage({ id: "contact-person" })}
                error={errors.contactPerson && touched.contactPerson}
                margin="normal"
                variant="outlined"
                fullWidth
            />
            <ErrorMessage name="contactPerson" component="div" className="invalid-field-message" />
        </div>
        <div className="recruiter-final-block">
            <div className="contact-number-field">
                <TextField
                    name="contactNumber"
                    value={values.contactNumber}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "contact-phone" })}
                    error={errors.contactNumber && touched.contactNumber}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="contactNumber" component="div" className="invalid-field-message" />
            </div>
            <div className="account-manager-field">
                <TextField
                    select
                    required
                    name="accountManager"
                    value={values.accountManager}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "account-manager" })}
                    error={errors.accountManager && touched.accountManager}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                >
                    {accountManagers.map((accountManager, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={accountManager._id}
                        >
                            {`${accountManager.firstName} ${accountManager.lastName}`}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="accountManager" component="div" className="invalid-field-message" />
            </div>
        </div>
    </form>)
};


export default AddRecruiterFormView;
