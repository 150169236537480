import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import moment from 'moment';

import { getAgencyRouteName, getDisplayMode } from 'reducers';

import { ROLES } from 'constants/userConstants';
import { SHORT_MONTH_FULL_YEAR } from 'constants/commonConstants';

const CardDescription = ({
	project,
	displayMode,
	agencyRouteName,
	clientName,
	projectCurrency,
}) => {
	const intl = useIntl();
	
	const currMonthWithYear = moment().format(SHORT_MONTH_FULL_YEAR);
	const prevMonthWithYear = moment().subtract(1, 'months').format(SHORT_MONTH_FULL_YEAR);

	return (
		<div className="calendar-project-summary-container">
			{displayMode !== ROLES.CLIENT &&
				<p className="client-name-paragraph">
					{intl.formatMessage({ id: "client" })}: <Link to={`/${agencyRouteName}/clients/${project.clientId}`} className="client-name-link">{project.clientName || clientName}</Link>
				</p>
			}
			<p className="description-paragraph">{project.description}</p>

			{[ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE].includes(displayMode) ? null : <div className="outcome-spending-container">
				<div className="spending-item-wrapper">
					<div className="item-label">{intl.formatMessage({ id: "monthly-cost" })} ({currMonthWithYear}):</div>
					<div className="amount">
						{project.currentMonthMonthlySpending?.toFixed(2)} <span>{projectCurrency?.LABEL}</span>
					</div>
				</div>
				<div className="spending-item-wrapper">
					<div className="item-label">{intl.formatMessage({ id: "monthly-cost" })} ({prevMonthWithYear}):</div>
					<div className="amount">
						{project.previousMonthMonthlySpending?.toFixed(2)} <span>{projectCurrency?.LABEL}</span>
					</div>
				</div>
				<div className="spending-item-wrapper">
					<div className="item-label">{intl.formatMessage({ id: "total-project-cost" })}:</div>
					<div className="amount">
						{project.totalProjectCost?.toFixed(2)} <span>{projectCurrency?.LABEL}</span>
					</div>
				</div>

				{/* This code is commented due to task # 1369 */}
				{/* <div className="spending-item-wrapper">
                        <div className="item-label">Total spending</div>
                        <div className="amount"> <span>{projectCurrency.LABEL}</span> 12 900</div>
                    </div> */}
			</div>}
		</div>
	);
};

const mapStateToProps = (state) => ({
	agencyRouteName: getAgencyRouteName(state),
	displayMode: getDisplayMode(state),
});

export default connect(mapStateToProps, null)(CardDescription);
