import React from 'react';
import { useIntl } from 'react-intl';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { CANDIDATE_EXPERIENCE_OPTIONS } from 'components/CandidateProfileJourney/Steps/YourProfileWrapper/YourProfileConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import "./ExperienceDialog.scss"

const ExperienceDialog = ({
    onSubmit,
    handleSubmit,
    handleChange,
    values,
    onClose,
    open
}) => {
    const intl = useIntl();

    const onFormSubmit = () => {
        handleSubmit();
    }

    return (
        <form>
            <CommonFormikDialog
                open={open}
                onClose={onClose}
                className={"experience-dialog"}
                dialogTitle={intl.formatMessage({ id: "edit-experience" })}
                saveBtnText="save"
                discardBtnText="discard"
                onSave={onFormSubmit}
            >
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2">
                            <TextField
                                select
                                value={values.experienceSince}
                                variant="outlined"
                                onChange={handleChange}
                                name="experienceSince"
                                id="experienceSince"
                                fullWidth
                            >
                                {CANDIDATE_EXPERIENCE_OPTIONS.map((experienceOption) => {
                                    return (
                                        <MenuItem key={experienceOption.value} value={experienceOption.value}>
                                            {intl.formatMessage({ id: convertStringToLanguageKey(experienceOption.title) })}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                    </div>
                </div>
            </CommonFormikDialog>
        </form>
    );
}

export default ExperienceDialog;
