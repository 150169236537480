import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import './TablePaging.scss';

const DEFAULT_ROWS_OPTIONS = [10, 20, 50, 100];

const TablePaging = ({
    totalCount,
    fromPage,
    setFromPage,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    searchTerm,
    rowsOptions = DEFAULT_ROWS_OPTIONS,
}) => {
    const from = Math.min(fromPage * rowsPerPage + 1, totalCount);
    const to = Math.min(from + rowsPerPage - 1, totalCount)

    const moveToNextPage = () => {
        if (from + rowsPerPage - 1 < totalCount) {
            setFromPage(oldState => oldState + 1);

            if (setPage && !searchTerm) {
                setPage(oldState => oldState + 1);
            }
        }
    };

    const moveToPreviousPage = () => {
        if (fromPage > 0) {
            setFromPage(oldState => oldState - 1);

            if (setPage && !searchTerm) {
                setPage(oldState => oldState - 1);
            }
        }
    };

    const changeItemsPerPage = e => {
        setFromPage(0);
        setRowsPerPage(e.target.value);

        if (setPage) {
            setPage(0)
        }
    };

    return (
        <div className='table-footer-wrapper'>
            <div className='footer-paging-container'>
                <div className='rolls-per-page-container'>
                    <div className='rolls-per-page-title'>
                        <span>Rows per page:</span>
                    </div>
                    <div className='page-count-select'>
                        <Select
                            className='page-count-select-container'
                            disableUnderline={true}
                            onChange={changeItemsPerPage}
                            value={rowsPerPage}>
                            {rowsOptions.map(row =>
                                <MenuItem
                                    key={`page-count-select-item${row}`}
                                    style={{ fontSize: '14px' }}
                                    className='page-count-select-item'
                                    value={row}
                                >
                                    {row}
                                </MenuItem>
                            )}
                        </Select>
                    </div>
                </div>
                <div className='show-pages-container'>
                    <div className='pages-info'>
                        <span>{from} - {to} of {totalCount}</span>
                    </div>
                </div>
                <div className='arrows-container'>
                    <Button className='arrow-wrapper' onClick={moveToPreviousPage}>
                        <ArrowBackIosIcon className='arrow' />
                    </Button>
                    <Button className='arrow-wrapper right-arrow' onClick={moveToNextPage}>
                        <ArrowForwardIosIcon className='arrow' />
                    </Button>
                </div>
            </div>
        </div >
    );
}

export default TablePaging;
