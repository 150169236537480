import React from 'react';
import moment from 'moment';

import { Avatar, Paper } from '@material-ui/core';

import { FORMAT_TIMES_MAP } from 'constants/timesFormat';

import './SessionCard.scss';

const SessionCard = ({
    session,
    withHeaderBackground,
    title,
    subTitle,
    footerContent,
    className,
}) => (
    <Paper className={`session-card-wrapper ${className}`}>
        <div className={`header${withHeaderBackground ? ' with-background' : ''}`}>
            <h2 className="day">{moment(session.calendlySessionInfo.startDateTime).format('DD')}</h2>
            <p className="month">{moment(session.calendlySessionInfo.startDateTime).format('MMMM')}</p>
        </div>
        <div className="content">
            <Avatar src={session.coachingOpportunity.coachingOpportunityImgUrl} className="session-img" />
            <div className="session-info">
                <h2 className="title">{title}</h2>
                { subTitle && <h3 className="sub-title">{subTitle}</h3>}
            </div>
            <p className="time">{moment(session.calendlySessionInfo.startDateTime).format(FORMAT_TIMES_MAP.TIME)} - {moment(session.calendlySessionInfo.endDateTime).format(FORMAT_TIMES_MAP.TIME)}</p>
        </div>
        { footerContent &&
            <div className="footer">
                <h1>{footerContent}</h1>
            </div>
        }
    </Paper>
);

export default SessionCard;
