import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import MoreHorizSharpIcon from '@material-ui/icons/MoreHorizSharp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import './AdditionalMenu.scss';

const AdditionalMenu = ({
    handleLogout,
    isAdmin,
    switchRoleMode,
    handleGoToTCLink,
    modeText,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [dotsColor, setDotsColor] = useState('grey');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setDotsColor('blue');
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDotsColor('grey');
    };

    const handleSwitchRoleMode = () => {
        handleClose();
        switchRoleMode();
    };

    const getSwitchModeLanguageKey = () => modeText === "Employee" ? "additional-menu-switch-to-employee-mode" : "additional-menu-switch-to-admin-mode";

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : null;

    return (
        <div>
            <IconButton
                className={`additional-menu-btn-icon ${dotsColor}`}
                onClick={handleClick}
            >
                <MoreHorizSharpIcon />
            </IconButton>

            <Popover
                className="additional-menu-popover"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <List className="popover-list">
                    <ListItem button className="popover-list-item" onClick={handleGoToTCLink}>
                        <FormattedMessage id="additional-menu-terms-and-conditions">
                            {(msg) => <ListItemText primary={msg} className="list-item-text" />}
                        </FormattedMessage>
                    </ListItem>
                    <Divider />

                    {isAdmin &&
                        <>
                            <ListItem button className="popover-list-item" onClick={handleSwitchRoleMode}>
                                <FormattedMessage id={getSwitchModeLanguageKey()}>
                                    {(msg) => <ListItemText primary={msg} className="list-item-text" />}
                                </FormattedMessage>
                            </ListItem>
                            <Divider />
                        </>
                    }
                    <ListItem button className="popover-list-item" onClick={handleLogout}>
                        <ListItemIcon className="popover-list-icon">
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <FormattedMessage id="additional-menu-logout">
                            {(msg) => <ListItemText primary={msg} className="list-item-text" />}
                        </FormattedMessage>
                    </ListItem>
                </List>
            </Popover>
        </div>
    );
}

export default AdditionalMenu;
