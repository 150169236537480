import React, { useState, useRef } from 'react';
import PlayCircleFilledWhite from '@material-ui/icons/PlayCircleFilledWhite';

import './VideoPlayer.scss'

const VideoPlayer = ({ videoUrl }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showPlayButton, setShowPlayButton] = useState(true);
    const [showControls, setShowControls] = useState(false);

    const vidRef = useRef(null);

    const handlePlayVideo = () => {
        setIsPlaying(true);
        setShowPlayButton(false);
        setShowControls(true)
    }

    const handlePauseVideo = () => {
        vidRef.current.pause();
        setIsPlaying(false);
    }

    return (
        <div onClick={!isPlaying ? handlePlayVideo : handlePauseVideo} className="video-wrapper">
            <video ref={vidRef} className="video-player" controls={showControls}
                src={videoUrl}
            />
            <div className={`play-button-wrapper ${!showPlayButton ? 'hidden' : ''}`}>
                <div className="play-button-relative">
                    <div className="play-button-container">
                        <PlayCircleFilledWhite className="play-button" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoPlayer
