import React from 'react';

import CandidateJourneyNavigation from 'components/Common/CandidateJourneyNavigation';

import './CandidateJourneyLayout.scss';

const CandidateJourneyLayout = ({ children }) => {


    return (
        <div className="candidate-journey-layout-wrapper">
            <CandidateJourneyNavigation />

            <main className="candidate-journey-layout-body-wrapper">
                <div id="scroller" className="candidate-journey-layout-content">
                    {children}
                </div>
            </main>
        </div>
    );
};

export default CandidateJourneyLayout;
