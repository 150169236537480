import React from 'react';
import { Formik } from 'formik';

import {
    validateRequiredFields,
    validatePhoneNumber,
    validateEmailAddress,
    validateLinkedInURL,
    validateGitHubURL,
} from 'utils/formValidations';

const FormikTemplate = ({
    initialValues,
    updateData,
    requiredFields,
    emailFields,
    phoneField,
    gitHubField,
    linkedInField,
    children
}) => {

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...initialValues
            }}
            validate={(values) => {
                return {
                    ...validateRequiredFields(values, requiredFields),

                    ...validatePhoneNumber(values, phoneField),

                    ...validateEmailAddress(values, emailFields),

                    ...validateLinkedInURL(values, linkedInField),

                    ...validateGitHubURL(values, gitHubField)
                }
            }}
            onSubmit={(values) => {
                updateData(values);
            }}
        >
            {children}
        </Formik>
    );
};

export default FormikTemplate;
