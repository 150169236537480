import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAgencyId, getIndividualTraining, isAdminMode } from 'reducers';
import {
    fetchTraining,
} from 'actions/agencyActions';

import Topic from './Topic';
import SubmenuContentLayout from 'layouts/SubmenuContentLayout';

const Training = ({
    individualTraining,
    fetchTraining,
    agencyId,
    isAdmin,
    displayMode,
    match
}) => {
    const { trainingId } = match.params;

    useEffect(() => {
        fetchTraining(agencyId, trainingId);
    }, []);

    const menuItems = individualTraining.topics?.map(x => ({ text: x.title, path: `/topics/${x._id}` }));

    return (
        <SubmenuContentLayout
            path={match.url}
            items={Object.values(menuItems)}
            isWithProfileInfo={false}
            displayMode={displayMode}
        >
            <Switch>
                <Route path={`${match.path}/topics/:topicId`} render={props => (
                    <Topic
                        {...props}
                        isAdmin={isAdmin}
                        agencyId={agencyId}
                        topics={individualTraining.topics?.slice()} />
                )} />
            </Switch>
        </SubmenuContentLayout>
    )
};

const mapStateToProps = (state, ownProps) => ({
    agencyId: getAgencyId(state),
    individualTraining: getIndividualTraining(state, ownProps.match.params.trainingId),
    isAdmin: isAdminMode(state),
});

const mapDispatchToProps = {
    fetchTraining,
};

export default connect(mapStateToProps, mapDispatchToProps)(Training);
