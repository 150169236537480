
import reportsService from 'services/reportsService';
import { authorizedShowNotification } from './actionHelpers';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { REPORTS_MESSAGES } from 'constants/messageConstants';

import { showNotification } from './notificationActions';

import {
    REPORTS_PAGE_FETCH_SUCCESS,
    KPI_REPORT_FETCH_SUCCESS,
    KPI_REPORT_DATA_FETCH_SUCCESS,
    KPI_REPORT_DATA_FETCHING_SUCCESS,
    REPORTS_PAGE_DATA_FETCH_SUCCESS,
    REPORTS_PAGE_DATA_FETCHING_SUCCESS
} from 'actions/actionTypes';

import { ENVIRONMENTS } from 'constants/env';
import { HTTP_STATUS_CODES } from 'constants/httpStatusCodesConstants';

const fetchReportsPageSuccess = data => ({
    type: REPORTS_PAGE_FETCH_SUCCESS,
    payload: data
});

const fetchKpiReportSuccess = data => ({
    type: KPI_REPORT_FETCH_SUCCESS,
    payload: data,
})

const kpiReportDataFetchSuccess = () => ({
    type: KPI_REPORT_DATA_FETCH_SUCCESS
})

const kpiReportDataFetchingSuccess = () => ({
    type: KPI_REPORT_DATA_FETCHING_SUCCESS
})

const reportsPageDataFetchSuccess = () => ({
    type: REPORTS_PAGE_DATA_FETCH_SUCCESS
})

const reportsPageDataFetchingSuccess = () => ({
    type: REPORTS_PAGE_DATA_FETCHING_SUCCESS
})

export const fetchReportsPage = (agencyId, month) => async dispatch => {
    try {
        dispatch(reportsPageDataFetchingSuccess());
        const data = await reportsService.getReportsPage(agencyId, month);

        if (process.env.REACT_APP_ENV !== ENVIRONMENTS.PRODUCTION && process.env.REACT_APP_ENV !== ENVIRONMENTS.WHOISAVAILABLE) {
            console.log(data.cached ? 'Cached' : 'Not Cached');
        }

        dispatch(fetchReportsPageSuccess(data.reportsData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(REPORTS_MESSAGES.FETCH_REPORTS_PAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(reportsPageDataFetchSuccess());
    }
};

export const fetchKpiReportData = (agencyId) => async dispatch => {
    try {
        dispatch(kpiReportDataFetchingSuccess());

        const data = await reportsService.getKpiReportData(agencyId);

        dispatch(fetchKpiReportSuccess(data));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(REPORTS_MESSAGES.FETCH_REPORTS_PAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(kpiReportDataFetchSuccess());
    }
}

export const exportReportsPage = (agencyId, month, fileName) => async dispatch => {
    try {
        await reportsService.exportReportsPage(agencyId, month, fileName);

        dispatch(showNotification(REPORTS_MESSAGES.EXPORT_REPORTS_PAGE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(REPORTS_MESSAGES.FETCH_REPORTS_PAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportReportsTableRow = (agencyId, tableRowId, month, fileName) => async dispatch => {
    try {
        await reportsService.exportReportsTableRow(agencyId, tableRowId, month, fileName);

        dispatch(showNotification(REPORTS_MESSAGES.EXPORT_REPORTS_PAGE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        if (error.message.statusCode === HTTP_STATUS_CODES.NOT_FOUND) {
            dispatch(authorizedShowNotification(error)(error.message.message, NOTIFICATION_TYPES.WARNING));
        } else {
            dispatch(authorizedShowNotification(error)(REPORTS_MESSAGES.FETCH_REPORTS_PAGE_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
};
