import {
    MANAGED_EMPLOYEES_PENDING_HOLIDAYS_FETCH_SUCCESS,
    MANAGED_EMPLOYEES_PENDING_HOLIDAYS_REPLACE_SUCCESS,
    MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_FETCH_SUCCESS,
    MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_REPLACE_SUCCESS,
    MANAGED_EMPLOYEES_SICK_LEAVES_FETCH_SUCCESS,
    MANAGED_EMPLOYEES_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    MANAGED_EMPLOYEES_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    MANAGED_EMPLOYEES_APPROVED_HOLIDAYS_FETCH_SUCCESS,
    MANAGED_EMPLOYEE_HOLIDAY_CREATE_REQUESTS_SUCCESS,
    MANAGED_EMPLOYEES_HOLIDAYS_DELETE_REQUESTS_SUCCESS,
    MANAGED_EMPLOYEES_DOCUMENTS_FETCH_SUCCESS,
    MANAGED_EMPLOYEES_DOCUMENTS_REPLACE_SUCCESS,
} from 'actions/actionTypes';

import { HTTP_STATUS_CODES } from 'constants/httpStatusCodesConstants';
import { HOLIDAYS_MESSAGES, DOCUMENTS_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES, NOTIFICATION_CLICKAWAY } from 'constants/notificationTypes';
import { HOLIDAY_REQUEST_CONSTANTS } from 'constants/actionsConstants';

import { authorizedShowNotification } from 'actions/actionHelpers';
import {
    approvedHolidaysTableDataFetchSuccess,
    approvedHolidaysTableDataFetchingSuccess,
} from 'actions/agencyActions';
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';

import { showNotification } from 'actions/notificationActions';
import managerService from 'services/managerServices';

export const fetchManagerEmployeesPendingHolidaysSuccess = employees => ({
    type: MANAGED_EMPLOYEES_PENDING_HOLIDAYS_FETCH_SUCCESS,
    payload: employees
});

const replaceManagerEmployeesPendingHolidaysSuccess = employees => ({
    type: MANAGED_EMPLOYEES_PENDING_HOLIDAYS_REPLACE_SUCCESS,
    payload: employees
});

const fetchManagerEmployeesReviewedHolidaysSuccess = employees => ({
    type: MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_FETCH_SUCCESS,
    payload: employees
});

const replaceManagerEmployeesReviewedHolidaysSuccess = employees => ({
    type: MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_REPLACE_SUCCESS,
    payload: employees
});

const fetchManagerEmployeesSickLeavesSuccess = employees => ({
    type: MANAGED_EMPLOYEES_SICK_LEAVES_FETCH_SUCCESS,
    payload: employees
});

export const createEmployeeHolidaysRequestsSuccess = request => ({
    type: MANAGED_EMPLOYEE_HOLIDAY_CREATE_REQUESTS_SUCCESS,
    payload: request
});

const approveHolidaysRequestsSuccess = request => ({
    type: MANAGED_EMPLOYEES_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    payload: request
});

export const rejectHolidayRequestSuccess = request => ({
    type: MANAGED_EMPLOYEES_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    payload: request
});

export const deleteHolidayRequestsSuccess = request => ({
    type: MANAGED_EMPLOYEES_HOLIDAYS_DELETE_REQUESTS_SUCCESS,
    payload: request
});

const fetchManagerEmployeesApprovedHolidaysSuccess = (data) => ({
    type: MANAGED_EMPLOYEES_APPROVED_HOLIDAYS_FETCH_SUCCESS,
    payload: data
})

export const fetchManagersEmployeesPendingHolidays = (agencyId, managerId, query, replaceState) => async dispatch => {
    try {
        const result = await managerService.fetchEmployeesPendingHolidaysRequests(agencyId, managerId, query);
        if (replaceState) {
            dispatch(replaceManagerEmployeesPendingHolidaysSuccess(result.holidays));
        } else {
            dispatch(fetchManagerEmployeesPendingHolidaysSuccess(result.holidays));
        }
        return result.totalCount;
    } catch (error) {
        if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.FETCH_HOLIDAY_REQUESTS_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
};

export const fetchManagersEmployeesReviewedHolidays = (agencyId, managerId, query, replaceState) => async dispatch => {
    try {
        const result = await managerService.fetchEmployeesReviewedHolidaysRequests(agencyId, managerId, query);
        if (replaceState) {
            dispatch(replaceManagerEmployeesReviewedHolidaysSuccess(result.holidays));
        } else {
            dispatch(fetchManagerEmployeesReviewedHolidaysSuccess(result.holidays));
        }
        return result.totalCount;
    } catch (error) {
        if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.FETCH_HOLIDAY_REQUESTS_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
};

export const fetchManagersEmployeesApprovedHolidays = (agencyId, from, to, managerId) => async dispatch => {
    try {
        dispatch(approvedHolidaysTableDataFetchingSuccess());

        const managedEmployeesApprovedHolidays = await managerService.fetchManagersEmployeesApprovedHolidays(agencyId, from, to, managerId);

        dispatch(fetchManagerEmployeesApprovedHolidaysSuccess(managedEmployeesApprovedHolidays))
    } catch (error) {
        if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.FETCH_HOLIDAY_REQUESTS_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    } finally {
        dispatch(approvedHolidaysTableDataFetchSuccess());
    }
};

export const fetchManagersEmployeesSickLeaves = (agencyId, from, to, managerId) => async dispatch => {
    try {
        const managedEmployeesSickLeaves = await managerService.fetchEmployeesSickLeaves(agencyId, from, to, managerId);

        dispatch(fetchManagerEmployeesSickLeavesSuccess(managedEmployeesSickLeaves));
    } catch (error) {
        if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.FETCH_HOLIDAY_REQUESTS_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
};

export const approveManagersEmployeesHolidaysRequests = (agencyId, requests, from, to, managerId, updateState = true) => async dispatch => {
    const type = HOLIDAY_REQUEST_CONSTANTS.HOLIDAY_APPROVE_REQUEST_TYPE;
    const employeeIds = requests.map(x => x.employeeId);

    try {
        dispatch(showNotification(HOLIDAYS_MESSAGES.START_GENERATING_PDFS, NOTIFICATION_TYPES.INFO, null, NOTIFICATION_CLICKAWAY.DISABLED));

        const approvedHolidays = await managerService.updateEmployeesHolidaysRequests(agencyId, type, {
            requestIds: requests.map(x => x._id),
            employeeIds,
            from,
            to
        }, managerId);

        if (updateState) {
            dispatch(approveHolidaysRequestsSuccess(approvedHolidays.data));
        }

        if (approvedHolidays.errorMessage) {
            dispatch(showNotification(approvedHolidays.errorMessage, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(showNotification(HOLIDAYS_MESSAGES.PDFS_GENERATED_SUCCESS, NOTIFICATION_TYPES.SUCCESS, null, NOTIFICATION_CLICKAWAY.DISABLED));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const rejectManagersEmployeesHolidaysRequest = (agencyId, request, managerId, updateState = true) => async dispatch => {
    const type = HOLIDAY_REQUEST_CONSTANTS.HOLIDAY_REJECT_REQUEST_TYPE;
    const employeeIds = [request.employeeId];

    try {
        dispatch(showNotification(HOLIDAYS_MESSAGES.START_GENERATING_PDFS, NOTIFICATION_TYPES.INFO, null, NOTIFICATION_CLICKAWAY.DISABLED));

        const rejectedHolidays = await managerService.updateEmployeesHolidaysRequests(agencyId, type, {
            requestIds: [request._id],
            employeeIds,
            rejectionReason: request.rejectionReason
        }, managerId);

        if (updateState) {
            dispatch(rejectHolidayRequestSuccess(rejectedHolidays.data));
        }
        dispatch(showNotification(HOLIDAYS_MESSAGES.PDFS_GENERATED_SUCCESS, NOTIFICATION_TYPES.SUCCESS, null, NOTIFICATION_CLICKAWAY.DISABLED));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

const fetchManagerEmployeesDocumentsSuccess = data => ({
    type: MANAGED_EMPLOYEES_DOCUMENTS_FETCH_SUCCESS,
    payload: data,
});

const replaceManagerEmployeesDocumentsSuccess = data => ({
    type: MANAGED_EMPLOYEES_DOCUMENTS_REPLACE_SUCCESS,
    payload: data
});

export const fetchManagerEmployeesDocuments = (agencyId, managerId, query, resetState) => async dispatch => {
    try {
        const result = await managerService.fetchManagerEmployeesDocuments(agencyId, managerId, query);
        
        if (resetState) {
            dispatch(replaceManagerEmployeesDocumentsSuccess(result.contracts));
        } else {
            dispatch(fetchManagerEmployeesDocumentsSuccess(result.contracts));
        }

        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DOCUMENTS_MESSAGES.FETCH_DOCUMENTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportManagersEmployeesDocumentsData = (agencyId, documentsData, fileName, managerId) => async dispatch => {
    dispatch(showApplicationLoader());

    try {
        await managerService.exportManagersEmployeesDocumentsData(agencyId, managerId, documentsData, fileName);

        dispatch(showNotification(DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_DATA_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_DATA_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const downloadManagersEmployeesDocumentsFiles = (agencyId, documentsData, fileName, managerId, query) => async dispatch => {
    try {
        dispatch(showNotification(DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_DATA_STARTED, NOTIFICATION_TYPES.INFO));
        documentsData = documentsData.map(document => ({ _id: document._id, documentType: document.documentType, userId: document.userId }));

        await managerService.downloadManagersEmployeesDocumentsFiles(agencyId, managerId, documentsData, fileName, query);

        dispatch(showNotification(DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_FILES_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_FILES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

