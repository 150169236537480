import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { usePreloadTable } from 'utils/hooks';

import { getClientsAccountManagers, getClientsTableDataFetchState, getClients } from 'reducers';
import { updateClientFlag } from 'actions/clientActions';

import TableWrapper from 'components/Shared/TableWrapper/TableWrapper';
import ActiveInvoicingFlags from 'components/Clients/ActiveInvoicingFlags';

import Avatar from '@material-ui/core/Avatar';

import { getStateAgencyName } from 'utils/helpers';
import { convertStringToLanguageKey } from 'utils/languageUtils';

import logoImg from 'assets/default-logo.svg';
import { ReactComponent as ExportIcon } from 'assets/export-button-icon.svg';
import { ReactComponent as AddIcon } from 'assets/add-button-icon.svg';
import { DATE_PICKER_FORMAT_WITH_DASHES } from 'constants/timesFormat';
import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';
import {
    CLIENTS_TABLE_COLUMNS,
    DEFAULT_CLIENTS_TABLE_COLUMNS,
    CLIENTS_SORT_QUERY_SELECTOR,
    CLIENTS_PAGE_QUERY_SELECTOR,
    CLIENTS_TABLE_INITIAL_QUERY,
    CLIENTS_TABLE_QUERY_MAP,
    CLIENTS_SEARCH_QUERY_SELECTOR,
    TABLE_FILTERS,
    REGISTRATION_TYPE_MAPPER,
    REGISTRATION_TYPES,
    CLIENT_TABLE_LEGEND_ITEMS,
    EXPORT_FIELDS,
} from './ClientsTableConstants';

import './ClientsTable.scss';

const ClientsTable = ({
    handleOpenDialog,
    removeClientsClickHandler,
    exportClients,
    agencyId,
    uniqueAccountManagers,
    updateClientFlag,
    fetchData,
    reload,
    setReload,
}) => {
    const intl = useIntl();

    const clientsTableAction = fetchData.bind(null, agencyId);

    const [isHolidaysTableLoading, totalCount] = usePreloadTable(CLIENTS_TABLE_INITIAL_QUERY, CLIENTS_TABLE_QUERY_MAP, TABLE_FILTERS, clientsTableAction, reload, setReload);

    TABLE_FILTERS.find(x => x.key === 'accountManagerName').value = uniqueAccountManagers.map(x => x.name);

    const handleExportClients = () => {
        exportClients(agencyId, EXPORT_FIELDS, []);
    };

    const handleRemoveClient = (client) => {
        removeClientsClickHandler(client);
    }

    const adjustClientKeys = clients => {
        return clients?.map((client) => {
            const clientLink = `/${getStateAgencyName()}/clients/${client._id}`;
            const registrationType = client.registrationType ? REGISTRATION_TYPE_MAPPER[client.registrationType] : REGISTRATION_TYPE_MAPPER[REGISTRATION_TYPES.INVITED_BY_ADMIN];
            return {
                _id: client._id,
                name: (
                    <Link className="client-link" to={clientLink}>
                        <div className="logo-name-wrapper">
                            <Avatar
                                alt="Emplyoee Avatar"
                                className={`avatar-logo-image ${registrationType}`}
                                src={client.logoImgUrl ? client.logoImgUrl : logoImg}
                            />
                            <div className="name-text">
                                {client.name}
                            </div>
                        </div>
                    </Link>
                ),
                contactPerson: client.contactPerson,
                contactEmail: client.contactEmail
                    ? <a className="email" href={`mailto:${client.contactEmail}`}>{client.contactEmail}</a>
                    : 'N/A',
                contactNumber: client.contactNumber,
                accountManagerName: (
                    <div className="logo-name-wrapper">
                        {client.accountManager.profilePictureImgUrl &&
                            <Avatar
                                alt="Acc Manager Avatar"
                                className="avatar-logo-image"
                                src={client.accountManager.profilePictureImgUrl}
                            />
                        }
                        <div className="name-text">
                            {client.accountManager?.name}
                        </div>
                    </div>
                ),
                status: intl.formatMessage({ id: convertStringToLanguageKey(client.status) }),
                flag: <ActiveInvoicingFlags
                    flag={client.flag}
                    userId={client._id}
                    updateFlag={updateClientFlag}
                />,
                invoice: (
                    <div className='invoice-container'>
                        {client.invoice ? (
                            <>
                                <span className='invoice-info'>{moment(client.invoice.issueDate).format(DATE_PICKER_FORMAT_WITH_DASHES)}</span>
                                <span className='invoice-info'>({intl.formatMessage({ id: convertStringToLanguageKey(client.invoice.status) })})</span>
                            </>
                        ) : (
                            <span className='invoice-info'>{intl.formatMessage({ id: "no-invoices" })}</span>
                        )}
                    </div>
                ),
                item: client,
            };
        }) || [];
    }

    const allActions = [
        { title: intl.formatMessage({ id: "delete-client" }), handleClick: handleRemoveClient },
    ];

    const dataSelectorMapped = (state, page) => getClients(state, {}, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const getPopoverActions = () => allActions;

    return (
        <div className="clients-table-container">
            <TableWrapper
                title={intl.formatMessage({ id: "clients-list" })}
                columns={CLIENTS_TABLE_COLUMNS}
                defaultColumns={DEFAULT_CLIENTS_TABLE_COLUMNS}
                dataSelector={dataSelectorMapped}
                sortQuerySelector={CLIENTS_SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustClientKeys}
                isTableDataLoading={isHolidaysTableLoading}
                addMargin
                withPagination
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={CLIENTS_PAGE_QUERY_SELECTOR}
                legendItems={CLIENT_TABLE_LEGEND_ITEMS}
                withLegend
                withActions
                getActionsHandler={getPopoverActions}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableFilter filters={TABLE_FILTERS} />
                        <TableWrapper.TableAlignedItems.TableSearch queryParamName={CLIENTS_SEARCH_QUERY_SELECTOR} />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton text={intl.formatMessage({ id: "add-client-button-text" })} icon={<AddIcon />} handleClick={handleOpenDialog} />
                        <TableWrapper.TableAlignedItems.TableActionButton text={intl.formatMessage({ id: "export-button-text" })} icon={<ExportIcon />} handleClick={handleExportClients} />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>
        </div>
    );
};

const mapStateToProps = (state) => ({
    dataFetchState: getClientsTableDataFetchState(state),
    uniqueAccountManagers: getClientsAccountManagers(state),
});

const mapDispatchToProps = {
    updateClientFlag,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsTable);
