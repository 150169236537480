import React from 'react';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import { getStateAgencyName } from 'utils/helpers';
import { getResultClickRoute } from 'utils/searchUtils';

import { getDisplayMode, getToggleNavigation } from 'reducers';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Search from 'components/Shared/Search';
import { ROLES } from 'constants/userConstants';
import { REQUIRED_FIELDS } from './headerConstants';

import './Header.scss';

const Header = ({
    toggleNavigationValue,
    displayMode,
    isWithSubmenu,
}) => {
    const history = useHistory();

    //this logic also exists in the mobile header
    const handleResultClick = (hideDropdown, result) => {
        history.push(getResultClickRoute(displayMode, result));
        hideDropdown();
    };

    const handleSearchSeeAllResultsClick = (handleBlur, _, searchTerm) => {
        history.push(`/${getStateAgencyName()}/search-results`);
        handleBlur();
    };

    const hideSearchBar = displayMode === ROLES.SUPPLIER_EMPLOYEE || displayMode === ROLES.EMPLOYEE || displayMode === ROLES.CANDIDATE;

    return (
        <AppBar position="static" className={(toggleNavigationValue ? 'site-header shrink-nav' : 'site-header') + (isWithSubmenu ? ' with-submenu' : '')}>
            <Toolbar className={hideSearchBar ? 'toolbar-hidden' : ''}>
                <Search
                    handleClick={handleResultClick}
                    handleClickTitle={handleSearchSeeAllResultsClick}
                    requiredFields={REQUIRED_FIELDS}
                >
                    <Search.SearchDropdown dropdownTitle="See all results">
                        <Search.SearchDropdown.PeopleMenu compact={displayMode === ROLES.ADMIN} />
                        <Search.SearchDropdown.ClientsMenu compact={displayMode === ROLES.ADMIN} />
                        <Search.SearchDropdown.SupplierAdminMenu compact={displayMode === ROLES.ADMIN} />
                        <Search.SearchDropdown.SupplierEmployeesMenu compact={displayMode === ROLES.ADMIN} />
                        <Search.SearchDropdown.CandidatesMenu compact={displayMode === ROLES.ADMIN} />
                        <Search.SearchDropdown.TeamMenu compact={displayMode === ROLES.CLIENT} />
                        <Search.SearchDropdown.ApplicantsMenu compact={displayMode === ROLES.CLIENT} />
                        <Search.SearchDropdown.ProjectsMenu compact={displayMode === ROLES.ADMIN || displayMode === ROLES.CLIENT} />
                    </Search.SearchDropdown>
                </Search>
                <div className="helper-div" />
            </Toolbar>

        </AppBar>
    );
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
    toggleNavigationValue: getToggleNavigation(state),
});

export default connect(mapStateToProps)(Header);
