import React from 'react';
import { useIntl } from 'react-intl';

import { Tooltip } from '@material-ui/core';
import { ReactComponent as MatchingLocationIcon } from 'assets/icon-matching-location.svg';
import { ReactComponent as NonMatchingLocationIcon } from 'assets/icon-non-matching-location.svg';
import { ReactComponent as MissingLocationIcon } from 'assets/icon-missing-location.svg';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './LocationLabel.scss';

const LocationLabel = ({ isMatch, location }) => {
    const intl = useIntl();

    const getLabelInfo = () => {
        if (isMatch === true) {
            return {
                icon: <MatchingLocationIcon />,
                title: `${intl.formatMessage({ id: "matching-location" })} (${intl.formatMessage({ id: convertStringToLanguageKey(location) })})`
            };
        } else if (isMatch === false) {
            return {
                icon: <NonMatchingLocationIcon />,

                title: `${intl.formatMessage({ id: "not-matching-location" })} (${intl.formatMessage({ id: convertStringToLanguageKey(location) })})`
            };
        } else {
            return {
                icon: <MissingLocationIcon />,
                title: intl.formatMessage({ id: "missing-location" })
            };
        }
    }

    const { title, icon } = getLabelInfo();

    return (
        <Tooltip placement="bottom-end" classes={{ tooltip: 'custom-tooltip' }} title={title}>
            <div className="location-label-wrapper">
                {icon}
            </div>
        </Tooltip>
    );
};

export default LocationLabel;
