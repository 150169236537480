import React from 'react';
import { useIntl } from 'react-intl';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { ErrorMessage } from 'formik';

import './AddEmployeeProjectsCardFormView.scss';

const EmployeeFields = ({
    values,
    handleChange,
    errors,
    touched,
    projectCurrency,
    isClientInvoicingCompleted,
}) => {
    const intl = useIntl();

    return (
        <>
            <FormControl className="add-project-employee-field currency" variant="outlined" fullWidth>
                <InputLabel id="currency-label">{intl.formatMessage({ id: "currency" })}</InputLabel>
                <Select
                    labelId="currency-label"
                    id="person-currency"
                    name="currency"
                    value={projectCurrency.LABEL}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "currency" })}
                    error={errors.currency && touched.currency}
                    disabled={isClientInvoicingCompleted}
                >
                    <MenuItem value={projectCurrency.LABEL}>
                        {projectCurrency.LABEL}
                    </MenuItem>
                </Select>
                <ErrorMessage name="currency" component="div" className="invalid-field-message" />
            </FormControl>
            <div className="add-project-employee-field">
                <TextField
                    type="number"
                    name="hourRate"
                    value={values.hourRate}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "per-hour-rate" })}
                    error={errors.hourRate && touched.hourRate}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="hourRate" component="div" className="invalid-field-message" />
            </div>
        </>
    )
};

export default EmployeeFields;
