export const DATE_FORMAT = 'DD-MM-YYYY';
export const MONTH_FORMAT = 'MM-YYYY';
export const REQUESTED_HOLIDAY_TITLE = 'Requested holiday';
export const HOURS_INPUT_NAME = 'hours';
export const PROJECT_INPUT = 'project';
export const NEW_EVENT_INITIAL_HOURS = 8;

export const SICK_LEAVE_ID = '000000000000000000000000';
export const SICK_LEAVE_CLASS = 'sick-leave-select';
export const SICK_LEAVE = { _id: SICK_LEAVE_ID, name: "Sick Leave", isSickLeave: true };

export const BENCH_ACTIVITIES_ID = '000000000000000000000001';
export const BENCH_ACTIVITIES_CLASS = 'bench-activities-select';
export const BENCH_ACTIVITIES = { _id: BENCH_ACTIVITIES_ID, name: 'Bench Activities' };

export const MANDATORY_ADDITIONAL_PROJECTS = [
    { ...BENCH_ACTIVITIES, class: BENCH_ACTIVITIES_CLASS },
    { ...SICK_LEAVE, class: SICK_LEAVE_CLASS },
];

export const EXPORT_EXCEPTIONS = [SICK_LEAVE_ID];

export const HOLIDAY_TYPE = {
    PAID: 'Paid',
    UNPAID: 'Unpaid'
};
