import styleConstants from 'style-constants.scss';

export const REFERRAL_CAMPAIGN_COLORS = {
  GoogleAdCampaign: 'red-darker',
  LinkedInPaidJobPost: 'primary-color-700',
  WebsiteOrganic: 'grey-dark-text',
  'Self-registered': 'orange',
  'Invited by admin': 'pink-light',
};

export const REFERRAL_CAMPAIGN_COLORS_HEX = {
  GoogleAdCampaign: styleConstants.redDarker,
  LinkedInPaidJobPost: styleConstants.primaryColor,
  WebsiteOrganic: styleConstants.greyDarkText,
  'Self-registered': styleConstants.orange,
  'Invited by admin': styleConstants.pinkLight,
};
