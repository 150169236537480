import React from "react";

import { BENCH_ACTIVITIES_ID } from 'components/Person/TimeTracking/TimeTrackingConstants';

const CalendarEvent = ({ event }) => {
    const holidayClass = event.isHoliday ? 'holiday' : '';
    const sickLeaveClass = event?.project?.isSickLeave ? 'sick-leave' : '';
    const benchActivitiesClass = event?.project?._id === BENCH_ACTIVITIES_ID ? 'bench-activities' : '';

    return (
        <div className={`event-container ${holidayClass} ${sickLeaveClass} ${benchActivitiesClass}`}>
            <span>{ event?.project?.name || event.title}</span>
            {event.hours && <span>{event.hours}h</span>}
        </div>
    )
};

export default CalendarEvent;
