import React from 'react';

import { ROLES } from 'constants/userConstants';

import Typography from '@material-ui/core/Typography';
import { ReactComponent as ExploreTalentImage } from 'assets/clientOnboarding/explore-talents-on-demand.svg';
import { ReactComponent as TeamRequestImage } from 'assets/clientOnboarding/custom-team-requests.svg';
import { ReactComponent as ProjectsImage } from 'assets/clientOnboarding/projects.svg';
import { ReactComponent as BillingImage } from 'assets/clientOnboarding/billing-and-feedback.svg';
import { ReactComponent as CreateEmployeeProfileBanner } from 'assets/onboardingScreens/create-employee-profile-banner.svg';
import { ReactComponent as BillingSupplierBanner } from 'assets/onboardingScreens/billing-supplier-banner.svg';
import { ReactComponent as UpdateContactInfoBanner } from 'assets/onboardingScreens/update-contact-info-banner.svg';
import { ReactComponent as UpdateCvBanner } from 'assets/onboardingScreens/update-cv-banner.svg';
import { ReactComponent as TrackTimeBanner } from 'assets/onboardingScreens/track-time-banner.svg';
import { ReactComponent as RequestHolidaysBanner } from 'assets/onboardingScreens/request-holidays-banner.svg';
import { ReactComponent as AccessContractsBanner } from 'assets/onboardingScreens/access-contracts-banner.svg';
import { ReactComponent as JobOpportunities } from 'assets/onboardingScreens/job-opportunities.svg';
import { ReactComponent as RecruitmentProcessBanner } from 'assets/onboardingScreens/recruitment-process-banner.svg';
import { ReactComponent as MyApplicationsBanner } from 'assets/onboardingScreens/my-applications-banner.svg';
import { ReactComponent as CreateEmployeeProfile } from 'assets/clientOnboarding/create-employee-profile.svg';
import { ReactComponent as CreateEmployeeProfileBilling } from 'assets/clientOnboarding/Billing.svg';


const EMPLOYEE_STEPS = [
    {
        content: <div className="onboarding-banner-content update-contact-info-banner-content">
            <Typography className="banner-header" variant="h4">Update Contact Info</Typography>
            <Typography className="banner-text">Your personal and contact information will help your team find out more about you or reach you when needed.</Typography>
        </div>,
        banner: <UpdateContactInfoBanner className="onboarding-screen-svg update-contact-info-banner" />
    },
    {
        content: <div className="onboarding-banner-content update-cv-banner-content">
            <Typography className="banner-header" variant="h4">Update CV</Typography>
            <Typography className="banner-text">Create a beautiful CV profile by adding all your skills, past experience and education with full flexibility. Make sure to keep this up to date when you learn new skills and get certified.</Typography>
        </div>,
        banner: <UpdateCvBanner className="onboarding-screen-svg update-cv--banner" />
    },
    {
        content: <div className="onboarding-banner-content track-time-banner-content">
            <Typography className="banner-header" variant="h4">Track Time</Typography>
            <Typography className="banner-text">Complete all your timesheets digitally or notify us when you're on a sick leave. If you're starting a new project, please ask your Direct Manager to allow time tracking for you.</Typography>
        </div>,
        banner: <TrackTimeBanner className="onboarding-screen-svg track-time-banner" />
    },
    {
        content: <div className="onboarding-banner-content request-holidays-content">
            <Typography className="banner-header" variant="h4">Request Holidays</Typography>
            <Typography className="banner-text">Use the system to request your paid time off. In order to sign your requests digitally you need to download and register to the the Evrotrust app (iOS and Android) and update your email on the Employment Information screen.</Typography>
            <a href='https://play.google.com/store/apps/details?id=com.evrotrust.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img className="evrotrust-android-img" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
            </a>
            <a href="https://apps.apple.com/us/app/evrotrust/id1195807317?itsct=apps_box&amp;itscg=30200" >
                <img className="evrotrust-ios-img" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1507593600&h=f299de19c8f3cd38b93fdaba80218f71" />
            </a>
        </div>,
        banner: <RequestHolidaysBanner className="onboarding-screen-svg request-holidays-banner" />
    },
    {
        content: <div className="onboarding-banner-content access-contracts-banner-content">
            <Typography className="banner-header" variant="h4">Access Contracts</Typography>
            <Typography className="banner-text">The platform allows you to access all your contracts and employment related documents from a single place.</Typography>
        </div>,
        banner: <AccessContractsBanner className="onboarding-screen-svg access-contracts-banner" />
    },
];

export const getOnboardingSteps = (role, agencyName) => {
    switch (role) {
        case ROLES.CANDIDATE:
            return {
                steps: [
                    // {
                    //     content: <div className="onboarding-banner-content update-contact-info-banner-content">
                    //         <Typography className="banner-header" variant="h4">Setup your Profile CV</Typography>
                    //         <Typography className="banner-text">Add your contact information, so we can reach out to you with hot job opportunities. We will never share this with anyone else and you can always ask us to delete any personal information we store about you.</Typography>
                    //         <Typography className="banner-text">Use the platform to easily create a beautiful CV profile and showcase your skills to the selected employers of your choice. We'll give you the option to import your LinkedIn profile information to make this super easy.</Typography>
                    //     </div>,
                    //     banner: <SetProfileBanner className="onboarding-screen-svg update-contact-info-banner" />
                    // },
                    {
                        content: <div className="onboarding-banner-content update-cv-banner-content">
                            <Typography className="banner-header" variant="h4">Customise Your Job Preferences</Typography>
                            <Typography className="banner-text">Select the types of job opportunities you would like us to match you with based on your dream remote job. Provide details about the area you want to develop in and what types of technologies you want to use.</Typography>
                        </div>,
                        banner: <MyApplicationsBanner className="onboarding-screen-svg request-holidays-banner" />
                    },
                    {
                        content: <div className="onboarding-banner-content track-time-banner-content">
                            <Typography className="banner-header" variant="h4">Complete Virtual Pre-screening Interview</Typography>
                            <Typography className="banner-text">We understand both remote candidates and companies value flexibility. Use our virtual interview module to record short video responses to our pre-screening questions from the convenience of your home or office. You have a full control over which employers can watch your videos as part of your applications.</Typography>
                        </div>,
                        banner: <RecruitmentProcessBanner className="onboarding-screen-svg track-time-banner" />
                    },
                    {
                        content: <div className="onboarding-banner-content request-holidays-content">
                            <Typography className="banner-header" variant="h4">Job Opportunities</Typography>
                            <Typography className="banner-text banner-text-cut">Explore the hottest remote job opportunities from our community of tech companies and choose an employment model that suits you. Work as a {agencyName} consultant on a variety of fixed term projects or apply for a permanent position with an employer of your choice. Whichever option you choose, you will receive the full {agencyName} premium benefits package.</Typography>

                        </div>,
                        banner: <JobOpportunities className="onboarding-screen-svg request-holidays-banner" />
                    }
                ]
            }
        case ROLES.SUPPLIER_ADMIN:
            return {
                steps: [
                    {
                        content: <div className="onboarding-banner-content create-employee-profile-banner-content">
                            <Typography className="banner-header" variant="h4">Create Employee Profile</Typography>
                            <Typography className="banner-text">Easily create CV profiles of your available consultants and our team </Typography>
                            <Typography className="banner-text">will match them with job opportunities from our clients.</Typography>
                            <Typography className="banner-text">Always get notified by email for our new job opportunities,</Typography>
                            <Typography className="banner-text"> send us profiles and follow the hiring process.</Typography>
                            <CreateEmployeeProfile  className="banner-content-image" />
                        </div>,
                        banner: <CreateEmployeeProfileBanner className="onboarding-screen-svg create-employee-profile-banner" />
                    },
                    {
                        content: <div className="onboarding-banner-content billing-supplier-banner-content">
                            <Typography className="banner-header" variant="h4">Billing</Typography>
                            <Typography className="banner-text">Use our platform to generate and send us</Typography>
                            <Typography className="banner-text">invoices for your ongoing projects. </Typography>
                            <Typography className="banner-text">Follow each invoice payment status and download </Typography>
                            <Typography className="banner-text">timesheet reports for all your active consultants.</Typography>
                            <CreateEmployeeProfileBilling  className="banner-content-image" />
                        </div>,
                        banner: <BillingSupplierBanner className="onboarding-screen-svg billing-supplier-banner" />
                    }
                ]
            }
        case ROLES.CLIENT:
            return {
                steps: [
                    {
                        content: <div className="onboarding-banner-content explore-talent-banner-content">
                            <Typography className="banner-header" variant="h4">Explore available talent on demand</Typography>
                            <Typography className="banner-text">Browse through hundreds of vetted profiles of engineers, designers and other IT specialists. Use the filters to find your perfect match quickly and send us an interview request straight into the platform.</Typography>
                            <ExploreTalentImage className="banner-image" />
                        </div>
                    },
                    {
                        content: <div className="onboarding-banner-content team-request-banner-content">
                            <Typography className="banner-header" variant="h4">Custom Team Request</Typography>
                            <Typography className="banner-text">If you haven't found your dream team member in our database, WhoIsAvailable allows you to send us a custom request whether you are looking for talent on demand or you want to establish your own dedicated software team.</Typography>
                            <TeamRequestImage className="banner-image" />
                        </div>
                    },
                    {
                        content: <div className="onboarding-banner-content projects-banner-content">
                            <Typography className="banner-header" variant="h4">Projects</Typography>
                            <Typography className="banner-text">Get a complete overview of your operations and monitor your projects, team allocation and budged expenditure via the platform.</Typography>
                            <ProjectsImage className="banner-image" />
                        </div>
                    },
                    {
                        content: <div className="onboarding-banner-content billing-banner-content">
                            <Typography className="banner-header" variant="h4">Billing and Feedback</Typography>
                            <Typography className="banner-text">Receive all your invoices and consultant timesheets via automated email notifications, leave regular feedback for your teams or individual team members.</Typography>
                            <BillingImage className="banner-image" />
                        </div>
                    },
                ]
            }
        default:
            return { steps: EMPLOYEE_STEPS };
    }
}

const employeeWelcomeScreen = (agencyName) => {
    return <div>
        <Typography className="banner-text">Thank you for joining our global community of tech professionals and software companies.</Typography>
        <Typography className="banner-text">We have prepared a short tutorial that will help you get started with {agencyName}.</Typography>
        <Typography className="banner-text">Click below to explore all important features that we've got you covered on.</Typography>
    </div >
}

export const getWelcomeScreen = (role, agencyName) => {
    switch (role) {
        case ROLES.CLIENT:
            return <div>
                <Typography className="banner-text">Thank you for joining our global community of tech professionals and software companies.
                    We prepared a short tutorial that will help you get started with WhoIsAvailable.
                    Click below to explore all important features that we've got you covered on.</Typography>
            </div >
        default:
            return employeeWelcomeScreen(agencyName)
    }
};
