import React from 'react';

import { Typography } from '@material-ui/core';

import './ContractInfoBanner.scss';

const ContractInfoBanner = () => (
    <div className="contract-info-banner-wrapper">
        <Typography variant="body1" className="bold-text">**Important Message**</Typography>
        <Typography variant="body1">
            We wanted to provide a quick clarification: (Require e-Signature from client, Require e-Signature from Goddard Co, Require e-Signature from both Goddard Co and client) signing is not part of the trial experience.
        </Typography>
        <Typography variant="body1">
            However, should you decide to continue with our service after the trial, electronic contract signing is available as an added feature. It's a seamless process designed for your convenience.
        </Typography>
    </div>
);

export default ContractInfoBanner;
