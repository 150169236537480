import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import { resetPassword } from 'actions/authActions';

import ProfileCreationCard from 'components/Shared/ProfileCreationCard';
import PasswordResetFormView from './PasswordResetFormView';

import {
    validateRequiredFields,
    validatePasswordRequirements,
    validatePasswordEquality
} from 'utils/formValidations';

const PasswordReset = ({
    resetPassword
}) => {
    const history = useHistory();
    const { actionCode } = history?.location?.state || {};

    return (
        <ProfileCreationCard>
            <ProfileCreationCard.Header />
            <ProfileCreationCard.ContentWrapper>
                <ProfileCreationCard.ContentTitle className="normal-text">
                    Reset your password
                </ProfileCreationCard.ContentTitle>
                <ProfileCreationCard.ContentDivider />
                <Formik
                    initialValues={{
                        newPassword: '',
                        confirmPassword: ''
                    }}
                    validate={values => ({
                        ...validatePasswordRequirements(values, 'newPassword'),
                        ...validatePasswordEquality(values, 'newPassword', 'confirmPassword'),
                        ...validateRequiredFields(values, ['newPassword', 'confirmPassword'])
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        resetPassword(actionCode, values.newPassword)
                            .then(() => {
                                history.push("/login");
                            })
                    }}
                >
                    {(props) => <PasswordResetFormView {...props} />}
                </Formik>
            </ProfileCreationCard.ContentWrapper>
        </ProfileCreationCard>
    )
};

const mapDispatchToProps = {
    resetPassword
};

export default connect(null, mapDispatchToProps)(PasswordReset);
