import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getDepartments } from 'reducers';
import {
    fetchDepartments,
    createDepartment,
    editDepartment,
    deleteDepartment
} from 'actions/departmentActions';

import Typography from '@material-ui/core/Typography';
import TableWrapper from 'components/Shared/TableWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import DepartmentDialog from './DepartmentDialog/DepartmentDialog';

import { ReactComponent as AddButtonIcon } from 'assets/add-button-icon.svg';

import {
    DEPARTMENTS_TABLE_COLUMNS,
    DEFAULT_DEPARTMENTS_TABLE_COLUMNS,
    DEPARTMENTS_TABLE_SORT_QUERY_SELECTOR,
    DEPARTMENTS_TABLE_PAGE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    INITIAL_QUERY,
    QUERY_PARAMS_MAP
} from './DepartmentsTableConstants';

import { usePreloadTable } from 'utils/hooks';

const DepartmentsTable = ({
    agencyId,
    fetchDepartments,
    createDepartment,
    editDepartment,
    deleteDepartment,
}) => {
    const intl = useIntl();

    const [openDepartmentDialog, setOpenDepartmentDialog] = useState(false);
    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
    const [targetDepartment, setTargetDepartment] = useState({});

    const tableFetchAction = fetchDepartments.bind(null, agencyId);
    const dataSelector = (state, page) => getDepartments(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const [reload, setReload] = useState(false);
    const [isTableDataLoading, totalCount] = usePreloadTable(INITIAL_QUERY, QUERY_PARAMS_MAP, null, tableFetchAction, reload, setReload);

    const handleOpenDepartmentDialog = () => setOpenDepartmentDialog(true);

    const handleCloseDepartmentDialog = () => {
        setOpenDepartmentDialog(false);
        setTargetDepartment({});
    }

    const handleCloseConfirmRemoveDialog = () => {
        setOpenConfirmRemoveDialog(false);
        setTargetDepartment({});
    };

    const handleDepartmentDelete = async () => {
        await deleteDepartment(agencyId, targetDepartment._id, false);
        setReload(true);
        setOpenConfirmRemoveDialog(false);
    };

    const editDepartmentClickHandler = department => {
        setTargetDepartment(department);
        handleOpenDepartmentDialog();
    }

    const deleteDepartmentClickHandler = department => {
        setTargetDepartment(department);
        setOpenConfirmRemoveDialog(true);
    };

    const handleSubmitDialog = async departmentData => {
        departmentData.members = departmentData.members.map(x => x._id);
        departmentData.managers = departmentData.managers.map(x => x._id);

        if (!!targetDepartment._id) {
            await editDepartment(agencyId, targetDepartment._id, departmentData, false);
        } else {
            await createDepartment(agencyId, departmentData, false);
        }
        setReload(true);
        handleCloseDepartmentDialog();
    };

    const adjustDepartmentsKeys = (stateData) => stateData.map(department => {
        return {
            name: department.name,
            admins: department.admins?.length > 0
                ? department.admins.map((admin, index) =>
                    <Typography
                        variant="body1"
                        key={index}
                    >
                        {admin}
                    </Typography>)
                : 'N/A',
            membersCount: department.membersCount,
            item: department,
        };
    });

    const actions = [
        { title: intl.formatMessage({ id: "edit" }), handleClick: editDepartmentClickHandler },
        { title: intl.formatMessage({ id: "delete" }), handleClick: deleteDepartmentClickHandler }
    ];

    const getPopoverActions = () => actions;

    return (
        <div className="departments-table-container">
            <TableWrapper
                title={intl.formatMessage({ id: "departments" })}
                columns={DEPARTMENTS_TABLE_COLUMNS}
                defaultColumns={DEFAULT_DEPARTMENTS_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={DEPARTMENTS_TABLE_SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustDepartmentsKeys}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withPagination
                withActions
                getActionsHandler={getPopoverActions}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={DEPARTMENTS_TABLE_PAGE_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton
                            text={intl.formatMessage({ id: "add-department" })}
                            icon={<AddButtonIcon />}
                            handleClick={handleOpenDepartmentDialog}
                        />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>

            <DepartmentDialog
                isOpen={openDepartmentDialog}
                departmentId={targetDepartment._id}
                agencyId={agencyId}
                handleCloseDialog={handleCloseDepartmentDialog}
                handleSubmitDialog={handleSubmitDialog}
                isForEdit={!!targetDepartment._id}
            />

            <ConfirmDeleteDialog
                itemToDelete="this department"
                handleDeleteItem={() => handleDepartmentDelete()}
                openDialog={openConfirmRemoveDialog}
                handleCloseDialog={handleCloseConfirmRemoveDialog}
            />
        </div>
    )
};

const mapDispatchToProps = {
    fetchDepartments,
    createDepartment,
    editDepartment,
    deleteDepartment,
};

export default connect(null, mapDispatchToProps)(DepartmentsTable);
