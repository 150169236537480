import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import AddSupplierFormView from './AddSupplierFormView';

import { getAdmins } from 'reducers';
import { resetEmployeeInformationStates } from 'actions/sectionsLoadersActions';
import { fetchAdmins } from 'actions/agencyActions';

import { maxLength } from './AddSupplierDialogConstants';
import { ROLES } from 'constants/userConstants';

import {
    validateRequiredFields,
    validateEmailAddress,
    validateInputLength,
} from 'utils/formValidations';

const AddSupplierDialog = ({
    openDialog,
    agencyId,
    handleCloseDialog,
    handleSubmitClick,
    accountManagers,
    fetchAdmins,
    resetEmployeeInformationStates,
}) => {
    const intl = useIntl();

    useEffect(() => {
        fetchAdmins(agencyId);
        return resetEmployeeInformationStates;
    }, []);

    return (
        <Formik
            initialValues={{
                name: '',
                contactPerson: '',
                email: '',
                accountManager: '',
                invoiceStatus: '',
            }}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['name', 'accountManager', 'contactPerson', 'invoiceStatus']),
                    ...validateInputLength(values, [
                        { property: 'name', maxLength },
                        { property: 'contactPerson', maxLength },
                        { property: 'email', maxLength },
                    ]),
                    ...validateEmailAddress(values, 'email'),
                }
            }}
            onSubmit={(values) => {
                values.name = values.name.trim();
                values.email = values.email.toLowerCase().trim();
                values.contactPerson = values.contactPerson.trim();
                handleSubmitClick(values, ROLES.SUPPLIER_ONLY);
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    open={openDialog}
                    dialogTitle={intl.formatMessage({ id: "add-partner-button-text" })}
                    onClose={handleCloseDialog}
                    onSave={props.handleSubmit}
                    saveBtnText="Save"
                    discardBtnText="Discard"
                    size="md"
                >
                    <AddSupplierFormView
                        {...props}
                        agencyId={agencyId}
                        handleCloseDialog={handleCloseDialog}
                        accountManagers={accountManagers}

                    />
                </CommonFormikDialog>
            }
        </Formik>
    )
}

const mapStateToProps = (state, props) => ({
    accountManagers: getAdmins(state).slice().sort(props.sortAlphabetically),
});

const mapDispatchToProps = {
    fetchAdmins,
    resetEmployeeInformationStates
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSupplierDialog);
