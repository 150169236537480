import { useContext } from 'react';

import { MatchingJobCardContext } from '../MatchingJobCard';

import JobCandidatesStatusBox from 'components/JobsDashboard/SharedComponents/JobCandidatesStatusBox';

const MatchingJobProfiles = () => {
    const { job } = useContext(MatchingJobCardContext);

    return (
        <div className="job-profiles">
            <div className="total-profiles-count">
                <span className="total-profiles-count-number">{job.profilesCount}</span>
            </div>

            <div className="candidates-statuses-count-boxes">
                {job.rejectedApplicationsCount > 0 &&
                    <JobCandidatesStatusBox
                        classType="rejected-applications"
                        count={job.rejectedApplicationsCount}
                    />
                }
                {job.approvedApplicationsCount > 0 &&
                    <JobCandidatesStatusBox
                        classType="approved-applications"
                        count={job.approvedApplicationsCount}
                    />
                }
                {job.pendingApplicationsCount > 0 &&
                    <JobCandidatesStatusBox
                        classType="pending-applications"
                        count={job.pendingApplicationsCount}
                    />
                }
                {job.pendingAdminReviewApplicationsCount > 0 &&
                    <JobCandidatesStatusBox
                        classType="pending-admin-applications"
                        count={job.pendingAdminReviewApplicationsCount}
                    />
                }
                {job.notVettedApplicationsCount > 0 &&
                    <JobCandidatesStatusBox
                        classType="not-vetted-applications"
                        count={job.notVettedApplicationsCount}
                    />
                }
                {job.newApplicationsCount > 0 &&
                    <JobCandidatesStatusBox
                        classType="new-applications"
                        count={job.newApplicationsCount}
                    />
                }
            </div>
        </div>
    );
};

export default MatchingJobProfiles;
