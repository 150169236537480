import React from 'react';

const PaperContent = ({
        classes=[], 
        children
    }) => {
        return (
            <div className={`paper-element-content ${classes.join(" ")}`}>
                {children}
            </div>
        );
    }

export default PaperContent;
