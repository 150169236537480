import React, { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import { isEqual } from 'lodash';

import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';
import SelectionCard from '../SelectionCard';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import Switcher from 'components/Shared/Switcher';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { CANDIDATE_EXPERIENCE_OPTIONS, JOB_TYPES } from '../YourProfileConstants';

import { getJobOpportunitiesTechnologies } from 'reducers';

import { getSelectionCardSubTitle } from './ExperienceSelectionUtils';
import { validateRequiredFields, validatesArrayLength } from 'utils/formValidations';

import './ExperienceSelection.scss';

const JobTypeSelection = ({
    handleContinueJourney,
    agencyId,
    userId,
    candidatePreferences,
    jobsTechnologies,
    updateCandidatePreferences,
}) => {
    const [errorClassName, setErrorClassName] = useState('');
    const [showToolTip, setShowToolTip] = useState(false);
    const isDeveloper = candidatePreferences.jobType === JOB_TYPES.DEVELOPER;

    const experienceSinceRef = useRef(null);

    const initialValues = isDeveloper
        ? {
            experienceSince: candidatePreferences.experienceSince || '',
            technologies: candidatePreferences.technologies || [],
            isWithTechStack: candidatePreferences.isWithTechStack,
        }
        : { experienceSince: candidatePreferences.experienceSince || '' };

    const handleSkillClick = (setFieldValue, values, currentTechnology) => {
        if (!values.isWithTechStack) {
            return;
        }

        if (values.technologies.find(x => x._id === currentTechnology._id)) {
            setFieldValue('technologies', values.technologies.filter(x => x._id !== currentTechnology._id))
        } else {
            setFieldValue('technologies', [...values.technologies, { _id: currentTechnology._id, title: currentTechnology.name }])
        }
    };

    const getAdditionalClasses = (values, technology) => {
        const additionalClasses = [];

        if (!isDeveloper || !values.technologies) {
            return;
        }

        if (values.technologies.find(x => x._id === technology._id)) {
            additionalClasses.push('selected');
        }

        if (values.isWithTechStack) {
            additionalClasses.push('with-tech-stack');
        } else {
            additionalClasses.push('without-tech-stack')
        }

        return additionalClasses.join(' ');
    };

    const handleContinueClick = ({ errors, touched, handleSubmit, validateForm }) => {
        validateForm().then(formErrors => {
            if (formErrors.experienceSince) {
                experienceSinceRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        });


        if (Object.values(errors).length > 0 && Object.values(touched).length > 0) {
            setErrorClassName('transition-error');
            setTimeout(() => setErrorClassName('animation-error'), 10);
        }

        handleSubmit();
    };

    return (
        <div className="experience-selection-wrapper">
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={(values) => {
                    let validationsObject = { ...validateRequiredFields(values, ['experienceSince']) };

                    // there should not be a validation if we do not have technologies at all
                    const technologiesMinLength = jobsTechnologies.length > 0 ? 1 : 0;

                    if (values.isWithTechStack && isDeveloper) {
                        validationsObject = {
                            ...validationsObject,
                            ...validatesArrayLength(values, 'technologies', technologiesMinLength)
                        }
                    };

                    return validationsObject;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    if (!values.isWithTechStack && isDeveloper) {
                        values.technologies = [];
                        initialValues.technologies = [];
                    }

                    const isDirty = !isEqual(values, initialValues);
                    const action = updateCandidatePreferences.bind(null, agencyId, userId, values);

                    handleContinueJourney(null, isDirty, action);
                }}
            >
                {(props) => (
                    <>
                        <div ref={experienceSinceRef}>
                            <SelectionCard
                                {...props}
                                title="Tell us about your experience"
                                subTitle={getSelectionCardSubTitle(candidatePreferences.jobType)}
                                options={CANDIDATE_EXPERIENCE_OPTIONS}
                                name="experienceSince"
                                errorClassName={errorClassName}
                            />
                        </div>
                        {(isDeveloper && jobsTechnologies.length > 0) &&
                            <PaperElement classes={["tech-stack-options-wrapper", isMobile ? 'max' : 'sm']}>
                                <PaperHeader classes={["tech-stack-options-title"]} title="What is your preferred tech stack?" />
                                <PaperContent classes={[`tech-stack-options  ${(props.errors.technologies && props.touched.technologies ? errorClassName || 'error' : '')}`]}>
                                    {jobsTechnologies.map((technology, index) => (
                                        <div
                                            key={index}
                                            className={`single-technology-wrapper ${getAdditionalClasses(props.values, technology)}`}
                                            onClick={handleSkillClick.bind(null, props.setFieldValue, props.values, technology)}
                                        >
                                            <span data-content={technology.name} className="technology-name">{technology.name}</span>
                                        </div>
                                    ))}
                                </PaperContent>
                                <div className="switcher-wrapper">
                                    <Switcher
                                        name="isWithTechStack"
                                        value={props.values.isWithTechStack}
                                        className="switcher"
                                        label="None of the above"
                                        isChecked={!props.values.isWithTechStack}
                                        changeStatusHandler={() => props.setFieldValue('isWithTechStack', !props.values.isWithTechStack)}
                                    />

                                    <InfoOutlinedIcon
                                        className="global-information-icon"
                                        onMouseEnter={() => setShowToolTip(true)}
                                        onMouseLeave={() => setShowToolTip(false)}
                                    />
                                    {showToolTip &&
                                        <div className="switcher-tooltip">
                                            You can still complete your profile. We will know more about you if upload your CV.
                                        </div>
                                    }
                                </div>

                                <ErrorMessage
                                    name="technologies"
                                    component={() => (
                                        <div className="invalid-field-wrapper">
                                            <ErrorRoundedIcon className="error-icon" fontSize="small" />
                                            <span>The field is required. Please select atleast one option.</span>
                                        </div>
                                    )}
                                />
                            </PaperElement>
                        }

                        <div className="journey-buttons-wrapper">
                            <PrimaryButton
                                text="Continue"
                                handleClick={handleContinueClick.bind(null, props)}
                            />
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};

const mapStateToProps = state => ({
    jobsTechnologies: getJobOpportunitiesTechnologies(state),
});

export default connect(mapStateToProps)(JobTypeSelection);
