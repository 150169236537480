import React from 'react';

import './CvCompletion.scss';

const CvCompletion = ({
    cvCompletionRate
}) => {
    return (
        <div className="cv-completion-container">
            <h1 className="title">Your CV is now {parseInt(cvCompletionRate)}% complete!</h1>
            <p className="summary">We were able to do some neat Machine Learning tricks to read your resume and fill out most of your profile for you!</p>
        </div>
    )
};

export default CvCompletion;
