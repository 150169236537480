export const DEPARTMENTS_TABLE_COLUMNS = [
    { name: 'Department Name', value: 'name', isSortable: true, langKey: "department-name" },
    { name: 'Admin', value: 'admins', isSortable: true, langKey: "admins" },
    { name: 'Members', value: 'membersCount', isSortable: true, langKey: "members" },
];

export const DEFAULT_DEPARTMENTS_TABLE_COLUMNS = ['name', 'admins', 'membersCount'];

export const DEPARTMENTS_TABLE_SORT_QUERY_SELECTOR = 'departmentsSort';
export const DEPARTMENTS_TABLE_PAGE_QUERY_SELECTOR = 'departmentsTablePage';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const INITIAL_QUERY = {
    page: 1,
    sortBy: 'name',
    sortByDir: 'desc',
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    pagesToLoad: 5,
};

export const QUERY_PARAMS_MAP = {
    page: DEPARTMENTS_TABLE_PAGE_QUERY_SELECTOR,
    sortBy: DEPARTMENTS_TABLE_SORT_QUERY_SELECTOR,
    sortByDir: `${DEPARTMENTS_TABLE_SORT_QUERY_SELECTOR}Dir`,
};
