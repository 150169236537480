import React from 'react';
import { useIntl } from 'react-intl';

import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import UploadFiles from 'components/Shared/UploadFiles';

import { MAX_FILES_COUNT, ACCEPTED_FORMATS } from './TrackTimeUploadFileDialogConstants';

const TrackTimeUploadFileFormView = ({
    values,
    setFieldValue,
    handleSubmit,
    handleCloseDialog,
}) => {
    const intl = useIntl();

    return (
        <form className="upload-files-form" autoComplete="off" >
            <UploadFiles
                name="filesAttached"
                filesAttached={values.filesAttached}
                setFieldValue={setFieldValue}
                maxFilesCount={MAX_FILES_COUNT}
                acceptedFormats={ACCEPTED_FORMATS}
                single={true}
            />

            <div className="dialog-actions">
                <SecondaryButton buttonStyle="discard" text={intl.formatMessage({ id: "discard" })} handleClick={handleCloseDialog} />
                <SecondaryButton buttonStyle="save" text={intl.formatMessage({ id: "save" })} type="button" handleClick={handleSubmit} />
            </div>
        </form >
    )
};

export default TrackTimeUploadFileFormView;
