import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import {
    parseQueryString,
    getStateAgencyName,
    formatStringAsClassName,
    getExperienceSinceString,
} from 'utils/helpers';

import { disableUsers } from 'actions/userActions';
import { setPersonalInfoInitialState } from 'actions/employeeActions';
import { exportCandidateCV } from 'actions/candidateActions';

import { getLanguage } from 'reducers';

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Tooltip } from '@material-ui/core';

import CandidateSpeciality from './CandidateSpeciality';
import TableWrapper from 'components/Shared/TableWrapper';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import { REFERRALS } from 'constants/userConstants';
import {
    PAGE_QUERY_SELECTOR,
    SORT_QUERY_SELECTOR,
    TABS_QUERY_SELECTOR,
    defaultTab,
    defaultTableColumns,
    legendItems,
    tableColumns,
    tableFilters,
    tableTabs
} from './CandidatesTableConstants';

import { ReactComponent as AddIcon } from 'assets/add-button-icon.svg';
import { ReactComponent as ExportIcon } from 'assets/export-button-icon.svg';
import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

import { getDataFromQuery } from './CandidatesTableUtils';

const CandidatesTable = ({
    handleOpenDialog,
    dataSelector,
    agencyId,
    exportCandidates,
    isTableDataLoading,
    totalCount,
    assigneeFilter,
    selectedLanguage
}) => {
    const intl = useIntl();
    const history = useHistory();

    const handleCandidateClick = (id) => {
        history.push(`/${getStateAgencyName()}/candidates/${id}`);
    };

    const handleExportCandidates = () => {
        const parsedQuery = parseQueryString(history.location.search, true);

        const { sortBy, sortByDir, tab, filters } = getDataFromQuery(parsedQuery, [...tableFilters, assigneeFilter]);

        exportCandidates(agencyId, { sortBy, sortByDir, tab, filters });
    };

    const getTooltipTitle = (user) => {
        const { addedByUser, referrer } = user;

        const invitedByText = intl.formatMessage({ id: "invited-by" });
        const referredByText = intl.formatMessage({ id: "referred-by" });
        const registeredThroughTheLinkText = intl.formatMessage({ id: "registered-through-the-link" });

        return addedByUser ? `${invitedByText}: ${addedByUser}` : referrer ? `${referredByText}: ${referrer}` : registeredThroughTheLinkText;
    };

    const getReferrerClassName = (user) => {
        const { addedByUser, referrer } = user;

        if (addedByUser) {
            return 'invited';
        } else if (referrer) {
            switch (referrer) {
                case REFERRALS.GOOGLE:
                    return 'google';
                case REFERRALS.LINKEDIN:
                    return 'linkedin';
                default:
                    return 'other-sources';
            }
        } else {
            return 'self';
        }
    };

    const adjustKeysFunction = users => users.map(user => {
        const date = moment.utc(user?.experienceSince);

        const statusLastEdit = moment
            .utc(user.statusLastEdit)
            .format(DATE_FORMAT_WITH_DOTS_FULL_YEAR);
        const lastEdited = moment.utc(
            statusLastEdit,
            DATE_FORMAT_WITH_DOTS_FULL_YEAR
        );
        const today = moment.utc();
        const days = today.diff(lastEdited, 'days');

        return {
            _id: user._id,
            fullName: (
                <div className="full-name-wrapper" onClick={() => handleCandidateClick(user._id)}>
                    <div className="logo-name-wrapper">
                        <Tooltip title={getTooltipTitle(user)} classes={{ tooltip: 'custom-tooltip' }}>
                            <Avatar
                                alt="Candidate Avatar"
                                className={`avatar-logo-image ${getReferrerClassName(user)}`}
                                src={user.profilePictureImgUrl}
                            />
                        </Tooltip>
                        <div className="name-text">
                            {user.contactPerson
                                ? `${user.contactPerson}`
                                : `${user.firstName} ${user.lastName}`}
                        </div>
                        {user.agencyImgUrl && (
                            <div
                                tooltip="This candidate has been transferred to agency"
                                key={user._id}
                                className="transferred-candidates-icon"
                            >
                                <img src={user.agencyImgUrl} alt="Agency Logo" />
                            </div>
                        )}
                    </div>
                </div>
            ),
            email:
                user.email || user.contactEmail ? (
                    <a
                        className="email"
                        href={`mailto:${user.email || user.contactEmail}`}
                    >
                        {user.email || user.contactEmail}
                    </a>
                ) : (
                    '-'
                ),
            speciality: (
                <div>
                    {user.preferences ? (
                        <CandidateSpeciality preferences={user.preferences} />
                    ) : (
                        '-'
                    )}
                </div>
            ),
            experienceSince: (
                <div>
                    {user?.experienceSince ? getExperienceSinceString(date, selectedLanguage) : '-'}
                </div>
            ),
            applications: (
                <div>
                    {user.applications && user.applications.length ?
                        <Fragment>
                            {user.applications.map((application) => (
                                <Typography
                                    variant="body2"
                                    className="single-position"
                                >
                                    {application.position} {intl.formatMessage({ id: "at" })} {application.client?.name};
                                </Typography>
                            ))
                            }
                        </Fragment>
                        : (
                            <Typography variant="body2">-</Typography>
                        )}
                </div>
            ),
            assignee: `${!user.assignee || !user.assignee.name ? '-' : user.assignee.name
                }`,
            status: (
                <div
                    className={`table-body-cell-status ${user.status ? formatStringAsClassName(user.status) : ''
                        }`}
                >
                    {intl.formatMessage({ id: convertStringToLanguageKey(user.status) })}
                    {user.statusLastEdit && (
                        <Tooltip title={intl.formatMessage({ id: "status-last-edit-tooltip-text" }).replace("{}", days)} classes={{ tooltip: 'custom-tooltip' }}>
                            <div className="edited">{intl.formatMessage({ id: "days-ago" }).replace("{}", days)}</div>
                        </Tooltip>
                    )}
                </div>
            ),
            item: user
        };
    });

    return (
        <div className="candidates-table-container">
            <TableWrapper
                title={intl.formatMessage({ id: "menu-candidates" })}
                columns={tableColumns}
                defaultColumns={defaultTableColumns}
                dataSelector={dataSelector}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                adjustKeysFunction={adjustKeysFunction}
                isTableDataLoading={isTableDataLoading}
                legendItems={legendItems}
                withPagination
                withLegend
                addMargin
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableFilter filters={[...tableFilters, assigneeFilter]} />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton text={intl.formatMessage({ id: "add-candidate-button-text" })} icon={<AddIcon />} handleClick={handleOpenDialog} />
                        <TableWrapper.TableAlignedItems.TableActionButton text={intl.formatMessage({ id: "export-button-text" })} icon={<ExportIcon />} handleClick={handleExportCandidates} />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableCategoryFilter filters={tableTabs} defaultFilter={defaultTab} queryParamName={TABS_QUERY_SELECTOR} />
                <TableWrapper.TableContent />
            </TableWrapper>
        </div>
    );
};

const mapStateToProps = (state) => ({
    selectedLanguage: getLanguage(state),
});

const mapDispatchToProps = {
    setPersonalInfoInitialState,
    exportCandidateCV,
    disableUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesTable);
