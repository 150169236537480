export const holidayTypes = [
    { value: 1, name: 'All' },
    { value: 2, name: 'Paid' },
    { value: 3, name: 'Unpaid' }
]

export const defaultHolidayType = holidayTypes[0];

export const INITIAL_FILTERS = {
    type: defaultHolidayType,
};

export const pendingHeadCells = [
    { title: 'Name', value: 'name' }, 
    { title: 'From', value: 'from'}, 
    { title: 'To', value: 'to' }, 
    { title: 'Days', value: 'numberOfDays' },
    { title: 'Type', value: 'type' }
];

export const PENDING_HEAD_CELLS_VALUES = {
    NAME: 'name', 
    FROM: 'from', 
    TO: 'to', 
    DAYS: 'numberOfDays',
    TYPE: 'type',
};
