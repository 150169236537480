
import React from 'react';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import './SubmenuActionButton.scss';

const SubmenuActionButton = ({
    className,
    handleClick,
    text,
    helperText,
    buttonStyle,
    component,
    ...attrs
}) => {
    return (
        <div className={`submenu-action-button-container${helperText ? ' with-helper-text' : ''}`}>
            <div className="submenu-action-button-wrapper">
                {component
                    ? React.cloneElement(component,
                        {
                            className: className ? `submenu-action-button ${className}` : 'submenu-action-button',
                            text,
                            handleClick,
                            buttonStyle,
                            ...attrs
                        }
                    )
                    : <SecondaryButton
                        className={className ? `submenu-action-button ${className}` : 'submenu-action-button'}
                        text={text}
                        handleClick={handleClick}
                        buttonStyle={buttonStyle}
                        {...attrs}
                    />}
            </div>
            {helperText && <div className="helper-text">{helperText}</div>}
        </div>
    );
};

export default SubmenuActionButton;
