import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchSupplierEmployeesProjects } from 'actions/supplierActions';
import { getClientProjects } from 'reducers';

import Section from 'components/Shared/Section';
import ClientDashboardBox from '../ClientDashboardComponents/ClientDashboardBox';

import { CLIENT_PROJECT_STATUSES } from 'constants/clientConstants';

import './ProjectsOverviewSupplier.scss';

const ACTIVE_PROJECTS_KEY = "Active";
const PAST_PROJECTS_KEY = "Past";

const ProjectsOverviewSupplier = ({
    agencyId,
    userId,
    fetchSupplierEmployeesProjects,
    projects,
}) => {
    useEffect(() => {
        fetchSupplierEmployeesProjects(agencyId, userId);
    }, []);

    const activePastProjectsMap = () => projects.reduce((acc, next) => {
        if (!acc[ACTIVE_PROJECTS_KEY]) {
            acc[ACTIVE_PROJECTS_KEY] = 0;
        }

        if (!acc[PAST_PROJECTS_KEY]) {
            acc[PAST_PROJECTS_KEY] = 0;
        }

        const today = moment();
        const endDate = moment(next.endDate);
        const startDate = moment(next.startDate);
        const hasEndDate = Boolean(next.endDate);
   
        const isPastProject = (hasEndDate && !next.ongoing && endDate.isBefore(today)) || (startDate.isBefore(today) && !hasEndDate && !next.ongoing);

        if (isPastProject) {
            acc[PAST_PROJECTS_KEY] += 1;
        } else {
            acc[ACTIVE_PROJECTS_KEY] += 1;
        }

        return acc;
    }, {});

    return (
        <div className="projects-overview-container">
            <Section title="Engagement">
                <div className="project-boxes-wrapper">
                    <ClientDashboardBox
                        boxText={CLIENT_PROJECT_STATUSES.ACTIVE}
                        boxCount={activePastProjectsMap()[ACTIVE_PROJECTS_KEY]}
                    />
                    <ClientDashboardBox
                        boxText={CLIENT_PROJECT_STATUSES.PAST}
                        boxCount={activePastProjectsMap()[PAST_PROJECTS_KEY]}
                    />
                </div>
            </Section>
        </div>
    )
}

const mapStateToProps = state => ({
    projects: getClientProjects(state),
});

const mapDispatchToProps = {
    fetchSupplierEmployeesProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsOverviewSupplier);
