export const profileSummaryFieldMaxLength = 500;

export const profileTypes = [
    'Developer',
    'Designer',
    'Project Manager',
    'QA Engineer',
    'Data Engineer',
    'Data Analyst',
    'DevOps Engineer',
    'Architect',
    'Team Lead'
];
