import React, { useState } from 'react';
import { connect } from 'react-redux';

import { addTrainingTopic, removeTrainingTopic, updateTrainingTopic } from 'actions/agencyActions';

import { Paper } from '@material-ui/core';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import AddDialog from 'components/Settings/EmployeeSettings/PerformanceCriteria/AddCriteriaDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import TopicRow from './TopicRow';

import './Topic.scss';

const Topics = ({
    topics,
    agencyId,
    trainingId,
    addTrainingTopic,
    removeTrainingTopic,
    updateTrainingTopic
}) => {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openConfirmDialog, setOpenCOnfirmDialog] = useState(false);
    const [selectedTopicToEdit, setSelectedTopicToEdit] = useState(null);
    const [topicToRemove, setTopicToRemove] = useState({});

    const handleOpenAddDialog = () => setOpenAddDialog(true);

    const handleCloseAddDialog = () => {
        setSelectedTopicToEdit(null);
        setOpenAddDialog(false);
    };

    const handleCloseConfirmDialog = () => {
        setSelectedTopicToEdit(null);
        setOpenCOnfirmDialog(false);
    };

    const handleTopicSubmit = (data) => {
        if (selectedTopicToEdit) {
            updateTrainingTopic(agencyId, trainingId, selectedTopicToEdit._id, { title: data.criteria })
        } else {
            addTrainingTopic(agencyId, trainingId, { title: data.criteria })
        }
    };

    const handleTopicEditClick = (item) => {
        setSelectedTopicToEdit(item);
        handleOpenAddDialog();
    };

    const handleRemoveClick = (item) => {
        setTopicToRemove(item);
        setOpenCOnfirmDialog(true);
    };

    const handeRemoveTraining = (item) => {
        removeTrainingTopic(agencyId, trainingId, item._id);
        setOpenCOnfirmDialog(false);
    }

    return (
        <div className="individual-training-topics-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Topics'
                />

                <SectionActionsWrapper.RightAlignedActions>
                    <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                        text='Add Topic'
                        onClick={handleOpenAddDialog}
                    />
                </SectionActionsWrapper.RightAlignedActions>
            </SectionActionsWrapper>

            <Paper className="topics-content">
                {topics.map((x, i) => <TopicRow key={i} item={x} removeItem={handleRemoveClick} editItem={handleTopicEditClick} />)}
            </Paper>

            <AddDialog
                selectedCriteria={selectedTopicToEdit?.title}
                openDialog={openAddDialog}
                criterias={[]}
                handleCloseDialog={handleCloseAddDialog}
                handleCriteriaSubmit={handleTopicSubmit}
                title="Topic"
            />

            <ConfirmDeleteDialog
                itemToDelete={topicToRemove.title || ''}
                handleDeleteItem={() => handeRemoveTraining(topicToRemove)}
                openDialog={openConfirmDialog}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </div>
    )
}

const mapDispatchToProps = {
    addTrainingTopic,
    removeTrainingTopic,
    updateTrainingTopic
};

export default connect(null, mapDispatchToProps)(Topics);
