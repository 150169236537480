import React from 'react';
import { connect } from 'react-redux';

import { fetchAgencyTimesheets, exportAgencyTimesheets } from 'actions/agencyActions'
import { getAgencyId } from 'reducers';

import TimesheetsTable from './TimesheetsTable';

const TimeManagement = ({
    agencyId,
    fetchAgencyTimesheets,
    exportAgencyTimesheets,
}) => {

    return (
        <div className="time-management-wrapper">
            <TimesheetsTable
                agencyId={agencyId}
                tableFetchAction={fetchAgencyTimesheets.bind(null, agencyId)}
                exportAgencyTimesheets={exportAgencyTimesheets}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state)
});

const mapDispatchToProps = {
    fetchAgencyTimesheets,
    exportAgencyTimesheets,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeManagement);
