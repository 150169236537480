import { createStructuredSelector } from 'reselect';

import {
    AGENCY_RECRUITER_HOME_INFORMATION_FETCH_SUCCESS,
    HOME_INFORMATION_INITIAL_STATE_SET,
    RECRUITER_INFORMATION_FETCH_SUCCESS,
    RECRUITER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS,
    RECRUITER_AVATAR_UPDATE_SUCCESS,
    RECRUITER_AVATAR_DELETE_SUCCESS,
    RECRUITER_CONTACT_INFORMATION_UPDATE_SUCCESS,
    RECRUITER_BASIC_INFORMATION_UPDATE_SUCCESS,
    RECRUITER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS,
    RECRUITERS_ACCOUNT_MANAGERS_FETCH_SUCCESS,
} from 'actions/actionTypes';

const recruiterInitialState = {
    basic: {
        name: '',
        country: '',
        email: ''
    },
    contact: {
        contactPerson: '',
        contactEmail: '',
        contactNumber: '',
    },
    logoImgUrl: '',
    logoImgName: '',
    logoImgDetails: {
        width: 0,
        height: 0,
        size: 0,
        sizeBits: 'B',
        extension: '',
    },
    accountManager: {
        name: '',
        email: '',
        phone: '',
        _id: '',
        profilePictureImgUrl: '',
        contactNumber: '',
    },
    accountManagers: [],
};


const recruiter = (state = recruiterInitialState, action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return {
                ...state,
                logoImgUrl: '',
                basic: { ...state.basic, name: '' },
                contact: { ...state.contact, contactPerson: '' },
            };
        case AGENCY_RECRUITER_HOME_INFORMATION_FETCH_SUCCESS:
            return {
                ...recruiterInitialState,
                logoImgUrl: action.payload.logoImgUrl,
                basic: { ...state.basic, name: action.payload.name },
                contact: { ...state.contact, contactPerson: action.payload.contactPerson },
            };
        case RECRUITER_AVATAR_DELETE_SUCCESS:
            return {
                ...state,
                logoImgUrl: '',
                logoImgName: '',
                logoImgDetails: {
                    width: 0,
                    height: 0,
                    size: 0,
                    sizeBits: 'B',
                    extension: ''
                }
            };
        case RECRUITER_INFORMATION_FETCH_SUCCESS:
            return { ...recruiterInitialState, ...action.payload };
        case RECRUITER_AVATAR_UPDATE_SUCCESS:
        case RECRUITER_CONTACT_INFORMATION_UPDATE_SUCCESS:
        case RECRUITER_BASIC_INFORMATION_UPDATE_SUCCESS:
        case RECRUITER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS:
        case RECRUITER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS:
            return { ...state, ...action.payload };
        case RECRUITERS_ACCOUNT_MANAGERS_FETCH_SUCCESS:
                return {...state, accountManagers: action.payload }
        default:
            return state;
    }
};

export default recruiter;

const recruiterNameSelector = (state) => state.basic.name;
const recruiterContactPersonSelector = (state) => state.contact.contactPerson;
const recruiterLogoSelector = (state) => state.logoImgUrl;
export const getRecruiterMenuInformation = createStructuredSelector({
    name: recruiterNameSelector,
    contactPerson: recruiterContactPersonSelector,
    logoImgUrl: recruiterLogoSelector,
});

export const getRecruiterAccountManager = state => state.accountManager;
export const getRecruiterBasicInformation = state => ({
    name: state.basic.name,
    country: state.basic.country,
    email: state.basic.email,
    logoImgUrl: state.logoImgUrl,
    logoImgDetails: state.logoImgDetails,
});

export const getRecruiterContactInformation = state => state.contact;

export const getRecruitersAccountManagers = state => state.accountManagers;
