import api from './api';
import requester from './requester';

const agencyProfileService = {
    updateUserInfo: (agencyId, userData) => requester(api.agencyProfileUserInfo(agencyId)).update(userData),
    updateAgencyInfo: (agencyId, agencyData) => requester(api.agencyProfileAgencyInfo(agencyId)).update(agencyData),
    fetchUserInfo: (agencyId) => requester(api.agencyProfileUserInfo(agencyId)).getOne(),
};

export default agencyProfileService;
