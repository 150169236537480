import React from 'react';
import { useIntl } from 'react-intl';

import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { ReactComponent as CheckIcon } from 'assets/icon-check.svg';

import './SlimSelect.scss';

const SlimSelect = ({
    value,
    initialValue, // TODO: REMOVE THIS FIELD
    onChangeHandler,
    items,
    className,
    name,
    checkSelected,
}) => {
    const intl = useIntl();
    return (
        <div>
            <FormControl variant="outlined">
                <Select
                    className={className ? `slim-select ${className}` : 'slim-select'}
                    IconComponent={ExpandMoreSharpIcon}
                    value={initialValue || value}
                    onChange={onChangeHandler}
                    MenuProps={{
                        className: "select-menu",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    name={name}
                >
                    {items.map(item =>
                        <MenuItem key={item.value} value={item.value} disabled={item.disabled} className={checkSelected && item.name === value ? 'menu-item selected' : 'menu-item'}>
                            {item.langKey ? intl.formatMessage({ id: item.langKey }) : item.name}
                            {checkSelected && value === item.name && <CheckIcon className="check-icon" />}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    )
};

export default SlimSelect;
