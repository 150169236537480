import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Avatar as DefaultAvatar } from '@material-ui/core';
import ImageEditDialog from './CropImageDialog';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import './DisplayImage.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const DisplayImage = ({
    imageUrl,
    imageDetails,
    handleUpdateAvatar,
    handleDeleteAvatar,
    entityId,
    agencyId,
    uploadButtonText,
    editButtonText,
    defaultLogo
}) => {
    const intl = useIntl();

    const [currentImgSelected, setCurrentImgSelected] = useState(imageUrl);
    const [showEditImgDialog, setShowEditImgDialog] = useState(false);
    const [showUploadButtons, setShowUploadButtons] = useState(false);
    const [currentProfilePictureFileName, setCurrentProfilePictureFileName] = useState();


    const handleShowUploadButtons = () => {
        setShowUploadButtons(oldState => !oldState);
    }

    const imageSelectedHandler = (e) => {
        const currentFile = e.target.files[0]
        setCurrentProfilePictureFileName(currentFile.name);
        const imgReader = new FileReader()

        imgReader.addEventListener("load", () => {
            const resultImg = imgReader.result
            setCurrentImgSelected(resultImg);
        }, false)

        imgReader.readAsDataURL(currentFile)

        setShowEditImgDialog(true);
    }

    const onImgEditClose = () => {
        setCurrentImgSelected(null);
        setShowEditImgDialog(false);
        profileImgRef.current.value = '';
    }

    const onImgCropSave = avatarInfo => {
        const data = new FormData()
        data.append('croppedImg', avatarInfo.file);
        data.append('croppedImgInfo', JSON.stringify(avatarInfo));
        handleUpdateAvatar(data, entityId, agencyId);
        setShowEditImgDialog(false);
        setShowUploadButtons(false);
        profileImgRef.current.value = '';
    }

    const handleDelete = () => {
        if (imageUrl !== '') {
            handleDeleteAvatar(entityId, agencyId);
        }

        setShowUploadButtons(false);
    }

    const profileImgRef = useRef();

    return (
        <div className="image-upload">
            <div className="image-wrapper">
                <div className={defaultLogo ? "image no-background" : "image"}>
                    {imageUrl ? <img
                        className="uploaded-picture"
                        src={imageUrl}
                        alt="avatar"
                        onError={() => <div className="default-avatar-container"><DefaultAvatar /></div>}
                    /> : <div className="default-avatar-container"><DefaultAvatar /></div>
                    }
                </div>
                <div className="photo-info">
                    <div className="photo-list-item">
                        <span className="item-key">{intl.formatMessage({ id: "width" })}:</span>
                        <span className="item-value">{imageDetails?.width || 0 + 'px'}</span>
                    </div>
                    <div className="photo-list-item">
                        <span className="item-key">{intl.formatMessage({ id: "height" })}:</span>
                        <span className="item-value">{imageDetails?.height || 0 + 'px'}</span>
                    </div>
                    <div className="photo-list-item">
                        <span className="item-key">{intl.formatMessage({ id: "size" })}:</span>
                        <span className="item-value">{
                            imageDetails?.size || 0 +
                            imageDetails?.sizeBits || 0}
                        </span>
                    </div>
                    <div className="photo-list-item">
                        <span className="item-key">{intl.formatMessage({ id: "type" })}:</span>
                        <span className="item-value">{imageDetails?.extension?.toUpperCase()}</span>
                    </div>
                </div>
            </div>

            {showUploadButtons
                ? <div className="photo-upload-buttons" >
                    <div>
                        <input
                            accept="image/*"
                            className="upload-input"
                            id="upload-file-input"
                            type="file"
                            onChange={imageSelectedHandler}
                            ref={profileImgRef}
                        />
                        <label htmlFor="upload-file-input">
                            <PrimaryButton
                                text={uploadButtonText || intl.formatMessage({ id: "upload-photo" })}
                                component="span"
                                handleClick={() => void 0} />
                        </label>
                    </div>

                    <SecondaryButton text={intl.formatMessage({ id: "delete" })} handleClick={() => handleDelete()} buttonStyle="delete" />
                </div>
                : <div className="edit-photo-button">
                    <PrimaryButton
                        text={editButtonText || intl.formatMessage({ id: "edit-photo" })}
                        //TODO: for now changes the button, apply correct handler function when possible
                        handleClick={handleShowUploadButtons} />
                </div>}
            {currentImgSelected &&
                <ImageEditDialog
                    showEditImgDialog={showEditImgDialog}
                    currentImgSelected={currentImgSelected}
                    onImgEditClose={onImgEditClose}
                    onSave={onImgCropSave}
                    currentProfilePictureFileName={currentProfilePictureFileName}
                />
            }
        </div>
    )
};

export default DisplayImage;
