import React from 'react';

import UploadFiles from 'components/Shared/UploadFiles';

const ACCEPTED_FORMATS = ['docx', 'pdf', 'txt', 'doc'];

const UploadFilesDialogFormView = ({
    values,
    setFieldValue,
}) => {
    return (
        <form className="upload-files-form" autoComplete="off">
            <UploadFiles
                name="filesAttached"
                filesAttached={values.filesAttached}
                setFieldValue={setFieldValue}
                acceptedFormats={ACCEPTED_FORMATS}
            />
        </form>
    )
};

export default UploadFilesDialogFormView;
