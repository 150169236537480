import React from 'react';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import { ReactComponent as EditIcon } from 'assets/jobs-edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/jobs-delete-icon.svg';

import { DATE_FORMAT } from 'constants/commonConstants';

import './HistoricalNote.scss';

const HistoricalNote = ({
    historicalNote,
    handleEditClick,
    handleDeleteClick,
}) => {
    return (
        <Paper className="job-historical-note-wrapper">
            <div className="job-historical-note-creation-info">
                <p className="creator-name">{historicalNote.creatorName}</p>
                <p className="creation-date">{moment(historicalNote.date).format(DATE_FORMAT)}</p>

                <div className="job-historical-note-actions">
                    <div className="icon-wrapper" onClick={() => handleEditClick(historicalNote)}>
                        <EditIcon />
                    </div>
                    <div className="icon-wrapper" onClick={() => handleDeleteClick(historicalNote)}>
                        <DeleteIcon />
                    </div>
                </div>
            </div>

            <div className="job-historical-note-description">
                <p className="description-text">
                    {historicalNote.feedbackType ? `${historicalNote.feedbackType}: ${historicalNote.description}` : historicalNote.description}
                </p>
            </div>
        </Paper>
    );
}

export default HistoricalNote;
