import { EMPLOYEE_OCCUPATION_STATUSES_VALUES } from 'constants/employeeConstants';

export const EMPLOYEES_LOCATIONS_COLORS = [
    { color: '#000000' },
    { color: '#4438BA' },
    { color: '#9C93FB' },
    { color: '#209883' },
    { text: 'Others', color: '#7FE0CE' },
    { text: 'Unassigned', color: '#AAEADF' },
];

export const EMPLOYEES_UTILISATION_COLORS = {
    [EMPLOYEE_OCCUPATION_STATUSES_VALUES.BENCH]: '#DC083A',
    [EMPLOYEE_OCCUPATION_STATUSES_VALUES.PROJECT_ENDING]: '#FF9100',
    [EMPLOYEE_OCCUPATION_STATUSES_VALUES.INTERNAL_PROJECT]: '#7E7E7E',
    [EMPLOYEE_OCCUPATION_STATUSES_VALUES.MANAGEMENT_TEAM]: '#040227',
    [EMPLOYEE_OCCUPATION_STATUSES_VALUES.PLACED]: '#7FE0CE',
};

