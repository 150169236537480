import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { employeesTimesheetCompletion } from 'actions/employeeActions';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { DATE_PICKER_FORMAT } from 'constants/timesFormat';

import './AutomaticallyTrackHours.scss';

const AutomaticallyTrackHours = ({
    employeesTimesheetCompletion,
    agencyId,
    userId,
}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleSubmit = () => {
        if (startDate && endDate) {
            employeesTimesheetCompletion(agencyId, userId, moment(startDate).toISOString(), moment(endDate).toISOString())
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="actions-wrapper">
                <KeyboardDatePicker
                    autoOk
                    required
                    label="Start Date"
                    name="startDate"
                    variant="inline"
                    disableToolbar
                    format={DATE_PICKER_FORMAT}
                    openTo="date"
                    inputVariant="outlined"
                    value={startDate}
                    onChange={value => {
                        setStartDate(moment(value));
                    }}
                    fullWidth
                />
                <KeyboardDatePicker
                    autoOk
                    required
                    label="End Date"
                    name="endDate"
                    variant="inline"
                    disableToolbar
                    format={DATE_PICKER_FORMAT}
                    openTo="date"
                    inputVariant="outlined"
                    value={endDate}
                    onChange={value => {
                        setEndDate(moment(value));
                    }}
                    fullWidth
                />

                <PrimaryButton handleClick={handleSubmit} text="Submit" type="submit" />
            </div>
        </MuiPickersUtilsProvider>
    )
}

const mapDispatchtoProps = {
    employeesTimesheetCompletion,
};

export default connect(null, mapDispatchtoProps)(AutomaticallyTrackHours);
