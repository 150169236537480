import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogDiscardButton from 'components/Shared/DialogButtons/DialogDiscardButton';
import DialogSaveButton from 'components/Shared/DialogButtons/DialogSaveButton';
import DialogCloseButton from 'components/Shared/DialogButtons/DialogCloseButton'
import { DialogActions } from '@material-ui/core';

import './UploadLinkedInCvDialog.scss';

const UploadLinkedInCvDialog = ({
    dialogTitle,
    onSave,
    open,
    onClose,
    children,
    saveBtnText,
    discardBtnText,
}) => {
    return (
        <Dialog open={open} onClose={() => onClose()} className="upload-linkedin-dialog" disablePortal={true}>
            <MuiDialogTitle disableTypography className="dialog-title">
                <Typography variant="body2" className="dialog-title-text">
                    {dialogTitle}
                </Typography>
                <DialogCloseButton className="dialog-close-btn" onClick={() => onClose()}></DialogCloseButton>

            </MuiDialogTitle>
            <DialogContent className="dialog-content">
                {children}
            </DialogContent>
            <DialogActions className="dialog-actions-container">
                <DialogDiscardButton onClick={() => onClose()}>{discardBtnText}</DialogDiscardButton>
                <DialogSaveButton onClick={onSave}>{saveBtnText}</DialogSaveButton>
            </DialogActions>
        </Dialog>
    );
};

export default UploadLinkedInCvDialog;
