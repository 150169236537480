import React from 'react';

import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

import ResultsCardView from '../ResultsCardView';

const SearchResultsClientView = ({
    results,
    agencyName,
    handleResultClick,
    displayName,
}) => {
    const yourTeam = results.filter(x => x.isProjectUsersResult).reduce((acc, project) => {
        for (const user of project.projectUsers) {
            acc.push({
                ...user,
                ...project,
                name: `${user.firstName} ${user.lastName}` || user.name,
                position: project.name,
                companyInfo: displayName,
            })
        }
        return acc;
    }, []);
    const applicants = results.filter(x => x.isUserResult).reduce((acc, result) => {
        acc.push({
            ...result,
            name: `${result.candidate.firstName} ${result.candidate.lastName}` || result.candidate.name,
            logoImgUrl: result.candidate.profilePictureImgUrl,
            email: result.candidate.email,
            role: result.candidate.role,
            profilePictureImgUrl: result.candidate.profilePictureImgUrl,
            companyInfo: displayName
        });
        return acc;
    }, []);

    const projects = results.filter(x => x.isProject);

    return (
        <div className='search-results'>
            {
                yourTeam.length ? <>
                    <Typography variant="body1" className="title result-category">Your Team</Typography>
                    <List >
                        {yourTeam.map((x) => (
                            <ResultsCardView
                                key={x._id}
                                result={x}
                                handleClick={handleResultClick}
                                agencyName={agencyName}
                            />))
                        }
                    </List >
                </> : null
            }
            {
                applicants.length ? <>
                    <Typography variant="body1" className="title result-category">Your Applicants</Typography>
                    <List >
                        {applicants.map((x) => (
                            <ResultsCardView
                                key={x._id}
                                result={x}
                                handleClick={handleResultClick}
                                agencyName={agencyName}
                                hideProjects={true}
                            />))
                        }
                    </List >
                </> : null
            }
            {
                projects.length ? <>
                    <Typography variant="body1" className="title result-category">Projects</Typography>
                    <List >
                        {projects.map((x) => (
                            <ResultsCardView
                                key={x._id}
                                result={x}
                                handleClick={handleResultClick}
                                agencyName={agencyName}
                            />))
                        }
                    </List >
                </> : null
            }
        </div>
    )
};

export default SearchResultsClientView;
