import React from 'react';

const LogoWhite = ({
    className = '',
    idSuffix = '' // idSuffix needed when logo is used in several different places on one page.
}) => (
    <svg className={className} id="Group_46479" data-name="Group 46479" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="188.402" height="61.511" viewBox="0 0 188.402 61.511">
        <defs>
            <clipPath id={"clip-path-logo-white" + idSuffix}>
                <path id="Path_13080" data-name="Path 13080" d="M31.173,61.37a8.184,8.184,0,0,1-.893.105,8.462,8.462,0,0,0,.893-.105m-6.316-.884a1.62,1.62,0,0,1-.156-.078c.133.064.27.128.412.188-.087-.032-.169-.069-.256-.11M47.968,0c-.179,0-.353,0-.531.014s-.383.027-.574.048c-.14.014-.28.033-.419.052l-.055.008a10.924,10.924,0,0,0-8.335,6.193l-8.6,18.452L20.846,6.317A10.933,10.933,0,0,0,1.029,15.558L19.509,55.2a10.939,10.939,0,0,0,9.375,6.3l-.01,0c.132.008.264.013.4.013a1.3,1.3,0,0,0,.133,0h.082c.179,0,.357,0,.536-.017l-.009,0a10.928,10.928,0,0,0,9.375-6.3l8.605-18.447L56.6,55.2a10.909,10.909,0,0,0,8.3,6.188L65,61.4c.127.018.255.035.383.048.195.021.389.039.584.049h.009q.268.014.536.014h.041q.268,0,.536-.014H67.1c.195-.01.389-.028.584-.049.128-.013.256-.03.383-.048l.109-.017a10.91,10.91,0,0,0,8.3-6.188L94.961,15.558A10.935,10.935,0,0,0,75.139,6.317l-8.6,18.452L57.933,6.317a10.924,10.924,0,0,0-8.35-6.2l-.031,0c-.144-.02-.288-.04-.433-.054-.19-.02-.38-.037-.571-.047S48.2,0,48.018,0Z" transform="translate(0 -0.001)" fill="#fff" />
            </clipPath>
            <clipPath id={"clip-path-logo-white-2" + idSuffix}>
                <path id="Path_13081" data-name="Path 13081" d="M489.407,6.322,470.924,45.958A10.934,10.934,0,1,0,490.742,55.2l18.483-39.637a10.934,10.934,0,1,0-19.818-9.241" transform="translate(-469.897 -0.006)" fill="#fff" />
            </clipPath>
            <clipPath id={"clip-path-logo-white-3" + idSuffix}>
                <path id="Path_13082" data-name="Path 13082" d="M686.992,0c-.179,0-.352,0-.531.014s-.383.027-.575.047c-.14.014-.28.033-.419.053l-.055.008a10.925,10.925,0,0,0-8.335,6.193L658.6,45.953A10.933,10.933,0,1,0,678.414,55.2l8.605-18.452,8.6,18.452a10.935,10.935,0,0,0,19.821-9.242L696.958,6.316a10.926,10.926,0,0,0-8.35-6.2l-.031,0c-.144-.021-.288-.04-.433-.055-.19-.02-.38-.037-.57-.047S687.221,0,687.042,0Z" transform="translate(-657.568 0)" fill="#fff" />
            </clipPath>
        </defs>
        <g id="Group_27577" data-name="Group 27577" transform="translate(0 0)">
            <g id="Group_27576" data-name="Group 27576" clipPath={`url(#clip-path-logo-white${idSuffix})`}>
                <rect id="Rectangle_5452" data-name="Rectangle 5452" width="97.513" height="63.036" transform="translate(-1.526 -1.525)" fill="#fff" />
            </g>
        </g>
        <g id="Group_27579" data-name="Group 27579" transform="translate(92.542 0.001)">
            <g id="Group_27578" data-name="Group 27578" clipPath={`url(#clip-path-logo-white-2${idSuffix})`}>
                <rect id="Rectangle_5453" data-name="Rectangle 5453" width="43.405" height="63.034" transform="translate(-1.525 0)" fill="#fff" />
            </g>
        </g>
        <g id="Group_27581" data-name="Group 27581" transform="translate(129.503)">
            <g id="Group_27580" data-name="Group 27580" clipPath={`url(#clip-path-logo-white-3${idSuffix})`}>
                <rect id="Rectangle_5454" data-name="Rectangle 5454" width="61.951" height="63.036" transform="translate(-1.526)" fill="#fff" />
            </g>
        </g>
    </svg>
);

export default LogoWhite;
