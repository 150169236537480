import React from 'react';

import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import EventSharpIcon from '@material-ui/icons/EventSharp';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PeopleSharpIcon from '@material-ui/icons/PeopleSharp';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import { ReactComponent as JobOpportunitiesIcon } from 'assets/public-opportunities.svg';
import { INITIAL_QUERY_STRING_JOBS_DASHBOARD } from 'components/JobsDashboard/JobsDashboardConstants';
import { temporaryRoute } from 'constants/commonConstants';

export const getSupplierMenuItems = agencyRouteName => [
    {
        TITLE: 'Dashboard',
        ICON: <AssessmentSharpIcon />,
        ROUTE: `/${agencyRouteName}/dashboard`,
    },
    {
        TITLE: 'Job Opportunities',
        ICON: <JobOpportunitiesIcon style={{width: '100%'}}/>,
        ROUTE: `/${agencyRouteName}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`,
    },
    {
        TITLE: 'Team',
        ICON: <PeopleSharpIcon />,
        ROUTE: `/${agencyRouteName}/team`,
        ITEMS: [
            { TEXT: 'Employees', ICON: <PersonSharpIcon />, ENDPOINT: `/${agencyRouteName}/team/people` },
            { TEXT: 'Holidays', ICON: <EventSharpIcon />, ENDPOINT: `/${agencyRouteName}/team/holidays` },
        ]
    },
    {
        TITLE: 'Contracts',
        ICON: <InsertDriveFileSharpIcon />,
        ROUTE: `/${agencyRouteName}/contracts`,
    },
    {
        TITLE: 'Billing',
        ICON: <AttachMoneyIcon />,
        ROUTE: `/${agencyRouteName}/billing`
    },
    {
        TITLE: 'Invoicing',
        ICON: <AttachMoneySharpIcon />,
        ROUTE: temporaryRoute,
    },
    {
        TITLE: 'Your Account Manager',
        ICON: <PersonOutlineIcon />,
        ROUTE: `/${agencyRouteName}/your-account-manager`,
    },
    {
		TITLE: 'Settings',
		ICON: <SettingsSharpIcon />,
		ROUTE: `/${agencyRouteName}/settings/employee-settings`,
	},
];
