import React from 'react';
import { connect } from 'react-redux';

import { getDisplayMode } from 'reducers';

import { getYourAccountManagerInformationComponent } from './YourAccountManagerInformationUtils';

const YourAccountManagerInformation = ({
    accountManager,
    contactNumber,
    contactEmail,
    profilePicture,
    displayMode,
}) => {
    const YourAccountManagerInformationComponent = getYourAccountManagerInformationComponent(displayMode);

    return (
        <div className="your-account-manager-information-wrapper">
            <YourAccountManagerInformationComponent
                accountManager={accountManager}
                contactNumber={contactNumber}
                contactEmail={contactEmail}
                profilePicture={profilePicture}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
});

export default connect(mapStateToProps)(YourAccountManagerInformation);
