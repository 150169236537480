import React from 'react';

import TimeTracking from 'components/Person/TimeTracking';
import OvertimeBanner from 'components/TimeTrackingPage/OvertimeBanner';

const TimeTrackingPage = (props) => {

    return (
        <div>
            <OvertimeBanner />
            <TimeTracking {...props} />
        </div>
    )
}

export default TimeTrackingPage;
