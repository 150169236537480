import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getRecruiterProfiles } from 'reducers';

import Avatar from '@material-ui/core/Avatar';

import TableWrapper from 'components/Shared/TableWrapper/TableWrapper';

import logoImg from 'assets/default-logo.svg';
import { ReactComponent as AddIcon } from 'assets/add-button-icon.svg';

import { getExperienceSinceString, getStateAgencyName, parseQueryString } from 'utils/helpers';
import {
    TABLE_COLUMNS,
    DEFAULT_TABLE_COLUMNS,
    DEFAULT_ITEMS_PER_PAGE,
    PAGE_QUERY_SELECTOR,
    SORT_QUERY_SELECTOR,
    TABLE_INITIAL_QUERY,
} from './RecruiterProfilesTableConstants';

const RecruiterProfilesTable = ({
    handleOpenAddProfileDialog,
    handleDeleteRecruiterClick,
}) => {
    const history = useHistory();

    const dataSelector = (state, page) => {
        const parsedQuery = parseQueryString(history.location.search, true);
        const parsedQuerySortBy = parsedQuery[SORT_QUERY_SELECTOR] ? parsedQuery[SORT_QUERY_SELECTOR][0] : TABLE_INITIAL_QUERY.sortBy;
        const sortBy = TABLE_COLUMNS.find(x => x.value === parsedQuerySortBy).value;
        const sortByDir = parsedQuery[`${SORT_QUERY_SELECTOR}Dir`] ? parsedQuery[`${SORT_QUERY_SELECTOR}Dir`][0] : TABLE_INITIAL_QUERY.sortByDir;

        return getRecruiterProfiles(state, page - 1, DEFAULT_ITEMS_PER_PAGE, sortBy, sortByDir).recruiterProfiles;
    };
    const totalCount = useSelector((state) => getRecruiterProfiles(state, null, null).totalCount);

    const generateToString = profile => `/${getStateAgencyName()}/team/people/${profile._id}/personal-information`;

    const adjustProfilesKeys = profiles => profiles.map(profile => {
        const experience = profile.employmentInformation?.generalInfo?.experienceSince
            ? getExperienceSinceString(profile.employmentInformation?.generalInfo?.experienceSince)
            : '-';

        return {
            name: (
                <div className="logo-name-wrapper">
                    <Avatar
                        alt="Emplyoee Avatar"
                        className="avatar-logo-image"
                        src={profile.profilePictureImgUrl ? profile.profilePictureImgUrl : logoImg}
                    />
                    <div className="name-text">
                        {`${profile.firstName} ${profile.lastName}`}
                    </div>
                </div>
            ),
            position: profile.employmentInformation?.generalInfo?.position || '-',
            experience,
            item: profile,
        };
    });

    const actions = [
        { title: 'Delete', handleClick: handleDeleteRecruiterClick }
    ];

    const getActionsHandler = () => actions;

    return (
        <TableWrapper
            title="Profiles"
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_TABLE_COLUMNS}
            dataSelector={dataSelector}
            sortQuerySelector={SORT_QUERY_SELECTOR}
            pageQuerySelector={PAGE_QUERY_SELECTOR}
            pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
            adjustKeysFunction={adjustProfilesKeys}
            withPagination
            withActions
            withLink
            generateToString={generateToString}
            getActionsHandler={getActionsHandler}
        >
            <TableWrapper.TableAlignedItems>
                <TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.TableHeader />
                </TableWrapper.TableAlignedItems.LeftAligned>
                <TableWrapper.TableAlignedItems.RightAligned>
                    <TableWrapper.TableAlignedItems.TableActionButton
                        text={"Add Profile"}
                        icon={<AddIcon />}
                        handleClick={handleOpenAddProfileDialog}
                    />
                </TableWrapper.TableAlignedItems.RightAligned>
            </TableWrapper.TableAlignedItems>
            <TableWrapper.TableContent />
        </TableWrapper>
    );
};

export default RecruiterProfilesTable;
