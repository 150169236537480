import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import Paper from '@material-ui/core/Paper';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import AgencySettingsEvrotrustFormView from './AgencySettingsEvrotrustFormView';

import { 
    validateRequiredFields,
    validateEmailAddress,
    validateEmailAddressesString,
    validateFieldIsContainedInAnotherField,
} from 'utils/formValidations';

import './AgencySettingsEvrotrustForm.scss'

const AgencySettingsEvrotrustForm = ({
    agencyInfo,
    agencySettings,
    updateAction,
    setIsUsingEvrotrust,
}) => {
    const intl = useIntl();

    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const handleShowHeaderButtons = () => setShowHeaderButtons(true);

    const evrotrustSettingsFormRef = useRef();

    const discardHandlerBasicInfo = () => {
        setShowHeaderButtons(false);
        evrotrustSettingsFormRef.current.resetForm();
    };

    const getContent = () => {
        return (
            <Paper className="evrotrust-settings-content">
                <Formik
                    initialValues={{
                        evrotrustEmail: agencyInfo?.isUsingEvrotrust ? agencyInfo?.evrotrustEmail || '' : '',
                        evrotrustSecondaryEmails: agencySettings?.evrotrustSecondaryEmails?.length > 0 ? agencySettings?.evrotrustSecondaryEmails?.join(',') : '',
                        evrotrustVendorNumber: agencyInfo?.isUsingEvrotrust ? agencyInfo?.evrotrustVendorNumber || '' : '',
                        evrotrustApiKey: agencyInfo?.isUsingEvrotrust ? agencyInfo?.evrotrustApiKey || '' : '',
                        isUsingEvrotrust: agencyInfo?.isUsingEvrotrust || false,
                        autoSignEnabled: agencySettings?.autoSignEnabled || false,
                        holidaysSignatoryEmail: agencyInfo?.isUsingEvrotrust && agencySettings?.autoSignEnabled ? agencySettings?.holidaysSignatoryEmail || agencyInfo?.evrotrustEmail : '',
                    }}
                    innerRef={evrotrustSettingsFormRef}
                    validate={values => {
                        if (values.isUsingEvrotrust) {
                            return {
                                ...validateEmailAddressesString(values, 'evrotrustSecondaryEmails'),
                                ...validateEmailAddress(values, 'evrotrustEmail'),
                                ...validateRequiredFields(values, ['evrotrustEmail', 'evrotrustVendorNumber', 'evrotrustApiKey']),
                                ...validateFieldIsContainedInAnotherField(values, 'holidaysSignatoryEmail', ['evrotrustEmail', 'evrotrustSecondaryEmails'], 'Please select a valid holiday signatory email')
                            };
                        }
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);

                        const evrotrustEmail = values.evrotrustEmail.toLowerCase().trim();
                        const evrotrustSecondaryEmails = values.evrotrustSecondaryEmails?.split(',').map(x => x.trim()).filter(x => x !== '') || [];
                        const holidaysSignatoryEmail = values.holidaysSignatoryEmail || values.evrotrustEmail;

                        updateAction({...values, evrotrustEmail, evrotrustSecondaryEmails, holidaysSignatoryEmail}, resetForm);
                        setShowHeaderButtons(false);
                    }}
                    enableReinitialize={true}
                >
                    {(props) =>
                        <AgencySettingsEvrotrustFormView
                            handleShowHeaderButtons={handleShowHeaderButtons}
                            setIsUsingEvrotrust={setIsUsingEvrotrust}
                            {...props}
                        />
                    }
                </Formik>
            </Paper>
        )
    };

    return (
        <div className="agency-settings-wrapper agency-evrotrust-settings">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "agency-settings-evrotrust-section-title" })}
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "discard" })}
                            onClick={discardHandlerBasicInfo}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "save" })}
                            onClick={() => evrotrustSettingsFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>

            {getContent()}
        </div>
    )
};

export default AgencySettingsEvrotrustForm;
