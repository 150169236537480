import { AGENCY_DOCUMENTS_FETCH_SUCCESS, AGENCY_DOCUMENTS_REPLACE_SUCCESS } from './actionTypes';

import { authorizedShowNotification } from './actionHelpers';
import { showNotification } from './notificationActions';
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';

import documentService from 'services/documentService';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { DOCUMENTS_MESSAGES } from 'constants/messageConstants';

const fetchAgencyDocumentsSuccess = data => ({
    type: AGENCY_DOCUMENTS_FETCH_SUCCESS,
    payload: data,
});

const replaceAgencyDocumentsSuccess = data => ({
    type: AGENCY_DOCUMENTS_REPLACE_SUCCESS,
    payload: data,
});

export const fetchAgencyDocuments = (agencyId, query, resetState) => async dispatch => {
    try {
        const result = await documentService.fetchAgencyDocuments(agencyId, query);

        if (resetState) {
            dispatch(replaceAgencyDocumentsSuccess(result.contracts));
        } else {
            dispatch(fetchAgencyDocumentsSuccess(result.contracts));
        }

        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DOCUMENTS_MESSAGES.FETCH_DOCUMENTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportDocumentsData = (agencyId, documentsData, fileName, query) => async dispatch => {
    dispatch(showApplicationLoader());

    try {
        await documentService.exportDocumentsData(agencyId, documentsData, fileName, query);

        dispatch(showNotification(DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_DATA_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        if (error.message === DOCUMENTS_MESSAGES.NO_DOCUMENTS_TO_EXPORT_WARNING) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.WARNING));
        } else {
            dispatch(authorizedShowNotification(error)(DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_DATA_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const downloadDocumentsFiles = (agencyId, documentsData, fileName, query) => async dispatch => {
    try {
        dispatch(showNotification(DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_DATA_STARTED, NOTIFICATION_TYPES.INFO));
        documentsData = documentsData.map(document => ({ _id: document._id, documentType: document.documentType, userId: document.userId }));

        await documentService.downloadDocumentsFiles(agencyId, documentsData, fileName, query);

        dispatch(showNotification(DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_FILES_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DOCUMENTS_MESSAGES.EXPORT_DOCUMENTS_FILES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
