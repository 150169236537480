import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { Formik } from 'formik';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AddInvoiceFormView from './AddInvoiceFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { fetchSupplierEmployeesProjects } from 'actions/supplierActions';
import { fetchClientProjectsWithEmployees } from 'actions/clientActions';
import { fetchAgencySettings } from 'actions/agencyActions';

import { getClientProjects, getDisplayMode, getClientBasicInformation, isHiddenSections, getAgencySettings } from 'reducers';

import { ROLES } from 'constants/userConstants';

import {
    validateRequiredFields,
    validateDecimalNumbers,
    validateEmailAddressesString,
    validateFilesArrayLength,
} from 'utils/formValidations';

import { addUtcOffset } from 'utils/formatTimes';

const AddInvoiceDialog = ({
    userId,
    agencyId,
    openDialog,
    handleCloseDialog,
    projects,
    fetchSupplierEmployeesProjects,
    fetchClientProjectsWithEmployees,
    fetchAgencySettings,
    addInvoice,
    displayMode,
    clientBasicInfo,
    editMode,
    editInvoice,
    initialValues,
    hiddenSections,
    agencySettings,
}) => {
    const intl = useIntl();

    const [isClientInvoice, setIsClientInvoice] = useState(false);

    useEffect(() => {
        if (ROLES.SUPPLIER_ADMIN === displayMode || ROLES.SUPPLIER_EMPLOYEE === displayMode) {
            fetchSupplierEmployeesProjects(agencyId, userId);
            setIsClientInvoice(false);
        } else {
            fetchClientProjectsWithEmployees(agencyId, userId);
            setIsClientInvoice(true);
        }

        fetchAgencySettings(agencyId);
    }, [openDialog]);

    let emailsTo = agencySettings?.financeEmail || '';
    const emailsBcc = agencySettings?.financeEmail || '';

    if (isClientInvoice) {
        emailsTo = clientBasicInfo.adminEmails?.join(', ');
    }

    const initialProjectId = projects.length === 1 ? projects[0]._id : ''

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                initialValues={editMode ? initialValues : {
                    projectId: initialProjectId,
                    period: moment().subtract(1, 'months'),
                    issueDate: null,
                    dueDate: null,
                    emailsTo: hiddenSections && displayMode === ROLES.SUPPLIER_ADMIN ? '' : emailsTo,
                    emailsCc: '',
                    emailsBcc,
                    amount: '',
                    filesAttached: [],
                    currency: '',
                    invoiceNumber: ''
                }}
                enableReinitialize={true}
                validate={values => {
                    return {
                        ...validateRequiredFields(values, [
                            'projectId',
                            'period',
                            'issueDate',
                            'dueDate',
                            'amount',
                            'currency',
                            'filesAttached',
                            'emailsTo',
                            'invoiceNumber',
                        ]),
                        ...validateFilesArrayLength(values, 'filesAttached', 1),
                        ...validateDecimalNumbers(values, ['amount']),
                        ...validateEmailAddressesString(values, 'emailsTo'),
                        ...validateEmailAddressesString(values, 'emailsCc'),
                        ...validateEmailAddressesString(values, 'emailsBcc')
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    const { filesAttached, emailsTo, emailsCc, emailsBcc, ...rest } = values;

                    values = {
                        ...rest,
                        amount: parseFloat(rest.amount),
                        recipientEmails: {
                            to: emailsTo.split(',').map(x => x.toLowerCase()),
                            cc: emailsCc.split(',').map(x => x.toLowerCase()),
                            bcc: emailsBcc.split(',').map(x => x.toLowerCase()),
                        },
                        period: addUtcOffset(rest.period),
                        dueDate: addUtcOffset(rest.dueDate),
                        issueDate: addUtcOffset(rest.issueDate),
                    };

                    const data = new FormData();

                    data.append('invoiceInfo', JSON.stringify({ ...values, status: 'Pending', filesCount: filesAttached.length }));

                    filesAttached.forEach((file, i) => {
                        data.append(`attachedfile${i + 1}`, file.cloudName ? JSON.stringify(file) : file);
                    });

                    editMode ? editInvoice(agencyId, userId, values._id, data) : addInvoice(agencyId, userId, data, displayMode);
                    handleCloseDialog();
                }}
            >
                {(props) =>
                    <CommonFormikDialog
                        className="add-invoice-dialog"
                        open={openDialog}
                        onClose={handleCloseDialog}
                        maxWidth={'sm'}
                        onDragEnter={() => void 0}
                        fullWidth={true}
                        onSave={props.handleSubmit}
                        dialogTitle={editMode ? intl.formatMessage({ id: "edit-invoice" }) : intl.formatMessage({ id: "create-invoice" })}
                    >
                        <AddInvoiceFormView
                            {...props}
                            projects={projects}
                            isClientInvoice={isClientInvoice}
                            hiddenSections={hiddenSections}
                            displayMode={displayMode}
                        />
                    </CommonFormikDialog>
                }
            </Formik>
        </MuiPickersUtilsProvider>

    );
};

const mapStateToProps = state => ({
    projects: getClientProjects(state),
    displayMode: getDisplayMode(state),
    clientBasicInfo: getClientBasicInformation(state),
    hiddenSections: isHiddenSections(state),
    agencySettings: getAgencySettings(state),
});

const mapDispatchToprops = {
    fetchSupplierEmployeesProjects,
    fetchClientProjectsWithEmployees,
    fetchAgencySettings,
};

export default connect(mapStateToProps, mapDispatchToprops)(AddInvoiceDialog);
