import React from 'react';
import { ErrorMessage } from 'formik';

import TermsAndConditionsCheckBox from 'components/Shared/TermsAndConditionsCheckBox';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ROLE_SELECTION_OPTIONS } from './RoleSelectionConstants';

const RoleSelectionFormView = ({
    values,
    handleChange,
    handleSubmit,
    preselectedRole,
}) => {
    return (
        <form autoComplete="off">
            <RadioGroup
                value={values.role}
                name="role"
                className="options-labels-wrapper"
                onChange={handleChange}
            >
                {ROLE_SELECTION_OPTIONS[process.env.REACT_APP_ENV || process.env.NODE_ENV]
                    .filter(option => preselectedRole ? option.value === preselectedRole : true)
                    .map(option => (
                        <FormControlLabel
                            className={`options-label${option.value === values.role ? ' checked' : ''}`}
                            key={option.value}
                            value={option.value}
                            control={
                                <Radio
                                    className="radio-btn"
                                    icon={<div className="radio-btn-dot" />}
                                    checkedIcon={<div className="radio-btn-dot" />}
                                />
                            }
                            label={option.title}
                            labelPlacement="start"
                        />
                    ))}
                <ErrorMessage name="role" component="div" className="invalid-field-message" />
            </RadioGroup>
            <div className="terms-and-conditions-checkbox-wrapper">
                <TermsAndConditionsCheckBox
                    isTermsAndConditionsAccepted={values.isTermsAndConditionsAccepted}
                    handleChange={handleChange}
                />
                <ErrorMessage name="isTermsAndConditionsAccepted" component="div" className="invalid-field-message" />
            </div>
            <PrimaryButton className="select-a-role-action-btn" text="Next" handleClick={handleSubmit} />
        </form>
    );
};

export default RoleSelectionFormView;
