import React from 'react';
import { connect } from 'react-redux';

import './JobTypeBox.scss';

const JobTypeBox = ({
    selectJobsTab,
    jobsCount,
    jobType,
    jobTypeText,
    filters,
    displayMode,
}) => {
    const isActive = filters['jobType'] === jobType;

    return (
        <div
            className={`job-type-filter-box filter-box-${jobType.toLowerCase()}-jobs ${isActive ? 'active-jobs-box' : ''}`}
            onClick={selectJobsTab}
        >
            <h1>{jobsCount}</h1>
            <p>{jobTypeText}</p>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    jobsCount: ownProps.dataSelector(state),
});

export default connect(mapStateToProps)(JobTypeBox);
