import api from './api';
import requester from './requester';

const jobOportunityService = {
    addJobRecommendationRating: (agencyId, jobRecommendationRatingData) => requester(api.jobRecommendationRatings(agencyId)).create(jobRecommendationRatingData),
    updateJobRecommendationRating: (agencyId, recommendationId, data) => requester(api.jobRecommendationRating(agencyId, recommendationId)).update(data),
    deleteJobRecommendationRating: (agencyId, recommendationId) => requester(api.jobRecommendationRating(agencyId, recommendationId)).delete(),
}

export default jobOportunityService;
