export const ROLES = {
    EMPLOYEE: 'employee',
    ADMIN: 'admin',
    CLIENT: 'client',
    SUPPLIER_ADMIN: 'supplier-admin',
    SUPPLIER_EMPLOYEE: 'supplier-employee',
    RECRUITER: 'recruiter',
    RECRUITER_EMPLOYEE: 'recruiter-employee',
    CANDIDATE: 'candidate',
    SUPPLIER_ONLY: 'supplier',
    PARTNER: 'partner',
    UNASSIGNED: 'unassigned',
    UNASSIGNED_CLIENT: 'unassigned-client',
    UNASSIGNED_ADMIN: 'unassigned-admin',
};

export const ROLES_TO_ROUTE_MAPPER = {
    [ROLES.EMPLOYEE]: 'employee',
    [ROLES.SUPPLIER_EMPLOYEE]: 'employee',
    [ROLES.ADMIN]: 'admin',
    [ROLES.SUPPLIER_ADMIN]: 'partner',
    [ROLES.CANDIDATE]: 'candidate',
    [ROLES.CLIENT]: 'client',
};

export const EMPLOYEE_TYPES = {
    ON_DEMAND: 'on-demand',
    DEDICATED: 'dedicated'
};

export const REGISTRATION_TYPES = {
    INVITED_BY_ADMIN: 'Invited by admin',
    SELF_REGISTERED: 'Self-registered',
};

export const REFERRALS = {
    LINKEDIN: 'LinkedInPaidJobPost',
    GOOGLE: 'GoogleAdCampaign',
    WEBSITE_ORGANIC: 'WebsiteOrganic',
}

export const MOTION_USERS_WITH_ACCESS_TO_CONTRACTS = ['ggkLKVnH00gs12T2jw4dspTNuso2', 'ZEoDnVPOm3Zt3OHsJrQ0s5U8QEc2', 'g9nEUUsUBLUYcOnOYQSC5RqVRh32'];
