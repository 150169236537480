import React from 'react';
import { useIntl } from 'react-intl';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { ErrorMessage } from 'formik';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import { SUPPLIER_STATUSES } from 'constants/supplierConstants';

import './AddSupplierFormView.scss';

const AddSupplierFormView = ({
    values,
    handleChange,
    errors,
    touched,
    accountManagers,
    handleBlur,
}) => {
    const intl = useIntl();

    return (<form className="add-supplier-form" autoComplete="off">
        <div className="supplier-name-field">
            <TextField
                required
                name="name"
                value={values.name}
                onChange={handleChange}
                label={intl.formatMessage({ id: "name" })}
                error={errors.name && touched.name}
                margin="normal"
                variant="outlined"
                fullWidth
            />
            <ErrorMessage name="name" component="div" className="invalid-field-message" />
        </div>
        <div className="contact-person-field">
            <TextField
                required
                name="contactPerson"
                value={values.contactPerson}
                onChange={handleChange}
                label={intl.formatMessage({ id: "contact-person" })}
                error={errors.contactPerson && touched.contactPerson}
                margin="normal"
                variant="outlined"
                fullWidth
            />
            <ErrorMessage name="contactPerson" component="div" className="invalid-field-message" />
        </div>
        <div className="email-field">
            <TextField
                name="email"
                value={values.email}
                onChange={handleChange}
                label={intl.formatMessage({ id: "email" })}
                error={errors.email && touched.email}
                margin="normal"
                variant="outlined"
                fullWidth
            />
            <ErrorMessage name="email" component="div" className="invalid-field-message" />
        </div>
        <div className="supplier-status-field">
            <TextField
                select
                required
                name="invoiceStatus"
                value={values.status}
                onChange={handleChange}
                label={intl.formatMessage({ id: "status" })}
                error={errors.status && touched.status}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                fullWidth
            >
                {SUPPLIER_STATUSES.map((status, i) => (
                    <MenuItem
                        native="true"
                        key={i}
                        value={status}
                    >
                        {intl.formatMessage({ id: convertStringToLanguageKey(status) })}
                    </MenuItem>
                ))}
            </TextField>
            <ErrorMessage name="invoiceStatus" component="div" className="invalid-field-message" />
        </div>
        <div className="account-manager-field">
            <TextField
                select
                required
                name="accountManager"
                value={values.accountManager}
                onChange={handleChange}
                label={intl.formatMessage({ id: "account-manager" })}
                error={errors.accountManager && touched.accountManager}
                margin="normal"
                variant="outlined"
                fullWidth
            >
                {accountManagers.map((accountManager, i) => (
                    <MenuItem
                        native="true"
                        key={i}
                        value={accountManager._id}
                    >
                        {`${accountManager.name}`}
                    </MenuItem>
                ))}
            </TextField>
            <ErrorMessage name="accountManager" component="div" className="invalid-field-message" />
        </div>
    </form>)
};


export default AddSupplierFormView;
