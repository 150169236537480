import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import AgencySettingsTeamNotificationsFormView from './AgencySettingsTeamNotificationsFormView';

import { validateEmailAddress } from 'utils/formValidations';

import './AgencySettingsTeamNotifications.scss';

const AgencySettingsTeamNotifications = ({
    agencyId,
    agencySettings,
    updateAction,
}) => {
    const intl = useIntl();

    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const handleShowHeaderButtons = () => setShowHeaderButtons(true);

    const teamNotificationsSettingsFormRef = useRef();

    const discardHandlerBasicInfo = () => {
        setShowHeaderButtons(false);
        teamNotificationsSettingsFormRef.current.resetForm();
    };

    return (
        <div className="agency-settings-wrapper agency-team-notifications-settings">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "team-notifications" })}
                />

                <Tooltip title={intl.formatMessage({ id: "team-notifications-tooltip-text" })} classes={{ tooltip: 'custom-tooltip' }}>
                    <InfoOutlinedIcon className="global-information-icon" />
                </Tooltip>

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "discard" })}
                            onClick={discardHandlerBasicInfo}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "save" })}
                            onClick={() => teamNotificationsSettingsFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>
            <Paper className="team-notifications-settings-content">
                <Formik
                    initialValues={{
                        recruitmentEmail: agencySettings?.recruitmentEmail || '',
                        financeEmail: agencySettings?.financeEmail || '',
                        hrEmail: agencySettings?.hrEmail || '',
                    }}
                    innerRef={teamNotificationsSettingsFormRef}
                    validate={values => {
                        return {
                            ...validateEmailAddress(values, 'recruitmentEmail'),
                            ...validateEmailAddress(values, 'financeEmail'),
                            ...validateEmailAddress(values, 'hrEmail'),
                        };
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        updateAction(agencyId, values);
                    }}
                    enableReinitialize={true}
                >
                    {(props) =>
                        <AgencySettingsTeamNotificationsFormView
                            handleShowHeaderButtons={handleShowHeaderButtons}
                            {...props}
                        />
                    }
                </Formik>
            </Paper>
        </div>
    )
};

export default AgencySettingsTeamNotifications;
