import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {useHistory} from 'react-router-dom';

import { Typography } from '@material-ui/core';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { updateCandidatesJourneyCompletedSteps } from 'actions/candidateActions';
import { getAgencyId, getCurrentJourneyStepId, getInitialJourneyCompletedStepsIds, getUserId, getAgencyRouteName } from 'reducers';

import { ReactComponent as FinishProfileImage } from 'assets/finish-profile.svg';
import { ReactComponent as ExploreJobsImage } from 'assets/explore-jobs.svg';
import { ReactComponent as FinishProfileImageMobile } from 'assets/finish-profile-mobile.svg';
import { ReactComponent as ExploreJobsImageMobile } from 'assets/explore-jobs-mobile.svg';

import { logEvent } from 'utils/amplitude';
import { parseQueryString } from 'utils/helpers';

import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { CALENDLY_DIALOG_STATES, substepIds } from 'components/CandidateProfileJourney/CandidateProfileJourneyConstants';

import './CandidatesJourneyNearlyThere.scss';

const Option = ({ imageComponent, text, buttonText, btnOnClick, heading, className }) => {
    return (
        <div className={`cjnt-option ${className ? className : ''}`}>
            <div className="option-image-wrapper">{imageComponent}</div>
            <Typography className="cjnt-option-heading">{heading}</Typography>
            <Typography className="cjnt-option-text">{text}</Typography>
            <PrimaryButton className={'option-btn'} text={buttonText} handleClick={btnOnClick}/>
        </div>
    );
};

const CandidatesJourneyNearlyThere = ({
    handleGoToNextStep,
    stepId,
    completedStepsIds,
    updateCandidatesJourneyCompletedSteps,
    userId,
    agencyId,
    agencyRouteName
}) => {
    const history = useHistory();
    const condition = !completedStepsIds.includes(stepId);
    const action = updateCandidatesJourneyCompletedSteps.bind(null, agencyId, userId);

    useEffect(() => {
        const queryString = history.location.search;
        const params = parseQueryString(queryString);
        const isChoiceSectionStepCompleted = completedStepsIds.includes(substepIds.choiseSection);

        if (params.calendlyDialogState === CALENDLY_DIALOG_STATES.OPEN && !isChoiceSectionStepCompleted) {
            goToNextStep();
        }

    }, [history.location.search]);
    
    const goToNextStep = () => {
        logEvent(AMPLITUDE_EVENT_TYPES.CANDIDATE_CLICK_FINISH_PROFILE);
        handleGoToNextStep(null, condition, action);
    }

    const goToJobsPage = () => {
        logEvent(AMPLITUDE_EVENT_TYPES.CANDIDATE_CLICK_EXPLORE_JOBS);
        const jobPrefix = history.location?.pathname?.includes('create-profile') ? '/create-profile' : '';
        history.push(`/${agencyRouteName}${jobPrefix}/jobs?selectedTab=Recommended`);
    }

    return (
        <div className="candidates-nearly-there-wrapper">
            <div className="candidates-nearly-there-container">
                <div className="text-container">
                    <Typography className="heading">You're nearly there!</Typography>
                    <Typography className="text-container-text">
                        Thanks for completing your profile! We prepared a list of curated remote jobs that you can now
                        explore. Remember - the more complete your profile is, the better recommendations you'll see.
                    </Typography>
                    <Typography className="text-container-text">
                        Before you're able to apply for jobs, we'd like to go the extra mile for knowing you. You can
                        record a 2-minute intro video or book a call with our team. We've prepared a few questions to
                        guide your answer!
                    </Typography>
                </div>
                <div className="cjnt-options-container">
                    <Option
                        className={'finish-profile-option'}
                        imageComponent={isMobile ? <FinishProfileImageMobile /> : <FinishProfileImage />}
                        buttonText={'Continue'}
                        btnOnClick={goToNextStep}
                        heading={'Finish Profile'}
                        text={`Record your informal intro video and show us you're a real star. Candidates who do it are 3
                            times more successful.`}
                    />
                    <Option
                        className={'explore-jobs-option'}
                        imageComponent={isMobile ? <ExploreJobsImageMobile /> : <ExploreJobsImage />}
                        buttonText={'Browse'}
                        btnOnClick={goToJobsPage}
                        heading={'Explore jobs'}
                        text={`See positions that match your skills. The list is updated regularly, but will notify you via email when new jobs become available.`}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    stepId: getCurrentJourneyStepId(state),
    completedStepsIds: getInitialJourneyCompletedStepsIds(state),
    agencyId: getAgencyId(state),
    userId: getUserId(state),
    agencyRouteName: getAgencyRouteName(state),
});

const mapDispatchToProps = {
    updateCandidatesJourneyCompletedSteps,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesJourneyNearlyThere);
