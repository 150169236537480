import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { sendPasswordResetEmail } from 'actions/authActions';

import ProfileCreationCard from 'components/Shared/ProfileCreationCard';
import FormInputField from 'components/Shared/FormInputField';

import { validateRequiredFields, validateEmailAddress } from 'utils/formValidations';

const ForgottenPassword = ({
    sendPasswordResetEmail,
}) => (
    <ProfileCreationCard>
        <ProfileCreationCard.Header />
        <ProfileCreationCard.ContentWrapper>
            <ProfileCreationCard.ContentTitle className="normal-text">
                Enter your email and we will send you a password reset link
            </ProfileCreationCard.ContentTitle>
            <ProfileCreationCard.ContentDivider />
            <Formik
                initialValues={{
                    email: ''
                }}
                validate={values => {
                    return {
                        ...validateRequiredFields(values, ['email']),
                        ...validateEmailAddress(values, 'email'),
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    sendPasswordResetEmail(values.email.toLowerCase().trim());
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => {
                    const isEmailRequirementNotMet = errors.email && touched.email;

                    return (
                        <>
                            <ProfileCreationCard.ContentBody>
                                <form autoComplete='off'>
                                    <FormInputField
                                        className="smaller"
                                        withInputLabel
                                        label="Email"
                                        placeholder="Enter your email address here"
                                        name="email"
                                        value={values.email}
                                        showValidationRequirement={isEmailRequirementNotMet}
                                        requirementText={errors.email}
                                        isRequirementNotMet={isEmailRequirementNotMet}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                    <ProfileCreationCard.ContentAction
                                        text='Send Request'
                                        handleClick={handleSubmit}
                                    />
                                </form>
                            </ProfileCreationCard.ContentBody>
                        </>
                    )
                }}
            </Formik>

        </ProfileCreationCard.ContentWrapper>
    </ProfileCreationCard>
);

const mapDispatchToProps = {
    sendPasswordResetEmail
};

export default connect(null, mapDispatchToProps)(ForgottenPassword);
