import React from 'react';

import NothingToShow from 'components/NothingToShow';

import './EmptyJobsList.scss';

const EmptyJobsList = ({
    filters,
}) => {
    const getContentForEmptyState = () => {
        if (Object.values(filters).every(x => !x)) {
            return {
                emptyStateComponent: <NothingToShow items="jobs" />,
            };
        } else {
            return {
                emptyStateComponent: <NothingToShow message="No jobs match your criteria" />,
            };
        }
    };

    const { emptyStateComponent } = getContentForEmptyState();

    return (
        <div className="empty-jobs-list-wrapper">
            {emptyStateComponent}
        </div>
    );
};

export default EmptyJobsList;
