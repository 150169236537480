import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { fetchJobOpportunityDetails } from 'actions/jobOpportunityActions';
import { useHistory } from 'react-router-dom';
import {
    getJobOpportunityDetails,
    getJobOpportunityDetailsDataFetchState,
    getApplicationProcessInformationFetchState,
    getAgencyRouteName,
    getPersonalInfo,
} from 'reducers';

import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import ApplicationProcessBanner from 'components/Shared/ApplicationProcessBanner';
import { CircularProgress } from '@material-ui/core';

import Job from '../Job';

import './JobDetails.scss';

const JobDetails = ({
    match,
    agencyId,
    jobOpportunityDetails,
    agencyRouteName,
    dataFetchState,
    applicationProcessInformationFetchState,
    fetchJobOpportunityDetails,
    isCandidateMode,
    candidatePersonalInformation,
}) => {
    const history = useHistory();

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    useEffect(() => {
        fetchJobOpportunityDetails(agencyId, match.params.id);
    }, []);

    const handleGoBackBtnClick = () => {
        const queryString = history.location.search;

        const backPath = match.path.includes('create-profile')
            ? `create-profile/jobs${queryString}`
            : `job-opportunities${queryString}`;

        history.push(`/${agencyRouteName}/${backPath}`)
    }

    const applyBtnRef = useRef(null);

    const closeDialog = (e) => {
        if (!applyBtnRef.current?.contains(e.target)) {
            setIsConfirmDialogOpen(false)
        }
    }

    const handleBackToFinishProcess = () => {
        history.push(`/${agencyRouteName}/create-profile`)
    };

    return (
        <div className="job-details-container" onClick={(e) => closeDialog(e)}>
            <div className="job-details-button-container">
                <PreviousButton text={"Back to Jobs"} handleClick={handleGoBackBtnClick} />
            </div>
            {dataFetchState?.isDataFetching || applicationProcessInformationFetchState?.isDataFetching
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <>
                    {isCandidateMode && <ApplicationProcessBanner />}
                        <Job
                            agencyId={agencyId}
                            jobOpportunity={jobOpportunityDetails}
                            isConfirmDialogOpen={isConfirmDialogOpen}
                            setIsConfirmDialogOpen={setIsConfirmDialogOpen}
                            applyBtnRef={applyBtnRef}
                            candidateStatus={candidatePersonalInformation?.status}
                            handleBackToFinishProcess={handleBackToFinishProcess}
                        />
                </>
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        jobOpportunityDetails: getJobOpportunityDetails(state),
        dataFetchState: getJobOpportunityDetailsDataFetchState(state),
        applicationProcessInformationFetchState: getApplicationProcessInformationFetchState(state),
        agencyRouteName: getAgencyRouteName(state),
        candidatePersonalInformation: getPersonalInfo(state),
    }
}

const mapDispatchToProps = {
    fetchJobOpportunityDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails)
