import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { motionRedirectLinks } from 'components/Register/RegisterConstants';

import './TermsAndConditionsCheckBox.scss';

const TermsAndConditionsCheckBox = ({
    isTermsAndConditionsAccepted,
    handleChange,
}) => {

    return (
        <FormControlLabel
            className="terms-and-conditions-label"
            label={
                <div>
                    I agree to the platform's <a href={motionRedirectLinks.privacy.link} target="_blank">{motionRedirectLinks.privacy.label}</a>.
                </div>
            }
            control={
                <Checkbox
                    className="terms-and-conditions-checkbox"
                    color="primary"
                    name="isTermsAndConditionsAccepted"
                    checked={isTermsAndConditionsAccepted}
                    onChange={handleChange}
                />
            }
        />
    );
};

export default TermsAndConditionsCheckBox;
