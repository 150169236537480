import React from 'react';
import ConfirmDialog from 'components/Shared/ConfirmDialog';
import { CONFIRM_DIALOG_EMPLOYEE_TERMINATION_CONFIRMATION_ITEMS } from 'components/Shared/ConfirmDialog/ConfirmDialogConstants';

const ConfirmEmployeeTerminationDialog = ({
    openDialog,
    handleConfirmDialog,
    handleCloseDialog
}) => {
    return (
        <ConfirmDialog
            openDialog={openDialog}
            dialogTitle={CONFIRM_DIALOG_EMPLOYEE_TERMINATION_CONFIRMATION_ITEMS.DIALOG_TITLE}
            confirmButtonName={CONFIRM_DIALOG_EMPLOYEE_TERMINATION_CONFIRMATION_ITEMS.CONFIRM_BUTTON_NAME}
            discardButtonName={CONFIRM_DIALOG_EMPLOYEE_TERMINATION_CONFIRMATION_ITEMS.DISCARD_BUTTON_NAME}
            handleConfirmDialog={handleConfirmDialog}
            handleCloseDialog={handleCloseDialog}
        />
    );
};

export default ConfirmEmployeeTerminationDialog;
