import React, { useContext } from 'react';

import { Typography } from '@material-ui/core';

import { TableWrapperContext } from '../TableWrapper';

const TableHeader = () => {
    const { title } = useContext(TableWrapperContext);

    return <Typography variant="h1">{title}</Typography>
};

export default TableHeader;
