import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { addUtcOffset } from 'utils/formatTimes';
import {
    validateCountryOfTaxResidence,
    validateDateFields,
    validateRequiredFields,
    validateAreDatesBeforeMinDate,
    validateInputForPositiveIntegerType,
    validateConditionalField,
} from 'utils/formValidations';
import { SUPPORTED_COUNTRIES_LIST, COUNTRY_OF_TAX_OTHER } from 'constants/candidateConstants';

const CandidateJourneyPreferencesFormik = ({
    candidatePreferences,
    updatePreferences,
    handleGoToNextStep,
    children,
}) => {
    return (
        <Formik
            initialValues={{
                salaryValue: candidatePreferences?.salaryValue || '',
                salaryCurrency: candidatePreferences?.salaryCurrency || '',
                countryOfTax: candidatePreferences?.countryOfTax && !SUPPORTED_COUNTRIES_LIST.includes(candidatePreferences?.countryOfTax)
                    ? COUNTRY_OF_TAX_OTHER
                    : candidatePreferences?.countryOfTax || '',
                otherCountryOfTax: candidatePreferences?.countryOfTax && !SUPPORTED_COUNTRIES_LIST.includes(candidatePreferences?.countryOfTax)
                    ? candidatePreferences?.countryOfTax
                    : '',
                earliestDate: candidatePreferences?.earliestDate ? moment.utc(candidatePreferences.earliestDate) : null,
                isEarliestDateUnknown: candidatePreferences?.isEarliestDateUnknown || false,
            }}
            validate={(values) => {
                const requiredFields = values.isEarliestDateUnknown
                    ? ['salaryValue', 'salaryCurrency', 'countryOfTax']
                    : ['salaryValue', 'salaryCurrency', 'countryOfTax', 'earliestDate'];
                return {
                    ...validateDateFields(values, ['earliestDate']),
                    ...validateAreDatesBeforeMinDate(values, ['earliestDate']),
                    ...validateCountryOfTaxResidence(values, 'countryOfTax'),
                    ...validateRequiredFields(values, requiredFields),
                    ...validateInputForPositiveIntegerType(values, ['salaryValue']),
                    ...validateConditionalField(values, 'countryOfTax', COUNTRY_OF_TAX_OTHER, 'otherCountryOfTax')
                };
            }}
            onSubmit={(values, { resetForm }) => {
                const preferencesData = {
                    ...candidatePreferences,
                    salaryValue: values.salaryValue,
                    salaryCurrency: values.salaryCurrency,
                    countryOfTax: values.countryOfTax === COUNTRY_OF_TAX_OTHER ? values.otherCountryOfTax : values.countryOfTax,
                    earliestDate: addUtcOffset(values.earliestDate),
                    isEarliestDateUnknown: values.isEarliestDateUnknown,
                };

                const isDirty = !isEqual(candidatePreferences, preferencesData);
                const action = updatePreferences.bind(null, preferencesData, resetForm);
                //here for testing purposes we pass null as a first argument 
                //there is a full description in the CandidateProfileJourney.js file 
                handleGoToNextStep(null, isDirty, action);
            }}
            enableReinitialize={true}
        >
            {children}
        </Formik>
    );
};

export default CandidateJourneyPreferencesFormik;
