// Contract Table
export const contractColumns = [
	{ name: 'Name', value: 'contractName', fieldValue: 'name', isSortable: true },
	{ name: 'Total Hours', value: 'contractTotalHours', fieldValue: 'trackedHours', isSortable: true },
	{ name: 'Rate', value: 'contractRate', fieldValue: 'hourRate', isSortable: true },
	{ name: 'Gained', value: 'contractGained', isSortable: false },
	{ name: 'Spent', value: 'contractSpent', isSortable: false },
	{ name: 'Start Date', value: 'contractDateAdded', fieldValue: 'dateAdded', isSortable: true },
	{ name: 'Last Active Date', value: 'contractDateDeleted', fieldValue: 'dateDeleted', isSortable: true },
];

export const defaultContractColumns = ['contractName', 'contractTotalHours', 'contractRate', 'contractGained', 'contractSpent', 'contractDateAdded', 'contractDateDeleted'];
export const CONTRACT_QUERY_SELECTOR = 'contractSort';
export const CONTRACT_PAGE_SELECTOR = 'contractPage';

// Full-Time Table
export const fullTimeColumns = [
	{ name: 'Name', value: 'fullTimeName', fieldValue: 'name', isSortable: true },
	{ name: 'Total Hours', value: 'fullTimeTotalHours', fieldValue: 'trackedHours', isSortable: true },
	{ name: 'Gained', value: 'fullTimeGained', isSortable: false },
	{ name: 'Spent', value: 'fullTimeSpent', isSortable: false },
	{ name: 'TCE', value: 'fullTimeTce', fieldValue: 'employerTotalCost', isSortable: true },
	{ name: 'Service fee', value: 'fullTimeServiceFee', fieldValue: 'monthlyServiceFee', isSortable: true },
	{ name: 'Start Date', value: 'fullTimeDateAdded', fieldValue: 'dateAdded', isSortable: true },
	{ name: 'Last Active Date', value: 'fullTimeDateDeleted', fieldValue: 'dateDeleted', isSortable: true },
];

export const defaultFullTimeColumns = ['fullTimeName', 'fullTimeTotalHours', 'fullTimeGained', 'fullTimeSpent', 'fullTimeTce', 'fullTimeServiceFee', 'fullTimeDateAdded', 'fullTimeDateDeleted'];
export const FULL_TIME_QUERY_SELECTOR = 'fullTimeSort';
export const FULL_TIME_PAGE_SELECTOR = 'fullTimePage';

// All Members Table
export const teamMembersColumns = [
	{ name: 'Name', value: 'teamName', fieldValue: 'name', isSortable: true, langKey: "name" },
	{ name: 'Total Hours', value: 'teamTotalHours', fieldValue: 'trackedHours', isSortable: true, langKey: "total-hours" },
	{ name: 'Gained', value: 'teamGained', fieldValue: 'gained', isSortable: true, langKey: "gained" },
	{ name: 'Spent', value: 'teamSpent', fieldValue: 'spent', isSortable: true, langKey: "spent" },
	{ name: 'TCE', value: 'teamTce', fieldValue: 'employerTotalCost', isSortable: true, langKey: "tce" },
	{ name: 'Rate', value: 'teamRate', fieldValue: 'hourRate', isSortable: true, langKey: "rate" },
	{ name: 'Service fee', value: 'teamServiceFee', fieldValue: 'monthlyServiceFee', isSortable: true, langKey: "service-fee" },
	{ name: 'Start Date', value: 'teamDateAdded', fieldValue: 'dateAdded', isSortable: true, langKey: "start-date" },
	{ name: 'Last Active Date', value: 'teamDateDeleted', fieldValue: 'dateDeleted', isSortable: true, langKey: "last-active-date" },
];

export const defaultTeamMembersColumns = ['teamName', 'teamTotalHours', 'teamGained', 'teamSpent', 'teamTce', 'teamRate', 'teamServiceFee', 'teamDateAdded'];
export const TEAM_MEMBERS_QUERY_SELECTOR = 'teamMembersSort';
export const TEAM_MEMBERS_PAGE_SELECTOR = 'teamMembersPage';

// Contract History Table
export const contractHistoryColumns = [
	{ name: 'Name', value: 'contractHistoryName', fieldValue: 'name', isSortable: true },
	{ name: 'Period', value: 'contractHistoryPeriod', fieldValue: 'dateAdded', isSortable: true },
];

export const defaultContractHistoryColumns = ['contractHistoryName', 'contractHistoryPeriod'];
export const CONTRACT_HISTORY_QUERY_SELECTOR = 'contractHistorySort';
export const CONTRACT_HISTORY_PAGE_SELECTOR = 'contractHistoryPage';

// Full-Time History Table
export const fullTimeHistoryColumns = [
	{ name: 'Name', value: 'fullTimeHistoryName', fieldValue: 'name', isSortable: true },
	{ name: 'Period', value: 'fullTimeHistoryPeriod', fieldValue: 'dateAdded', isSortable: true },
];

export const defaultFullTimeHistoryColumns = ['fullTimeHistoryName', 'fullTimeHistoryPeriod'];
export const FULL_TIME_HISTORY_QUERY_SELECTOR = 'fullTimeHistorySort';
export const FULL_TIME_HISTORY_PAGE_SELECTOR = 'fullTimeHistoryPage';

// All Members History Table
export const teamMembersHistoryColumns = [
	{ name: 'Name', value: 'teamHistoryName', fieldValue: 'name', isSortable: true, langKey: "name" },
	{ name: 'Period', value: 'teamHistoryPeriod', fieldValue: 'dateAdded', isSortable: true, langKey: "period" },
];

export const defaultTeamMembersHistoryColumns = ['teamHistoryName', 'teamHistoryPeriod'];
export const TEAM_HISTORY_QUERY_SELECTOR = 'teamHistorySort';
export const TEAM_HISTORY_PAGE_SELECTOR = 'teamHistoryPage';

export const HISTORY_DIALOG_CONSTANTS = {
	RATE: {
		TITLE: 'Rate History',
		SUBTITLE: 'Last Rate Changes'
	}, 
	TCE: {
		TITLE: 'TCE History',
		SUBTITLE: 'TCE',
		FIELD: 'employerTotalCost',
	},
	SERVICE_FEE: {
		TITLE: 'Service fee History',
		SUBTITLE: 'Service fee',
		FIELD: 'monthlyServiceFee',
	}
}
