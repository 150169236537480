import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getUserId, getClientUsers } from 'reducers';
import { disableUsers, fetchClientUsers, updateUser } from 'actions/userActions';
import { registerClient } from 'actions/clientActions';

import ContactPersonForm from './ContactPersonForm';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import TableWrapper from 'components/Shared/TableWrapper/TableWrapper';

import { DEFAULT_ITEMS_PER_PAGE, INITIAL_QUERY, PAGE_QUERY_SELECTOR, QUERY_PARAMS_MAP, SEARCH_QUERY_SELECTOR, SORT_QUERY_SELECTOR, defaultColumns, tableColumns } from './CompanyInformationContactPeopleConstants';

import { ReactComponent as AddIcon } from 'assets/add-button-icon.svg';

import { usePreloadTable } from 'utils/hooks';

const CompanyInformationContactPeople = ({
    userId,
    agencyId,
    companyId,
    fetchClientUsers,
    updateUser,
    registerClient,
    accountManager,
    disableUsers,
}) => {
    const intl = useIntl();

    const actionFunction = fetchClientUsers.bind(null, companyId);
    const dataSelector = (state, page) => getClientUsers(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const [reload, setReload] = useState(false);
    const [isTableDataLoading, totalCount] = usePreloadTable(INITIAL_QUERY, QUERY_PARAMS_MAP, null, actionFunction, reload, setReload);

    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    const adjustKeysFunction = (clients) => clients.map((client) => ({
        _id: client._id,
        name: `${client.firstName} ${client.lastName}`,
        email: client.email,
        item: client,
    }));

    const handleEditClick = (client) => {
        setSelectedUser(client);
        setIsEditMode(true);
        setIsAddDialogOpen(true);
    };

    const handleRemoveClick = (client) => {
        setSelectedUser(client)
        setIsDeleteDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsEditMode(false);
        setIsAddDialogOpen(false);
        setIsDeleteDialogOpen(false);
    };

    const handleAddContactClick = () => setIsAddDialogOpen(true);

    const handleAdd = (...props) => {
        registerClient(...props, false).then(() => {
            setReload(true);
        });
    }

    const handleEdit = (...props) => {
        updateUser(...props, false).then(() => {
            setReload(true);
        });
    };

    const handleDelete = () => {
        disableUsers([selectedUser._id]);
        handleCloseDialog();
    };

    const defaultActions = [
        { title: intl.formatMessage({ id: "edit" }), handleClick: handleEditClick },
        { title: intl.formatMessage({ id: "delete" }), handleClick: handleRemoveClick }
    ];

    const getPopoverActions = () => defaultActions;

    return (
        <Fragment>
            <TableWrapper
                title={intl.formatMessage({ id: "client-team-active-users" })}
                columns={tableColumns}
                defaultColumns={defaultColumns}
                dataSelector={dataSelector}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustKeysFunction}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withPagination
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
                withActions
                getActionsHandler={getPopoverActions}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableSearch queryParamName={SEARCH_QUERY_SELECTOR} />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton text={intl.formatMessage({ id: "add-client-admin" })} icon={<AddIcon />} handleClick={handleAddContactClick} />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>
            <ContactPersonForm
                isOpen={isAddDialogOpen}
                handleCloseDialog={handleCloseDialog}
                userId={userId}
                clientId={companyId}
                agencyId={agencyId}
                registerClient={handleAdd}
                handleEdit={handleEdit}
                accountManager={accountManager}
                isEditMode={isEditMode}
                user={selectedUser}
                isFirstClientUser={totalCount === 0}
            />

            <ConfirmDeleteDialog
                itemToDelete="this user"
                handleDeleteItem={handleDelete}
                openDialog={isDeleteDialogOpen}
                handleCloseDialog={handleCloseDialog}
            />
        </Fragment>
    )
};

const mapStateToProps = state => ({
    userId: getUserId(state),
});
 
const mapDispatchToProps = {
    registerClient,
    fetchClientUsers,
    updateUser,
    disableUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInformationContactPeople);
