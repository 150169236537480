import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './CustomCheckbox.scss';

const CustomCheckbox = ({
    className,
    isChecked,
    onChangeHandler,
    size,
    disabled,
}) => (
    <Checkbox
        className={className ? `custom-checkbox ${className}` : 'custom-checkbox'}
        checked={isChecked}
        onChange={onChangeHandler}
        size={size}
        disabled={disabled}
    />
);

export default CustomCheckbox;
