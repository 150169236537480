import jobRecommendationRatingService from 'services/jobRecommendationRatingService';

import { updateJobSuccess } from './jobOpportunityActions';

import { authorizedShowNotification } from './actionHelpers';

import { JOB_RECOMMENDATION_RATING_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';

export const addJobRecommendationRating = (agencyId, jobRecommendationRatingData) => async dispatch => {
    try {
        const result = await jobRecommendationRatingService.addJobRecommendationRating(agencyId, jobRecommendationRatingData);

        dispatch(updateJobSuccess({ _id: jobRecommendationRatingData.jobOpportunityId, recommendationRating: result }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_RECOMMENDATION_RATING_MESSAGES.RATE_JOB_RECOMMENDATION_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const updateJobRecommendationRating = (agencyId, recommendationId, data) => async dispatch => {
    try {
        const result = await jobRecommendationRatingService.updateJobRecommendationRating(agencyId, recommendationId, data);

        dispatch(updateJobSuccess({ _id: data.jobOpportunityId, recommendationRating: result }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_RECOMMENDATION_RATING_MESSAGES.RATE_JOB_RECOMMENDATION_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const deleteJobRecommendationRating = (agencyId, recommendationId, jobId) => async dispatch => {
    try {
        await jobRecommendationRatingService.deleteJobRecommendationRating(agencyId, recommendationId);

        dispatch(updateJobSuccess({ _id: jobId, recommendationRating: {} }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_RECOMMENDATION_RATING_MESSAGES.RATE_JOB_RECOMMENDATION_FAIL, NOTIFICATION_TYPES.ERROR))
    }
}; 
