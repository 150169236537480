import { auth } from 'utils/firebase';
import { logEvent } from 'utils/amplitude';
import { v4 as uuid } from 'uuid';

import userService from 'services/userService';

import {
    USERS_DISABLE_SUCCESS,
    USERS_RESTORE_SUCCESS,
    CLIENT_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS,
    RECRUITER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS,
    SUPPLIER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS,
    USER_PERMANENTLY_DELETE_SUCCESS,
    ACCOUNT_MANAGER_DATA_FETCH_SUCCESS,
    ACCOUNT_MANAGER_DATA_FETCHING_SUCCESS,
    USER_TRANSFER_SUCCESS,
    CLIENT_USERS_FETCH_SUCCESS,
    USER_UPDATE_SUCCESS,
    USER_TERMINATION_DATE_UPDATE_SUCCESS,
    CLIENT_USERS_REPLACE_SUCCESS
} from './actionTypes';

import { fetchUserInformationSuccess, refreshTokenSuccess } from './authActions';
import { fetchHomeInformationWithToken } from './tokenPropActions';
import { fetchCandidateApplicationsSuccess } from './jobApplicationActions'
import { fetchTermsAndConditionsSucces, fetchAgencyNameSuccess, fetchMainMenuInformationSuccess, initialJourneyFetchSuccess } from './agencyActions'
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';
import { fetchEmployeesPending, fetchEmployeesSuccess } from 'actions/sharedActions';
import { authorizedShowNotification } from 'actions/actionHelpers';
import { showNotification } from 'actions/notificationActions';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { USER_MESSAGES, EMPLOYEE_MESSAGES, AGENCY_MESSAGES, EMAIL_MESSAGES } from 'constants/messageConstants';
import { ROLES } from 'constants/userConstants';
import { CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS } from 'constants/candidateConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

const disableUsersSuccess = (userIds) => ({
    type: USERS_DISABLE_SUCCESS,
    payload: userIds
});

const permanentlyDeleteUserSuccess = userId => ({
    type: USER_PERMANENTLY_DELETE_SUCCESS,
    payload: userId
});

const restoreUsersSuccess = (userIds) => ({
    type: USERS_RESTORE_SUCCESS,
    payload: userIds
});

const fetchClientAccountManagerInformationSuccess = accountManagerData => ({
    type: CLIENT_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS,
    payload: accountManagerData
});

const fetchSupplierAccountManagerInformationSuccess = accountManagerData => ({
    type: SUPPLIER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS,
    payload: accountManagerData
});

const fetchRecruiterAccountManagerInformationSuccess = accountManagerData => ({
    type: RECRUITER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS,
    payload: accountManagerData
});

const accountManagerDataFetchSuccess = () => ({
    type: ACCOUNT_MANAGER_DATA_FETCH_SUCCESS,
});

const accountManagerDataFetchingSuccess = () => ({
    type: ACCOUNT_MANAGER_DATA_FETCHING_SUCCESS,
});

const transferToAgencySuccess = data => ({
    type: USER_TRANSFER_SUCCESS,
    payload: data,
});

const fetchClientUsersSuccess = data => ({
    type: CLIENT_USERS_FETCH_SUCCESS,
    payload: data,
});

const clientUsersReplaceSuccess = (payload) => ({
    type: CLIENT_USERS_REPLACE_SUCCESS,
    payload,
});

const updateUserSuccess = data => ({
    type: USER_UPDATE_SUCCESS,
    payload: data,
});

const updateUserTerminationDateSuccess = data => ({
    type: USER_TERMINATION_DATE_UPDATE_SUCCESS,
    payload: data,
});

export const disableUsers = (userIds, role, updateState = true) => async dispatch => {
    try {
        const disabledUserIds = await userService.disableUsers({ userIds, role });
        updateState && await dispatch(disableUsersSuccess(disabledUserIds));

        dispatch(showNotification(USER_MESSAGES.DELETE_USERS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(USER_MESSAGES.DELETE_USERS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const restoreUsers = (userIds, role) => async dispatch => {
    try {
        const restoredUsersIds = await userService.restoreUsers({ userIds, role });
        await dispatch(restoreUsersSuccess(restoredUsersIds));

        dispatch(showNotification(USER_MESSAGES.RESTORE_USERS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(USER_MESSAGES.RESTORE_USERS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const terminateUserEmployment = (userId, terminationDate) => async dispatch => {
    try {
        const data = await userService.terminateUserEmployment({ userId, terminationDate });

        dispatch(updateUserTerminationDateSuccess({ terminationDate: data.terminationDate, isDeleted: data.isDeleted }));
        dispatch(showNotification(USER_MESSAGES.TERMINATE_USER_EMPLOYMENT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(USER_MESSAGES.TERMINATE_USER_EMPLOYMENT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const permanentlyDeleteUser = (userId) => async dispatch => {
    try {
        const deletedUserId = await userService.permanentlyDeleteUser(userId);
        await dispatch(permanentlyDeleteUserSuccess(deletedUserId));

        dispatch(showNotification(USER_MESSAGES.PERMANENTLY_DELETE_USERS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(USER_MESSAGES.PERMANENTLY_DELETE_USERS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const requestCustomerSupport = requestData => async dispatch => {
    try {
        await userService.requestCusomerSupport(requestData);

        dispatch(showNotification(USER_MESSAGES.CUSTOMER_SUPPORT_REQUEST_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(USER_MESSAGES.CUSTOMER_SUPPORT_REQUEST_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

const FETCH_ACCOUNT_MANAGER_INFORMATION_MAPPER = {
    [ROLES.CLIENT]: fetchClientAccountManagerInformationSuccess,
    [ROLES.SUPPLIER_ADMIN]: fetchSupplierAccountManagerInformationSuccess,
    [ROLES.RECRUITER]: fetchRecruiterAccountManagerInformationSuccess,
};

export const fetchAccountManagerInformation = (agencyId, userId, role) => async dispatch => {
    try {
        dispatch(accountManagerDataFetchingSuccess());
        const accountManager = await userService.fetchUserAccountManagerInformation(agencyId, userId);
        dispatch(FETCH_ACCOUNT_MANAGER_INFORMATION_MAPPER[role]({ accountManager }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(USER_MESSAGES.FETCH_USER_ACCOUNT_MANAGER_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(accountManagerDataFetchSuccess());
    }
};

export const fetchAllHottestTalents = (agencyId, query) => async dispatch => {
    try {
        const transactionId = uuid();

        dispatch(fetchEmployeesPending(transactionId));

        const employeesData = await userService.fetchAllHottestTalents(agencyId, query);

        dispatch(fetchEmployeesSuccess(employeesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchUserAsHottestTalent = (agencyId, userId) => async dispatch => {
    dispatch(fetchEmployeesPending());

    try {
        const userData = await userService.fetchUserAsHottestTalent(agencyId, userId);
        dispatch(fetchEmployeesSuccess([userData]));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const transferToAgency = (agencyId, userId, currentRole, employmentOptions) => async dispatch => {
    try {
        const updateInformation = {
            currentRole,
            updateData: { role: ROLES.EMPLOYEE, employmentOptions }
        };

        const result = await userService.updateUserRole(agencyId, userId, updateInformation);

        dispatch(fetchCandidateApplicationsSuccess(result))
        dispatch(transferToAgencySuccess(ROLES.EMPLOYEE));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_EMPLOYEES_ROLE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_EMPLOYEES_ROLE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchClientUsers = (clientId, query, replaceState) => async dispatch => {
    try {
        
        const result = await userService.fetchClientUsers(clientId, query);
        
        if (replaceState) {
            dispatch(clientUsersReplaceSuccess(result.clientAdmins));
        } else {
            dispatch(fetchClientUsersSuccess(result.clientAdmins));
        }
        
        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.CLIENT_USERS_FETCH_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateUser = (userId, userData, updateState=true) => async dispatch => {
    try {
        const result = await userService.updateUser(userId, userData);

        if (updateState) {
            dispatch(updateUserSuccess(result));
        }

        dispatch(showNotification(USER_MESSAGES.UPDATE_USER_SUCCESS, NOTIFICATION_TYPES.SUCCESS))
    } catch (error) {
        if (error.message === EMAIL_MESSAGES.ACCOUNT_WITH_EMAIL_ALREADY_EXISTS) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(USER_MESSAGES.UPDATE_USER_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
};

export const updateUserUnassignedRole = (userId, userData) => async dispatch => {
    try {
        dispatch(showApplicationLoader());

        const result = await userService.updateUserUnassignedRole(userId, userData);
        const idToken = await auth.currentUser.getIdToken(true);

        logEvent(AMPLITUDE_EVENT_TYPES.UNASSIGNED_USER_SELECTS_ROLE, null, { role: userData.role });

        await dispatch(refreshTokenSuccess(idToken));
        await dispatch(fetchTermsAndConditionsSucces({ isTermsAndConditionsAccepted: true }));

        if (result.role === ROLES.CANDIDATE) {
            await dispatch(fetchAgencyNameSuccess({ name: result.agencyName }));
            await dispatch(fetchMainMenuInformationSuccess(result.menuInformation));
            await dispatch(initialJourneyFetchSuccess({ currentStepId: CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS.EXPERTISE }))
            await dispatch(fetchUserInformationSuccess({
                user: {},
                agencyId: result.agencyId,
                roles: [result.role],
                displayMode: result.role,
                isJourneyStarted: result.journeyStartInformation?.isJourneyStarted
            }));
        } else {
            await dispatch(fetchUserInformationSuccess({
                user: {},
                roles: [result.role],
                displayMode: result.role,
            }));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(USER_MESSAGES.UPDATE_USER_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const updateUserCompanyInformation = (userId, data) => async dispatch => {
    try {
        dispatch(showApplicationLoader());

        const result = await userService.updateUserCompanyInformation(userId, data);

        await auth.currentUser.reload();
        const idToken = await auth.currentUser.getIdToken(true);
        const currentUser = auth.currentUser;

        await dispatch(refreshTokenSuccess(idToken));
        await dispatch(fetchUserInformationSuccess({ user: {}, roles: [result.role] }));
        await dispatch(fetchHomeInformationWithToken(result.agencyId, currentUser.toJSON(), result.role, idToken, true));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(USER_MESSAGES.UPDATE_USER_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchUnassignedUserTermsAndConditions = (userId) => async dispatch => {
    try {
        const result = await userService.fetchUnassignedUserTermsAndConditions(userId);
        dispatch(fetchTermsAndConditionsSucces(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AGENCY_MESSAGES.GET_TERMS_AND_CONDITIONS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
