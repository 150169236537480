import React from "react";
import Profile from "components/Person/Profile";

const EmployeeProfile = ({ userId, agencyId, ...props }) => {
  return (
    <Profile {...props} agencyId={agencyId} userId={userId}>
      <Profile.Cv />
    </Profile>
  );
};


export default EmployeeProfile;
