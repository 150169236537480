import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import Paper from '@material-ui/core/Paper';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import AgencySettingsCalendlyFormView from './AgencySettingsCalendlyFormView';

import { validateRequiredFields } from 'utils/formValidations';

import './AgencySettingsCalendlyForm.scss';

const AgencySettingsCalendlyForm = ({
    agencyInfo,
    updateAction,
}) => {
    const intl = useIntl();

    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const handleShowHeaderButtons = () => setShowHeaderButtons(true);

    const calendlySettingsFormRef = useRef();

    const discardHandlerBasicInfo = () => {
        setShowHeaderButtons(false);
        calendlySettingsFormRef.current.values.calendlyUrl = agencyInfo?.calendlyInfo?.calendlyUrl || '';
        calendlySettingsFormRef.current.values.calendlyApiKey = agencyInfo?.calendlyInfo?.calendlyApiKey || '';
        calendlySettingsFormRef.current.values.calendlyPersonalAccessToken = agencyInfo?.calendlyInfo?.calendlyPersonalAccessToken || '';
    }

    return (
        <div className="agency-settings-wrapper agency-calendly-settings">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "calendly" })}
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "discard" })}
                            onClick={discardHandlerBasicInfo}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "save" })}
                            onClick={() => calendlySettingsFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>

            <Paper className="calendly-settings-content">
                <Formik
                    initialValues={{
                        isUsingCalendly: agencyInfo?.calendlyInfo?.isUsingCalendly || false,
                        calendlyUrl: agencyInfo?.calendlyInfo?.calendlyUrl || '',
                        calendlyApiKey: agencyInfo?.calendlyInfo?.calendlyApiKey || '',
                        calendlyPersonalAccessToken: agencyInfo?.calendlyInfo?.calendlyPersonalAccessToken || '',
                    }}
                    innerRef={calendlySettingsFormRef}
                    validate={values => {
                        return {
                            ...validateRequiredFields(values, ['calendlyUrl', 'calendlyApiKey']),
                        };
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);
                        updateAction(values, resetForm);
                        setShowHeaderButtons(false);
                    }}
                    enableReinitialize={true}
                >
                    {(props) => <AgencySettingsCalendlyFormView
                        handleShowHeaderButtons={handleShowHeaderButtons}
                        {...props} />
                    }
                </Formik>
            </Paper>
        </div>
    )
};

export default AgencySettingsCalendlyForm;
