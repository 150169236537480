import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import { getAgencyName, getUserId, getDisplayMode } from 'reducers';

import { showNotification } from 'actions/notificationActions';


import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import AddContractFormView from './AddContractFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { addUtcOffset } from 'utils/formatTimes';

import {
    validateRequiredFields,
    validateInputLength,
    validateDateFields,
    validateEndDateNotBeforeStartDate,
    validateStartDateNotBeforeEndDate,
    validateFilesArrayLength,
    validateConditionalField,
} from 'utils/formValidations';

import { CONTRACT_TYPES, CONTRACT_SIGNATURE_CASES } from 'constants/contractsConstants';
import { CONTRACTS_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { ROLES } from 'constants/userConstants';
import { CHECKBOX_VALUES } from './AddContractDialogConstants';

import './AddContractDialog.scss';

const MAX_INPUT_LENGTH = 200;

const AddContractDialog = ({
    openDialog,
    handleCloseDialog,
    addContractHandler,
    contractTypes,
    showNotification,
    receiverRole,
    agencyName,
    userId,
    departmentId,
    displayMode,
    isEvrotrustIntegrated,
    agencyInfo,
}) => {
    const intl = useIntl();

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                initialValues={{
                    startDate: null,
                    endDate: null,
                    ongoing: false,
                    type: '',
                    title: '',
                    sign: '',
                    filesAttached: [],
                    agencyEvrotrustSignEmail: agencyInfo?.evrotrustEmail,
                }}
                validate={values => {
                    return {
                        ...validateRequiredFields(values, ['startDate', 'type', 'title', 'sign']),
                        ...validateFilesArrayLength(values, 'filesAttached', 1),
                        ...validateInputLength(values, [{ property: 'title', maxLength: MAX_INPUT_LENGTH }]),
                        ...validateDateFields(values, ['startDate']),
                        ...validateEndDateNotBeforeStartDate(values, 'startDate', 'endDate'),
                        ...validateStartDateNotBeforeEndDate(values, 'startDate', 'endDate', false),
                        ...validateConditionalField(values, 'ongoing', false, 'endDate'),
                        ...validateConditionalField(values, 'sign', CHECKBOX_VALUES.ONLY_AGENCY, 'agencyEvrotrustSignEmail'),
                        ...validateConditionalField(values, 'sign', CHECKBOX_VALUES.BOTH, 'agencyEvrotrustSignEmail'),
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    const { sign, filesAttached, ...rest } = values;

                    const data = new FormData();
                    const ongoing = values.ongoing || values.type === CONTRACT_TYPES.EMPLOYEE[0] || !!values.endDate;

                    const onlyReceiverSignatureRequired = sign === CONTRACT_SIGNATURE_CASES.RECEIVER;
                    const onlyAgencySignatureRequired = sign === CONTRACT_SIGNATURE_CASES.AGENCY;
                    const eSignatureRequired = sign === CONTRACT_SIGNATURE_CASES.ALL
                        || sign === CONTRACT_SIGNATURE_CASES.RECEIVER
                        || sign === CONTRACT_SIGNATURE_CASES.AGENCY;

                    const contractInfo = {
                        ...rest,
                        startDate: addUtcOffset(rest.startDate),
                        endDate: addUtcOffset(rest.endDate),
                        onlyReceiverSignatureRequired,
                        onlyAgencySignatureRequired,
                        eSignatureRequired,
                        ongoing,
                        filesCount: filesAttached.length,
                        receiverRole,
                    }

                    if (!departmentId && displayMode === ROLES.ADMIN && receiverRole === ROLES.EMPLOYEE) {
                        contractInfo.uploaderId = userId;
                    }

                    data.append('contractInfo', JSON.stringify(contractInfo));

                    if (filesAttached.length <= 0) {
                        showNotification(CONTRACTS_MESSAGES.NO_FILES_TO_UPLOAD, NOTIFICATION_TYPES.ERROR);
                        return;
                    }

                    filesAttached.forEach((file, i) => {
                        data.append(`attachedfile${i + 1}`, file);
                    });

                    addContractHandler(data, eSignatureRequired);
                    handleCloseDialog();
                }}
            >
                {(props) =>
                    <CommonFormikDialog
                        className="add-contract-dialog"
                        open={openDialog}
                        onClose={handleCloseDialog}
                        maxWidth={'sm'}
                        onDragEnter={() => void 0}
                        dialogTitle={intl.formatMessage({ id: "add-contract" })}
                        onSave={props.handleSubmit}
                    >
                        <AddContractFormView
                            {...props}
                            contractTypes={contractTypes}
                            receiverRole={receiverRole}
                            agencyName={agencyName}
                            isEvrotrustIntegrated={isEvrotrustIntegrated}
                            autoSignEnabled={agencyInfo?.autoSignEnabled}
                            evrotrustSecondaryEmails={agencyInfo?.evrotrustSecondaryEmails}
                            primaryEvrotrustEmail={agencyInfo?.evrotrustEmail}
                        />
                    </CommonFormikDialog>
                }
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

const mapDispatchtoProps = {
    showNotification,
};

const mapStateToProps = (state) => ({
    agencyName: getAgencyName(state),
    userId: getUserId(state),
    displayMode: getDisplayMode(state),
});

export default connect(mapStateToProps, mapDispatchtoProps)(AddContractDialog);
