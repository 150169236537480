import React, { Suspense } from 'react';

import { Paper } from '@material-ui/core';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import './Topic.scss';

const FilesTable = React.lazy(() => import('components/Settings/Trainings/TrainingField/IndividualTraining/TrainingFilesTable/TrainingFilesTable'));
const VideosField = React.lazy(() => import('components/Settings/Trainings/TrainingField/IndividualTraining/Topic/VideosField'));

const Topic = ({
    match,
    isAdmin,
    topics,
    agencyId
}) => {
    const { trainingId, topicId } = match.params;
    const topic = topics.find(x => x._id === topicId);

    return (
        <div className="topic-employee-container">
            <div className="topic-overview-container">
                <SectionActionsWrapper>
                    <SectionActionsWrapper.SectionTitle
                        sectionTitle='Overview'
                    />
                </SectionActionsWrapper>
                <Paper className="overview-wrapper">
                    <p className="overview-body">{topic?.description}</p>
                </Paper>
            </div>
            <div className="topic-videos-container">
                <Suspense>
                    <VideosField topicId={topicId} trainingId={trainingId} />
                </Suspense>
            </div>
            <div className="topic-files-container">
                <Suspense>
                    <FilesTable
                        agencyId={agencyId}
                        topicId={topicId}
                        trainingId={trainingId}
                        isAdmin={isAdmin}
                    />
                </Suspense>
            </div>
        </div>
    )
};

export default Topic;
