import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorMessage, Formik } from 'formik';

import { TextField } from '@material-ui/core';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { validateEmailAddress, validateRequiredFields } from 'utils/formValidations';

import './ContactPersonForm.scss';

const ContactPersonForm = ({
    userId,
    agencyId,
    clientId,
    isOpen,
    isEditMode,
    handleCloseDialog,
    user,
    registerClient,
    handleEdit,
    accountManager,
    isFirstClientUser,
}) => {
    const intl = useIntl();

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                contactPerson: user?.firstName ? user.firstName + ' ' + user.lastName : '',
                email: user?.email ? user.email : '',
            }}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['contactPerson', 'email']),
                    ...validateEmailAddress(values, 'email')
                };
            }}
            onSubmit={(values) => {
                values.clientId = clientId;
                values.accountManager = accountManager._id;
                values.email = values.email.toLowerCase().trim();

                if (isFirstClientUser) {
                    values.isFirst = true;
                }

                if (isEditMode) {
                    handleEdit(user._id, values);
                } else {
                    registerClient(agencyId, { ...values, addedBy: userId });
                }

                handleCloseDialog();
            }}
        >
            {({ values, handleChange, errors, touched, handleSubmit }) =>
                <CommonFormikDialog
                    className="add-contact-person-dialog-container"
                    open={isOpen}
                    onClose={handleCloseDialog}
                    maxWidth='xs'
                    fullWidth={true}
                    onSave={handleSubmit}
                    dialogTitle={isEditMode ? intl.formatMessage({ id: "edit-client-admin" }) : intl.formatMessage({ id: "add-client-admin" })}
                >
                    <form className="add-contact-person-form" autoComplete="off">
                        <div className="add-contact-person-form-field contact-person">
                            <TextField
                                required
                                label={intl.formatMessage({ id: "contact-person-name" })}
                                variant="outlined"
                                name="contactPerson"
                                value={values.contactPerson}
                                onChange={handleChange}
                                error={errors.contactPerson && touched.contactPerson}
                                fullWidth
                            />
                            <ErrorMessage name="contactPerson" component="div" className="invalid-field-message" />
                        </div>
                        <div className="add-contact-person-form-field email">
                            <TextField
                                required
                                label={intl.formatMessage({ id: "email" })}
                                variant="outlined"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                error={errors.email && touched.email}
                                fullWidth
                            />
                            <ErrorMessage name="email" component="div" className="invalid-field-message" />
                        </div>
                    </form>
                </CommonFormikDialog>
            }
        </Formik>
    )
};

export default ContactPersonForm;
