import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { Link, Paper, Typography, IconButton } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import CvOptionsHeading from 'components/Shared/CvOptionsHeading';
import ProfileUploadRawCvPdf from '../ProfileUploadRawCvPdf';
import ProfileUploadNewRawCvPdf from '../ProfileUploadNewRawCvPdf';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import UploadCVDialog from './UploadCVDialog';

import { getAgencyId, getRecruiterProfile, getRawCvProgressDataFetchState } from 'reducers';
import { fetchRawCvProgress } from 'actions/rawCvActions';
import { downloadRecruiterEmployeeCV, fetchRecruiterEmployee } from 'actions/recruiterActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    OPTION_ONE_TITLE_RAW_CV,
    OPTION_TWO_TITLE_RAW_CV,
    STEP_ONE_TITLE_RAW_CV,
    STEP_TWO_TITLE_RAW_CV
} from 'constants/cvTitlesConstants';

import './ProfileUploadCV.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const ProfileUploadCV = ({
    children,
    className,
    ...props
}) => {
    children = React.Children.map(children, (child) => {
        return React.cloneElement(child, { ...props });
    });

    return (
        <div className={`profile-info-wrapper ${className ? className : ''}`}>
            {children}
        </div>
    );
};

const UploadCvRecruiter = ({
    agencyId,
    recruiterId,
    recruiterEmployeeInfo,
    fetchRecruiterEmployee,
    downloadRecruiterEmployeeCV,
}) => {
    const intl = useIntl();

    const { id } = useParams();

    useEffect(() => {
        fetchRecruiterEmployee(agencyId, recruiterId, id);
    }, [])

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleDownloadRecruiterEmployeeCV = () => {
        downloadRecruiterEmployeeCV(agencyId, recruiterId, id);
    }

    const getPaperContent = () => {
        if (recruiterEmployeeInfo.profileCV) {
            return (
                <Paper className="profile-upload-cv-content">
                    <Typography
                        variant="subtitle1"
                        className="content-header uploaded row"
                    >
                        {intl.formatMessage({ id: "uploaded-cv" })}
                    </Typography>
                    <Link
                        onClick={handleOpenDialog}
                        className="cv-file-name row"
                    >
                        {recruiterEmployeeInfo.profileCV.cloudName}
                    </Link>
                    <IconButton
                        className='download-cv-icon-button'
                        onClick={handleDownloadRecruiterEmployeeCV}
                    >
                        <SaveAltIcon fontSize='large' className='download-cv-icon' />
                    </IconButton>
                </Paper>
            )
        } else {
            return (
                <Paper className="profile-upload-cv-content">
                    <Typography variant='h6' className="content-header row">{intl.formatMessage({ id: "upload-cv" })}</Typography>
                    <PrimaryButton
                        text={intl.formatMessage({ id: "upload-cv" })}
                        handleClick={handleOpenDialog}
                        className="upload-cv-button row"
                    />
                    <Typography className="row">{intl.formatMessage({ id: "or" })}</Typography>
                    <Typography className="suggestion row">{intl.formatMessage({ id: "create-cv-from-scratch" })}</Typography>
                    <Link href="#general-info">
                        <ArrowDownwardIcon className="arrow row" />
                    </Link>
                </Paper>
            )
        }
    }

    const filesAttached = recruiterEmployeeInfo.profileCV
        ? [{ name: recruiterEmployeeInfo.profileCV.cloudName }]
        : null;

    return (
        <div className="profile-upload-cv-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={recruiterEmployeeInfo.profileCV ? intl.formatMessage({ id: "uploaded-cv" }) : intl.formatMessage({ id: "upload-cv" })}
                />
            </SectionActionsWrapper>

            {getPaperContent()}

            <UploadCVDialog
                openDialog={openDialog}
                recruiterId={recruiterId}
                filesAttached={filesAttached}
                handleCloseDialog={handleCloseDialog}
                recruiterEmployeeInfo={recruiterEmployeeInfo}
            />
        </div>
    )
}

const UploadRawCvPdf = ({
    agencyId,
    employeeId,
    isRawCvPdfGenerated,
    fetchRawCvProgress,
    dataFetchState
}) => {
    const intl = useIntl();

    useEffect(() => {
        fetchRawCvProgress(agencyId, employeeId);
    }, []);

    return (
        <>
            {dataFetchState?.isDataFetching && !dataFetchState?.isDataFetched
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <>
                    <CvOptionsHeading primaryText={intl.formatMessage({ id: convertStringToLanguageKey(OPTION_ONE_TITLE_RAW_CV) })} secondaryText={intl.formatMessage({ id: convertStringToLanguageKey(STEP_ONE_TITLE_RAW_CV) })} />
                    {isRawCvPdfGenerated
                        ? <ProfileUploadNewRawCvPdf employeeId={employeeId} agencyId={agencyId} />
                        : <ProfileUploadRawCvPdf employeeId={employeeId} agencyId={agencyId} />}
                    <CvOptionsHeading primaryText={intl.formatMessage({ id: convertStringToLanguageKey(OPTION_TWO_TITLE_RAW_CV) })} secondaryText={intl.formatMessage({ id: convertStringToLanguageKey(STEP_TWO_TITLE_RAW_CV) })} />
                </>
            }
        </>
    );
};

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    recruiterEmployeeInfo: getRecruiterProfile(state),
    dataFetchState: getRawCvProgressDataFetchState(state),
});

const mapDispatchToProps = {
    fetchRecruiterEmployee,
    downloadRecruiterEmployeeCV,
    fetchRawCvProgress,
};

ProfileUploadCV.UploadCvRecruiter = UploadCvRecruiter;
ProfileUploadCV.UploadRawCvPdf = UploadRawCvPdf;

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUploadCV);
