import {
    PROJECT_DETAILS_PAGE_UNMOUNT_SUCCESS,
    EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    CANDIDATE_APPLICATION_PAGE_UNMOUNTED_SUCCESS,
    DIALOG_ACTION_BUTTON_LOADER_SHOW_SUCCESS,
    DIALOG_ACTION_BUTTON_LOADER_HIDE_SUCCESS,
    ACTION_BUTTON_LOADER_SHOW_SUCCESS,
    ACTION_BUTTON_LOADER_HIDE_SUCCESS,
} from 'actions/actionTypes';

const projectDetailsPageUnmount = () => ({
    type: PROJECT_DETAILS_PAGE_UNMOUNT_SUCCESS,
});

const employeeProfilePageUnmounted = () => ({
    type: EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
});

const clientProfilePageUnmounted = () => ({
    type: CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS,
});

const supplierProfilePageUnmounted = () => ({
    type: SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS,
});

const candidateApplicationPageUnmounted = () => ({
    type: CANDIDATE_APPLICATION_PAGE_UNMOUNTED_SUCCESS,
});

const showDialogActionButtonLoaderSuccess = () => ({
    type: DIALOG_ACTION_BUTTON_LOADER_SHOW_SUCCESS,
    payload: true
});

const hideDialogActionButtonLoaderSuccess = () => ({
    type: DIALOG_ACTION_BUTTON_LOADER_HIDE_SUCCESS,
    payload: false
});

const showActionButtonLoaderSuccess = () => ({
    type: ACTION_BUTTON_LOADER_SHOW_SUCCESS,
    payload: true
});

const hideActionButtonLoaderSuccess = () => ({
    type: ACTION_BUTTON_LOADER_HIDE_SUCCESS,
    payload: false
});

export const resetProjectDetailsState = () => dispatch => {
    dispatch(projectDetailsPageUnmount());
};

export const resetEmployeeInformationStates = () => dispatch => {
    dispatch(employeeProfilePageUnmounted());
}

export const resetClientInformationStates = () => dispatch => {
    dispatch(clientProfilePageUnmounted());
};

export const resetSupplierInformationStates = () => dispatch => {
    dispatch(supplierProfilePageUnmounted());
};

export const resetCandidateApplicationStates = () => dispatch => {
    dispatch(candidateApplicationPageUnmounted());
};

export const showDialogActionButtonLoader = () => dispatch => dispatch(showDialogActionButtonLoaderSuccess());

export const hideDialogActionButtonLoader = () => dispatch => dispatch(hideDialogActionButtonLoaderSuccess());

export const showActionButtonLoader = () => dispatch => dispatch(showActionButtonLoaderSuccess());

export const hideActionButtonLoader = () => dispatch => dispatch(hideActionButtonLoaderSuccess());
