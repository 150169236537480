import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorMessage } from 'formik';

import TextField from '@material-ui/core/TextField';
import UploadFiles from 'components/Shared/UploadFiles';

const ACCEPTED_FORMATS = ['ppt', 'pptx', 'xlsx', 'csv', 'doc', 'docx', 'pdf', 'jpg', 'jpeg', 'png'];

const AddImportantDocumentsDialogView = ({
    values,
    handleChange,
    errors,
    touched,
    setFieldValue
}) => {
    const intl = useIntl();

    return (
        <form className="add-important-documents-form" autoComplete="off">
            <div>
                <TextField
                    required
                    className="add-important-documents-field"
                    name="title"
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "title" })}
                    error={errors.title && touched.title}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="title" component="div" className="invalid-field-message" />
            </div>
            <UploadFiles
                name="filesAttached"
                filesAttached={values.filesAttached}
                setFieldValue={setFieldValue}
                acceptedFormats={ACCEPTED_FORMATS}
            />
        </form>
    )
};

export default AddImportantDocumentsDialogView;
