import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorMessage } from 'formik';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import MaskedToggableInputField from 'components/Shared/MaskedToggableInputField';
import Switcher from 'components/Shared/Switcher/Switcher';

const AgencySettingsEvrotrustFormView = ({
    values,
    handleChange,
    setFieldValue,
    handleShowHeaderButtons,
    errors,
    touched,
    setIsUsingEvrotrust,
}) => {
    const intl = useIntl();

    const holidaySignatoryEmails = values.evrotrustSecondaryEmails !== ''
        ? [values.evrotrustEmail, ...values.evrotrustSecondaryEmails.split(',').map(x => x.trim()).filter(x => x !== '')]
        : [values.evrotrustEmail];

    return (
        <form autoComplete="off">
            <div className="agency-evrotrust-checkbox-wrapper">
                <Switcher
                    initialValue={values.isUsingEvrotrust}
                    isChecked={values.isUsingEvrotrust}
                    changeStatusHandler={() => {
                        setFieldValue('isUsingEvrotrust', !values.isUsingEvrotrust);
                        setIsUsingEvrotrust(!values.isUsingEvrotrust);
                        handleShowHeaderButtons();
                    }}
                />
                <span className="checkbox-text">{intl.formatMessage({ id: "agency-settings-using-evrotrust-checkbox-text" })}</span>
            </div>

            {values.isUsingEvrotrust &&
                <div className="agency-evrotrust-settings-form-block">
                    <div className="agency-evrotrust-settings-form-field">
                        <MaskedToggableInputField
                            label={intl.formatMessage({ id: "evrotrust-vendor-number" })}
                            name="evrotrustVendorNumber"
                            value={values.evrotrustVendorNumber}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            handleShowHeaderButtons={handleShowHeaderButtons}
                        />
                        <ErrorMessage name="evrotrustVendorNumber" component="div" className="invalid-field-message" />
                    </div>
                    <div className="agency-evrotrust-settings-form-field">
                        <MaskedToggableInputField
                            label={intl.formatMessage({ id: "evrotrust-api-key" })}
                            name="evrotrustApiKey"
                            value={values.evrotrustApiKey}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            handleShowHeaderButtons={handleShowHeaderButtons}
                        />
                        <ErrorMessage name="evrotrustApiKey" component="div" className="invalid-field-message" />
                    </div>
                </div>
            }
            <div className="agency-team-notifications-checkbox-wrapper">
                <Switcher
                    initialValue={values.isUsingEvrotrust && values.autoSignEnabled}
                    disabled={!values.isUsingEvrotrust}
                    isChecked={values.isUsingEvrotrust && values.autoSignEnabled}
                    changeStatusHandler={() => {
                        setFieldValue('autoSignEnabled', !values.autoSignEnabled);
                        handleShowHeaderButtons();
                    }}
                />
                <span className="checkbox-text">{intl.formatMessage({ id: "agency-settings-evrotrust-autosign-checkbox-text" })}</span>
            </div>
            {
                values.isUsingEvrotrust &&
                <div className="agency-evrotrust-settings-form-block vertical">
                    <div className="agency-evrotrust-settings-form-field">
                        <TextField
                            required
                            label={intl.formatMessage({ id: "evrotrust-primary-email" })}
                            variant="outlined"
                            name="evrotrustEmail"
                            value={values.evrotrustEmail}
                            onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                            error={errors.evrotrustEmail && touched.evrotrustEmail}
                            fullWidth
                        />
                        <ErrorMessage name="evrotrustEmail" component="div" className="invalid-field-message" />
                    </div>
                    {
                        values.autoSignEnabled &&
                        <div className="agency-evrotrust-settings-form-field">
                            <TextField
                                label={intl.formatMessage({ id: "evrotrust-secondary-emails-comma-separated" })}
                                variant="outlined"
                                name="evrotrustSecondaryEmails"
                                placeholder='example1@gmail.com,example2@gmail.com'
                                value={values.evrotrustSecondaryEmails}
                                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                                error={errors.evrotrustSecondaryEmails && touched.evrotrustSecondaryEmails}
                                fullWidth
                            />
                            <ErrorMessage name="evrotrustSecondaryEmails" component="div" className="invalid-field-message" />
                        </div>
                    }
                    {
                        values.autoSignEnabled &&
                        <div className="agency-evrotrust-settings-form-field">
                            <TextField
                                select
                                name="holidaysSignatoryEmail"
                                label={intl.formatMessage({ id: "select-holidays-signatory-email" })}
                                value={values.holidaysSignatoryEmail}
                                onChange={(e) => {
                                    setFieldValue('holidaysSignatoryEmail', e.target.value);
                                    handleShowHeaderButtons();
                                }}
                                variant="outlined"
                                fullWidth
                                error={errors.holidaysSignatoryEmail && touched.holidaysSignatoryEmail}
                            >
                                {holidaySignatoryEmails.map((x, idx) => (
                                    <MenuItem key={idx} value={x}>
                                        {x}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <ErrorMessage name="holidaysSignatoryEmail" component="div" className="invalid-field-message" />
                        </div>
                    }
                </div>
            }
        </form>
    );
};

export default AgencySettingsEvrotrustFormView;
