import {
    REPORTS_PAGE_FETCH_SUCCESS,
    KPI_REPORT_FETCH_SUCCESS,
} from 'actions/actionTypes';

const reportsInitialState = {
    partnerEmployeesReportData: {},
    newHiresReportData: {},
    attritionReportData: {},
    openPositionsReportData: {},
    registeredCandidatesReportData: {},
    jobApplicationsReportData: {},
};

const reportsReducer = (state = reportsInitialState, action) => {
    switch (action.type) {
        case REPORTS_PAGE_FETCH_SUCCESS:
            return { ...state, ...action.payload };
        case KPI_REPORT_FETCH_SUCCESS:
            return { ...state, ...action.payload }
        default:
            return state;
    }
};

export default reportsReducer;

export const getReportsData = (state) => state;
