import React from 'react';
import { useIntl } from 'react-intl';

import ConfirmDialog from '../ConfirmDialog';
import { CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS } from '../ConfirmDialog/ConfirmDialogConstants';

import './ConfirmRestoreUserDialog.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const ConfirmRestoreUserDialog = ({
    openDialog,
    handleConfirmDialog,
    handleCloseDialog,
    isUserEmployeeOrAdmin,
}) => {
    const intl = useIntl();

    return (
        <ConfirmDialog
            openDialog={openDialog}
            dialogTitle={intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS.DIALOG_TITLE)})}
            dialogSubtitle={isUserEmployeeOrAdmin
                ? intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS.DIALOG_SUBTITLE_EMPLOYEE_OR_ADMIN)})
                : intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS.DIALOG_SUBTITLE_DEFAULT)}) 
            }
            confirmButtonName={intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS.CONFIRM_BUTTON_NAME)})}
            discardButtonName={intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS.DISCARD_BUTTON_NAME)})}
            handleConfirmDialog={handleConfirmDialog}
            handleCloseDialog={handleCloseDialog}
        />
    )
};

export default ConfirmRestoreUserDialog;

