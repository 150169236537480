import React from 'react';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import TextField from '@material-ui/core/TextField';
import { ErrorMessage } from 'formik';

import './EditFieldDialog.scss';

const EditFieldDialog = ({
    handleSubmit,
    handleChange,
    values,
    onClose,
    open,
    dialogTitle,
}) => {
    const onFormSubmit = () => {
        handleSubmit();
    };

    return (
        <CommonFormikDialog
            className="field-dialog"
            open={open}
            onClose={onClose}
            dialogTitle={dialogTitle}
            saveBtnText="SAVE"
            discardBtnText="DISCARD"
            onSave={onFormSubmit}
        >
            <form className="field-dialog-form" autoComplete="off">
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2">
                            <TextField
                                value={values.field}
                                variant="outlined"
                                onChange={handleChange}
                                name="field"
                                id="field"
                                fullWidth
                            />
                            <ErrorMessage name="field" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                </div>
            </form>
        </CommonFormikDialog>
    );
};

export default EditFieldDialog;

