import React from 'react';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import TimeTracking from '../../TimeTracking';

import './ProfileTimeTracking.scss';

const TimeTrackingField = ({
    userId,
    agencyId,
}) => (
    <div className="profile-info-wrapper profile-time-tracking">
        <SectionActionsWrapper>
            <SectionActionsWrapper.SectionTitle
                sectionTitle='Time Tracking'
            />
        </SectionActionsWrapper>

        <div >
            <TimeTracking userId={userId} agencyId={agencyId} />
        </div>
    </div>
);


export default TimeTrackingField;
