export const tableColumns = [
    { name: 'Employee Name', value: 'employeeName', isSortable: true, langKey: "name-text"},
    { name: 'Upload Date', value: 'uploadDate', isSortable: true, langKey: "upload-date"},
    { name: 'Contract Period', value: 'contractPeriod', isSortable: true, langKey: "contract-period"},
    { name: 'Type', value: 'type', isSortable: true, langKey: "type"},
    { name: 'Status', value: 'documentStatus', isSortable: true, langKey: "status"},
];

export const defaultTableColumns = ['employeeName', 'uploadDate', 'contractPeriod', 'type', 'documentStatus'];

export const tableFilters = [
    { key: 'type', name:'Type of Document', value: ['Permanent Employment Contract', 'Freelance Employment Contract', 'Other'], hasTranslation: true },
    { key: 'signatureStatus', name: 'Status', value: ['Sign pending', 'Signed', 'Sign rejected', 'N/A'], hasTranslation: true },
];

export const PAGE_QUERY_SELECTOR = 'page';
export const SEARCH_QUERY_SELECTOR = 'search';
export const SORT_QUERY_SELECTOR = 'sort';
export const UPLOAD_DATE_QUERY_SELECTOR = 'uploadDate';
export const SIGNED_DATE_QUERY_SELECTOR = 'signedDate';

export const TABLE_INITIAL_QUERY = {
    filters: JSON.stringify({}),
    page: 1,
    search: "",
    sortBy: null,
    sortByDir: null,
    pagesToLoad: 5,
    itemsPerPage: 25,
    uploadDate: "",
    signedDate: "",
};

export const TABLE_QUERY_MAP = {
    filters: "filters",
    page: PAGE_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
    search: SEARCH_QUERY_SELECTOR,
    uploadDate: UPLOAD_DATE_QUERY_SELECTOR,
    signedDate: SIGNED_DATE_QUERY_SELECTOR,
};
