import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import KPIList from 'components/Shared/KPIList';
import { CURRENT_QUARTER_YEAR_FORMAT } from 'constants/commonConstants';

import './ReportsKPIs.scss';

const ReportsKPIs = ({
    openJobs,
    publishedJobs,
    jobsWithNoApplications,
    timeToFillPosition,
    averageTimeToHire,
    hiredUsers,
    applicantPipelineProfiles,
    dataFetchState,
}) => {
    const intl = useIntl();

    const reportKPIsData = [
        { title: intl.formatMessage({ id: "open-jobs" }), amount: openJobs },
        { title: intl.formatMessage({ id: "open-published-jobs" }), amount: publishedJobs },
        { title: intl.formatMessage({ id: "open-jobs-without-application" }), amount: jobsWithNoApplications },
        { title: intl.formatMessage({ id: "applicant-pipeline" }), amount: applicantPipelineProfiles },
        {
            title: intl.formatMessage({ id: "time-to-fill" }),
            amount: Number.isInteger(timeToFillPosition?.averageDaysCurrentQuarter)
                ? timeToFillPosition?.averageDaysCurrentQuarter
                : 'N/A',
            percentageChange: timeToFillPosition?.percentageChange,
        },
        {
            title: intl.formatMessage({ id: "time-to-hire" }),
            amount: Number.isInteger(averageTimeToHire?.averageDaysCurrentQuarter)
                ? averageTimeToHire?.averageDaysCurrentQuarter
                : 'N/A',
            percentageChange: averageTimeToHire?.percentageChange,
        },
        {
            title: `${intl.formatMessage({ id: "hired-candidates" })} ${moment().format(CURRENT_QUARTER_YEAR_FORMAT)}`,
            amount: Number.isInteger(hiredUsers?.currentQuarterHiredUsers)
                ? hiredUsers?.currentQuarterHiredUsers
                : 'N/A',
            percentageChange: hiredUsers?.percentageChange,
        }
    ];

    return (
        <div className="reports-kpis-wrapper">
            <KPIList
                data={reportKPIsData}
                dataFetchState={dataFetchState}
            />
        </div>
    );
}

export default ReportsKPIs;
