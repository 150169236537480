import moment from "moment";
import { BULGARIAN_LABEL } from "constants/languages";
import { convertStringToLanguageKey } from "utils/languageUtils";
import { FULL_MONTH_ONLY } from "constants/commonConstants";

const getContractsBannerText = (contractsCount, selectedLanguage) => {
    if (selectedLanguage === BULGARIAN_LABEL) {
        return `Служителят има ${contractsCount > 1 ? `${contractsCount} договора` : `${contractsCount} договор`}.`
    } else {
        return `Employee has ${contractsCount > 1 ? `${contractsCount} contracts` : `${contractsCount} contract`}.`
    }
};

const getHistoricalNotesBannerText = (historicalNotesCount, selectedLanguage) => {
    if (selectedLanguage === BULGARIAN_LABEL) {
        return `Служителят има ${historicalNotesCount > 1 ? `${historicalNotesCount} бележки` : `${historicalNotesCount} бележка`}.`
    } else {
        return `Employee has ${historicalNotesCount > 1 ? `${historicalNotesCount} historical notes` : `${historicalNotesCount} historical note`}.`
    }
};

export const getOverviewBannersItems = (employeeOverviewInformation, intl, selectedLanguage) => {
    moment.locale(intl.locale);

    const {
        contractsCount,
        historicalNotesCount,
        hasCVInformation,
        missingHoursInMonths
    } = employeeOverviewInformation;
    const bannerItems = {};

    const hasContracts = contractsCount > 0;
    const hasHistoricalNotes = historicalNotesCount > 0;
    const hasMissingHours = missingHoursInMonths.length > 0;
    const currentMonth = moment().format(FULL_MONTH_ONLY);
    const previousMonth = moment().subtract(1, 'month').format(FULL_MONTH_ONLY);

    bannerItems.contracts = {
        sectionTitle: intl.formatMessage({ id: "submenu-contracts" }),
        bannerText: hasContracts
            ? getContractsBannerText(contractsCount, selectedLanguage)
            : intl.formatMessage({ id: "no-contracts-text" }),
        buttonText: hasContracts ? intl.formatMessage({ id: "see-contracts" }) : intl.formatMessage({ id: "add-contract" }),
    };

    bannerItems.cv = {
        sectionTitle: intl.formatMessage({ id: "submenu-cv" }),
        bannerText: hasCVInformation
            ? intl.formatMessage({ id: "employee-has-cv-text" })
            : intl.formatMessage({ id: "employee-has-no-cv-text" }),
        buttonText: hasCVInformation ? intl.formatMessage({ id: "export-cv" }) : intl.formatMessage({ id: "upload-cv" }),
    }

    bannerItems.trackTime = {
        sectionTitle: intl.formatMessage({ id: "submenu-time-tracking" }),
        bannerText: hasMissingHours
            ? `${intl.formatMessage({ id: "employee-has-missing-hours" })} ${missingHoursInMonths.map(x => intl.formatMessage({ id: convertStringToLanguageKey(x) })).join(', ')}.`
            : <span className="green">{intl.formatMessage({ id: "employee-has-completed-timesheet" })} {currentMonth} {intl.formatMessage({ id: "and"})} {previousMonth}.</span>,
        buttonText: hasMissingHours ? intl.formatMessage({ id: "track-time" }) : intl.formatMessage({ id: "see-more" })
    }

    bannerItems.historicalNotes = {
        sectionTitle: intl.formatMessage({ id: "historical-notes" }),
        bannerText: hasHistoricalNotes
            ? getHistoricalNotesBannerText(historicalNotesCount, selectedLanguage)
            : intl.formatMessage({ id: "employee-has-no-historical-notes" }),
        buttonText: hasHistoricalNotes ? intl.formatMessage({ id: "see-notes" }) : intl.formatMessage({ id: "create-new" }),
    }

    return bannerItems;
};
