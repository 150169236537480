import React, { useState, useRef } from 'react'
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import Paper from '@material-ui/core/Paper';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import BankDetailsFormView from './BankDetailsFormView';

import './BankDetails.scss'

import {
    validateRequiredFields,
    validateIban
} from 'utils/formValidations';

const BankDetails = ({
    employeeId,
    bankDetails,
    editBankDetails,
    agencyId,
    departmentId,
}) => {
    const intl = useIntl();

    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const bankDetailsFormRef = useRef();

    const handleSubmit = async (values, resetForm) => {
        await editBankDetails(values, employeeId, agencyId, departmentId);
        resetForm();
        setShowHeaderButtons(false);
    }

    const handleDiscard = () => {
        bankDetailsFormRef.current.handleReset();
        setShowHeaderButtons(false);
    }

    return (
        <div className="employment-info-wrapper bank-details-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "bank-details" })}
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "discard" })}
                            onClick={handleDiscard}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "save" })}
                            onClick={() => bankDetailsFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>


            <Paper className="inner-content-wrapper">
                <Formik
                    initialValues={{
                        firstName: bankDetails.firstName || '',
                        lastName: bankDetails.lastName || '',
                        iban: bankDetails.iban || '',
                        swift: bankDetails.swift || '',
                        currency: bankDetails.currency || '',
                    }}
                    validate={values => {
                        return {
                            ...validateRequiredFields(values, ['firstName', 'lastName', 'iban', 'swift', 'currency']),
                            ...validateIban(values, 'iban')
                        }
                    }}
                    innerRef={bankDetailsFormRef}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);

                        handleSubmit(values, resetForm)
                    }}
                    enableReinitialize={true}
                >
                    {(props) => <BankDetailsFormView {...props} showHeaderButtons={() => setShowHeaderButtons(true)} />}
                </Formik>
            </Paper>
        </div >
    )
}

export default BankDetails;
