const PROJECT_TYPES = {
    BILLABLE_PROJECT: 1,
    NON_BILLABLE_PROJECT: 2,
    BENCH_ACTIVITY: 3,
};

export const PROJECT_TYPES_ARRAY = [
    { key: PROJECT_TYPES.BILLABLE_PROJECT, value: 'Billable Project' },
    { key: PROJECT_TYPES.NON_BILLABLE_PROJECT, value: 'Non-billable Project' },
    { key: PROJECT_TYPES.BENCH_ACTIVITY, value: 'Bench Activity' },
];

export const PROJECT_TYPES_MAP = {
    [PROJECT_TYPES.BILLABLE_PROJECT]: 'Billable Project',
    [PROJECT_TYPES.NON_BILLABLE_PROJECT]: 'Non-billable Project',
    [PROJECT_TYPES.BENCH_ACTIVITY]: 'Bench Activity',
};
