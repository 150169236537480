import React from 'react';
import { ErrorMessage } from 'formik';
import { DialogActions, TextField } from '@material-ui/core';

import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import './AddProfileFormView.scss';

const AddProfileFormView = ({
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleCloseDialog,
}) => {
    return (
        <form className="add-profile-form" autoComplete="off">
            <div className="names-wrapper">
                <TextField
                    required
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    label="Name"
                    error={errors.name && touched.name}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>
            <div className="linkedin-field">
                <TextField
                    name="linkedin"
                    value={values.linkedin}
                    onChange={handleChange}
                    label="LinkedIn"
                    error={errors.linkedin && touched.linkedin}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="linkedin" component="div" className="invalid-field-message" />
            </div>
            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
                <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Save" type="submit" />
            </DialogActions>
        </form>
    )
};


export default AddProfileFormView;
