import React, { useState } from 'react';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import ConfirmDialog from 'components/Shared/ConfirmDialog';
import { updateRawCvGenerated } from 'actions/rawCvActions';
import { getDisplayMode, getEmployeeUploadedCv } from 'reducers';
import LinkedinPdfIcon from 'assets/linkedin-pdf-icon.svg';

import { downloadEmployeeCv } from 'actions/employeeActions';

import './ProfileUploadNewRawCvPdf.scss'

const ProfileUploadNewRawCvPdf = ({
    downloadEmployeeCv,
    uploadedCv,
    updateRawCvGenerated,
    agencyId,
    employeeId,
    role
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleUploadCV = () => {
        updateRawCvGenerated(agencyId, employeeId, { isRawCvPdfGenerated: false }, role);
    };

    const handleDownloadCV = () => {
        const fileName = uploadedCv?.originalFileName.split('.').slice(0, -1).join('.')
        downloadEmployeeCv(agencyId, employeeId, fileName);
    };

    return (
        <div className="raw-cv-new-profile-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Upload a CV file'
                />
            </SectionActionsWrapper>

            <Paper className="raw-cv-new-profile-content">
                <div className="raw-cv-new-profile-action">
                    <div className="action-header">
                        <div className="action-title">
                            You've already uploaded a CV file, which you can download below.
                        </div>
                        <div className="action-subtitle">
                            If you wish to import a new file, the current profile data will be deleted and re-uploaded.
                        </div>
                    </div>
                    <PrimaryButton
                        className="raw-cv-new-action-button"
                        handleClick={handleOpenDialog}
                        text="Import New CV"
                    />

                    <PrimaryButton
                        text="Download Original"
                        className="raw-cv-new-action-button"
                        handleClick={handleDownloadCV}
                    />
                </div>
                <div className="image-wrapper">
                    <img className="image-info" src={LinkedinPdfIcon} />
                </div>
            </Paper>

            <ConfirmDialog
                openDialog={isDialogOpen}
                dialogTitle={` Notice: You've already uploaded a profile CV.\nIf you wish to upload another file, your previous changes will be lost.`}
                confirmButtonName='Proceed'
                discardButtonName='Cancel'
                handleConfirmDialog={handleUploadCV}
                handleCloseDialog={handleCloseDialog}
            />
        </div>
    )
};

const mapStateToProps = state => ({
    role: getDisplayMode(state),
    uploadedCv: getEmployeeUploadedCv(state),
});

const mapDispatchtoProps = { updateRawCvGenerated, downloadEmployeeCv };

export default connect(mapStateToProps, mapDispatchtoProps)(ProfileUploadNewRawCvPdf);
