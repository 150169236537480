import React from 'react';
import { Link } from 'react-router-dom'

import { getPathToCandidateApplication } from 'utils/applicationUtils';
import { logEvent } from 'utils/amplitude';

import NothingToShow from 'components/NothingToShow';

import { NOTIFICATION_TYPES_MESSAGES, NOTIFICATION_TYPES } from './JobNotificationConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { ROLES } from 'constants/userConstants';

import { ReactComponent as NotificationsIcon } from 'assets/jobs-notifications-icon.svg';

import './JobNotifications.scss';

const JobNotifications = ({
    notifications
}) => {
    const logClickEvent = (notificationType) => logEvent(AMPLITUDE_EVENT_TYPES.CLIENT_CLICK_NOTIFICATION, { rolesPermitted: [ROLES.CLIENT] }, { notificationType });

    return (
        <div className="job-notifications">
            <h3 className="job-notification-title">
                <span className="job-notification-title-icon"><NotificationsIcon /></span>
                <span className="job-notification-title-text">Notifications</span>
            </h3>
            {notifications?.length > 0 ?
                notifications.map(notification => (
                    <Link
                        key={notification._id}
                        className="candidate-application-link"
                        to={getPathToCandidateApplication(notification.jobId, notification.candidateId, notification.applicationId)}
                        onClick={() => logClickEvent(notification.type)}
                    >
                        <div className="job-notification">
                            <span className={`notification-type-indication-box ${notification.isSeen ? 'seen-indicator' : 'new-indicator'}`}></span>
                            <div className="job-notification-information">
                                <span className="job-notification-text">
                                    {`${NOTIFICATION_TYPES_MESSAGES(
                                        notification.type !== NOTIFICATION_TYPES.NEW_APPLICATION
                                            ? notification.candidateName
                                            : notification.position)
                                    [notification.type]}`}
                                </span>
                                <span className="job-notification-date">
                                    {notification.added}
                                </span>
                            </div>
                        </div>
                    </Link>
                ))
                : <NothingToShow items="notifications" />
            }
        </div >
    );
}

export default JobNotifications;
