import {
    CLIENT_PROJECTS_FETCH_SUCCESS,
    CLIENT_PROJECT_ADD_SUCCESS,
    PROJECT_REVIEWS_FETCH_SUCCESS,
    EMPLOYEE_TO_PROJECT_ADD_SUCCESS,
    DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS,
    DEDICATED_EMPLOYEE_TO_PROJECT_UPDATE_SUCCESS,
    PROJECT_EMPLOYEE_UPDATE_SUCCESS,
    PROJECT_EMPLOYEE_DELETE_SUCCESS,
    PROJECT_DEDICATED_EMPLOYEE_DELETE_SUCCESS,
    CLIENT_PROJECT_UPDATE_SUCCESS,
    SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS,
    CLIENT_PROJECT_FILES_ADD_SUCCESS,
    CLIENT_PROJECT_DOCUMENTS_FETCH_SUCCESS,
    CLIENT_PROJECT_LINK_ADD_SUCCESS,
    CLIENT_PROJECT_LINK_DELETE_SUCCESS,
    CLIENT_PROJECT_FILE_DELETE_SUCCESS,
    CLIENT_PROJECT_FETCH_PENDING,
    CLIENT_PROJECT_FETCH_SUCCESS,
    CLIENT_PROJECT_EMPLOYEE_HISTORY_FETCH_SUCCESS,
    CLIENT_PROJECTS_COUNT_FETCH_SUCCESS,
    CLIENT_TEAM_MEMBERS_COUNT_FETCH_SUCCESS,
} from 'actions/actionTypes';

const clientProjectEmployeeInitialState = {
    _id: '',
    firstName: '',
    lastName: '',
    employmentInformation: {
        generalInfo: {
            position: ''
        }
    },
    hourRate: 0,
    trackedHours: 0,
    currency: '',
    employeeId: '',
};

const employee = (state = clientProjectEmployeeInitialState, action = {}) => {
    if (state._id && state._id !== action.payload.employeeId) {
        return state;
    }

    switch (action.type) {
        case PROJECT_EMPLOYEE_UPDATE_SUCCESS:
        case DEDICATED_EMPLOYEE_TO_PROJECT_UPDATE_SUCCESS:
            return action.payload.data ? { ...state, ...action.payload.data } : { ...state };
        default:
            return state;
    }
};

const dedicatedEmployeesInitialState = [];

const dedicatedEmployees = (state = dedicatedEmployeesInitialState, action = {}) => {
    if (!action.payload) {
        return state;
    }

    switch (action.type) {
        case CLIENT_PROJECT_FETCH_SUCCESS:
        case SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS:
        case CLIENT_PROJECTS_FETCH_SUCCESS:
            return action.payload.map(x => ({ billingType: 'Monthly', ...clientProjectEmployeeInitialState, ...x }));
        case DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS:
            return [
                ...state,
                {
                    billingType: 'Monthly',
                    ...clientProjectEmployeeInitialState,
                    ...action.payload
                }
            ];
        case DEDICATED_EMPLOYEE_TO_PROJECT_UPDATE_SUCCESS:
            const result = state.map(emp => employee(emp, action));
            return result;
        default:
            return state;
    }
};

const employeesInitialState = [];

const employees = (state = employeesInitialState, action = {}) => {
    if (!action.payload) {
        return state;
    }

    switch (action.type) {
        case CLIENT_PROJECT_FETCH_SUCCESS:
        case SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS:
        case CLIENT_PROJECTS_FETCH_SUCCESS:
            return action.payload.map(x => ({ billingType: 'Per Hour', ...clientProjectEmployeeInitialState, ...x }));
        case EMPLOYEE_TO_PROJECT_ADD_SUCCESS:
            return [
                ...state,
                {
                    billingType: 'Per Hour',
                    ...clientProjectEmployeeInitialState,
                    ...action.payload
                }
            ];
        case PROJECT_EMPLOYEE_UPDATE_SUCCESS:
            return state.map(x => employee(x, action));
        default:
            return state;
    }
};

const clientProjectDocumentsFileInitialState = {
    _id: '',
    cloudName: '',
    fileType: '',
    originalFileName: '',
    dateAdded: '',
    type: 'file'
};

const clientProjectDocumentsLinkInitialState = {
    _id: '',
    name: '',
    url: '',
    dateAdded: '',
    type: 'link'
};

const documentsFile = (state = clientProjectDocumentsFileInitialState, action) => {
    switch (action.type) {
        case CLIENT_PROJECT_FILES_ADD_SUCCESS:
            return { ...clientProjectDocumentsFileInitialState, ...state };
        default:
            return state;
    }
};

const documentsLink = (state = clientProjectDocumentsLinkInitialState, action) => {
    switch (action.type) {
        case CLIENT_PROJECT_LINK_ADD_SUCCESS:
            return { ...clientProjectDocumentsLinkInitialState, ...state };
        default:
            return state;
    }
};

const clientProjectInitialState = {
    _id: '',
    name: '',
    clientName: '',
    description: '',
    ongoing: false,
    startDate: '',
    endDate: '',
    contactPerson: '',
    contactEmail: '',
    contactPhone: '',
    employees: employees(),
    dedicatedEmployees: dedicatedEmployees(),
    feedback: {
        reviews: [],
        overallPerformance: '',
        categoriesPerformance: []
    },
    documents: {
        files: [],
        links: []
    },
};

const projectDetails = (state = clientProjectInitialState, action) => {
    switch (action.type) {
        case CLIENT_PROJECT_FETCH_PENDING:
            return clientProjectInitialState;
        case CLIENT_PROJECT_FETCH_SUCCESS:
            return {
                ...clientProjectInitialState,
                ...action.payload,
                employees: employees(undefined, { ...action, payload: action.payload.employees }),
                dedicatedEmployees: dedicatedEmployees(undefined, { ...action, payload: action.payload.dedicatedEmployees })
            };
        case CLIENT_PROJECT_DOCUMENTS_FETCH_SUCCESS:
            return {
                ...state, documents: { ...state.documents, ...action.payload.documents }
            }
        case CLIENT_PROJECT_FILES_ADD_SUCCESS:
            const filesData = action.payload.files ? [...state.documents.files, ...action.payload.files.map(x => documentsFile(x, action))] : [];
            return { ...state, documents: { ...state.documents, files: filesData } };
        case CLIENT_PROJECT_LINK_ADD_SUCCESS:
            const linksData = [...state.documents.links, documentsLink(action.payload.link, action)];
            return { ...state, documents: { ...state.documents, links: linksData } };
        case CLIENT_PROJECT_LINK_DELETE_SUCCESS:
            const filteredLinks = state.documents.links.filter(x => x._id !== action.payload.documentId);
            return { ...state, documents: { ...state.documents, links: filteredLinks } };
        case CLIENT_PROJECT_FILE_DELETE_SUCCESS:
            const filteredFiles = state.documents.files.filter(x => x._id !== action.payload.documentId);
            return { ...state, documents: { ...state.documents, files: filteredFiles } };
        case PROJECT_EMPLOYEE_UPDATE_SUCCESS:
            return {
                ...state,
                employees: employees(state.employees, action)
            };
        case CLIENT_PROJECT_UPDATE_SUCCESS:
            return { ...state, ...action.payload, employees: state.employees, dedicatedEmployees: state.dedicatedEmployees };
        case EMPLOYEE_TO_PROJECT_ADD_SUCCESS:
            const newAction = { ...action, payload: { ...action.payload, _id: action.payload._id, employeeId: action.payload.employeeId } }

            return {
                ...state,
                employees: employees(state.employees, newAction),
            };
        case PROJECT_EMPLOYEE_DELETE_SUCCESS:
            return {
                ...state,
                employees: state.employees.map(x => x._id === action.payload.employee._id ? { ...x, ...action.payload.employee } : x)
            }
        case PROJECT_DEDICATED_EMPLOYEE_DELETE_SUCCESS:
            return {
                ...state,
                dedicatedEmployees: state.dedicatedEmployees.map(x => x._id === action.payload.employee._id ? { ...x, ...action.payload.employee } : x)
            }
        case DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS:
            const newDedicatedAction = { ...action, payload: { ...action.payload, _id: action.payload._id, employeeId: action.payload.employeeId } }

            return {
                ...state,
                dedicatedEmployees: dedicatedEmployees(state.dedicatedEmployees, newDedicatedAction)
            }
        case DEDICATED_EMPLOYEE_TO_PROJECT_UPDATE_SUCCESS:
            return {
                ...state,
                dedicatedEmployees: dedicatedEmployees(state.dedicatedEmployees, action)
            };
        default:
            return state;
    }
};

const project = (state = clientProjectInitialState, action = {}) => {
    // TODO: Refactor all client project actions to use projectId instead of action.payload._id
    if (state._id && !(state._id === action.payload._id || state._id === action.payload.projectId)) {
        return state;
    }

    switch (action.type) {
        case SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS:
        case CLIENT_PROJECT_FETCH_SUCCESS:
        case CLIENT_PROJECTS_FETCH_SUCCESS:
            return {
                ...state,
                ...action.payload,
                employees: employees(undefined, { ...action, payload: action.payload.employees }),
                dedicatedEmployees: dedicatedEmployees(undefined, { ...action, payload: action.payload.dedicatedEmployees })
            };
        case CLIENT_PROJECT_ADD_SUCCESS:
            return { ...state, ...action.payload };
        case CLIENT_PROJECT_UPDATE_SUCCESS:
            return { ...state, ...action.payload, employees: state.employees, dedicatedEmployees: state.dedicatedEmployees };
        case PROJECT_REVIEWS_FETCH_SUCCESS:
            return { ...state, feedback: action.payload.reviews }
        case PROJECT_EMPLOYEE_DELETE_SUCCESS:
            return {
                ...state,
                employees: state.employees.filter(x => x._id !== action.payload.employee._id),
            }
        case PROJECT_DEDICATED_EMPLOYEE_DELETE_SUCCESS:
            return {
                ...state,
                dedicatedEmployees: state.dedicatedEmployees.map(x => x._id === action.payload.employee._id ? { ...x, ...action.payload.employee } : x)
            }
        case DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS:
            return {
                ...state,
                dedicatedEmployees: dedicatedEmployees(state.dedicatedEmployees, action)
            }

        case EMPLOYEE_TO_PROJECT_ADD_SUCCESS:
            return {
                ...state,
                employees: employees(state.employees, action),
            };
        case PROJECT_EMPLOYEE_UPDATE_SUCCESS:
            return {
                ...state,
                employees: employees(state.employees, action)
            };
        case DEDICATED_EMPLOYEE_TO_PROJECT_UPDATE_SUCCESS:
            return {
                ...state,
                dedicatedEmployees: dedicatedEmployees(state.dedicatedEmployees, action)
            };
        default:
            return state;
    }
}

const clientProjectsCountInitialState = {
    activeProjectsCount: 0,
    pastProjectsCount: 0
};

const projectsCount = (state = clientProjectsCountInitialState, action = {}) => {
    switch (action.type) {
        case CLIENT_PROJECTS_COUNT_FETCH_SUCCESS:
            return action.payload
        default:
            return state;
    }
};

const clientTeamMembersCountInitialState = {
    contractMembersCount: 0,
    fullTimeMembersCount: 0
};

const teamMembersCount = (state = clientTeamMembersCountInitialState, action = {}) => {
    switch (action.type) {
        case CLIENT_TEAM_MEMBERS_COUNT_FETCH_SUCCESS:
            return action.payload
        default:
            return state;
    }
};

const clientProjectsInitialState = {
    projectsList: [],
    projectDetails: clientProjectInitialState,
    projectEmployeeHistory: [],
    allClientTeamHistory: [],
    projectsCount: clientProjectsCountInitialState,
    teamMembersCount: clientTeamMembersCountInitialState,
};

const clientProjects = (state = clientProjectsInitialState, action = {}) => {
    switch (action.type) {
        case SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS:
        case CLIENT_PROJECTS_FETCH_SUCCESS:
            return { ...clientProjectsInitialState, projectsList: action.payload.map(x => project(undefined, { ...action, payload: x })) };
        case CLIENT_PROJECT_ADD_SUCCESS:
            return { ...state, projectsList: [project(undefined, action), ...state.projectsList] }
        case PROJECT_REVIEWS_FETCH_SUCCESS:
        case CLIENT_PROJECT_UPDATE_SUCCESS:
        case EMPLOYEE_TO_PROJECT_ADD_SUCCESS:
        case PROJECT_EMPLOYEE_UPDATE_SUCCESS:
        case DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS:
        case DEDICATED_EMPLOYEE_TO_PROJECT_UPDATE_SUCCESS:
        case CLIENT_PROJECT_FETCH_SUCCESS:
        case CLIENT_PROJECT_DOCUMENTS_FETCH_SUCCESS:
        case CLIENT_PROJECT_FILES_ADD_SUCCESS:
        case CLIENT_PROJECT_LINK_ADD_SUCCESS:
        case CLIENT_PROJECT_LINK_DELETE_SUCCESS:
        case CLIENT_PROJECT_FILE_DELETE_SUCCESS:
        case PROJECT_DEDICATED_EMPLOYEE_DELETE_SUCCESS:
        case PROJECT_EMPLOYEE_DELETE_SUCCESS:
            return { ...state, projectsList: state.projectsList.map(x => project(x, action)), projectDetails: projectDetails(state.projectDetails, action) }
        case CLIENT_PROJECT_EMPLOYEE_HISTORY_FETCH_SUCCESS:
            return { ...state, projectEmployeeHistory: action.payload }
        case CLIENT_PROJECT_FETCH_PENDING:
            return { ...state, projectDetails: projectDetails(state.projectDetails, action) }
        case CLIENT_PROJECTS_COUNT_FETCH_SUCCESS:
            return { ...state, projectsCount: projectsCount(state.projectsCount, action) }
        case CLIENT_TEAM_MEMBERS_COUNT_FETCH_SUCCESS:
            return { ...state, teamMembersCount: teamMembersCount(state.projectsCount, action) }
        default:
            return state;
    }
};

export default clientProjects;
