export const TABLE_COLUMNS = [
    { name: 'From', value: 'from', propertyName: 'startDate', isSortable: true, langKey: "from" },
    { name: 'To', value: 'to', propertyName: 'endDate', isSortable: true, langKey: "to" },
    { name: 'Days', value: 'numberOfDays', propertyName: 'numberOfDays', isSortable: true, langKey: "days" },
    { name: 'Type', value: 'type', propertyName: 'type', isSortable: true, langKey: "type" },
    { name: 'Requested on', value: 'requestedOn', propertyName: 'requestedOn', isSortable: true, langKey: "requested-on" },
];

export const DEFAULT_TABLE_COLUMNS = [
    'from',
    'to',
    'numberOfDays',
    'type',
    'requestedOn',
];

export const SORT_QUERY_SELECTOR = 'pendingSort';
export const PAGE_QUERY_SELECTOR = 'pendingPage';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const TABLE_INITIAL_QUERY = {
    page: 1,
    sortBy: 'from',
    sortByDir: 'desc',
};

export const CONFIRM_DELETE_DIALOG_TITLE = 'selected request';
