import {
    VIDEO_INTERVIEW_ANSWER_FETCH_SUCCESS,
    VIDEO_INTERVIEW_QUESTIONS_FETCH_SUCCESS,
} from 'actions/actionTypes';

const videoInterviewInitialState = {
    isAvailable: false,
    candidateAnswers: [],
    questionsList: [],
};

const videoInterview = (state = videoInterviewInitialState, action) => {
    switch (action.type) {
        case VIDEO_INTERVIEW_ANSWER_FETCH_SUCCESS: 
            return { ...state, candidateAnswers: action.payload };
        case VIDEO_INTERVIEW_QUESTIONS_FETCH_SUCCESS:
            return { ...state, questionsList: action.payload };
        default:
            return state;
    }
};

export default videoInterview;

export const getVideoInterviewAnswers = state => state.candidateAnswers;
export const getVideoInterviewQuestionsList = state => state.questionsList;
