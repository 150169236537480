import {
	NOTIFICATION_TRIGGER,
	NOTIFICATION_CLEAR,
} from 'actions/actionTypes';

const initialState = { notificationOpen: false, notificationClickaway: true };

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case NOTIFICATION_TRIGGER:
        return {
          notificationOpen: true,
          notificationMessage: action.payload.message,
          notificationInfoList: action.payload.infoList,
          notificationType: action.payload.notificationType,
          notificationClickaway: action.payload.clickaway
        };
      case NOTIFICATION_CLEAR:
        return initialState;
      default:
        return state;
    }
};

export default notificationReducer;

export const isNotificationOpen = state => state.notificationOpen;
export const getNotificationMessage = state => state.notificationMessage;
export const getNotificationInfoList = state => state.notificationInfoList;
export const getNotificationType = state => state.notificationType;
export const isNotificationClickawayEnabled = state => state.notificationClickaway;
