import React from 'react';
import Rating from '@material-ui/lab/Rating';

import { ReactComponent as FilledStar } from 'assets/awesome-star-icon-filled.svg';
import { ReactComponent as EmptyStar } from 'assets/awesome-star-icon-empty.svg';

const FeedbackRating = ({
    category,
    rating,
}) => {
    return (
        <div className="feedback-block grid col-2">
            <div className="feedback-block-content">
                <h3>{category}</h3>
            </div>

            <div className="feedback-block-content">
                    <Rating
                        icon={<FilledStar />}
                        emptyIcon={<EmptyStar />}
                        name="read-only"
                        value={rating}
                        readOnly
                    />
            </div>
        </div>
    )
};

export default FeedbackRating;
