import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';

import TableWrapper from 'components/Shared/TableWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import ExportOptionsPopover from 'components/Shared/ExportOptionsPopover';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { ReactComponent as ExportIcon } from 'assets/export-button-icon.svg';

import {
    SORT_QUERY_SELECTOR,
    PAGE_QUERY_SELECTOR,
    DATE_QUERY_SELECTOR,
    SEARCH_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    TABLE_INITIAL_QUERY,
    TABLE_QUERY_MAP,
    CONFIRM_DELETE_DIALOG_TITLE,
} from './HistoryTeamHolidaysTableConstants';
import { MONTH_YEAR_DATE_FORMAT, DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { HOLIDAY_SIGNATURE_STATUSES, HOLIDAY_APPROVAL_STATUSES, HOLIDAY_TYPES_FULL_NAMES_MAP } from 'constants/holidayConstants';
import { ROLES } from 'constants/userConstants';
import { HOLIDAY_TYPES } from 'components/Person/Holidays/HolidaysRequests/HolidayRequestDialog/HolidayRequestDialogConstants';
import { DOCUMENTS_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { DOCUMENT_TYPES } from 'constants/documentsConstants';

import { getTableColumnsAndFilters, getDataFromQuery, getHolidaysExportFileName } from './HistoryTeamHolidaysTableUtils';
import { getBrowserLocation, mapStateToPropsPicker } from '../TeamHolidaysUtils';
import { capitalizeFirstLetter, parseQueryString, queryObjToString } from 'utils/helpers';
import { usePreloadTable } from 'utils/hooks';
import { convertStringToLanguageKey } from 'utils/languageUtils';

import './HistoryTeamHolidaysTable.scss';

const HistoryTeamHolidaysTable = ({
    userId,
    agencyId,
    displayMode,
    generateToString,
    isUsingEvrotrust,
    tableFetchAction,
    tablePrefetchAction,
    historyReload,
    setHistoryReload,
    sendHolidaySignReminderEmail,
    handleDownloadSignedFile,
    deleteHolidayRequests,
    showNotification,
    exportDocumentsData,
    downloadDocumentsFiles,
}) => {
    const intl = useIntl();

    const history = useHistory();
    const isDepartmentView = history.location?.pathname?.includes('/departments/holidays');
    const isAdmin = displayMode === ROLES.ADMIN || displayMode === ROLES.SUPPLIER_ADMIN;
    const isAgencyAdmin = displayMode === ROLES.ADMIN;
    const isAgencyMember = displayMode === ROLES.ADMIN || displayMode === ROLES.EMPLOYEE;

    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const [holidayToDelete, setHolidayToDelete] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    const { TABLE_COLUMNS, DEFAULT_TABLE_COLUMNS, TABLE_FILTERS } = getTableColumnsAndFilters(displayMode, isUsingEvrotrust);

    const browserLocation = getBrowserLocation(history);
    const { getApprovedHolidaysHistory } = mapStateToPropsPicker(browserLocation);
    const dataSelector = (state, page) => getApprovedHolidaysHistory(state, page - 1, DEFAULT_ITEMS_PER_PAGE);
    const approvedHolidaysHistory = useSelector((state) => getApprovedHolidaysHistory(state));

    const [isTableDataLoading, totalCount] = usePreloadTable(
        TABLE_INITIAL_QUERY,
        TABLE_QUERY_MAP,
        TABLE_FILTERS,
        tableFetchAction,
        historyReload,
        setHistoryReload,
        tablePrefetchAction,
    );

    const getHolidayDocumentsInformation = holiday => {
        return (
            <div>
                {holiday.trackingInfo?.transactions.length > 0 ? holiday.trackingInfo.transactions.map(transaction =>
                    <div key={transaction.transactionID}>
                        <p>TransactionID: {transaction.transactionID}</p>
                        <p>Document Proccesed: {transaction.isProcessing ? 'No' : 'Yes'} </p>
                        {(holiday.signatureStatus === HOLIDAY_SIGNATURE_STATUSES.SIGNED || transaction.status) &&
                            <p>
                                Document Status: {holiday.signatureStatus === HOLIDAY_SIGNATURE_STATUSES.SIGNED ? capitalizeFirstLetter(HOLIDAY_SIGNATURE_STATUSES.SIGNED) : transaction.status}
                            </p>
                        }
                    </div>
                ) : null}
            </div>
        );
    };

    const adjustHistoryHolidaysKeysFunction = holidays => holidays.map(holiday => {
        let holidayTypeFullName;

        if (holiday.type === HOLIDAY_TYPES.PAID && (holiday.byArticle === '155' || holiday.byArticle === '169')) {
            holidayTypeFullName = intl.formatMessage({ id: convertStringToLanguageKey(HOLIDAY_TYPES_FULL_NAMES_MAP[holiday.byArticle]) });
        }

        return {
            ...holiday,
            _id: holiday._id,
            employeeId: holiday.employeeId,
            name: holiday.name,
            from: moment.utc(holiday.from).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            to: moment.utc(holiday.to).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            numberOfDays: holiday.numberOfDays,
            type: holiday.type === HOLIDAY_TYPES.PAID && holiday.byArticle
                ? `${intl.formatMessage({ id: convertStringToLanguageKey(holiday.type) })} ${intl.formatMessage({ id: "by-article-text" })} ${holiday.byArticle}${holidayTypeFullName ? ` - ${holidayTypeFullName}` : ''}${holiday.description ? `, ${intl.formatMessage({ id: convertStringToLanguageKey(holiday.description) })}` : ''}`
                : intl.formatMessage({ id: convertStringToLanguageKey(holiday.type) }),
            requestedOn: moment.utc(holiday.requestedOn).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            status: (<span className={`approval-status ${holiday.status}`}>{intl.formatMessage({ id: convertStringToLanguageKey(holiday.status) })}</span>),
            signatureStatus: isAgencyMember && isUsingEvrotrust && (
                <div className="signature-status-wrapper">
                    <span className={`${holiday.signatureStatus}`}>{intl.formatMessage({ id: convertStringToLanguageKey(holiday.signatureStatus) })}</span>
                    {(isAgencyAdmin || isDepartmentView) && holiday.trackingInfo
                        ? <Tooltip title={getHolidayDocumentsInformation(holiday)} classes={{ tooltip: 'custom-tooltip' }}>
                            <InfoOutlinedIcon className="global-information-icon" />
                        </Tooltip>
                        : null
                    }
                </div>
            ),
            item: holiday,
        };
    });

    const handleSendReminderEmail = holiday => {
        sendHolidaySignReminderEmail(agencyId, null, holiday.employeeId, holiday._id);
    }

    const handleCloseConfirmDeleteDialog = () => {
        setIsConfirmDeleteDialogOpen(false);
        setHolidayToDelete({});
    };

    const handleDeleteHolidayHistoryClick = holiday => {
        setHolidayToDelete(holiday);
        setIsConfirmDeleteDialogOpen(true);
    };

    const handleConfirmDeleteDialogSubmit = async () => {
        const { _id, employeeId } = holidayToDelete;
        await deleteHolidayRequests(employeeId, agencyId, null, [_id], null, null, false);
        setHistoryReload(true);
        setHolidayToDelete({});
        setIsConfirmDeleteDialogOpen(false);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleExportButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleExportTable = async () => {
        const fileName = `WIA_Holidays_Export_as_of_${moment().format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`;

        const parsedQuery = parseQueryString(history.location.search, true);
        const queryObj = getDataFromQuery(parsedQuery, TABLE_FILTERS);
        const queryObjAsString = queryObjToString({
            ...queryObj,
            filters: JSON.stringify(queryObj.filters),
            documentType: DOCUMENT_TYPES.HOLIDAY
        });

        await exportDocumentsData(agencyId, [], fileName, queryObjAsString);
        handleClosePopover();
    };

    const handleExportDocumentsFiles = async () => {
        if (approvedHolidaysHistory?.length === 0) {
            showNotification(DOCUMENTS_MESSAGES.NO_DOCUMENTS_TO_EXPORT_WARNING, NOTIFICATION_TYPES.WARNING);
            return;
        }

        const fileName = getHolidaysExportFileName(approvedHolidaysHistory);

        const parsedQuery = parseQueryString(history.location.search, true);
        const queryObj = getDataFromQuery(parsedQuery, TABLE_FILTERS);
        const queryObjAsString = queryObjToString({
            ...queryObj,
            filters: JSON.stringify(queryObj.filters),
            documentType: DOCUMENT_TYPES.HOLIDAY
        });

        await downloadDocumentsFiles(agencyId, [], fileName, userId, queryObjAsString);
        handleClosePopover();
    };

    const resendEmailAction = { title: intl.formatMessage({ id: "resend-email" }), handleClick: handleSendReminderEmail };
    const downloadFileAction = { title: intl.formatMessage({ id: "download-file" }), handleClick: handleDownloadSignedFile };
    const deleteHolidayHistoryAction = { title: intl.formatMessage({ id: "delete-holiday-history" }), handleClick: handleDeleteHolidayHistoryClick }

    const getActionsHandler = holiday => {
        const actions = [];

        if ((isAgencyAdmin || isDepartmentView) && [HOLIDAY_TYPES.PAID, HOLIDAY_TYPES.UNPAID].includes(holiday.type)) {
            actions.push(resendEmailAction);
        }

        if ([HOLIDAY_TYPES.PAID, HOLIDAY_TYPES.UNPAID].includes(holiday.type)) {
            actions.push(downloadFileAction);
        }

        if (isAdmin && holiday.status !== HOLIDAY_APPROVAL_STATUSES.DELETED) {
            actions.push(deleteHolidayHistoryAction);
        }

        return actions;
    };

    return (
        <div className="history-team-holidays-wrapper">
            <TableWrapper
                title={intl.formatMessage({ id: "holidays-history-section-title" })}
                columns={TABLE_COLUMNS}
                defaultColumns={DEFAULT_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustHistoryHolidaysKeysFunction}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withPagination
                withActions
                withLink
                getActionsHandler={getActionsHandler}
                generateToString={generateToString}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableFilter filters={TABLE_FILTERS} />
                        <TableWrapper.TableAlignedItems.TableDateFilter
                            placeholderText={intl.formatMessage({ id: "holiday-date" })}
                            queryParamName={DATE_QUERY_SELECTOR}
                            format={MONTH_YEAR_DATE_FORMAT}
                        />
                        <TableWrapper.TableAlignedItems.TableSearch queryParamName={SEARCH_QUERY_SELECTOR} />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton
                            text={intl.formatMessage({ id: "export-button-text" })}
                            icon={<ExportIcon />}
                            handleClick={handleExportButtonClick}
                        />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>

            <ConfirmDeleteDialog
                itemToDelete={CONFIRM_DELETE_DIALOG_TITLE}
                openDialog={isConfirmDeleteDialogOpen}
                handleDeleteItem={handleConfirmDeleteDialogSubmit}
                handleCloseDialog={handleCloseConfirmDeleteDialog}
            />

            <ExportOptionsPopover
                anchorEl={anchorEl}
                handleClose={handleClosePopover}
                handleExportTable={handleExportTable}
                handleExportDocumentsFiles={handleExportDocumentsFiles}
                dataSelector={getApprovedHolidaysHistory}
                displayMode={displayMode}
            />
        </div>
    );
};

export default HistoryTeamHolidaysTable;
