import { createSelector } from 'reselect';
import moment from 'moment';
import { persistReducer } from 'redux-persist';
import { DATE_FORMAT_WITH_SLASH_SHORT_YEAR } from 'constants/commonConstants.js';
import {
    CONTRACT_SIGNATURE_STATUSES_TO_DOCUMENT_STATUSES_MAP,
    CONTRACT_TYPES_TO_DOCUMENT_TYPES_MAP
} from 'constants/documentsConstants';
import { PERSIST_MANAGER_CONFIG } from 'utils/persistUtils';
import { escapeRegExp } from 'utils/regexUtils';
import {
    MANAGED_EMPLOYEES_PENDING_HOLIDAYS_FETCH_SUCCESS,
    MANAGED_EMPLOYEES_PENDING_HOLIDAYS_REPLACE_SUCCESS,
    MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_FETCH_SUCCESS,
    MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_REPLACE_SUCCESS,
    MANAGED_EMPLOYEES_SICK_LEAVES_FETCH_SUCCESS,
    MANAGED_EMPLOYEES_APPROVED_HOLIDAYS_FETCH_SUCCESS,
    MANAGED_EMPLOYEES_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    MANAGED_EMPLOYEES_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    MANAGED_EMPLOYEE_HOLIDAY_CREATE_REQUESTS_SUCCESS,
    MANAGED_EMPLOYEES_HOLIDAYS_DELETE_REQUESTS_SUCCESS,
    MANAGED_EMPLOYEES_DOCUMENTS_FETCH_SUCCESS,
    MANAGED_EMPLOYEES_DOCUMENTS_REPLACE_SUCCESS,
} from 'actions/actionTypes';

const managerInitialState = {
    pendingHolidays: [],
    approvedHolidays: [],
    holidaysHistory: [],
    sickLeaves: [],
    documents: [],
    pendingHolidayRequestsCount: 0
}

const manager = (state = managerInitialState, action) => {
    switch (action.type) {
        case MANAGED_EMPLOYEES_PENDING_HOLIDAYS_FETCH_SUCCESS:
            return { ...state, pendingHolidays: [...state.pendingHolidays, ...action.payload] }
        case MANAGED_EMPLOYEES_PENDING_HOLIDAYS_REPLACE_SUCCESS:
            return { ...state, pendingHolidays: action.payload, pendingHolidayRequestsCount: action.payload.length };
        case MANAGED_EMPLOYEE_HOLIDAY_CREATE_REQUESTS_SUCCESS:
            const pendingHolidays = [...state.pendingHolidays, ...action.payload];
            return { ...state, pendingHolidays, pendingHolidayRequestsCount: state.pendingHolidayRequestsCount + 1 };
        case MANAGED_EMPLOYEES_HOLIDAYS_APPROVE_REQUESTS_SUCCESS:
            return {
                ...state,
                pendingHolidays: state.pendingHolidays.filter(x => !action.payload.some(y => y._id === x._id)),
                approvedHolidays: [...state.approvedHolidays, ...action.payload],
                holidaysHistory: [...state.holidaysHistory, ...action.payload],
                pendingHolidayRequestsCount: state.pendingHolidayRequestsCount - action.payload.length
            }
        case MANAGED_EMPLOYEES_HOLIDAYS_REJECT_REQUEST_SUCCESS:
            return {
                ...state,
                pendingHolidays: state.pendingHolidays.filter(x => !action.payload.some(y => y._id === x._id)),
                holidaysHistory: [...state.holidaysHistory, ...action.payload],
                pendingHolidayRequestsCount: state.pendingHolidayRequestsCount - action.payload.length
            }
        case MANAGED_EMPLOYEES_HOLIDAYS_DELETE_REQUESTS_SUCCESS:
            return {
                ...state,
                pendingHolidays: state.pendingHolidays.filter(x => !action.payload.includes(x._id)),
                pendingHolidayRequestsCount: state.pendingHolidayRequestsCount - action.payload.length
            };
        case MANAGED_EMPLOYEES_APPROVED_HOLIDAYS_FETCH_SUCCESS:
            return { ...state, approvedHolidays: action.payload };
        case MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_FETCH_SUCCESS:
            return { ...state, holidaysHistory: [...state.holidaysHistory, ...action.payload] };
        case MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_REPLACE_SUCCESS:
            return { ...state, holidaysHistory: action.payload };
        case MANAGED_EMPLOYEES_SICK_LEAVES_FETCH_SUCCESS:
            return { ...state, sickLeaves: action.payload }
        case MANAGED_EMPLOYEES_DOCUMENTS_FETCH_SUCCESS:
            return { ...state, documents: [...state.documents, ...action.payload] };
        case MANAGED_EMPLOYEES_DOCUMENTS_REPLACE_SUCCESS:
            return { ...state, documents: [...action.payload] };
        default:
            return state;
    }
}

const pageInputSelector = (_, page) => page;
const countInputSelector = (_, __, count) => count;
const pendingHolidaysSelector = state => state.pendingHolidays;


export const getOwnEmployeesPendingHolidays = createSelector(
    [pendingHolidaysSelector, pageInputSelector, countInputSelector],
    (state, page, count) => {
        const from = page * count;
        const to = page * count + count;

        return state.slice(from, to || state.length);
    }
);

export const getOwnEmployeesPendingHolidayRequestsCount = (state) => state.pendingHolidayRequestsCount;
export const getOwnEmployeesApprovedHolidays = (state) => state.approvedHolidays || [];
export const getOwnEmployeesSickLeaves = (state) => state.sickLeaves || [];

export const getOwnEmployeesHolidaysHistory = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.holidaysHistory.slice(from || 0, to || state.holidaysHistory.length);
}

export const getOwnEmployeesDocuments = (state, page, count, comparer, filters) => {
    const from = page * count;
    const to = page * count + count;

    let filteredDocuments = state.documents
        .filter(document => {
            const hasDocumentType = filters?.documentType
                ? filters.documentType === CONTRACT_TYPES_TO_DOCUMENT_TYPES_MAP[document.type]
                : true;

            const hasStatus = filters?.documentStatus
                ? filters.documentStatus === CONTRACT_SIGNATURE_STATUSES_TO_DOCUMENT_STATUSES_MAP[document.signatureStatus]
                : true;

            let isUploadDateInRange = true;
            if (filters?.uploadDate) {
                const [uploadDateFrom, uploadDateTo] = filters.uploadDate.split('-');
                const documentUploadDateParsed = moment(document.uploadDate).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR);

                isUploadDateInRange = moment(documentUploadDateParsed, DATE_FORMAT_WITH_SLASH_SHORT_YEAR)
                    .isBetween(
                        moment(uploadDateFrom, DATE_FORMAT_WITH_SLASH_SHORT_YEAR),
                        moment(uploadDateTo, DATE_FORMAT_WITH_SLASH_SHORT_YEAR),
                        undefined,
                        '[]'
                    );
            }

            let isSignatureDateInRange = true;
            if (filters?.signatureDate) {
                if (document.dateSigned) {
                    const [signatureDateFrom, signatureDateTo] = filters.signatureDate.split('-');
                    const documentDateSignedParsed = moment(document.dateSigned).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR);

                    isSignatureDateInRange = moment(documentDateSignedParsed, DATE_FORMAT_WITH_SLASH_SHORT_YEAR)
                        .isBetween(
                            moment(signatureDateFrom, DATE_FORMAT_WITH_SLASH_SHORT_YEAR),
                            moment(signatureDateTo, DATE_FORMAT_WITH_SLASH_SHORT_YEAR),
                            undefined,
                            '[]'
                        );
                } else {
                    isSignatureDateInRange = false;
                }
            }

            let isSearchMatch = true;
            if (filters?.searchTerm) {
                const regexTest = new RegExp(escapeRegExp(filters.searchTerm), 'gi');
                isSearchMatch = regexTest.test(`${document.user.firstName} ${document.user.lastName}`);
            }

            return hasStatus && hasDocumentType && isUploadDateInRange && isSignatureDateInRange && isSearchMatch;
        });

    if (comparer) {
        filteredDocuments = filteredDocuments.sort(comparer);
    }

    return {
        slicedFilteredDocuments: filteredDocuments.slice(from, to || filteredDocuments.length),
        allFilteredDocuments: filteredDocuments,
        totalDocumentsCount: filteredDocuments.length,
    };
};

export default persistReducer(PERSIST_MANAGER_CONFIG, manager);
