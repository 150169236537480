import {
    COACHING_OPPORTUNITIES_DELETE_SUCCESS,
    COACHING_OPPORTUNITIES_EDIT_SUCCESS,
    COACHING_OPPORTUNITIES_FETCH_SUCCESS,
    COACHING_OPPORTUNITIES_SESSIONS_FETCH_SUCCESS,
    COACHING_OPPORTUNITY_CREATE_SUCCESS,
    COACHING_OPPORTUNITY_FETCH_SUCCESS,
    COACHING_OPPORTUNITY_SESSION_CREATE_SUCCESS,
    COACHING_OPPORTUNITY_SESSIONS_FETCH_SUCCESS,
} from "actions/actionTypes";

import { combineReducers } from "redux";

const coachingOpportunitiesInitialState = [];

const coachingOpportunitiesList = (state = coachingOpportunitiesInitialState, action) => {
    switch (action.type) {
        case COACHING_OPPORTUNITIES_FETCH_SUCCESS:
            return [...action.payload];
        case COACHING_OPPORTUNITY_CREATE_SUCCESS:
            return [...state, { ...action.payload }];
        case COACHING_OPPORTUNITIES_EDIT_SUCCESS:
            return [...state.map(x => {
                if (x._id === action.payload._id) {
                    return action.payload;
                }
                return x;
            })];
        case COACHING_OPPORTUNITIES_DELETE_SUCCESS:
            return [...state.filter(x => x._id !== action.payload._id)];
        default:
            return state;
    }
};

const initialSessionsState = [];

const coachingOpportunitySessions = (state = initialSessionsState, action = {}) => {
    switch (action.type) {
        case COACHING_OPPORTUNITY_SESSIONS_FETCH_SUCCESS:
            return action.payload;
        case COACHING_OPPORTUNITY_SESSION_CREATE_SUCCESS:
            return [...state, action.payload];
        default:
            return state;
    }
};

const coachingOpportunityInitialState = {
    title: '',
    description: '',
    coachName: '',
    aboutTheCoach: '',
    languages: [],
    tags: [],
    calendlyInfo: {},
    coachingSessions: coachingOpportunitySessions(),
};

const coachingOpportunityDetails = (state = coachingOpportunityInitialState, action) => {
    switch (action.type) {
        case COACHING_OPPORTUNITY_FETCH_SUCCESS:
        case COACHING_OPPORTUNITIES_EDIT_SUCCESS:
            return { ...action.payload, coachingSessions: state.coachingSessions };
        case COACHING_OPPORTUNITY_SESSIONS_FETCH_SUCCESS:
            return { ...state, coachingSessions: coachingOpportunitySessions(state.coachingSessions, action) };
        case COACHING_OPPORTUNITY_SESSION_CREATE_SUCCESS:
            return { ...state, coachingSessions: coachingOpportunitySessions(state.coachingSessions, action) }
        default:
            return state;
    }
};

const coachingOpportunitiesSessionsInitialState = [];

const coachingOpportunitiesSessionsList = (state = coachingOpportunitiesSessionsInitialState, action) => {
    switch (action.type) {
        case COACHING_OPPORTUNITY_SESSION_CREATE_SUCCESS:
            return [...state, { ...action.payload }];
        case COACHING_OPPORTUNITIES_SESSIONS_FETCH_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
};

const coachingOpportunities = combineReducers({
    coachingOpportunitiesList,
    coachingOpportunityDetails,
    coachingOpportunitiesSessionsList,
});

export const getCoachingOpportunities = (state) => state.coachingOpportunitiesList;
export const getCoachingOpportunity = (state) => state.coachingOpportunityDetails;
export const getCoachingOpportunityCalendlyUrl = (state) => state.coachingOpportunityDetails.calendlyUrl;
export const getCoachingOpportunitiesSessions = (state) => state.coachingOpportunitiesSessionsList;
export const getCoachingOpportunitiesUpcomingSessions = (state) => state.coachingOpportunitiesSessionsList.filter(x => {
    return x._id && new Date(x.calendlySessionInfo.startDateTime) >= new Date();
});
export const getCoachingOpportunitiesPastSessions = (state) => state.coachingOpportunitiesSessionsList
    .filter(x => {
        return x._id && new Date(x.calendlySessionInfo.startDateTime) < new Date();
    })
    .sort((a, b) => new Date(b.calendlySessionInfo.startDateTime) - new Date(a.calendlySessionInfo.startDateTime));

export const getCoachingOpportunitySessions = (state) => state.coachingOpportunityDetails.coachingSessions.filter(x => x._id);

export default coachingOpportunities;
