import staticDataService from 'services/staticDataService';
import { authorizedShowNotification } from './actionHelpers';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';

import {
    COUNTRIES_FETCH_ALL_SUCCESS,
    STATIC_LANGUAGES_FETCH_ALL_SUCCESS,
    STATIC_LANGUAGES_FETCH_SUGGESTIONS_SUCCESS,
} from 'actions/actionTypes';

const fetchAllCountriesSuccess = countries => ({
    type: COUNTRIES_FETCH_ALL_SUCCESS,
    payload: countries
});

export const fetchAllStaticLanguagesSuccess = languages => ({
    type: STATIC_LANGUAGES_FETCH_ALL_SUCCESS,
    payload: languages
});

export const fetchStaticLanguagesSuggestionsSuccess = languages => ({
    type: STATIC_LANGUAGES_FETCH_SUGGESTIONS_SUCCESS,
    payload: languages
});

export const fetchAllCountries = () => async dispatch => {
    try {
        const countriesData = await staticDataService.fetchCountriesData();
        const countries = countriesData.countries;
        dispatch(fetchAllCountriesSuccess(countries));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchStaticLanguagesSuggestions = inputText => async dispatch => {
    try {
        const languagesSuggestions = await staticDataService.fetchStaticLanguagesSuggestions(inputText);

        dispatch(fetchStaticLanguagesSuggestionsSuccess(languagesSuggestions));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

