import React from 'react';
import { useIntl } from 'react-intl';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { CANDIDATE_JOB_TYPE_OPTIONS } from 'components/CandidateProfileJourney/Steps/YourProfileWrapper/YourProfileConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './JobTypeDialog.scss';

const JobTypeDialog = ({
    handleSubmit,
    handleChange,
    values,
    onClose,
    open,
}) => {
    const intl = useIntl();

    const onFormSubmit = () => {
        handleSubmit();
    };

    return (
        <CommonFormikDialog
            className="job-type-dialog"
            open={open}
            onClose={onClose}
            dialogTitle={intl.formatMessage({ id: "edit-job-type" })}
            saveBtnText="save"
            discardBtnText="discard"
            onSave={onFormSubmit}
        >
            <form className="job-type-dialog-form" autoComplete="off">
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2">
                            <TextField
                                select
                                value={values.jobType}
                                variant="outlined"
                                onChange={handleChange}
                                name="jobType"
                                id="jobType"
                                fullWidth
                            >
                                {CANDIDATE_JOB_TYPE_OPTIONS.map((option) => {
                                    return (
                                        <MenuItem key={option.value} value={option.value}>
                                            {intl.formatMessage({ id: convertStringToLanguageKey(option.title) })}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                    </div>
                </div>
            </form>
        </CommonFormikDialog>
    );
};

export default JobTypeDialog;
