import contractService from 'services/contractsService';
import { logEvent } from 'utils/amplitude';
import { showNotification } from 'actions/notificationActions';
import { authorizedShowNotification } from 'actions/actionHelpers';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { CONTRACTS_MESSAGES } from 'constants/messageConstants';
import { HTTP_STATUS_CODES } from 'constants/httpStatusCodesConstants';
import { CONTRACT_SIGNATURE_STATUSES } from 'constants/contractsConstants';

import {
    CONTRACT_ADD_SUCCESS,
    CONTRACTS_FETCH_SUCCESS,
    CONTRACT_DELETE_SUCCESS,
    CONTRACT_UPDATE_SUCCESS,
    UPDATE_PENDING_CONTRACT_STATUS_SUCCESS,
    CONTRACTS_REPLACE_SUCCESS,
} from 'actions/actionTypes';

import { ROLES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

const createContractSuccess = contractData => ({
    type: CONTRACT_ADD_SUCCESS,
    payload: contractData
});

const updatePendingContractStatusSuccess = contractStatus => ({
    type: UPDATE_PENDING_CONTRACT_STATUS_SUCCESS,
    payload: contractStatus
}) 

const updateContract = contractData => ({
    type: CONTRACT_UPDATE_SUCCESS,
    payload: contractData
});

const deleteContractSuccess = contractId => ({
    type: CONTRACT_DELETE_SUCCESS,
    payload: contractId
});

const fetchContractsSuccess = contracts => ({
    type: CONTRACTS_FETCH_SUCCESS,
    payload: contracts
});

const contractsReplaceSuccess = (payload) => ({
    type: CONTRACTS_REPLACE_SUCCESS,
    payload,
});

export const createContract = (agencyId, userId, contractData, receiverRole, updateState=true, eSignatureRequired) => async dispatch => {
    try {
        const createdContract = await contractService.createContract(agencyId, userId, contractData);

        if (updateState) {
            dispatch(createContractSuccess(createdContract));
        }

        dispatch(showNotification(CONTRACTS_MESSAGES.ADD_CONTRACT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));

        if (eSignatureRequired) {
            const updatedContract = await contractService.signContract(agencyId, userId, createdContract.contract._id, contractData);
    
            if (receiverRole === ROLES.EMPLOYEE) {
                logEvent(AMPLITUDE_EVENT_TYPES.ADD_EMPLOYEE_CONTRACT);
            } else if (receiverRole === ROLES.CLIENT) {
                logEvent(AMPLITUDE_EVENT_TYPES.ADD_CLIENT_CONTRACT);
            } else {
                logEvent(AMPLITUDE_EVENT_TYPES.ADD_SUPPLIER_CONTRACT);
            }
    
            if (updateState) {
                dispatch(updateContract(updatedContract));
            }
            
            dispatch(showNotification(CONTRACTS_MESSAGES.UPDATE_ESIGNATURE_STATUS, NOTIFICATION_TYPES.SUCCESS));
        }
    } catch (error) {
        if(error.message === CONTRACT_SIGNATURE_STATUSES.ERROR) {
            dispatch(updatePendingContractStatusSuccess(CONTRACT_SIGNATURE_STATUSES.ERROR));
        } else {
            dispatch(updatePendingContractStatusSuccess(''));
        }
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteContract = (agencyId, contractId, departmentId) => async dispatch => {
    try {
        await contractService.deleteContract(agencyId, contractId, departmentId);

        dispatch(deleteContractSuccess(contractId));
        dispatch(showNotification(CONTRACTS_MESSAGES.DELETE_CONTRACT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CONTRACTS_MESSAGES.DELETE_CONTRACT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchContracts = (agencyId, clientId, query, replaceState) => async dispatch => {
    try {
        query = query ? query + `&userId=${clientId}` : `?userId=${clientId}`;

        const result = await contractService.fetchContracts(agencyId, query);

        if (replaceState) {
            dispatch(contractsReplaceSuccess(result.contracts));
        } else {
            dispatch(fetchContractsSuccess(result.contracts));
        }
        return result.totalCount;
    } catch (error) {
        if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(CONTRACTS_MESSAGES.FETCH_CONTRACTS_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
};

export const downloadContractFiles = (agencyId, userId, contractId, fileName) => async dispatch => {
    try {
        await contractService.downloadContractFiles(agencyId, userId, contractId, fileName);
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CONTRACTS_MESSAGES.FETCH_CONTRACT_FILES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchContractFilesUrl = (agencyId, userId, contractId) => async dispatch => {
    try {
        return await contractService.fetchContractFilesUrl(agencyId, userId, contractId);
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CONTRACTS_MESSAGES.FETCH_CONTRACT_FILES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const sendContractSignReminderEmail = (agencyId, employeeId, contractId) => async dispatch => {
    try {
        await contractService.sendContractSignReminderEmail(agencyId, employeeId, contractId);
        dispatch(showNotification(CONTRACTS_MESSAGES.REMIND_CONTRACT_FILE_SIGN_EMAIL_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        if (error.message === CONTRACTS_MESSAGES.CONTRACT_DOES_NOT_REQUIRE_SIGNATURE) {
            dispatch(authorizedShowNotification(error)(CONTRACTS_MESSAGES.CONTRACT_DOES_NOT_REQUIRE_SIGNATURE, NOTIFICATION_TYPES.WARNING))
        } else if (error.status === 406) {
            dispatch(authorizedShowNotification(error)(CONTRACTS_MESSAGES.REMIND_CONTRACT_FILE_SIGN_EMAIL_FAIL_406, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(CONTRACTS_MESSAGES.REMIND_CONTRACT_FILE_SIGN_EMAIL_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
}
