import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';

import { connect } from 'react-redux';
import { showNotification } from 'actions/notificationActions';

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

import PrimaryButton from '../Buttons/PrimaryButton';
import AttachedFilesList from './AttachedFilesList';

import { CONTRACTS_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { VARIANTS_CLASS_NAMES, VARIANT_NEW } from './UploadFilesConstants';

import { ReactComponent as UploadPhotosIcon } from 'assets/add-to-photos-icon.svg';

import './UploadFiles.scss';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UploadFiles = ({
    name,
    maxFilesCount,
    filesAttached,
    setFieldValue,
    showNotification,
    acceptedFormats,
    single,
    titleText,
    isFormReinitialized,
    variant,
}) => {
    const intl = useIntl();

    const [isDropedActive, setIsDropedActive] = useState(false);
    const [isWarningOpen, setIsWarningOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const handleCloseWarning = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsWarningOpen(false);
        setWarningMessage('');
    };

    const inputFilesRef = useRef();

    const onDrop = attachedFiles => {
        addFiles(attachedFiles);
        setIsDropedActive(false);
    };

    const addFiles = files => {
        const newFiles = [];

        if (!maxFilesCount || files.length + filesAttached.length <= maxFilesCount) {
            files.forEach(file => {
                if (file.size === 0) {

                    if (isFormReinitialized) {
                        setIsWarningOpen(true);
                        setWarningMessage(CONTRACTS_MESSAGES.ADD_EMPTY_FILE_FAIL);
                    } else {
                        showNotification(CONTRACTS_MESSAGES.ADD_EMPTY_FILE_FAIL, NOTIFICATION_TYPES.ERROR);
                    }

                } else if (!filesAttached.some(x => x.name === file.name)) {
                    const fileExt = file.name.split('.').pop();

                    if (!acceptedFormats.some(x => x === fileExt)) {

                        if (isFormReinitialized) {
                            setIsWarningOpen(true);
                            setWarningMessage(CONTRACTS_MESSAGES.ADD_FILE_FORMAT_FAIL);
                        } else {
                            showNotification(CONTRACTS_MESSAGES.ADD_FILE_FORMAT_FAIL, NOTIFICATION_TYPES.ERROR);
                        }

                    } else {
                        newFiles.push(file);
                    }
                }
            })

            setFieldValue(name, [...filesAttached, ...newFiles]);
        } else if (isFormReinitialized) {
            setIsWarningOpen(true);
            setWarningMessage(CONTRACTS_MESSAGES.MAX_FILES_COUNT_REACHED);
        } else {
            showNotification(CONTRACTS_MESSAGES.MAX_FILES_COUNT_REACHED, NOTIFICATION_TYPES.ERROR);
        }

        inputFilesRef.current.value = '';

        setIsDropedActive(false);
    };

    const onDragEnter = () => setIsDropedActive(true);

    const onDragLeave = () => setIsDropedActive(false);

    const { getRootProps } = useDropzone({ onDragEnter, onDragLeave, onDrop });

    const deleteAttachedFile = fileName => {
        setFieldValue(name, filesAttached.filter(x => x.name !== fileName));
    }

    const filesAttachedClassName = filesAttached.length > 0 && 'with-files-attached';

    return (
        <section className={`upload-files-container ${filesAttachedClassName}`}>
            {variant !== VARIANT_NEW &&
                <div className='attach-contract-label'>
                    <span>{titleText || intl.formatMessage({ id: "attach-file" })}</span>
                </div>
            }
            <div {...getRootProps({ className: 'dropzone' })} onClick={() => void 0}>
                <input
                    name={name}
                    ref={inputFilesRef}
                    className='files-upload'
                    onChange={e => {
                        addFiles(Array.from(e.target.files))
                    }}
                    type='file'
                    multiple={!single} />

                {
                    variant
                        ?
                        <div className={['inner-container', isDropedActive ? 'drop' : '', VARIANTS_CLASS_NAMES[variant]].join(' ')}>
                            <span>
                                <UploadPhotosIcon className="cloud-icon" />
                            </span>
                            <span>{intl.formatMessage({ id: "drag-and-drop" })}</span>
                            <span>your files or <span
                                className='browse-button'
                                type="file"
                                onClick={() => inputFilesRef.current.click()}
                            >
                                {intl.formatMessage({ id: "browse" })}
                            </span>
                            </span>
                        </div>
                        :
                        <div className={['inner-container', isDropedActive ? 'drop' : ''].join(' ')}>
                            <div className='upload-files-message'>
                                <h4>{intl.formatMessage({ id: "drag-and-drop" })} {intl.formatMessage({ id: "your-files-or" })}</h4>
                            </div>
                            <div className='browse-files-wrapper'>
                                <PrimaryButton text={intl.formatMessage({ id: "browse" })} handleClick={() => inputFilesRef.current.click()} />
                            </div>
                        </div>
                }
                {variant && <p className="accepted-formats">{acceptedFormats.join(', ')}</p>}
            </div>

            <AttachedFilesList
                files={Array.from(filesAttached)}
                deleteAttachedFile={deleteAttachedFile}
                variant={variant}
            />

            {isFormReinitialized &&
                <Snackbar
                    open={isWarningOpen}
                    autoHideDuration={4000}
                    onClose={handleCloseWarning}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}>
                    <Alert onClose={handleCloseWarning} severity="error" sx={{ width: '100%' }}>
                        {warningMessage}
                    </Alert>
                </Snackbar>
            }

        </section>
    );
};

const mapDispatchToProps = {
    showNotification,
};

export default connect(
    null,
    mapDispatchToProps
)(UploadFiles);
