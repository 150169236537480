import React from 'react';
import { Formik } from 'formik';

import CandidateCustomerSupportRequestFormView from './CandidateCustomerSupportRequestFormView';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import Typography from '@material-ui/core/Typography';

import { validateRequiredFields, validateInputLength } from 'utils/formValidations';

import { DESCRIPTION_MAX_LENGTH } from '../../../CustomerSupportConstants';

import './CandidateCustomerSupportRequestForm.scss';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper/SectionActionsWrapper';

const CandidateCustomerSupportRequestForm = ({
    requestCustomerSupport,
    accountManager
}) => {
    return (
        <Formik
            initialValues={{
                name: '',
                description: ''
            }}

            validate={values => {
                return {
                    ...validateRequiredFields(values, ['name', 'description']),
                    ...validateInputLength(values, [
                        { property: 'description', maxLength: DESCRIPTION_MAX_LENGTH },
                    ]),
                }
            }}

            onSubmit={(values, { resetForm, setSubmitting }) => {
                setSubmitting(false);
                values = accountManager ? { ...values, accountManager } : values;
                requestCustomerSupport(values);
                resetForm();
            }}
        >
            {(props) => (
                <div className="candidate-customer-support-request-container">
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.SectionTitle sectionTitle="Customer Support" />
                    </SectionActionsWrapper>
                    <div className="candidate-customer-support-form-content">
                        <div>
                            <Typography className="title" variant="h4">How can we help?</Typography>
                            <Typography className="helper-text" variant="h5">
                                If you're experiencing problems that you need help with, please complete the form below and our customer support team will get back to you within 48 hours.
                            </Typography>
                            <CandidateCustomerSupportRequestFormView {...props} />
                        </div>
                        <div className="action-buttons">
                            <PrimaryButton
                                className="submit-request-btn"
                                text="Send"
                                handleClick={props.handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default CandidateCustomerSupportRequestForm;
