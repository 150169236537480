import React from 'react';

import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import CodeSharpIcon from '@material-ui/icons/CodeSharp';
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import AccessTimeSharpIcon from '@material-ui/icons/AccessTimeSharp';
import EventSharpIcon from '@material-ui/icons/EventSharp';
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp';
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import { ReactComponent as UpcomingInterviews } from 'assets/upcoming-interviews.svg';

import { temporaryRoute } from 'constants/commonConstants';

export const getSupplierEmployeeMenuItems = agencyRouteName => [
    {
        TITLE: 'Dashboard',
        ICON: <AssessmentSharpIcon />,
        ROUTE: `/${agencyRouteName}/dashboard`,
        EXACT: true,
    },
    {
        TITLE: 'Time-tracking',
        ICON: <AccessTimeSharpIcon />,
        ROUTE: `/${agencyRouteName}/time-tracking`,
        EXACT: false,
    },
    {
        TITLE: 'Employment',
        ICON: <BusinessSharpIcon />,
        ROUTE: `/${agencyRouteName}/employment`,
        EXACT: false,
        ITEMS: [
            { TEXT: 'Personal', ICON: <PersonSharpIcon />, ENDPOINT: `/${agencyRouteName}/employment/personal-info` },
            { TEXT: 'Contracts', ICON: <InsertDriveFileSharpIcon />, ENDPOINT: temporaryRoute },
            { TEXT: 'Salary', ICON: <AccountBalanceWalletRoundedIcon />, ENDPOINT: temporaryRoute },
            { TEXT: 'CV', ICON: <DescriptionRoundedIcon />, ENDPOINT: `/${agencyRouteName}/employment/cv` },
            { TEXT: 'Perks & Equipment', ICON: <AccountBalanceWalletRoundedIcon />, ENDPOINT: temporaryRoute },
        ]
    },
    {
        TITLE: 'Holidays',
        ICON: <EventSharpIcon />,
        ROUTE: `/${agencyRouteName}/holidays`,
        EXACT: false,
    },
    {
        TITLE: 'Trainings',
        ICON: <CodeSharpIcon />,
        ROUTE: temporaryRoute,
        exact: false,
    },
    {
		TITLE: 'Upcoming Interviews',
		ICON: <UpcomingInterviews />,
		ROUTE: `/${agencyRouteName}/upcoming-interviews`,
		exact: false,
	},
    {
        TITLE: 'Settings',
        ICON: <SettingsSharpIcon />,
        ROUTE: temporaryRoute,
        exact: false,
    },
];
