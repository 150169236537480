import React, { useState } from 'react'
import { useIntl } from 'react-intl';

import CustomTooltip from '../ApplicationCustomTooltip';
import ApplicationInterviewActions from '../ApplicationInterviewActions';
import VideoPlayer from 'components/Shared/VideoPlayer';
import NothingToShow from 'components/NothingToShow';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import './ApplicationVideoIntroduction.scss';

const ApplicationVideoIntroduction = ({
    agencyId,
    applicationId,
    videoInterviewAnswers,
    agencyName,
    interviewInvitation,
    hideInterviewInviteButton
}) => {
    const intl = useIntl();

    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <Paper className="paper-element application-video-introduction-content-wrapper">
            <div className="video-introduction-wrapper">
                <Typography className="title">{intl.formatMessage({ id: "video-introduction" })}</Typography>
                {videoInterviewAnswers?.length > 0
                    ? <div className="video-interview-answers-wrapper">
                        {videoInterviewAnswers.map(video =>
                            <VideoPlayer
                                key={video?.question?._id}
                                videoUrl={video.temporarySignedUrl}
                            />)
                        }
                    </div>
                    : <div className="no-videos-available-wrapper">
                        <div className="no-videos-available-message">
                            <NothingToShow
                                message={
                                    <div className="no-videos-available-message">
                                        <p className="no-videos-available-text">{intl.formatMessage({ id: "no-video-to-show" })}</p>
                                        <InfoOutlinedIcon
                                            className="global-information-icon"
                                            onMouseEnter={() => setShowTooltip(true)}
                                            onMouseLeave={() => setShowTooltip(false)}
                                        />
                                        {showTooltip &&
                                            <CustomTooltip styles={{ width: '285px', top: '30px', left: '0px' }}>
                                                {intl.formatMessage({ id: "no-video-to-show-text" })}
                                            </CustomTooltip>
                                        }
                                    </div>
                                }
                            />
                        </div>
                    </div>
                }
            </div>

            <ApplicationInterviewActions
                agencyId={agencyId}
                applicationId={applicationId}
                agencyName={agencyName}
                interviewInvitation={interviewInvitation}
                hideInterviewInviteButton={hideInterviewInviteButton}
            />
        </Paper>
    );
};

export default ApplicationVideoIntroduction;
