import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { showNotification } from 'actions/notificationActions';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { AUTH_MESSAGES } from 'constants/messageConstants';
import { ENVIRONMENTS } from 'constants/env';

const isEnviroment = (Component, requiredPermission) => {
    const Enviroment = ({
        showNotification,
        ...props
    }) => {
        const history = useHistory();

        useEffect(() => {
            if (requiredPermission && (!requiredPermission.includes(process.env.REACT_APP_ENV) && !process.env.NODE_ENV === ENVIRONMENTS.DEVELOPMENT)) {
                history.push('/');

                showNotification(AUTH_MESSAGES.INSUFFICIENT_PERMISSIONS, NOTIFICATION_TYPES.ERROR);
            }
        });

        if (requiredPermission) {
            return (requiredPermission.includes(process.env.REACT_APP_ENV) || process.env.NODE_ENV === ENVIRONMENTS.DEVELOPMENT) ? <Component {...props} /> : null;
        } else {
            return <Component {...props} />;
        }
    }

    const mapDispatchToProps = {
        showNotification,
    };

    return connect(null, mapDispatchToProps)(Enviroment);
}

export default isEnviroment;
