import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { BASE_APP_TRADEMARK } from 'constants/env';

import './AuthCard.scss';

const AuthCard = ({
    className,
    title,
    children,
}) => {

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={`auth-card-wrapper${className ? ` ${className}` : ''}`}
        >
            <Paper
                elevation={0}
                className="auth-card-content rounded-l"
            >
                <div className="auth-card-header">
                    <div className="auth-card-media">
                        <BASE_APP_TRADEMARK.LOGO />
                    </div>
                    <Typography className="auth-card-title" variant="h2">{title}</Typography>
                </div>
                <div className="auth-card-form-wrapper">
                    {children}
                </div>
            </Paper>
        </Grid>
    );
};

export default AuthCard;
