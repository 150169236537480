import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAgencyRouteName, getJobOpportunityDetails, getAiMatchedCandidatesScoresFetchState } from 'reducers';

import { deleteJobApplication } from 'actions/jobApplicationActions';
import { sendProfileToClient } from 'actions/jobOpportunityActions';

import AppliedCandidate from './AppliedCandidate';
import AppliedCandidateOptionsPopover from './AppliedCandidateOptionsPopover';
import DeleteApplicationDialog from './DeleteApplicationDialog';
import AddCommentsDialog from 'components/Shared/SendProfilesDialog/AddCommentsDialog';

import { APPLIED_CANDIDATE_STATUSES } from '../AppliedCandidatesConstants';

import './AppliedCandidatesList.scss';

const AppliedCandidatesList = ({
    appliedConsultants,
    searchTermMatch,
    selectCandidateStatusTab,
    filters,
    agencyName,
    isAdmin,
    isSupplierAdmin,
    agencyId,
    deleteJobApplication,
    sendProfileToClient,
    agencyRouteName,
    job,
    aiMatchedCandidatesScoresFetchState,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isAddCommentsDialogOpen, setIsAddCommentsDialogOpen] = useState(false);
    const [selectedCandidate, setSelectedCandidate] = useState({});

    const history = useHistory();

    useEffect(() => {
        if (searchTermMatch && appliedConsultants.length <= 0) {
            selectCandidateStatusTab(APPLIED_CANDIDATE_STATUSES.ALL);
        }
    }, [searchTermMatch, filters.searchTerm]);


    const handleDotsClick = (event, candidate) => {
        event.stopPropagation();
        event.preventDefault();
        setSelectedCandidate(candidate);
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setSelectedCandidate({});
        setAnchorEl(null);
    };

    const handleOpenDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        handleClosePopover();
    };

    const handleDeleteApplication = async () => {
        await deleteJobApplication(agencyId, selectedCandidate.jobApplicationId, selectedCandidate.status);
    };

    const handleRateCandidateClick = (event, candidate) => {
        event.stopPropagation();
        event.preventDefault();
        history.push(`/${agencyRouteName}/candidates/${candidate._id}/profile-information`);
    };

    const handleSendProfileClick = (event, candidate) => {
        event.stopPropagation();
        event.preventDefault();
        setSelectedCandidate(candidate);
        setIsAddCommentsDialogOpen(true);
    };

    const handleCloseAddCommentsDialog = () => {
        setSelectedCandidate({});
        setIsAddCommentsDialogOpen(false);
    };


    const handleSendProfiles = async (profiles, notifyClient) => {
        //The dialog handleSubmit returns array, but in this case it will be only 1 profile
        const profile = {
            _id: profiles[0]._id,
            comment: profiles[0].comment,
            jobApplicationId: selectedCandidate.jobApplicationId
        };

        await sendProfileToClient(agencyId, job._id, profile, notifyClient);
        handleCloseAddCommentsDialog();
    };

    return (
        <div className="applied-candidates-list-container">
            {appliedConsultants.map((candidate, i) => (
                <AppliedCandidate
                    candidate={candidate}
                    key={(candidate._id + i)}
                    agencyName={agencyName}
                    isAdmin={isAdmin}
                    handleDotsClick={handleDotsClick}
                    handleRateCandidateClick={handleRateCandidateClick}
                    handleSendProfileClick={handleSendProfileClick}
                    showNotes={!isSupplierAdmin}
                    showStatusLabel={!isSupplierAdmin}
                    isSupplierAdmin={isSupplierAdmin}
                    aiMatchedCandidatesScoresFetchState={aiMatchedCandidatesScoresFetchState}
                />
            ))}

            {isAdmin &&
                <>
                    <AppliedCandidateOptionsPopover
                        anchorEl={anchorEl}
                        openDeleteDialog={handleOpenDeleteDialog}
                        handleClose={handleClosePopover}
                    />

                    <DeleteApplicationDialog
                        handleDeleteApplication={handleDeleteApplication}
                        handleCloseDialog={handleCloseDeleteDialog}
                        isDialogOpen={isDeleteDialogOpen}
                    />

                    {isAddCommentsDialogOpen &&
                        <AddCommentsDialog
                            isAddCommentsDialogOpen={isAddCommentsDialogOpen}
                            handleCloseDialog={handleCloseAddCommentsDialog}
                            initialSelectedEmployees={[selectedCandidate]}
                            handleSendProfiles={handleSendProfiles}
                            showSearchbar={false}
                            notifierName={job.client.name}
                        />
                    }
                </>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyRouteName: getAgencyRouteName(state),
    job: getJobOpportunityDetails(state),
    aiMatchedCandidatesScoresFetchState: getAiMatchedCandidatesScoresFetchState(state),
});

const mapDispatchToProps = {
    deleteJobApplication,
    sendProfileToClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppliedCandidatesList);
