import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import moment from 'moment';
import { createSelector, createStructuredSelector } from 'reselect';

import agencyHolidaysCount, * as fromAgencyHolidaysCount from './agencyHolidaysCountReducer';
import agencyDocuments, * as fromAgencyDocuments from './agencyDocumentsReducer';
import trainings, * as fromTrainings from './trainingsReducer';

import {
    EVROTRUST_AGENCY_INFO_UPDATE_SUCCESS,
    EVROTRUST_AGENCY_INFO_FETCH_SUCCESS,
    CALENDLY_AGENCY_INFO_FETCH_SUCCESS,
    CALENDLY_AGENCY_INFO_UPDATE_SUCCESS,
    BASIC_AGENCY_INFO_UPDATE_SUCCESS,
    AGENCY_INFO_FETCH_SUCCESS,
    AGENCY_LOGO_UPLOAD_SUCCESS,
    AGENCY_LOGO_DELETE_SUCCESS,
    AGENCY_TIMESHEETS_FETCH_SUCCESS,
    AGENCY_PENDING_HOLIDAYS_FETCH_SUCCESS,
    AGENCY_PENDING_HOLIDAYS_REPLACE_SUCCESS,
    AGENCY_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    AGENCY_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    AGENCY_HOLIDAY_ALLOWANCE_UPDATE_SUCCESS,
    AGENCY_EMPLOYEE_SETTINGS_FETCH_SUCCESS,
    AGENCY_PERFORMANCE_CRITERIAS_UPDATE_SUCCESS,
    AGENCY_PERFORMANCE_CRITERIAS_FETCH_SUCCESS,
    AGENCY_APPROVED_HOLIDAYS_FETCH_SUCCESS,
    AGENCY_REVIEWED_HOLIDAYS_FETCH_SUCCESS,
    AGENCY_REVIEWED_HOLIDAYS_REPLACE_SUCCESS,
    AGENCY_SICK_LEAVES_FETCH_SUCCESS,
    AGENCY_WORK_LEAVES_FETCH_SUCCESS,
    AGENCY_HOME_INFORMATION_FETCH_SUCCESS,
    HOME_INFORMATION_INITIAL_STATE_SET,
    AGENCY_ADMINS_FETCH_SUCCESS,
    AGENCY_IMPORTANT_DOCUMENTS_FETCH_SUCCESS,
    AGENCY_IMPORTANT_DOCUMENTS_UPDATE_SUCCESS,
    AGENCY_PROJECTS_FETCH_SUCCESS,
    AGENCY_TASK_EDIT_SUCCESS,
    AGENCY_TASK_DELETE_SUCCESS,
    ALL_TASKS_FETCH_SUCCESS,
    TASK_ADD_SUCCESS,
    AGENCY_INTERVIEW_INTRO_URL_FETCH_SUCCESS,
    DELETED_USERS_FETCH_SUCCESS,
    DELETED_USERS_REPLACE_SUCCESS,
    AGENCY_NAME_FETCH_SUCCESS,
    USER_PERMANENTLY_DELETE_SUCCESS,
    EVROTRUST_AGENCY_USAGE_UPDATE_SUCCESS,
    USERS_RESTORE_SUCCESS,
    EVROTRUST_AGENCY_INTEGRATION_FETCH_SUCCESS,
    UI_DEPENDENCIES_FETCH_SUCCESS,
    AGENCY_MAIN_ADMIN_FETCH_SUCCESS,
    EMPLYOEE_HOLIDAYS_DELETE_REQUESTS_SUCCESS,
    AGENCY_HOLIDAYS_REQUESTS_UPDATE_SUCCESS,
    EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    AGENCY_SETTINGS_FETCH_SUCCESS,
    AGENCY_SETTINGS_UPDATE_SUCCESS,
} from 'actions/actionTypes';

import { defaultOwnerValue, defaultTypeValue } from 'components/Tasks/TasksConstants';
import { HOLIDAY_APPROVAL_STATUSES } from 'constants/holidayConstants';

import { FORMAT_TIMES_MAP } from 'constants/timesFormat';
import { ISO_8601_DATE_FORMAT, TABLE_COLUMN_DIRECTIONS } from 'constants/commonConstants.js';
import { falseAgencyNamePlaceholders } from 'constants/agencyRouteConstants';
import { defaultCurrencyEUR } from 'constants/currenciesConstant';
import { SICK_LEAVE } from 'components/Person/TimeTracking/TimeTrackingConstants';
import { localToUtcDateAndTime } from 'utils/formatTimes';
import { PERSIST_AGENCY_INFORMATION_CONFIG } from 'utils/persistUtils';

const agencyInformationInitialState = {
    name: '',
    country: '',
    clients: [],
    recruiters: [],
    suppliers: [],
    performanceCriterias: [],
    signedDocumentsDetails: [],
    currency: defaultCurrencyEUR,
    hiddenSections: false,
    changedUiModules: [],
    logoImgUrl: '',
    logoImgName: '',
    logoImgDetails: {
        width: 0,
        height: 0,
        size: 0,
        sizeBits: 'B',
        extension: '',
    },
    evrotrustEmail: '',
    evrotrustVendorNumber: '',
    evrotrustApiKey: '',
    isUsingEvrotrust: false,
    isEvrotrustIntegrated: false,
    autoSignEnabled: false,
    evrotrustSecondaryEmails: [],
    calendlyInfo: {
        calendlyUrl: '',
        calendlyApiKey: '',
        name: '',
        duration: '',
        avatarUrl: '',
    },
    mainAdmin: {
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
    },
    revenueStatistics: {
        currentMonthMonthlySpending: 0,
        previousMonthMonthlySpending: 0,
        yearToDateSpending: 0,
    },
};

const agencyInformation = (state = agencyInformationInitialState, action) => {
    switch (action.type) {
        case AGENCY_INFO_FETCH_SUCCESS:
        case EVROTRUST_AGENCY_USAGE_UPDATE_SUCCESS:
        case EVROTRUST_AGENCY_INFO_UPDATE_SUCCESS:
        case EVROTRUST_AGENCY_INFO_FETCH_SUCCESS:
        case CALENDLY_AGENCY_INFO_FETCH_SUCCESS:
        case CALENDLY_AGENCY_INFO_UPDATE_SUCCESS:
        case EVROTRUST_AGENCY_INTEGRATION_FETCH_SUCCESS:
        case BASIC_AGENCY_INFO_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        case AGENCY_MAIN_ADMIN_FETCH_SUCCESS:
            return { ...state, mainAdmin: action.payload }
        //TODO: create home reducer
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return agencyInformationInitialState;
        //TODO: create home reducer
        case AGENCY_HOME_INFORMATION_FETCH_SUCCESS:
            return {
                ...state,
                name: action.payload.agencyName,
                logoImgUrl: action.payload.logoImgUrl,
            };
        case AGENCY_LOGO_UPLOAD_SUCCESS:
            return { ...state, ...action.payload };
        case AGENCY_LOGO_DELETE_SUCCESS:
            return {
                ...state, logoImgUrl: '', logoImgName: '', logoImgDetails: { width: 0, height: 0, size: 0, sizeBits: 'B', extension: '' }
            };
        case UI_DEPENDENCIES_FETCH_SUCCESS:
        case AGENCY_NAME_FETCH_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};


export const getAgencyRouteName = state => {
    //Get agency name from state or from window.location in case of redirection (email) or refresh
    let agencyName = state.agencyInformation.name.replaceAll(' ', '-').toLowerCase() || window.location.pathname.split('/')[1];
    //In cases where user registers, logs in etc, state will be empty and window.location provide one of the values in falseAgencyNamePlaceholders
    //which will result in an error related to non-existing route
    if (falseAgencyNamePlaceholders.includes(agencyName)) {
        agencyName = 'agency';
    };

    const specialSymbols = /[^a-zA-Z0-9-]/g;
    agencyName = agencyName.replaceAll(specialSymbols, '');

    return agencyName;
}


const agencyEmployeeSettingsInitialState = {
    holidayAllowance: '',
    performanceCriterias: [],
    importantDocuments: []
};

const agencyEmployeeSettings = (state = agencyEmployeeSettingsInitialState, action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return agencyEmployeeSettingsInitialState;
        case AGENCY_EMPLOYEE_SETTINGS_FETCH_SUCCESS:
            return { ...state, ...action.payload };
        case AGENCY_HOLIDAY_ALLOWANCE_UPDATE_SUCCESS:
            return { ...state, holidayAllowance: action.payload };
        case AGENCY_PERFORMANCE_CRITERIAS_UPDATE_SUCCESS:
            return { ...state, performanceCriterias: [...action.payload] };
        case AGENCY_PERFORMANCE_CRITERIAS_FETCH_SUCCESS:
            return { ...state, performanceCriterias: [...action.payload] };
        case AGENCY_IMPORTANT_DOCUMENTS_FETCH_SUCCESS:
        case AGENCY_IMPORTANT_DOCUMENTS_UPDATE_SUCCESS:
            return { ...state, importantDocuments: [...action.payload] };
        default:
            return state;
    }
};

const timeSheetsInitialState = [];

const timesheets = (state = timeSheetsInitialState, action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return timeSheetsInitialState;
        case AGENCY_TIMESHEETS_FETCH_SUCCESS:
            return action.payload || timeSheetsInitialState;
        default:
            return state;
    }
};

const projectInitialState = {
    agencyId: '',
    clientId: '',
    name: '',
    description: '',
    ongoing: false,
    startDate: '',
    endDate: '',
    currency: '',
    contactPerson: '',
    contactEmail: '',
    contactPhone: '',
    monthlySpending: '',
    employees: [],
    dedicatedEmployees: [],
    status: '',
    currentMonthlySpending: 0,
};

const projects = (state = [], action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return [];
        case AGENCY_PROJECTS_FETCH_SUCCESS:
            return [...action.payload.map(x => ({ ...projectInitialState, ...x }))]
        default:
            return state;
    }
};

const pendingHolidaysInitialState = [];

const pendingHolidays = (state = pendingHolidaysInitialState, action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return pendingHolidaysInitialState;
        case AGENCY_PENDING_HOLIDAYS_FETCH_SUCCESS:
            return [...state, ...action.payload];
        case AGENCY_PENDING_HOLIDAYS_REPLACE_SUCCESS:
            return action.payload || pendingHolidaysInitialState;
        case AGENCY_HOLIDAYS_REJECT_REQUEST_SUCCESS:
            return state.filter(x => x._id !== action.payload._id);
        case AGENCY_HOLIDAYS_APPROVE_REQUESTS_SUCCESS:
            return action.payload.reduce((state, approvedRequest) => {
                return state.filter(x => x._id !== approvedRequest._id);
            }, state.slice());

        default:
            return state;
    }
};

const approvedHolidaysInitialState = [];

const approvedHolidays = (state = approvedHolidaysInitialState, action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return approvedHolidaysInitialState;
        case AGENCY_APPROVED_HOLIDAYS_FETCH_SUCCESS:
            return action.payload || approvedHolidaysInitialState;
        case AGENCY_WORK_LEAVES_FETCH_SUCCESS:
            return action.payload?.holidays || approvedHolidaysInitialState;
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return approvedHolidaysInitialState;
        case EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS:
        case AGENCY_HOLIDAYS_APPROVE_REQUESTS_SUCCESS: {
            return [...state, ...action.payload];
        }
        case EMPLYOEE_HOLIDAYS_DELETE_REQUESTS_SUCCESS: {
            return state.filter(holiday => !action.payload.includes(holiday._id));
        }
        default:
            return state;
    }
};

const sickLeavesInitialState = [];

const sickLeaves = (state = sickLeavesInitialState, action) => {
    switch (action.type) {
        case AGENCY_WORK_LEAVES_FETCH_SUCCESS:
            return action.payload?.sickLeaves || sickLeavesInitialState;
        case AGENCY_SICK_LEAVES_FETCH_SUCCESS:
            return action.payload || sickLeavesInitialState;
        default:
            return state;
    }
};

const holidaysHistoryInitialState = [];

const holidaysHistory = (state = holidaysHistoryInitialState, action) => {
    switch (action.type) {
        case AGENCY_REVIEWED_HOLIDAYS_FETCH_SUCCESS:
            return [...state, ...action.payload];
        case AGENCY_REVIEWED_HOLIDAYS_REPLACE_SUCCESS:
            return action.payload || holidaysHistoryInitialState;
        case AGENCY_HOLIDAYS_REQUESTS_UPDATE_SUCCESS:
            return [...state, ...action.payload]
        case EMPLYOEE_HOLIDAYS_DELETE_REQUESTS_SUCCESS:
            return [...state.map(x => {
                if (action.payload.includes(x._id)) {
                    return { ...x, status: HOLIDAY_APPROVAL_STATUSES.DELETED }
                }
                return { ...x }
            })]
        default: return state;
    }
}

//Tasks

const taskInitialState = {
    _id: '',
    name: '',
    relatedTo: {},
    owner: {},
    description: '',
    reminderDateTime: null,
    reminder: false,
    status: 'incompleted',
};

const task = (state = taskInitialState, action) => {
    switch (action.type) {
        case TASK_ADD_SUCCESS:
            return { ...taskInitialState, ...action.payload }
        case AGENCY_TASK_EDIT_SUCCESS:
            return { ...state, ...action.payload }
        case ALL_TASKS_FETCH_SUCCESS:
            return { ...taskInitialState, ...state }
        default:
            return state;
    }
};

const tasksInitialState = [];

const tasks = (state = tasksInitialState, action) => {
    switch (action.type) {
        case TASK_ADD_SUCCESS:
            return [...state, task(state, action)];
        case ALL_TASKS_FETCH_SUCCESS:
            return action.payload.map(x => task(x, action));
        case AGENCY_TASK_EDIT_SUCCESS:
            const updatedTask = task(undefined, action);
            return state.map(x => x._id === updatedTask._id ? updatedTask : x);
        case AGENCY_TASK_DELETE_SUCCESS:
            return state.filter(x => x._id !== action.payload)
        default:
            return state;
    }
};

const adminsInitialState = [];

const admins = (state = adminsInitialState, action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return adminsInitialState;
        case AGENCY_ADMINS_FETCH_SUCCESS:
            return action.payload || adminsInitialState;
        default:
            return state;
    }
};

const interviewIntroUrlInitialState = '';

const interviewIntroUrl = (state = interviewIntroUrlInitialState, action) => {
    switch (action.type) {
        case AGENCY_INTERVIEW_INTRO_URL_FETCH_SUCCESS:
            return action.payload || interviewIntroUrlInitialState;
        default:
            return state;
    }
};

const deletedUsersInitialState = [];

const deletedUsers = (state = deletedUsersInitialState, action) => {
    switch (action.type) {
        case DELETED_USERS_FETCH_SUCCESS:
            return [...state, ...action.payload]
        case DELETED_USERS_REPLACE_SUCCESS:
            return action.payload;
        case USER_PERMANENTLY_DELETE_SUCCESS:
            return state.filter(x => x._id !== action.payload);
        case USERS_RESTORE_SUCCESS:
            return state.filter(x => !action.payload.includes(x._id));
        default:
            return state;
    }
};

const agencySettingsInitialState = {
    recruitmentEmail: '',
    financeEmail: '',
    hrEmail: '',
    evrotrustSecondaryEmails: [],
    holidaysSignatoryEmail: '',
    autoSignEnabled: false,
};

const settings = (state = agencySettingsInitialState, action) => {
    switch (action.type) {
        case AGENCY_SETTINGS_FETCH_SUCCESS:
            return { ...state, ...action.payload };
        case AGENCY_SETTINGS_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const agency = combineReducers({
    agencyInformation: persistReducer(PERSIST_AGENCY_INFORMATION_CONFIG, agencyInformation),
    agencyEmployeeSettings,
    timesheets,
    projects,
    pendingHolidays,
    approvedHolidays,
    sickLeaves,
    holidaysHistory,
    admins,
    tasks,
    pendingHolidaysCount: agencyHolidaysCount,
    trainings,
    interviewIntroUrl,
    deletedUsers,
    documents: agencyDocuments,
    settings,
});

export default agency;

const agencyNameSelector = (state) => state.agencyInformation.name;
const agencyCountrySelector = (state) => state.agencyInformation.country;
const agencyCurrencySelector = (state) => state.agencyInformation.currency;
const agencyLogoImgUrlSelector = (state) => state.agencyInformation.logoImgUrl;
const agencyLogoImgDetailsSelector = (state) => state.agencyInformation.logoImgDetails;
const agencyEvrotrustEmailSelector = (state) => state.agencyInformation.evrotrustEmail;
const agencyEvrotrustApiKeySelector = (state) => state.agencyInformation.evrotrustApiKey;
const agencyEvrotrustVendorNumberSelector = (state) => state.agencyInformation.evrotrustVendorNumber;
const agencyIsUsingEvrotrustSelector = (state) => state.agencyInformation.isUsingEvrotrust;
const agencyCalendlyInfoSelector = (state) => state.agencyInformation.calendlyInfo;
const agencyMainAdminSelector = (state) => state.agencyInformation.mainAdmin;
const agencyAutosignEnabledSelector = (state) => state.agencyInformation.autoSignEnabled;
const agencyEvrotrustSecondaryEmailsSelector = (state) => state.agencyInformation.evrotrustSecondaryEmails;

export const getAgencyBasicInfo = createStructuredSelector({
    name: agencyNameSelector,
    country: agencyCountrySelector,
    currency: agencyCurrencySelector,
    logoImgUrl: agencyLogoImgUrlSelector,
    logoImgDetails: agencyLogoImgDetailsSelector,
    evrotrustEmail: agencyEvrotrustEmailSelector,
    evrotrustApiKey: agencyEvrotrustApiKeySelector,
    evrotrustVendorNumber: agencyEvrotrustVendorNumberSelector,
    isUsingEvrotrust: agencyIsUsingEvrotrustSelector,
    autoSignEnabled: agencyAutosignEnabledSelector,
    evrotrustSecondaryEmails: agencyEvrotrustSecondaryEmailsSelector,
    calendlyInfo: agencyCalendlyInfoSelector,
    mainAdmin: agencyMainAdminSelector,
});

export const getHolidayAllowance = state => state.agencyEmployeeSettings.holidayAllowance;
export const getPerformanceCriterias = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.agencyEmployeeSettings.performanceCriterias.slice(from, to || state.agencyEmployeeSettings.performanceCriterias.length);
};
export const getImportantDocuments = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.agencyEmployeeSettings.importantDocuments.slice(from, to || state.agencyEmployeeSettings.importantDocuments.length);
};
export const getAgencyTimesheets = (state, page, count, sortBy, sortByDir) => {
    const from = page * count;
    const to = page * count + count;

    if (sortBy && sortByDir) {
        state.timesheets = state.timesheets.sort((a, b) => {
            const isAscending = sortByDir === TABLE_COLUMN_DIRECTIONS.ASCENDING;
            const fieldA = a[sortBy];
            const fieldB = b[sortBy];

            if (sortBy === 'projects') {
                return isAscending ? Number(fieldA) - Number(fieldB) : Number(fieldB) - Number(fieldA);
            }

            if (sortBy === 'daysWorked') {
                const daysFieldA = fieldA.split('/')[0];
                const daysFieldB = fieldB.split('/')[0];
                return isAscending
                    ? Number(daysFieldA) - Number(daysFieldB)
                    : Number(daysFieldB) - Number(daysFieldA);
            }

            return isAscending
                ? fieldA?.localeCompare(fieldB)
                : fieldB?.localeCompare(fieldA);
        })
    }

    return state.timesheets.slice(from, to || state.timesheets.length);
};

export const getAllProjects = (state) => state.projects;
export const getCompanyLogoUrl = state => state.agencyInformation.logoImgUrl;
export const getAgencyMainAdmin = state => state.agencyInformation.mainAdmin;

export const getAgencyPendingHolidays = (state, page = 0, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.pendingHolidays.slice(from, to || state.pendingHolidays.length);
};

export const getAgencyApprovedHolidays = state => state.approvedHolidays;

export const getAgencyHolidaysHistory = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.holidaysHistory.slice(from || 0, to || state.holidaysHistory.length);
}

export const getAgencySickLeaves = state => state.sickLeaves;

export const getBookedHolidaysCountForEmployee = (state, employeeId) => {
    const currentYear = moment.utc().format('YYYY');
    const paidHolidayArticle = '155';
    return state.holidaysHistory
        .filter(x => x.employeeId === employeeId)
        .filter(x => x.status === 'approved' && x.type === 'Paid' && (x.byArticle === paidHolidayArticle || !x.byArticle))
        .reduce((totalBookedCount, request) => {
            return Number(moment.utc(request.startDate).format('YYYY')) >= Number(currentYear)
                ? totalBookedCount + request.numberOfDays
                : totalBookedCount;
        }, 0);
};

export const getAgencyId = state => state.agencyInformation._id;
export const getAgencyName = state => state.agencyInformation.name;
export const getAgencyNameWithoutSpecialSymbols = state => {
    let agencyName = state.agencyInformation.name;
    const specialSymbols = /[^a-zA-Z-\s]/g;
    agencyName = agencyName.replace(specialSymbols, '');
    return agencyName;
}

export const isHiddenSections = state => state.agencyInformation.hiddenSections;

const adminsInputSelector = (state) => state.admins;
const pageInputSelector = (_, page) => page;
const countInputSelector = (_, __, count) => count;

export const getAdmins = createSelector(
    [adminsInputSelector, pageInputSelector, countInputSelector],
    (state, page, count) => {
        const from = page * count;
        const to = page * count + count - 1;
        const admins = state.map(x => ({
            _id: x._id,
            name: `${x.firstName} ${x.lastName}`,
            position: x.employmentInformation?.generalInfo?.position || '-',
            adminSince: moment.utc(x.adminSince).format(FORMAT_TIMES_MAP.DATE),
            isFirst: x.isFirst
        }));

        return admins.slice(from, to || admins.length);
    }
);


export const getAdminsCount = state => state.admins.length;

const getAdminsInputSelector = (state) => state.admins

export const getAdminItems = createSelector([getAdminsInputSelector], (admins) => {
    const items = admins.map((x, i) => ({ value: i + 2, name: `${x.firstName} ${x.lastName}` }));
    return [...items]
})

export const getAgencyPendingHolidaysCount = state => fromAgencyHolidaysCount.getAgencyPendingHolidaysCount(state.pendingHolidaysCount);

export const getAgencyCurrency = state => state.agencyInformation.currency;

export const getAgencyEvrotrustEmail = state => state.agencyInformation.evrotrustEmail;

export const getAgencyCalendlyInfo = state => state.agencyInformation.calendlyInfo;

export const getAgencyImportantDocuments = state => state.importantDocuments;

// Trainings
export const getAgencyTrainings = (state, ...args) => fromTrainings.getAgencyTrainings(state.trainings, ...args);
export const getIndividualTraining = (state, trainingId) => fromTrainings.getIndividualTraining(state.trainings, trainingId);
export const getCurrentTrainingFiles = (state, topicId, ...args) => fromTrainings.getCurrentTrainingFiles(state.trainings, topicId, ...args);

export const getTrainingTopicVideos = (state, topicId) => fromTrainings.getTrainingTopicVideos(state, topicId);

//Tasks
const tasksInputSelector = (state) => state.tasks;
const filtersInputSelector = (_, filters) => filters;
export const getAllTasks = createSelector([tasksInputSelector, filtersInputSelector], (state, filters) => {
    let tasks = [];
    filters
        ? tasks = state.
            filter(task => {
                const hasOwner = filters.owner.value === defaultOwnerValue[0].value || filters.owner.name === task.owner.name;
                const hasType = filters.type.value === defaultTypeValue[0].value || filters.type.status === task.status;

                return hasOwner && hasType;
            })
        : tasks = state;


    return tasks;
})

export const getInterviewIntroUrl = state => state.interviewIntroUrl;

export const getDeletedUsers = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.deletedUsers.slice(from, to || state.deletedUsers.length);
};

export const getIsAgencyEvrotrustIntegrated = state => state.agencyInformation.isEvrotrustIntegrated;

export const isClientProjectsModuleModified = state => state.agencyInformation.changedUiModules.includes('clientProjects');

export const getRevenueStatistics = state => state.agencyInformation.revenueStatistics;

const getBrowserLocation = (pathname, agencyName) => {
    const currentLocation = pathname;
    if (currentLocation !== `/${agencyName}/dashboard`) {
        return currentLocation
    }
    return '/holidays';
}

const approvedHolidaysFromManagerInputSelector = (state) => state.manager.approvedHolidays;
const sickLeavesFromManagerInputSelector = (state) => state.manager.sickLeaves;
const approvedHolidaysFromAgencyInputSelector = (state) => state.agency.approvedHolidays;
const sickLeavesFromAgencyInputSelector = (state) => state.agency.sickLeaves;
const agencyNameInputSelector = (state) => state.agency.agencyInformation.name
const pathnameInputSelector = (_, pathname) => pathname;

export const getHolidays = createSelector(
    [
        approvedHolidaysFromManagerInputSelector,
        sickLeavesFromManagerInputSelector,
        approvedHolidaysFromAgencyInputSelector,
        sickLeavesFromAgencyInputSelector,
        agencyNameInputSelector,
        pathnameInputSelector,
    ],
    (
        approvedHolidaysFromManager,
        sickLeavesFromManager,
        approvedHolidaysFromAgency,
        sickLeavesFromAgency,
        agencyName,
        pathname
    ) => {
        if (agencyName && pathname) {
            const specialSymbols = /[^a-zA-Z-]/g;
            const agencyRouteName = agencyName.replaceAll(' ', '-').toLowerCase().replaceAll(specialSymbols, '');
            const location = getBrowserLocation(pathname, agencyRouteName);
            let approvedHolidays = [];
            let sickLeaves = [];

            if (location === `/${agencyRouteName}/departments/holidays`) {
                approvedHolidays = approvedHolidaysFromManager || [];
                sickLeaves = sickLeavesFromManager || [];
            } else {
                approvedHolidays = approvedHolidaysFromAgency || [];
                sickLeaves = sickLeavesFromAgency || [];
            }

            return [...approvedHolidays, ...sickLeaves].length
                ? [...approvedHolidays, ...sickLeaves].map((holiday) => {
                    const start = moment(holiday.startDate).toDate();
                    const end = moment(holiday.endDate).toDate();

                    localToUtcDateAndTime(start, moment.utc(holiday.startDate).startOf('day'));
                    localToUtcDateAndTime(end, moment.utc(holiday.endDate).endOf('day'));

                    return {
                        start,
                        end,
                        allDay: true,
                        title: `${holiday.firstName} ${holiday.lastName}`,
                        avatarUrl: holiday.profilePictureImgUrl,
                        isActive: moment(holiday.startDate, ISO_8601_DATE_FORMAT).isSameOrBefore(moment().toDate()),
                        isSickLeave: holiday.type === SICK_LEAVE.name,
                        _id: holiday.employeeId,
                    };
                })
                : [];
        } else {
            return [];
        }
    }
);

export const getAgencyDocuments = (state, ...args) => fromAgencyDocuments.getAgencyDocuments(state.documents, ...args);
export const getAgencySettings = (state) => state.settings;
