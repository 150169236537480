import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import ShareLinkedinPopup from './ShareLinkedinPopup';

import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
import { Typography } from '@material-ui/core';
import { ReactComponent as CopyLinkIcon } from 'assets/copy-link-icon.svg';
import { ReactComponent as LinkedinIcon } from 'assets/linkedin-icon.svg';
import { ReactComponent as FacebookIcon } from 'assets/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from 'assets/twitter-icon.svg';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { TRACKING_URL_MESSAGES } from 'constants/messageConstants';

import './ShareOptionsPopup.scss';

const ShareOptionsPopup = ({
    anchorEl,
    handleClose,
    url,
    trackingUrl,
    showNotification,
}) => {
    const intl = useIntl();

    const [linkedinAnchorEl, setLinkedinAnchorEl] = useState(null);

    const handleCloseLinkedinPopover = () => {
        setLinkedinAnchorEl(null);
    };

    const handleLinkedinClick = (event) => {
        setLinkedinAnchorEl(event.currentTarget);
    };

    const handleAddToClipboard = (url) => {
        navigator.clipboard.writeText(url);
        showNotification(TRACKING_URL_MESSAGES.COPY_TRACKING_URL_SUCCESS, NOTIFICATION_TYPES.SUCCESS);
        handleCloseLinkedinPopover();
        handleClose();
    };

    return (
        <div className="share-popup-container">
            <Popover
                className="job-options-popover"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Typography className="title">
                    {intl.formatMessage({ id: "share-options" })}
                </Typography>
                <List className="popup-list">
                    <ListItem button className="popover-list-item" onClick={() => handleAddToClipboard(url)}>
                        <Typography className="list-item-text" />
                        <span className="share-popover-icon"><CopyLinkIcon /></span>
                        <span className="share-popover-text">{intl.formatMessage({ id: "copy-job-url" })}</span>
                        <Typography />
                    </ListItem>
                    <ListItem button className="popover-list-item" onClick={(event) => handleLinkedinClick(event)}>
                        <Typography className="list-item-text" />
                        <span className="share-popover-icon"><LinkedinIcon /></span>
                        <span className="share-popover-text">{intl.formatMessage({ id: "share-to-linkedin" })}</span>
                        <span className="arrow-icon"><ArrowIcon /></span>
                        <Typography />
                    </ListItem>

                    <FacebookShareButton url={url}>
                        <ListItem button className="popover-list-item">
                            <Typography className="list-item-text" />
                            <span className="share-popover-icon"><FacebookIcon /></span>
                            <span className="share-popover-text">{intl.formatMessage({ id: "share-to-facebook" })}</span>
                            <Typography />
                        </ListItem>
                    </FacebookShareButton>

                    <TwitterShareButton url={url}>
                        <ListItem button className="popover-list-item">
                            <Typography className="list-item-text" />
                            <span className="share-popover-icon"><TwitterIcon /></span>
                            <span className="share-popover-text">{intl.formatMessage({ id: "share-to-twitter" })}</span>
                            <Typography />
                        </ListItem>
                    </TwitterShareButton>
                </List>
            </Popover>

            <ShareLinkedinPopup
                anchorEl={linkedinAnchorEl}
                handleClose={handleCloseLinkedinPopover}
                url={url}
                trackingUrl={trackingUrl}
                handleAddToClipboard={handleAddToClipboard}
            />
        </div>
    )
}

export default ShareOptionsPopup;
