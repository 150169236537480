import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SectionActionsWrapper from '../SectionActionsWrapper';
import PrimaryButton from '../Buttons/PrimaryButton';

import './SectionBanner.scss';

const SectionBanner = ({
    sectionTitle,
    bannerText,
    bannerTextAdditionalInfo,
    buttonText,
    buttonProps,
}) => {
    return (
        <div className="section-banner-wrapper">
            {sectionTitle &&
                <SectionActionsWrapper>
                    <SectionActionsWrapper.LeftAlignedActions>
                        <SectionActionsWrapper.SectionTitle sectionTitle={sectionTitle} />
                    </SectionActionsWrapper.LeftAlignedActions>
                </SectionActionsWrapper>
            }

            <Paper className="section-banner-content">
                <div className="section-banner-text-wrapper">
                    <Typography variant="h1" className="section-banner-text">
                        {bannerText}
                    </Typography>
                    {bannerTextAdditionalInfo && bannerTextAdditionalInfo}
                </div>
                <div className="section-banner-action">
                    <PrimaryButton
                        {...buttonProps}
                        text={buttonText}
                    />
                </div>
            </Paper>
        </div>
    );
};

export default SectionBanner;
