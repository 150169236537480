import api from './api';
import requester from './requester';

const searchService = {
    fetchSearchUsersByNames: async (agencyId, searchTerm, roles) => await requester(api.searchUsersByNames(agencyId, searchTerm, roles)).getMany(),
    performSearch: async (agencyId, searchTerm, roles, detailedInformation, limit) => await requester(api.search(agencyId, roles, searchTerm, detailedInformation, limit)).getMany(),
    fetchSearchUsersResponses: async (agencyId, searchTerm) => await requester(api.searchUsersResponses(agencyId, searchTerm)).getMany(),
};

export default searchService;
