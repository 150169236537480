import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
	addTrainingTopicVideo,
	removeTrainingTopicVideo,
} from 'actions/agencyActions';
import { getTrainingTopicVideos, getAgencyId, isAdminMode } from 'reducers';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import AddVideoForm from './AddVideoForm';
import VideoCard from './VideoCard';
import NothingToShow from 'components/NothingToShow';

import { Paper } from '@material-ui/core';
import './VideosField.scss';

const VideosField = ({
	agencyId,
	isAdmin,
	addTrainingTopicVideo,
	removeTrainingTopicVideo,
	trainingTopicVideos,
	trainingId,
	topicId,
}) => {
	const [openAddDialog, setOpenAddDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [videoToRemove, setVideoToRemove] = useState({});

	const parseVideoURL = (url) => {
		const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
		const match = url.match(regExp);
		return match && match[7].length === 11 ? match[7] : false;
	};

	const handleOpenAddDialog = () => setOpenAddDialog(true);

	const handleCloseAddDialog = () => {
		setOpenAddDialog(false);
	};

	const handleVideoSubmit = (data) => {
		const video = parseVideoURL(data.video);
		addTrainingTopicVideo(agencyId, trainingId, topicId, { videoId: video });
	};

	const handleDeleteClick = (videoId) => {
		setOpenConfirmDialog(true);
		setVideoToRemove(videoId);
	};

	const handleRemoveVideo = (videoId) => {
		removeTrainingTopicVideo(agencyId, trainingId, topicId, videoId._id);
		handleCloseConfirmDialog();
	};

	const handleCloseConfirmDialog = () => {
		setOpenConfirmDialog(false);
		setVideoToRemove({});
	};

	return (
		<>
			<SectionActionsWrapper>
				<SectionActionsWrapper.SectionTitle
					sectionTitle='Videos'
				/>

				{isAdmin && (
					<SectionActionsWrapper.RightAlignedActions>
						<SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
							text='Add Video'
							onClick={handleOpenAddDialog}
						/>
					</SectionActionsWrapper.RightAlignedActions>
				)}
			</SectionActionsWrapper>

			<Paper className='video-content'>
				{trainingTopicVideos && trainingTopicVideos.length > 0 ? (
					trainingTopicVideos.map((video, i) => (
						<VideoCard
							key={i}
							item={video}
							isAdmin={isAdmin}
							removeItem={handleDeleteClick}
						/>
					))
				) : (
					<NothingToShow items='videos' tableView={true} />
				)}
			</Paper>

			<AddVideoForm
				openDialog={openAddDialog}
				handleCloseDialog={handleCloseAddDialog}
				handleVideoSubmit={handleVideoSubmit}
				title='YouTube Video URL'
			/>

			<ConfirmDeleteDialog
				itemToDelete={'this video'}
				handleDeleteItem={() => handleRemoveVideo(videoToRemove)}
				openDialog={openConfirmDialog}
				handleCloseDialog={handleCloseConfirmDialog}
			/>
		</>
	);
};
const mapStateToProps = (state, ownProps) => ({
	trainingTopicVideos: getTrainingTopicVideos(
		state.agency.trainings,
		ownProps.topicId,
	),
	agencyId: getAgencyId(state),
	isAdmin: isAdminMode(state),
});

const mapDispatchToProps = {
	addTrainingTopicVideo,
	removeTrainingTopicVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideosField);
