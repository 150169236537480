import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import { MONTH_YEAR_DATE_FORMAT } from 'constants/commonConstants';

const ReportsPageFilters = ({
    handleExportButtonClick,
    handleMonthChange,
    monthFilter,
}) => {
    const intl = useIntl();

    return (
        <SectionActionsWrapper>
            <SectionActionsWrapper.SectionTitle sectionTitle={intl.formatMessage({ id: "select-period" })} />
            <SectionActionsWrapper.LeftAlignedActions>
                <SectionActionsWrapper.LeftAlignedActions.DateFilter
                    openTo='month'
                    views={['year', 'month']}
                    format={MONTH_YEAR_DATE_FORMAT}
                    value={moment(monthFilter, MONTH_YEAR_DATE_FORMAT)}
                    minDate={new Date('1990-01-01')}
                    maxDate={new Date('2040-01-01')}
                    onChange={handleMonthChange}
                    variant='inline'
                    inputVariant='outlined'
                />
            </SectionActionsWrapper.LeftAlignedActions>
            <SectionActionsWrapper.RightAlignedActions classes={['team-documents', 'no-margin']}>
                <SectionActionsWrapper.RightAlignedActions.PrimaryExportButton
                    text={intl.formatMessage({ id: "export-button-text" })}
                    onClick={handleExportButtonClick}
                />
            </SectionActionsWrapper.RightAlignedActions>
        </SectionActionsWrapper>
    )
};

export default ReportsPageFilters;
