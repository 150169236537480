import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import MyBenefits from './MyBenefits';

import { getBenefitsDataFetchState, getDisplayMode } from 'reducers';
import { fetchBenefitTemplates, fetchBenefits } from 'actions/employeeActions'

import './Benefits.scss';

const Benefits = ({
    userId,
    agencyId,
    displayMode,
    fetchBenefits,
    fetchBenefitTemplates,
    dataFetchState,
}) => {

    useEffect(() => {
        fetchBenefits(agencyId, userId);
        fetchBenefitTemplates(agencyId, userId);
    }, [])

    return (
        <div className="benefits-wrapper">
            <MyBenefits
                dataFetchState={dataFetchState}
                userId={userId}
                agencyId={agencyId}
                displayMode={displayMode}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
    dataFetchState: getBenefitsDataFetchState(state),
});

const mapDispatchToProps = {
    fetchBenefits,
    fetchBenefitTemplates,
};

export default connect(mapStateToProps, mapDispatchToProps)(Benefits);
