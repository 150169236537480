import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Drawer from '@material-ui/core/Drawer';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ReactComponent as CheckmarkIcon } from 'assets/checkmark-icon.svg';

import { logout } from 'actions/authActions';

import { getInitialJourneyCompletedStepsIds, getCurrentJourneyStepId, getMenuInformation } from 'reducers';

import {
    getMainStepTitles,
    getCandidateCurrentMainStepTitle,
    getCandidateCompletedMainStepTitles,
} from '../../CandidateProfileJourney/CandidateProfileJourneyUtils';
import { BASE_APP_TRADEMARK } from 'constants/env';

import './CandidateJourneyNavigation.scss';

const CandidateJourneyNavigation = ({
    completedStepsIds,
    currentJourneyStep,
    menuInformation,
    logout,
}) => {
    const history = useHistory();

    const CANDIDATE_CREATE_PROFILE_JOURNEY_STEP_TITLES = getMainStepTitles();
    const CANDIDATE_CREATE_PROFILE_JOURNEY_CURRENT_STEP_TITLE = getCandidateCurrentMainStepTitle(currentJourneyStep);
    const CANDIDATE_CREATE_PROFILE_JOURNEY_COMPLETED_MAIN_STEP_TITLES = getCandidateCompletedMainStepTitles(completedStepsIds);

    const handleConfirmLogout = async () => {
        await logout();
        history.push('/login?logout=true');
    };

    return (
        isMobile
            ?
            <div className="candidate-initial-journey-navigation-mobile-wrapper">
                <div className="candidate-initial-journey-navigation-content">
                    <div className="candidate-initial-journey-navigation-header">
                        <div className="logo-wrapper">
                            <BASE_APP_TRADEMARK.LOGO className="logo" />
                        </div>
                    </div>
                </div>
            </div>
            :
            <Drawer
                id="candidate-initial-journey-navigation"
                className="candidate-initial-journey-navigation-desktop-wrapper"
                variant="permanent"
            >
                <div className="candidate-initial-journey-navigation-content">
                    <div className="candidate-initial-journey-navigation-header">
                        <div className="logo-wrapper">
                            <BASE_APP_TRADEMARK.LOGO className="logo" />
                        </div>
                    </div>

                    <Stepper
                        className="candidate-initial-journey-stepper"
                        orientation="vertical"
                        connector={<StepConnector className="step-connector" />}
                    >
                        {CANDIDATE_CREATE_PROFILE_JOURNEY_STEP_TITLES.map((stepTitle, index) => (
                            <Step key={index}>
                                <StepLabel
                                    className={CANDIDATE_CREATE_PROFILE_JOURNEY_CURRENT_STEP_TITLE === stepTitle ? "step-label selected" : "step-label"}
                                    StepIconComponent={() =>
                                        <div className="step-box">
                                            {
                                                CANDIDATE_CREATE_PROFILE_JOURNEY_COMPLETED_MAIN_STEP_TITLES.includes(stepTitle)
                                                    ? <CheckmarkIcon className="checkmark-icon" />
                                                    : <span className="step">{index + 1}</span>
                                            }
                                        </div>
                                    }
                                >
                                    {stepTitle}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <div className="candidate-initial-journey-navigation-footer">
                        <ListItem button className="list-item" onClick={handleConfirmLogout}>
                            <ListItemIcon className="list-icon exit-icon">
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" className="list-item-text" />
                        </ListItem>
                    </div>


                    {/* Commented until there is functionality to check if there is changed data
                in the forms through the journey
                <ConfirmLogoutDialog
                    open={isLogoutDialogOpen}
                    onCancel={handleOpenLogoutDialog}
                    onLeave={handleConfirmLogout}
                /> */}

                </div>
            </Drawer>
    );
};

const mapStateToProps = (state) => ({
    completedStepsIds: getInitialJourneyCompletedStepsIds(state),
    currentJourneyStep: getCurrentJourneyStepId(state),
    menuInformation: getMenuInformation(state),
});

const mapDispatchToProps = {
    logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateJourneyNavigation); 
