import React from 'react';
import { connect } from 'react-redux';

import MatchingConsultantsView from './MatchingConsultantsView'

const MatchingConsultantsRedux = ({
    totalProfilesCount,
    profiles,
    ...props
}) => {
    return (
        <MatchingConsultantsView
            profiles={profiles}
            totalProfilesCount={totalProfilesCount}
            {...props}
        />
    );
};

const mapStateToProps = (state, props) => {
    const {
        profiles,
        totalProfilesCount,
    } = props.dataSelector(state, props.filters);

    return {
        totalProfilesCount,
        profiles,
    }
};

export default connect(mapStateToProps, null)(MatchingConsultantsRedux);
