import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { Autocomplete } from "@material-ui/lab";

import DialogSaveButton from 'components/Shared/DialogButtons/DialogSaveButton';
import DialogDiscardButton from 'components/Shared/DialogButtons/DialogDiscardButton';

import { DIALOG_COMMENT_INPUT } from 'constants/commonConstants';

const AddEditBenefitDialogView = ({
    errors,
    touched,
    handleCloseDialog,
    handleSubmit,
    templates,
    values,
    setIsTemplate,
    handleChange,
    setFieldValue,
    handleSelectProjectTemplate,
    isEmployeeMode
}) => {
    const intl = useIntl();

    return (
        <form className="add-edit-benefit-form" autoComplete="off">
            <div className="add-benefit-top-block">
                <div className='benefit-name-field'>
                    <Autocomplete
                        id="benefitName"
                        className="name-select"
                        name="name"
                        freeSolo={true}
                        options={templates.map(t => t.name)}
                        getOptionLabel={option => option ? option : ''}
                        includeInputInList
                        value={values.name}
                        filterOptions={
                            options => options.filter(o => o.toLowerCase().includes(values.name?.toLowerCase()))
                        }
                        onChange={(e, value) => {
                            if (value !== null) {
                                handleSelectProjectTemplate(value);
                            } else {
                                setFieldValue("name", value);
                            }
                        }}
                        onInputChange={(e, value) => {
                            setFieldValue("name", value);
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                name="name"
                                label={intl.formatMessage({ id: "name-text" })}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        disabled={isEmployeeMode}
                    />
                    <ErrorMessage name="name" component="div" className="invalid-field-message" />
                </div>
                <div className='benefit-details-field'>
                    <TextField
                        name="details"
                        value={values.details}
                        onChange={handleChange}
                        id="add-benefit-details"
                        label={intl.formatMessage({ id: "details-text" })}
                        error={errors.details && touched.details}
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={DIALOG_COMMENT_INPUT.MAX_ROWS}
                        minRows={DIALOG_COMMENT_INPUT.ROWS}
                    />
                    <ErrorMessage name="details" component="div" className="invalid-field-message" />
                </div>
            </div>
            <DialogActions className="dialog-actions">
                <DialogDiscardButton onClick={handleCloseDialog}>{intl.formatMessage({ id: "discard-button-text" })}</DialogDiscardButton>
                {!isEmployeeMode &&
                    <DialogSaveButton
                        onClick={e => {
                            setIsTemplate(true)
                            handleSubmit(e)
                        }}
                        type="submit"
                    >{intl.formatMessage({ id: "save-and-create-template" })}</DialogSaveButton>
                }
                <DialogSaveButton onClick={handleSubmit} type="submit">{intl.formatMessage({ id: "save-button-text" })}</DialogSaveButton>
            </DialogActions>
        </form>
    );
}

export default AddEditBenefitDialogView;
