import { JOB_TYPES } from "../YourProfileConstants";

export const getSelectionCardSubTitle = candidateJobType => {
    switch (candidateJobType) {
        case JOB_TYPES.DEVELOPER:
            return 'How many years of professional experience do you have as a Software Engineer?';
        case JOB_TYPES.OTHER:
            return 'How many years of professional experience do you have in your field?'
        default:
            return `How many years of professional experience do you have as a ${candidateJobType}?`;
    }
};
