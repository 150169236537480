import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getCurrentTrainingFiles } from 'reducers';

import { downloadTrainingFile, deleteTrainingFile } from 'actions/agencyActions';

import TableWrapper from 'components/Shared/TableWrapper';

import { ReactComponent as AddIcon } from 'assets/add-button-icon.svg';

import {
    TABLE_COLUMNS,
    DEFAULT_TABLE_COLUMNS,
    SORT_QUERY_SELECTOR,
    PAGE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    TABLE_INITIAL_QUERY,
} from './TrainingFilesTableConstants';

import { parseQueryString } from 'utils/helpers';

const TrainingFilesTable = ({
    agencyId,
    topicId,
    trainingId,
    isAdmin,
    handleOpenUploadFiledDialog,
    deleteTrainingFile,
    downloadTrainingFile
}) => {
    const history = useHistory();

    const dataSelector = (state, page) => {
        const parsedQuery = parseQueryString(history.location.search, true);
        const parsedQuerySortBy = parsedQuery[SORT_QUERY_SELECTOR] ? parsedQuery[SORT_QUERY_SELECTOR][0] : TABLE_INITIAL_QUERY.sortBy;
        const sortBy = TABLE_COLUMNS.find(x => x.value === parsedQuerySortBy).value;
        const sortByDir = parsedQuery[`${SORT_QUERY_SELECTOR}Dir`] ? parsedQuery[`${SORT_QUERY_SELECTOR}Dir`][0] : TABLE_INITIAL_QUERY.sortByDir;

        return getCurrentTrainingFiles(state, topicId, page - 1, DEFAULT_ITEMS_PER_PAGE, sortBy, sortByDir).files;
    };
    const totalCount = useSelector((state) => getCurrentTrainingFiles(state, topicId).totalCount);

    const handleDownloadTrainingFile = (trainingFile) => {
        const lastDot = trainingFile.name.lastIndexOf('.');
        const withoutExtension = trainingFile.name.slice(0, lastDot);
        downloadTrainingFile(agencyId, trainingId, topicId, trainingFile.name, withoutExtension);
    }

    const handleDeleteTrainingFile = (trainingFile) => {
        deleteTrainingFile(agencyId, trainingId, topicId, trainingFile.name);
    }

    const adjustFilesKeys = files => files.map(trainingFile => {
        return {
            name: trainingFile.name,
            type: trainingFile.type,
            item: trainingFile,
        };
    });

    const actionDownload = { title: 'Download', handleClick: handleDownloadTrainingFile };
    const actionDelete = { title: 'Delete', handleClick: handleDeleteTrainingFile };

    const getActionsHandler = () => {
        const actions = [actionDownload];

        if (isAdmin) {
            actions.push(actionDelete);
        }

        return actions;
    };

    return (
        <>
            <TableWrapper
                title="Files To Download"
                columns={TABLE_COLUMNS}
                defaultColumns={DEFAULT_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                adjustKeysFunction={adjustFilesKeys}
                withPagination
                withActions
                addMargin
                getActionsHandler={getActionsHandler}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    {isAdmin &&
                        <TableWrapper.TableAlignedItems.RightAligned>
                            <TableWrapper.TableAlignedItems.TableActionButton
                                text={"Upload Files"}
                                icon={<AddIcon />}
                                handleClick={handleOpenUploadFiledDialog}
                            />
                        </TableWrapper.TableAlignedItems.RightAligned>
                    }
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>
        </>
    );
}

const mapDispatchToProps = {
    downloadTrainingFile,
    deleteTrainingFile
};

export default connect(null, mapDispatchToProps)(TrainingFilesTable);
