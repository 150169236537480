import React from "react";

import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const CalendarToolbar = ({
    label,
    onNavigate
}) => {
    const navigate = action => {
        onNavigate(action)
    }

    return(
        <div className="rbc-toolbar">
            <IconButton className="prev-arrow" onClick={() => navigate('PREV')}>
                <ArrowLeftIcon color="disabled"/>
            </IconButton>
            <span className="rbc-toolbar-label">{label}</span>
            <IconButton className="next-arrow" onClick={() => navigate('NEXT')}>
                <ArrowRightIcon color="disabled"/>
            </IconButton>
        </div>
    )
}

export default CalendarToolbar;
