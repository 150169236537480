import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { showNotification } from 'actions/notificationActions';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { ReactComponent as UploadedFileIcon } from 'assets/icon-uploaded-file.svg';
import { ReactComponent as UploadIcon } from 'assets/upload-cv-cloud-icon.svg';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { CONTRACTS_MESSAGES } from 'constants/messageConstants';

import './UploadImage.scss';

const ACCEPTED_FORMATS = ['.png', '.jpg', '.svg', '.jpeg'];

const UploadImage = ({
    value,
    setFieldValue,
    showNotification,
}) => {
    const inputRef = useRef(null);
    const [isDroppedActive, setIsDroppedActive] = useState(false);

    const addFiles = files => {
        const extension = files[0].name.split('.').pop();

        if (extension === files[0].name) {
            return;
        }


        if (!ACCEPTED_FORMATS.includes(`.${extension}`)) {
            showNotification(CONTRACTS_MESSAGES.ADD_FILE_FORMAT_FAIL, NOTIFICATION_TYPES.ERROR);
            inputRef.current.value = '';
            setIsDroppedActive(false);
            return;
        }

        const fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        fileReader.addEventListener('load', () => {
            const info = {};

            const imageReader = new Image();
            imageReader.src = fileReader.result;
            imageReader.addEventListener('load', function () {
                info.width = this.width;
                info.height = this.height;
                info.name = files[0].name;
                info.size = files[0].size.toString();
                info.sizeBits = 'B';
                info.extension = extension;

                setFieldValue('image', { info, file: files[0], imgUrl: fileReader.result });
            });
        }, false);

        inputRef.current.value = '';
        setIsDroppedActive(false);
    };

    const onDrop = attachedFiles => {
        addFiles(attachedFiles);
        setIsDroppedActive(false);
    };

    const onDragEnter = () => setIsDroppedActive(true);

    const onDragLeave = () => setIsDroppedActive(false);

    const { getRootProps } = useDropzone({ onDragEnter, onDragLeave, onDrop });

    const deleteAttachedFile = () => setFieldValue('image', {});

    return (
        <section className="upload-image-container">
            <h3>Upload Your Logo (Optional)</h3>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input
                    ref={inputRef}
                    className="image-upload"
                    name="imageFile"
                    type="file"
                    onChange={e => {
                        addFiles(Array.from(e.target.files))
                    }}
                />
                <div className={['inner-container', isDroppedActive ? 'drop' : '', value?.imgUrl ? 'image-selected' : ''].join(' ')}>
                    {value?.imgUrl
                        ? <div className="image-container" style={{ backgroundImage: `url(${value.imgUrl})` }} />
                        : <>
                            <UploadIcon className="upload-icon" />
                            <span>Drag & Drop</span>
                            <span>your files or <span
                                className="browse-button"
                                type="file"
                                onClick={() => inputRef.current.click()}
                            >
                                browse
                            </span>
                            </span>
                        </>
                    }
                </div>
                <p className="accepted-formats">{ACCEPTED_FORMATS.join(', ')}</p>
            </div>

            {value?.info &&
                <div className="attached-image-wrapper">
                    <div className="image-name-wrapper">
                        <UploadedFileIcon />
                        <span className="image-name">{value?.info.name}</span>
                    </div>
                    <IconButton className="delete-attached-file-btn" edge="end" aria-label="delete" onClick={() => deleteAttachedFile()}>
                        <ClearIcon />
                    </IconButton>
                </div>
            }
        </section>
    );
};

const mapDispatchToProps = {
    showNotification,
};

export default connect(null, mapDispatchToProps)(UploadImage);
