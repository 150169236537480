import projectTemplateService from 'services/projectTemplateService';
import { showNotification } from 'actions/notificationActions';
import { authorizedShowNotification } from './actionHelpers';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { EMPLOYEE_MESSAGES } from 'constants/messageConstants';

import {
    PROJECT_TEMPLATE_ADD_SUCCESS,
    PROJECT_TEMPLATE_EDIT_SUCCESS,
    PROJECT_TEMPLATES_FETCH_SUCCESS,
    PROJECT_TEMPLATE_GET_SUCCESS,
} from 'actions/actionTypes';

const addProjectTemplateSuccess = data => ({
    type: PROJECT_TEMPLATE_ADD_SUCCESS,
    payload: data,
});

const editProjectTemplateSuccess = data => ({
    type: PROJECT_TEMPLATE_EDIT_SUCCESS,
    payload: data,
});

const fetchProjectTemplatesSuccess = templatesData => ({
    type: PROJECT_TEMPLATES_FETCH_SUCCESS,
    payload: templatesData,
});

const getProjectTemplateSuccess = templateData => ({
    type: PROJECT_TEMPLATE_GET_SUCCESS,
    payload: templateData,
});

export const addProjectTemplate = (template, agencyId) => async dispatch => {
    try {
        const savedTemplate = await projectTemplateService.createProjectTemplate(template, agencyId);
        dispatch(addProjectTemplateSuccess(savedTemplate));
        dispatch(showNotification(EMPLOYEE_MESSAGES.ADD_CERTIFICATE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.ADD_CERTIFICATE_FAIL, NOTIFICATION_TYPES.ERROR));
    } 
};

export const editProjectTemplate = (template, agencyId) => async dispatch => {
    try {
        const updatedProjectTemplate = await projectTemplateService.updateProjectTemplate(template, agencyId);
        dispatch(editProjectTemplateSuccess(updatedProjectTemplate));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_CERTIFICATE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_CERTIFICATE_FAIL, NOTIFICATION_TYPES.ERROR));
    } 
};

export const fetchProjectTemplates = agencyId => async dispatch => {
    try {
        const templatesData = await projectTemplateService.fetchProjectTemplates(agencyId);
        dispatch(fetchProjectTemplatesSuccess(templatesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CERTIFICATE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const getTemplate = (templateId, agencyId) => async dispatch => {
    try {
        const templateData = await projectTemplateService.getProjectTemplate(templateId, agencyId);
        dispatch(getProjectTemplateSuccess(templateData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CERTIFICATE_FAIL, NOTIFICATION_TYPES.ERROR));
    } 
};
