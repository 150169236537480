import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import Paper from '@material-ui/core/Paper';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import CategorySkills from './CategorySkills';

import {
    updateProfileSkills,
    createProfileSkill,
    deleteProfileSkill,
    fetchSkillSuggestions,
} from 'actions/employeeActions';

import { getProfileSkills, isHiddenSections } from 'reducers';

import './ProfileSkills.scss';

const ProfileSkills = ({
    employeeId,
    agencyId,
    departmentId,
    profileSkills,
    updateProfileSkills,
    createProfileSkill,
    deleteProfileSkill,
    fetchSkillSuggestions,
}) => {
    const intl = useIntl();

    const handleRemoveSkillItem = item => {
        deleteProfileSkill(agencyId, employeeId, item.skillId, departmentId);
    };

    const handleAddSkillSubmit = (skillName, isNewSkill) => createProfileSkill(agencyId, employeeId, skillName, isNewSkill, profileSkills, departmentId);

    const handleSkillDrag = profileSkills => updateProfileSkills(agencyId, employeeId, profileSkills, departmentId);

    const fetchSkillSuggestionsHandler = inputText => fetchSkillSuggestions(agencyId, inputText);

    return (
        <div className="profile-info-wrapper profile-skills-info-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "skills-text" })}
                />
            </SectionActionsWrapper>

            <Paper className="profile-info-content profile-skills-rows-wrapper">
                <CategorySkills
                    handleSkillDrag={handleSkillDrag}
                    skills={profileSkills}
                    fetchSkillSuggestions={fetchSkillSuggestionsHandler}
                    removeSkill={handleRemoveSkillItem}
                    addSkill={handleAddSkillSubmit}>
                </CategorySkills>
            </Paper>
        </div>
    );
}

const mapStateToProps = state => ({
    profileSkills: getProfileSkills(state),
    hiddenSections: isHiddenSections(state)
});
const mapDispatchtoProps = {
    updateProfileSkills,
    createProfileSkill,
    fetchSkillSuggestions,
    deleteProfileSkill,
};

export default connect(mapStateToProps, mapDispatchtoProps)(ProfileSkills);
