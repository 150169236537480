import React from 'react';

import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader'
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent'

import { ReactComponent as TimerIcon } from "assets/timer-icon.svg";
import { ReactComponent as IosTimerIcon } from 'assets/ios-timer-icon.svg';
import { ReactComponent as GreenListBox } from 'assets/green-list-box.svg';

import { secondsToCounterString } from 'utils/formatTimes';

import { GUIDE_QUESTIONS } from './VideoRecordingViewConstants.js';

import './VideoRecordingView.scss';

const VideoRecordingView = ({
    videoRef,
    showCountDown,
    countDownSeconds,
    timerSeconds,
    recordedVideoObjectUrl,
    hasRecordingStarted,
}) => (
    <div className="video-recording-view-container">
        <PaperElement classes={["guide-questions-wrapper", "m"]}>
            <PaperHeader title="We prepared a few questions to help you guide your response:" />
            <PaperContent>
                <ul className="questions-list">
                    {GUIDE_QUESTIONS.map((question, index) => {
                        return (
                            <li className="question-item" key={index}>
                                <GreenListBox className="box-icon" />
                                <p>{question}</p>
                            </li>
                        )
                    })}
                </ul>
            </PaperContent>
        </PaperElement>
        <PaperElement classes={["m"]}>
            <PaperHeader title="Video Recording" classes={["flex-header"]}>
                <div className="timer-container">
                    <TimerIcon />
                    <p className="timer">{secondsToCounterString(timerSeconds)}</p>
                </div>
            </PaperHeader>
            <PaperContent>
                <div className="camera-container">
                    {showCountDown
                        ?
                        <div className="countdown-container">
                            <div className="countdown-items">
                                {hasRecordingStarted && <p className="hint">Video recording starts in</p>}
                                {hasRecordingStarted && <IosTimerIcon />}
                                {hasRecordingStarted && <p>{secondsToCounterString(countDownSeconds)}</p>}
                            </div>
                        </div>
                        : recordedVideoObjectUrl
                            ?
                            <video src={recordedVideoObjectUrl} controls>
                                Video not available
                            </video>
                            :
                            <video ref={videoRef} muted controls={false}>
                                Video stream not available.
                            </video>
                    }
                </div>
            </PaperContent>
            {hasRecordingStarted && <p className="hint">*After you finish answering the question, press the button to stop recording.</p>}
        </PaperElement>
    </div>
);

export default VideoRecordingView;
