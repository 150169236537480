import {
    TASKS_DATA_FETCH_SUCCESS,
    TASKS_DATA_FETCHING_SUCCESS,
    EMPLOYEES_FETCH_PENDING,
    EMPLOYEES_FETCH_SUCCESS,
} from 'actions/actionTypes';

export const tasksDataFetchSuccess = () => ({
    type: TASKS_DATA_FETCH_SUCCESS,
});

export const tasksDataFetchingSuccess = () => ({
    type: TASKS_DATA_FETCHING_SUCCESS,
});

export const fetchEmployeesPending = transactionId => ({
    type: EMPLOYEES_FETCH_PENDING,
    transactionId
});

export const fetchEmployeesSuccess = (employeesData, transactionId) => ({
    type: EMPLOYEES_FETCH_SUCCESS,
    payload: employeesData,
    transactionId
});
