import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getAgencyId, getAgencyName, getSearchResults, isAdmin } from 'reducers';

import { fetchSearchUsersResponses, fetchSearchResultsPending } from 'actions/searchActions';
import { sendProfilesToJobOpportunity, getAiMatchedCandidatesScores } from 'actions/jobOpportunityActions';

import SelectEmployeesDialog from './SelectEmployeesDialog';
import AddCommentsDialog from './AddCommentsDialog';

import { useDebounce } from 'utils/hooks';

const SendProfilesDialog = ({
    job,
    isSelectDialogOpen,
    isCommentsDialogOpen,
    handleCloseDialog,
    initialSelectedEmployees,
    isAdmin,
    agencyId,
    agencyName,
    searchResult,
    fetchSearchUsersResponses,
    fetchSearchResultsPending,
    sendProfilesToJobOpportunity,
    getAiMatchedCandidatesScores,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedEmployees, setSelectedEmployees] = useState(initialSelectedEmployees || []);
    const [isSelectEmployeesDialogOpen, setIsSelectEmployeesDialogOpen] = useState(isSelectDialogOpen || false);
    const [isAddCommentsDialogOpen, setIsAddCommentsDialogOpen] = useState(isCommentsDialogOpen || false);
    const [isLoading, setIsLoading] = useState(false);

    const debouncedInputValue = useDebounce(inputValue, 500);

    useEffect(() => {
        if (debouncedInputValue && debouncedInputValue.length >= 3) {
            fetchSearchUsersResponses(agencyId, debouncedInputValue).then(() => setIsLoading(false));
        }

        return () => {
            fetchSearchResultsPending();
        }
    }, [debouncedInputValue]);

    const handleChangeInputValue = value => {
        /* If it has loaded data and user starts to delete characters,
        we want to reset search results,
        so we dont show old data which doesn't correspond to the new input 
        */
        if (debouncedInputValue.length >= 3 && value.length < 3) {
            fetchSearchResultsPending();
        }

        setIsLoading(true);
        setInputValue(value);
    };

    const handleSendProfiles = async (profiles, notifyClient) => {
        await sendProfilesToJobOpportunity(agencyId, job._id, profiles, notifyClient);
        handleCloseDialog();
        await getAiMatchedCandidatesScores(agencyId, job._id, profiles.map(x => x._id));
    };

    return (
        <div className="send-profiles-dialog">
            <SelectEmployeesDialog
                setSelectedEmployees={setSelectedEmployees}
                isSelectEmployeesDialogOpen={isSelectEmployeesDialogOpen}
                setIsSelectEmployeesDialogOpen={setIsSelectEmployeesDialogOpen}
                setIsAddCommentsDialogOpen={setIsAddCommentsDialogOpen}
                handleCloseDialog={handleCloseDialog}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleChangeInputValue={handleChangeInputValue}
                searchResult={searchResult}
                fetchSearchResultsPending={fetchSearchResultsPending}
                isLoading={isLoading}
            />
            {isAddCommentsDialogOpen &&
                <AddCommentsDialog
                    isAddCommentsDialogOpen={isAddCommentsDialogOpen}
                    handleCloseDialog={handleCloseDialog}
                    initialSelectedEmployees={selectedEmployees}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    handleChangeInputValue={handleChangeInputValue}
                    searchResult={searchResult}
                    fetchSearchResultsPending={fetchSearchResultsPending}
                    isLoading={isLoading}
                    handleSendProfiles={handleSendProfiles}
                    notifierName={!isAdmin ? agencyName : job.client.name}
                />
            }
        </div>
    );
};

const mapStateToProps = state => ({
    isAdmin: isAdmin(state),
    agencyId: getAgencyId(state),
    agencyName: getAgencyName(state),
    searchResult: getSearchResults(state),
});

const mapDispatchToProps = {
    fetchSearchUsersResponses,
    fetchSearchResultsPending,
    sendProfilesToJobOpportunity,
    getAiMatchedCandidatesScores,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendProfilesDialog);
