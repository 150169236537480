import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { getBusinessTripsListDataFetchState } from 'reducers';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import TableWrapper from 'components/Shared/TableWrapper';

import { ReactComponent as ExportIcon } from 'assets/export-button-icon.svg';
import { ReactComponent as SingleExportIcon } from 'assets/icon-export.svg';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { PAGE_QUERY_SELECTOR, SORT_QUERY_SELECTOR, TABLE_INITIAL_QUERY, TABLE_QUERY_MAP, defaultTableColumns, tableColumns } from '../ExpenseReportsConstants';
import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

import { usePreloadTable } from 'utils/hooks';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const ExpenseReportsTable = ({
    agencyId,
    downloadBusinessTripFiles,
    exportBusinessTrips,
    dataSelector,
    expenseReports,
    action,
}) => {
    const intl = useIntl();
    const [isTableLoading, totalCount] = usePreloadTable(TABLE_INITIAL_QUERY, TABLE_QUERY_MAP, null, action);
    
    const handleDownloadBusinessTripFiles = expenseReport => {
        const startDate = moment(expenseReport.startDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR);
        const endDate = moment(expenseReport.endDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR);
        const employeeName = `${expenseReport?.firstName} ${expenseReport?.lastName}`.trim();
        const country = expenseReport.country;

        const fileName = `${startDate}_${endDate}_${employeeName}_${country}`;
        downloadBusinessTripFiles(agencyId, expenseReport._id, fileName);
    };

    const handleExportBusinessTrips = () => {
        const businessTripData = expenseReports.map(x => x._id);

        const fileName = `Expense_Reports_as_of_${moment().format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`;
        exportBusinessTrips(agencyId, businessTripData, fileName);
    };

    //Adjust keys
    const adjustKeysFunction = expenseReports => expenseReports.map(expenseReport => {
        return {
            _id: expenseReport._id,
            name: `${expenseReport.firstName} ${expenseReport.lastName}`,
            startDate: moment(expenseReport.startDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            endDate: moment(expenseReport.endDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            country: intl.formatMessage({ id: convertStringToLanguageKey(expenseReport.country) }),
            actions: (
                <IconButton
                    className="export-icon-button"
                    onClick={() => handleDownloadBusinessTripFiles(expenseReport)}
                >
                    <Icon>
                        <SingleExportIcon />
                    </Icon>
                </IconButton>
            ),
            item: expenseReport,
        }
    });

    const getPopupActions = () => ([
        { title: intl.formatMessage({ id: "download" }), handleClick: handleDownloadBusinessTripFiles },
    ]);

    return (
        <div className="expense-reports-table-container">
            <TableWrapper
                title={intl.formatMessage({ id: "menu-team-expense-reports" })}
                columns={tableColumns}
                defaultColumns={defaultTableColumns}
                dataSelector={dataSelector}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustKeysFunction}
                isTableDataLoading={isTableLoading}
                withPagination
                pagesCount={Math.ceil(totalCount/DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
                withActions
                addMargin
                getActionsHandler={getPopupActions}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton text={intl.formatMessage({ id: "export-button-text" })} icon={<ExportIcon />} handleClick={handleExportBusinessTrips} />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const expenseReports = props.dataSelector(
        state,
        props.fromPage,
        props.rowsPerPage,
        props.dataComparer
    );
    const dataFetchState = getBusinessTripsListDataFetchState(state);

    return {
        expenseReports,
        dataFetchState,
    }
};

export default connect(mapStateToProps)(ExpenseReportsTable);
