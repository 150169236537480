import React from "react";
import { getIsRawCvPdfGenerated } from "reducers";
import { connect } from "react-redux";
import './styles.scss'

const CvOptionHeading = ({ getIsRawCvPdfGenerated, primaryText, secondaryText }) => {
    return (
        <>
          {getIsRawCvPdfGenerated ? (
            <h1 className="option-text">{secondaryText}</h1>
          ) : (
            <h1 className="option-text">{primaryText}</h1>
          )}
        </>
      );
}

const mapStateToProps = (state) => ({
    getIsRawCvPdfGenerated: getIsRawCvPdfGenerated(state),
  });

export default connect(mapStateToProps)(CvOptionHeading)
