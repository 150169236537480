import React from 'react';
import { useIntl } from 'react-intl';

const ActiveHolidaysLegend = () => {
    const intl = useIntl();

    return (
        <div className='active-holidays-legend'>
            <div className='upcoming-holiday'></div>
            <span>{intl.formatMessage({ id: "upcoming" })}</span>
            <div className='active-holiday'></div>
            <span>{intl.formatMessage({ id: "active" })}</span>
            <div className='sick-leave'></div>
            <span>{intl.formatMessage({ id: "sick-leave" })}</span>
        </div>
    )
}

export default ActiveHolidaysLegend;
