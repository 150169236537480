import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { Calendar as BigCalendar, momentLocalizer, Views } from "react-big-calendar";

import moment from "moment";
import 'moment/locale/bg';

import CalendarToolbar from './CalendarToolbar';
import CalendarEvent from './CalendarEvent';
import { BENCH_ACTIVITIES, DATE_FORMAT, HOLIDAY_TYPE, MONTH_FORMAT, SICK_LEAVE } from '../TimeTrackingConstants';

import "./Calendar.scss";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const Calendar = ({
    projects,
    pendingHolidays,
    approvedHolidays,
    selectedMonth,
    selectedEvents,
    setSelectedEvents,
    handleNavigate,
    handleEditTimeClick,
    publicHolidays,
    date,
    defaultDate,
    startDate,
}) => {
    const intl = useIntl();
    moment.locale(intl.locale);
    const localizer = momentLocalizer(moment);

    const isWeekend = date => moment(date).day() === 0 || moment(date).day() === 6;
    const isPaidHoliday = date => approvedHolidays.some(x => moment(date).format(DATE_FORMAT) === x.date && x.type === HOLIDAY_TYPE.PAID);
    const isUnpaidHoliday = date => approvedHolidays.some(x => moment(date).format(DATE_FORMAT) === x.date && x.type === HOLIDAY_TYPE.UNPAID);
    const isPublicHoliday = date => publicHolidays.includes(moment(date).format(DATE_FORMAT));
    const isBeforeStartDate = date => startDate ? moment(date).isBefore(moment(startDate), 'date') : false;

    const handleSelect = ({ start, end, slots }) => {
        slots.forEach(slot => {
            const date = moment(slot).format(DATE_FORMAT);
            if (selectedEvents.some(x => x.date === date)) {
                setSelectedEvents(state => state.filter(x => x.date !== date));
            } else if (moment(slot).format(MONTH_FORMAT) === selectedMonth) {
                setSelectedEvents(state => [...state, { date, project: {}, hours: '', tasksDesc: '', selected: true }]);
            }
        })
    };

    const customDayPropGetter = (date) => {
        const classes = [];

        if (selectedEvents.some(x => x?.date === moment(date).format(DATE_FORMAT))) {
            classes.push('selected');
        }

        if (projects.some(x => moment.utc(x?.start).isSame(moment.utc(date), 'day'))) {
            classes.push('tracked');
        }

        if (isWeekend(date) || isPublicHoliday(date) || isPaidHoliday(date) || isUnpaidHoliday(date) || isBeforeStartDate(date)) {
            classes.push('weekend');
        }

        return { className: classes.join(' ') };
    };

    const getCalendarName = (value) => {
        if (isWeekend(value) && isPublicHoliday(value) || isWeekend(value)) {
            return intl.formatMessage({ id: 'weekend' });
        } else if (isPaidHoliday(value) && isPublicHoliday(value) || isPublicHoliday(value)) {
            return intl.formatMessage({ id: 'public-holiday' });
        } else if (isPaidHoliday(value)) {
            return intl.formatMessage({ id: 'paid-vacation' });
        } else if (isUnpaidHoliday(value)) {
            return intl.formatMessage({ id: 'unpaid-vacation' });
        } else if (isBeforeStartDate(value)) {
            return '';
        }
    }
    const CustomDateCellWrapper = ({ children, value }) => {
        return <div className={children.props.className}>
            {<span>{getCalendarName(value)}</span>}
        </div>
    };

    return (
        <Fragment>
            <div className="calendar-wrapper">
                <BigCalendar
                    date={date}
                    defaultDate={defaultDate}
                    selectable
                    localizer={localizer}
                    events={projects.map(x => {
                        if (x.project?.name === SICK_LEAVE.name) {
                            return { ...x, project: { ...x.project, name: intl.formatMessage({ id: "sick-leave" }) } }
                        } else if (x.project?.name === BENCH_ACTIVITIES.name) {
                            return { ...x, project: { ...x.project, name: intl.formatMessage({ id: "bench-activities" }) } }
                        }

                        return x;
                    }).concat(pendingHolidays)}
                    views={['month']}
                    defaultView={Views.MONTH}
                    popup={true}
                    onSelectEvent={handleEditTimeClick.bind(null, selectedMonth)}
                    onSelectSlot={handleSelect}
                    onNavigate={handleNavigate}
                    dayPropGetter={customDayPropGetter}
                    components={{
                        toolbar: CalendarToolbar,
                        event: CalendarEvent,
                        dateCellWrapper: CustomDateCellWrapper
                    }}
                />
            </div>
        </Fragment>
    );
};


export default Calendar;
