import { HISTORY_DIALOG_CONSTANTS } from "../../ProjectDetailsConstants";

export const getEmployeeHistoryDialogTitle = (rateDialogType) => {
    switch (rateDialogType) {
        case RATE_DIALOG_OPTIONS.RATE:
            return HISTORY_DIALOG_CONSTANTS.RATE.TITLE;
        case RATE_DIALOG_OPTIONS.SERVICE_FEE:
            return HISTORY_DIALOG_CONSTANTS.SERVICE_FEE.TITLE;
        case RATE_DIALOG_OPTIONS.TCE:
            return HISTORY_DIALOG_CONSTANTS.TCE.TITLE;
        default:
            return "";
    }
};

export const getEmployeeHistoryDialogSubTitle = (rateDialogType) => {
    switch (rateDialogType) {
        case RATE_DIALOG_OPTIONS.RATE:
            return HISTORY_DIALOG_CONSTANTS.RATE.SUBTITLE;
        case RATE_DIALOG_OPTIONS.SERVICE_FEE:
            return HISTORY_DIALOG_CONSTANTS.SERVICE_FEE.SUBTITLE;
        case RATE_DIALOG_OPTIONS.TCE:
            return HISTORY_DIALOG_CONSTANTS.TCE.SUBTITLE;
        default:
            return "";
    }
};

export const getEmployeeHistoryDialogField = (rateDialogType) => {
    switch (rateDialogType) {
        case RATE_DIALOG_OPTIONS.RATE:
            return "";
        case RATE_DIALOG_OPTIONS.SERVICE_FEE:
            return HISTORY_DIALOG_CONSTANTS.SERVICE_FEE.FIELD;
        case RATE_DIALOG_OPTIONS.TCE:
            return HISTORY_DIALOG_CONSTANTS.TCE.FIELD;
        default:
            return "";
    }
};

export const RATE_DIALOG_OPTIONS = {
    TCE: 'tce',
    RATE: 'rate',
    SERVICE_FEE: 'service-fee'
};
