export const clientSubmenuItems = {
    overview: { text: 'Overview', path: '', langKey: 'submenu-overview' },
    companyInformation: { text: 'Company Information', path: '/company-information', langKey: 'submenu-company-information' },
    projects: { text: 'Projects', path: '/projects', langKey: 'submenu-projects' },
    jobOpportunities: { text: 'Job Opportunities', path: '/jobs', langKey: 'submenu-job-opportunities' },
    billing: { text: 'Billing', path: '/billing', langKey: 'submenu-billing' },
    contracts: { text: 'Contracts', path: '/contracts', langKey: 'submenu-contracts' },
    tasksReminders: { text: 'Tasks/Reminders', path: '/tasks-reminders', langKey: 'submenu-tasks-and-reminders' },
};


export const HIDDEN_MENU_ITEMS = []; 

export const clientStatuses = ['Prospect', 'Active (Invoicing)', 'Inactive (Not Invoicing)'];
