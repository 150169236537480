import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getStaticLanguages } from 'reducers';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';

import { languageLevels } from './ProfileLanguageRowConstants';

import './ProfileLanguageRow.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const ProfileLanguageRow = ({
    language,
    handleChange,
    removeLanguage,
    staticLanguages,
}) => {
    const intl = useIntl();

    return (
        <div className="language-row">
            <FormControl className="language-select" variant="outlined" fullWidth>
                <InputLabel id="language-name-label">{intl.formatMessage({ id: "language" })}</InputLabel>
                <Select
                    labelId="language-name-label"
                    id="language-name"
                    name="name"
                    value={language.name}
                    onChange={(e) => handleChange(e, language)}
                    label={intl.formatMessage({ id: "language" })}
                >
                    {staticLanguages.map((lang, i) => (
                        <MenuItem key={i} value={lang}>
                            {intl.formatMessage({ id: convertStringToLanguageKey(lang) })}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="language-level-container language-name">
                <FormControl className="language-select" variant="outlined" fullWidth >
                    <InputLabel id="language-level-label">{intl.formatMessage({ id: "level" })}</InputLabel>
                    <Select
                        labelId="language-level-label"
                        name="level"
                        value={language.level}
                        onChange={e => handleChange(e, language)}
                        id="language-level"
                        label={intl.formatMessage({ id: "level" })}
                    >
                        {languageLevels.map((lang, i) => (
                            <MenuItem key={i} value={lang}>
                                {intl.formatMessage({ id: convertStringToLanguageKey(lang) })}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <CancelSharpIcon className="language-delete-icon" onClick={() => removeLanguage(language)} />
            </div>
        </div>
    )
}

const MapStateToProps = state => ({
    staticLanguages: getStaticLanguages(state),
});

export default connect(MapStateToProps)(ProfileLanguageRow);
