import React from 'react';
import { useIntl } from 'react-intl';

import './MissingInformation.scss';

const MissingInformation = () => {
    const intl = useIntl();

    return (
        <div className="missing-information-wrapper">
            <p>{intl.formatMessage({ id: "missing-information" })}</p>
        </div>
    )
};

export default MissingInformation;
