import styleConstants from 'style-constants.scss';

export const TABLE_COLUMNS = [
  { name: "Name", value: "name", isSortable: true, langKey: "name-text" },
  { name: "Skills", value: "technologies", isSortable: true, langKey: "skills-text" },
  { name: "Experience", value: "experience", isSortable: true, langKey: "experience-text" },
  { name: "Status", value: "status", isSortable: true, langKey: "status-text" },
];

export const DEFAULT_COLUMNS = ["name", "technologies", "experience", "status"];

export const SORT_QUERY_SELECTOR = "sort";
export const SEARCH_QUERY_SELECTOR = "search";
export const CATEGORY_QUERY_SELECTOR = "tab";

export const PROFILES_CATEGORY_FILTER = [
  { name: "Employees", value: "employee", langKey: "employees" },
  { name: "Candidates", value: "candidate", langKey: "candidates" },
  { name: "Partners", value: "supplier-employee", langKey: "supplier-employees" },
];

export const PROFILES_CATEGORY_DEFAULT_FILTER = PROFILES_CATEGORY_FILTER[0];

export const TABLE_INITIAL_QUERY = {
  filter: PROFILES_CATEGORY_DEFAULT_FILTER.value,
  search: "",
  sortBy: null,
  sortByDir: null
};

export const TABLE_QUERY_MAP = {
  filter: CATEGORY_QUERY_SELECTOR,
  search: SEARCH_QUERY_SELECTOR,
  sortBy: SORT_QUERY_SELECTOR,
  sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};

export const TABS = {
  EMPLOYEES: 'employee',
  CANDIDATES: 'candidate',
  PARTNER: 'supplier-employee',
};

export const LEGEND_ITEMS = {
  [TABS.EMPLOYEES]: [
    { color: styleConstants.greenLight, langKey: "submenu-fulltime-employee-label" },
    { color: styleConstants.yellowLight, langKey: "submenu-freelance-contract-label" },
  ],
  [TABS.CANDIDATES]: [
    { color: styleConstants.yellowLight, langKey: "registration-link" },
    { color: styleConstants.greenLight, langKey: "invited-by-admin" },
    { color: styleConstants.blueLight, langKey: "linkedin-text" },
    { color: styleConstants.purple, langKey: "google-text" },
    { color: styleConstants.greyDark, langKey: "others" },
  ],
};
