import React from 'react';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import taskDescriptionIcon from 'assets/task-description-icon.svg';
import { ReactComponent as RelatedToIcon } from 'assets/related-to-icon.svg';

import './TaskCard.scss';

const TaskCard = ({
    task,
    handleTaskCardClick,
    submitTaskHandler,
    agencyId,
    open,
    handlePopoverOpen,
    handlePopoverClose,
}) => {
    const handleStatusUpdate = () => {
        task.status === 'incompleted'
            ? submitTaskHandler({ ...task, status: "completed" }, task.relatedTo._id, agencyId)
            : submitTaskHandler({ ...task, status: "incompleted" }, task.relatedTo._id, agencyId);
    };

    return (
        <Paper className="task-card" onClick={!open ? handleTaskCardClick : null}>
            <div className="task-card-inner">
                <div className="title-wrapper">
                    <Typography className="task-name">
                        {task.name}
                    </Typography>
                    <div className="task-status-actions">
                        <Typography
                            className="task-status"
                            type="button"
                            onClick={handleStatusUpdate}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            {task.status === 'incompleted' &&
                                <RadioButtonCheckedIcon className="task-status-icon-incompleted" />
                            }
                            {task.status === 'completed' &&
                                <CheckCircleIcon className="task-status-icon-completed" />
                            }
                        </Typography>
                        {open &&
                            <div
                                className="task-status-popup"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                            >
                                {task.status === 'incompleted' ? 'Mark as completed' : 'Mark as incompleted'}
                            </div>
                        }
                    </div>
                </div>

                <div className="task-info">
                    <div className="info-row">
                        <PersonOutlineRoundedIcon fontSize="small" className="info-icon" />
                        <Typography variant="body2" className="info-text">{task.owner.name}</Typography>
                    </div>
                    <div className="info-row">
                        <RelatedToIcon fontSize="small" className="info-icon" />
                        <Typography variant="body2" className="info-text">{task.relatedTo.name}</Typography>
                    </div>
                    {task.reminderDateTime
                        && task.reminder
                        && <div className="info-row">
                            <div className="task-date">
                                <DateRangeRoundedIcon fontSize="small" className="info-icon" />
                                <Typography variant="body2" className="info-text">
                                    {moment.utc(task.reminderDateTime).format("DD/MM/YY")}
                                </Typography>
                            </div>
                            <div className="task-time">
                                <NotificationsNoneIcon fontSize="small" className="info-icon" />
                                <Typography variant="body2" className="info-text">
                                    {moment.utc(task.reminderDateTime).format("HH:mm")}
                                </Typography>
                            </div>
                        </div>
                    }
                    {task.description &&
                        <div className="info-row last">
                            <img src={taskDescriptionIcon} alt="Task description icon" className="info-icon" />
                            <Typography variant="body2" className="info-text">
                                {task.description.length > 60 ? `${task.description.substring(0, 60)}...` : task.description}
                            </Typography>
                        </div>
                    }
                </div>
            </div>
        </Paper>
    ); 
}

export default TaskCard;
