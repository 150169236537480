import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getAgencyId, getAgencyRouteName, getChecklistedCandidatesDataFetchState, getChecklistedCandidates } from 'reducers';
import { fetchChecklistedCandidates } from 'actions/candidateActions';

import CandidatesChecklistTable from './CandidatesChecklistTable';

import { INITIAL_CANDIDATES_CHECKLIST_FILTERS } from './CandidatesChecklistConstants';

const CandidatesChecklist = ({
    agencyId,
    agencyRouteName,
    dataFetchState,
    fetchChecklistedCandidates,
}) => {
    const [areFiltersParsed, setAreFiltersParsed] = useState(false);
    const [filters, setFilters] = useState(INITIAL_CANDIDATES_CHECKLIST_FILTERS);

    useEffect(() => {
        if (areFiltersParsed) {
            const { searchTerm, ...restFilters } = filters;
            fetchChecklistedCandidates(agencyId, { ...restFilters });
        }
    }, [filters.status, areFiltersParsed]);

    return (
        <div className="candidates-checklist-wrapper">
            <CandidatesChecklistTable
                agencyRouteName={agencyRouteName}
                dataFetchState={dataFetchState}
                filters={filters}
                setFilters={setFilters}
                setAreFiltersParsed={setAreFiltersParsed}
                dataSelector={getChecklistedCandidates}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    agencyRouteName: getAgencyRouteName(state),
    dataFetchState: getChecklistedCandidatesDataFetchState(state),
});

const mapDispatchToProps = {
    fetchChecklistedCandidates,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesChecklist);
