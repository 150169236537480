import { ROLES } from "constants/userConstants";

export const getCardTitle = role => {
    switch (role) {
        case ROLES.EMPLOYEE:
        case ROLES.ADMIN:
        case ROLES.SUPPLIER_EMPLOYEE:
        case ROLES.RECRUITER_EMPLOYEE:
            return 'Join your team now!';
        case ROLES.CANDIDATE:
            return 'Get your dream job now!';
        case ROLES.CLIENT:
            return 'Build your team in seconds!';
        case ROLES.SUPPLIER_ADMIN:
        case ROLES.RECRUITER:
            return 'Explore growth opportunities in seconds!';
        default:
            return null;
    }
}

export const getInviterInfoText = (role, name, agencyName, companyName) => {
    switch (role) {
        case ROLES.EMPLOYEE:
        case ROLES.ADMIN:
        case ROLES.SUPPLIER_EMPLOYEE:
        case ROLES.RECRUITER_EMPLOYEE:
            return <><b>{name}</b> invited you to join your team in <b>{agencyName}</b>.</>
        case ROLES.CANDIDATE:
            return <><b>{name}</b> invited you to explore new jobs from <b>{agencyName}</b>.</>
        case ROLES.CLIENT:
            return <><b>{name}</b> invited you to join <b>{companyName || agencyName}</b> on WIA!</>
        case ROLES.SUPPLIER_ADMIN:
        case ROLES.RECRUITER:
            return <><b>{name}</b> invited you to join <b>{agencyName}</b> on WIA!</>
        default:
            return null;
    }
};
