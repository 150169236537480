import {
    JOB_TYPE_FILTER_VALUES,
    JOB_STATUS_FILTER_VALUES,
    JOB_PRIVACY_FILTER_VALUES,
    JOB_PRIORITY_FILTER_VALUES,
    INITIAL_JOB_TYPE_FILTER,
    INITIAL_JOB_STATUS_FILTER,
    INITIAL_JOB_PRIVACY_FILTER,
    INITIAL_JOB_PRIORITY_FILTER,
} from './JobsDashboardConstants';

export const mapQueryToFilters = (queryObj) => {
    const initFilters = {
        jobType: INITIAL_JOB_TYPE_FILTER,
        status: INITIAL_JOB_STATUS_FILTER,
        privacy: INITIAL_JOB_PRIVACY_FILTER,
        priority: INITIAL_JOB_PRIORITY_FILTER,
    };

    const newFilters = Object.keys(queryObj).reduce((acc, x) => {
        let foundItem;

        switch (x) {
            case 'jobType':
                foundItem = JOB_TYPE_FILTER_VALUES.find((y) => y === queryObj[x]);
                acc.jobType = foundItem ? foundItem : acc.jobType;
                break;
            case 'status':
                foundItem = JOB_STATUS_FILTER_VALUES.find((y) => y === queryObj[x]);
                acc.status = foundItem ? foundItem : acc.status;
                break;
            case 'privacy':
                foundItem = JOB_PRIVACY_FILTER_VALUES.find((y) => y === queryObj[x]);
                acc.privacy = foundItem ? foundItem : acc.privacy;
                break;
            case 'priority':
                foundItem = JOB_PRIORITY_FILTER_VALUES.find((y) => y === queryObj[x]);
                acc.priority = foundItem ? foundItem : acc.priority;
                break;
            default:
                break;
        }
        return acc;
    }, initFilters);

    return newFilters;
};

export const encodeFilters = filters => Object.keys(filters).reduce((acc, filterKey) => ({ ...acc, [filterKey]: encodeURIComponent(filters[filterKey]) }), {});
