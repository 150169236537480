import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { toggleOvertimeBanner } from 'actions/uiActions';

import { getIsOvertimeBannerOpen, getAgencyName } from 'reducers';

import Banner from 'components/Shared/Banner';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import { ReactComponent as BannerIcon } from 'assets/banner-awesome-clock.svg';

import { OVERTIME_BANNER_TITLE, OVERTIME_BANNER_CLOSE_TEXT, OVERTIME_BANNER_POINTS_COMPONENT } from 'components/TimeTrackingPage/OvertimeBanner/OvertimeBannerConstants';

import './OvertimeBanner.scss';

const OvertimeBanner = ({
    toggleOvertimeBanner,
    isBannerOpen,
    agencyName,
}) => {
    const [isOpen, setIsOpen] = useState(isBannerOpen || moment().isSame(moment().date(15)));

    const handleToggleBanner = () => {
        setIsOpen(state => !state);
        toggleOvertimeBanner();
    };

    return (
        <div className="overtime-banner-wrapper">
            {isOpen ?
                <Banner
                    title={OVERTIME_BANNER_TITLE}
                    content={OVERTIME_BANNER_POINTS_COMPONENT}
                    icon={<BannerIcon />}
                    primaryButtonComponent={<SecondaryButton
                        text="Got It"
                        handleClick={handleToggleBanner}
                    />}
                />
                :
                <Banner
                    title={OVERTIME_BANNER_TITLE}
                    content={OVERTIME_BANNER_CLOSE_TEXT(agencyName)}
                    icon={<BannerIcon />}
                    primaryButtonComponent={<SecondaryButton
                        text="Learn More"
                        handleClick={handleToggleBanner}
                    />}
                />
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    isBannerOpen: getIsOvertimeBannerOpen(state),
    agencyName: getAgencyName(state),
});

const mapDispatchToProps = {
    toggleOvertimeBanner,
}

export default connect(mapStateToProps, mapDispatchToProps)(OvertimeBanner);
