import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorMessage } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { CURRENCIES } from 'constants/currenciesConstant';
import { clientStatuses } from 'components/Client/ClientConstants';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const InvoicingAndAccountManagementFormView = ({
    values,
    errors,
    touched,
    handleChange,
    handleShowHeaderButtons,
    accountManagers,
}) => {
    const intl = useIntl();

    return (
        <form className="grid-form-double-row" autoComplete="off">
            <div className="form-field">
                <TextField
                    select
                    required
                    fullWidth
                    variant="outlined"
                    name="status"
                    label={intl.formatMessage({ id: "invoice-status" })}
                    value={values.status}
                    error={errors.status && touched.status}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                >
                    {clientStatuses.map((status, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={status}
                        >
                            {intl.formatMessage({ id: convertStringToLanguageKey(status) })}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="status" component="div" className="invalid-field-message" />
            </div>

            <div className="form-field">
                <TextField
                    fullWidth
                    select
                    required
                    variant="outlined"
                    name="accountManager"
                    label={intl.formatMessage({ id: "account-manager" })}
                    value={values.accountManager}
                    error={errors.accountManager && touched.accountManager}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                >
                    {accountManagers.map((accountManager, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={accountManager._id}
                        >
                            {`${accountManager.firstName} ${accountManager.lastName}`}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="accountManager" component="div" className="invalid-field-message" />
            </div>

            <div className="form-field">
                <FormControl variant="outlined" fullWidth>
                    <InputLabel required id="currency-label">{intl.formatMessage({ id: "currency" })}</InputLabel>
                    <Select
                        id="currency"
                        labelId="currency-label"
                        name="currency"
                        label={intl.formatMessage({ id: "currency" })}
                        value={values.currency}
                        error={errors.currency && touched.currency}
                        onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    >
                        {CURRENCIES.map(CURRENCY => (
                            <MenuItem key={CURRENCY.VALUE} value={CURRENCY.VALUE}>
                                {CURRENCY.LABEL}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </form>
    );
};

export default InvoicingAndAccountManagementFormView;
