export const INVITE_DIALOG_TITLES = {
    INVITE: 'Invitation for Interview',
    PROPOSE_NEW: 'Propose New Time Slots',
};

export const INVITE_BUTTON_TEXTS = {
    INVITE_TO_INTERVIEW: 'Invite to interview',
    ALREADY_INVITED: 'Already invited',
};

export const MOVE_TO_EMPLOYEES_HELPER_TEXT = 'Since this candidate has been hired, do you want to move them to your team and employees?';

export const DEFAULT_INVITE_DIALOG_TITLE = INVITE_DIALOG_TITLES.INVITE;
