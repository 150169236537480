import { BULGARIAN_LABEL } from "constants/languages";

export const TOOLTIP_STATUS = {
    'new-applications': (count, selectedLanguage) => count === 1 ? selectedLanguage === BULGARIAN_LABEL ? 'нова кандидатура' : 'new applicant profile' : selectedLanguage === BULGARIAN_LABEL ? 'нови кандидатури' : 'new applicant profiles',
    'rejected-applications': (count, selectedLanguage) => count === 1 ? selectedLanguage === BULGARIAN_LABEL ? 'отказан профил' : 'profile have been rejected' : selectedLanguage === BULGARIAN_LABEL ? 'отказани профили' : 'profiles have been rejected',
    'pending-applications': (count, selectedLanguage) => count === 1 ? selectedLanguage === BULGARIAN_LABEL ? 'профил на фаза интервю' : 'profile in interview stage' : selectedLanguage === BULGARIAN_LABEL ? 'профили на фаза интервю' : 'profiles in interview stage',
    'approved-applications': (count, selectedLanguage) => count === 1 ? selectedLanguage === BULGARIAN_LABEL ? 'назначен профил' : 'profile have been hired' : selectedLanguage === BULGARIAN_LABEL ? 'назначени профили' : 'profiles have been hired',
    'pending-admin-applications': (count, selectedLanguage) => count === 1 ? selectedLanguage === BULGARIAN_LABEL ? 'профил чакащ одобрение от админ' : 'profile in admin approval stage' : selectedLanguage === BULGARIAN_LABEL ? 'профили чакащи одобрение от админ' : 'profiles in admin approval stage',
    'not-vetted-applications': (count, selectedLanguage) => count === 1 ? selectedLanguage === BULGARIAN_LABEL ? 'профил на неодобрен кандидат' : 'not vetted profile' :  selectedLanguage === BULGARIAN_LABEL ? 'профили на неодобрени кандидати' : 'not vetted profiles',
}
