import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import FormInputField from 'components/Shared/FormInputField';
import FormControlCheckbox from 'components/Shared/FormControlCheckbox';
import ActionButton from 'components/Shared/Buttons/ActionButton';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as GoogleIcon } from 'assets/google.svg';

import { motionRedirectLinks } from 'components/Register/RegisterConstants';
import { PASSWORD_VALIDATION_MESSAGES } from 'constants/commonConstants';

const RegisterAgencyCandidateFormView = ({
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isActionButtonLoading,
    ssoLoginLoading,
    handleSSOSignIn,
}) => {
    const isNameRequirementNotMet = errors.name && touched.name;

    const isEmailRequirementNotMet = errors.email && touched.email;

    const isPasswordRequirementMet = !errors.password && values.password;
    const isPasswordRequirementNotMet = errors.password && touched.password;

    const isTCChecked = values.isTermsAndConditionsAccepted;
    const hasTCError = errors.isTermsAndConditionsAccepted;
    const isTCFieldTouched = touched.isTermsAndConditionsAccepted;

    return (
        <form autoComplete="off">
            <FormInputField
                className="smaller"
                withInputLabel
                label="Name"
                placeholder="Enter your name here"
                name="name"
                value={values.name}
                showValidationRequirement={isNameRequirementNotMet}
                requirementText={errors.name}
                isRequirementNotMet={isNameRequirementNotMet}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />

            <FormInputField
                className="smaller"
                withInputLabel
                label="Email"
                placeholder="Enter your email address here"
                name="email"
                value={values.email}
                showValidationRequirement={isEmailRequirementNotMet}
                requirementText={errors.email}
                isRequirementNotMet={isEmailRequirementNotMet}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />

            <FormInputField
                className="smaller"
                withPasswordVisibility
                withInputLabel
                label="Password"
                placeholder="Enter your password here"
                name="password"
                value={values.password}
                showValidationRequirement
                requirementText={PASSWORD_VALIDATION_MESSAGES.ALL}
                isRequirementMet={isPasswordRequirementMet}
                isRequirementNotMet={isPasswordRequirementNotMet}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />

            <FormControlCheckbox
                isTouched={isTCFieldTouched}
                hasError={hasTCError}
                name="isTermsAndConditionsAccepted"
                label={
                    <span>
                        I agree to the platform's <a href={motionRedirectLinks.privacy.link} target="_blank">{motionRedirectLinks.privacy.label}.</a>
                    </span>
                }
                checked={isTCChecked}
                handleChange={() => setFieldValue('isTermsAndConditionsAccepted', !isTCChecked)}
            />

            <div className="buttons-wrapper">
                <ActionButton
                    text={isActionButtonLoading ? <CircularProgress className="white" /> : "Sign Up"}
                    handleClick={handleSubmit}
                />

                <ActionButton
                    className="inverted with-icon"
                    text={ssoLoginLoading ? "Sign In With Google" : <CircularProgress className="teal" />}
                    icon={ssoLoginLoading && <GoogleIcon />}
                    handleClick={handleSSOSignIn}
                />
            </div>

            <p className="login-text-wrapper">
                Already have an account? <Link
                    underline="always"
                    component={RouterLink}
                    to={motionRedirectLinks.login.link}
                >
                    {motionRedirectLinks.login.label}.
                </Link>
            </p>
        </form>
    )
}
export default RegisterAgencyCandidateFormView;
