import api from './api';
import requester from './requester';

const recruiterService = {
    registerRecruiter: (agencyId, recruiterData) => requester(api.recruiters(agencyId)).create(recruiterData),
    fetchRecruiters: (agencyId, query) => requester(api.recruiters(agencyId, query)).getMany(),
    getRecruiterEmployees: (agencyId, recruiterId) => requester(api.recruiterEmployees(agencyId, recruiterId)).getMany(),
    getRecruiterEmployee: (agencyId, recruiterId, profileId) => requester(api.recruiterEmployee(agencyId, recruiterId, profileId)).getOne(),
    getRecruiterInformation: (agencyId, recruiterId) => requester(api.recruiterInformation(agencyId, recruiterId)).getOne(),
    uploadRecruiterEmployeeCV: (agencyId, recruiterId, profileId, files) => requester(api.uploadRecruiterEmployeeCV(agencyId, recruiterId, profileId)).uploadFile(files),
    downloadRecruiterEmployeeCV: (agencyId, recruiterId, profileId, fileName, fileNameWithoutExtension, fileType) =>
        requester(api.downloadRecruiterEmployeeCV(agencyId, recruiterId, profileId))
            .saveAsAction(fileNameWithoutExtension, { fileType, fileName }),
    deleteRecruiterEmployeeCV: (agencyId, recruiterId, profileId) => requester(api.deleteRecruiterEmployeeCV(agencyId, recruiterId, profileId)).delete(),
    deleteRecruiterEmployees: (employeesIds) => requester(api.deleteRecruiterEmployees()).update(employeesIds),
    updateAvatar: (agencyId, recruiterId, croppedImgData) => requester(api.recruiterAvatar(agencyId, recruiterId)).uploadFile(croppedImgData),
    deleteAvatar: (agencyId, recruiterId) => requester(api.recruiterAvatar(agencyId, recruiterId)).delete(),
    updateRecruiterBasicInformation: (agencyId, supplierId, basicInfo) => requester(api.recruiterBasicInformation(agencyId, supplierId)).create(basicInfo),
    updateRecruiterContactInformation: (agencyId, supplierId, contactInfo) => requester(api.recruiterContactInformation(agencyId, supplierId)).create(contactInfo),
    updateRecruiterAccountManagerInformation: (agencyId, supplierId, invoceInfo) => requester(api.recruiterAccountManagerInformation(agencyId, supplierId)).create(invoceInfo),
    fetchRecruitersManagers: agencyId => requester(api.recruitersAccountManagers(agencyId)).getMany(),
};

export default recruiterService;
