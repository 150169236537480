import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import RegisterAgencyFormView from './RegisterAgencyFormView';
import RegisterFooter from '../RegisterFooter';
import AuthCard from 'components/Shared/AuthCard';
import Grid from '@material-ui/core/Grid';

import { getActionButtonLoading, getSSOLoginLoading } from 'reducers';

import { registerAsUnassignedAdmin, login, logout, ssoLoginSuccess } from 'actions/authActions';
import { createSSOLoggingRecord } from 'actions/loggingActions';
import { showNotification } from 'actions/notificationActions';
import { hideActionButtonLoader, showActionButtonLoader } from 'actions/sectionsLoadersActions';

import { signInAgencyWithPopup } from 'utils/firebase';
import {
    validateEmailAddress,
    validatePasswordRequirements,
    validateInputLength,
    validateRequiredFields
} from 'utils/formValidations';
import { getHomePath } from 'utils/navigationUtils';

import { AUTH_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { MAX_NAME_LENGTH } from '../RegisterConstants';

import './RegisterAgency.scss';

const RegisterAgency = ({
    isActionButtonLoading,
    ssoLoginLoading,
    registerAsUnassignedAdmin,
    login,
    logout,
    ssoLoginSuccess,
    createSSOLoggingRecord,
    showNotification,
    showActionButtonLoader,
    hideActionButtonLoader,
}) => {
    const history = useHistory();

    const handleSignUp = async values => {
        /* 
        This will prevent redirecting from the isGuest component,
        so we can redirect manually after successful login.
        */
        history.replace({ state: { isFromAgencyRegistration: true } });

        try {
            showActionButtonLoader();
            values.email = values.email.toLowerCase().trim();

            await registerAsUnassignedAdmin(values);
            await login(values.email, values.password);
            const homePath = getHomePath();
            history.push({ pathname: homePath.pathname, state: { ...history.location.state, ...homePath.state } });
        } finally {
            hideActionButtonLoader();
        }
    }

    const handleSSOSignIn = async event => {
        event.preventDefault();
        /* 
        This will prevent redirecting from the isGuest component,
        so we can redirect manually after successful login.
        */
        history.replace({ state: { isFromAgencyRegistration: true } });
        try {
            ssoLoginSuccess(false);
            const user = await signInAgencyWithPopup();
            await login(user.email, null, false, false, true, user.credential);
            const homePath = getHomePath(history.location.state?.gotoUrl);
            history.push({ pathname: homePath.pathname, state: { ...history.location.state, ...homePath.state } });
        } catch (err) {
            createSSOLoggingRecord({ message: err.message });
            // making a request to the backend will trigger refreshing the token and storing it
            // so we need to trigger logout to clear it
            await logout();
            history.push(`/register/agency`);
            if (err.message === AUTH_MESSAGES.NO_ACCOUNT_ASSOCIATED_WITH_EMAIL) {
                showNotification(err.message, NOTIFICATION_TYPES.INFO);
            }
        } finally {
            ssoLoginSuccess(true);
        }
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="register-agency-wrapper"
        >
            <AuthCard
                title="It's free to join!"
                className="register-agency-form-wrapper"
            >
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        isTermsAndConditionsAccepted: false,
                    }}
                    validate={values => {
                        return {
                            ...validateRequiredFields(values, ['email', 'password', 'isTermsAndConditionsAccepted']),
                            ...validateEmailAddress(values, 'email'),
                            ...validatePasswordRequirements(values, 'password'),
                            ...validateInputLength(values, [{ property: 'email', MAX_NAME_LENGTH }]),
                        };
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(false);
                        await handleSignUp(values);
                    }}
                >
                    {(props) => {
                        return (
                            <RegisterAgencyFormView
                                {...props}
                                isActionButtonLoading={isActionButtonLoading}
                                ssoLoginLoading={ssoLoginLoading}
                                handleSSOSignIn={handleSSOSignIn}
                            />
                        )
                    }}
                </Formik>
            </AuthCard>
            <RegisterFooter />
        </Grid>
    );
};

const mapStateToProps = state => ({
    isActionButtonLoading: getActionButtonLoading(state),
    ssoLoginLoading: getSSOLoginLoading(state),
});

const mapDispatchToProps = {
    registerAsUnassignedAdmin,
    login,
    logout,
    ssoLoginSuccess,
    createSSOLoggingRecord,
    showNotification,
    showActionButtonLoader,
    hideActionButtonLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAgency);
