import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getEmployeeProjectsWithClientInfo } from 'reducers';
import EmployeeProjectCardList from './EmployeeProjectCardList';
import { fetchEmployeeProjectsWithClientInfo } from 'actions/employeeActions';

const EmployeeProjects = ({
    userId,
    agencyId,
    projects,
    fetchEmployeeProjectsWithClientInfo,
    isDataAvailable,
    displayMode,
}) => {
    
    useEffect(() => {
        if (!isDataAvailable) {
            fetchEmployeeProjectsWithClientInfo(userId, agencyId);
        }
    }, []);
    // this component will contain filter dropdown and change view icons
    return <EmployeeProjectCardList projects={projects} displayMode={displayMode}/>
};

const mapStateToProps = state => ({
    projects: getEmployeeProjectsWithClientInfo(state)
});

const mapDispatchToProps = {
    fetchEmployeeProjectsWithClientInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProjects);
