import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogSaveButton from 'components/Shared/DialogButtons/DialogSaveButton';
import DialogCloseButton from 'components/Shared/DialogButtons/DialogCloseButton'
import DialogActions from '@material-ui/core/DialogActions';

import './InfoMessageWithIconDialog.scss';

const InfoMessageWithIconDialog = ({
    isOpen,
    handleCloseDialog,
    title,
    textComponent,
    iconComponent,
}) => {
    return (
        <Dialog className="info-message-with-icon-dialog" open={isOpen} onClose={handleCloseDialog}>
            <DialogTitle disableTypography className="dialog-title">
                <Typography variant="body2" className="dialog-title-text">
                    {title}
                </Typography>
                <DialogCloseButton className="dialog-close-btn" onClick={handleCloseDialog}></DialogCloseButton>
            </DialogTitle>
            <DialogContent className="dialog-content">
                <div className="dialog-icon-wrapper">
                    {iconComponent}
                </div>
                <p className="dialog-text">
                    {textComponent}
                </p>
            </DialogContent>
            <DialogActions className="dialog-actions-container">
                <DialogSaveButton onClick={handleCloseDialog}>Got It</DialogSaveButton>
            </DialogActions>
        </Dialog>
    );
};

export default InfoMessageWithIconDialog;
