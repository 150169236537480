import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Paper, TextField } from '@material-ui/core';

import { updateTraining, fetchTraining } from 'actions/agencyActions';
import { getAgencyId, getIndividualTraining, isAdminMode } from 'reducers';

import Topics from '../Topics';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

const Overview = ({
    individualTraining,
    updateTraining,
    fetchTraining,
    agencyId,
    match
}) => {
    useEffect(() => {
        fetchTraining(agencyId, match.params.trainingId)
    }, []);

    const { trainingId } = match.params;

    const [description, setDescription] = useState('');
    const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);

    useEffect(() => {
        setDescription(individualTraining.description || '');
    }, [individualTraining.description]);

    const handleSaveDescription = () => updateTraining(agencyId, trainingId, { ...individualTraining, description });

    return (
        <div className="individual-training-wrapper">
            <div className="individual-training-description-wrapper">
                <SectionActionsWrapper>
                    <SectionActionsWrapper.SectionTitle
                        sectionTitle='Description'
                    />

                    {isSaveButtonVisible && (
                        <SectionActionsWrapper.RightAlignedActions>
                            <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                                text='Save'
                                onClick={handleSaveDescription}
                                buttonStyle='save'
                            />
                        </SectionActionsWrapper.RightAlignedActions>
                    )}
                </SectionActionsWrapper>

                <Paper className="description-content">
                    <TextField
                        className='description-text-field'
                        value={description}
                        onChange={(e) => {
                            setIsSaveButtonVisible(true);
                            setDescription(e.target.value);
                        }}
                        label="Description"
                        variant="outlined"
                        fullWidth
                        multiline
                        inputProps={{
                            maxLength: 1000
                        }}
                    />
                </Paper>
            </div>
            <Topics agencyId={agencyId} trainingId={trainingId} topics={individualTraining.topics} />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    agencyId: getAgencyId(state),
    individualTraining: getIndividualTraining(state, ownProps.match.params.trainingId),
    isAdmin: isAdminMode(state)
})

const mapDispatchToProps = {
    fetchTraining,
    updateTraining
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
