import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { getLanguage } from 'reducers';

import { Paper } from '@material-ui/core';

import { applicationProgress, APPLICATION_STATUSES, applicationProgressBG } from 'constants/applicationConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { STEPS_OF_REJECTION } from 'constants/candidateConstants';
import { BULGARIAN_LABEL } from 'constants/languages';

import './ApplicationProgressBar.scss';

const ApplicationProgressBar = ({
    applicationHistory,
    interviewDate = {},
    agencyName,
    isAdminMode,
    selectedLanguage,
}) => {
    const intl = useIntl();

    const executeApplicationProgressFunction = (record) => {
        if (record.status === APPLICATION_STATUSES.REJECTED) {
            if (selectedLanguage === BULGARIAN_LABEL) {
                return applicationProgressBG(agencyName, isAdminMode, record?.reviewerName)[record.status][record.stepOfRejection || STEPS_OF_REJECTION.REJECTED_BY_AGENCY]
            } else {
                return applicationProgress(agencyName, isAdminMode, record?.reviewerName)[record.status][record.stepOfRejection || STEPS_OF_REJECTION.REJECTED_BY_AGENCY]
            }
        } else if (selectedLanguage === BULGARIAN_LABEL) {
            return applicationProgressBG(agencyName, isAdminMode, record?.reviewerName, record?.interviewDate || interviewDate)[record.status]
        } else {
            return applicationProgress(agencyName, isAdminMode, record?.reviewerName, record?.interviewDate || interviewDate)[record.status]

        }
    };

    return (
        <Paper className="application-progress-bar-wrapper">
            <h4 className="title">{intl.formatMessage({ id: "application-history" })}</h4>
            <ul>
                {applicationHistory.filter(x => x.status !== APPLICATION_STATUSES.NON_VETTED_CANDIDATE).sort((a, b) => new Date(a.date) - new Date(b.date)).map((record, i) => {
                    return (
                        <li key={i} className="progress-bar-step">
                            <div className="step-content-container">
                                <span className="date-content">{moment.utc(record.date).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span>
                                <div className="status-content" dangerouslySetInnerHTML={{
                                    __html: executeApplicationProgressFunction(record)
                                }} />
                            </div>
                        </li>
                    );
                })}
            </ul>
        </Paper>
    )
};

const mapStateToProps = (state) => ({
    selectedLanguage: getLanguage(state)
});

export default connect(mapStateToProps, null)(ApplicationProgressBar);
