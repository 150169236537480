export const singleCheckClickHandler = (setCheckedRows, setIsButtonInitial, checkedRows) => (e, checkedItem) => {
    setCheckedRows(oldState =>
        oldState.some(x => x._id === checkedItem._id)
            ? oldState.filter(x => x._id !== checkedItem._id)
            : [...oldState, checkedItem]
    );

    setIsButtonInitial(isButtonInitial => {
        if (isButtonInitial) {
            return false
        } else {
            return !checkedRows.some(x => x._id !== checkedItem._id);
        }
    })
};

export const multipleCheckClickHandler = (setCheckedRows, setIsButtonInitial) => (event, checkedItems) => {
    if (event.target.checked) {
        setCheckedRows(checkedItems);

        if (checkedItems.length) {
            setIsButtonInitial(false);
        }
    } else {
        setCheckedRows([]);
        setIsButtonInitial(true);
    }
};
