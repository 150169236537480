import interviewInvitationService from "services/interviewInvitationService";
import { authorizedShowNotification } from 'actions/actionHelpers';
import { fetchInterviewInvitationSuccess } from 'actions/candidateActions';
import { updateApplicationStatusSuccess } from "actions/jobApplicationActions";
import {
    INTERVIEW_INVITATIONS_FETCH_SUCCESS,
    INTERVIEW_DATE_UPDATE_SUCCESS,
    INTERVIEW_DATA_UPDATE_SUCCESS,
    INTERVIEW_STATUS_UPDATE_SUCCESS,
    INTERVIEW_INVITATION_ADD_SUCCESS,
    CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCH_SUCCESS,
    CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCHING_SUCCESS
} from './actionTypes';
import { INTERVIEW_INVITATION_MESSAGES } from "constants/messageConstants";
import { NOTIFICATION_TYPES } from "constants/notificationTypes";
import { APPLICATION_STATUSES } from "constants/applicationConstants";
import { showDialogActionButtonLoader, hideDialogActionButtonLoader, } from "./sectionsLoadersActions";

export const fetchInterviewInvitationsSuccess = invitationsData => ({
    type: INTERVIEW_INVITATIONS_FETCH_SUCCESS,
    payload: invitationsData
});

const updateInterviewDateSuccess = interviewDate => ({
    type: INTERVIEW_DATE_UPDATE_SUCCESS,
    payload: interviewDate
});

const updateInterviewDataSuccess = interviewDate => ({
    type: INTERVIEW_DATA_UPDATE_SUCCESS,
    payload: interviewDate
});

const updateInterviewStatusSuccess = interviewData => ({
    type: INTERVIEW_STATUS_UPDATE_SUCCESS,
    payload: interviewData,
});

const createInterviewInvitationSuccess = invitationData => ({
    type: INTERVIEW_INVITATION_ADD_SUCCESS,
    payload: invitationData
});

const candidateInterviewInvitationsDataFetchSuccess = () => ({
    type: CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCH_SUCCESS,
});

const candidateInterviewInvitationsDataFetchingSuccess = () => ({
    type: CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCHING_SUCCESS,
});

export const fetchCandidateInterviewInvitations = (agencyId, candidateId) => async dispatch => {
    try {
        dispatch(candidateInterviewInvitationsDataFetchingSuccess());

        const result = await interviewInvitationService.fetchCandidateInterviewInvitations(agencyId, candidateId);

        dispatch(fetchInterviewInvitationsSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INTERVIEW_INVITATION_MESSAGES.FETCH_CANDIDATE_INTERVIEW_INVITATIONS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(candidateInterviewInvitationsDataFetchSuccess());
    }
};

export const updateInterviewDate = (agencyId, interviewId, data) => async dispatch => {
    try {
        const { history, ...interviewDate } = await interviewInvitationService.updateInterviewDate(agencyId, interviewId, data);

        dispatch(updateInterviewDateSuccess(interviewDate));
        dispatch(updateApplicationStatusSuccess({ history }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INTERVIEW_INVITATION_MESSAGES.UPDATE_INTERVIEW_DATE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateInterviewData = (agencyId, interviewId, data) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const interviewDate = await interviewInvitationService.updateInterviewData(agencyId, interviewId, data);

        dispatch(fetchInterviewInvitationSuccess(data));
        dispatch(updateInterviewDataSuccess(interviewDate));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INTERVIEW_INVITATION_MESSAGES.UPDATE_INTERVIEW_DATA_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const updateInterviewStatus = (agencyId, interviewId, data) => async dispatch => {
    try {
        const { interviewInvitation, history } = await interviewInvitationService.updateInterviewStatus(agencyId, interviewId, data);

        dispatch(fetchInterviewInvitationSuccess(interviewInvitation));
        dispatch(updateInterviewStatusSuccess(interviewId));
        dispatch(updateApplicationStatusSuccess({ status: APPLICATION_STATUSES.NEW_APPLICATION, ...(history && { history }) }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INTERVIEW_INVITATION_MESSAGES.UPDATE_INTERVIEW_STATUS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const createInterviewInvitation = (agencyId, data) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const result = await interviewInvitationService.createInterviewInvitation(agencyId, data);

        dispatch(createInterviewInvitationSuccess(result.interviewInvitation));
        dispatch(updateApplicationStatusSuccess({ status: APPLICATION_STATUSES.INVITED_FOR_INTERVIEW, history: result.history }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INTERVIEW_INVITATION_MESSAGES.CREATE_INTERVIEW_INVITATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const rescheduleInterview = (agencyId, interviewId, data) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const { interviewInvitation, history } = await interviewInvitationService.rescheduleInterview(agencyId, interviewId, data);

        dispatch(createInterviewInvitationSuccess(interviewInvitation));
        dispatch(updateApplicationStatusSuccess({ ...(history && { history }) }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INTERVIEW_INVITATION_MESSAGES.INTERVIEW_RESCHEDULE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};
