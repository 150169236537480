import React from 'react';

const HourGlassIcon = ({ style = {} }) => (
	<div className='hour-glass-icon-container'>
		<svg
			version='1.0'
			style={style}
			width='15px'
			height='15px'
			viewBox='0 0 453.000000 610.000000'
			preserveAspectRatio='xMidYMid meet'>
			<g
				transform='translate(0.000000,610.000000) scale(0.100000,-0.100000)'
				fill={style.color || '#000000'}
				stroke='none'>
				<path
					d='M10 5775 l0 -324 158 -3 157 -3 6 -220 c7 -237 13 -276 73 -450 107
                -312 342 -775 680 -1339 241 -402 230 -380 215 -408 -13 -26 -181 -312 -269
                -458 -292 -487 -525 -950 -626 -1245 -60 -174 -66 -213 -73 -450 l-6 -220
                -160 -3 -160 -2 3 -325 3 -325 2259 0 2260 0 0 325 0 324 -147 3 -148 3 -6
                220 c-7 237 -13 277 -73 450 -110 322 -320 728 -747 1442 l-169 283 29 47 c15
                27 86 145 156 263 71 118 143 240 160 270 123 213 159 277 255 457 157 294
                252 501 316 688 60 173 66 213 73 450 l6 220 148 3 147 3 0 324 0 325 -2260 0
                -2260 0 0 -325z m3890 -532 l0 -206 -36 -106 c-63 -186 -240 -533 -542 -1066
                -247 -435 -289 -512 -352 -638 -86 -172 -86 -180 5 -363 39 -77 121 -229 183
                -339 63 -110 144 -254 182 -320 291 -514 434 -794 518 -1015 l36 -95 4 -222 4
                -223 -1621 0 -1621 0 0 207 0 207 41 120 c57 170 230 515 484 966 282 502 334
                597 394 720 87 178 87 184 0 361 -59 121 -105 204 -327 599 -318 566 -493 911
                -551 1086 l-41 120 0 207 0 207 1620 0 1620 0 0 -207z'
				/>
				<path
					d='M1150 4760 c0 -121 6 -143 93 -335 163 -363 475 -812 817 -1177 l72
                -78 148 0 149 0 80 88 c349 382 669 866 862 1304 38 86 39 93 39 198 l0 110
                -1130 0 -1130 0 0 -110z'
				/>
			</g>
		</svg>
	</div>
);
export default HourGlassIcon;
