import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getEmployeeBasicInfo, getUpcomingInterviewsList } from 'reducers';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import JobTypeLabel from 'components/JobsDashboard/SharedComponents/JobTypeLabel';
import Avatar from '@material-ui/core/Avatar';

import { FULL_DAY_ONLY, FULL_MONTH_ONLY, US_TIME_FORMAT } from 'constants/commonConstants';

import './UpcomingInterviews.scss';

const UpcomingInterviews = ({
    upcomingInterviews,
    basicInfo,
}) => {

    return (
        upcomingInterviews.length > 0 &&
        <div className="candidate-upcoming-interviews-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle sectionTitle='Upcoming Interviews' />
            </SectionActionsWrapper>

            <div className="candidate-upcoming-interviews-list">
                {upcomingInterviews.map(interview =>
                    <div
                        key={interview._id}
                        className="candidate-upcoming-interview-card"
                    >
                        <JobTypeLabel jobType={interview.jobType} />

                        <div>
                            <div className="candidate-upcoming-interview-job-position">
                                {interview.position}
                            </div>

                            <div className="candidate-info">
                                <Avatar
                                    className="candidate-avatar"
                                    src={basicInfo.profilePictureImgUrl}
                                />
                                <p className="candidate-name">{`${basicInfo.firstName} ${basicInfo.lastName}`}</p>
                            </div>

                            <div className="candidate-upcoming-interview-location">
                                Location: <span>{interview.location}</span>
                            </div>
                        </div>
                        <div className="candidate-upcoming-interview-time-wrapper">
                            <div className="candidate-upcoming-interview-day-month-container">
                                <div className="day">{moment(interview.interviewDate?.date).format(FULL_DAY_ONLY)}</div>
                                <div className="month">{moment(interview.interviewDate?.date).format(FULL_MONTH_ONLY)}</div>
                            </div>
                            <div className="candidate-upcoming-interview-hours-range">
                                {`${moment(interview.interviewDate?.date).format(US_TIME_FORMAT).split(' ').join('')} - ${moment(interview.interviewDate?.date).add(1, 'hours').format(US_TIME_FORMAT).split(' ').join('')}`}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    upcomingInterviews: getUpcomingInterviewsList(state),
    basicInfo: getEmployeeBasicInfo(state),
});

export default connect(mapStateToProps)(UpcomingInterviews);
