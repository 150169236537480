import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import logoImg from 'assets/default-logo.svg';

const ResultsCardViewProject = ({
    result,
    handleClick,
}) => (
    <div className="card-view-wrapper">
        <ListItem
            className="result-list-item"
            button={!!handleClick}
            onClick={handleClick ? handleClick.bind(null, result) : null}
        >
             <div className="main-info">
                    <ListItemAvatar>
                        <Avatar src={logoImg} alt={result.name} className="search-result-avatar" />
                    </ListItemAvatar>
                    <ListItemText
                        className="name-info"
                        primary={result.name}
                    />
                </div>
        </ListItem>
    </div>

);

export default ResultsCardViewProject;
