import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import { getLoginLoading } from 'reducers';

import { resetPassword, login } from 'actions/authActions';

import PasswordSetFormView from './PasswordSetFormView';
import AuthCard from 'components/Shared/AuthCard';

import { getCardTitle } from './PasswordSetUtils';

import {
    validateRequiredFields,
    validatePasswordRequirements,
    validatePasswordEquality
} from 'utils/formValidations';

import './PasswordSet.scss';

const PasswordSet = ({
    resetPassword,
    login,
    loginLoading,
}) => {
    const history = useHistory();
    const { passwordOobCode, email, inviter, role } = history.location?.state || {};
    const inviterInfo = inviter ? JSON.parse(atob(decodeURIComponent(inviter))) : null;

    return (
        <AuthCard
            className="password-set-wrapper"
            title={getCardTitle(role)}
        >
            <Formik
                initialValues={{
                    password: '',
                    confirmPassword: '',
                    isTermsAndConditionsAccepted: false
                }}
                validate={values => {
                    return {
                        ...validatePasswordRequirements(values, 'password'),
                        ...validatePasswordEquality(values, 'password', 'confirmPassword'),
                        ...validateRequiredFields(values, ['password', 'confirmPassword', 'isTermsAndConditionsAccepted'])
                    }
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(false);
                    await resetPassword(passwordOobCode, values.password)
                    login(email, values.password, false, values.isTermsAndConditionsAccepted);
                }}
            >
                {(props) =>
                    <PasswordSetFormView
                        {...props}
                        role={role}
                        inviterInfo={inviterInfo}
                        loginLoading={loginLoading}
                    />
                }
            </Formik>
        </AuthCard>
    )
};

const mapStateToProps = state => ({
    loginLoading: getLoginLoading(state),
});


const mapDispatchToProps = {
    resetPassword,
    login,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordSet);
