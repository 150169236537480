import React from 'react';
import { connect } from 'react-redux';

import { switchLanguage } from 'actions/languageActions';
import { getLanguage } from 'reducers';

import Typography from '@material-ui/core/Typography';

import { BULGARIAN_LABEL, ENGLISH_LABEL } from 'constants/languages';

import './LanguageSelector.scss';

const LanguageSelector = ({
    userId,
    selectedLanguage,
    switchLanguage,
}) => {
    const handleSwitchLanguage = () => {
        const newLanguage = selectedLanguage === BULGARIAN_LABEL ? ENGLISH_LABEL : BULGARIAN_LABEL;
        switchLanguage(userId, newLanguage);
    };

    return (
        <div className="language-selector" onClick={handleSwitchLanguage}>
            <Typography className={`option ${selectedLanguage === ENGLISH_LABEL ? 'selected': ''}`} type="body2">EN</Typography>
            <Typography className={`option ${selectedLanguage === BULGARIAN_LABEL ? 'selected': ''}`} type="body2">BG</Typography>
        </div>
    )
};

const mapStateToProps = state => ({
    selectedLanguage: getLanguage(state),
});

const mapDispatchToProps = {
    switchLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
