import moment from 'moment';

import { ROLES } from 'constants/userConstants';
import { mapEmployeeOccupationStatusToStyles, getToolTipTitleBasedOnOccupationStatus } from 'constants/employeeConstants';
import { BULGARIAN_LABEL } from 'constants/languages';

export const handleScoreTooltipHover = (e, visibility, setPopupTransform, setIsShowMorePopupVisible) => {
    if (e) {
        const popup = e.target.parentNode.nextSibling;

        const popupBoundingRect = popup.getBoundingClientRect();
        const boundingRect = e.target.getBoundingClientRect();
        const topOffset = boundingRect.top;

        const yOffset = window.innerHeight - (topOffset + popupBoundingRect.height + 20);

        let yTranslate = 0;
        const xTranslate = e.target.offsetLeft - 115;

        if (yOffset < 0) {
            yTranslate = - (popupBoundingRect.height + 22);
        }

        setPopupTransform({ transform: `translate(${xTranslate}px, ${yTranslate}px)` });
    }
    setIsShowMorePopupVisible(visibility);
};

const getCandidateTooltipText = (isCandidateAvailable, selectedLanguage) => {
    if (selectedLanguage === BULGARIAN_LABEL) {
        return `Този кандидат ${isCandidateAvailable ? 'е на разположение' : 'не е на разположение'}`
    } else {
        return `This candidate profile is currently ${isCandidateAvailable ? 'available' : 'unavailable'}`
    }
};

const getSupplierTooltipText = (isPartnerAvailable, selectedLanguage) => {
    if (selectedLanguage === BULGARIAN_LABEL) {
        return `Този партньорски профил ${isPartnerAvailable ? 'е на разположение' : 'не е на разположение'}`
    } else {
        return `This partner profile is currently ${isPartnerAvailable ? 'available' : 'unavailable'}`
    }
};

export const getStatusDotInformation = (profile, selectedLanguage) => {
    switch (profile.role) {
        case ROLES.EMPLOYEE:
        case ROLES.ADMIN:
            return {
                dotTooltipTitle: `${getToolTipTitleBasedOnOccupationStatus(profile.occupationStatus, selectedLanguage)}`,
                dotClassName: `status-dot ${mapEmployeeOccupationStatusToStyles(profile.occupationStatus)} `,
            };
        case ROLES.CANDIDATE:
            const isCandidateAvailable = (profile.role === ROLES.CANDIDATE && profile.earliestDate)
                ? moment(new Date()).isAfter(profile.earliestDate)
                : false;
            return {
                dotTooltipTitle: getCandidateTooltipText(isCandidateAvailable, selectedLanguage),
                dotClassName: isCandidateAvailable ? 'status-dot available' : 'status-dot occupied',
            };
        case ROLES.SUPPLIER_EMPLOYEE:
        case ROLES.SUPPLIER_ADMIN:
            const isPartnerAvailable = profile.isAvailable;
            return {
                dotTooltipTitle: getSupplierTooltipText(isPartnerAvailable, selectedLanguage),
                dotClassName: isPartnerAvailable ? 'status-dot available' : 'status-dot occupied'
            };
        default:
            return {
                dotTooltipTitle: '',
                dotClassName: '',
            };
    }
};
