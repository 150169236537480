export const JOB_TYPES = {
    DEVELOPER: 'Developer',
    PROJECT_MANAGER: 'Project Manager',
    QA_ENGINEER: 'QA Engineer',
    DEVOPS_ENGINEER: 'DevOps Engineer',
    DESIGNER: 'Designer',
    OTHER: 'Other',
}

export const CANDIDATE_JOB_TYPE_OPTIONS = [
    {
        title: 'Software Engineer',
        value: JOB_TYPES.DEVELOPER,
    },
    {
        title: 'Project Owner / PM',
        value: JOB_TYPES.PROJECT_MANAGER,
    },
    {
        title: 'QA Engineer',
        value: JOB_TYPES.QA_ENGINEER,
    },
    {
        title: 'DevOps Engineer',
        value: JOB_TYPES.DEVOPS_ENGINEER,
    },
    {
        title: 'Designer',
        value: JOB_TYPES.DESIGNER,
    },
    {
        title: 'Other',
        value: JOB_TYPES.OTHER,
    },
];

export const CANDIDATE_EXPERIENCE_OPTIONS = [
    { value: '<1 year', title: '<1 year' },
    { value: '1-2 years', title: '1-2 years' },
    { value: '2-3 years', title: '2-3 years' },
    { value: '3-5 years', title: '3-5 years' },
    { value: '5+ years', title: '5+ years' },
];

export const LESS_THAN_ONE_YEAR_EXPERIENCE = CANDIDATE_EXPERIENCE_OPTIONS[0].value;
export const ONE_TO_TWO_YEARS_EXPERIENCE = CANDIDATE_EXPERIENCE_OPTIONS[1].value;
