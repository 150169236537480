import { persistReducer } from 'redux-persist';

import { SWITCH_LANGUAGE_SUCCESS } from 'actions/actionTypes';

import { PERSIST_LANGUAGE_CONFIG } from 'utils/persistUtils';

const initialState = {
    locale: 'en'
};

const languageReducer = (state=initialState, action) => {
    switch (action.type) {
        case SWITCH_LANGUAGE_SUCCESS:
            return { locale: action.payload };
        default:
            return state;
    }
};

export default persistReducer(PERSIST_LANGUAGE_CONFIG, languageReducer);

export const getLanguage = (state) => state.locale;
