export const tableColumns = [
    { name: 'Project', value: 'projectName', isSortable: true, langKey: "project-text" },
    { name: 'Issue Date', value: 'issueDate', isSortable: true, langKey: "issue-date" },
    { name: 'Due Date', value: 'dueDate', isSortable: true, langKey: "due-date" },
    { name: 'Amount', value: 'amount', isSortable: true, langKey: "amount" },
    { name: 'Status', value: 'status', isSortable: true, langKey: "status" },
    { name: 'Amount Paid', value: 'amountPaid', isSortable: true, langKey: "amount-paid" },
    { name: 'Payment Date', value: 'paymentDate', isSortable: true, langKey: "payment-date" },
    { name: 'Invoice ID', value: 'invoiceNumber', isSortable: true, langKey: "invoice-id" },
];

export const defaultTableColumns = [
    'projectName',
    'issueDate',
    'dueDate',
    'amount',
    'status',
    'amountPaid',
    'paymentDate',
    'invoiceNumber',
];

export const tableFilters = [{ key: 'status', name: 'Status', value: ["Pending", "Paid", "Overdue"], hasTranslation: true }];

export const SORT_QUERY_SELECTOR = 'sort';
export const PAGE_QUERY_SELECTOR = 'page';
export const SEARCH_QUERY_SELECTOR = 'search';

export const TABLE_INITIAL_QUERY = {
    filters: JSON.stringify({}),
    page: 1,
    search: "",
    sortBy: "projectName",
    sortByDir: "asc",
    pagesToLoad: 5,
    itemsPerPage: 25,
};

export const TABLE_QUERY_MAP = {
    filters: "filters",
    page: PAGE_QUERY_SELECTOR,
    search: SEARCH_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};

export const invoiceStatuses = [
    { value: 1, name: 'Pending' },
    { value: 2, name: 'Paid' },
    { value: 3, name: 'Overdue' },
];

export const INVOICE_STATUSES_STRINGS = {
    PENDING: 'Pending',
    PAID: 'Paid',
    OVERDUE: 'Overdue'
};

export const INVOICE_STATUSES_TO_CLASS_NAME_MAP = {
    [INVOICE_STATUSES_STRINGS.PENDING]: "pending",
    [INVOICE_STATUSES_STRINGS.PAID]: "paid",
    [INVOICE_STATUSES_STRINGS.OVERDUE]: "overdue",
}
