import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchContracts } from 'actions/contractActions';
import { fetchAgencyEvrotrustInfo, fetchAgencyName, fetchAgencySettings, fetchIsAgencyEvrotrustIntegrated } from 'actions/agencyActions';

import { getAgencyBasicInfo, getAllContracts, getClientId, getDisplayMode, getIsAgencyEvrotrustIntegrated } from 'reducers';

import ContractsTable from './ContractsTable';

import { ROLES } from 'constants/userConstants';

import './Contracts.scss';

const Contracts = ({
    userId,
    isAdmin,
    agencyId,
    contractTypes,
    fetchContracts,
    fetchAgencyName,
    receiverRole,
    fetchIsAgencyEvrotrustIntegrated,
    fetchAgencySettings,
    fetchAgencyEvrotrustInfo,
    isEvrotrustIntegrated,
    departmentId,
    displayMode,
    clientId,
    agencyInfo,
}) => {
    useEffect(() => {
        fetchAgencyName(agencyId);
        fetchIsAgencyEvrotrustIntegrated(agencyId);

        if (displayMode === ROLES.ADMIN) {
            fetchAgencySettings(agencyId);
            fetchAgencyEvrotrustInfo(agencyId);
        }
    }, []);

    const id = displayMode === ROLES.CLIENT ? clientId : userId;
    const fetchAction = fetchContracts.bind(null, agencyId, id);

    return (
        <div className="contracts-wrapper">
            <ContractsTable
                agencyId={agencyId}
                userId={id}
                departmentId={departmentId}
                isAdmin={isAdmin}
                isEvrotrustIntegrated={isEvrotrustIntegrated}
                agencyInfo={agencyInfo}
                contractTypes={contractTypes}
                receiverRole={receiverRole}
                dataSelector={getAllContracts}
                fetchAction={fetchAction}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    clientId: getClientId(state),
    agencyInfo: getAgencyBasicInfo(state),
    isEvrotrustIntegrated: getIsAgencyEvrotrustIntegrated(state),
    displayMode: getDisplayMode(state),
});

const mapDispatchToProps = {
    fetchContracts,
    fetchAgencyName,
    fetchIsAgencyEvrotrustIntegrated,
    fetchAgencySettings,
    fetchAgencyEvrotrustInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
