/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';

import { generateSentryError } from 'actions/agencyActions'
import { getAgencyId } from 'reducers';

const TestSentry = ({
    generateSentryError,
    agencyId,
}) => {
    return (
        <div className="test-sentry">
            <button onClick={() => methodDoesNotExist()}>Frontend Error</button>
            <button onClick={() => generateSentryError(agencyId)}>Backend Error</button>
        </div>
    );
}

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state)
});

const mapDispatchToProps = {
    generateSentryError
};

export default connect(mapStateToProps, mapDispatchToProps)(TestSentry);
