import React from 'react';

import CoachingMentoringCard from './CoachingMentoringCard';

import './CoachingMentoring.scss';

const CoachingMentoring = ({
    coachingOpportunities,
}) => {

    return (
        <div className="coaching-mentoring-opportunities-wrapper">
            {coachingOpportunities.map(x => (
                <CoachingMentoringCard
                    key={x._id}
                    coachingOpportunity={x}
                />
            ))}
        </div>
    )
};

export default CoachingMentoring;
