import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import UnsupportedLocationDialog from 'components/Shared/UnsupportedLocationDialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { SUPPORTED_COUNTRIES_LIST } from 'constants/candidateConstants';

import './TaxResidencyDialog.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const TaxResidencyDialog = ({
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    onClose,
    touched,
    errors,
    open,
    countries,
    isCandidateMode,
}) => {
    const intl = useIntl();

    const onFormSubmit = () => {
        handleSubmit();
    };

    const reorderedCountries = [
        ...SUPPORTED_COUNTRIES_LIST,
        ...countries.filter((country) => !SUPPORTED_COUNTRIES_LIST.includes(country.name)).map((country) => country.name)
    ];

    const [isUnsupportedLocationDialogOpen, setIsUnsupportedLocationDialogOpen] = useState(false);

    const handleCloseUnsupportedLocationDialog = () => setIsUnsupportedLocationDialogOpen(false);

    return (
        <>
            <CommonFormikDialog
                className="tax-residency-dialog"
                open={open}
                onClose={onClose}
                dialogTitle={intl.formatMessage({ id: "edit-tax-residency" })}
                saveBtnText="save"
                discardBtnText="discard"
                onSave={onFormSubmit}
            >
                <form className="tax-residency-dialog-form" autoComplete="off">
                    <div className="form-row">
                        <div className="form-cols">
                            <div className="col-1of2">
                                <Autocomplete
                                    name="countryOfTax"
                                    value={intl.formatMessage({ id: convertStringToLanguageKey(values.countryOfTax) })}
                                    options={reorderedCountries}
                                    autoHighlight
                                    getOptionLabel={(option) => option}
                                    renderOption={(option) => <span>{intl.formatMessage({ id: convertStringToLanguageKey(option) })}</span>}
                                    onChange={(e, newValue) => {
                                        if (!SUPPORTED_COUNTRIES_LIST.includes(newValue) && isCandidateMode) {
                                            setIsUnsupportedLocationDialogOpen(true);
                                        }
                                        setFieldValue('countryOfTax', newValue)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={`${touched.countryOfTax && errors.countryOfTax ? "with-error" : "base-input"}`}
                                            value={values.countryOfTax}
                                            onBlur={handleBlur}
                                            label={intl.formatMessage({ id: "country" })}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                            error={touched.countryOfTax && Boolean(errors.countryOfTax)}
                                            helperText={touched.countryOfTax && errors.countryOfTax}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </CommonFormikDialog>
            <UnsupportedLocationDialog
                isOpen={isUnsupportedLocationDialogOpen}
                handleCloseDialog={handleCloseUnsupportedLocationDialog}
            />
        </>
    );
};

export default TaxResidencyDialog;
