import React, { useState, createContext } from 'react';
import { connect } from 'react-redux';
import SearchBar from './SearchBar';
import SearchDropdown from './SearchDropdown';

import { fetchSearchResultsPending } from 'actions/searchActions';
import { useDebounce } from 'utils/hooks';

export const SearchContext = createContext();
const { Provider } = SearchContext;

const Search = ({
    handleClick,
    handleClickTitle,
    requiredFields,
    alwaysShowSuggestions,
    isMobileHeaderSearch,
    closeMobileSearchInput,
    children,
    fetchSearchResultsPending
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showResultsDropdown, setShowResultsDropdown] = useState(false);
    const [loader, setLoader] = useState(true);
    const [inputBaseRef, setInputBaseRef] = useState({});

    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    const handleSearchTermChange = e => {
        if (e.target.value) {
            // without this focus when search icon is clicked 
            // the dropdown gets blurred and cannot be closed on user click outside of it
            // same with blur but when this is used in forward request 
            requiredFields
                ? inputBaseRef.focus()
                : inputBaseRef.blur();

            setSearchTerm(e.target.value);
            setShowResultsDropdown(true);
        } else {
            setSearchTerm('');
            setShowResultsDropdown(false);
            fetchSearchResultsPending();
        }

        if (e.target.value === debouncedSearchTerm) {
            setLoader(false);
        } else {
            setLoader(true);
        }
    }

    const handleFocus = () => {
        if (searchTerm || (alwaysShowSuggestions ? searchTerm === "" : false)) {
            setShowResultsDropdown(true);
        }
    }

    const handleLoader = (opt) => {
        setLoader(opt)
    }

    const handleBlur = () => {
        setShowResultsDropdown(false);
        if (isMobileHeaderSearch) {
            closeMobileSearchInput();
        }
    }

    const context = {
        handleFocus,
        handleBlur,
        setInputBaseRef,
        handleSearchTermChange,
        searchTerm,
        handleClick,
        requiredFields,
        handleClickTitle,
        alwaysShowSuggestions,
        isMobileHeaderSearch,
        debouncedSearchTerm,
        showResultsDropdown,
        handleLoader,
        loader,
    }

    return (
        <Provider value={context}>
            <div className={isMobileHeaderSearch ? 'search-wrapper header-mobile-search' : 'search-wrapper'}>
                <SearchBar />
                {children}
            </div>
        </Provider>
    );
}


Search.SearchDropdown = SearchDropdown;

const mapDispatchToProps = {
    fetchSearchResultsPending
};

export default connect(null, mapDispatchToProps)(Search);
