import React, { createContext } from 'react';

import Typography from '@material-ui/core/Typography';

import LeftAlignedActions from './LeftAlignedActions';
import RightAlignedActions from './RightAlignedActions';

import './SectionActionsWrapper.scss';

export const SectionActionsWrapperContext = createContext();
const { Provider } = SectionActionsWrapperContext;


const SectionActionsWrapper = ({
    children,
}) => {
    const context = {};

    return (
        <div className="section-actions-wrapper">
            <Provider value={context}>
                {children}
            </Provider>
        </div>
    );
};

const SectionTitle = ({
    sectionTitle,
}) => {
    return (
        <Typography variant="h1">{sectionTitle}</Typography>
    );
};

SectionActionsWrapper.SectionTitle = SectionTitle;
SectionActionsWrapper.LeftAlignedActions = LeftAlignedActions;
SectionActionsWrapper.RightAlignedActions = RightAlignedActions;

export default SectionActionsWrapper;
