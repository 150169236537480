import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { fetchAgencyIndexedJobs } from 'actions/jobOpportunityActions';
import {
    addJobRecommendationRating,
    updateJobRecommendationRating,
    deleteJobRecommendationRating
} from 'actions/jobRecommendationRatingActions';

import { parseQueryString, updateQuery } from 'utils/helpers';
import { getSelectedTab } from './JobsUtils';
import { logEvent } from 'utils/amplitude';

import {
    getJobOpportunities,
    getJobOpportunitiesListDataFetchState,
    getAgencyRouteName,
    getIsInitialJourneyCompleted,
    getMatchingJobOpportunities
} from 'reducers';

import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import Button from '@material-ui/core/Button';
import JobCard from './JobCard';
import NothingToShow from 'components/NothingToShow';
import { CircularProgress } from '@material-ui/core';

import { RECOMMENDED_JOBS, ALL_JOBS } from './JobsConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { ROLES } from 'constants/userConstants';

import './Jobs.scss';

const Jobs = ({
    agencyId,
    userId,
    fetchAgencyIndexedJobs,
    addJobRecommendationRating,
    updateJobRecommendationRating,
    deleteJobRecommendationRating,
    jobOpportunities,
    match,
    matchingJobOpportunities,
    agencyRouteName,
    isInitialJourneyCompleted,
    dataFetchState,
    hasPreviousStepButton = true,
    additionalHeadingText,
    additionalSubHeadingText,
    isFromCandidateJourney,
}) => {
    const history = useHistory();

    const [selectedJobsType, setSelectedJobsType] = useState(history.location.search.includes(ALL_JOBS) ? ALL_JOBS : RECOMMENDED_JOBS);

    const jobs = {
        [RECOMMENDED_JOBS]: matchingJobOpportunities,
        [ALL_JOBS]: jobOpportunities,
    }

    useEffect(() => {
        if (!dataFetchState.isDataFetched) {
            fetchAgencyIndexedJobs(agencyId, userId).then(data => !data && selectAllJobsTab());
        }
    }, []);

    useEffect(() => {
        const queryString = history.location.search;
        const parsedQuery = parseQueryString(queryString);

        getSelectedTab(parsedQuery.selectedTab, setSelectedJobsType);
    }, [history.location.search]);

    const jobOpportunityDetailsLink = (id) => {
        const queryString = history.location.search;
        const jobPrefix = match?.path?.includes('create-profile') ? '/create-profile' : '';

        const path = match?.path?.includes('job-opportunities')
            ? `/${agencyRouteName}/job-opportunities/${id}${queryString}`
            : `/${agencyRouteName}${jobPrefix}/jobs/${id}${queryString}`;

        return path;
    }

    const handleBackToFinishProcess = () => {
        history.push({
            pathname: `/${agencyRouteName}/create-profile`,
            state: { comingFrom: 'jobs' }
        });
    }

    const selectAllJobsTab = () => {
        const updatedQuery = updateQuery(history.location.search, { selectedTab: ALL_JOBS });

        history.replace({ pathname: history.location.pathname, search: updatedQuery, state: { isFromCandidateJourney } });
    }

    const selectRecommendedJobsTab = () => {
        const updatedQuery = updateQuery(history.location.search, { selectedTab: RECOMMENDED_JOBS });

        history.replace({ pathname: history.location.pathname, search: updatedQuery, state: { isFromCandidateJourney } });
    }

    const handleJobRecommendationRatingClick = async (event, job, isGoodRecommendation) => {
        event.preventDefault();

        const jobRecommendationRatingData = {
            userId,
            isGoodRecommendation,
            jobOpportunityId: job._id,
            score: job.score,
            categoriesScores: job.categoriesScores,
        }

        if (!job.recommendationRating?._id) {
            await addJobRecommendationRating(agencyId, jobRecommendationRatingData);
            logEvent(AMPLITUDE_EVENT_TYPES.RATE_JOB_RECOMMENDATION, [ROLES.CANDIDATE], { type: isGoodRecommendation ? 'upvote' : 'downvote' })
        } else if (job.recommendationRating?._id && job.recommendationRating?.isGoodRecommendation !== isGoodRecommendation) {
            await updateJobRecommendationRating(agencyId, job.recommendationRating._id, jobRecommendationRatingData);
            logEvent(AMPLITUDE_EVENT_TYPES.RATE_JOB_RECOMMENDATION, [ROLES.CANDIDATE], { type: isGoodRecommendation ? 'upvote' : 'downvote' })
        } else {
            await deleteJobRecommendationRating(agencyId, job.recommendationRating._id, job._id);
        }
    };

    return (
        <div className={`recommended-jobs-wrapper${isMobile ? ' mobile' : ''}`}>
            <div className="back-button-wrapper">
                {
                    !isInitialJourneyCompleted && hasPreviousStepButton &&
                    <div className="back-button-container">
                        <PreviousButton text="Finish process" handleClick={handleBackToFinishProcess} />
                    </div>
                }
            </div>
            {dataFetchState?.isDataFetching
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <>
                    <div className="header">
                        <div className="tab-buttons-container">
                            <Button onClick={selectRecommendedJobsTab} className={"tab-button " + (selectedJobsType === RECOMMENDED_JOBS ? "button-selected" : 'button-unselected')} variant="outlined">
                                Recommended
                            </Button>
                            <Button onClick={selectAllJobsTab} className={"tab-button " + (selectedJobsType === ALL_JOBS ? "button-selected" : "button-unselected")} variant="outlined">
                                All Jobs
                            </Button>
                        </div>
                        {additionalHeadingText && selectedJobsType === RECOMMENDED_JOBS && <p className="additional-heading-text">{additionalHeadingText}</p>}
                        <div className="section-description-container">
                            {additionalSubHeadingText && selectedJobsType === RECOMMENDED_JOBS && <p className="additional-sub-heading-text">{additionalSubHeadingText}</p>}
                            {selectedJobsType === RECOMMENDED_JOBS && <p className="section-description">This list of selected jobs is tailored to your skills and profile.</p>}
                        </div>
                    </div>
                    <div className="job-positions-wrapper">
                        {
                            jobs[selectedJobsType].length === 0
                                ? <NothingToShow items="jobs" />
                                : jobs[selectedJobsType].map((jobOpportunity, index) =>
                                    <JobCard
                                        key={jobOpportunity._id}
                                        jobOpportunity={jobOpportunity}
                                        jobOpportunityDetailsLink={jobOpportunityDetailsLink}
                                        selectedJobsType={selectedJobsType}
                                        handleJobRecommendationRatingClick={handleJobRecommendationRatingClick}
                                    />
                                )
                        }

                    </div>
                </>
            }
        </div >

    )
}

const mapStateToProps = state => ({
    jobOpportunities: getJobOpportunities(state),
    matchingJobOpportunities: getMatchingJobOpportunities(state),
    dataFetchState: getJobOpportunitiesListDataFetchState(state),
    agencyRouteName: getAgencyRouteName(state),
    isInitialJourneyCompleted: getIsInitialJourneyCompleted(state),
});

const mapDispatchToProps = {
    fetchAgencyIndexedJobs,
    addJobRecommendationRating,
    updateJobRecommendationRating,
    deleteJobRecommendationRating,
};

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
