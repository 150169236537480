import { React } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { getAgencyPendingHolidaysCount, getAgencyRouteName } from 'reducers';

import { Paper, Typography } from '@material-ui/core';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import Section from 'components/Shared/Section';
import NothingToShow from 'components/NothingToShow';

import './TimeOffRequestsDashboard.scss';

const TimeOffRequestsDashboard = ({
    agencyRouteName,
    timeOffRequestsCount
}) => {
    const intl = useIntl();
    const history = useHistory();

    const handleViewButtonClick = () => history.push(`/${agencyRouteName}/team/holidays`);
    const getBannerTitleText = () => {
        let translatedText;

        if (timeOffRequestsCount === 1) {
            translatedText = intl.formatMessage({ id: "time-off-requests-banner-title-singular" });
        } else {
            translatedText = intl.formatMessage({ id: "time-off-requests-banner-title-plural" });
            translatedText = translatedText.replace("{}", timeOffRequestsCount);
        }

        return translatedText;
    }

    return (
        <Section title={intl.formatMessage({ id: "time-off-requests-section-title" })}>
            {timeOffRequestsCount === 0 ?
                <NothingToShow items="pending timeoff requests" /> :
                <Paper className="time-off-requests-paper">
                    <div className="flex-container">
                        <div className="flex-box">
                            <Typography className="bold-text" variant="h2">{getBannerTitleText()}</Typography>
                            <Typography variant="h2">{intl.formatMessage({ id: "time-off-requests-banner-text" })}</Typography>
                        </div>
                        <div className="flex-box">
                            <PrimaryButton handleClick={handleViewButtonClick} text={intl.formatMessage({ id: "view-button-text" })} />
                        </div>
                    </div>
                </Paper>
            }
        </Section>
    )
};

const mapStateToProps = (state) => ({
    agencyRouteName: getAgencyRouteName(state),
    timeOffRequestsCount: getAgencyPendingHolidaysCount(state),
});

export default connect(mapStateToProps, null)(TimeOffRequestsDashboard);
