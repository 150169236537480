import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { ErrorMessage } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { getStaticLanguages } from 'reducers';

import { LANGUAGE_LEVELS } from '../LanguageDialog/LanguageDialogConstants';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const LanguageDialogView = ({
    languages,
    values,
    errors,
    touched,
    handleChange,
}) => {
    const intl = useIntl();

    return (
        <form className="language-dialog-view form" autoComplete="off">
            <div className="language-name-field">
                <FormControl fullWidth variant="outlined">
                    <InputLabel>{intl.formatMessage({ id: "language" })}</InputLabel>
                    <Select
                        name="name"
                        label={intl.formatMessage({ id: "language" })}
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.name}
                        error={errors.name && touched.name}
                        onChange={handleChange}
                    >
                        {languages.map((language, i) => (
                            <MenuItem key={i} value={language}>
                                {intl.formatMessage({ id: convertStringToLanguageKey(language) })}
                            </MenuItem>
                        ))}
                    </Select>
                    <ErrorMessage className="invalid-field-message" name="name" component="div" />
                </FormControl>
            </div>
            <div className="language-level-field">
                <FormControl fullWidth variant="outlined">
                    <InputLabel>{intl.formatMessage({ id: "proficiency" })}</InputLabel>
                    <Select
                        name="level"
                        label={intl.formatMessage({ id: "proficiency" })}
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.level}
                        error={errors.level && touched.level}
                        onChange={handleChange}
                    >
                        {LANGUAGE_LEVELS.map((level, i) => (
                            <MenuItem key={i} value={level}>
                                {intl.formatMessage({ id: convertStringToLanguageKey(level) })}
                            </MenuItem>
                        ))}
                    </Select>
                    <ErrorMessage className="invalid-field-message" name="level" component="div" />

                </FormControl>
            </div>
        </form>
    );
};

const mapStateToProps = state => ({
    languages: getStaticLanguages(state),
});

export default connect(mapStateToProps)(LanguageDialogView);
