import React from 'react';
import { useIntl } from 'react-intl';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { ErrorMessage } from 'formik';

import { SUPPLIER_STATUSES } from 'constants/supplierConstants';
import '../CompanyInformationAccountManagerForm.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const CompanyInformationAccountManagerFormView = ({
    values,
    errors,
    touched,
    handleChange,
    handleShowHeaderButtons,
    accountManagers,
}) => {
    const intl = useIntl();

    return (
        <form className="company-account-manager-form" autoComplete="off">
            <div className="company-information-supplier-form-field">
                <TextField
                    select
                    required
                    name="accountManager"
                    value={values.accountManager}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    label={intl.formatMessage({ id: "account-manager" })}
                    error={errors.accountManager && touched.accountManager}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                >
                    {accountManagers.map((accountManager, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={accountManager._id}
                        >
                            {`${accountManager.firstName} ${accountManager.lastName}`}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="accountManager" component="div" className="invalid-field-message" />
            </div>

            <div className="company-information-supplier-form-field status-field">
                <TextField
                    select
                    required
                    name="status"
                    value={values.status}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    label={intl.formatMessage({ id: "status" })}
                    error={errors.status && touched.status}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                >
                    {SUPPLIER_STATUSES.map((status, i) => (
                        <MenuItem
                            native="true"
                            key={status}
                            value={status}
                        >
                            {intl.formatMessage({ id: convertStringToLanguageKey(status) })}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="status" component="div" className="invalid-field-message" />
            </div>
        </form>
    )
};

export default CompanyInformationAccountManagerFormView;
