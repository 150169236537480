import React from 'react';
import { connect } from 'react-redux';
import { getClientId, isClientMode } from 'reducers';

import ClientProjects from '../Client/Projects';

const CurrentProjects = ({
    agencyId,
    clientId,
    isClientMode,
    history
}) => {
    return (
        <ClientProjects
            clientId={clientId}
            agencyId={agencyId}
            isClientMode={isClientMode}
            history={history}
        />
    );
};

const mapStateToProps = state => ({
    isClientMode: isClientMode(state),
    clientId: getClientId(state),
});

export default connect(mapStateToProps)(CurrentProjects);
