import React from 'react';
import { connect } from 'react-redux';

import { getMatchingJobOpportunities } from 'reducers';

import MatchingJobCard from './MatchingJobCard';
import NothingToShow from 'components/NothingToShow';

const MatchingJobsRedux = ({
    agencyId,
    isAdmin,
    onEditClick,
    handleOpenDeleteDialog,
    updateJob,
    updateJobOpportunityPrivacy,
    setClosePopoverFunction,
    matchingJobs,
}) => {
    return (
        matchingJobs?.length > 0
            ? matchingJobs.map((job, i) => (
                <MatchingJobCard
                    key={job._id}
                    agencyId={agencyId}
                    isAdmin={isAdmin}
                    job={job}
                    onEditClick={onEditClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog.bind(null, job)}
                    updateJob={updateJob}
                    updateJobOpportunityPrivacy={updateJobOpportunityPrivacy}
                    setClosePopoverFunction={setClosePopoverFunction}
                >
                    <MatchingJobCard.MatchingJobTypeLabel />
                    <MatchingJobCard.MatchingJobLocationLabel />
                    <MatchingJobCard.MatchingJobMainInfo />
                    <MatchingJobCard.MatchingJobProfiles />
                    <MatchingJobCard.MatchingJobFooter />
                </MatchingJobCard>
            ))
            : <NothingToShow items="jobs" />
    )
};

const mapStateToProps = (state, ownProps) => ({
    matchingJobs: getMatchingJobOpportunities(state, ownProps.filters),
});

export default connect(mapStateToProps)(MatchingJobsRedux);
