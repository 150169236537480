import React from 'react';

import MobilePageWithoutJob from './MobilePageWithoutJob';
import MobilePageWithJob from './MobilePageWithJob';

import './StartCandidateJourneyMobilePage.scss';

const StartCandidateJourneyMobilePage = ({
    getCandidateJourneyDuration,
    START_JOURNEY_STEPS,
    handleStartCandidateJourney,
    isWithOpportunity,
    agencyName,
    jobOpportunity,
    showContent,
}) => (showContent &&
    <>
        { isWithOpportunity
            ?
                <MobilePageWithJob
                    getCandidateJourneyDuration={getCandidateJourneyDuration}
                    START_JOURNEY_STEPS={START_JOURNEY_STEPS}
                    handleStartCandidateJourney={handleStartCandidateJourney}
                    agencyName={agencyName}
                    jobOpportunity={jobOpportunity}
                />
            :
                <MobilePageWithoutJob
                    getCandidateJourneyDuration={getCandidateJourneyDuration}
                    START_JOURNEY_STEPS={START_JOURNEY_STEPS}
                    handleStartCandidateJourney={handleStartCandidateJourney}
                    agencyName={agencyName}
                />
        }
    </>
);

export default StartCandidateJourneyMobilePage;
