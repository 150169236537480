import React from 'react';
import { connect } from 'react-redux';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    showApplicationLoader,
    hideApplicationLoader,
} from 'actions/applicationLoaderActions';

import { isAppLoaderActive } from 'reducers';
import { loaderSize } from './ApplicationLoaderConstants';

import './ApplicationLoader.scss';

const ApplicationLoader = ({
    isActive,
}) => {
    return (
        <Backdrop className='backdrop' open={isActive} >
            <CircularProgress size={loaderSize} disableShrink />
        </Backdrop>
    );
};

const mapStateToProps = state => ({
    isActive: isAppLoaderActive(state),
});

const mapDispatchToProps = {
    showApplicationLoader,
    hideApplicationLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLoader);
