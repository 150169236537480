import React from 'react';
import { connect } from 'react-redux';

import { getEmployeeBasicInfo } from 'reducers';

import { exportCV } from 'actions/employeeActions';
import { exportSupplierEmployeesCVs } from 'actions/supplierActions';
import { exportCandidateCV } from 'actions/candidateActions';

import ApplicationInterviewActions from '../ApplicationInterviewActions';
import PaperCv from 'components/Shared/PaperCv';
import PrimaryExportButton from 'components/Shared/Buttons/PrimaryExportButton';

import { ROLES } from 'constants/userConstants';

import './ApplicationCvInformation.scss';

const ApplicationCvInformation = ({
    agencyId,
    candidateId,
    applicationId,
    agencyName,
    interviewInvitation,
    cvInformation,
    companyLogoUrl,
    basicInfo,
    exportCandidateCV,
    exportCV,
    exportSupplierEmployeesCVs,
}) => {
    const exportCVsFunctions = {
        [ROLES.CANDIDATE]: exportCandidateCV,
        [ROLES.EMPLOYEE]: exportCV,
        [ROLES.ADMIN]: exportCV,
        [ROLES.SUPPLIER_EMPLOYEE]: exportSupplierEmployeesCVs,
        [ROLES.SUPPLIER_ADMIN]: exportSupplierEmployeesCVs,
    };

    const handleExportCV = () => {
        const user = {
            _id: candidateId,
            firstName: basicInfo.firstName,
            lastName: basicInfo.lastName
        };

        exportCVsFunctions[basicInfo.role]([user], agencyId);
    };

    return (
        <div className="application-cv-information-wrapper">
            <div className="cv-container">
                <PrimaryExportButton
                    text='Export'
                    className="export-cv-btn"
                    handleClick={handleExportCV}
                />
                <PaperCv
                    cvInformation={cvInformation}
                    companyLogoUrl={companyLogoUrl}
                >
                    <PaperCv.ProfileInformation />
                    <PaperCv.ProfileSummary />
                    <PaperCv.Skills />
                    <PaperCv.Education />
                    <PaperCv.Experience />
                    <PaperCv.Certificates />
                    <PaperCv.Languages />
                </PaperCv>
            </div>
            <ApplicationInterviewActions
                agencyId={agencyId}
                applicationId={applicationId}
                agencyName={agencyName}
                interviewInvitation={interviewInvitation}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    basicInfo: getEmployeeBasicInfo(state),
});

const mapDispatchToProps = {
    exportCandidateCV,
    exportCV,
    exportSupplierEmployeesCVs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationCvInformation);
