import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Avatar, Divider, Typography } from '@material-ui/core';
import Jobs from 'components/JobOpportunities/Jobs';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { getIsInitialJourneyCompleted, getMenuInformation } from 'reducers';
import { getStateAgencyName } from 'utils/helpers';

import './CandidateJourneyCompletionThankYouScreen.scss';

const CandidateJourneyCompletionThankYouScreen = ({ mainMenuInformation, isInitialJourneyCompleted, ...props }) => {
    const history = useHistory();
    const isFromCandidateJourney = history.location.state?.isFromCandidateJourney;

    useEffect(() => {
        if (!isInitialJourneyCompleted) {
            history.push(`/${getStateAgencyName()}/create-profile`);
            return;
        }

        if (!isFromCandidateJourney) {
            history.push(`/${getStateAgencyName()}/profile`);
            
        }
    }, []);

    return (
        <>
            <div className="journey-completion-screen-wrapper">
                <Avatar alt="Company logo" className="company-logo" src={mainMenuInformation.companyLogoUrl} />
                <div className="journey-completion-screen-container">
                    <div className="thank-you-container">
                        <div className="thank-you-message-container">
                            <Typography className="thank-you-message">
                                Thank you for completing your profile
                            </Typography>
                        </div>
                        <PrimaryButton
                            text="View Profile"
                            component={Link}
                            to={`/${getStateAgencyName()}/profile`}
                        />
                    </div>
                    <Divider className="divider" />
                    <div>
                        <Jobs
                            additionalSubHeadingText={'Hey! 👋'}
                            additionalHeadingText={'Featured jobs on WIA'}
                            hasPreviousStepButton={false}
                            {...props}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    mainMenuInformation: getMenuInformation(state),
    isInitialJourneyCompleted: getIsInitialJourneyCompleted(state),
});

export default connect(mapStateToProps)(CandidateJourneyCompletionThankYouScreen);
