import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getLanguage } from 'reducers';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { getBannerContent } from './EmployeeStatusBannerConstants';

import './EmployeeStatusBanner.scss';

const EmployeeStatusBanner = ({
    selectedEmployee,
    openEditStatusDialog,
    selectedLanguage,
}) => {
    const intl = useIntl();
    const { TEXT, ICONS } = getBannerContent(selectedEmployee.occupationStatus, selectedLanguage);

    return (
        <>
            {TEXT ?
                <div className="employee-status-banner-wrapper">
                    <div className="employee-status-text-wrapper">
                        {TEXT}
                    </div>
                    <div className="employee-status-actions-wrapper">
                        <PrimaryButton
                            className="light"
                            text={intl.formatMessage({ id: "change-status" })}
                            handleClick={openEditStatusDialog}
                        />
                    </div>
                    <div className="employee-status-icons-wrapper">
                        {ICONS}
                    </div>
                </div>
                : null
            }
        </>
    );
};

const mapStateToProps = (state) => ({
    selectedLanguage: getLanguage(state),
});

export default connect(mapStateToProps)(EmployeeStatusBanner);
