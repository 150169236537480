import React, { Fragment } from 'react';

import './CategorySkillsDropdown.scss';

const CategorySkillsDropdown = ({
    skillSuggestions,
    currentInput,
    showResultsDropdown,
    onSelect,
    addNewSkill,
}) => {
    const showAddNewSkillButton = !skillSuggestions.some(x => x.title.toLowerCase() === currentInput.toLowerCase());

    return (
        <Fragment>
            {showResultsDropdown &&
                <div className="skills-dropdown-container" onMouseDown={(e) => e.preventDefault()} >
                    {skillSuggestions.map((skill, i) => {
                        return (
                            <div
                                key={i}
                                className="skill"
                                onClick={() => onSelect(skill.title)}>
                                {skill.title}
                            </div>
                        )
                    })}
                    {showAddNewSkillButton && <div
                        className="add-skill-container"
                        onClick={() => addNewSkill(currentInput)}
                    >
                        Add skill - "{currentInput}"
                    </div>}
                </div>}
        </Fragment>
    );
};

export default CategorySkillsDropdown;
