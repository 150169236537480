import React from 'react';
import { useIntl } from 'react-intl';

import MatchingProfilesTable from './MatchingProfilesTable';
import NothingToShow from 'components/NothingToShow';

const MatchingConsultantsView = ({
    profiles,
    handleSendToClientClick,
    totalProfilesCount,
}) => {
    const intl = useIntl();

    return (
        <>
            {!totalProfilesCount >= 0 &&
                <>
                    <MatchingProfilesTable
                        title={intl.formatMessage({ id: "agency" })}
                        matchingProfiles={profiles?.employees}
                        handleSendToClientClick={handleSendToClientClick}
                    />
                    <MatchingProfilesTable
                        title={intl.formatMessage({ id: "partner" })}
                        matchingProfiles={profiles?.partnerEmployees}
                        handleSendToClientClick={handleSendToClientClick}
                    />
                    <MatchingProfilesTable
                        title={intl.formatMessage({ id: "candidate" })}
                        matchingProfiles={profiles?.candidates}
                        handleSendToClientClick={handleSendToClientClick}
                    />
                </>
            }

            {totalProfilesCount === 0 && <NothingToShow items="profiles" />}
        </>
    )
};

export default MatchingConsultantsView;
