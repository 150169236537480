import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getAgencyId, getDisplayName, getProfileCreationStepsCount, isTermsAndConditions } from 'reducers';

import { fetchUserInfo } from 'actions/agencyProfileActions';

import ProfileCreationCard from 'components/Shared/ProfileCreationCard';
import CompletionProgressBar from 'components/Shared/CompletionProgressBar';
import ReviewTermsAndConditions from './ReviewTermsAndConditions';
import TellUsAboutYourself from './TellUsAboutYourself';
import TellUsAboutYourCompany from './TellUsAboutYourCompany';

import { AGENCY_PROFILE_CREATION_STEPS_TO_COMPLETE } from 'constants/agencyConstants';


const CreateAgencyProfile = ({
    agencyId,
    displayName,
    isTermsAndConditionsAccepted,
    profileCreationStepsCount,
    fetchUserInfo,
}) => {
    useEffect(() => {
        fetchUserInfo(agencyId);
    }, []);

    const getStepContent = () => {
        if (!isTermsAndConditionsAccepted) {
            return {
                title: 'Review the terms and conditions',
                content: <ReviewTermsAndConditions />
            }
        }

        switch (profileCreationStepsCount) {
            case 0:
                return {
                    title: displayName ? `Tell us about yourself, ${displayName.split(' ')[0]}`: 'Tell us about yourself',
                    content: <TellUsAboutYourself />,
                }
            case 1:
                return {
                    title: 'Tell us more about your company',
                    content: <TellUsAboutYourCompany />,
                }
            default:
                return {
                    title: '',
                    content: null,
                };
        }
    };

    const percentage = (profileCreationStepsCount + isTermsAndConditionsAccepted) / AGENCY_PROFILE_CREATION_STEPS_TO_COMPLETE * 100;
    const { title, content } = getStepContent();

    return (
        <ProfileCreationCard>
             <ProfileCreationCard.Header />
            <CompletionProgressBar percentage={percentage} />
            <ProfileCreationCard.ContentWrapper>
                <ProfileCreationCard.ContentTitle>
                    {title}
                </ProfileCreationCard.ContentTitle>
                <ProfileCreationCard.ContentDivider />
                {content}
            </ProfileCreationCard.ContentWrapper>
        </ProfileCreationCard>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    displayName: getDisplayName(state),
    isTermsAndConditionsAccepted: isTermsAndConditions(state),
    profileCreationStepsCount: getProfileCreationStepsCount(state),
});

const mapDispatchToProps = {
    fetchUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAgencyProfile);
