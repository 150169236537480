import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getLanguage } from 'reducers';

import { Tooltip } from '@material-ui/core';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import { BULGARIAN_LABEL } from 'constants/languages';
import { JOB_OPPORTUNITIES_TYPES } from 'constants/jobOpportunitiesConstants';

import './JobTypeLabel.scss';

const JobTypeLabel = ({
    jobType = '',
    selectedLanguage,
}) => {
    const intl = useIntl();

    const getTooltipTitle = () => {
        if (selectedLanguage === BULGARIAN_LABEL) {
            return `Това е обява на ${jobType === JOB_OPPORTUNITIES_TYPES.CONTRACT ? 'граждански' : 'трудов'} договор`
        } else {
            return `This is a ${jobType === JOB_OPPORTUNITIES_TYPES.CONTRACT ? 'contract' : 'full-time'} job opportunity`
        }
    };

    return (
        <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={getTooltipTitle()}>
            <div className={`job-type-label-${jobType?.toLowerCase()}`} >
                <span className="job-type-text">{intl.formatMessage({ id: convertStringToLanguageKey(jobType) })}</span>
            </div>
        </Tooltip>
    )
};

const mapStateToProps = (state) => ({
    selectedLanguage: getLanguage(state),
});

export default connect(mapStateToProps)(JobTypeLabel);
