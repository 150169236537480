import React from 'react';

import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import StoreSharpIcon from '@material-ui/icons/StoreSharp';
import { ReactComponent as TalentIcon } from 'assets/talent-icon.svg';
import { ReactComponent as HottestTalentsIcon } from 'assets/hottest-talents-icon.svg';

import { ReactComponent as DashboardMenuIcon } from 'assets/dashboard-menu-icon.svg';
import { ReactComponent as TalentSearchMenuIcon } from 'assets/talent-search-menu-icon.svg';
import { ReactComponent as TeamMenuIcon } from 'assets/team-menu-icon.svg';
import { ReactComponent as TimeManagementMenuIcon } from 'assets/time-tracking-menu-icon.svg';
import { ReactComponent as HolidaysMenuIcon } from 'assets/holidays-menu-item.svg';
import { ReactComponent as ExpenseReportsMenuIcon } from 'assets/expense-reports-icon.svg';
import { ReactComponent as DocumentsMenuIcons } from 'assets/documents-menu-icon.svg';
import { ReactComponent as CandidatesMenuIcon } from 'assets/candidates-menu-icon.svg';
import { ReactComponent as ReportsMenuIcon } from 'assets/reports-main-menu-icon.svg';
import { ReactComponent as ClientsMenuIcon } from 'assets/clients-menu-icon.svg';
import { ReactComponent as JobRequestsMenuIcon } from 'assets/job-requests-menu-icon.svg';
import { ReactComponent as PartnersMenuIcon } from 'assets/partners-menu-icon.svg';
import { ReactComponent as RecruitersMenuIcon } from 'assets/recruiters-menu-item.svg';
import { ReactComponent as TasksMenuIcon } from 'assets/tasks-menu-item.svg';
import { ReactComponent as SettingsMenuIcon } from 'assets/settings-menu-icon.svg';


import { INITIAL_QUERY_STRING_JOBS_DASHBOARD } from 'components/JobsDashboard/JobsDashboardConstants';
import { INITIAL_QUERY_STRING_CLIENTS } from 'components/Clients/ClientsConstants';

export const getAdminMenuItems = agencyRouteName => [
	{
		TITLE: 'Dashboard',
		ICON: <DashboardMenuIcon />,
		ROUTE: `/${agencyRouteName}/dashboard`,
		LANG_KEY: 'menu-dashboard',
	},
	{
		TITLE: 'Talent',
		ICON: <TalentIcon className="talent-icon" />,
		ROUTE: `/${agencyRouteName}/hottest-talents`,
		LANG_KEY: 'menu-talent',
		ITEMS: [
			{ TEXT: 'Hottest Talent', ICON: <HottestTalentsIcon className="hottest-talents-icon" />, ENDPOINT: `/${agencyRouteName}/hottest-talents`, LANG_KEY: 'menu-hottest-talent', },
			{ TEXT: 'Search', ICON: <TalentSearchMenuIcon />, ENDPOINT: `/${agencyRouteName}/explore-talents?profileType=Candidate,Partner,Employee`, LANG_KEY: 'menu-talent-search', },
		],
	},
	{
		TITLE: 'Team',
		ICON: <TeamMenuIcon />,
		ROUTE: `/${agencyRouteName}/team`,
		LANG_KEY: 'menu-team',
		ITEMS: [
			{ TEXT: 'Employees', ICON: <PersonSharpIcon />, ENDPOINT: `/${agencyRouteName}/team/people`, LANG_KEY: 'menu-team-employees', },
			{
				TEXT: 'Time Management',
				ICON: <TimeManagementMenuIcon />,
				ENDPOINT: `/${agencyRouteName}/team/time-management`,
				LANG_KEY: 'menu-team-time-management',
			},
			{
				TEXT: 'Holidays',
				ICON: <HolidaysMenuIcon />,
				ENDPOINT: `/${agencyRouteName}/team/holidays`,
				LANG_KEY: 'menu-team-holidays',
			},
			{
				TEXT: 'Expense Reports',
				ICON: <ExpenseReportsMenuIcon />,
				ENDPOINT: `/${agencyRouteName}/team/expense-reports`,
				LANG_KEY: 'menu-team-expense-reports',
			},
			{
				TEXT: 'Documents',
				ICON: <DocumentsMenuIcons />,
				ENDPOINT: `/${agencyRouteName}/team/documents`,
				LANG_KEY: 'menu-team-documents',
			},
		],
	},
	{
		TITLE: 'Candidates',
		ICON: <CandidatesMenuIcon />,
		ROUTE: `/${agencyRouteName}/candidates`,
		LANG_KEY: 'menu-candidates',
	},
	{
		TITLE: 'Reports',
		ICON: <ReportsMenuIcon />,
		ROUTE: `/${agencyRouteName}/reports`,
		LANG_KEY: 'menu-reports',
	},
	{
		TITLE: 'Clients',
		ICON: <ClientsMenuIcon />,
		ROUTE: `/${agencyRouteName}/clients`,
		LANG_KEY: 'menu-clients',
		ITEMS: [
			{
				TEXT: 'Accounts',
				ICON: <AccountCircleSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/clients${INITIAL_QUERY_STRING_CLIENTS}`,
				LANG_KEY: 'menu-clients-accounts',
			},
			{
				TEXT: 'Job Requests',
				ICON: <JobRequestsMenuIcon />,
				ENDPOINT: `/${agencyRouteName}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`,
				LANG_KEY: 'menu-clients-job-requests',
			},
		],
	},
	{
		TITLE: 'Partners',
		ICON: <PartnersMenuIcon />,
		ROUTE: `/${agencyRouteName}/partners`,
		LANG_KEY: 'menu-partners',
		ITEMS: [
			{
				TEXT: 'Companies',
				ICON: <StoreSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/partners`,
				LANG_KEY: 'menu-partners-companies',
			},
			{
				TEXT: 'Recruiters',
				ICON: <RecruitersMenuIcon />,
				ENDPOINT: `/${agencyRouteName}/recruiters`,
				LANG_KEY: 'menu-partners-recruiters',
			},
		],
	},
	{
		TITLE: 'Tasks',
		ICON: <TasksMenuIcon />,
		ROUTE: `/${agencyRouteName}/tasks`,
		LANG_KEY: 'menu-tasks',
	},
	{
		TITLE: 'Settings',
		ICON: <SettingsMenuIcon />,
		ROUTE: `/${agencyRouteName}/settings`,
		LANG_KEY: 'menu-settings',
	},
];

export const getAdminMenuItemsSaas  = agencyRouteName => [
	{
		TITLE: 'Dashboard',
		ICON: <DashboardMenuIcon />,
		ROUTE: `/${agencyRouteName}/dashboard`,
		LANG_KEY: 'menu-dashboard',
	},
	{
		TITLE: 'Talent',
		ICON: <TalentIcon className="talent-icon" />,
		ROUTE: `/${agencyRouteName}/hottest-talents`,
		LANG_KEY: 'menu-talent',
		ITEMS: [
			{ TEXT: 'Hottest Talent', ICON: <HottestTalentsIcon className="hottest-talents-icon" />, ENDPOINT: `/${agencyRouteName}/hottest-talents`, LANG_KEY: 'menu-hottest-talent',  },
			{ TEXT: 'Search', ICON: <TalentSearchMenuIcon />, ENDPOINT: `/${agencyRouteName}/explore-talents?profileType=Candidate,Partner,Employee`, LANG_KEY: 'menu-talent-search', },
		],
	},
	{
		TITLE: 'Team',
		ICON: <TeamMenuIcon />,
		ROUTE: `/${agencyRouteName}/team`,
		LANG_KEY: 'menu-team',
		ITEMS: [
			{ TEXT: 'Employees', ICON: <PersonSharpIcon />, ENDPOINT: `/${agencyRouteName}/team/people`, LANG_KEY: 'menu-team-employees', },
			{
				TEXT: 'Time Management',
				ICON: <TimeManagementMenuIcon />,
				ENDPOINT: `/${agencyRouteName}/team/time-management`,
				LANG_KEY: 'menu-team-time-management',
			},
			{
				TEXT: 'Holidays',
				ICON: <HolidaysMenuIcon />,
				ENDPOINT: `/${agencyRouteName}/team/holidays`,
				LANG_KEY: 'menu-team-holidays',
			},
			{
				TEXT: 'Expense Reports',
				ICON: <ExpenseReportsMenuIcon />,
				ENDPOINT: `/${agencyRouteName}/team/expense-reports`,
				LANG_KEY: 'menu-team-expense-reports',
			},
			{
				TEXT: 'Documents',
				ICON: <DocumentsMenuIcons />,
				ENDPOINT: `/${agencyRouteName}/team/documents`,
				LANG_KEY: 'menu-team-documents',
			},
		],
	},
	{
		TITLE: 'Candidates',
		ICON: <CandidatesMenuIcon />,
		ROUTE: `/${agencyRouteName}/candidates`,
		LANG_KEY: 'menu-candidates',
	},
	{
		TITLE: 'Reports',
		ICON: <ReportsMenuIcon />,
		ROUTE: `/${agencyRouteName}/reports`,
		LANG_KEY: 'menu-reports',
	},
	{
		TITLE: 'Clients',
		ICON: <ClientsMenuIcon />,
		ROUTE: `/${agencyRouteName}/clients`,
		LANG_KEY: 'menu-clients',
		ITEMS: [
			{
				TEXT: 'Accounts',
				ICON: <AccountCircleSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/clients${INITIAL_QUERY_STRING_CLIENTS}`,
				LANG_KEY: 'menu-clients-accounts',
			},
			{
				TEXT: 'Job Requests',
				ICON: <JobRequestsMenuIcon />,
				ENDPOINT: `/${agencyRouteName}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`,
				LANG_KEY: 'menu-clients-job-requests',
			},
		],
	},
	{
		TITLE: 'Partners',
		ICON: <PartnersMenuIcon />,
		ROUTE: `/${agencyRouteName}/partners`,
		LANG_KEY: 'menu-partners',
		ITEMS: [
			{
				TEXT: 'Companies',
				ICON: <StoreSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/partners`,
				LANG_KEY: 'menu-partners-companies',
			},
			{
				TEXT: 'Recruiters',
				ICON: <RecruitersMenuIcon />,
				ENDPOINT: `/${agencyRouteName}/recruiters`,
				LANG_KEY: 'menu-partners-recruiters',
			},
		],
	},
	{
		TITLE: 'Tasks',
		ICON: <TasksMenuIcon />,
		ROUTE: `/${agencyRouteName}/tasks`,
		LANG_KEY: 'menu-tasks',
	},
	{
		TITLE: 'Settings',
		ICON: <SettingsMenuIcon />,
		ROUTE: `/${agencyRouteName}/settings`,
		LANG_KEY: 'menu-settings',
	},
];

export const HIDDEN_MENU_ITEMS = [
	{
		menuItemTitle: 'Team',
		subItemsTitles: ['Dedicated Opportunities'],
		action: 'hide-subitems',
	},
	{
		menuItemTitle: 'Clients',
		subItemsTitles: [],
		action: 'hide-subitems',
	},
];
