import api from './api';
import requester from './requester';

const candidateService = {
    fetchAllCandidates: (agencyId, queryParams) => requester(api.candidates(agencyId, queryParams)).getMany(),
    exportCandidates: (agencyId, query, fileName) => requester(api.candidatesExport(agencyId)).saveAsAction(fileName, query),
    exportCandidateInformation: (agencyId, candidateId, fileName) => requester(api.candidateInformationExport(agencyId, candidateId)).saveAsAction(fileName),
    fetchAllTransferredCandidates: agencyId => requester(api.transferredCandidates(agencyId)).getMany(),
    fetchChecklistedCandidates: (agencyId, filters) => requester(api.checklistedCandidates(agencyId), { ...filters }).getMany(),
    fetchCandidatePersonalInformation: (agencyId, candidateId) => requester(api.candidatePersonalInformation(agencyId, candidateId)).getOne(),
    fetchCandidatePreferences: (agencyId, candidateId) => requester(api.candidatePreferences(agencyId, candidateId)).getOne(),
    updateCandidatePreferences: (agencyId, candidateId, preferencesData, stepData) => requester(api.candidatePreferences(agencyId, candidateId)).update({preferencesData, stepData}),
    uploadCandidateVideoInterviewQuestionAnswer: (agencyId, candidateId, category, questionId, videoAnswerData) => requester(api.candidateVideoInterviewQuestion(agencyId, candidateId, category, questionId)).uploadFile(videoAnswerData),
    fetchCandidateVideoInterviewQuestions: (agencyId, candidateId, category) => requester(api.candidateVideoInterviewQuestions(agencyId, candidateId, category)).getMany(),
    getExportedCVs: (agencyId, candidateId, fileName) => requester(api.candidateCV(agencyId, candidateId)).saveAs(fileName),
    updateCandidateAssignee: (agencyId, candidateId, assigneeId) => requester(api.candidateAssignee(agencyId, candidateId)).update(assigneeId),
    fetchCandidateVideoInterviewAnswers: (agencyId, candidateId) => requester(api.candidateVideoInterviewAnswers(agencyId, candidateId)).getMany(),
    fetchCandidateContractorFilters: (agencyId, candidateId) => requester(api.candidateContractorFilters(agencyId, candidateId)).getOne(),
    updateCandidateCriteria: (agencyId, candidateId, criteriaData) => requester(api.candidateCriteria(agencyId, candidateId)).update(criteriaData),
    fetchCandidateCriteria: (agencyId, candidateId) => requester(api.candidateCriteria(agencyId, candidateId)).getOne(),
    fetchCandidateVideoInterviewFeedbackInformation: (agencyId, candidateId) => requester(api.candidateVideoInterviewFeedbackInformation(agencyId, candidateId)).getOne(),
    fetchProfile: (agencyId, candidateId) => requester(api.candidateProfile(agencyId, candidateId)).getOne(),
    fetchCandidateApplicationPage: (agencyId, candidateId, applicationId) => requester(api.candidateApplicationPage(agencyId, candidateId, applicationId)).getOne(),
    fetchCandidateApplicationFeedback: (agencyId, candidateId, applicationId) => requester(api.candidateApplicationFeedback(agencyId, candidateId, applicationId)).getOne(),
    fetchCandidateInterviewInvitations: (agencyId, candidateId) => requester(api.candidateInterviewInvitations(agencyId, candidateId)).getOne(),
    updateInterviewDate: (agencyId, interviewId, data) => requester(api.candidateInterviewDate(agencyId, interviewId)).update(data),
    fetchCandidatePersonalInformationPages: (agencyId, candidateId) => requester(api.candidatePersonalInformationPages(agencyId, candidateId)).getOne(),
    updateInterviewStatus: (agencyId, interviewId, data) => requester(api.candidateInterviewStatus(agencyId, interviewId)).update(data),
    setBookedInterview: (agencyId, candidateId, eventUris, isThroughReminderEmail, stepData, withEmail) => requester(api.candidateFaceToFaceInterview(agencyId, candidateId, withEmail)).update({eventUris, isThroughReminderEmail, stepData }),
    updateCandidatesJourneyCompletedSteps: (agencyId, candidateId, stepData) => requester(api.candidateJourney(agencyId, candidateId)).update({stepData}),
    updateCandidateJourneyStartInformation: (agencyId, candidateId, updateData) => requester(api.candidateJourneyStartInformation(agencyId, candidateId)).update(updateData),
    updateCandidateJourneyImproveCvPage: (agencyId, candidateId, data) => requester(api.candidateJourneyImproveCvPage(agencyId, candidateId)).update(data),
    updateCandidateViewedJob: (agencyId, candidateId, jobOpportunityId) => requester(api.candidateViewedJob(agencyId, candidateId)).update(jobOpportunityId),
    fetchCandidateViewedJob: (agencyId, candidateId) => requester(api.candidateViewedJob(agencyId, candidateId)).getOne(),
    fetchCandidateProfilePage: (agencyId, candidateId) => requester(api.candidateProfilePage(agencyId, candidateId)).getOne(),
    updateCandidateJourneyIsCompleted: (agencyId, candidateId, isCompleted) => requester(api.updateCandidateJourneyIsCompleted(agencyId, candidateId)).update({isCompleted}),
    fetchCandidateJourneyPage: (agencyId, candidateId) => requester(api.candidateJourney(agencyId, candidateId)).getOne(),
    registerCandidate: (agencyId, candidateData) => requester(api.candidates(agencyId)).create(candidateData),
    updateCandidateProfileSteps: (agencyId, candidateId, candidateData) => requester(api.candidateProfileSteps(agencyId, candidateId)).update(candidateData),
    fetchCandidateApplicationProcessInformation: (agencyId, candidateId) => requester(api.candidateApplicationProcessInformation(agencyId, candidateId)).getOne(),
};

export default candidateService;
