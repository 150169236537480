import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
    getDisplayMode,
    getVideoInterviewAnswers,
    getInterviewInvitationDetails,
    getEmployeeCvInformation,
    getJobOpportunityDetailsDataFetchState,
    getJobOpportunityDetails,
    getCandidatePreferences,
    getJobOpportunityCandidateDetails,
    getCandidateApplicationDetails,
    getAgencyRouteName,
    isAdminMode,
} from 'reducers';

import { getStateAgencyName } from 'utils/helpers';

import { fetchJobOpportunityCandidateApplication } from 'actions/jobOpportunityActions';

import { updateInterviewDate } from 'actions/interviewInvitationActions';

import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import ApplicationProgressBar from 'components/Shared/ApplicationProgressBar';
import JobDetailsHeader from './CandidateDetails/JobDetailsHeader';
import CandidateDetails from './CandidateDetails';
import InterviewSection from './InterviewSection';
import ApplicationInterviewSlots from 'components/MyApplications/ApplicationDetails/Application/ApplicationInterviewSlots';
import RateCandidateBanner from 'components/CandidateDetailsPage/RateCandidateBanner';
import CircularProgress from '@material-ui/core/CircularProgress';
import ApplicationVideoIntroduction from 'components/MyApplications/ApplicationDetails/Application/ApplicationVideoIntroduction';
import ApplicationCvInformation from 'components/MyApplications/ApplicationDetails/Application/ApplicationCvInformation';
import HistoricalNotes from 'components/Shared/HistoricalNotes';

import { INTERVIEW_INVITATION_STATUSES, INTERVIEW_INVITATION_CONTRIBUTORS } from 'constants/interviewInvitationConstants';
import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { ROLES } from 'constants/userConstants';
import { HISTORICAL_NOTES_TYPES } from 'components/Shared/HistoricalNotes/HistoricalNotesConstants';

import './CandidateDetailsPage.scss';

const CandidateDetailsPage = ({
    userId,
    agencyId,
    agencyName,
    displayMode,
    interviewInvitation,
    cvInformation,
    jobDetails,
    candidatePreferences,
    candidateDetails,
    dataFetchState,
    fetchJobOpportunityCandidateApplication,
    updateInterviewDate,
    jobApplication,
    agencyRouteName,
    videoInterviewAnswers,
    isAdminMode,
    fromCandidateContext,
}) => {
    const intl = useIntl();

    const history = useHistory();
    const params = useParams();
    const { jobId, applicationId } = params;
    let { candidateId } = params;

    if (fromCandidateContext) {
        candidateId = params.id;
    }

    useEffect(() => {
        fetchJobOpportunityCandidateApplication(agencyId, jobId, candidateId, applicationId);
    }, [history.location.pathname]);

    const isCandidateInvitation = interviewInvitation.proposedBy === INTERVIEW_INVITATION_CONTRIBUTORS.PARTICIPANT;
    const isInterviewApproved = interviewInvitation.status === INTERVIEW_INVITATION_STATUSES.APPROVED;

    const goToSingleJobPage = () => history.push(`/${getStateAgencyName()}/job-page/${jobId}`);
    const goToCandidateApplicationsPage = () => history.push(`/${getStateAgencyName()}/candidates/${candidateId}/applications`);

    const handleRateCandidateClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        history.push(`/${agencyRouteName}/candidates/${candidateDetails._id}/profile-information`);
    };

    const previousButtonText = fromCandidateContext ? intl.formatMessage({ id: "back-to-candidate-applications" }) : intl.formatMessage({ id: "back-to-single-job" });
    const handlePreviousButtonClick = fromCandidateContext ? goToCandidateApplicationsPage : goToSingleJobPage;

    return (
        <div className="candidate-details-page-wrapper">
            <PreviousButton text={previousButtonText} handleClick={handlePreviousButtonClick} />
            {dataFetchState?.isDataFetching
                ? <CircularProgress size={50} disableShrink className="candidate-application-page-loader" />
                : <>
                    <JobDetailsHeader jobDetails={jobDetails} goToSingleJobPage={goToSingleJobPage} isAdminMode={isAdminMode} />
                    <CandidateDetails
                        agencyId={agencyId}
                        applicationId={applicationId}
                        agencyName={agencyName}
                        candidatePreferences={candidatePreferences}
                        candidateDetails={candidateDetails}
                        interviewInvitation={interviewInvitation}
                        profilePictureImgUrl={cvInformation?.profileInformation?.profilePictureImgUrl}
                        applicationStatus={jobApplication?.status}
                        clientId={jobApplication?.clientId}
                        displayMode={displayMode}
                        reviewerName={jobApplication?.reviewerName}
                        candidateId={candidateId}
                    />

                    {isAdminMode &&
                        <HistoricalNotes
                            targetId={applicationId}
                            noteType={HISTORICAL_NOTES_TYPES.JOB_APPLICATION}
                        />
                    }

                    {jobApplication?.status === APPLICATION_STATUSES.NON_VETTED_CANDIDATE && displayMode === ROLES.ADMIN &&
                        <RateCandidateBanner handleRateCandidateClick={handleRateCandidateClick} />
                    }

                    {jobApplication.history.length > 0 &&
                        <ApplicationProgressBar isAdminMode={isAdminMode} applicationHistory={jobApplication.history} interviewDate={interviewInvitation.interviewDate?.date} agencyName={agencyName} />
                    }


                    {displayMode === ROLES.CLIENT &&
                        <ApplicationVideoIntroduction
                            userId={userId}
                            agencyId={agencyId}
                            candidateId={candidateId}
                            applicationId={applicationId}
                            displayMode={displayMode}
                            agencyName={agencyName}
                            videoInterviewAnswers={videoInterviewAnswers}
                            interviewInvitation={interviewInvitation}
                            hideInterviewInviteButton={true}
                        />
                    }


                    {(isCandidateInvitation && !isInterviewApproved) ?
                        <ApplicationInterviewSlots
                            agencyId={agencyId}
                            userId={userId}
                            candidateId={candidateId}
                            clientId={jobApplication.clientId}
                            applicationId={applicationId}
                            displayMode={displayMode}
                            interviewInvitation={interviewInvitation}
                            updateInterviewDate={updateInterviewDate}
                        />

                        : ![APPLICATION_STATUSES.ACCEPTED_ANOTHER_OFFER, APPLICATION_STATUSES.APPROVED, APPLICATION_STATUSES.PENDING_ADMIN_REVIEW, APPLICATION_STATUSES.NON_VETTED_CANDIDATE].includes(jobApplication?.status) &&
                        <InterviewSection
                            jobApplication={jobApplication}
                            userId={userId}
                            agencyId={agencyId}
                            candidateId={candidateId}
                            applicationId={applicationId}
                            displayMode={displayMode}
                            agencyName={agencyName}
                            interviewInvitation={interviewInvitation}
                        />
                    }

                    {displayMode === ROLES.CLIENT &&
                        <ApplicationCvInformation
                            candidateId={candidateId}
                            agencyId={agencyId}
                            applicationId={applicationId}
                            agencyName={agencyName}
                            interviewInvitation={interviewInvitation}
                            cvInformation={cvInformation}
                        />
                    }
                </>
            }
        </div>

    )
}

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
    videoInterviewAnswers: getVideoInterviewAnswers(state),
    interviewInvitation: getInterviewInvitationDetails(state),
    cvInformation: getEmployeeCvInformation(state),
    dataFetchState: getJobOpportunityDetailsDataFetchState(state),
    jobDetails: getJobOpportunityDetails(state),
    candidatePreferences: getCandidatePreferences(state),
    candidateDetails: getJobOpportunityCandidateDetails(state),
    jobApplication: getCandidateApplicationDetails(state),
    agencyRouteName: getAgencyRouteName(state),
    isAdminMode: isAdminMode(state),
});

const mapDispatchToProps = {
    fetchJobOpportunityCandidateApplication,
    updateInterviewDate
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailsPage);
