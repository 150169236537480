import React from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';
import { ErrorMessage } from 'formik';

import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { DATE_PICKER_FORMAT_MM_YYYY } from 'constants/timesFormat'

const CertificateDialogView = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
}) => {
    const intl = useIntl();

    return (
        <form className="certificate-dialog-view form" autoComplete="off">
            <div className="certificate-title-field">
                <TextField
                    fullWidth
                    variant="outlined"
                    name="title"
                    label={intl.formatMessage({ id: "certificate-title" })}
                    value={values.title}
                    onChange={handleChange}
                    error={errors.title && touched.title}
                />
                <ErrorMessage className="invalid-field-message" name="title" component="div" />
            </div>
            <div className="certificate-url-field">
                <TextField
                    fullWidth
                    variant="outlined"
                    name="documentUrl"
                    label={intl.formatMessage({ id: "document-url" })}
                    value={values.documentUrl}
                    onChange={handleChange}
                    error={errors.documentUrl && touched.documentUrl}
                />
                <ErrorMessage className="invalid-field-message" name="documentUrl" component="div" />
            </div>
            <div className="project-start-date-field">
                <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disableToolbar
                    allowKeyboardControl
                    variant="inline"
                    inputVariant="outlined"
                    openTo="year"
                    views={["year", "month"]}
                    label={intl.formatMessage({ id: "issue-date" })}
                    format={DATE_PICKER_FORMAT_MM_YYYY}
                    value={values.issueDate}
                    onChange={value => setFieldValue('issueDate', moment(value))}
                />
            </div>
        </form>
    );
};

export default CertificateDialogView;
