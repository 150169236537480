import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Tooltip from '@material-ui/core/Tooltip';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import { Icon, IconButton } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import EditStatusDialog from 'components/Employees/EditStatusDialog';
import TableWrapper from 'components/Shared/TableWrapper/TableWrapper';

import defaultAvatar from 'assets/dummy-avatar.jpg';

import { REFERRALS, ROLES } from 'constants/userConstants';
import { EMPLOYEE_OCCUPATION_STATUSES_MAP, EMPLOYEE_OCCUPATION_STATUSES_VALUES } from 'constants/employeeConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { CATEGORY_QUERY_SELECTOR, DEFAULT_COLUMNS, LEGEND_ITEMS, PROFILES_CATEGORY_DEFAULT_FILTER, PROFILES_CATEGORY_FILTER, SEARCH_QUERY_SELECTOR, SORT_QUERY_SELECTOR, TABLE_COLUMNS, TABLE_INITIAL_QUERY, TABLE_QUERY_MAP, TABS } from '../HottestTalentsConstants';

import { formatStringAsClassName, getExperienceSinceString, getProfileCvLink, getStateAgencyName, getViewCvLink, getViewRequestsLink, parseQueryString, } from 'utils/helpers';
import { showPopupInScreen } from 'utils/uiHelpers';
import { usePreloadTable } from 'utils/hooks';
import { convertStringToLanguageKey } from 'utils/languageUtils';

import './HottestTalentsTable.scss';

const HottestTalentsTable = ({
    agencyId,
    dataSelector,
    action,
    updateEmployeeOccupationStatus,
    selectedLanguage
}) => {
    const intl = useIntl();
    const history = useHistory();

    const [selectedTab, setSelectedTab] = useState(TABS.EMPLOYEES);

    const [isTableDataLoading] = usePreloadTable(TABLE_INITIAL_QUERY, TABLE_QUERY_MAP, null, action);

    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [isShowMorePopupVisible, setIsShowMorePopupVisible] = useState(false);
    const [isEditStatusDialogOpen, setIsEditStatusDialogOpen] = useState(false);

    const [popupTransform, setPopupTransform] = useState({ transform: 'translateX(-50%)' });

    useEffect(() => {
        const query = parseQueryString(history.location.search);
        const queryTab = query.tab;

        if (queryTab && queryTab !== selectedTab) {
            setSelectedTab(queryTab);
        }
    }, [history.location.search]);

    // Edit Employee Status
    const handleEditStatusDialogOpen = (employee) => {
        setSelectedEmployee(employee);
        setIsEditStatusDialogOpen(true);
    }

    const handleEditStatusDialogClose = () => {
        setIsEditStatusDialogOpen(false)
    }

    const handleEditStatus = (employeeId, data) => {
        updateEmployeeOccupationStatus(agencyId, employeeId, data);
    };

    //Handle mouse over on rest skills
    const handleRestSkillsHover = (visibility, employee) => {
        setIsShowMorePopupVisible(visibility);

        if (employee) {
            showPopupInScreen(`rest-skills-text-${employee._id}`, setPopupTransform);
            setSelectedEmployee(employee);
        } else {
            setSelectedEmployee({});
        }
    };

    const agencyRouteName = getStateAgencyName();

    const getReferrerClassName = (user) => {
        const { addedByUser, referrer } = user;

        if (addedByUser) {
            return 'invited';
        } else if (referrer) {
            switch (referrer) {
                case REFERRALS.GOOGLE:
                    return 'google';
                case REFERRALS.LINKEDIN:
                    return 'linkedin';
                default:
                    return 'other-sources';
            }
        } else {
            return 'self';
        }
    };

    const adjustKeysFunction = employees => employees.map((employee) => {
        const experienceSince = moment.utc(employee.employmentInformation?.generalInfo?.experienceSince);
        const experienceString = getExperienceSinceString(experienceSince, selectedLanguage);

        const status = employee.occupationStatus
            ? EMPLOYEE_OCCUPATION_STATUSES_MAP[employee.occupationStatus]
            : employee.status;
        const projectEndDate = employee.projectEndDate && employee.occupationStatus === EMPLOYEE_OCCUPATION_STATUSES_VALUES.PROJECT_ENDING
            ? moment(employee.projectEndDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)
            : null;
        const statusDate = employee.statusLastEdit && ![ROLES.EMPLOYEE, ROLES.ADMIN].includes(employee.role)
            ? moment(employee.statusLastEdit).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)
            : null;

        const visibleSkills = employee.skills?.slice(0, 4) || [];
        const restSkills = employee.skills?.slice(4) || [];
        const restSkillsCount = restSkills.length || 0;

        const customClassName =
            employee.role === ROLES.EMPLOYEE ? employee.employmentInformation.contractInformation.employmentType || '' :
                employee.role === ROLES.CANDIDATE ? getReferrerClassName(employee) :
                    '';

        return {
            _id: employee._id,
            name: (
                <div className="name-experience-container">
                    <div className="name">
                        <Tooltip
                            title={employee.addedByUser ? `Invited by: ${employee.addedByUser}` : ''}
                            placement="right-end"
                            classes={{ tooltip: 'custom-tooltip' }}
                        >
                            <Avatar
                                alt="Employee Avatar"
                                className={`avatar profile-image ${formatStringAsClassName(customClassName)}`}
                                src={employee.profilePictureImgUrl}
                                onError={(e) => (e.target.src = defaultAvatar)}
                            />
                        </Tooltip>
                        <Link to={getProfileCvLink(agencyRouteName, employee)}>
                            <p>{`${employee.firstName} ${employee.lastName}`}</p>
                        </Link>

                    </div>
                </div>
            ),
            technologies: visibleSkills?.length > 0 && (
                <div className="technologies-container">
                    {visibleSkills.map((skill, index) => (
                        <div key={`${skill} ${index}`} className="single-technology-box">
                            <span className="technology-text">{skill}</span>
                        </div>
                    ))}
                    {restSkillsCount > 0 && (
                        <div
                            className="rest-skills-container"
                            onMouseOver={() => handleRestSkillsHover(true, employee)}
                            onMouseLeave={() => handleRestSkillsHover(false)}
                        >
                            <span id={`rest-skills-text-${employee._id}`} className="rest-skills-count">
                                {`+ ${restSkillsCount} ${intl.formatMessage({ id: "more" }).toLowerCase()}`}
                            </span>
                            {isShowMorePopupVisible && selectedEmployee._id === employee._id && (
                                <div className="rest-skills-popup" style={popupTransform}>
                                    {restSkills.map((skill, index) => (
                                        <div key={`${skill} ${index}`} className="single-technology-box no-border">
                                            <span className="technology-text">{skill}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ),
            status: (
                <div className="status-wrapper">
                    {status && (
                        <div className={`status-container ${formatStringAsClassName(status)}`}>
                            <span className="status-text">
                                {intl.formatMessage({ id: convertStringToLanguageKey(status) })} {statusDate && statusDate} {projectEndDate && `(${projectEndDate})`}
                            </span>
                        </div>
                    )}
                    {(employee.role === ROLES.EMPLOYEE || employee.role === ROLES.ADMIN) && (
                        <IconButton className="edit-status-button" onClick={() => handleEditStatusDialogOpen(employee)}>
                            <Icon>
                                <EditSharpIcon className="edit-sharp-icon" />
                            </Icon>
                        </IconButton>
                    )}
                </div>
            ),
            experience: experienceString && (
                <p className="experience">
                    {experienceString}
                </p>
            ),
            item: employee,
        }
    });

    const popoverOptions = [
        { title: intl.formatMessage({ id: "edit-profile" }), handleClick: () => history.push(getProfileCvLink(agencyRouteName, selectedEmployee)) },
        { title: intl.formatMessage({ id: "view-cv" }), handleClick: () => history.push(getViewCvLink(agencyRouteName, selectedEmployee)) },
        { title: intl.formatMessage({ id: "view-requests" }), handleClick: () => history.push(getViewRequestsLink(agencyRouteName, selectedEmployee)) },
    ];

    const getPopoverActions = (item) => {
        setSelectedEmployee(item);
        return popoverOptions;
    }

    return (
        <div className="hottest-talents-table-wrapper">
            <TableWrapper
                title={intl.formatMessage({ id: "hottest-talent-section-title" })}
                columns={TABLE_COLUMNS}
                defaultColumns={DEFAULT_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustKeysFunction}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withLegend={selectedTab !== TABS.PARTNER}
                withActions
                getActionsHandler={getPopoverActions}
                legendItems={LEGEND_ITEMS[selectedTab]}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableSearch queryParamName={SEARCH_QUERY_SELECTOR} />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableCategoryFilter
                    filters={PROFILES_CATEGORY_FILTER}
                    defaultFilter={PROFILES_CATEGORY_DEFAULT_FILTER}
                    queryParamName={CATEGORY_QUERY_SELECTOR}
                />
                <TableWrapper.TableContent />

                <EditStatusDialog
                    isOpen={isEditStatusDialogOpen}
                    handleCloseDialog={handleEditStatusDialogClose}
                    selectedEmployee={selectedEmployee}
                    handleEditStatus={handleEditStatus}
                />
            </TableWrapper>
        </div>
    );
};

export default HottestTalentsTable;
