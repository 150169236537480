import {
    HOLIDAY_APPROVAL_STATUSES,
    HOLIDAY_SIGNATURE_STATUSES,
} from 'constants/holidayConstants';

export const SINGLE_EMPLOYEE_TABLE_COLUMNS = [
    { name: 'From', value: 'from', isSortable: true },
    { name: 'To', value: 'to', isSortable: true },
    { name: 'Days', value: 'numberOfDays', isSortable: true },
    { name: 'Type', value: 'type', isSortable: true },
    { name: 'Request Date', value: 'requestedOn', isSortable: true },
    { name: 'Approval Status', value: 'status', isSortable: true },
    { name: 'Signature Status', value: 'signatureStatus', isSortable: true },
];
export const DEFAULT_SINGLE_EMPLOYEE_TABLE_COLUMNS = [
    'from',
    'to',
    'numberOfDays',
    'type',
    'requestedOn',
    'status',
    'signatureStatus',
];


export const SINGLE_EMPLOYEE_TABLE_COLUMNS_NO_SIGN = [
    { name: 'From', value: 'from', isSortable: true },
    { name: 'To', value: 'to', isSortable: true },
    { name: 'Days', value: 'numberOfDays', isSortable: true },
    { name: 'Type', value: 'type', isSortable: true },
    { name: 'Request Date', value: 'requestedOn', isSortable: true },
    { name: 'Approval Status', value: 'status', isSortable: true },
];
export const DEFAULT_SINGLE_EMPLOYEE_TABLE_COLUMNS_NO_SIGN = [
    'from',
    'to',
    'numberOfDays',
    'type',
    'requestedOn',
    'status',
];


export const TABLE_COLUMNS = [
    { name: 'Name', value: 'name', isSortable: true, langKey: "name-text" },
    { name: 'From', value: 'from', isSortable: true, langKey: "from" },
    { name: 'To', value: 'to', isSortable: true, langKey: "to" },
    { name: 'Days', value: 'numberOfDays', isSortable: true, langKey: "days" },
    { name: 'Type', value: 'type', isSortable: true, langKey: "type" },
    { name: 'Request Date', value: 'requestedOn', isSortable: true, langKey: "request-date" },
    { name: 'Approval Status', value: 'status', isSortable: true, langKey: "approval-status" },
    { name: 'Signature Status', value: 'signatureStatus', isSortable: true, langKey: "signature-status" },
];
export const DEFAULT_TABLE_COLUMNS = [
    'name',
    'from',
    'to',
    'numberOfDays',
    'type',
    'requestedOn',
    'status',
    'signatureStatus',
];


export const TABLE_COLUMNS_NO_SIGN = [
    { name: 'Name', value: 'name', isSortable: true, langKey: "name-text" },
    { name: 'From', value: 'from', isSortable: true, langKey: "from" },
    { name: 'To', value: 'to', isSortable: true, langKey: "to" },
    { name: 'Days', value: 'numberOfDays', isSortable: true, langKey: "days" },
    { name: 'Type', value: 'type', isSortable: true, langKey: "type" },
    { name: 'Request Date', value: 'requestedOn', isSortable: true, langKey: "request-date" },
    { name: 'Approval Status', value: 'status', isSortable: true, langKey: "approval-status" },
    { name: 'Approval Status', value: 'status', isSortable: true },
];
export const DEFAULT_TABLE_COLUMNS_NO_SIGN = [
    'name',
    'from',
    'to',
    'numberOfDays',
    'type',
    'requestedOn',
    'status',
];

export const SORT_QUERY_SELECTOR = 'historySort';
export const PAGE_QUERY_SELECTOR = 'historyPage';
export const DATE_QUERY_SELECTOR = 'historyDate';
export const SEARCH_QUERY_SELECTOR = 'historySearch';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const TABLE_FILTERS = [
    {
        key: 'status',
        name: 'Approval Status',
        value: [
            HOLIDAY_APPROVAL_STATUSES.APPROVED,
            HOLIDAY_APPROVAL_STATUSES.REJECTED,
        ],
        hasTranslation: true,
    },
    {
        key: 'signatureStatus',
        name: 'Signature Status',
        value: [
            HOLIDAY_SIGNATURE_STATUSES.PENDING,
            HOLIDAY_SIGNATURE_STATUSES.REJECTED,
            HOLIDAY_SIGNATURE_STATUSES.SIGNED
        ],
        hasTranslation: true,
    },
];

export const TABLE_INITIAL_QUERY = {
    filters: JSON.stringify({}),
    page: 1,
    search: '',
    date: null,
    sortBy: 'from',
    sortByDir: 'desc',
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    pagesToLoad: 5,
};

export const TABLE_QUERY_MAP = {
    filters: 'filters',
    page: PAGE_QUERY_SELECTOR,
    search: SEARCH_QUERY_SELECTOR,
    date: DATE_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};

export const CONFIRM_DELETE_DIALOG_TITLE = 'selected request';
