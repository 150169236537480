import React from 'react';
import { useIntl } from 'react-intl';

import { Paper } from '@material-ui/core';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './InterviewSectionPaper.scss';

const InterviewSectionPaper = ({ title, text, classes, children }) => {
    const intl = useIntl();

    return (
        <Paper className="interview-section">
            <div className="header">
                <h3 className="title">
                    {intl.formatMessage({ id: convertStringToLanguageKey(title) })}
                </h3>
                <p className="text">
                    {intl.formatMessage({ id: convertStringToLanguageKey(text) })}
                </p>
            </div>
            {children}
        </Paper>
    )
};

export default InterviewSectionPaper;
