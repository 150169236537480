import React from 'react';

import { connect } from 'react-redux';
import { showNotification } from 'actions/notificationActions';

import AttachedFilesList from './AttachedFilesList';

import './UploadFiles.scss';

// TODO: remove this and use the shared component with the new variant

const UploadFiles = ({
    filesAttached,
    setUploads,
}) => {
    return (
        <AttachedFilesList
            files={Array.from(filesAttached)}
            deleteAttachedFile={() => setUploads({})}
        />
    );
};

const mapDispatchtoProps = {
    showNotification,
};

export default connect(
    null,
    mapDispatchtoProps
)(UploadFiles);
