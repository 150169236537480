import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import CreateEditTaskDialog from './CreateEditTaskDialog';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import TaskCard from './TaskCard';
import NothingToShow from 'components/NothingToShow';
import CircularProgress from '@material-ui/core/CircularProgress';

import { logEvent } from 'utils/amplitude';

import { taskStatuses } from './TaskCardListConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { ROLES } from 'constants/userConstants';

import './TaskCardList.scss';

const TaskCardList = ({
    tasks,
    currentUser,
    submitTaskHandler,
    selectedUser,
    agencyId,
    isAgencyTasks,
    taskAdmins,
    taskTypeSelect,
    agencyDashboard,
    handleFilterChange,
    handleFilterReset,
    taskAdminsSelect,
    filters,
    expandTitle,
    dataFetchState
}) => {
    const intl = useIntl();

    const [taskOwner, setTaskOwner] = useState(currentUser);
    const [reletedTo, setReletedTo] = useState(selectedUser);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentTask, setCurrentTask] = useState({});
    const [isStatusPopoverOpen, setIsStatusPopoverOpen] = React.useState(null);

    const handleOpenDialog = () => {
        setTaskOwner(currentUser);
        setReletedTo(selectedUser);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setCurrentTask({});
        setTaskOwner(currentUser);
        setReletedTo(selectedUser);
        setOpenDialog(false);
    };

    const handleTaskCardClick = (task) => {
        setCurrentTask(task);
        setTaskOwner(task.owner);
        setReletedTo(task.relatedTo);
        setOpenDialog(true);
    };

    const sortTasks = (taskA, taskB) => {
        if (!taskA.reminder) { return 1; }
        if (!taskB.reminder) { return -1; }
        if (taskA.reminderDateTime > taskB.reminderDateTime) { return 1; }
        return -1;
    };

    const handlePopoverOpen = () => {
        setIsStatusPopoverOpen(true);
    };

    const handlePopoverClose = () => {
        setIsStatusPopoverOpen(false);
    };

    return (
        <>
            {dataFetchState?.isDataFetching && !dataFetchState?.isDataFetched
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <div className={agencyDashboard ? "tasks-wrapper all-tasks admin-dashboard" : "tasks-wrapper all-tasks"}>
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.SectionTitle
                            sectionTitle={expandTitle ? expandTitle : intl.formatMessage({ id: "tasks" })}
                        />

                        {isAgencyTasks && !agencyDashboard &&
                            <SectionActionsWrapper.LeftAlignedActions>
                                <SectionActionsWrapper.LeftAlignedActions.SelectOneFilter
                                    name='owner'
                                    value={filters.owner.value}
                                    onChangeHandler={handleFilterChange}
                                    items={taskAdminsSelect}
                                />

                                <SectionActionsWrapper.LeftAlignedActions.SelectOneFilter
                                    name='type'
                                    value={filters.type.value}
                                    onChangeHandler={handleFilterChange}
                                    items={taskTypeSelect}
                                />

                                <SectionActionsWrapper.LeftAlignedActions.ClearFiltersButton
                                    text={intl.formatMessage({ id: "clear" })}
                                    handleClearFilters={handleFilterReset}
                                />
                            </SectionActionsWrapper.LeftAlignedActions>
                        }

                        <SectionActionsWrapper.RightAlignedActions>
                            <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                                text={intl.formatMessage({ id: "create-task" })}
                                onClick={() => {
                                    logEvent(AMPLITUDE_EVENT_TYPES.CLICK_ADD_TASK, { rolesPermitted: [ROLES.ADMIN] });
                                    handleOpenDialog();
                                }}
                            />
                        </SectionActionsWrapper.RightAlignedActions>
                    </SectionActionsWrapper>

                    <div className="task-cards-wrapper">
                        {tasks && tasks.length > 0
                            ? agencyDashboard
                                ? tasks.filter(x => currentUser.name === x.owner.name && x.status !== taskStatuses.COMPLETED).sort(sortTasks).map((task, i) =>
                                    <TaskCard
                                        key={i}
                                        task={task}
                                        handleTaskCardClick={() => handleTaskCardClick(task)}
                                        submitTaskHandler={submitTaskHandler}
                                        agencyId={agencyId}
                                        open={isStatusPopoverOpen}
                                        handlePopoverOpen={handlePopoverOpen}
                                        handlePopoverClose={handlePopoverClose}
                                    />
                                )
                                : tasks.sort(sortTasks).map((task, i) =>
                                    <TaskCard
                                        key={i}
                                        task={task}
                                        handleTaskCardClick={() => handleTaskCardClick(task)}
                                        submitTaskHandler={submitTaskHandler}
                                        agencyId={agencyId}
                                        open={isStatusPopoverOpen}
                                        handlePopoverOpen={handlePopoverOpen}
                                        handlePopoverClose={handlePopoverClose}
                                    />
                                )
                            : <NothingToShow items='tasks' />
                        }
                    </div>

                    <CreateEditTaskDialog
                        reletedTo={reletedTo}
                        taskOwner={taskOwner}
                        currentTask={currentTask}
                        submitTaskHandler={submitTaskHandler}
                        openDialog={openDialog}
                        handleCloseDialog={handleCloseDialog}
                        agencyId={agencyId}
                        taskAdmins={taskAdmins}
                        isAgencyTasks={isAgencyTasks}
                    />
                </div>
            }
        </>
    )
};

export default TaskCardList;
