import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { downloadEmployeeCv } from 'actions/employeeActions';
import { fetchAgencyCalendlyInfo } from 'actions/agencyActions';
import {
    fetchCandidateProfilePage,
    exportCandidateCV,
    updateCandidateJourneyCvPage,
    updateCandidateCriteria,
    bookFaceToFaceInterview,
    updateCandidateJourneyIsCompleted,
} from 'actions/candidateActions';

import {
    isCandidateMode,
    isAdminMode,
    getVideoInterviewAnswers,
    getEmployeeCvInformation,
    getCandidatePreferences,
    getPersonalInfo,
    getCompanyLogoUrl,
    getEmployeeUploadedCv,
    hasUserBookedInterview,
    getUserId,
    getFaceToFaceInterviewInformation,
    getCandidateProfilePageDataFetchState,
    getCandidateOverviewPageDataFetchState,
    getCandidateJourneyInfo,
    getAgencyCalendlyInfo,
    getProfileDetails,
    getCvPageUpdateState,
} from 'reducers';

import CandidateProfileInfo from './CandidateProfileInfo';
import InterviewRecordingsSection from './InterviewRecordingsSection';
import ThankYouBanner from './ThankYouBanner';
import RejectionBanner from './RejectionBanner';
import HiringChecklist from './HiringChecklist';
import CandidateVideoInterviewFeedback from './CandidateVideoInterviewFeedback';
import CandidateJourneyCv from 'components/CandidateProfileJourney/Steps/UploadResumeWrapper/CandidateJourneyCv';
import CvSection from '../Shared/CvSection/CvSection';
import CircularProgress from '@material-ui/core/CircularProgress';

import { checkForFilledCvSections } from 'utils/cvUtils';

import { CANDIDATE_STATUSES } from 'constants/candidateConstants';

import './CandidateProfilePage.scss';

const CandidateProfilePage = ({
    userId,
    agencyId,
    fetchCandidateProfilePage,
    exportCandidateCV,
    updateCandidateJourneyCvPage,
    downloadEmployeeCv,
    updateCandidateCriteria,
    isCandidateMode,
    isAdminMode,
    videoInterviewAnswers,
    cvInformation,
    candidatePreferences,
    personalInfo,
    companyLogoUrl,
    uploadedCv,
    hasBookedInterview,
    calendlyInterviewInfo,
    videoInterviewFeedback,
    assignee,
    candidateProfilePageDataFetchState,
    candidateOverviewPageDataFetchState,
    candidateJourneyInfo,
    agencyCalendlyInfo,
    fetchAgencyCalendlyInfo,
    bookFaceToFaceInterview,
    updateCandidateJourneyIsCompleted,
    profileDetails,
    dataUpdateState,
}) => {
    const { id: candidateId } = useParams();
    const id = isCandidateMode ? userId : candidateId;

    const [isEditMode, setEditMode] = useState(false);
    const [uploads, setUploads] = useState({});

    const scrollToRef = useRef(null);

    const hasFilledCvSection = checkForFilledCvSections(cvInformation);
    const showUploadCv = isAdminMode && !uploadedCv.originalFileName && !hasFilledCvSection && !isEditMode;

    const interviewRecordingsSectionTitle = isCandidateMode
        ? 'Here you can watch your video recording.'
        : 'Watch the candidate video introduction.';

    useEffect(() => {
        if (isCandidateMode) {
            fetchCandidateProfilePage(agencyId, id);
        }

        fetchAgencyCalendlyInfo(agencyId);
    }, []);

    useEffect(() => {
        if (uploadedCv.originalFileName) {
            setUploads({ type: 'file', filesAttached: [{ name: uploadedCv.originalFileName }] });
        } else {
            setUploads({});
        }
    }, [uploadedCv.originalFileName]);

    const enterEditMode = () => {
        setEditMode(true);
    }

    const exitEditMode = () => {
        setEditMode(false);
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView();
        }
    };

    const handleDownloadCV = () => {
        const fileName = uploadedCv?.originalFileName.split('.').slice(0, -1).join('.')
        downloadEmployeeCv(agencyId, candidateId, fileName);
    };

    const handleRequestNewReview = () => {
        const status = CANDIDATE_STATUSES.NEW;

        updateCandidateCriteria(agencyId, id, { status, clearCriteria: true }, false);
    };

    const handleEventScheduling = (event) => {
        const eventUri = event.data.payload.event.uri;
        const inviteeUri = event.data.payload.invitee.uri;
        bookFaceToFaceInterview(agencyId, id, { eventUri, inviteeUri }, true, {}, true);
        updateCandidateJourneyIsCompleted(agencyId, id, true);
    };

    return (
        <>
            {(isCandidateMode ? candidateProfilePageDataFetchState.isDataFetching : candidateOverviewPageDataFetchState.isDataFetching)
                ? <CircularProgress size={50} className="page-loader" disableShrink />
                : <div className="candidate-profile-page">
                    {(!isEditMode && !dataUpdateState?.isDataUpdating) &&
                        <>
                            {isCandidateMode &&
                                (personalInfo.status === CANDIDATE_STATUSES.REJECTED
                                    ? <RejectionBanner
                                        rejectionReason={personalInfo.rejectionReason}
                                        rejectionDate={personalInfo.dateOfRate}
                                        handleRequestNewReview={handleRequestNewReview}
                                    />
                                    : <ThankYouBanner />
                                )
                            }
                            {isAdminMode && <HiringChecklist />}
                            <CandidateProfileInfo
                                personalInfo={personalInfo}
                                profileInfo={candidatePreferences}
                                agencyId={agencyId}
                                userId={userId}
                                candidateId={id}
                                setEditMode={setEditMode}
                                isCandidateMode={isCandidateMode}
                                isAdminMode={isAdminMode}
                            />
                        </>
                    }
                    {(!isEditMode && !dataUpdateState?.isDataUpdating) &&
                        <InterviewRecordingsSection
                            title={interviewRecordingsSectionTitle}
                            videos={videoInterviewAnswers}
                            hasBookedInterview={hasBookedInterview}
                            calendlyInterviewInfo={calendlyInterviewInfo}
                            isCandidateMode={isCandidateMode}
                            isAdminMode={isAdminMode}
                            candidateJourneyInfo={candidateJourneyInfo}
                            calendlyInfo={agencyCalendlyInfo}
                            handleEventScheduling={handleEventScheduling}
                            isDeleted={profileDetails.isDeleted}
                        />
                    }
                    {showUploadCv
                        ? <CandidateJourneyCv
                            agencyId={agencyId}
                            userId={id}
                            uploads={uploads}
                            setUploads={setUploads}
                            enterEditMode={enterEditMode}
                        />
                        : <CvSection
                            userId={id}
                            agencyId={agencyId}
                            isCandidateProfilePage={true}
                            cvInformation={cvInformation}
                            enterEditMode={enterEditMode}
                            exitEditMode={exitEditMode}
                            isEditMode={isEditMode}
                            exportCandidateCV={exportCandidateCV}
                            companyLogoUrl={companyLogoUrl}
                            updateCandidateJourneyCvPage={updateCandidateJourneyCvPage}
                            isCandidateMode={isCandidateMode}
                            isAdminMode={isAdminMode}
                            handleDownloadCV={handleDownloadCV}
                            uploadedCv={uploadedCv}
                            scrollToRef={scrollToRef}
                            dataUpdateState={dataUpdateState}
                        />
                    }
                    {((!isEditMode && isAdminMode) && !dataUpdateState?.isDataUpdating) &&
                        <CandidateVideoInterviewFeedback
                            userId={userId}
                            candidateId={id}
                            agencyId={agencyId}
                            videoInterviewFeedback={videoInterviewFeedback}
                            assignee={assignee}
                            isAdminMode={isAdminMode}
                        />
                    }
                </div >
            }
        </>
    )
}

const mapStateToProps = state => ({
    isCandidateMode: isCandidateMode(state),
    isAdminMode: isAdminMode(state),
    videoInterviewAnswers: getVideoInterviewAnswers(state),
    cvInformation: getEmployeeCvInformation(state),
    candidatePreferences: getCandidatePreferences(state),
    personalInfo: getPersonalInfo(state),
    companyLogoUrl: getCompanyLogoUrl(state),
    uploadedCv: getEmployeeUploadedCv(state),
    hasBookedInterview: hasUserBookedInterview(state),
    calendlyInterviewInfo: getFaceToFaceInterviewInformation(state),
    userId: getUserId(state),
    candidateProfilePageDataFetchState: getCandidateProfilePageDataFetchState(state),
    candidateOverviewPageDataFetchState: getCandidateOverviewPageDataFetchState(state),
    candidateJourneyInfo: getCandidateJourneyInfo(state),
    agencyCalendlyInfo: getAgencyCalendlyInfo(state),
    profileDetails: getProfileDetails(state),
    dataUpdateState: getCvPageUpdateState(state),
});

const mapDispatchToProps = {
    exportCandidateCV,
    updateCandidateJourneyCvPage,
    fetchCandidateProfilePage,
    downloadEmployeeCv,
    updateCandidateCriteria,
    fetchAgencyCalendlyInfo,
    bookFaceToFaceInterview,
    updateCandidateJourneyIsCompleted,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfilePage);
