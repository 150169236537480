import { BULGARIAN_LABEL } from "./languages";

export const EMPLOYEE_OCCUPATION_STATUSES = {
    PLACED: 'Billable',
    BENCH: 'Bench',
    NON_BILLABLE_PROJECT: 'Non-billable',
    LEADERSHIP_TEAM: 'Leadership Team',
    PROJECT_ENDING: 'Project Ending'
};

export const EMPLOYEE_OCCUPATION_STATUSES_ARRAY = [
    { key: 1, value: 'Billable' },
    { key: 2, value: 'Bench' },
    { key: 3, value: 'Non-billable' },
    { key: 4, value: 'Leadership Team' },
    { key: 5, value: 'Project Ending' },
];

export const EMPLOYEE_OCCUPATION_STATUSES_MAP = {
    1: 'Billable',
    2: 'Bench',
    3: 'Non-billable',
    4: 'Leadership Team',
    5: 'Project Ending',
};

export const EMPLOYMENT_TYPES = {
    FULLTIME_CONTRACT: 'Full-time Contract',
    FREELANCE_CONTRACT: 'Freelance Contract',
};

export const EMPLOYEE_OCCUPATION_STATUSES_VALUES = {
    PLACED: 1,
    BENCH: 2,
    INTERNAL_PROJECT: 3,
    MANAGEMENT_TEAM: 4,
    PROJECT_ENDING: 5
};

export const AVAILABLE_OCCUPATION_STATUSES = [EMPLOYEE_OCCUPATION_STATUSES_VALUES.BENCH, EMPLOYEE_OCCUPATION_STATUSES_VALUES.INTERNAL_PROJECT, EMPLOYEE_OCCUPATION_STATUSES_VALUES.PROJECT_ENDING]

export const mapEmployeeOccupationStatusToStyles = occupationStatus => {
    switch (occupationStatus) {
        case 1:
            return 'unavailable'
        case 2:
        case 3:
        case 5:
            return 'available'
        default:
            return 'default'
    }
};

export const getToolTipTitleBasedOnOccupationStatus = (occupationStatus, selectedLanguage) => {
    switch (occupationStatus) {
        case 1:
            return selectedLanguage === BULGARIAN_LABEL ? 'Този служител е активен проект' : 'This employee is marked as Billable.'
        case 2:
        case 3:
        case 5:
            return selectedLanguage === BULGARIAN_LABEL ? 'Този служител е без проект, проект без плащане или проекта им приключва скоро ' : 'This employee is marked as Bench, Non-billable project or their project ends soon.'
        case 4:
            return selectedLanguage === BULGARIAN_LABEL ? 'Този служител е в лидерския екип' : 'This employee is marked as part of the leadership team.'
        default:
            return ''
    }
};

export const EMPLOYEE_TEAM_TYPES = {
    EXECUTIVE: 'Executive Team',
    HR: 'HR Team',
    RECRUITMENT: 'Recruitment Team',
    SALES: 'Sales Team',
    OPERATIONS: 'Operations Team',
    FINANCE: 'Finance Team',
    STAFFING: 'Staffing Team',
    OTHER: 'Other',
};

export const EMPLOYEE_TEAM_TYPES_VALUES = {
    [EMPLOYEE_TEAM_TYPES.EXECUTIVE]: 1,
    [EMPLOYEE_TEAM_TYPES.HR]: 2,
    [EMPLOYEE_TEAM_TYPES.RECRUITMENT]: 3,
    [EMPLOYEE_TEAM_TYPES.SALES]: 4,
    [EMPLOYEE_TEAM_TYPES.OPERATIONS]: 5,
    [EMPLOYEE_TEAM_TYPES.FINANCE]: 6,
    [EMPLOYEE_TEAM_TYPES.STAFFING]: 7,
    [EMPLOYEE_TEAM_TYPES.OTHER]: 8,
};

export const EMPLOYEE_TEAM_TYPES_ARRAY = Object.values(EMPLOYEE_TEAM_TYPES).map(x => ({ name: x, value: EMPLOYEE_TEAM_TYPES_VALUES[x] }));

export const MODE_EMPLOYEE_QUERY = '?mode=employee';
