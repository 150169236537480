import React from 'react';

import ProfileCreationCard from 'components/Shared/ProfileCreationCard';
import FormInputField from 'components/Shared/FormInputField';
import FormValidationRequirement from 'components/Shared/FormValidationRequirement';

import { PASSWORD_VALIDATION_MESSAGES } from 'constants/commonConstants';

const PasswordResetFormView = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit
}) => {
    const isNewPasswordRequirementMet = !errors.newPassword && values.newPassword;
    const isNewPasswordRequirementNotMet = errors.newPassword && touched.newPassword;
    const isConfirmPasswordRequirementNotMet = errors.confirmPassword && touched.confirmPassword;

    return (
        <>
            <ProfileCreationCard.ContentBody>
                <form autoComplete="off">
                    <FormInputField
                        className="smaller"
                        placeholder="New Password"
                        name="newPassword"
                        value={values.newPassword}
                        isRequirementNotMet={isNewPasswordRequirementNotMet}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        withPasswordVisibility
                    />
                    <FormInputField
                        className="smaller"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        isRequirementNotMet={isConfirmPasswordRequirementNotMet}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        withPasswordVisibility
                    />

                    <FormValidationRequirement
                        requirementText={PASSWORD_VALIDATION_MESSAGES.ALL}
                        isRequirementMet={isNewPasswordRequirementMet}
                        isRequirementNotMet={isNewPasswordRequirementNotMet}
                    />

                    {(isNewPasswordRequirementNotMet || isConfirmPasswordRequirementNotMet) &&
                        <FormValidationRequirement
                            requirementText={errors.confirmPassword || errors.newPassword}
                            isRequirementNotMet={isNewPasswordRequirementNotMet || isConfirmPasswordRequirementNotMet}
                        />
                    }
                    <ProfileCreationCard.ContentAction
                        text='Change Password'
                        handleClick={handleSubmit}
                    />
                </form>
            </ProfileCreationCard.ContentBody>
        </>
    );
};

export default PasswordResetFormView;
