import React from 'react';
import Button from '@material-ui/core/Button';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import './PreviousButton.scss'

const PreviousButton = React.forwardRef(({
    text,
    className,
    component,
    type,
    handleClick,
    disabled,
    ...props
}, ref) => {

    return (
        <Button
            disableRipple
            variant='text'
            className={className ? `previous-button ${className}` : 'previous-button'}
            type={type}
            onClick={handleClick}
            component={component ? component : 'button'}
            disabled={disabled}
            ref={ref}
            {...props}
        >
            <div className="arrow-icon-wrapper">
                <KeyboardArrowLeftIcon />
            </div>
            {text}
        </Button>
    );
});

export default PreviousButton;
