import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getAgencyName } from 'reducers';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';

import CloseIcon from '@material-ui/icons/Close';

import { DIALOG_COMMENT_INPUT } from 'constants/commonConstants';

import './SendProfilesListItem.scss';

const SendProfilesListItem = ({
    employee,
    agencyName,
    isWithRemoveProfile,
    isWithNote,
    isCommentButtonTriggered,
    handleShowComment,
    handleHideComment,
    index,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
}) => {
    const intl = useIntl();

    const handleRemoveProfile = () => {
        const newEmployeesArray = values.selectedEmployees.filter(selectedEmployee => selectedEmployee._id !== employee._id);

        if (isWithNote) {
            const newCommentsArray = values.comments.filter((x, commentIndex) => commentIndex !== index);
            setFieldValue('comments', newCommentsArray);
            handleHideComment(employee._id);
        }

        setFieldValue('selectedEmployees', newEmployeesArray)
    };

    return (
        <div className="send-profiles-list-item">
            <div className="profile-info-wrapper">
                <Avatar
                    className="avatar"
                    alt="Avatar"
                    src={employee?.profilePictureImgUrl}
                />
                <div className="profile-name-position-wrapper">
                    {employee?.name && <p className="name">{employee?.name}</p>}
                    <div>
                        {
                            employee?.position
                                ? <span>{agencyName} / {employee?.position}</span>
                                : <span>{agencyName}</span>
                        }
                    </div>
                </div>

                <div className="actions-wrapper">
                    {isWithNote &&
                        (isCommentButtonTriggered ?
                            <PrimaryButton
                                className="hide-comment-btn"
                                text={intl.formatMessage({ id: "hide" })}
                                handleClick={handleHideComment.bind(null, employee._id)}
                            />
                            :
                            <PrimaryButton
                                className="add-comment-btn"
                                text={intl.formatMessage({ id: "add-comment" })}
                                handleClick={handleShowComment.bind(null, employee._id)}
                            />
                        )
                    }
                    {isWithRemoveProfile &&
                        <CloseIcon className="close-icon" onClick={handleRemoveProfile} />
                    }
                </div>
            </div>
            {(isWithNote && isCommentButtonTriggered) &&
                <div className="comment-field">
                    <TextField
                        inputProps={{ "data-testid": "add-comment-field" }}
                        name={`comments[${index}]`}
                        onChange={handleChange}
                        value={values.comments[index]}
                        label={intl.formatMessage({ id: "add-note" })}
                        placeholder="Add information about the rate, availability, suitable interview slots or other."
                        error={errors[`comments[${index}]`] ? true : !!(false && touched[`comments[${index}]`])}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={DIALOG_COMMENT_INPUT.ROWS}
                        maxRows={DIALOG_COMMENT_INPUT.MAX_ROWS}
                    />
                    {(errors[`comments[${index}]`] ? true : !!(false && touched[`comments[${index}]`])) &&
                        <div className="invalid-field-message">
                            Comment must be maximum 2000 characters long.
                        </div>
                    }
                </div>
            }
        </div>
    );
};

const mapStateToProps = state => ({
    agencyName: getAgencyName(state),
});

export default connect(mapStateToProps)(SendProfilesListItem);
