import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { addClientProject, fetchClientProjectsPage, fetchClientInvoicingInformation } from 'actions/clientActions';

import { getClientProjects, getClientsProjectsPageDataFetchState, isAdminMode } from 'reducers';

import { getStateAgencyName, updateQuery } from 'utils/helpers';

import CircularProgress from '@material-ui/core/CircularProgress';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import AddProjectDialog from './AddProjectsDialog';
import CardDetails from './ProjectDetails/CardDetails';
import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import NothingToShow from 'components/NothingToShow';

import {
    FILTER_TYPES,
    PROJECTS_STATUS_SELECT,
    DEFAULT_FILTER_TYPE,
    PROJECTS_STATUS_SELECT_MAPPER,
    PROJECTS_STATUS_SELECT_VALUES
} from './ProjectsConstants';

import './Projects.scss';

const ClientProjects = ({
    clientId,
    agencyId,
    addClientProject,
    projects,
    isAdminMode,
    history,
    isDataAvailable,
    fetchClientProjectsPage,
    contactInformation,
    dataFetchState,
    fetchClientInvoicingInformation,
}) => {
    const intl = useIntl();

    const [openDialog, setOpenDialog] = useState(false);
    const [statusFilter, setStatusFilter] = useState(DEFAULT_FILTER_TYPE);
    const [filteredProjects, setFilteredProjects] = useState(projects);

    const requiredFields = ['firstName', 'lastName', 'occupationStatus', 'employmentInformation.generalInfo.hourRate'];

    useEffect(() => {
        fetchClientInvoicingInformation(agencyId, clientId);
        if (!isDataAvailable) {
            fetchClientProjectsPage(agencyId, clientId, requiredFields);
        }
    }, []);

    useEffect(() => {
        const searchParams = history.location?.search;
        let filteredData = projects.slice();
        if (searchParams) {
            setStatusFilter(PROJECTS_STATUS_SELECT_MAPPER[searchParams.split('=')[1]])

            if (searchParams.includes(FILTER_TYPES.ACTIVE)) {
                filteredData = projects.filter(x => {
                    return x.ongoing || moment.utc().isBefore(x?.endDate)
                });
            } else if (searchParams.includes(FILTER_TYPES.PAST)) {
                filteredData = projects.filter(x => {
                    return moment.utc().isAfter(x?.endDate) && !x.ongoing
                });
            }
        }
        setFilteredProjects(filteredData);
    }, [history.location?.search, projects])

    const handleOpenDialog = () => setOpenDialog(true);

    const handleCloseDialog = () => setOpenDialog(false);

    const handleAddProject = projectData => addClientProject(agencyId, clientId, { ...projectData, employees: [], dedicatedEmployees: [] });

    const handleFilterChange = (_, value) => {
        const filterType = PROJECTS_STATUS_SELECT.find(x => x.name === value);
        setStatusFilter(filterType.name)
        const updatedQuery = updateQuery(history.location.search, { status: filterType.name });
        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    return (
        <>
            {!isAdminMode &&
                <PreviousButton
                    text={"Back to Dashboard"}
                    handleClick={() => history.push(`/${getStateAgencyName()}/dashboard`)}
                />
            }
            {dataFetchState?.isDataFetching
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <div className="client-projects-wrapper">
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.SectionTitle sectionTitle={intl.formatMessage({ id: "projects" })} />
                        <SectionActionsWrapper.LeftAlignedActions.SelectFilterList
                            name="status"
                            value={statusFilter}
                            title={intl.formatMessage({ id: "filter-by" })}
                            items={PROJECTS_STATUS_SELECT_VALUES}
                            onChangeHandler={handleFilterChange}
                            withTranslation
                        />
                        {isAdminMode && (
                            <SectionActionsWrapper.RightAlignedActions>
                                <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                                    text={intl.formatMessage({ id: "add-project" })}
                                    onClick={handleOpenDialog}
                                />
                            </SectionActionsWrapper.RightAlignedActions>
                        )}
                    </SectionActionsWrapper>

                    <div className="client-projects-container">
                        {filteredProjects?.length
                            ? filteredProjects.map((project, i) => (
                                <CardDetails
                                    key={i + project.name} project={project}
                                    handleAddProject={handleAddProject}
                                />
                            ))
                            : <NothingToShow items="projects" />
                        }
                    </div>

                    <AddProjectDialog
                        openDialog={openDialog}
                        handleCloseDialog={handleCloseDialog}
                        handleAddProject={handleAddProject}
                        contactInformation={contactInformation}
                    />
                </div>
            }
        </>
    );
};

const mapStateToProps = (state, ownProps) => ({
    projects: getClientProjects(state, ownProps.clientId),
    isAdminMode: isAdminMode(state),
    dataFetchState: getClientsProjectsPageDataFetchState(state),
});

const mapDispatchToProps = {
    addClientProject,
    fetchClientProjectsPage,
    fetchClientInvoicingInformation
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientProjects);
