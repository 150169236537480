import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getEmploymentInformation } from 'reducers';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import './EmployeeEvrotrustStatus.scss';

const EmployeeEvrotrustStatus = ({
    employmentInformation,
}) => {
    const intl = useIntl();

    const evrotrustEmail = employmentInformation?.contractInformation?.evrotrustEmail;
    const employeeEvrotrustStatus = employmentInformation?.employeeEvrotrustStatus || {};

    const {
        isRegistered,
        isIdentified,
        isSupervised,
        isReadyToSign,
        hasConfirmedPhone,
        hasConfirmedEmail
    } = employeeEvrotrustStatus;

    if (!evrotrustEmail) {
        return null;
    }

    return (
        <div className="evrotrust-info-wrapper">
            <h1 className="evrotrust-info-title">{intl.formatMessage({ id: "e-signature-details" })}</h1>
            <div className="evrotrust-info-content">
                <div className="evrotrust-info-subsection">
                    <div className="evrotrust-info-item-wrapper">
                        {isRegistered ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">{intl.formatMessage({ id: "email-registered" })}</p>
                    </div>
                    <div className="evrotrust-info-item-wrapper">
                        {isIdentified ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">{intl.formatMessage({ id: "user-identified" })}</p>
                    </div>
                    <div className="evrotrust-info-item-wrapper">
                        {isSupervised ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">{intl.formatMessage({ id: "successfully-supervised" })}</p>
                    </div>
                </div>
                <div className="evrotrust-info-subsection">
                    <div className="evrotrust-info-item-wrapper">
                        {isReadyToSign ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">{intl.formatMessage({ id: "user-ready-to-sign" })}</p>
                    </div>
                    <div className="evrotrust-info-item-wrapper">
                        {hasConfirmedPhone ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">{intl.formatMessage({ id: "confirmed-phone" })}</p>
                    </div>
                    <div className="evrotrust-info-item-wrapper">
                        {hasConfirmedEmail ? <CheckIcon className="check-icon"/> : <CloseIcon className="close-icon"/>}
                        <p className="evrotrust-info-item">{intl.formatMessage({ id: "confirmed-email" })}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    employmentInformation: getEmploymentInformation(state),
});

export default connect(mapStateToProps, null)(EmployeeEvrotrustStatus);
