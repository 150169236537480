import React, { useRef, useCallback, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import CommonDialog from 'components/Shared/CommonDialog';
import CandidateJourneyUploadFile from './CandidateJourneyUploadFile';
import UploadLinkedInCvDialog from './UploadLinkedInCvDialog';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import uploadLinkedInCv from 'assets/linkedin-upload-cv.gif';

import { updateCandidatePreferences } from 'actions/candidateActions';
import { showNotification } from 'actions/notificationActions';
import { generateRawCv } from 'actions/rawCvActions';

import { getDisplayMode, getCandidatePreferences, getEmployeeUploadedCv, isCandidateMode, getCandidateOverviewPageDataFetchState } from 'reducers';

import { CONTRACTS_MESSAGES, USER_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

import Base64 from 'utils/base64';
import { logEvent } from 'utils/amplitude';

import './CandidateJourneyCv.scss';

const ACCEPTED_FORMAT = ['ppt', 'pptx', 'xlsx', 'csv', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'pdf', 'txt'];

const CandidateJourneyCv = ({
    generateRawCv,
    agencyId,
    userId,
    showNotification,
    role,
    handleClickNextStep,
    uploads,
    setUploads,
    uploadedCv,
    overviewPageDataFetchState,
    isCandidateMode,
    enterEditMode,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const [isFileChanged, setIsFileChanged] = useState(false);
    const [isUploadLinkedInFileOpen, setIsUploadLinkedInFileOpen] = useState(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [newUploadedFile, setNewUploadedFile] = useState({});

    const inputFilesRef = useRef();
    const buttonRef = useRef();

    const inputFilesRefLinkedIn = useRef();
    const buttonRefLinkedIn = useRef();

    const rawCvUploadWrapperRef = useRef();

    const [inputFilesRefState, setInputFilesRefState] = useState(inputFilesRef);

    const dragAndDrop = (ref) => {
        if (ref && ref.current) {
            ref.current.addEventListener("dragover", function (e) {
                e.preventDefault();
                e.target.classList.add('file-hover-animation');
            }, false);

            ['dragleave', 'drop'].forEach(eventName =>
                ref.current.addEventListener(eventName, function (e) {
                    e.preventDefault();
                    e.target.classList.remove('file-hover-animation');
                }, false)
            );
        }
    };

    useEffect(() => {
        if (rawCvUploadWrapperRef.current && history.location.search?.includes('scrollIntoUploadCv') && overviewPageDataFetchState.isDataFetched && !isCandidateMode) {
            rawCvUploadWrapperRef.current.scrollIntoView({ behavior: 'smooth' });
            showNotification(USER_MESSAGES.FIND_A_MATCH_MISSING_CV_WARNING, NOTIFICATION_TYPES.WARNING);
            history.replace();
        }
    }, [rawCvUploadWrapperRef]);

    useEffect(() => {
        dragAndDrop(buttonRef);
        dragAndDrop(buttonRefLinkedIn);
    }, [isUploadLinkedInFileOpen]);

    useEffect(() => {
        //This prevents disappearing file when user clicks 'X' -> goes to another step -> returns to the Upload Resume step
        return () => uploadedCv.originalFileName && setUploads({ type: 'file', filesAttached: [{ name: uploadedCv.originalFileName }] });
    }, [uploadedCv.originalFileName]);

    const handleOpenUploadLinkedInCvDialog = () => setIsUploadLinkedInFileOpen(true);
    const handleCloseUploadLinkedInCvDialog = () => setIsUploadLinkedInFileOpen(false);

    const handleCloseConfirmationDialog = (ref) => {
        setNewUploadedFile({});
        setIsConfirmationDialogOpen(false);
        ref.current.value = '';
    };

    const handleProceed = (ref) => handleGenerateCV(newUploadedFile.filesAttached, ref);

    const handleUpload = (data) => {
        if (Object.keys(uploads).length > 0) {
            setNewUploadedFile(data);
            setIsConfirmationDialogOpen(true);
        } else {
            setUploads(data);
            setIsFileChanged(true);
        }
    };

    const handleOnInputChange = (e) => {
        if (e.target.files.length === 0) {
            return;
        }
        handleUpload({ type: 'file', filesAttached: Array.from(e.target.files) });
    }

    const handleGenerateCV = (files, ref) => {
        if (files.length <= 1) {
            files.forEach(file => {
                if (file.size === 0) {
                    showNotification(CONTRACTS_MESSAGES.ADD_EMPTY_FILE_FAIL, NOTIFICATION_TYPES.ERROR);
                } else {
                    const fileExt = file.name.split('.').pop();

                    if (!ACCEPTED_FORMAT.some(x => x === fileExt)) {
                        showNotification(CONTRACTS_MESSAGES.ADD_FILE_FORMAT_FAIL, NOTIFICATION_TYPES.ERROR);
                    } else {
                        const reader = new FileReader();

                        reader.onload = function (event) {
                            const modifiedDate = (new Date(file.lastModified)).toISOString().substring(0, 10);
                            const base64Text = Base64.encodeArray(event.target.result);

                            const data = new FormData();

                            data.append(`attachedfile1`, file);
                            data.append(`documentAsBase64String`, base64Text);
                            data.append(`documentLastModified`, modifiedDate);

                            if (isCandidateMode) {
                                const action = generateRawCv.bind(null, agencyId, userId, data, role);
                                handleClickNextStep(null, true, action);
                                logEvent(AMPLITUDE_EVENT_TYPES.CANDIDATE_USED_UPLOAD_RESUME);
                            } else {
                                generateRawCv(agencyId, userId, data, role);
                            }
                        }

                        reader.readAsArrayBuffer(file);
                    }
                }
            })
        } else {
            showNotification(CONTRACTS_MESSAGES.MAX_FILES_COUNT_REACHED, NOTIFICATION_TYPES.ERROR);
        }
        ref.current.value = '';
    };

    const handleSave = (ref) => {
        if (!isFileChanged && uploads?.type && isCandidateMode) {
            handleClickNextStep(null, false, null);
        } else if (uploads?.type === 'file') {
            handleGenerateCV(uploads.filesAttached, ref);
        }
    }

    const onDrop = useCallback((acceptedFiles) => handleUpload({ type: 'file', filesAttached: acceptedFiles }));

    const { getRootProps } = useDropzone({ onDrop });

    return (
        <div ref={rawCvUploadWrapperRef} className="candidate-journey-cv-wrapper">
            <PaperElement classes={['raw-cv-profile-content', isMobile ? 'max' : 'sm']}>
                <div className="raw-cv-steps-wrapper">
                    <div className="row">
                        <div className="cols">
                            <div className="col-1of3">
                                <div className="text-info">
                                    <h2>{intl.formatMessage({ id: "upload-resume" })}</h2>
                                    {isCandidateMode
                                        ? <>
                                            <p>Let us know more about you, so our algorithm can suggest the most suitable jobs.</p>
                                            <p>Don't worry if your CV is outdated or uncompleted, you will be able to edit it later.</p>
                                        </>
                                        : <p>{intl.formatMessage({ id: "upload-resume-admin-text" })}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cols">
                            <div className="col-1of3">
                                <CandidateJourneyUploadFile
                                    setInputFilesRefState={setInputFilesRefState}
                                    buttonRef={buttonRef}
                                    handleOnInputChange={handleOnInputChange}
                                    inputFilesRef={inputFilesRef}
                                    getRootProps={getRootProps}
                                    uploads={uploads}
                                    setUploads={setUploads}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PaperElement>

            <div className="journey-buttons-wrapper">
                <SecondaryButton
                    text={isCandidateMode ? 'Use LinkedIn' : intl.formatMessage({ id: "edit" })}
                    handleClick={isCandidateMode ? handleOpenUploadLinkedInCvDialog : enterEditMode} />
                <PrimaryButton
                    text={(!isFileChanged && uploads?.type && isCandidateMode) ? intl.formatMessage({ id: "continue" }) : intl.formatMessage({ id: "save" })}
                    handleClick={() => handleSave(inputFilesRef)}
                    disabled={!uploads?.type} />
            </div>

            <CommonDialog
                handleProceedDialog={() => handleProceed(inputFilesRefState)}
                dialogTitle={intl.formatMessage({ id: "confirm-upload-cv-title" })}
                dialogSubtitle={intl.formatMessage({ id: "confirm-upload-cv-subtitle" })}
                openDialog={isConfirmationDialogOpen}
                handleCloseDialog={() => handleCloseConfirmationDialog(inputFilesRefState)}
                primaryButtonText={intl.formatMessage({ id: "proceed" })}
                secondaryButtonText={intl.formatMessage({ id: "cancel" })}
            />

            <UploadLinkedInCvDialog
                onSave={() => handleSave(inputFilesRef)}
                dialogTitle="Import LinkedIn Resume"
                open={isUploadLinkedInFileOpen}
                onClose={handleCloseUploadLinkedInCvDialog}
                saveBtnText="Save"
                discardBtnText="Discard"
            >
                <Typography variant="body1" className="dialog-text dialog-subtitle">
                    CVs can sometimes feel a bit old-fashioned. Export and upload your LinkedIn profile and we'll complete your profile automatically <a target="_blank" href="https://www.linkedin.com">Open LinkedIn</a>.
                </Typography>

                <img src={uploadLinkedInCv} style={{ width: '100%', height: 'auto' }} />

                <Typography variant="body2" className="dialog-title-text">
                    Upload file
                </Typography>
                <CandidateJourneyUploadFile
                    setInputFilesRefState={setInputFilesRefState}
                    buttonRef={buttonRefLinkedIn}
                    handleOnInputChange={handleOnInputChange}
                    inputFilesRef={inputFilesRefLinkedIn}
                    getRootProps={getRootProps}
                    uploads={uploads}
                    setUploads={setUploads}
                />
            </UploadLinkedInCvDialog>
        </div>
    );
};


const mapStateToProps = state => ({
    isCandidateMode: isCandidateMode(state),
    role: getDisplayMode(state),
    candidatePreferences: getCandidatePreferences(state),
    uploadedCv: getEmployeeUploadedCv(state),
    overviewPageDataFetchState: getCandidateOverviewPageDataFetchState(state),
});

const mapDispatchToProps = {
    generateRawCv,
    showNotification,
    updateCandidatePreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateJourneyCv);
