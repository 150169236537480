import React, { useEffect, createContext, useContext, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { fetchPersonalInfo } from 'actions/employeeActions';

import {
    getEmployeesPersonalInformationDataFetchState,
    getPersonalInfo,
} from 'reducers';

import HistoricalNotes from 'components/Shared/HistoricalNotes';
import PersonalInfoBasicForm from './PersonalInfoBasicForm';
import PersonalInfoContactForm from './PersonalInfoContactForm';
import PersonalInfoPersonalForm from './PersonalInfoPersonalForm';
import ContractInformation from '../EmploymentInformation/ContractInformation';
import ProfileHiredBanner from '../Profile/ProfileHiredBanner';
import { HISTORICAL_NOTES_TYPES } from 'components/Shared/HistoricalNotes/HistoricalNotesConstants';

import CircularProgress from '@material-ui/core/CircularProgress';
import './PersonalInfo.scss';

export const PersonalInfoContext = createContext();

const { Provider } = PersonalInfoContext;

const PersonalInfo = ({
    agencyId,
    userId,
    departmentId,
    fetchPersonalInfo,
    children,
    dataFetchState,
}) => {
    const context = { userId, agencyId, departmentId, dataFetchState };

    useEffect(() => {
        fetchPersonalInfo(agencyId, userId, null, null);
    }, []);

    return (
        <Provider value={context}>
            <div className="personal-info-content">
                {
                    dataFetchState?.isDataFetching
                        ? <CircularProgress size={50} disableShrink className="page-loader" />
                        : <div>
                            {children}
                        </div>
                }
            </div>
        </Provider>
    );
};

const BasicInfo = () => {
    const { userId, agencyId, departmentId } = useContext(PersonalInfoContext);

    return (
        <PersonalInfoBasicForm
            agencyId={agencyId}
            employeeId={userId}
            departmentId={departmentId}
            isDepartmentView={!!departmentId}
        />
    )
};

const ContactInfo = () => {
    const { userId, agencyId, departmentId } = useContext(PersonalInfoContext);

    return (
        <PersonalInfoContactForm
            agencyId={agencyId}
            employeeId={userId}
            departmentId={departmentId}
        />
    )
};

const PersonalInfoForm = () => {
    const { userId, agencyId, departmentId } = useContext(PersonalInfoContext);

    return (
        <PersonalInfoPersonalForm
            agencyId={agencyId}
            employeeId={userId}
            departmentId={departmentId}
        />
    )
};

const ContractInfo = () => {
    const { userId, agencyId, departmentId } = useContext(PersonalInfoContext);

    return (
        <ContractInformation
            agencyId={agencyId}
            employeeId={userId}
            departmentId={departmentId}
        />
    )
};

const ProfileHistoricalNotes = () => {
    const { userId, departmentId, dataFetchState } = useContext(PersonalInfoContext);
    const historicalNotesRef = useRef();
    const history = useHistory();

    useEffect(() => {
        if (historicalNotesRef.current && history.location.search?.includes('scrollIntoHistoricalNotes') && dataFetchState.isDataFetched) {
            historicalNotesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [historicalNotesRef, dataFetchState]);

    return (
        <div ref={historicalNotesRef}>
            <HistoricalNotes
                departmentId={departmentId}
                targetId={userId}
                noteType={HISTORICAL_NOTES_TYPES.USER}
            />
        </div>
    );
};


PersonalInfo.BasicForm = BasicInfo;
PersonalInfo.ContractForm = ContractInfo;
PersonalInfo.ContactForm = ContactInfo;
PersonalInfo.PersonalForm = PersonalInfoForm;
PersonalInfo.HiredBanner = ProfileHiredBanner;
PersonalInfo.HistoricalNotes = ProfileHistoricalNotes;

const mapStateToProps = state => ({
    dataFetchState: getEmployeesPersonalInformationDataFetchState(state),
    personalInfo: getPersonalInfo(state),
});

const mapDispatchToProps = {
    fetchPersonalInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
