import api from './api';
import requester from './requester';
import { URL_QUERY_ACTION_TYPES } from 'constants/typeConstants';

const agencyService = {
    updateBasicInfo: (agencyId, basicInfo) => requester(api.agencyInfo(agencyId)).create(basicInfo),
    fetchAgencyInfo: (agencyId, departmentId) => requester(api.agencyInfo(agencyId, departmentId)).getOne(),
    uploadLogo: (croppedImgData, agencyId) => requester(api.agencyLogo(agencyId)).uploadFile(croppedImgData),
    deleteLogo: agencyId => requester(api.agencyLogo(agencyId)).delete(),
    fetchEmployeeSettings: agencyId => requester(api.agencyEmployeeSettings(agencyId)).getOne(),
    updateHolidayAllowance: (holidayAllowance, agencyId) => requester(api.agencyHolidaysUpdate(agencyId)).update(holidayAllowance),
    updatePerformanceCriterias: (criterias, agencyId) => requester(api.agencyPerformanceCriterias(agencyId)).update(criterias),
    fetchTimeManagementTimesheets: (agencyId, month, query) => requester(api.agencyTimesheets(agencyId, month, query)).getMany(),
    getExportedTimesheets: (agencyId, employeeIds, months, fileName, trackedMonths) =>
        requester(api.agencyTimesheetsExport(agencyId, employeeIds, months, URL_QUERY_ACTION_TYPES.DOWNLOAD)).saveAsAction(fileName, trackedMonths),
    fetchPendingHolidays: (agencyId, query) => requester(api.agencyPendingHolidays(agencyId, query)).getMany(),
    updateHolidayRequests: (agencyId, type, request) => requester(api.updateAgencyHolidayRequests(agencyId, type)).updateMany(request),
    getAgencyPerformanceCriterias: (agencyId, query) => requester(api.agencyPerformanceCriterias(agencyId, query)).getMany(),
    fetchReviewedHolidays: (agencyId, query) => requester(api.agencyReviewedHolidays(agencyId, query)).getMany(),
    fetchSickLeaves: (agencyId, from, to) => requester(api.agencySickLeaves(agencyId, from, to)).getMany(),
    fetchHomeInformation: (agencyId, userData) => requester(api.homeInformation(agencyId, userData.userId, userData.role)).getOne(),
    updateHomeInformation: (agencyId, userId, updateData) => requester(api.homeInformation(agencyId, userId)).updatePatch(updateData),
    fetchAdmins: (agencyId, query) => requester(api.admins(agencyId, query)).getMany(),
    fetchImportantDocuments: (agencyId, query) => requester(api.agencyImportantDocuments(agencyId, query)).getMany(),
    uploadImportantDocuments: (agencyId, onboardingData) => requester(api.agencyImportantDocuments(agencyId)).uploadFile(onboardingData),
    deleteImportantDocument: (agencyId, importnatDocument) => requester(api.agencyImportantDocuments(agencyId)).update(importnatDocument),
    downloadImportantDocuments: (agencyId, documentId, fileName) => requester(api.agencyDownloadImportantDocuments(agencyId, documentId)).saveAs(fileName),
    addTraining: (agencyId, training) => requester(api.agencyTrainings(agencyId)).create(training),
    removeTraining: (agencyId, trainingId) => requester(api.agencySingleTraining(agencyId, trainingId)).delete(),
    fetchTrainings: (agencyId, query) => requester(api.agencyTrainings(agencyId, query)).getMany(),
    updateTraining: (agencyId, trainingId, training) => requester(api.agencySingleTraining(agencyId, trainingId)).update(training),
    uploadTrainingFiles: (agencyId, trainingId, topicId, fileData) => requester(api.agencyTrainingTopicUploadFiles(agencyId, trainingId, topicId)).uploadFile(fileData),
    downloadTrainingFile: (agencyId, trainingId, topicId, fullFileName, saveAsName) =>
        requester(api.agencyTrainingTopicDownloadFile(agencyId, trainingId, topicId, fullFileName)).saveAs(saveAsName),
    deleteTrainingFile: (agencyId, trainingId, topicId, fileName) => requester(api.agencyTrainingTopicFileDelete(agencyId, trainingId, topicId, fileName)).delete(),

    addTrainingTopicVideo: (agencyId, trainingId, topicId, video) => requester(api.createTrainingTopicVideo(agencyId, trainingId, topicId)).create(video),
    removeTrainingTopicVideo: (agencyId, trainingId, topicId, videoId) => requester(api.deleteTrainingTopicVideo(agencyId, trainingId, topicId, videoId)).delete(),

    fetchAgencyProjects: (agencyId) => requester(api.agencyProjects(agencyId)).getMany(),
    fetchAgencyTasks: (agencyId) => requester(api.agencyTasks(agencyId)).getMany(),
    fetchTraining: (agencyId, trainingId) => requester(api.agencySingleTraining(agencyId, trainingId)).getOne(),
    addTrainingTopic: (agencyId, trainingId, topic) => requester(api.agencyTrainingTopics(agencyId, trainingId)).create(topic),
    removeTrainingTopic: (agencyId, trainingId, topicId) => requester(api.agencyTrainingSingleTopic(agencyId, trainingId, topicId)).delete(),
    updateTrainingTopic: (agencyId, trainingId, topicId, data) => requester(api.agencyTrainingSingleTopic(agencyId, trainingId, topicId)).update(data),
    updateTrainingTopicDescription: (agencyId, trainingId, topicId, data) => requester(api.agencyTrainingSingleTopicDescription(agencyId, trainingId, topicId)).update(data),
    updateTask: (agencyId, task) => requester(api.agencyTask(agencyId, task._id)).update(task),
    deleteTask: (agencyId, taskId) => requester(api.agencyTask(agencyId, taskId)).delete(),
    createTask: (agencyId, task) => requester(api.agencyTasks(agencyId)).create(task),
    fetchAgencyDashboardInformation: (agencyId, from, to, fields, roles) => requester(api.agencyDashboardInformation(agencyId, from, to, fields, roles)).getOne(),
    updateAgencyEvrotrustInformation: (agencyId, data) => requester(api.agencyEvrotrustInformation(agencyId)).update(data),
    updateAgencyIsUsingEvrotrust: (agencyId, data) => requester(api.agencyEvrotrustUsage(agencyId)).update(data),
    updateAgencyCalendlyInformation: (agencyId, data) => requester(api.agencyCalendlyInformation(agencyId)).update(data),
    fetchAgencyEvrotrustInformation: (agencyId) => requester(api.agencyEvrotrustInformation(agencyId)).getOne(),
    fetchAgencyEvrotrustIntegration: (agencyId) => requester(api.agencyEvrotrustIntegration(agencyId)).getOne(),
    fetchAgencyCalendlyInformation: (agencyId) => requester(api.agencyCalendlyInformation(agencyId)).getOne(),
    fetchUserManagementPage: (agencyId, fields, roles, filters) => requester(api.userManagementPage(agencyId, fields, roles, filters)).getOne(),
    fetchRawCvProgress: (agencyId, userId) => requester(api.employeeRawCvProgress(agencyId, userId)).getOne(),
    generateRawCv: (agencyId, userId, files) => requester(api.employeeRawCv(agencyId, userId)).uploadFile(files),
    updateRawCvGenerated: (agencyId, employeeId, data) => requester(api.employeeRawCv(agencyId, employeeId)).update(data),
    updateTermsAndConditionsVersion: (agencyId) => requester(api.termsAndConditionsVersion(agencyId)).update(),
    fetchTermsAndConditions: (agencyId) => requester(api.termsAndConditionsVersion(agencyId)).getOne(),
    fetchAgencyName: (agencyId) => requester(api.agencyName(agencyId)).getOne(),
    fetchAgencyMainAdmin: (agencyId) => requester(api.agencyMainAdmin(agencyId)).getOne(),
    validateEmployeesInformation: (agencyId, type, employeeIds, departmentId) => requester(api.holidayInformationValidity(agencyId, type, employeeIds, departmentId)).getOne(),
    fetchIsOnboardingPassed: (userId) => requester(api.isOnboardingPassed(userId)).getOne(),
    fetchApprovedHolidays: (agencyId, from, to) => requester(api.agencyApprovedHolidays(agencyId, from, to)).getMany(),
    fetchMainMenuInformation: (agencyId, userId, role) => requester(api.mainMenuInformation(agencyId, userId, role)).getOne(),
    fetchAgencySettings: (agencyId) => requester(api.agencySettings(agencyId)).getOne(),
    updateAgencySettings: (agencyId, newSettings) => requester(api.agencySettings(agencyId)).update(newSettings),
    generateSentryError: (agencyId) => requester(api.generateSentryError(agencyId)).getOne(),
    fetchAgencyDeletedUsers: (agencyId, query) => requester(api.agencyDeletedUsers(agencyId, query)).getMany(),
};

export default agencyService;
