import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';

export const GreenCheckbox = withStyles({
    root: {
      color: '#808495',
      '&$checked': {
        color: '#16D696',
      },
    },
    checked: {},
  })((props) => <Checkbox color='default' {...props} />);

  export const yearsExperience = [
    { _id: 1, minValue: 0, maxValue: 1, label: '< 1 year' },
    { _id: 2, minValue: 1, maxValue: 3, label: '1 - 3 years' },   
    { _id: 3, minValue: 3, maxValue: 5, label: '3 - 5 years' },   
    { _id: 4, minValue: 5, maxValue: 100, label: '5 + years' },   
  ];
