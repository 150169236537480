import React from "react";
import { connect } from "react-redux";

import { getAgencyName } from "reducers";

import Paper from "@material-ui/core/Paper";

import "./PaperElement.scss";

const PaperElement = ({
    classes = [],
    children,
}) => {
    return (
        <Paper className={`paper-element-container ${classes.join(" ")}`}>
            {children}
        </Paper>
    );
};

const mapStateToProps = (state) => ({
    agencyName: getAgencyName(state),
});

export default connect(mapStateToProps)(PaperElement);
