import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ErrorMessage } from 'formik';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import FeedbackDialogWrapper from 'components/Shared/FeedbackDialog';

import { DIALOG_COMMENT_INPUT } from 'constants/commonConstants';
import { ROLES } from 'constants/userConstants';
import { BULGARIAN_LABEL } from 'constants/languages';

const PerformanceReviewFormView = ({
    values,
    errors,
    touched,
    performanceCriterias,
    setFieldValue,
    performanceToEdit,
    handleChange,
    role,
    displayMode,
    selectedLanguage,
    agencyRouteName,
}) => {
    const intl = useIntl();

    const getGeneralMessage = () => {
        if (selectedLanguage === BULGARIAN_LABEL) {
            return (
                <p className="general-msg">Ако желаете, можете да зададете критерии за ефективност за вашите оценки в <Link to={`/${agencyRouteName}/settings/employee-settings`} className="settings-link">Настройки на служителите</Link> в менюто Настройки.</p>
            );
        } else {
            return (
                <p className="general-msg">If you wish you can set performance criteria for your reviews in <Link to={`/${agencyRouteName}/settings/employee-settings`} className="settings-link">Employee Settings</Link> under the Settings menu.</p>
            );
        }
    };

    const getFeedbackMessageText = () => {
        if (selectedLanguage === BULGARIAN_LABEL) {
            return "Обратната връзка, която предоставяте в следното поле, ще бъде изпратена на служителя. Моля, дайте конструктивни и изчерпателни коментари.";
        } else {
            return "The feedback you provide in the following field will be sent to the employee. Please provide constructive and comprehensive comments.";
        }
    };

    return <>
        <TextField
            className="reviewer"
            label={intl.formatMessage({ id: "reviewer" })}
            name="reviewer"
            variant="outlined"
            value={values.reviewer || performanceToEdit.reviewer}
            disabled
        />

        <div className="rating-container">
            <Typography variant="h5" className="rating-criteria-title">{intl.formatMessage({ id: "rating-criteria" })}</Typography>

            {performanceCriterias.length > 0
                ? <FeedbackDialogWrapper.RatingCriteriaWrapper>
                    {performanceCriterias.map((criteria, i) => (
                        <FeedbackDialogWrapper.RatingCriteriaItem id={`${i}_${criteria}`} name={criteria} title={criteria} readOnly={displayMode === ROLES.EMPLOYEE} />
                    ))}
                </FeedbackDialogWrapper.RatingCriteriaWrapper>
                : displayMode === ROLES.ADMIN
                    ? getGeneralMessage()
                    : null
            }
            {displayMode === ROLES.ADMIN && <p className="feedback-msg">{getFeedbackMessageText()}</p>}
        </div>

        <TextField
            className="overall-performance"
            label={intl.formatMessage({ id: "overall-feedback" })}
            name="summary"
            multiline
            minRows={DIALOG_COMMENT_INPUT.ROWS}
            maxRows={DIALOG_COMMENT_INPUT.MAX_ROWS}
            variant="outlined"
            value={values.summary || performanceToEdit.summary}
            onChange={handleChange}
            error={errors.summary && touched.summary}
            disabled={role === ROLES.EMPLOYEE}
        />
        <ErrorMessage name="summary" component="div" className="invalid-field-message" />
    </>
}

export default PerformanceReviewFormView
