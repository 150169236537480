import moment from 'moment';

import { MONTH_YEAR_DATE_FORMAT } from 'constants/commonConstants';
import { FORMAT_TIMES_MAP } from 'constants/timesFormat';

export const formatTimes = {
    date: (date) => date ? moment(date).format(FORMAT_TIMES_MAP.DATE) : null,
    time: (time) => time ? moment(time).format(FORMAT_TIMES_MAP.TIME) : null,
};

export const localToUtcDateAndTime = (localDate, utcDate) => {
    const year = utcDate.year();
    const month = utcDate.month();
    const day = utcDate.date();
    const hours = utcDate.hour();
    const minutes = utcDate.minute();

    localDate.setFullYear(year, month, day);
    localDate.setHours(hours);
    localDate.setMinutes(minutes);
};


// The below function gets a date, turns it to a utc date, adds the utc offset and returns the newDate
export const addUtcOffset = (date, customFormat) => {
    const initialDate = moment(date);
    const utcOffset = moment(initialDate).utcOffset();
    const utcDate = moment.utc(initialDate);

    const newUtcOffsettedDate = customFormat 
        ? moment(utcDate).add(utcOffset, 'minutes').format(customFormat)
        : moment(utcDate).add(utcOffset, 'minutes').toISOString();

    return newUtcOffsettedDate;
};

export const getMonths = (startDate, endDate) => {
    if (endDate) {
        return Array.from({ length: endDate.diff(startDate, 'month') + 1 })
            .map((value, index) => moment(startDate).add(index, 'month').format(MONTH_YEAR_DATE_FORMAT));
    }

    return moment(startDate).format(MONTH_YEAR_DATE_FORMAT);
};

export const setTimeFromTimezone = (date, timezone) => {
    const initialDate = moment(date);

    // get datetime in current timezone
    const todayInTimezone = moment().tz(timezone);

    // set time to selected one
    todayInTimezone.set({
        hour: initialDate.get('hour'),
        minutes: initialDate.get('minute')
    });

    return todayInTimezone;
};

export const secondsToCounterString = (seconds) => new Date(seconds * 1000).toISOString().substr(11, 8);

export const calculateDaysLeft = (initialDate, totalDaysUntilEndDate) => {
    const today = moment().utc();

    const daysLeft = moment(initialDate).utc().add(totalDaysUntilEndDate, 'days').diff(today, 'days');
    const daysLeftInPercentage = 100 - (totalDaysUntilEndDate - daysLeft) * 100 / totalDaysUntilEndDate;

    if (daysLeft <= 0) {
        return { daysLeft: 0, daysLeftInPercentage: 0 }
    }

    return { daysLeft, daysLeftInPercentage: Math.round(daysLeftInPercentage) };
};

export const getDifferenceInDays = (date) => {
    const today = moment().utc().startOf('day');
    const differenceInDaysFromDateOfRate = today.diff(moment(date).utc().startOf('day'), 'days');

    return differenceInDaysFromDateOfRate;
};

export const transformDifferenceInDaysToText = (differenceInDays, intl) => {
    if (differenceInDays === 0) {
        return intl.formatMessage({ id: "today" });
    } else if (differenceInDays === 1) {
        return intl.formatMessage({ id: "one-day-ago" });
    }

    return intl.formatMessage({ id: "days-ago" }).replace("{}", differenceInDays);
};
