import moment from 'moment';

import {
    FULL_DAY_ONLY,
    MONTH_YEAR_DATE_FORMAT,
    DATE_FORMAT_WITH_DOTS,
    DATE_FORMAT_WITH_SLASH_SHORT_YEAR,
    DATE_FORMAT_WITH_DOTS_FULL_YEAR,
} from 'constants/commonConstants';

export const parseStringDateRangeToDates = (dates, datesFormat) =>
    dates?.length > 1 ? dates.split('-').map(date => moment(date, datesFormat)) : [];

export const formatDateRangeToString = (from, to) => {
    const parsedFromDate = moment(from, DATE_FORMAT_WITH_SLASH_SHORT_YEAR);
    const parsedToDate = moment(to, DATE_FORMAT_WITH_SLASH_SHORT_YEAR)
    const isMoreThanOneMonth = moment(parsedFromDate).format(MONTH_YEAR_DATE_FORMAT) !== moment(parsedToDate).format(MONTH_YEAR_DATE_FORMAT);

    from = isMoreThanOneMonth ? parsedFromDate.format(DATE_FORMAT_WITH_DOTS) : parsedFromDate.format(FULL_DAY_ONLY);
    to = parsedToDate.format(DATE_FORMAT_WITH_DOTS);

    return `${from} - ${to}`;
};

export const getExportFileName = (documentsData=[]) => {
    let fileName = '';

    if (documentsData.length === 1) {
        const document = documentsData[0];
        const employeeName = `${document.user.firstName} ${document.user.lastName}`;
        const uploadDate = moment(document.uploadDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)
        fileName = `${employeeName}_Contract_uploaded_on_${uploadDate}`
    } else {
        fileName = `WIA_Contracts_Export_as_of_${moment().format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`;
    }

    return fileName;
};
