import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import AdditionalMenu from './AdditionalMenu';
import AdditionalMenuMobile from './AdditionalMenuMobile';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ArrowLeftSharpIcon from '@material-ui/icons/ArrowLeftSharp';

import LanguageSelector from './LanguageSelector';

import { toggleNavigation, toggleMobileNavigation } from 'actions/uiActions';
import { logout, switchMode } from 'actions/authActions';
import {
    fetchAgencyBasicInfo,
    setHomeInformationInitialState,
    fetchMainMenuInformation,
} from 'actions/agencyActions';
import { fetchAgencyPublicHolidays } from 'actions/publicHolidaysActions';
import {
    getDisplayMode,
    getCurrentUser,
    isAdmin,
    getAgencyName,
    isHiddenSections,
    isAuthenticated as isUserAuthenticated,
    getAccess,
    getAgencyRouteName,
    getMenuInformation,
    getToggleMobileNavigation,
} from 'reducers';

import { getPixelsAsNumber, getStateAgencyName, useWindowDimensions } from 'utils/helpers';
import { logEvent } from 'utils/amplitude';

import defaultLogo from 'assets/default-logo.svg';

import { getMenuForRole } from './constants/NavigationConstants';
import { ROLES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { BASE_APP_TRADEMARK } from 'constants/env';

import styleConstants from 'style-constants.scss';

import './Navigation.scss';

const Navigation = ({
    logout,
    displayMode,
    switchMode,
    user,
    isAdmin,
    agencyName,
    agencyRouteName,
    hiddenSections,
    fetchAgencyBasicInfo,
    setHomeInformationInitialState,
    isUserAuthenticated,
    accessAsManager,
    toggleNavigation,
    toggleNavigationValue,
    toggleMobileNavigation,
    toggleMobileNavigationValue,
    fetchAgencyPublicHolidays,
    fetchMainMenuInformation,
    mainMenuInformation,
}) => {
    const history = useHistory();
    const { width } = useWindowDimensions();

    const toggleNavigationHandler = () => toggleNavigation(!toggleNavigationValue);
    const closeMobileNavigationHandler = () => toggleMobileNavigation(false);

    const mainNavScroller = document.getElementById("main-navigation");
    const defaultAvatar = [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN, ROLES.RECRUITER].includes(displayMode) ? defaultLogo : '';

    useEffect(() => {
        if (isUserAuthenticated && user.agencyId && displayMode !== ROLES.CLIENT) {
            fetchAgencyBasicInfo(user.agencyId);
            fetchAgencyPublicHolidays(user.agencyId, user._id, displayMode);
        }
    }, [displayMode, user.agencyId]);


    useEffect(() => {
        if (!toggleNavigationValue
            && width >= getPixelsAsNumber(styleConstants.desktop)
            && width <= getPixelsAsNumber(styleConstants.smallDesktop)
        ) {
            toggleNavigation(true);
        }
    }, [width]);

    useEffect(() => {
        if (mainNavScroller) {
            mainNavScroller.scrollTo(0, 0);
        }
    }, [toggleMobileNavigationValue]);

    const handleLogout = () => {
        logout().then(() => history.push('/login?logout=true'));
    }

    const handleSwitchRoleMode = () => {
        const updatedRole = displayMode === ROLES.ADMIN ? ROLES.EMPLOYEE : ROLES.ADMIN;

        if (updatedRole === ROLES.EMPLOYEE) {
            logEvent(AMPLITUDE_EVENT_TYPES.SWITCH_TO_EMPLOYEE_MODE);
        }

        setHomeInformationInitialState();
        switchMode(updatedRole);
        fetchMainMenuInformation(user.agencyId, user._id, updatedRole);
        history.push(`/${getStateAgencyName()}/dashboard`);
    };

    const handleGoToTCLink = () => {
        history.push(`/${getStateAgencyName()}/terms-and-conditions`);
    }

    const modeText = displayMode === 'admin' ? 'Employee' : 'Admin';

    return isUserAuthenticated && (
        <Drawer id="main-navigation" variant="permanent" className={'navigation ' + (toggleNavigationValue ? 'navigation-close ' : '') + (!toggleMobileNavigationValue ? 'navigation-mobile-hide' : '')} open={true}>
            <IconButton className="close-mobile-navigation-button" onClick={closeMobileNavigationHandler}>
                <KeyboardBackspaceIcon />
            </IconButton>

            <div className="navigation-header">
                <div className="navigation-header-content">
                    <Avatar
                        alt="Company logo"
                        className="company-logo"
                        src={mainMenuInformation.menuAvatar || defaultAvatar}
                    />
                    <Typography variant="h5" className="header-title">
                        {mainMenuInformation.menuName}
                    </Typography>
                </div>
                <div className="navigation-header-divider-wrapper">
                    <Divider className="header-divider" />
                    <ArrowLeftSharpIcon
                        className="header-arrow-icon"
                        onClick={toggleNavigationHandler}
                    />
                </div>
            </div>

            {getMenuForRole(displayMode, accessAsManager, hiddenSections, agencyRouteName, user.agencyId, user._id)}

            {(displayMode === ROLES.ADMIN || displayMode === ROLES.EMPLOYEE) && <LanguageSelector userId={user._id} />}

            <div className="navigation-footer">
                <BASE_APP_TRADEMARK.LOGO className="logo" idSuffix={uuid()} />
                <AdditionalMenu
                    handleLogout={handleLogout}
                    isAdmin={isAdmin}
                    switchRoleMode={handleSwitchRoleMode}
                    displayMode={displayMode}
                    handleGoToTCLink={handleGoToTCLink}
                    modeText={modeText}
                    hiddenSections={hiddenSections} />
            </div>

            <div className="navigation-footer-mobile-wrapper">
                <AdditionalMenuMobile
                    handleLogout={handleLogout}
                    isAdmin={isAdmin}
                    handleSwitchRoleMode={handleSwitchRoleMode}
                    displayMode={displayMode}
                    handleGoToTCLink={handleGoToTCLink}
                    modeText={modeText}
                    closeMobileNavigationHandler={closeMobileNavigationHandler} />
            </div>

        </Drawer>
    );
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
    user: getCurrentUser(state),
    isAdmin: isAdmin(state),
    agencyName: getAgencyName(state),
    hiddenSections: isHiddenSections(state),
    isUserAuthenticated: isUserAuthenticated(state),
    accessAsManager: getAccess(state),
    toggleMobileNavigationValue: getToggleMobileNavigation(state),
    agencyRouteName: getAgencyRouteName(state),
    mainMenuInformation: getMenuInformation(state),
});

const mapDispatchToProps = {
    logout,
    switchMode,
    fetchAgencyBasicInfo,
    setHomeInformationInitialState,
    toggleNavigation,
    toggleMobileNavigation,
    fetchAgencyPublicHolidays,
    fetchMainMenuInformation,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Navigation);
