import React from 'react';

import ClientCustomerSupportRequestForm from './ClientCustomerSupportRequestForm';

import './ClientCustomerSupport.scss';

const ClientCustomerSupport = ({
    requestCustomerSupport,
    accountManager,
}) => {

    return (
        <div className="client-customer-support-wrapper">
            <ClientCustomerSupportRequestForm
                requestCustomerSupport={requestCustomerSupport}
                accountManager={accountManager}
            />
        </div>
    );
};

export default ClientCustomerSupport;
