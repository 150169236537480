import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MenuItem from '@material-ui/core/MenuItem';

import isHoliday from 'hoc/isHoliday';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, DIALOG_COMMENT_INPUT } from 'constants/commonConstants';

import './CreateEditTaskFormView.scss';

const CreateEditTaskFormView = ({
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    taskAdmins,
}) => {
    const intl = useIntl();

    return (
        <form className="create-task-form" autoComplete="off">
            <div className="task-name-field">
                <TextField
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "name" })}
                    error={errors.name && touched.name}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>
            <div className="task-middle-block">
                <div className="task-relatedTo-field">
                    <TextField
                        name="relatedTo"
                        disabled
                        value={values.relatedTo.name}
                        onChange={handleChange}
                        label={intl.formatMessage({ id: "related-to" })}
                        error={errors.relatedTo && touched.relatedTo}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <ErrorMessage name="relatedTo" component="div" className="invalid-field-message" />
                </div>
                <div className="task-owner-field">
                    <TextField
                        name="owner"
                        select
                        value={values.owner}
                        onChange={(event) => {
                            const { _id } = taskAdmins.find(x => x._id === event.target.value);
                            setFieldValue('owner', _id);
                        }}
                        label={intl.formatMessage({ id: "owner" })}
                        error={errors.owner && touched.owner}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    >
                        {taskAdmins.map((user, i) => (
                            <MenuItem
                                native='true'
                                key={i}
                                value={user._id}
                            >
                                {user.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ErrorMessage name="owner" component="div" className="invalid-field-message" />
                </div>
            </div>
            <div className="task-description-field">
                <TextField
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "description" })}
                    error={errors.description && touched.description}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={DIALOG_COMMENT_INPUT.ROWS}
                />
                <ErrorMessage name="description" component="div" className="invalid-field-message" />
            </div>
            <div>
                <div className="switch-wrapper">
                    <FormControlLabel
                        control={<Switch
                            name="reminder"
                            checked={values.reminder}
                            onChange={handleChange}
                            size="small"
                            color="primary" />}
                        label={intl.formatMessage({ id: "add-a-reminder" })}
                        labelPlacement="start"
                        className="switch-label"
                    />
                </div>
                {((values._id !== '' && values.dateTime) || (values.reminder === true))
                    && <div className="reminder-datetime-block">
                        <KeyboardDatePicker
                            autoOk={true}
                            className="task-date-field"
                            label={intl.formatMessage({ id: "date" })}
                            disableToolbar
                            disabled={!values.reminder}
                            disablePast
                            variant="inline"
                            inputVariant="outlined"
                            fullWidth
                            value={values.date}
                            onChange={value => {
                                setFieldValue('date', value);
                            }}
                            renderDay={isHoliday}
                            format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                            allowKeyboardControl={true}
                        />

                        <KeyboardTimePicker
                            className={values.date ? "task-time-field" : "task-time-field hidden"}
                            ampm={false}
                            variant="outlined"
                            label={intl.formatMessage({ id: "time" })}
                            fullWidth
                            disabled={!values.reminder}
                            inputVariant="outlined"
                            value={values.time}
                            keyboardIcon={
                                <NotificationsNoneIcon
                                    className={values.reminder ? 'datetime-icon' : 'datetime-icon-disabled'} />
                            }
                            renderDay={isHoliday}
                            onChange={value => {
                                setFieldValue("time", value);
                            }}
                        />
                    </div>
                }
            </div>
        </form >
    );
}

export default CreateEditTaskFormView;
