import React from 'react';
import { useIntl } from 'react-intl';

import ConfirmDialog from '../ConfirmDialog';

import { CONFIRM_DIALOG_DELETION_CONFIRMATION_ITEMS } from '../ConfirmDialog/ConfirmDialogConstants'

import './ConfirmDeleteDialog.scss';

const ConfirmDeleteDialog = ({
    itemToDelete,
    handleDeleteItem,
    openDialog,
    handleCloseDialog,
}) => {
    const intl = useIntl();

    return (
        <ConfirmDialog
            openDialog={openDialog}
            dialogTitle={CONFIRM_DIALOG_DELETION_CONFIRMATION_ITEMS.GET_DIALOG_TITLE(intl, itemToDelete)}
            confirmButtonName={CONFIRM_DIALOG_DELETION_CONFIRMATION_ITEMS.CONFIRM_BUTTON_NAME(intl)}
            discardButtonName={CONFIRM_DIALOG_DELETION_CONFIRMATION_ITEMS.DISCARD_BUTTON_NAME(intl)}
            handleConfirmDialog={handleDeleteItem}
            handleCloseDialog={handleCloseDialog}
        />
    )
};

export default ConfirmDeleteDialog;
