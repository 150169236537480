import React from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';
import moment from 'moment';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Dialog from 'components/Shared/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AddEditProjectFormView from './AddEditProjectFormView';

import { addUtcOffset } from 'utils/formatTimes';
import {
    validateRequiredFields,
    validateInputLength,
    validateInputForWhiteSpaces,
    validateDateFields,
    validateEndDateNotBeforeStartDate,
    validateStartDateNotBeforeEndDate
} from 'utils/formValidations';

import { inputFieldMaxLength } from './AddEditProjectDialogConstants';

import './AddEditProjectDialog.scss';

const validateTechnologyField = (values, fieldName) => {
    return {
        ...validateInputForWhiteSpaces(values, [fieldName]),
        ...validateInputLength(values, [
            { property: fieldName, maxLength: inputFieldMaxLength.technologyField },
        ]),
    }
}

const AddEditProjectDialog = ({
    projectEntry,
    displayMode,
    templates,
    fromTemplate,
    openDialog,
    setFromTemplate,
    handleCloseDialog,
    handleAddEntry,
    handleEditEntry,
    handleAddProjectTemplate,
    handleEditProjectTemplate,
    handleSelectProjectTemplate,
    renderHolidays,
}) => {
    const intl = useIntl();

    templates = displayMode === 'admin' ? templates : [];

    const isTechInputFocused = () => {
        return document.activeElement === document.getElementById('tech-name');
    }

    const clickAddTech = () => {
        document.getElementById('add-tech-btn').click();
    }

    const handleCharsMap = (str, chars) => {
        const strChars = Object.keys(chars).join('|');
        const reg = new RegExp(strChars, 'gi');

        return str.replace(reg, matched => chars[matched]);
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>

            <Formik
                enableReinitialize
                initialValues={{
                    name: projectEntry?.name || '',
                    client: projectEntry?.client || '',
                    position: projectEntry?.position || '',
                    description: projectEntry?.description || '',
                    startDate: projectEntry?.startDate ? moment.utc(projectEntry.startDate) : null,
                    endDate: projectEntry?.endDate ? moment.utc(projectEntry.endDate) : null,
                    datesUnknown: false,
                    currentlyWork: projectEntry?.currentlyWork || false,
                    technologies: projectEntry?.technologies || [],
                    technology: '',
                    isExisting: projectEntry?.isExisting || false,
                }}
                validate={values => {
                    if (isTechInputFocused()) {
                        return {};
                    }

                    const validateDates = values.datesUnknown
                        ? {}
                        : {
                            ...validateDateFields(values, ['startDate', 'endDate']),
                            ...validateEndDateNotBeforeStartDate(values, 'startDate', 'endDate'),
                            ...validateStartDateNotBeforeEndDate(values, 'startDate', 'endDate', false),
                        };

                    return {
                        ...validateInputForWhiteSpaces(values, ['name']),
                        ...validateRequiredFields(values, ['name']),
                        ...validateInputLength(values, [
                            { property: 'name', maxLength: inputFieldMaxLength.name },
                            { property: 'client', maxLength: inputFieldMaxLength.client },
                            { property: 'description', maxLength: inputFieldMaxLength.description },
                            { property: 'position', maxLength: inputFieldMaxLength.position },
                        ]),
                        ...validateDates,
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    if (isTechInputFocused()) {
                        clickAddTech();
                        return;
                    }

                    setSubmitting(false);

                    const {
                        name,
                        client,
                        position,
                        description,
                        startDate,
                        endDate,
                        currentlyWork,
                        technologies,
                        isExisting,
                        datesUnknown
                    } = values;

                    const entry = {
                        name,
                        client,
                        position,
                        description,
                        startDate: datesUnknown ? '' : addUtcOffset(startDate),
                        endDate: datesUnknown ? '' : addUtcOffset(endDate),
                        currentlyWork,
                        technologies
                    };

                    if (fromTemplate) {
                        if (templates.some(t => t.name === values.name)) {
                            const id = templates.find(t => t.name === values.name)._id;
                            handleEditProjectTemplate({ ...entry, _id: id });
                        } else {
                            handleAddProjectTemplate(entry);
                        }
                    }

                    isExisting
                        ? handleEditEntry({ _id: projectEntry._id, ...entry })
                        : handleAddEntry(entry);

                    handleCloseDialog();
                }}
            >
                {(props) =>
                    <Dialog className="add-edit-project-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
                        <MuiDialogTitle disableTypography className="dialog-title">
                            <Typography variant="body2" className="dialog-title-text">
                                {projectEntry.isExisting
                                    ? <span>{intl.formatMessage({ id: "edit" })}</span>
                                    : <span>{intl.formatMessage({ id: "create" })}</span>
                                }
                            </Typography>
                            <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                                <CloseIcon />
                            </IconButton>
                        </MuiDialogTitle>

                        <DialogContent className="dialog-content">
                            <AddEditProjectFormView
                                {...props}
                                displayMode={displayMode}
                                templates={templates}
                                handleCloseDialog={handleCloseDialog}
                                setFromTemplate={setFromTemplate}
                                handleSelectProjectTemplate={handleSelectProjectTemplate}
                                validateTechnologyField={validateTechnologyField}
                                renderHolidays={renderHolidays}
                                handleCharsMap={handleCharsMap}
                            />
                        </DialogContent>
                    </Dialog>
                }
            </Formik>
        </MuiPickersUtilsProvider>
    );
}

export default AddEditProjectDialog;
