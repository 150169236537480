import React, { Fragment } from 'react';
import UploadFiles from 'components/Shared/UploadFiles';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import DialogActions from '@material-ui/core/DialogActions';

const ACCEPTED_FORMATS = ['ppt', 'pptx', 'xlsx', 'csv', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'pdf'];

const AddFilesDialogFormView = ({
    values,
    handleSubmit,
    setFieldValue,
    handleCloseDialog
}) => {
    return (
        <Fragment>
            <UploadFiles
                name='filesAttached'
                setFieldValue={setFieldValue}
                acceptedFormats={ACCEPTED_FORMATS}
                filesAttached={values.filesAttached}
            />
            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
                <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Save" type="submit" />
            </DialogActions>
        </Fragment>
    )
}

export default AddFilesDialogFormView;
