import React from 'react';
import { useIntl } from 'react-intl';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import { GreenCheckbox } from 'components/ExploreTalents/ExploreTalentsEmployeeCardConstants';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const MultiSelectList = ({ checkedItems, onChangeHandler, items, subheaderText, ...props }) => {
    const intl = useIntl();

    return (
        <List
            {...props}
            subheader={
                <ListSubheader disableSticky className="list-sub-header">
                    {subheaderText}
                </ListSubheader>
            }
        >
            {items.map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                    <ListItem key={value} onClick={() => onChangeHandler(value)} className="list-item">
                        <GreenCheckbox
                            edge="start"
                            checked={checkedItems.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemText id={labelId} primary={intl.formatMessage({ id: convertStringToLanguageKey(value) })} />
                    </ListItem>
                );
            })}
        </List>
    );
};

export default MultiSelectList;
