import React from 'react';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';

import { US_TIME_FORMAT, FULL_MONTH_ONLY, FULL_DAY_ONLY } from 'constants/commonConstants';
import JobTypeLabel from 'components/JobsDashboard/SharedComponents/JobTypeLabel';

import './UpcomingInterviewCard.scss';

const UpcomingInterviewCard = ({
    interview,
}) => {
    return (
        <PaperElement classes={["upcoming-interview-card-wrapper"]}>
            <JobTypeLabel jobType={interview?.jobType}/>
            <div className="upcoming-interview-card-header">
                <div className="position">
                    {interview?.position}
                </div>
                <div className="time">
                    {`${moment(interview.interviewDate?.time).format(US_TIME_FORMAT)} - ${moment(interview.interviewDate?.time).add(1, 'hours').format(US_TIME_FORMAT)}`}
                </div>
            </div>
            <div className="upcoming-interview-card-body">
                <div className="candidate-information">
                    <div className="candidate-details">
                        <Avatar
                            alt="avatar"
                            className="avatar"
                            src={interview?.candidateImgUrl}
                        />
                        <span>{interview?.candidateName}</span>
                    </div>

                    <div className="location">
                        <div className="upcoming-interview-title">
                            Meeting Link:
                        </div>
                        <div className="upcoming-interview-text">
                            {interview?.location}
                        </div>
                    </div>
                </div>

                <div className="date-container">
                    <div className="day">{moment(interview.interviewDate?.date).format(FULL_DAY_ONLY)}</div>
                    <div className="month">{moment(interview.interviewDate?.date).format(FULL_MONTH_ONLY)}</div>
                </div>
            </div>
        </PaperElement>
    )
};

export default UpcomingInterviewCard;
