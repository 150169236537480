import { combineReducers } from 'redux';

import {
    COUNTRIES_FETCH_ALL_SUCCESS,
    STATIC_LANGUAGES_FETCH_ALL_SUCCESS,
    STATIC_LANGUAGES_FETCH_SUGGESTIONS_SUCCESS,
} from 'actions/actionTypes';

const countriesInitialState = [];

const countries = (state = countriesInitialState, action) => {
    switch (action.type) {
        case COUNTRIES_FETCH_ALL_SUCCESS:
            return action.payload || countriesInitialState;
        default:
            return state;
    }
};

const languagesInitialState = [];

const languages = (state = languagesInitialState, action) => {
    switch (action.type) {
        case STATIC_LANGUAGES_FETCH_ALL_SUCCESS:
        case STATIC_LANGUAGES_FETCH_SUGGESTIONS_SUCCESS:
            return action.payload || languagesInitialState;
        default:
            return state;
    }
}

const staticData = combineReducers({
    countries,
    languages,
});

export default staticData;

export const getCountries = state => state.countries;

export const getStaticLanguages = state => state.languages;
