/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { getAgencyRouteName, getAdminDashboardUpcomingBirthdays, getLanguage } from 'reducers';

import { Paper, Typography } from '@material-ui/core';
import Section from 'components/Shared/Section';
import NothingToShow from 'components/NothingToShow';

import { ReactComponent as GiftIcon } from 'assets/gift-icon.svg';

import { MONTH_DAY_DATE_FORMAT } from 'constants/commonConstants';
import { BULGARIAN_LABEL } from 'constants/languages';

import './UpcomingBirthdaysDashboard.scss';

const UpcomingBirthdaysDashboard = ({
    agencyRouteName,
    upcomingEmployeeBirthdays,
    selectedLanguage,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const [slides, setSlides] = useState([]);
    const [activeSlide, setActiveSlide] = useState(0);
    const [mouseX, setMouseX] = useState(0);

    const handleSlideChange = (newSlide) => {
        setActiveSlide(newSlide);
    };

    const handleGoToNextSlide = () => {
        if (activeSlide + 1 < slides.length) {
            handleSlideChange(activeSlide + 1);
        }
    };

    const handleGoToPreviousSlide = () => {
        if (activeSlide - 1 >= 0) {
            handleSlideChange(activeSlide - 1);
        }
    };

    const handleGoToEmployeePage = (_id) => history.push(`/${agencyRouteName}/team/people/${_id}`)

    const onMouseDownEvent = (event) => {
        setMouseX(event.clientX);
    };

    const onMouseUpEvent = (event) => {
        const currentX = event.clientX;
        const diff = currentX - mouseX;

        if (diff > 0) {
            handleGoToPreviousSlide();
        } else if (diff < 0) {
            handleGoToNextSlide();
        }
    };

    useEffect(() => {
        if (upcomingEmployeeBirthdays.length > 0) {
            const result = [];
            let sameDayBirthdays = [];
            let currentMonthDay = moment(upcomingEmployeeBirthdays[0].date).format(MONTH_DAY_DATE_FORMAT);

            for (const birthday of upcomingEmployeeBirthdays) {
                const birthdayMonthDayFormat = moment(birthday.date).format(MONTH_DAY_DATE_FORMAT);

                // if the day and month is the same, add the person to a same date birthdays array
                // otherwise push the same dates array to the result and restart
                if (currentMonthDay === birthdayMonthDayFormat) {
                    sameDayBirthdays.push(birthday);
                } else {
                    result.push(sameDayBirthdays);
                    currentMonthDay = birthdayMonthDayFormat;
                    sameDayBirthdays = [birthday];
                }
            }

            result.push(sameDayBirthdays);

            setSlides(result);
        }
    }, [upcomingEmployeeBirthdays]);

    return (
        <Section title={intl.formatMessage({ id: "upcoming-birthdays-section-title" })}>
            {upcomingEmployeeBirthdays.length === 0 ?
                <NothingToShow items="upcoming birthdays" /> :
                <Paper className="upcoming-birthdays-paper">
                    <Typography className="bold-text" variant="h2">{intl.formatMessage({ id: "upcoming-birthdays-banner-title" })}<GiftIcon /></Typography>
                    <div className="carousel-container">
                        <div
                            className="carousel"
                            style={{
                                display: 'flex',
                                transform: `translateX(-${activeSlide * 100}%)`,
                                transition: 'transform 0.3s ease-in-out',
                            }}
                        >
                            {slides.map((slide, index) => (
                                <div
                                    key={index}
                                    className={`carousel-slide ${index === activeSlide ? 'active' : ''}`}
                                    onMouseDown={onMouseDownEvent}
                                    onMouseUp={onMouseUpEvent}
                                >
                                    {slide.map(birthday => {
                                        const birthdayDate = new Date(birthday.date);

                                        const date = birthdayDate.getDate();
                                        let month;

                                        if (selectedLanguage === BULGARIAN_LABEL) {
                                            month = birthdayDate.toLocaleString('bg-BG', { month: 'short' });
                                        } else {
                                            month = birthdayDate.toLocaleString('en-US', { month: 'short' });
                                        }


                                        return (
                                            <div className="birthday-item">
                                                <div className="date-box">
                                                    <Typography className="date-item" variant="h2">{date}</Typography>
                                                    <Typography className="date-item" variant="h2">{month}</Typography>
                                                </div>
                                                <Typography variant="h2" className="employee-name" onClick={() => handleGoToEmployeePage(birthday._id)}>{birthday.firstName} {birthday.lastName}</Typography>
                                            </div>
                                        )

                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="carousel-dots">
                        {slides.map((_, index) => (
                            <div
                                key={index}
                                className={`carousel-dot ${index === activeSlide ? 'active' : ''}`}
                                onClick={() => handleSlideChange(index)}
                            ></div>
                        ))}
                    </div>
                </Paper>
            }
        </Section>
    )
};

const mapStateToProps = (state) => ({
    agencyRouteName: getAgencyRouteName(state),
    upcomingEmployeeBirthdays: getAdminDashboardUpcomingBirthdays(state),
    selectedLanguage: getLanguage(state)
});

export default connect(mapStateToProps, null)(UpcomingBirthdaysDashboard);
