import React from 'react';

import './ProfileCreationLayout.scss';

const ProfileCreationLayout = ({ children }) => {
    return (
        <div className="profile-creation-layout-wrapper">
            <main className="profile-creation-layout-body-wrapper">
                <div className="circles-wrapper">
                    <div className="circle big" />
                    <div className="circle small" />
                    <div className="circle medium" />
                </div>
                <div id="scroller" className="profile-creation-layout-content">
                    {children}
                </div>
            </main>
        </div>
    );
};

export default ProfileCreationLayout;
