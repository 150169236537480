import {
    AGENCY_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS,
    HOLIDAY_REQUEST_CREATE_SUCCESS,
    AGENCY_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    AGENCY_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    EMPLYOEE_HOLIDAYS_DELETE_PENDING_REQUESTS_SUCCESS,
    HOME_INFORMATION_INITIAL_STATE_SET,
} from 'actions/actionTypes';

const agencyHolidaysCount = (state = 0, action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return 0;
        case AGENCY_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS:
            return action.payload;
        case HOLIDAY_REQUEST_CREATE_SUCCESS:
            return Number(state) + 1;
        case EMPLYOEE_HOLIDAYS_DELETE_PENDING_REQUESTS_SUCCESS:
            return state - action.payload.length;
        case EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS:
        case EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS:
        case AGENCY_HOLIDAYS_REJECT_REQUEST_SUCCESS:
        case AGENCY_HOLIDAYS_APPROVE_REQUESTS_SUCCESS:
            return state - (action.payload.length ? action.payload.length : 1);
        default:
            return state;
    }
};

export default agencyHolidaysCount;

export const getAgencyPendingHolidaysCount = state => state;
