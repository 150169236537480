import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import stringhash from 'string-hash';
import CryptoJS from 'crypto-js';

import store from 'utils/store';

import { MEDIA_TYPES } from 'constants/typeConstants';
import { ROLES } from 'constants/userConstants';

import {
    getAgencyRouteName,
    getDisplayMode,
    isTermsAndConditions,
    isOnboarded,
    getIsInitialJourneyCompleted,
    isJourneyStarted,
} from 'reducers';
import { DEFAULT_PROFILE_MATCHING_JOB_FILTER_QUERY } from 'constants/candidateConstants';
import moment from 'moment';
import { BULGARIAN_LABEL } from 'constants/languages';

export const concatName = str => str.replace(/\s/g, '').trim();

export const formatNumber = num => {
    const pattern = /(\d)(?=(\d{3})+(?!\d))/g;
    return Number(num).toFixed(2).toString().replace(pattern, '$1,');
};

export const formatStringAsClassName = str => str.replace(/\s+/g, '-').replace(/[()]/g, '').replace(/\_/g, '-').toLowerCase();

export const formatStringAsCamelCase = str => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        return +match === 0 ? '' : index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

export const convertFromMacroCaseToCamelCase = str => {
    const upperToLower = str.replace(/([A-Z])/g, function (g) {
        return g[0].toLowerCase();
    });

    return upperToLower.replace(/_([a-z])/g, function (g) {
        return g[1].toUpperCase();
    });
}

export const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getRoleBadgeText = employee => {
    const roleMapper = {
        [ROLES.ADMIN]: 'Employee',
        [ROLES.EMPLOYEE]: 'Employee',
        [ROLES.CANDIDATE]: 'Candidate',
        [ROLES.SUPPLIER_ADMIN]: 'Partner',
        [ROLES.SUPPLIER_EMPLOYEE]: 'Partner',
        [ROLES.RECRUITER_EMPLOYEE]: 'Recruiter',
    }

    return roleMapper[employee.role];
};

export const getProfileCvLink = (agencyRouteName, employee) => {
    const profileCvLinkMapper = {
        [ROLES.EMPLOYEE]: `/${agencyRouteName}/team/people/${employee._id}/profile-cv`,
        [ROLES.ADMIN]: `/${agencyRouteName}/team/people/${employee._id}/profile-cv`,
        [ROLES.CANDIDATE]: `/${agencyRouteName}/candidates/${employee._id}/profile-information`,
        [ROLES.SUPPLIER_EMPLOYEE]: `/${agencyRouteName}/partners/${employee.supplierId}/team/people/${employee._id}`,
        [ROLES.SUPPLIER_ADMIN]: `/${agencyRouteName}/partners/${employee._id}/team/people/${employee._id}`,
        [ROLES.RECRUITER_EMPLOYEE]: `/${agencyRouteName}/recruiters/${employee.recruiterId}/people/${employee._id}`,
    };

    return profileCvLinkMapper[employee.role];
};

export const getViewCvLink = (agencyRouteName, employee) => `/${agencyRouteName}/explore-talents/${employee._id}`;

export const getViewRequestsLink = (agencyRouteName, employee) => `/${agencyRouteName}/profile/${employee._id}/matching-jobs${DEFAULT_PROFILE_MATCHING_JOB_FILTER_QUERY}`

export const throttle = (func, wait) => {
    let inThrottle = false;

    return () => {
        if (!inThrottle) {
            inThrottle = true;
            setTimeout(() => { inThrottle = false }, wait);
            return func();
        }
    };
};

export function debounce(callback, wait = 500) {
    let timeout;

    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
};

export function getMediaType(browserName) {
    switch (browserName) {
        case 'Chrome':
            return MEDIA_TYPES.WEBM_WITH_CODECS;
        case 'Firefox':
            return MEDIA_TYPES.WEBM_WITHOUT_CODECS;
        case 'Opera':
            return MEDIA_TYPES.WEBM_WITH_CODECS;
        case 'Safari':
            return MEDIA_TYPES.MIMETYPE_FOR_SAFARI;
        case 'Edge':
            return MEDIA_TYPES.WEBM_WITH_CODECS;
        case 'Internet Explorer':
            return MEDIA_TYPES.WEBM_WITH_CODECS;
        default:
            return MEDIA_TYPES.WEBM_WITH_CODECS;
    }
};

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        const scroller = document.getElementById("scroller");
        const subMenuScroller = document.getElementById('sub-menu-scroll-container');
        if (scroller && scroller.scrollTo) {
            scroller.scrollTo(0, 0);
        }

        if (subMenuScroller && scroller.scrollTo) {
            subMenuScroller.scrollTo(0, 0)
        }
    }, [pathname]);

    return null;
}

const scalablePages = [
    'create-profile',
    'start-journey',
    'login',
    'register/client',
    'register/candidate',
    'register/agency',
    'register',
    'forgottenpassword',
    'account/passwordreset',
    'account/passwordset',
    'select-a-role',
    'select-a-role/client',
    'create-company-profile',
    'terms-and-conditions/accept'
];

const nonScalablePages = [];

const isPageScalable = (pathname) => {
    let isScalable = false;

    for (let i = 0; i < scalablePages.length; i++) {
        if (pathname.includes(scalablePages[i]) && !nonScalablePages.find(el => pathname.includes(el))) {
            isScalable = true;
            break;
        }
    }

    return isScalable;
};

export function ModifyAppWidth() {
    const { pathname } = useLocation();

    useEffect(() => {
        const viewport = document.getElementById("viewport");

        if (viewport) {
            if (isPageScalable(pathname)) {
                viewport.content = 'width=device-width, initial-scale=1';
            } else {
                viewport.content = 'width=1400px';
            }
        }
    }, [pathname]);

    return null;
};

export const reorderJobOpportunities = (jobOpportunities) => {
    const normalJobOpportunities = jobOpportunities.filter(x => !x.isForContractor);
    const contractorJobOpportunities = jobOpportunities.filter(x => x.isForContractor);
    let jobOpportunitiesToReturn = [];
    if (normalJobOpportunities.length > 2) {
        jobOpportunitiesToReturn = [
            ...normalJobOpportunities.slice(0, 2),
            ...contractorJobOpportunities,
            ...normalJobOpportunities.slice(2)
        ];
    } else {
        jobOpportunitiesToReturn = [...normalJobOpportunities, ...contractorJobOpportunities];
    }

    return jobOpportunitiesToReturn;
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};

export const getPixelsAsNumber = str => Number(str?.split('px')[0]);

// query helpers
export const queryObjToString = (queryObj) => {
    const keyValuePairs = Object.keys(queryObj)
        .map(x => {
            if (Array.isArray(queryObj[x])) {
                queryObj[x] = queryObj[x]
                    .map(value => encodeURIComponent(value))
                    .join(',');

                return `${x}=${queryObj[x]}`;
            }

            return `${x}=${encodeURIComponent(queryObj[x])}`;
        })
        .filter(x => x.split('=')[1].trim() !== '');

    return keyValuePairs.length ? `?${keyValuePairs.join('&')}` : '';
};

export const parseQueryString = (queryString, asArray=false) => {
    if (queryString.includes('?')) {
        return queryString.substring(1).split('&').reduce((acc, x) => {
            const [key, value] = x.split('=');

            if (value.includes(',')) {
                acc[key] = value.split(',').map(x => decodeURIComponent(x))
            } else if (asArray) {
                acc[key] = [decodeURIComponent(value)];
            } else {
                acc[key] = decodeURIComponent(value);
            }

            return acc;
        }, {});
    }

    return {};
};

export const updateQuery = (queryString, entry) => {
    const parsedQuery = parseQueryString(queryString);
    const key = Object.keys(entry)[0];
    const value = Object.values(entry)[0];
    parsedQuery[key] = value;
    return queryObjToString(parsedQuery);
};

export const updateQueryWithMultipleEntries = (queryString, entries) => {
    const parsedQuery = parseQueryString(queryString);

    for (const [key, value] of Object.entries(entries)) {
        if (value === null) {
            delete parsedQuery[key];
        } else {
            parsedQuery[key] = value;
        }
    }

    return queryObjToString(parsedQuery);
};

export const checkIfMediaRecorderIsSupported = (browserName, browserVersion) => {
    switch (browserName) {
        case 'Chrome':
            return browserVersion >= 49;
        case 'Firefox':
            return browserVersion >= 25;
        case 'Opera':
            return browserVersion >= 36;
        case 'Safari':
            return browserVersion >= 14;
        case 'Edge':
            return browserVersion >= 79;
        case 'Internet Explorer':
            return false;
        default:
            return false;
    }
};

export const mapQueryToSorting = (defaultSortValues) => {
    return (queryObj) => {
        const initSorting = {
            column: defaultSortValues.column,
            direction: defaultSortValues.direction,
            isAscending: defaultSortValues.direction === 'asc',
        };

        const newSorting = Object.keys(queryObj).reduce((acc, x) => {
            switch (x) {
                case 'column':
                    acc.column = queryObj[x] ? queryObj[x] : acc.column;
                    break;
                case 'direction':
                    acc.direction = queryObj[x] ? queryObj[x] : acc.direction;
                    acc.isAscending = acc.direction === 'asc';
                    break;
                default:
                    break;
            }
            return acc;
        }, initSorting);

        return newSorting;
    };
};

export const generateNumericHash = (text) => {
    const hashedTimesheetId = stringhash(text);

    const hashLength = hashedTimesheetId.toString().length;
    const desireHashLength = 10;
    return new Array(desireHashLength - hashLength + 1).join('0').slice((desireHashLength - hashLength) * -1) + hashedTimesheetId;
};

export const getStateAgencyName = () => getAgencyRouteName(store.getState());

export const getHomePathDetails = () => {
    const role = getDisplayMode(store.getState());
    const termsAndConditions = isTermsAndConditions(store.getState());
    const onboarded = isOnboarded(store.getState());
    const agencyName = getAgencyRouteName(store.getState());

    if (role === ROLES.CANDIDATE) {
        const isInitialJourneyCompleted = getIsInitialJourneyCompleted(store.getState());
        const isCandidateJourneyStarted = isJourneyStarted(store.getState());
        return { agencyName, role, termsAndConditions, onboarded, isInitialJourneyCompleted, isJourneyStarted: isCandidateJourneyStarted }
    }

    return { agencyName, role, termsAndConditions, onboarded };
};

export const decryptString = (string, secret) => CryptoJS.AES.decrypt(decodeURIComponent(string), secret).toString(CryptoJS.enc.Utf8);

export const encryptString = (string, secret) => encodeURIComponent(CryptoJS.AES.encrypt(string, secret).toString());

export const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
}

export const isObject = (object) => {
    return object != null && typeof object === 'object';
}

/**
*
* @param {moment} experience - experience since date as moment instance
*
*/
export const getExperienceSinceString = (experience, selectedLanguage) => {
    const experienceYears = moment().diff(experience, 'years', true);

    if (experienceYears > 0 && experienceYears <= 1) {
        return selectedLanguage === BULGARIAN_LABEL ? "1 год." : "1 yr.";
    } else if (experienceYears > 1 && experienceYears <= 2) {
        return selectedLanguage === BULGARIAN_LABEL ? "1+ год." : "1+ yr.";
    } else if (experienceYears > 2 && experienceYears <= 3 ) {
        return selectedLanguage === BULGARIAN_LABEL ? "2+ год." : "2+ yr.";
    } else if (experienceYears > 3 && experienceYears <= 7 ) {
        return selectedLanguage === BULGARIAN_LABEL ? "3+ год." : "3+ yr.";
    } else if (experienceYears > 7 && experienceYears <= 10 ) {
        return selectedLanguage === BULGARIAN_LABEL ? "7+ год." : "7+ yr.";
    } else if (experienceYears > 10 && experienceYears <= 15) {
        return selectedLanguage === BULGARIAN_LABEL ? "10+ год." : "10+ yr.";
    } else if (experienceYears > 15) {
        return selectedLanguage === BULGARIAN_LABEL ? "15+ год." : "15+ yr.";
    }
};
