import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import './CompletionProgressBar.scss';

const CompletionProgressBar = ({ percentage }) => {
    return (
        <div className="completion-progress-bar-wrapper">
            <div className="determinated-linear-progress-wrapper">
                <LinearProgress
                    value={percentage}
                    variant="determinate"
                />
            </div>
            <span className="percentage-text">{percentage.toFixed(0)}%</span>
        </div>
    );
};

export default CompletionProgressBar;
