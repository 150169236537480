
export const base64StringtoFile = (base64String, filename) => {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

export const extractImageFileExtensionFromBase64 = (base64Data) => base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'));

export const getCroppedImg = (image, crop, canvasRefImageCroppRef, currentImage) => {
    const canvas = canvasRefImageCroppRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    image.crossOrigin = "anonymous";

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    const fileExtension = extractImageFileExtensionFromBase64(currentImage);
    const croppedImgUrl = canvas.toDataURL('image/' + fileExtension);
    const width = crop.width;
    const height = crop.height;

    return {
        croppedImgUrl,
        width,
        height
    }
}

export const makeClientCrop = async (image, imageRef, crop, canvasRefImageCropp, fileName, setCroppedImage) => {
    if (imageRef && crop.width && crop.height) {
        const croppedImageResultObject = await getCroppedImg(imageRef, crop, canvasRefImageCropp, image);

        const croppedImageUrl = croppedImageResultObject.croppedImgUrl;

        const fileExtension = extractImageFileExtensionFromBase64(image);

        const currentCroppedImgFileToUpload = base64StringtoFile(croppedImageUrl, fileName);

        let size = currentCroppedImgFileToUpload.size;

        const sizeInBytes = size > 1000 ? 'KB' : 'B';
        size = size > 1000 ? size / 1000 : size;

        const result = {
            size: size.toFixed(2),
            sizeBits: sizeInBytes,
            width: Math.trunc(croppedImageResultObject.width),
            height: Math.trunc(croppedImageResultObject.height),
            extension: fileExtension,
            file: currentCroppedImgFileToUpload
        }

        setCroppedImage(result);
    }
};
