import { combineReducers } from 'redux';
import moment from 'moment';

import {
    ADMIN_DASHBOARD_EMPLOYEES_BY_COUNTRY_FETCH_SUCCESS,
    ADMIN_DASHBOARD_EMPLOYEES_BY_UTILISATION_FETCH_SUCCESS,
    ADMIN_DASHBOARD_RECENT_STARTERS_FETCH_SUCCESS,
    ADMIN_DASHBOARD_RECENT_LEAVERS_FETCH_SUCCESS,
    EMPLOYEE_REGISTER_FETCH_SUCCESS,
    AGENCY_UPCOMING_BIRTHDAYS_FETCH_SUCCESS,
} from 'actions/actionTypes';

import { EMPLOYEE_OCCUPATION_STATUSES_VALUES } from 'constants/employeeConstants';
import { createSelector } from 'reselect';

const employeesInitialState = {
    employeesByCountry: [],
    employeesByUtilisation: [],
    recentStarters: [],
    recentLeavers: [],
    upcomingBirthdays: [],
};

const adminDashboardEmployees = (state = employeesInitialState, action) => {
    switch (action.type) {
        case ADMIN_DASHBOARD_EMPLOYEES_BY_COUNTRY_FETCH_SUCCESS:
            return { ...state, employeesByCountry: action.payload };
        case ADMIN_DASHBOARD_EMPLOYEES_BY_UTILISATION_FETCH_SUCCESS:
            return { ...state, employeesByUtilisation: action.payload };
        case ADMIN_DASHBOARD_RECENT_STARTERS_FETCH_SUCCESS:
            return { ...state, recentStarters: action.payload };
        case ADMIN_DASHBOARD_RECENT_LEAVERS_FETCH_SUCCESS:
            return { ...state, recentLeavers: action.payload };
        case EMPLOYEE_REGISTER_FETCH_SUCCESS:
            const employee = action.payload;
            const isInRecentStartersRange = moment(employee?.employmentInformation?.generalInfo?.startDate)
                .isBetween(
                    moment().subtract(30, 'days').startOf('day'),
                    moment().add(30, 'days').endOf('day'),
                    undefined,
                    '[]'
                );

            return {
                ...state,
                employeesByCountry: [...state.employeesByCountry, { numberOfEmployees: 1 }],
                employeesByUtilisation: state.employeesByUtilisation.reduce((acc, currValue) =>
                    acc.concat(currValue.status === EMPLOYEE_OCCUPATION_STATUSES_VALUES.BENCH
                        ? [{ status: currValue.status, numberOfEmployees: currValue.numberOfEmployees + 1 }]
                        : [currValue]), []),
                recentStarters: isInRecentStartersRange
                    ? [...state.recentStarters, {
                        _id: employee._id,
                        firstName: employee.firstName,
                        lastName: employee.lastName,
                        date: moment(employee?.employmentInformation?.generalInfo?.startDate).utc().startOf('day').format()
                    }].sort((a, b) => moment(b.date).diff(a.date))
                    : state.recentStarters
            }
        case AGENCY_UPCOMING_BIRTHDAYS_FETCH_SUCCESS:
            return { ...state, upcomingBirthdays: [...action.payload] };
        default:
            return state;
    }
};


const adminDashboard = combineReducers({
    adminDashboardEmployees,
});

export default adminDashboard;

const adminDashboardEmployeesInputSelector = (state) => state.adminDashboardEmployees;
export const getAdminDashboardEmployeesByCountry = createSelector([adminDashboardEmployeesInputSelector], (adminDashboardEmployees) => {
    const employees = adminDashboardEmployees.employeesByCountry;
    const filteredCountries = [];
    let totalUnassignedEmployees = 0;

    for (let i = 0; i < employees.length; i++) {
        const { country, numberOfEmployees } = employees[i];

        if (!country) {
            totalUnassignedEmployees += numberOfEmployees;
        } else {
            filteredCountries.push({ country, numberOfEmployees });
        }
    }

    const sortedCountries = filteredCountries.sort((a, b) => b.numberOfEmployees - a.numberOfEmployees || a.country.localeCompare(b.country));

    const topCountries = sortedCountries.slice(0, 4);
    const totalOtherEmployees = sortedCountries.slice(4).reduce((acc, entry) => acc + entry.numberOfEmployees, 0);

    const employeesByCountries = [...topCountries];

    if (totalOtherEmployees) {
        employeesByCountries.push({ country: "Others", numberOfEmployees: totalOtherEmployees })
    }

    if (totalUnassignedEmployees) {
        employeesByCountries.push({ country: "Unassigned", numberOfEmployees: totalUnassignedEmployees });
    }

    return employeesByCountries;
});

export const getAdminDashboardEmployeesByUtilisation = createSelector([adminDashboardEmployeesInputSelector], (adminDashboardEmployees) => {
    const employees = adminDashboardEmployees.employeesByUtilisation;
    const initialValue = [
        { status: EMPLOYEE_OCCUPATION_STATUSES_VALUES.BENCH, numberOfEmployees: 0 },
        { status: EMPLOYEE_OCCUPATION_STATUSES_VALUES.PROJECT_ENDING, numberOfEmployees: 0 },
        { status: EMPLOYEE_OCCUPATION_STATUSES_VALUES.MANAGEMENT_TEAM, numberOfEmployees: 0 },
        { status: EMPLOYEE_OCCUPATION_STATUSES_VALUES.PLACED, numberOfEmployees: 0 },
        { status: EMPLOYEE_OCCUPATION_STATUSES_VALUES.INTERNAL_PROJECT, numberOfEmployees: 0 },
    ];

    const employeesByUtilisation = employees
        .reduce((acc, currentValue) => {
            const currentItemIndex = acc.findIndex(x => x.status === currentValue.status);
            if (currentItemIndex !== -1) {
                acc[currentItemIndex].numberOfEmployees = currentValue.numberOfEmployees;
            }
            return acc;
        }, initialValue);

    return employeesByUtilisation;
});

export const getAdminDashboardRecentStarters = createSelector(
    [adminDashboardEmployeesInputSelector],
    (adminDashboardEmployees) => adminDashboardEmployees.recentStarters
);

export const getAdminDashboardRecentLeavers = createSelector(
    [adminDashboardEmployeesInputSelector],
    (adminDashboardEmployees) => adminDashboardEmployees.recentLeavers
);

export const getAdminDashboardUpcomingBirthdays = createSelector(
    [adminDashboardEmployeesInputSelector],
    (adminDashboardEmployees) => adminDashboardEmployees.upcomingBirthdays
);

