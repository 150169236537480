import React from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import LanguageDialogView from '../FormViews/LanguageDialogView';
import { validateRequiredFields } from 'utils/formValidations';

const LanguageDialog = ({
    open,
    onClose,
    onSubmit,
    selectedItem,
}) => {
    const intl = useIntl();

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: selectedItem?.name || '',
                level: selectedItem?.level || '',
            }}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['name', 'level'])
                }
            }}
            onSubmit={(values) => {
                selectedItem
                    ? onSubmit({ ...selectedItem, ...values }, selectedItem?.index)
                    : onSubmit(values);

                onClose();
            }}
        >
            {(props) => (
                <CommonFormikDialog
                    size="sm"
                    className="language-dialog"
                    dialogTitle={intl.formatMessage({ id: "add-language" })}
                    saveBtnText="save"
                    discardBtnText="discard"
                    open={open}
                    onClose={onClose}
                    onSave={props.handleSubmit}
                >
                    <LanguageDialogView {...props} />
                </CommonFormikDialog>
            )}
        </Formik>
    );
};

export default LanguageDialog;
