import React from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import MomentUtils from '@date-io/moment';

import AddTeamMemberFormView from './AddTeamMemberForm';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { validateRequiredFields, validateEmailAddress } from 'utils/formValidations';
import { addUtcOffset } from 'utils/formatTimes';

import { ROLES } from 'constants/userConstants';

const AddTeamMemberDialog = ({
    isOpen,
    handleCloseDialog,
    handleRegisterMember,
    newUserData,
    isAddingEmployee,
    isAdminMode,
    dialogTitle,
    isEmailRequired,
    nameFieldLabelText,
}) => {
    const intl = useIntl();

    const initialValues = isAddingEmployee
        ? {
            name: `${newUserData?.firstName || ''} ${newUserData?.lastName || ''}`,
            email: '',
            employmentType: '',
            startDate: null,
            ...(isAdminMode && { asAdmin: false }),
        } : {
            name: `${newUserData?.firstName || ''} ${newUserData?.lastName || ''}`,
            email: ''
        }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={values => {
                    const additionalField = isEmailRequired ? ['email'] : [];
                    const requiredFields = isAddingEmployee
                        ? ['name', 'employmentType', 'startDate', ...additionalField]
                        : ['name', ...additionalField];

                    return {
                        ...validateRequiredFields(values, requiredFields),
                        ...validateEmailAddress(values, 'email')
                    }
                }}
                onSubmit={(values) => {
                    values.name = values.name.trim();
                    values.email = values.email.toLowerCase().trim();

                    if (values.startDate) {
                        values.startDate = addUtcOffset(values.startDate);
                    }

                    handleRegisterMember(values, ROLES.EMPLOYEE);
                }}
            >
                {(props) =>
                    <CommonFormikDialog
                        className="add-team-member-dialog"
                        open={isOpen}
                        dialogTitle={dialogTitle || intl.formatMessage({ id: "add-team-member-dialog-title" })}
                        onClose={handleCloseDialog}
                        onSave={props.handleSubmit}
                        saveBtnText="Save"
                        discardBtnText="Discard"
                    >
                        <AddTeamMemberFormView
                            {...props}
                            handleCloseDialog={handleCloseDialog}
                            isAddingEmployee={isAddingEmployee}
                            isAdminMode={isAdminMode}
                            isEmailRequired={isEmailRequired}
                            nameFieldLabelText={nameFieldLabelText}
                        />
                    </CommonFormikDialog>
                }
            </Formik>
        </MuiPickersUtilsProvider>
    )
};

export default AddTeamMemberDialog;
