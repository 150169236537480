import { EMPLOYEE_MESSAGES } from "../constants/messageConstants";

import { authorizedShowNotification } from './actionHelpers';
import { fetchEmployeeUploadedCvSuccess } from "./employeeActions";
import {
    RAW_CV_GENERATED_UPDATE_SUCCESS,
    PROFILE_FETCH_SUCCESS,
    RAW_CV_PROGRESS_DATA_FETCH_SUCCESS,
    RAW_CV_PROGRESS_DATA_FETCHING_SUCCESS
} from './actionTypes';
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';
import { logEvent } from 'utils/amplitude';

import agencyService from 'services/agencyService';

import { NOTIFICATION_TYPES } from "../constants/notificationTypes";
import { showNotification } from "./notificationActions";
import { ROLES } from '../constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

const rawCvRoleMapper = {
    [ROLES.ADMIN]: {
        newRawCvPdf: agencyService.generateRawCv,
        updateRawCvPdf: agencyService.updateRawCvGenerated,
    },
    [ROLES.SUPPLIER_ADMIN]: {
        newRawCvPdf: agencyService.generateRawCv,
        updateRawCvPdf: agencyService.updateRawCvGenerated,
    },
    [ROLES.EMPLOYEE]: {
        newRawCvPdf: agencyService.generateRawCv,
        updateRawCvPdf: agencyService.updateRawCvGenerated,
    },
    [ROLES.SUPPLIER_EMPLOYEE]: {
        newRawCvPdf: agencyService.generateRawCv,
        updateRawCvPdf: agencyService.updateRawCvGenerated,
    },
    [ROLES.CANDIDATE]: {
        newRawCvPdf: agencyService.generateRawCv,
        updateRawCvPdf: agencyService.updateRawCvGenerated,
    }
};

const fetchProfileSuccess = profileData => ({
    type: PROFILE_FETCH_SUCCESS,
    payload: profileData,
});

const updateRawCvGeneratedSuccess = data => ({
    type: RAW_CV_GENERATED_UPDATE_SUCCESS,
    payload: data
});

const rawCvProgressDataFetchSuccess = () => ({
    type: RAW_CV_PROGRESS_DATA_FETCH_SUCCESS,
});

const rawCvProgressDataFetchingSuccess = () => ({
    type: RAW_CV_PROGRESS_DATA_FETCHING_SUCCESS,
});

export const generateRawCv = (agencyId, userId, files, role, stepData) => async dispatch => {
    try {
        dispatch(showApplicationLoader());

        if (stepData) {
            files.append(`stepData`, JSON.stringify(stepData));
        }

        const result = await rawCvRoleMapper[role].newRawCvPdf(agencyId, userId, files);

        logEvent(AMPLITUDE_EVENT_TYPES.UPLOAD_RAW_CV, { rolesPermitted: [ROLES.ADMIN] }, { isSuccessful: true });
        dispatch(fetchProfileSuccess(result));

        if (result.uploadedCv) {
            dispatch(fetchEmployeeUploadedCvSuccess({ uploadedCv: result.uploadedCv }));
        }

        stepData
            ? dispatch(showNotification(EMPLOYEE_MESSAGES.GENERATE_CV_PDF_INFO_WARNING, NOTIFICATION_TYPES.WARNING))
            : dispatch(showNotification([EMPLOYEE_MESSAGES.GENERATE_CV_PDF_SUCCESS, EMPLOYEE_MESSAGES.GENERATE_CV_PDF_INFO_WARNING], [NOTIFICATION_TYPES.SUCCESS, NOTIFICATION_TYPES.WARNING]));

        return result;
    } catch (error) {
        logEvent(AMPLITUDE_EVENT_TYPES.UPLOAD_RAW_CV, { rolesPermitted: [ROLES.ADMIN] }, { isSuccessful: false });
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));

        return { error }
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const updateRawCvGenerated = (agencyId, candidateId, files, role) => async dispatch => {
    try {
        const result = await rawCvRoleMapper[role].updateRawCvPdf(agencyId, candidateId, files);

        dispatch(updateRawCvGeneratedSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_PROGRESS_DATA_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchRawCvProgress = (agencyId, userId) => async dispatch => {
    try {
        dispatch(rawCvProgressDataFetchingSuccess());

        const result = await agencyService.fetchRawCvProgress(agencyId, userId);

        if (result.uploadedCv) {
            dispatch(fetchEmployeeUploadedCvSuccess({ uploadedCv: result.uploadedCv }));
        }

        dispatch(updateRawCvGeneratedSuccess({ isRawCvPdfGenerated: result.isRawCvPdfGenerated }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CV_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(rawCvProgressDataFetchSuccess());
    }
};
