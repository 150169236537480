export const columnsTitles = [
    { title: 'Name', column: 'name' },
    { title: 'Admin Name', column: 'contactPerson' },
    { title: 'Admin Email', column: 'contactEmail' },
    { title: 'Acc Manager', column: 'accountManager' },
    { title: 'Status', column: 'status' },
];

export const clientStatusFilterValues = [
    { value: 0, name: 'All Statuses' },
    { value: 1, name: 'Active/Prospect' },
    { value: 2, name: 'Active' },
    { value: 3, name: 'Inactive' },
    { value: 4, name: 'Prospect' },
];

export const clientStatusSelectValues = [
    'Active/Prospect',
    'Active (Invoicing)',
    'Inactive (Not Invoicing)',
    'Prospect',
];

export const CLIENT_ACTIVE_PROSPECTIVE_STATUS = 'Active/Prospect';
export const CLIENT_ACTIVE_STATUS = 'Active (Invoicing)';
export const CLIENT_PROSPECTIVE_STATUS = 'Prospect';

export const defaultClientStatusFitlers = clientStatusSelectValues;

export const CLIENT_ACCOUNT_MANAGER_NONE = 'None';

export const clientAccountManagerSelectValues = [
    CLIENT_ACCOUNT_MANAGER_NONE
];

export const defaultClientAccountManagerFilters = clientAccountManagerSelectValues;

export const activeInvoicingFlags = [
    { value: 0, name: 'None' },
    { value: 1, name: 'Missing Timesheet' },
    { value: 2, name: 'Missing Information from Acc. Manager' },
    { value: 3, name: 'Awaiting Approval' },
    { value: 4, name: 'Approved for Sending' },
    { value: 5, name: 'Sent to Client' },
];

export const allStatusesFilterValue = clientStatusFilterValues[0];
export const allStatusFilterValue = clientStatusFilterValues[0];
export const activeAndProspectStatusFilterValue = clientStatusFilterValues[1];
export const inactiveStatusFilterValue = clientStatusFilterValues[3];
export const defaultActiveInvoicingFlag = activeInvoicingFlags[0];

export const TABLE_COLUMN_TYPES_ORDER_BY = {
    NAME: 'name',
    CONTACT_PERSON: 'contactPerson',
    CONTACT_EMAIL: 'contactEmail',
    CONTACT_NUMBER: 'contactNumber',
    ACCOUNT_MANAGER: 'accountManager',
    STATUS: 'status',
};

export const ADDITIONAL_FILTERS = {
    SELECT: 'Select All',
    DESELECT: 'Deselect All'
}

export const defaultFilters = {
    status: defaultClientStatusFitlers,
    accountManager: defaultClientAccountManagerFilters,
}

export const defaultSortValues = {
    column: columnsTitles[0].column,
    direction: 'asc',
}

export const COLUMN_TITLE_INVOICE = { title: 'Invoice status', column: 'invoice', };

export const ACTIVE_INVOICING_FILTER_VALUE = 2;

export const WITH_INNER_ACTIONS_COLUMNS = [
    'contactEmail',
];

export const ACTIVE_INVOICING_FILTER_NAME = clientStatusFilterValues[ACTIVE_INVOICING_FILTER_VALUE].name;

export const DEFAULT_COLUMNS = {
    name: true,
    contactPerson: true,
    contactEmail: true,
    contactNumber: false,
    accountManager: true,
    status: true
};

export const INITIAL_QUERY_STRING_CLIENTS = `?status=${clientStatusSelectValues.map(status => encodeURIComponent(status))}&accountManager=`;
