import React from 'react';

import PerformanceReviewFormView from './PerformanceReviewFormView'

const PerformanceReviewForm = ({
    displayName,
    performanceCriterias,
    performanceToEdit,
    role,
    displayMode,
    selectedLanguage,
    ...props
}) => {
    return (
        <PerformanceReviewFormView
            {...props}
            selectedLanguage={selectedLanguage}
            displayName={displayName}
            performanceCriterias={performanceCriterias}
            performanceToEdit={performanceToEdit}
            role={role}
            displayMode={displayMode}
        />
    );
};

export default PerformanceReviewForm;
