import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import HolidayAllowance from './HolidayAllowance';
import PerformanceCriteria from './PerformanceCriteria';
import OnboardingDocuments from './OnboardingDocuments';

import { fetchAgencyEmployeeSettings } from 'actions/agencyActions';
import { fetchSupplierEmployeeSettings } from 'actions/supplierActions';
import { getAgencyId, isAdminMode, getUserId } from 'reducers';

import { ROLES } from 'constants/userConstants';

import isAuthenticated from 'hoc/isAuthenticated';

import './EmployeeSettings.scss';

const EmployeeSettings = ({
    agencyId,
    fetchAgencyEmployeeSettings,
    fetchSupplierEmployeeSettings,
    isAdmin,
    userId,
}) => {
    useEffect(() => {
        isAdmin
            ? fetchAgencyEmployeeSettings(agencyId)
            : fetchSupplierEmployeeSettings(agencyId, userId);
    }, [agencyId])

    return (
        <div className="employee-settings-wrapper">
            <HolidayAllowance agencyId={agencyId} isAdmin={isAdmin} userId={userId} />
            {isAdmin && <PerformanceCriteria agencyId={agencyId} />}
            {isAdmin && <OnboardingDocuments agencyId={agencyId} />}
        </div>
    );
};

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    isAdmin: isAdminMode(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    fetchAgencyEmployeeSettings,
    fetchSupplierEmployeeSettings,
};

export default isAuthenticated(connect(mapStateToProps, mapDispatchToProps)(EmployeeSettings), [ROLES.ADMIN, ROLES.SUPPLIER_ADMIN]);
