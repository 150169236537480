import {
    HISTORICAL_NOTES_FETCH_SUCCESS,
    HISTORICAL_NOTES_EDIT_SUCCESS,
    HISTORICAL_NOTES_DELETE_SUCCESS,
    HISTORICAL_NOTES_FETCH_PENDING,
    EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    HISTORICAL_NOTES_ADD_SUCCESS,
} from 'actions/actionTypes';

const historicalNotesInitialState = {
    notes: [],
    notesCount: 0,
};

const historicalNotes = (state = historicalNotesInitialState, action) => {
    switch (action.type) {
        case HISTORICAL_NOTES_FETCH_PENDING:
            return historicalNotesInitialState;
        case HISTORICAL_NOTES_FETCH_SUCCESS:
            return { ...historicalNotesInitialState, ...action.payload };
        case HISTORICAL_NOTES_ADD_SUCCESS:
            return { ...state, notes: [action.payload, ...state.notes] }
        case HISTORICAL_NOTES_DELETE_SUCCESS:
            return { ...state, notes: state.notes.filter(note => note._id !== action.payload._id), notesCount: state.notesCount - 1 }
        case HISTORICAL_NOTES_EDIT_SUCCESS:
            return {
                notes: state.notes.map(x => {
                    if (x._id === action.payload._id) {
                        return { ...x, description: action.payload.description, date: action.payload.date };
                    }

                    return x;
                }), notesCount: state.notesCount
            };
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return historicalNotesInitialState;
        default:
            return state;
    };
};


// Selectors
export const getHistoricalNotes = state => state;

export default historicalNotes;
