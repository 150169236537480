export const BANNERS_TITLES = {
    CONTRACTOR: {
        TITLE: 'Contractor Jobs',
        BUTTON_TEXT: 'Request',
    },
    FULL_TIME: {
        TITLE: 'Full-Time Jobs',
        BUTTON_TEXT: 'Hire',
    },
};
