import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchEmploymentInformation } from 'actions/employeeActions';
import { isEmployeeMode, isSupplierEmployeeMode } from 'reducers';

import PersonalInfo from 'components/Person/PersonalInfo';
import EmployeeEvrotrustStatus from 'components/Person/EmploymentInformation/EmployeeEvrotrustStatus';

const EmployeePersonalInfo = ({
    isEmployeeMode,
    isSupplierEmployeeMode,
    fetchEmploymentInformation,
    ...props
}) => {
    useEffect(() => {
        fetchEmploymentInformation(props.agencyId, props.userId);
    }, []);

    return (
        <PersonalInfo {...props}>
            <PersonalInfo.BasicForm />
            {
                isEmployeeMode &&
                <>
                    <PersonalInfo.ContractForm />
                    <EmployeeEvrotrustStatus />
                </>
            }
            <PersonalInfo.ContactForm />
            <PersonalInfo.PersonalForm />
        </PersonalInfo>
    )
};

const mapDispatchToProps = {
    fetchEmploymentInformation,
};

const mapStateToProps = state => ({
    isEmployeeMode: isEmployeeMode(state),
    isSupplierEmployeeMode: isSupplierEmployeeMode(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeePersonalInfo);
