import { USER_ACTION_TYPES } from 'constants/userServiceConstants';
import api from './api';
import requester from './requester';

const userService = {
    changeUserPreferredLanguage: (userId, query) => requester(api.user(userId, query)).create(),
    disableUsers: (userIds) => requester(api.users(USER_ACTION_TYPES.DISABLE)).create(userIds),
    restoreUsers: (userIds) => requester(api.users(USER_ACTION_TYPES.RESTORE)).create(userIds),
    terminateUserEmployment: (data) => requester(api.users(USER_ACTION_TYPES.TERMINATE)).create(data),
    permanentlyDeleteUser: (userId) => requester(api.user(userId)).delete(),
    requestCusomerSupport: (requestData) => requester(api.customerSupport()).create(requestData),
    fetchUserAccountManagerInformation: (agencyId, userId) => requester(api.userAccountManagerInformation(agencyId, userId)).getOne(),
    fetchAllHottestTalents: (agencyId, query) => requester(api.hottestTalents(agencyId, query)).getMany(),
    fetchUserAsHottestTalent: (agencyId, userId) => requester(api.hottestTalent(agencyId, userId)).getOne(),
    updateUserRole: (agencyId, userId, updateData) => requester(api.userRole(agencyId, userId)).update(updateData),
    fetchClientUsers: (clientId, query) => requester(api.clientUsers(clientId, query)).getMany(),
    updateUser: (userId, userData) => requester(api.user(userId)).update(userData),
    updateUserUnassignedRole: (userId, userData) => requester(api.userUnassignedRole(userId)).update(userData),
    fetchUnassignedUserTermsAndConditions: (userId) => requester(api.userUnassignedTermsAndConditions(userId)).getOne(),
    updateUserCompanyInformation: (userId, data) => requester(api.userCompanyInformation(userId)).updateFile(data),
};

export default userService;
