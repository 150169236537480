import React from 'react';

import AddFormView from '../AddProjectsFormView';

export default function ProjectsFormView({
	renderHolidays,
	...rest
}) {
	return ( <AddFormView {...rest} renderHolidays={renderHolidays} />);
}
