import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { isAdminMode } from 'reducers';
import { registerEmployee } from 'actions/employeeActions';

import Banner from 'components/Shared/Banner';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import AddTeamMemberDialog from 'components/Employees/AddTeamMemberDialog';
import ConfirmInviteUserDialog from 'components/Shared/ConfirmInviteUserDialog';

import { ReactComponent as PeopleCarryIcon } from 'assets/icon-people-carry.svg';

import { ROLES } from 'constants/userConstants';

const InviteEmployeeBanner = ({
    agencyId,
    userId,
    isAdminMode,
    registerEmployee,
}) => {
    const intl = useIntl();

    const [isAddEmloyeeDialogOpen, setIsAddEmployeeDialogOpen] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [newUserData, setNewUserData] = useState({});

    const handleOpenAddEmployeeDialog = () => setIsAddEmployeeDialogOpen(true);

    const handleCloseAddEmployeeDialog = () => setIsAddEmployeeDialogOpen(false);

    const handleCloseConfirmDialog = () => setIsConfirmDialogOpen(false);

    const handleSubmitClick = async values => {
        const { email, name, employmentType, startDate, asAdmin } = values;

        values = {
            email,
            name,
            employmentInformation: {
                generalInfo: { startDate },
                contractInformation: { employmentType }
            },
            role: asAdmin ? ROLES.ADMIN : ROLES.EMPLOYEE,
            addedBy: userId,
        };


        if (values.email) {
            setNewUserData(values);
            setIsConfirmDialogOpen(true);
            return;
        }

        await registerEmployee(values, agencyId);
        handleCloseAddEmployeeDialog();
    };

    const handleProceedClick = async () => {
        handleCloseConfirmDialog();
        await registerEmployee(newUserData, agencyId);
        handleCloseAddEmployeeDialog();
        setNewUserData({});
    };

    return (
        <>
            <Banner
                title={intl.formatMessage({ id: "invite-employee-banner-title" })}
                content={intl.formatMessage({ id: "invite-employee-banner-content" })}
                icon={<PeopleCarryIcon />}
                primaryButtonComponent={
                    <SecondaryButton
                        text={intl.formatMessage({ id: "invite-button-text" })}
                        handleClick={handleOpenAddEmployeeDialog}
                    />
                }
            />

            {isAddEmloyeeDialogOpen &&
                <AddTeamMemberDialog
                    isOpen={isAddEmloyeeDialogOpen}
                    handleCloseDialog={handleCloseAddEmployeeDialog}
                    handleRegisterMember={handleSubmitClick}
                    newUserData={newUserData}
                    isAddingEmployee={true}
                    isAdminMode={isAdminMode}
                />
            }

            <ConfirmInviteUserDialog
                openDialog={isConfirmDialogOpen}
                invitedUser={ROLES.EMPLOYEE}
                handleInviteUser={handleProceedClick}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </>
    );
};

const mapStateToProps = state => ({
    isAdminMode: isAdminMode(state),
});

const mapDispatchToProps = {
    registerEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteEmployeeBanner);
