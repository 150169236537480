import React from 'react';
import { Formik } from 'formik';

import LoginFormView from '../LoginFormView';

import { validateRequiredFields, validateEmailAddress } from 'utils/formValidations';

const LoginFormik = ({
    loginLoading,
    ssoLoginLoading,
    handleLogin,
    handleSSOLogin,
}) => (
    <Formik
        initialValues={{
            email: '',
            password: '',
            rememberMe: false,
        }}
        validate={values => {
            return {
                ...validateRequiredFields(values, ['email', 'password']),
                ...validateEmailAddress(values, 'email')
            };
        }}
        onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleLogin(values);
        }}
    >
        {(props) =>
            <LoginFormView
                {...props}
                loginLoading={loginLoading}
                ssoLoginLoading={ssoLoginLoading}
                handleSSOLogin={handleSSOLogin}
            />
        }
    </Formik>
);

export default LoginFormik;
