import en from '../languages/en.json';
import bg from '../languages/bg.json';

export const ENGLISH_LABEL = 'en';
export const BULGARIAN_LABEL = 'bg';

export const languages = {
    bg,
    en
};
