import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { ErrorMessage } from 'formik';

import { CURRENCIES } from 'constants/currenciesConstant';

import './AddEmployeeProjectsCardFormView.scss';

const DedicatedEmployeeFields = ({ values, handleChange, errors, touched, isClientInvoicingCompleted }) => {
    const intl = useIntl();

    return (
        <Fragment>
            <FormControl className="add-project-employee-field currency" variant="outlined" fullWidth>
                <InputLabel id="currency-label">{intl.formatMessage({ id: "currency" })}</InputLabel>
                <Select
                    labelId="currency-label"
                    id="person-currency"
                    name="currency"
                    value={values.currency}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "currency" })}
                    error={errors.currency && touched.currency}
                    disabled={isClientInvoicingCompleted}
                >
                    {CURRENCIES.map(CURRENCY => (
                        <MenuItem key={CURRENCY.VALUE} value={CURRENCY.LABEL}>
                            {CURRENCY.LABEL}
                        </MenuItem>
                    ))}
                </Select>
                <ErrorMessage name="currency" component="div" className="invalid-field-message" />
            </FormControl>
            <>
                <div className="add-project-employee-field">
                    <TextField
                        type="tel"
                        name="employerTotalCost"
                        value={values.employerTotalCost || ""}
                        onChange={handleChange}
                        label={intl.formatMessage({ id: "monthly-total-cost-to-employer" })}
                        error={errors.employerTotalCost && touched.employerTotalCost}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <ErrorMessage name="employerTotalCost" component="div" className="invalid-field-message" />
                </div>

                <div className="add-project-employee-field">
                    <TextField
                        type="tel"
                        name="monthlyServiceFee"
                        value={values.monthlyServiceFee || ""}
                        onChange={handleChange}
                        label={intl.formatMessage({ id: "monthly-service-fee" })}
                        error={errors.monthlyServiceFee && touched.monthlyServiceFee}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <ErrorMessage name="monthlyServiceFee" component="div" className="invalid-field-message" />
                </div>
            </>
        </Fragment>
    )
};

export default DedicatedEmployeeFields;
