import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import SendProfilesAutocomplete from '../SharedComponents/SendProfilesAutocomplete';
import SendProfilesListItem from '../SharedComponents/SendProfilesListItem';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import List from '@material-ui/core/List';

import { validatesArrayLength } from 'utils/formValidations';

const SelectEmployeesDialog = ({
    setSelectedEmployees,
    isSelectEmployeesDialogOpen,
    setIsSelectEmployeesDialogOpen,
    setIsAddCommentsDialogOpen,
    handleCloseDialog,
    inputValue,
    setInputValue,
    handleChangeInputValue,
    searchResult,
    fetchSearchResultsPending,
    isLoading,
}) => {
    const intl = useIntl();

    return (
        <Formik
            enableReinitialize
            initialValues={{
                selectedEmployees: [],
            }}
            validate={(values) => {
                return {
                    ...validatesArrayLength(values, 'selectedEmployees', 1)
                }
            }}
            onSubmit={(values) => {
                setInputValue('');
                setSelectedEmployees(values.selectedEmployees);
                setIsAddCommentsDialogOpen(true);
                setIsSelectEmployeesDialogOpen(false);
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    className="select-employees-dialog"
                    onSave={props.handleSubmit}
                    dialogTitle={intl.formatMessage({ id: "select-profiles" })}
                    open={isSelectEmployeesDialogOpen}
                    onClose={handleCloseDialog}
                    saveBtnText={intl.formatMessage({ id: "forward" })}
                    discardBtnText={intl.formatMessage({ id: "discard" })}
                    size="md"
                >
                    <SendProfilesAutocomplete
                        {...props}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        handleChangeInputValue={handleChangeInputValue}
                        searchResult={searchResult}
                        fetchSearchResultsPending={fetchSearchResultsPending}
                        isLoading={isLoading}
                    />

                    {props.values.selectedEmployees.length > 0 &&
                        <List className="send-profiles-list-wrapper">
                            {props.values.selectedEmployees.map(selectedEmployee => (
                                <SendProfilesListItem
                                    {...props}
                                    key={selectedEmployee._id}
                                    employee={selectedEmployee}
                                    isWithRemoveProfile={true}
                                    isWithNote={false}
                                />
                            ))}
                        </List>
                    }
                </CommonFormikDialog>
            }
        </Formik>
    );
};

export default SelectEmployeesDialog;
