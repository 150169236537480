import React from 'react';

import './Subsection.scss';

const Subsection = ({
    iconComponent,
    headerComponent,
    content
}) => (
    <div className="subsection-wrapper">
        {iconComponent}
        <div className="subsection-container">
            {headerComponent}
            <p className="subsection-content">{content}</p>
        </div>
    </div>
);

export default Subsection;
