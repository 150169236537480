import {
    EMPLOYEE_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS,
    EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    HOLIDAY_REQUEST_CREATE_SUCCESS,
    EMPLYOEE_HOLIDAYS_DELETE_PENDING_REQUESTS_SUCCESS,
} from 'actions/actionTypes';

const employeeHolidaysCount = (state = 0, action) => {
    switch (action.type) {
        case EMPLOYEE_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS:
            return action.payload || 0;
        case HOLIDAY_REQUEST_CREATE_SUCCESS:
            return state + action.payload.length;
        case EMPLYOEE_HOLIDAYS_DELETE_PENDING_REQUESTS_SUCCESS:
            return state - action.payload.length;
        case EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS:
        case EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS:
            return state - (action.payload.length ? action.payload.length : 1);
        default:
            return state;
    }
};

export default employeeHolidaysCount;

export const getEmployeePendingHolidaysCount = state => state;
