import React from 'react';
import { useIntl } from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PhotoIcon from '@material-ui/icons/Photo';

import { VARIANTS_CLASS_NAMES, VARIANT_NEW } from '../UploadFilesConstants';

import './AttachedFilesList.scss';

const AttachedFilesList = ({
    files,
    deleteAttachedFile,
    variant = "",
}) => {
    const intl = useIntl();

    return (
        <div className={`attached-files-list-container ${VARIANTS_CLASS_NAMES[variant]}`}>
            {files.length > 0 && variant !== VARIANT_NEW && <div className='attached-list-title'>
                <span>{intl.formatMessage({ id: "uploaded-files" })}</span>
            </div>}
            <List>
                {files.map((file, i) => {
                    return (
                        <ListItem key={i + file.name}>
                            <ListItemAvatar>
                                {variant === VARIANT_NEW ?
                                    <PhotoIcon /> :
                                    <DescriptionOutlinedIcon className="color-green" />
                                }
                            </ListItemAvatar>
                            <ListItemText primary={file.name} />
                            <ListItemSecondaryAction>
                                <IconButton edge='end' aria-label='delete' onClick={() => deleteAttachedFile(file.name)}>
                                    <ClearIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    )
};

export default AttachedFilesList;
