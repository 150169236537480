import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { getAgencyRouteName, getChecklistedCandidates } from 'reducers';

import Banner from 'components/Shared/Banner';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import { ReactComponent as WarningIcon } from 'assets/banner-warning-icon.svg';

import { INITIAL_QUERY_STRING_CANIDATES_CHECKLIST } from 'components/CandidatesChecklist/CandidatesChecklistConstants';

import './RequireActionBanner.scss';

const RequireActionBanner = ({
    agencyRouteName,
    totalChecklistedCandidatesCount,
}) => {
    const intl = useIntl();

    if (totalChecklistedCandidatesCount > 0) {
        return <Banner
            title={`${totalChecklistedCandidatesCount} ${intl.formatMessage({ id: "candidates-require-your-action" })}`}
            content={intl.formatMessage({ id: "candidates-require-your-action-content" })}
            icon={<WarningIcon />}
            primaryButtonComponent={<SecondaryButton
                text={intl.formatMessage({ id: "view-button-text" })}
                component={Link}
                to={`/${agencyRouteName}/candidates/checklist${INITIAL_QUERY_STRING_CANIDATES_CHECKLIST}`}
            />}
        />
    }

    return null;
};

const mapStateToProps = (state) => ({
    agencyRouteName: getAgencyRouteName(state),
    totalChecklistedCandidatesCount: getChecklistedCandidates(state).totalChecklistedCandidatesCount,
});

export default connect(mapStateToProps)(RequireActionBanner);
