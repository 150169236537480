export const IMPORTANT_DOCUMENTS_TABLE_COLUMNS = [
    { name: 'Title', value: 'title', isSortable: true, langKey: "title" },
    { name: 'Uploaded On', value: 'uploadedOn', isSortable: true, langKey: "upload-date" },
];

export const DEFAULT_IMPORTANT_DOCUMENTS_TABLE_COLUMNS = ['title', 'uploadedOn'];

export const IMPORTANT_DOCUMENTS_SORT_QUERY_SELECTOR = 'importantDocumentsSort';
export const IMPORTANT_DOCUMENTS_PAGE_QUERY_SELECTOR = 'importantDocumentsTablePage';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const INITIAL_QUERY = {
    page: 1,
    sortBy: null,
    sortByDir: null,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    pagesToLoad: 5,
};

export const QUERY_PARAMS_MAP = {
    page: IMPORTANT_DOCUMENTS_PAGE_QUERY_SELECTOR,
    sortBy: IMPORTANT_DOCUMENTS_SORT_QUERY_SELECTOR,
    sortByDir: `${IMPORTANT_DOCUMENTS_SORT_QUERY_SELECTOR}Dir`,
};
