import React from 'react';

const WhatToExpect = ({
    getCandidateJourneyDuration,
    START_JOURNEY_STEPS,
}) => (
    <>
        <h1 className="start-candidate-journey-header">What to expect?</h1>
        <h2 className="start-candidate-journey-sub-header">
            How to get matched to a remote job in <span className="highlighted-text green">{getCandidateJourneyDuration()} mins</span>
        </h2>
        <div className="start-candidate-journey-steps-container">
            <ul className="start-candidate-journey-stepper">
                {START_JOURNEY_STEPS.map((step, index) => (
                    <li key={index} className="single-step">
                        <div className="step-box">
                            <span className="box-text">{index + 1}</span>
                        </div>
                        <div className="step-content">
                            <div className="common-line">
                                <span className="step-title">{step.title}</span>
                                <span className="step-duration">{` ${step.duration}min`}</span>
                            </div>
                            <span className="step-description">{step.description}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    </>
);

export default WhatToExpect;
