import React from 'react';
import { useIntl } from 'react-intl';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import { Paper, Typography } from '@material-ui/core';

import './ClientOverview.scss';

const ClientOverview = ({
    projectsCount,
    monthlySpending,
    totalRevenue,
    clientCurrency
}) => {
    const intl = useIntl();

    return (
        <div className="client-overview-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "client-overview" })}
                    className='section-title'
                />
            </SectionActionsWrapper>
            <div>
                <Paper className="client-overview-content">
                    <Typography className="overview-subtitle" variant="h6">{intl.formatMessage({ id: "projects" })}</Typography>
                    <div className="projects-content">
                        <div className="active-projects">
                            <Typography variant="subtitle2" >{intl.formatMessage({ id: "active-projects" })}</Typography>
                            <span>{projectsCount.active}</span>
                        </div>
                        <div className="complete-projects">
                            <Typography variant="subtitle2" >{intl.formatMessage({ id: "complete-projects" })}</Typography>
                            <span>{projectsCount.complete}</span>
                        </div>
                        <div className="total-projects">
                            <Typography variant="subtitle2" >{intl.formatMessage({ id: "total-projects" })}</Typography>
                            <span>{projectsCount.total}</span>
                        </div>
                    </div>
                    <Typography className="overview-subtitle" variant="h6">{intl.formatMessage({ id: "financial" })}</Typography>
                    <div className="financial-content">
                        <div className="monthly-finance">
                            <Typography variant="subtitle2" >{intl.formatMessage({ id: "monthly-revenue" })}</Typography>
                            <span>{`${monthlySpending.toFixed(2)} ${clientCurrency}`}</span>
                        </div>
                        <div className="total-finance">
                            <Typography variant="subtitle2" >{intl.formatMessage({ id: "total-revenue" })}</Typography>
                            <span>{`${Math.round(totalRevenue)} ${clientCurrency}`}</span>
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    )
};


export default ClientOverview;
