import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import './AttachedFilesList.scss';

const AttachedFilesList = ({
    files,
    deleteAttachedFile,
}) => (
    <div className="attached-files-container">
        <List>
            {files.map((file, i) => {
                return (
                    <ListItem key={i + file.name}>
                        <ListItemAvatar>
                            <DescriptionOutlinedIcon />
                        </ListItemAvatar>
                        <ListItemText primary={file.name} />
                        <ListItemSecondaryAction>
                            <IconButton edge='end' aria-label='delete' onClick={() => deleteAttachedFile()}>
                                <ClearIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
    </div>
);

export default AttachedFilesList;
