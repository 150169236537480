import React from 'react';
import { useIntl } from 'react-intl';

import { Field, ErrorMessage } from 'formik';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { CANDIDATE_SALARY_CURRENCIES } from 'constants/currenciesConstant';

import './ExpectedSalary.scss';

const ExpectedSalaryDialog = ({
    handleSubmit,
    handleChange,
    values,
    onClose,
    open
}) => {
    const intl = useIntl();

    const onFormSubmit = () => {
        handleSubmit();
    }

    return (
        <form>
            <CommonFormikDialog
                open={open}
                className={"expected-salary-dialog"}
                onClose={onClose}
                dialogTitle={intl.formatMessage({ id: "edit-salary" })}
                saveBtnText="save"
                discardBtnText="discard"
                onSave={onFormSubmit}
            >
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2">
                            <Field
                                as={TextField}
                                type="number"
                                variant="outlined"
                                label={intl.formatMessage({ id: "number" })}
                                value={values.salaryValue}
                                name="salaryValue"
                                fullWidth={true}
                            />

                            <ErrorMessage name="salaryValue" component="div" className="invalid-field-message" />
                        </div>

                        <div className="col-2of2">
                            <TextField
                                select
                                label={intl.formatMessage({ id: "currency" })}
                                value={values.salaryCurrency}
                                variant="outlined"
                                onChange={handleChange}
                                name="salaryCurrency"
                                id="salaryCurrency"
                                fullWidth={true}
                            >
                                {CANDIDATE_SALARY_CURRENCIES.map((currency) => {
                                    return (
                                        <MenuItem key={currency.VALUE} value={currency.LABEL}>
                                            {currency.LABEL}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>

                            <ErrorMessage name="salaryCurrency" component="div" className="invalid-field-message" />
                        </div>
                    </div>
                </div>
            </CommonFormikDialog>
        </form>
    );
}

export default ExpectedSalaryDialog;
