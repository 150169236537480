import { REPORT_DATA_TABLE_TITLES, REPORT_DATA_TO_FIELD_MAP, REPORT_DATA_TO_QUESTIONS_MAP, REPORTS_REFERRER_TO_TEXT_MAP } from './ReportsPageConstants';
import { REFERRAL_CAMPAIGN_COLORS_HEX } from 'constants/referralCampaign';

export const constructPopularReferrersElement = (referrersValue = {}, selectedLanguage) => {
    const counts = {
        GoogleAdCampaign: 0,
        LinkedInPaidJobPost: 0,
        WebsiteOrganic: 0,
        'Invited by admin': 0,
        'Self-registered': 0,
    };

    Object.entries(referrersValue).forEach(([key, value]) => {
        const mappedKey = REPORTS_REFERRER_TO_TEXT_MAP[key]['en'];

        // if the referrer is not in the map, we add it to WebsiteOrganic or Other
        if (!mappedKey || mappedKey === REPORTS_REFERRER_TO_TEXT_MAP["WebsiteOrganic"]['en']) {
            counts['WebsiteOrganic'] += value;
            return null;
        }

        counts[key] = value;
    });

    const chartOptions = {
        credits: {
            enabled: false
        },
        chart: {
            height: 330,
            width: 220,
            type: 'pie',
            margin: [0, 0, 0, 0],
            spacing: [0, 0, 0, 0],
        },
        title: {
            text: null,
        },
        tooltip: {
            outside: true,
            useHTML: true,
            formatter: function () {
                return `${this.point.name}: <b>${this.point.percentage.toFixed(2)}% (${this.point.y})</b>`
            },
        },
        plotOptions: {
            pie: {
                cursor: 'pointer',
                allowPointSelect: true,
                dataLabels: { enabled: false },
                showInLegend: true,
                center: ['50%', '35%'],
            },
        },
        series: [{
            name: 'Source of candidates',
            colorByPoint: true,
            groupPadding: 0,
            pointPadding: 0,
            data: Object.keys(counts).map(key => ({
                name: REPORTS_REFERRER_TO_TEXT_MAP[key][selectedLanguage],
                color: REFERRAL_CAMPAIGN_COLORS_HEX[key],
                y: counts[key],
            })).sort((a, b) => b.y - a.y)
        }],
        legend: {
            labelFormatter: function () {
                return `${this.name} (${this.y})`
            }
        },
    };

    return chartOptions;
};

export const mapReportDataToTableContent = (tableName, data, selectedLanguage) => {
    switch (tableName) {
        case REPORT_DATA_TABLE_TITLES.OPEN_POSITIONS:
            return [
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.OPEN_CONTRACT_POSITIONS], answer: data[REPORT_DATA_TO_FIELD_MAP.OPEN_CONTRACT_POSITIONS] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.OPEN_FULL_TIME_POSITIONS], answer: data[REPORT_DATA_TO_FIELD_MAP.OPEN_FULL_TIME_POSITIONS] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.OPEN_POSITIONS], answer: data[REPORT_DATA_TO_FIELD_MAP.OPEN_POSITIONS] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.CLOSED_POSITIONS], answer: data[REPORT_DATA_TO_FIELD_MAP.CLOSED_POSITIONS] },
            ];
        case REPORT_DATA_TABLE_TITLES.REGISTERED_CANDIDATES:
            return [
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.NEW_CANDIDATES], answer: data[REPORT_DATA_TO_FIELD_MAP.NEW_CANDIDATES] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.POPULAR_REFERRERS], answer: constructPopularReferrersElement(data[REPORT_DATA_TO_FIELD_MAP.POPULAR_REFERRERS], selectedLanguage) },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.VETTED_CANDIDATES], answer: data[REPORT_DATA_TO_FIELD_MAP.VETTED_CANDIDATES] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.REJECTED_CANDIDATES], answer: data[REPORT_DATA_TO_FIELD_MAP.REJECTED_CANDIDATES] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.INCOMPLETE_PROFILE_CANDIDATES], answer: data[REPORT_DATA_TO_FIELD_MAP.INCOMPLETE_PROFILE_CANDIDATES] },
            ];
        case REPORT_DATA_TABLE_TITLES.PARTNER_EMPLOYEES:
            return [
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.NEW_PARTNER_EMPLOYEES], answer: data[REPORT_DATA_TO_FIELD_MAP.NEW_PARTNER_EMPLOYEES] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.VETTED_PARTNER_EMPLOYEES], answer: data[REPORT_DATA_TO_FIELD_MAP.VETTED_PARTNER_EMPLOYEES] },
            ];
        case REPORT_DATA_TABLE_TITLES.JOB_APPLICATIONS:
            return [
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.CREATED_APPLICATIONS], answer: data[REPORT_DATA_TO_FIELD_MAP.CREATED_APPLICATIONS] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.CREATED_EMPLOYEE_APPLICATIONS], answer: data[REPORT_DATA_TO_FIELD_MAP.CREATED_EMPLOYEE_APPLICATIONS] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.CREATED_SUPPLIER_EMPLOYEE_APPLICATIONS], answer: data[REPORT_DATA_TO_FIELD_MAP.CREATED_SUPPLIER_EMPLOYEE_APPLICATIONS] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.CREATED_CANDIDATE_APPLICATIONS], answer: data[REPORT_DATA_TO_FIELD_MAP.CREATED_CANDIDATE_APPLICATIONS] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.REJECTED_APPLICATIONS], answer: data[REPORT_DATA_TO_FIELD_MAP.REJECTED_APPLICATIONS] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.HIRED_APPLICATIONS], answer: data[REPORT_DATA_TO_FIELD_MAP.HIRED_APPLICATIONS] },
            ];
        case REPORT_DATA_TABLE_TITLES.NEW_HIRES:
            return [
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.HIRED_EMPLOYEES], answer: data[REPORT_DATA_TO_FIELD_MAP.HIRED_EMPLOYEES] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.ASSIGNED_EMPLOYEES], answer: data[REPORT_DATA_TO_FIELD_MAP.ASSIGNED_EMPLOYEES] },
            ];
        case REPORT_DATA_TABLE_TITLES.ATTRITION:
            return [
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.REMOVED_EMPLOYEES], answer: data[REPORT_DATA_TO_FIELD_MAP.REMOVED_EMPLOYEES] },
                { question: REPORT_DATA_TO_QUESTIONS_MAP[REPORT_DATA_TO_FIELD_MAP.REMOVED_FROM_PROJECT_EMPLOYEES], answer: data[REPORT_DATA_TO_FIELD_MAP.REMOVED_FROM_PROJECT_EMPLOYEES] },
            ];
        default:
            break;
    }
};
