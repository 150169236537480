import moment from 'moment';

export const areAllDatesInThePast = (dates) => {
    for (const date of dates) {
        if (moment(date).isAfter(moment())) {
            return false;
        }
    }

    return true;
};
