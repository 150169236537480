import React from 'react';
import { ErrorMessage } from 'formik';

import TextField from '@material-ui/core/TextField';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { DESCRIPTION_ROWS_COUNT } from '../../../CustomerSupportConstants';

const CustomerSupportFormView = ({
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
}) => {

    return (
        <form className="client-customer-support-request-form" autoComplete="off">
            <div className="client-customer-support-request-field">
                <TextField
                    fullWidth
                    variant="outlined"
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name && touched.name}
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>

            <div className="client-customer-support-request-field description">
                <TextField
                    multiline
                    fullWidth
                    variant="outlined"
                    name="description"
                    label="Describe your problem"
                    value={values.description}
                    onChange={handleChange}
                    error={errors.description && touched.description}
                    minRows={DESCRIPTION_ROWS_COUNT}
                />
                <ErrorMessage name="description" component="div" className="invalid-field-message" />
            </div>

            <div className="action-buttons">
                <PrimaryButton
                    className="submit-request-btn"
                    text="Send"
                    handleClick={handleSubmit}
                />
            </div>
        </form>
    );
};

export default CustomerSupportFormView;
