import React, { createContext } from 'react';
import { connect } from 'react-redux';

import { updateCandidatesJourneyCompletedSteps, uploadCandidateVideoInterviewQuestionAnswer } from 'actions/candidateActions';
import { showNotification } from 'actions/notificationActions';

import {
    getInitialJourneyCompletedStepsIds,
    getCurrentJourneyStepId,
    getAgencyCalendlyInfo,
    getCandidatePreferences,
    hasUserBookedInterview,
    getAgencyName
} from 'reducers';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import CandidatesJourneyNearlyThere from 'components/CandidateProfileJourney/Steps/CandidatesJourneyNearlyThere'
import VideoIntroduction from './VideoIntroduction';
import VideoRecording from './VideoRecording';

import './VideoIntroductionWrapper.scss';

export const VideoIntroductionWrapperContext = createContext();
const { Provider } = VideoIntroductionWrapperContext;

const VideoIntroductionWrapper = ({
    mainStepTitle,
    updateCandidatesJourneyCompletedSteps,
    completedStepsIds,
    currentStepId,
    showNotification,
    children,
    uploadCandidateVideoInterviewQuestionAnswer,
    candidatePreferences,
    agencyCalendlyInfo,
    hasUserBookedFaceToFaceInterview,
    agencyName,
}) => {
    const context = {
        mainStepTitle,
        completedStepsIds,
        currentStepId,
        updateCandidatesJourneyCompletedSteps,
        showNotification,
        uploadCandidateVideoInterviewQuestionAnswer,
        candidatePreferences,
        agencyCalendlyInfo,
        hasUserBookedFaceToFaceInterview,
        agencyName,
    };

    return (
        <Provider value={context}>
            {children}
        </Provider>
    );
};

const PrimaryStepButton = ({
    text,
    handleClick,
    className,
    buttonStyle,
}) => {
    return (
        <PrimaryButton
            text={text}
            handleClick={handleClick}
            className={className}
            buttonStyle={buttonStyle}
        />
    );
};

const SecondaryStepButton = ({
    text,
    handleClick,
    className,
}) => {
    return (
        <SecondaryButton
            text={text}
            handleClick={handleClick}
            className={className}
        />
    );
};

const NearlyThereScreen = ({
    handleGoToNextStep
}) => <CandidatesJourneyNearlyThere handleGoToNextStep={handleGoToNextStep} />;

VideoIntroductionWrapper.VideoIntroduction = VideoIntroduction;
VideoIntroductionWrapper.VideoRecording = VideoRecording;
VideoIntroductionWrapper.PrimaryStepButton = PrimaryStepButton;
VideoIntroductionWrapper.SecondaryStepButton = SecondaryStepButton;
VideoIntroductionWrapper.NearlyThereScreen = NearlyThereScreen;

const mapStateToProps = (state) => ({
    completedStepsIds: getInitialJourneyCompletedStepsIds(state),
    currentStepId: getCurrentJourneyStepId(state),
    agencyCalendlyInfo: getAgencyCalendlyInfo(state),
    candidatePreferences: getCandidatePreferences(state),
    hasUserBookedFaceToFaceInterview: hasUserBookedInterview(state),
    agencyName: getAgencyName(state),
});

const mapDispatchToProps = {
    updateCandidatesJourneyCompletedSteps,
    showNotification,
    uploadCandidateVideoInterviewQuestionAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoIntroductionWrapper);
