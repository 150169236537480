import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorMessage } from 'formik';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { BILLING_TYPE_STATIC_VALUES } from 'constants/clientProjectConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './AddEmployeeProjectsCardFormView.scss';

const EmployeeFields = ({
    values,
    handleChange,
    errors,
    touched,
}) => {
    const intl = useIntl();

    return (
        <FormControl className="add-project-employee-field billing-type" variant="outlined" fullWidth>
            <InputLabel id="billing-type-label">{intl.formatMessage({ id: "billing-type" })}</InputLabel>
            <Select
                labelId="billing-type-label"
                id="person-billing-type"
                name="billingType"
                value={values.billingType}
                onChange={handleChange}
                label="billingType"
                error={errors.billingType && touched.billingType}
            >
                {BILLING_TYPE_STATIC_VALUES.map(BILLING_TYPE => (
                        <MenuItem key={BILLING_TYPE.VALUE} value={BILLING_TYPE.LABEL}>
                            {intl.formatMessage({ id: convertStringToLanguageKey(BILLING_TYPE.LABEL) }) }
                        </MenuItem>
                    ))}
            </Select>
            <ErrorMessage name="billingType" component="div" className="invalid-field-message" />
        </FormControl>
    );
};

export default EmployeeFields;
