export const NOTIFICATION_TYPES = {
    NEW_APPLICATION: 'New Application',
    ACCEPTED_INTERVIEW: 'Accepted Interview',
    INTERVIEW_TIME_SLOT_PROPOSAL: 'Interview Time Slot Proposal'
}

export const NOTIFICATION_TYPES_MESSAGES = string => ({
    [NOTIFICATION_TYPES.NEW_APPLICATION]: `1 new application for ${string}`,
    [NOTIFICATION_TYPES.ACCEPTED_INTERVIEW]: `Candidate ${string} has accepted your interview invitation`,
    [NOTIFICATION_TYPES.INTERVIEW_TIME_SLOT_PROPOSAL]: `Candidate ${string} has proposed new time for an interview`
});
