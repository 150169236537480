import React from 'react';

import Grid from '@material-ui/core/Grid';

import SoftwareUniversityLogoWhite from 'assets/software-university-logo-white.png';
import SoftwareUniversityLogo from 'assets/software-university-logo.svg';
import MotionSoftwareLogoWhite from 'assets/motion-software-logo-white.png';
import MotionSoftwareLogo from 'assets/motion-software-logo.svg';
import AmibitonedLogoWhite from 'assets/ambitioned-logo-white.png';
import AmibitonedLogo from 'assets/ambitioned-logo.svg';

import './RegisterFooter.scss';

const FOOTER_LOGOS = [
    { LOGO: SoftwareUniversityLogoWhite, LOGO_MOBILE: SoftwareUniversityLogo, TEXT: 'Software University' },
    { LOGO: MotionSoftwareLogoWhite, LOGO_MOBILE: MotionSoftwareLogo, TEXT: 'Motion Software' },
    { LOGO: AmibitonedLogoWhite, LOGO_MOBILE: AmibitonedLogo, TEXT: 'Ambitioned' }
];

const RegisterFooter = () => {

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className="register-footer"
        >
            {FOOTER_LOGOS.map((x, index) => {
                return (
                    <div
                        key={`logo-wrapper-${index}`}
                        className="logo-wrapper"
                    >
                        <img src={x.LOGO} className="logo" alt={x.TEXT} />
                        <img src={x.LOGO_MOBILE} className="logo-mobile" alt={x.TEXT} />
                    </div>
                );
            })}
        </Grid>
    );
};

export default RegisterFooter;
