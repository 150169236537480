const REFERRAl_CAMPAIGN_REGEX = /(\?|\&)referralCampaign=\w+\.?\w+\b/;
const GCLID_REGEX = /(\?|\&)gclid=.*?(?=&|\\s|$)/;
const EMAIL_REGEX = /(\?|\&)newCandidateEmail=.*?(?=&|\\s|$)/;

export const setReferrer = (url) => {
    const campaign = url.match(REFERRAl_CAMPAIGN_REGEX);

    let referrer = localStorage.getItem('referrer');
    if (campaign) {
        referrer = campaign[0].split('=')[1];
    }
    if (referrer) {
        localStorage.setItem('referrer', referrer);
    }
}

export const setNewCandidateEmail = (url) => {
    const email = url.match(EMAIL_REGEX);

    let newCandidateEmail = localStorage.getItem('newCandidateEmail');
    if (email) {
        newCandidateEmail = email[0].split('=')[1];
    }
    if (newCandidateEmail) {
        localStorage.setItem('newCandidateEmail', newCandidateEmail);
    }
}

export const setGCLID = (searchString) => {
    const gclidMatch = searchString.match(GCLID_REGEX);

    let gclid = localStorage.getItem('gclid');

    if (gclidMatch) {
        gclid = gclidMatch[0].split('=')[1];
    }

    if (gclid) {
        localStorage.setItem('gclid', gclid);
    }
};

export const getReferrer = () => localStorage.getItem('referrer');
export const getGCLID = () => localStorage.getItem('gclid');
export const getNewCandidateEmail = () => localStorage.getItem('newCandidateEmail');
