import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getUserId } from 'reducers';

import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { AUTH_MESSAGES } from 'constants/messageConstants';

import './SessionChangeListener.scss';

const SessionChangeListener = ({
    userId,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [message, setMessage] = useState(false);

    useEffect(() => {
        function authChangeHandler(event) {
            if (event.storageArea === localStorage && event.key === 'persist:auth') {
                const newValue = JSON.parse(event.newValue);
                if (newValue?.user) {
                    const { uid } = JSON.parse(newValue.user);
                    /* 
                    Login in another tab/window with different account 
                    OR 
                    Login in another tab/window, but in this tab/window is logged out
                    */
                    if ((userId && uid !== userId) || (uid && !userId)) {
                        setIsDialogOpen(true);
                        setMessage(AUTH_MESSAGES.SIGNED_IN_ANOTHER_TAB_OR_WINDOW);
                    } else {
                        setIsDialogOpen(false);
                    }
                } else if (!newValue && userId) {
                    /* 
                    Log out in another tab/window
                    */
                    setIsDialogOpen(true);
                    setMessage(AUTH_MESSAGES.SIGNED_OUT_ANOTHER_TAB_OR_WINDOW);
                } else {
                    setIsDialogOpen(false);
                }
            }
        }

        window.addEventListener('storage', authChangeHandler);
        setIsDialogOpen(false);

        return () => {
            window.removeEventListener('storage', authChangeHandler);
        };
    }, [userId]);

    const handleReloadClick = () => {
        window.location.href = '/';
    };

    return (
        <Dialog className="session-change-listener-dialog" open={isDialogOpen}>
            <Typography className="dialog-text">
                {message} Reload to refresh your session.
            </Typography>
            <PrimaryButton className="reload-btn" text='Reload' handleClick={handleReloadClick} />
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
});

export default connect(mapStateToProps)(SessionChangeListener);
