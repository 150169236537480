export const AGENCY_PROFILE_CREATION_STEPS_TO_COMPLETE = 3;

export const COMPANY_SIZES = {
    SMALL: '0-10',
    MEDIUM: '11-50',
    LARGE: '51-100',
    LARGER: '101-250',
    LARGEST: '250+',
};

export const COMPANY_SIZES_VALUES = {
    [COMPANY_SIZES.SMALL]: 1,
    [COMPANY_SIZES.MEDIUM]: 2,
    [COMPANY_SIZES.LARGE]: 3,
    [COMPANY_SIZES.LARGER]: 4,
    [COMPANY_SIZES.LARGEST]: 5,
};

export const COMPANY_SIZES_ARRAY = Object.values(COMPANY_SIZES).map(x => ({ name: x, value: COMPANY_SIZES_VALUES[x] }));
