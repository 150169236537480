import CandidateCustomerSupport from './CustomerSupportViews/CandidateCustomerSupport';
import ClientCustomerSupport from './CustomerSupportViews/ClientCustomerSupport';

import { ROLES } from 'constants/userConstants';

export const getCustomerSupportComponent = displayMode => {

    switch (displayMode) {
        case ROLES.CANDIDATE:
            return CandidateCustomerSupport;
        case ROLES.CLIENT:
            return ClientCustomerSupport;
        default:
            return CandidateCustomerSupport;
    }
};
