import React from 'react';
import { Formik } from 'formik';
import MomentUtils from '@date-io/moment';

import SelectEmploymentOptionsDialogForm from './SelectEmploymentOptionsDialogForm';
import Dialog from 'components/Shared/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { validateRequiredFields } from 'utils/formValidations';
import { addUtcOffset } from 'utils/formatTimes';

import './SelectEmploymentOptionsDialog.scss';

const SelectEmploymentOptionsDialog = ({
    infoText,
    openDialog,
    handleCloseDialog,
    handleSubmit,
    handleCloseCurrentDialog,
    startDateInitialValue,
}) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize
                initialValues={{
                    employmentType: '',
                    startDate: startDateInitialValue || null,
                }}
                validate={(values) => {
                    return {
                        ...validateRequiredFields(values, ['employmentType', 'startDate']),
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    values.startDate = addUtcOffset(values.startDate);
                    setSubmitting(false);
                    handleSubmit({ ...values });
                    handleCloseCurrentDialog();
                }}
            >
                {(props) => (
                    <Dialog className="select-employment-options-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
                        <MuiDialogTitle disableTypography className="dialog-title">
                            <Typography variant="body2" className="dialog-title-text">Select Employment Options</Typography>
                            <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                                <CloseIcon />
                            </IconButton>
                        </MuiDialogTitle>

                        <DialogContent>
                            <SelectEmploymentOptionsDialogForm
                                {...props}
                                handleCloseDialog={handleCloseDialog}
                                infoText={infoText}
                            />
                        </DialogContent>
                    </Dialog>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    )
};

export default SelectEmploymentOptionsDialog;
