import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { getChecklistedCandidates } from 'reducers';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import { updateQuery } from 'utils/helpers';
import { useDebounce } from 'utils/hooks';

import { CANDIDATES_CHECKLIST_FILTER_LIST_ITEMS } from '../CandidatesChecklistConstants';

const TeamDocumentsTableActionsWrapper = ({
    filters,
    totalChecklistedCandidatesCount
}) => {
    const history = useHistory();
    const [inputValue, setInputValue] = useState('');

    const debouncedInputValue = useDebounce(inputValue, 100);

    useEffect(() => {
        if (filters.searchTerm) {
            setInputValue(filters.searchTerm);
        }
    }, [filters.searchTerm]);

    useEffect(() => {
        if (debouncedInputValue) {
            handleSearchTermFilterChange(debouncedInputValue);
        }
    }, [debouncedInputValue]);

    const onSearchFieldChangeHandler = (e) => {
        if (e.target.value) {
            setInputValue(e.target.value)
        } else {
            setInputValue('');
            handleSearchTermFilterChange('');
        };
    }

    const handleSingleSelectFilterChange = (filterName, value) => {
        const filterObj = filters[filterName] !== value
            ? { [filterName]: value }
            : { [filterName]: '' };

        const updatedQuery = updateQuery(history.location.search, filterObj);

        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    const handleSearchTermFilterChange = (value) => {
        const updatedQuery = updateQuery(history.location.search, { searchTerm: value });
        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    return (
        <SectionActionsWrapper>
            <SectionActionsWrapper.SectionTitle sectionTitle={`${totalChecklistedCandidatesCount} Candidates`} />

            <SectionActionsWrapper.LeftAlignedActions classes={['no-margin']}>
                <SectionActionsWrapper.LeftAlignedActions.GroupedSelectFilterListWrapper
                    title="Filter by"
                    className="candidates-checklist"
                    filters={filters}
                    groups={CANDIDATES_CHECKLIST_FILTER_LIST_ITEMS}
                    handleChange={handleSingleSelectFilterChange}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    closeMenuOnSelect={true}
                />

                <SectionActionsWrapper.LeftAlignedActions.SearchFilter
                    classes={['candidates-checklist']}
                    fieldValue={inputValue}
                    onChangeHandler={onSearchFieldChangeHandler}
                />
            </SectionActionsWrapper.LeftAlignedActions>
        </SectionActionsWrapper>
    );
};

const mapStateToProps = (state, props) => ({
    totalChecklistedCandidatesCount: getChecklistedCandidates(state, null, null, props.filters).totalChecklistedCandidatesCount,
});

export default connect(mapStateToProps)(TeamDocumentsTableActionsWrapper);
