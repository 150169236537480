import React from 'react';

import './ApplicationCustomTooltip.scss';

const ApplicationCustomTooltip = ({
    styles,
    children
}) => {
    return (
        <div
            className="application-custom-tooltip"
            style={styles}
        >
            {children}
        </div>
    );
};

export default ApplicationCustomTooltip;
