import DefaultYourAccountManagerInformation from './YourAccountManagerInformationViews/DefaultYourAccountManagerInformation';
import ClientYourAccountManagerInformation from './YourAccountManagerInformationViews/ClientYourAccountManagerInformation';

import { ROLES } from 'constants/userConstants';

export const getYourAccountManagerInformationComponent = displayMode => {

    switch (displayMode) {
        case ROLES.CLIENT:
            return ClientYourAccountManagerInformation;
        default:
            return DefaultYourAccountManagerInformation;
    }
};
