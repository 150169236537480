export const EXPIRED_LINK_TYPES = {
    RESET_PASSWORD: 'reset-password',
    SET_PASSWORD: 'set-password',
};

export const EXPIRED_LINK_PAGE_DATA = {
    [EXPIRED_LINK_TYPES.SET_PASSWORD]: {
        buttonName: 'Request New Link',
        title: 'Your invitation link has already expired.',
        text: 'Please click on the button below to receive a new email and complete your registration.'
    },
    [EXPIRED_LINK_TYPES.RESET_PASSWORD]: {
        buttonName: 'Reset Password',
        title: 'Your password reset link has already expired.',
        text: 'Please request a new reset password link from the button below.',
    }
}
