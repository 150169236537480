import {
    SEARCH_RESULTS_PENDING,
    SEARCH_RESULTS_FETCH_SUCCESS,
    SEARCH_RESULTS_BY_NAME_FETCH_SUCCESS,
    USERS_RESTORE_SUCCESS
} from 'actions/actionTypes';

const searchInitialState = [];

const searchReducer = (state = searchInitialState, action) => {
    switch (action.type) {
        case SEARCH_RESULTS_PENDING:
            return searchInitialState;
        case SEARCH_RESULTS_FETCH_SUCCESS:
        case SEARCH_RESULTS_BY_NAME_FETCH_SUCCESS:
            return action.payload || searchInitialState;
        case USERS_RESTORE_SUCCESS:
            const updatedSearchResult = state.map(user => {
                if (user._id === action.payload[0]) {
                    user.isDeleted = false
                }
                return user;
            });
            return updatedSearchResult;
        default:
            return state;
    }
};

export default searchReducer;

export const getSearchResults = state => state;
export const getSearchResultsWithoutDeleted = (state) => state.filter(x => !x.isDeleted);
