import React from 'react';

import CoachingOpportunityCard from './CoachingOpportunityCard';

const CoachingOpportunities = ({
    coachingOpportunities,
    setAnchorEl,
    setSelectedOpportunity,
}) => (
    <div className="coaching-opportunities-wrapper">
        {coachingOpportunities.map(x => (
            <CoachingOpportunityCard
                key={x._id}
                coachingOpportunity={x}
                setAnchorEl={setAnchorEl}
                setSelectedOpportunity={setSelectedOpportunity}
            />
        ))}
    </div>
);

export default CoachingOpportunities;
