export const HOLIDAY_SIGNATURE_STATUSES = {
    PENDING: 'sign pending',
    REJECTED: 'sign rejected',
    SIGNED: 'signed',
};

export const HOLIDAY_TYPES_FULL_NAMES_MAP = {
    155: 'Regular Paid Holiday',
    169: 'Trainings and Certifications'
};

export const HOLIDAY_APPROVAL_STATUSES = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    DELETED: 'deleted',
};
