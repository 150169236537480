import moment from 'moment';

import { AGENCY_DOCUMENTS_FETCH_SUCCESS, AGENCY_DOCUMENTS_REPLACE_SUCCESS } from 'actions/actionTypes';
import {
    CONTRACT_SIGNATURE_STATUSES_TO_DOCUMENT_STATUSES_MAP,
    CONTRACT_TYPES_TO_DOCUMENT_TYPES_MAP
} from 'constants/documentsConstants';
import { DATE_FORMAT_WITH_SLASH_SHORT_YEAR } from 'constants/commonConstants';

import { escapeRegExp } from 'utils/regexUtils';

const agencyDocuments = (state = [], action) => {

    switch (action.type) {
        case AGENCY_DOCUMENTS_FETCH_SUCCESS:
            return [...state, ...action.payload];
        case AGENCY_DOCUMENTS_REPLACE_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
};

export default agencyDocuments;

export const getAgencyDocuments = (state, page, count, comparer, filters) => {
    const from = page * count;
    const to = page * count + count;

    let filteredDocuments = state
        .filter(document => {
            const hasDocumentType = filters?.documentType
                ? filters.documentType === CONTRACT_TYPES_TO_DOCUMENT_TYPES_MAP[document.type]
                : true;

            const hasStatus = filters?.documentStatus
                ? filters.documentStatus === CONTRACT_SIGNATURE_STATUSES_TO_DOCUMENT_STATUSES_MAP[document.signatureStatus]
                : true;

            let isUploadDateInRange = true;
            if (filters?.uploadDate) {
                const [uploadDateFrom, uploadDateTo] = filters.uploadDate.split('-');
                const documentUploadDateParsed = moment(document.uploadDate).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR);

                isUploadDateInRange = moment(documentUploadDateParsed, DATE_FORMAT_WITH_SLASH_SHORT_YEAR)
                    .isBetween(
                        moment(uploadDateFrom, DATE_FORMAT_WITH_SLASH_SHORT_YEAR),
                        moment(uploadDateTo, DATE_FORMAT_WITH_SLASH_SHORT_YEAR),
                        undefined,
                        '[]'
                    );
            }

            let isSignatureDateInRange = true;
            if (filters?.signatureDate) {
                if (document.dateSigned) {
                    const [signatureDateFrom, signatureDateTo] = filters.signatureDate.split('-');
                    const documentDateSignedParsed = moment(document.dateSigned).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR);

                    isSignatureDateInRange = moment(documentDateSignedParsed, DATE_FORMAT_WITH_SLASH_SHORT_YEAR)
                        .isBetween(
                            moment(signatureDateFrom, DATE_FORMAT_WITH_SLASH_SHORT_YEAR),
                            moment(signatureDateTo, DATE_FORMAT_WITH_SLASH_SHORT_YEAR),
                            undefined,
                            '[]'
                        );
                } else {
                    isSignatureDateInRange = false;
                }
            }

            let isSearchMatch = true;
            if (filters?.searchTerm) {
                const regexTest = new RegExp(escapeRegExp(filters.searchTerm), 'gi');
                isSearchMatch = regexTest.test(`${document.user.firstName} ${document.user.lastName}`);
            }

            return hasStatus && hasDocumentType && isUploadDateInRange && isSignatureDateInRange && isSearchMatch;
        });

    if (comparer) {
        filteredDocuments = filteredDocuments.sort(comparer);
    }

    return {
        slicedFilteredDocuments: filteredDocuments.slice(from, to || filteredDocuments.length),
        allFilteredDocuments: filteredDocuments,
        totalDocumentsCount: filteredDocuments.length,
    };
};
