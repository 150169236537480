import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, InputAdornment, Popper, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import './MultiSelectAutocomplete.scss';

const CustomPopper = function (props) {
    return (
        <Popper
            {...props}
            className="multi-select-autocomplete-popover"
            style={{ minWidth: 180, width: 'fit-content' }}
            placement="bottom-start"
        />
    );
};

const MultiSelectAutocomplete = ({ 
    items,
    currentlyChecked,
    name,
    onChangeHandler,
    className 
}) => {
    const notSelectedItems = items.filter(x => !currentlyChecked.includes(x)).sort((a, b) => a.localeCompare(b));
    const itemsSorted = [...currentlyChecked.sort((a, b) => a.localeCompare(b)), ...notSelectedItems];

    return (
        <Autocomplete
            PopperComponent={CustomPopper}
            ListboxProps={{ className: 'multi-select-autocomplete-list-box' }}
            className={`multi-select-autocomplete ${className ? className : ''}`}
            multiple
            value={currentlyChecked}
            options={itemsSorted}
            groupBy={(option) => {
                return currentlyChecked.includes(option) ? 'Selected' : option[0].toUpperCase();
            }}
            ChipProps={{ style: { display: 'none' } }}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(option) => (
                <span className="multi-select-autocomplete-list-box-option">
                    <Checkbox checked={currentlyChecked.includes(option)} />
                    {option}
                </span>
            )}
            disableClearable
            onChange={(e, value) => onChangeHandler(value)}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={name}
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                        }}
                    />
                );
            }}
        />
    );
};

export default MultiSelectAutocomplete;
