import React, { createContext } from 'react';

import Paper from '@material-ui/core/Paper';
import MatchingJobTypeLabel from './MatchingCardParts/MatchingJobTypeLabel';
import MatchingJobLocationLabel from './MatchingCardParts/MatchingJobLocationLabel';
import MatchingJobMainInfo from './MatchingCardParts/MatchingJobMainInfo';
import MatchingJobProfiles from './MatchingCardParts/MatchingJobProfiles';
import MatchingJobFooter from './MatchingCardParts/MatchingJobFooter';

import './MatchingJobCard.scss';

export const MatchingJobCardContext = createContext();

const { Provider } = MatchingJobCardContext;

const MatchingJobCard = ({
    agencyId,
    isAdmin,
    job,
    onEditClick,
    handleOpenDeleteDialog,
    updateJob,
    updateJobOpportunityPrivacy,
    setClosePopoverFunction,
    children,
}) => {
    const context = {
        agencyId,
        isAdmin,
        job,
        onEditClick,
        handleOpenDeleteDialog,
        updateJob,
        updateJobOpportunityPrivacy,
        setClosePopoverFunction,
    };

    return (
        <div className="profile-matching-job-card-wrapper">
            <Provider value={context}>
                <Paper>
                    <div>
                        {children}
                    </div>
                </Paper>
            </Provider>
        </div>
    );
};

MatchingJobCard.MatchingJobTypeLabel = MatchingJobTypeLabel;
MatchingJobCard.MatchingJobLocationLabel = MatchingJobLocationLabel;
MatchingJobCard.MatchingJobMainInfo = MatchingJobMainInfo;
MatchingJobCard.MatchingJobProfiles = MatchingJobProfiles;
MatchingJobCard.MatchingJobFooter = MatchingJobFooter;

export default MatchingJobCard;
