import React from 'react';
import { connect } from 'react-redux';

import TaskCardList from 'components/Person/Tasks/TaskCardList';

const TasksRedux = ({
    tasks,
    dataFetchState,
    ...props
}) => {
    return (
        <TaskCardList
            dataFetchState={dataFetchState}
            tasks={tasks}
            {...props}
        />
    )
}

const mapStateToProps = (state, props) => ({
    tasks: props.dataSelector(state, props.filters),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TasksRedux);
