import React from 'react';
import { useIntl } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Icon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { ReactComponent as LinkIcon } from 'assets/link-icon.svg';

import './UploadMethodDialog.scss';

const UploadMethodDialog = ({
    isDialogOpen,
    handleCloseDialog,
    handleOpenUploadLinkDialog,
    handleOpenUploadFilesDialog,
}) => {
    const intl = useIntl();

    return (
        <Dialog className="upload-method-dialog" open={isDialogOpen} onClose={handleCloseDialog} maxWidth={'md'}>
            <MuiDialogTitle disableTypography className="dialog-header">
                <Typography variant="subtitle2" className="dialog-title-text">
                    {intl.formatMessage({ id: "upload-documents-text" })}
                </Typography>
                <IconButton onClick={handleCloseDialog} className="close-icon">
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>

            <DialogContent className="dialog-content">
                <IconButton className="upload-file-icon-button" disableRipple={true} onClick={() => handleOpenUploadFilesDialog()}>
                    <DescriptionOutlinedIcon />
                    <Typography className="upload-file-icon-label" variant="subtitle2">{intl.formatMessage({ id: "upload-file" })}</Typography>
                </IconButton>

                <IconButton className="upload-link-icon-button" disableRipple={true} onClick={() => handleOpenUploadLinkDialog()}>
                    <Icon className="upload-link-icon">
                        <LinkIcon />
                    </Icon>
                    <Typography variant="subtitle2">{intl.formatMessage({ id: "upload-link" })}</Typography>
                </IconButton>
            </DialogContent>
        </Dialog>
    )
};

export default UploadMethodDialog;
