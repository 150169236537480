import { ReactComponent as DeveloperRoleIcon } from 'assets/developer-role-icon.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';
import { ReactComponent as CompanyIcon } from 'assets/company.svg';

export const DETAILS_HEADER_KEYS = {
    JOB_TYPE: 'JOB_TYPE',
    APPLICATION_DATE: 'APPLICATION_DATE',
    COMPANY: 'COMPANY',
};

export const TOOLTIPS = {
    [DETAILS_HEADER_KEYS.JOB_TYPE]: 'Profile type',
    [DETAILS_HEADER_KEYS.APPLICATION_DATE]: 'Application Date',
    [DETAILS_HEADER_KEYS.COMPANY]: 'Company',
};

export const FIELDS = {
    [DETAILS_HEADER_KEYS.JOB_TYPE]: 'jobType',
    [DETAILS_HEADER_KEYS.APPLICATION_DATE]: 'appliedDate',
    [DETAILS_HEADER_KEYS.COMPANY]: 'agencyName',
};

export const ICONS = {
    [DETAILS_HEADER_KEYS.JOB_TYPE]: <DeveloperRoleIcon />,
    [DETAILS_HEADER_KEYS.APPLICATION_DATE]: <CalendarIcon />,
    [DETAILS_HEADER_KEYS.COMPANY]: <CompanyIcon />,
};
