import React from 'react';
import moment from 'moment';
import { Form } from 'formik';

import MomentUtils from '@date-io/moment';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Switcher from 'components/Shared/Switcher';

import { DATE_PICKER_FORMAT } from 'constants/timesFormat';
import { CANDIDATE_SALARY_CURRENCIES } from 'constants/currenciesConstant';
import { COUNTRY_OF_TAX_OTHER, SUPPORTED_COUNTRIES_LIST } from 'constants/candidateConstants';

const CandidateJourneyPreferencesFormView = ({
    setFieldValue,
    handleSubmit,
    handleChange,
    errors,
    touched,
    values,
    countries,
    handleBlur,
    handleIsEarliestDateUnknownClick,
    setIsUnsupportedLocationDialogOpen,
    setValues,
}) => {
    const otherCountriesList = countries?.filter((country) => !SUPPORTED_COUNTRIES_LIST.includes(country.name)).map((country) => country.name);

    return (
        <Form>
            <div className="form-row">
                <Typography className="form-sub-title">Which country are you a tax resident of?</Typography>

                <Autocomplete
                    name="countryOfTax"
                    value={values.countryOfTax}
                    options={[...SUPPORTED_COUNTRIES_LIST, COUNTRY_OF_TAX_OTHER]}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderOption={(option) => <span>{option}</span>}
                    onChange={(event, newValue) => {
                        if (newValue === COUNTRY_OF_TAX_OTHER) {
                            setIsUnsupportedLocationDialogOpen(true);
                        }
                        setFieldValue('countryOfTax', newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={`${touched.countryOfTax && errors.countryOfTax ? "with-error" : "base-input"}`}
                            value={values.countryOfTax}
                            onBlur={handleBlur}
                            label="Choose Country"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                            }}
                            error={touched.countryOfTax && Boolean(errors.countryOfTax)}
                            helperText={touched.countryOfTax && errors.countryOfTax}
                        />
                    )}
                />
            </div>

            {values.countryOfTax === COUNTRY_OF_TAX_OTHER &&
                <div className="form-row">
                    <Autocomplete
                        name="otherCountryOfTax"
                        value={values.otherCountryOfTax}
                        options={otherCountriesList}
                        autoHighlight
                        getOptionLabel={(option) => option}
                        renderOption={(option) => <span>{option}</span>}
                        onChange={(event, newValue) => {
                            setFieldValue('otherCountryOfTax', newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={`${touched.otherCountryOfTax && errors.otherCountryOfTax ? "with-error" : "base-input"}`}
                                value={values.otherCountryOfTax}
                                onBlur={handleBlur}
                                label="Other Country"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                }}
                                error={touched.otherCountryOfTax && Boolean(errors.otherCountryOfTax)}
                                helperText={touched.otherCountryOfTax && errors.otherCountryOfTax}
                            />
                        )}
                    />
                </div>
            }

            <div className="form-row">
                <Typography className="form-sub-title">
                    What is your desired monthly net salary (after tax and social security)?
                </Typography>

                <div className="form-cols">
                    <div className="col-1of2">
                        <TextField
                            className={`${touched.salaryValue && errors.salaryValue ? "with-error" : "base-input"}`}
                            type="number"
                            variant="outlined"
                            label="Enter Value"
                            InputLabelProps={{ shrink: true }}
                            value={values.salaryValue}
                            name="salaryValue"
                            fullWidth={true}
                            onChange={(event) => {
                                setFieldValue('salaryValue', Number(event.target.value));
                            }}
                            error={touched.salaryValue && Boolean(errors.salaryValue)}
                            helperText={touched.salaryValue && errors.salaryValue}
                        />
                    </div>

                    <div className="col-1of2">
                        <TextField
                            className={`${touched.salaryCurrency && errors.salaryCurrency ? "with-error" : "base-input"}`}
                            select
                            label="Choose Currency"
                            InputLabelProps={{ shrink: true }}
                            value={values.salaryCurrency}
                            variant="outlined"
                            onChange={handleChange}
                            name="salaryCurrency"
                            id="salaryCurrency"
                            fullWidth={true}
                            error={touched.salaryCurrency && Boolean(errors.salaryCurrency)}
                            helperText={touched.salaryCurrency && errors.salaryCurrency}
                        >
                            {CANDIDATE_SALARY_CURRENCIES.map((currency) => {
                                return (
                                    <MenuItem key={currency.VALUE} value={currency.LABEL}>
                                        {currency.LABEL}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </div>
                </div>
            </div>

            <div className="form-row">
                <Typography className="form-sub-title">When is the earliest you can start a new project?</Typography>
                <div className="form-cols availability-colum">
                    <div className="col-1of2 candidate-earliestDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                className={`${touched.earliestDate && errors.earliestDate ? "with-error" : "base-input"}`}
                                autoOk
                                label="Select Date"
                                InputLabelProps={{ shrink: true }}
                                name="earliestDate"
                                variant="inline"
                                disableToolbar
                                disablePast
                                format={DATE_PICKER_FORMAT}
                                openTo="date"
                                inputVariant="outlined"
                                value={values.earliestDate}
                                onChange={(value) => {
                                    setFieldValue('earliestDate', moment(value));
                                }}
                                fullWidth
                                disabled={values.isEarliestDateUnknown}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                error={touched.earliestDate && Boolean(errors.earliestDate)}
                                helperText={touched.earliestDate && errors.earliestDate}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className="col-1of2 candidate-isAvailability-unknown">
                        <Switcher
                            isChecked={values.isEarliestDateUnknown}
                            initialValue={values.isEarliestDateUnknown}
                            changeStatusHandler={() => handleIsEarliestDateUnknownClick(values, setValues)}
                            label={"Not sure yet"}
                        />
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default CandidateJourneyPreferencesFormView;
