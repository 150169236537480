import moment from 'moment'

import {
    CLIENTS_DELETE_SUCCESS,
    CLIENT_FETCH_ALL_SUCCESS,
    CLIENT_REGISTER_FETCH_SUCCESS,
    CLIENT_UPDATE_FLAG_SUCCESS,
    CLIENT_PAGE_FETCH_SUCCESS,
    AGENCY_QUERIED_CLIENTS_RESET_FETCH_SUCCESS,
} from 'actions/actionTypes';

import {
    activeAndProspectStatusFilterValue,
    allStatusesFilterValue,
    CLIENT_ACTIVE_PROSPECTIVE_STATUS,
    CLIENT_ACTIVE_STATUS,
    CLIENT_PROSPECTIVE_STATUS,
    CLIENT_ACCOUNT_MANAGER_NONE,
    inactiveStatusFilterValue,
} from 'components/Clients/ClientsConstants';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

const clientsInitialState = [];

const clients = (state = clientsInitialState, action) => {
    switch (action.type) {
        case CLIENT_FETCH_ALL_SUCCESS:
            return action.payload || clientsInitialState;
        case CLIENT_PAGE_FETCH_SUCCESS:
            return [...state, ...action.payload];
        case CLIENT_REGISTER_FETCH_SUCCESS:
            return [...[action.payload], ...state];
        case CLIENT_UPDATE_FLAG_SUCCESS:
            return state.map(x => {
                if (x._id === action.payload._id) {
                    x.flag = action.payload.flag;
                }

                return x;
            });
        case CLIENTS_DELETE_SUCCESS:
            return state.filter(x => !action.payload.includes(x._id));
        case AGENCY_QUERIED_CLIENTS_RESET_FETCH_SUCCESS:
            return clientsInitialState;
        default:
            return state;
    }
};

export default clients;

export const getClients = (state, filters, page, count) => {
    const from = page * count;
    const to = page * count + count;

    const clientsWithFormattedIssueDates = state.map(client => {
        if (client.invoice) {
            return {
                ...client,
                invoice: {
                    issueDate: moment.utc(client.invoice.issueDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
                    status: client.invoice.status,
                },
            };
        }

        return client;
    });

    let filteredClients = [...clientsWithFormattedIssueDates].map(x => {
        if (x.accountManager.name === null) {
            x.accountManager.name = ''
        }
        return x;
    });

    if (filters) {
        if (filters.status && filters.status.value === activeAndProspectStatusFilterValue.value) {
            filteredClients = filteredClients.filter(client => client.status !== inactiveStatusFilterValue.name);
        } else if (filters.status && filters.status.value !== allStatusesFilterValue.value) {
            filteredClients = filteredClients.filter(client => client.status === filters.status.name);
        }

        if (filters.accountManager && filters.accountManager.value !== 'all') {
            filteredClients = filteredClients.filter(client => client.accountManager.name === filters.accountManager.name);
        }

        return filteredClients.slice(from, to || filteredClients.length);
    }

    return state.slice(from, to || state.length).sort((a, b) => a.name?.localeCompare(b.name));
};

export const getClientsWithCount = (state, page, count, comparer, filters) => {
    const from = page * count;
    const to = page * count + count - 1;
    const filteredClients = state
        .filter(client => {
            const isActiveOrProspectiveFilter = filters && filters.status.includes(CLIENT_ACTIVE_PROSPECTIVE_STATUS);
            let hasStatus = filters && filters.status && filters.status.includes(client.status);
            if (!hasStatus && isActiveOrProspectiveFilter) {
                hasStatus = client.status === CLIENT_ACTIVE_STATUS || client.status === CLIENT_PROSPECTIVE_STATUS;
            }

            const hasAccountManager = filters && filters.accountManager &&
                (filters.accountManager.includes(client.accountManager.name) ||
                    (filters.accountManager.includes(CLIENT_ACCOUNT_MANAGER_NONE) && !client.accountManager.name));

            return hasAccountManager && hasStatus;
        })
        .sort(comparer);

    return {
        result: filteredClients.slice(from, to || filteredClients.length),
        count: filteredClients.length,
    }
};

export const getClientsCount = state => state.length;
