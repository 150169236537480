import React from 'react';
import { useIntl } from 'react-intl';

import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { ErrorMessage } from 'formik';

import SecondaryButton from '../Buttons/SecondaryButton';

const RejectDialogForm = ({
    handleChange,
    values,
    errors,
    touched,
    handleCloseDialog,
    handleSubmit,
}) => {
    const intl = useIntl();

    return (
        <form className="reject-application-form" autoComplete="off">
            <p className="feedback-msg">{intl.formatMessage({ id: "feedback-message" })}</p>
            <div>
                <TextField
                    className="reject-feedback-field"
                    name="comment"
                    value={values.comment}
                    onChange={handleChange}
                    id="reject-application-feedback"
                    label={intl.formatMessage({ id: "overall-feedback" })}
                    error={errors.comment && touched.comment}
                    margin="normal"
                    variant="outlined"
                    multiline
                    minRows='6'
                />
                <ErrorMessage name="comment" component="div" className="invalid-field-message" />
            </div>

            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" text={intl.formatMessage({ id: "discard" })} handleClick={() => handleCloseDialog()} />
                <SecondaryButton buttonStyle="save" text={intl.formatMessage({ id: "send" })} handleClick={handleSubmit} type="submit" className="dialog-send-btn" />
            </DialogActions>
        </form>
    )
};

export default RejectDialogForm;
