import React from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import Paper from '@material-ui/core/Paper';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import {
    CHART_COLORS,
    CHART_PARAMETERS,
} from './PerformanceAnalyticsConstants';
import './PerformanceAnalytics.scss';

const PerformanceAnalytics = ({
    title,
    performanceReviews,
    performanceCriterias,
}) => {
    const intl = useIntl();

    const formatedReviews = performanceReviews ? performanceReviews.map(x => ({
        ...x.ratings,
        date: moment.utc(x.date).format(CHART_PARAMETERS.DATE_FORMAT),
        overall: x.overallRating,

    })) : [];

    const renderLegendItem = value => {
        return <span className="legend-text">{value}</span>
    };

    return (
        <div className="performance-review-wrapper performance-review-history">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={title}
                />
            </SectionActionsWrapper>

            <Paper className="performance-content-wrapper">
                <ResponsiveContainer height={CHART_PARAMETERS.CHART_HEIGHT}>
                    <LineChart
                        data={formatedReviews}
                        margin={{ top: 20, right: 0, left: 0, bottom: 20 }}
                        dot={true}
                    >
                        <CartesianGrid
                            stroke={CHART_COLORS.GRID_STROKE}
                            strokeDasharray='1'
                        />
                        <XAxis
                            dataKey='date'
                            axisLine={false}
                            padding={{ right: 5 }}
                            tickLine={false}
                            tick={{ fontSize: 11, fill: CHART_COLORS.AXIS_LINES_TICK }}
                            height={50}
                        />
                        <YAxis
                            type='number'
                            domain={[0, 5]}
                            ticks={[0, 1, 2, 3, 4, 5]}
                            interval={0}
                            axisLine={false}
                            tickLine={false}
                            tick={{ fontSize: 11, fill: CHART_COLORS.AXIS_LINES_TICK }}
                        />
                        <Legend
                            iconType='rect'
                            iconSize={14} align='left'
                            width={CHART_PARAMETERS.LEGEND_WIDTH}
                            wrapperStyle={{ paddingLeft: '50px' }}
                            formatter={renderLegendItem}
                        />
                        <Line
                            type='monotone'
                            dataKey='overall'
                            stroke={CHART_COLORS.OVERALL_STROKE}
                            strokeWidth={CHART_PARAMETERS.STROKE_WIDTH}
                            name={intl.formatMessage({ id: "overall" })}
                            isAnimationActive={false}
                        />
                        <Tooltip classes={{ tooltip: 'custom-tooltip' }}/>
                        {performanceCriterias.map((criteria, i) => (
                            <Line
                                key={i}
                                type='monotone'
                                dataKey={criteria}
                                stroke={CHART_COLORS.COLORS[i].STROKE}
                                dot={{ stroke: CHART_COLORS.COLORS[i].DOT }}
                                strokeWidth={CHART_PARAMETERS.STROKE_WIDTH}
                                name={criteria}
                                isAnimationActive={false}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </Paper>
        </div >
    )
};

export default PerformanceAnalytics;
