import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';

import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { ReactComponent as Upload } from 'assets/upload.svg';
import { BENCH_ACTIVITIES, SICK_LEAVE } from '../../TimeTrackingConstants';

const TrackTimeDialogFormView = ({
    projects,
    currentFileName,
    handleOpenUploadFileDialog,
    values,
    handleChange,
    setFieldValue,
    selectedDays,
    renderDay
}) => {
    const intl = useIntl();

    return (
        <>
            <div className='dates-container'>
                    <DayPicker
                        localeUtils={MomentLocaleUtils}
                        locale={intl.locale}
                        month={selectedDays[0] || moment()}
                        selectedDays={selectedDays}
                        firstDayOfWeek={1}
                        renderDay={renderDay}
                    />
            </div>
            <div className="input-container">
                <FormControl className="project" variant="outlined" fullWidth>
                    <InputLabel id="project-label">{intl.formatMessage({ id: "project" })}</InputLabel>
                    <Select
                        labelId="project-label"
                        id="project"
                        name="project"
                        value={values.project}
                        classes={{ root: values.project.class }}
                        onChange={async (e) => { await setFieldValue('hours', 8); handleChange(e); }}
                        label={intl.formatMessage({ id: "project" })}
                    >
                        {projects.map((project, i) => (
                            <MenuItem
                                key={i}
                                value={project}
                                classes={{ root: project.class }}
                            >
                                {project.name === SICK_LEAVE.name ? intl.formatMessage({ id: "sick-leave" }) : project.name === BENCH_ACTIVITIES.name ? intl.formatMessage({ id: "bench-activities" }) : project.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <ErrorMessage name="project" component="div" className="invalid-field-message" />
                </FormControl>
                {values.project?.isSickLeave &&
                    <div className="track-time-upload-file-container">
                        <TextField
                            className="track-time-upload-text-field"
                            label={intl.formatMessage({ id: "module" })}
                            type="text"
                            name="fileInfo"
                            variant="outlined"
                            value={currentFileName}
                            disabled
                        />

                        <div className="track-time-upload-image-wrapper">
                            <Upload
                                className="track-time-upload-image"
                                name="fileInfo"
                                onClick={async (e) => { handleOpenUploadFileDialog(e); await setFieldValue('fileInfo', currentFileName); }}
                            />
                        </div>

                        <ErrorMessage name="fileInfo" component="div" className="invalid-field-message" />
                    </div>
                }
                {!values.project?.isSickLeave &&
                    <>
                        <TextField
                            className="hours"
                            type="number"
                            label={intl.formatMessage({ id: "hours-per-day" })}
                            variant="outlined"
                            name="hours"
                            onChange={(e) => { handleChange(e); }}
                            value={values.hours}
                        />
                        <ErrorMessage name="hours" component="div" className="invalid-field-message" />

                        <TextField
                            className={'tasks-desc'}
                            label={intl.formatMessage({ id: "tasks-description" })}
                            multiline
                            minRows={5}
                            variant="outlined"
                            name="tasksDesc"
                            onChange={(e) => { handleChange(e); }}
                            inputProps={{
                                maxLength: 190
                            }}
                            value={values.tasksDesc}
                        />
                        <ErrorMessage name="tasksDesc" component="div" className="invalid-field-message" />
                    </>
                }
            </div >
        </>
    )
};

export default TrackTimeDialogFormView;
