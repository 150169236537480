import React, { createContext, useContext } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import MaterialAvatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as ProfileVettedIcon } from 'assets/profile-vetted.svg';
import { ExploreTalentsEmployeeCardContext } from '../ExploreTalentsEmployeeCard';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { ROLES } from 'constants/userConstants';
import { CANDIDATE_STATUSES } from 'constants/candidateConstants';
import { mapEmployeeOccupationStatusToStyles, getToolTipTitleBasedOnOccupationStatus } from 'constants/employeeConstants';

import { getRoleBadgeText } from 'utils/helpers';
import { BULGARIAN_LABEL } from 'constants/languages';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const CardProfileWrapperContext = createContext();
const { Provider } = CardProfileWrapperContext;

const CardProfileWrapper = ({
    children,
}) => {
    const { employee, selectedLanguage } = useContext(ExploreTalentsEmployeeCardContext);
    const context = { employee, selectedLanguage };

    return (
        <Provider value={context}>
            <div className="employee-profile-container">
                {children}
            </div>
        </Provider>
    );
};

const RoleBadge = () => {
    const intl = useIntl();
    const { employee } = useContext(CardProfileWrapperContext);

    return (
        <div className="role-badge-container">
            <div className="role-badge-wrapper">
                <span className="role-text">{intl.formatMessage({ id: convertStringToLanguageKey(getRoleBadgeText(employee))})}</span>
            </div>
        </div>
    );
};

const Avatar = () => {
    const { employee } = useContext(CardProfileWrapperContext);
    return (
        <MaterialAvatar
            alt="Employee Avatar"
            className="employee-avatar"
            src={employee.profilePictureImgUrl}
        />
    );
};

const StatusOrb = () => {
    const intl = useIntl();

    const { employee } = useContext(CardProfileWrapperContext);

    if (employee.role === ROLES.EMPLOYEE || employee.role === ROLES.ADMIN) {
        return(
            <Tooltip title={getToolTipTitleBasedOnOccupationStatus(employee.occupationStatus, intl.locale)} placement='right-end' classes={{ tooltip: 'custom-tooltip' }}>
                <div className={`status-orb ${mapEmployeeOccupationStatusToStyles(employee.occupationStatus)}`} />
            </Tooltip>
        ) 
    }
    const isEmployeeAvailable = employee.role === ROLES.CANDIDATE
        ? !!(moment(new Date()).isAfter(employee.preferences?.earliestDate))
        : employee.isAvailable;

    let tooltipTitle;
    
    if (intl.locale === BULGARIAN_LABEL) {
        tooltipTitle = `Този ${employee.role === ROLES.CANDIDATE ? `${intl.formatMessage({  id: convertStringToLanguageKey(employee.role)}).toLocaleLowerCase()}ски` : 'партньорски'} профил ${isEmployeeAvailable ? 'е на разположение' : 'не е на разположение'}`;
    } else {
        tooltipTitle = `This ${employee.role === ROLES.CANDIDATE ? employee.role : 'partner'} profile is currently ${isEmployeeAvailable ? 'available' : 'unavailable'}`;
    }
   
    return (
        <Tooltip title={tooltipTitle} placement='right-end' classes={{ tooltip: 'custom-tooltip' }}>
            <div className={isEmployeeAvailable ? 'status-orb available' : 'status-orb occupied'} />
        </Tooltip>
    );
};

const VettedProfile = () => {
    const intl = useIntl();
    const { employee } = useContext(CardProfileWrapperContext);

    const isVetted = employee.role === ROLES.CANDIDATE
        ? employee.status === CANDIDATE_STATUSES.VETTED
        : employee.isVetted;

    const getTooltipContent = () => {
        return (
            isVetted && employee?.statusHistory?.date
                ?
                <div>
                    <p>Date vetted: {moment(employee.statusHistory.date).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>
                    <p>Vetted by: {employee.statusHistory.adminName}</p>
                </div>
                : ''
        );
    };

    return (
        (employee.role !== ROLES.EMPLOYEE && employee.role !== ROLES.ADMIN)
            ? <Tooltip title={getTooltipContent()} classes={{ tooltip: 'custom-tooltip' }}>
                <div className={isVetted ? "vetted-profile vetted" : "vetted-profile not-vetted"}>
                    <ProfileVettedIcon alt="WIA logo" className="vetted-icon" />
                    <span className="vetted-status-text">
                        {isVetted ? intl.formatMessage({ id: 'vetted'}) : intl.formatMessage({ id: 'not-vetted'})}
                    </span>
                </div>
            </Tooltip>
            : null
    );
};

CardProfileWrapper.RoleBadge = RoleBadge;
CardProfileWrapper.Avatar = Avatar;
CardProfileWrapper.StatusOrb = StatusOrb;
CardProfileWrapper.VettedProfile = VettedProfile;

export default CardProfileWrapper;
