import React from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';
import moment from 'moment';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import ProjectDialogView from '../FormViews/ProjectDialogView';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import { addUtcOffset } from 'utils/formatTimes';
import {
    validateRequiredFields,
    validateInputLength,
    validateInputForWhiteSpaces,
    validateDateFields,
    validateEndDateNotBeforeStartDate,
    validateStartDateNotBeforeEndDate
} from 'utils/formValidations';

import { INPUT_FIELDS_MAX_LENGTH } from './ProjectDialogConstants';

import './ProjectDialog.scss';

const ProjectDialog = ({
    open,
    onClose,
    onSubmit,
    selectedItem,
}) => {
    const intl = useIntl();

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize
                initialValues={{
                    name: selectedItem?.name || '',
                    client: selectedItem?.client || '',
                    technologies: selectedItem?.technologies || [],
                    position: selectedItem?.position || '',
                    description: selectedItem?.description || '',
                    currentlyWork: selectedItem?.currentlyWork || false,
                    datesUnknown: selectedItem?.datesUnknown || false,
                    startDate: selectedItem?.startDate ? moment(selectedItem.startDate) : null,
                    endDate: selectedItem?.endDate ? moment(selectedItem.endDate) : null,
                }}
                validate={values => {
                    let validateDates = values.datesUnknown
                        ? {}
                        : { ...validateDateFields(values, ['startDate', 'endDate']), };

                    if (values.startDate && values.endDate) {
                        validateDates = {
                            ...validateDates,
                            ...validateEndDateNotBeforeStartDate(values, 'startDate', 'endDate'),
                            ...validateStartDateNotBeforeEndDate(values, 'startDate', 'endDate', false)
                        }
                    }

                    return {
                        ...validateInputForWhiteSpaces(values, ['name']),
                        ...validateRequiredFields(values, ['name']),
                        ...validateInputLength(values, [
                            { property: 'name', maxLength: INPUT_FIELDS_MAX_LENGTH.NAME },
                            { property: 'client', maxLength: INPUT_FIELDS_MAX_LENGTH.CLIENT },
                            { property: 'description', maxLength: INPUT_FIELDS_MAX_LENGTH.DESCRIPTION },
                            { property: 'position', maxLength: INPUT_FIELDS_MAX_LENGTH.POSITION },
                        ]),
                        ...validateDates,
                    }
                }}
                onSubmit={(values) => {
                    const {
                        name,
                        client,
                        technologies,
                        position,
                        description,
                        currentlyWork,
                        datesUnknown,
                        startDate,
                        endDate,
                    } = values;

                    const entry = {
                        name,
                        client,
                        technologies,
                        position,
                        description,
                        datesUnknown,
                        startDate: datesUnknown ? '' : addUtcOffset(startDate),
                        endDate: datesUnknown ? '' : addUtcOffset(endDate),
                        currentlyWork,
                    };

                    selectedItem
                        ? onSubmit({ ...selectedItem, ...entry }, selectedItem?.index)
                        : onSubmit(entry);

                    onClose();
                }}
            >
                {(props) => (
                    <CommonFormikDialog
                        size="sm"
                        className="project-dialog"
                        dialogTitle={intl.formatMessage({ id: "add-experience" })}
                        saveBtnText="save"
                        discardBtnText="discard"
                        open={open}
                        onClose={onClose}
                        onSave={props.handleSubmit}
                    >
                        <ProjectDialogView {...props} />
                    </CommonFormikDialog>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

export default ProjectDialog;
