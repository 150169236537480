import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getCandidateApplicationsCountByStatus } from 'reducers';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import { FILTER_TABS, SORT_VALUES } from 'components/JobPage/AppliedCandidates/AppliedCandidatesConstants';

import './CandidateApplicationsActions.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const CandidateApplicationsActions = ({
    displayMode,
    filters,
    selectCandidateStatusTab,
    handleSortChange,
    handleSearchTermChange,
    statusCounts,
}) => {
    const intl = useIntl();

    return (
        <div className="candidate-applications-actions">
            <div className="candidate-applications-tabs-container">
                {
                    FILTER_TABS[displayMode].map((tab, i) => (
                        <div
                            key={(tab.value + i)}
                            className={`candidate-applications-tab ${filters.status === tab.name ? 'active-applications-tab' : ''}`}
                            onClick={() => selectCandidateStatusTab(tab.name)}
                        >
                            <h3 className="candidate-applications-tab-title">{intl.formatMessage({ id: convertStringToLanguageKey(tab.name) })} ({statusCounts[tab.value]})</h3>
                        </div>
                    ))
                }
            </div>
            <SectionActionsWrapper.LeftAlignedActions>
                <SectionActionsWrapper.LeftAlignedActions.SelectFilterList
                    name="sortBy"
                    title={intl.formatMessage({ id: "sort-by" })}
                    value={filters.sortBy}
                    items={SORT_VALUES}
                    onChangeHandler={handleSortChange}
                    closeMenuOnSelect={true}
                    withTranslation
                />

                <SectionActionsWrapper.LeftAlignedActions.SearchFilter
                    classes={["candidate-applications"]}
                    fieldValue={filters.searchTerm}
                    placeholder={intl.formatMessage({ id: "search-for-a-name" })}
                    onChangeHandler={handleSearchTermChange}
                />
            </SectionActionsWrapper.LeftAlignedActions>
        </div>
    );
};

const mapStateToProps = (state) => ({
    statusCounts: getCandidateApplicationsCountByStatus(state),
});

export default connect(mapStateToProps)(CandidateApplicationsActions);
