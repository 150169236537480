import storage from 'redux-persist/lib/storage';

import { LOGOUT_FETCH_SUCCESS } from 'actions/actionTypes';

export const resetEnhancer = reducer => (state, action) => {
    if (action.type === LOGOUT_FETCH_SUCCESS) {
        storage.removeItem('persist:auth');
        storage.removeItem('persist:menu');

        storage.removeItem('persist:employee');
        storage.removeItem('persist:jobTechnologies');
        storage.removeItem('persist:agencyName');
        storage.removeItem('persist:client');

        const newState = reducer(undefined, {});
        return {
            ...newState,
            auth: {
                ...newState.auth,
                _persist: { version: - 1, rehydrated: true }
            },
            menu: {
                ...newState.menu,
                _persist: { version: - 1, rehydrated: true }
            },
            jobOpportunities: {
                ...newState.jobOpportunities,
                _persist: { version: - 1, rehydrated: true }
            },
            employee: {
                ...newState.employee,
                _persist: { version: - 1, rehydrated: true }
            },
            notification: {
                ...state.notification
            },
            agency: {
                ...newState.agency,
                agencyInformation: {
                    ...newState.agency.agencyInformation,
                    _persist: { version: - 1, rehydrated: true }
                }
            },
            client: {
                ...newState.client,
                _persist: { version: - 1, rehydrated: true }
            }
        };
    }

    return reducer(state, action);
};

const pendingActions = {};
export const transactions = () => next => action => {
    if (action.transactionId) {
        if (action.type.endsWith('PENDING')) {
            const actionType = action.type.replace('PENDING', '');

            pendingActions[actionType] = action.transactionId;
            next(action);
        } else {
            const actionType = action.type.replace('SUCCESS', '');

            if (pendingActions[actionType] && pendingActions[actionType] === action.transactionId) {
                delete pendingActions[actionType];
                next(action);
            }
        }
    } else {
        next(action);
    }
};
