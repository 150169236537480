import React from 'react';

import NothingToShow from 'components/NothingToShow';
import TablePaging from 'components/Shared/TablePaging'
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CANDIDATES_CHECKLIST_HEAD_CELLS, CANDIDATES_CHECKLIST_TABLE_ROWS_OPTIONS } from '../CandidatesChecklistConstants';

const CandidatesChecklistTableView = ({
    slicedFilteredChecklistedCandidates,
    totalChecklistedCandidatesCount,
    dataFetchState,
    adjustCandidateKeys,
    fromPage,
    setFromPage,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <>
            <Table className="candidates-checklist-table">
                <TableHead>
                    <TableRow>
                        {CANDIDATES_CHECKLIST_HEAD_CELLS.map(headCell => (
                            <TableCell className={`candidates-checklist-table-head-cell ${headCell.value}`} key={headCell.title}>
                                {headCell.title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {dataFetchState?.isDataFetching &&
                    <TableBody>
                        <TableRow>
                            <td> <CircularProgress size={25} data-testid="candidates-checklist-table-loader" disableShrink className="candidates-checklist-table-loader" /></td>
                        </TableRow>
                    </TableBody>
                }
                {dataFetchState.isDataFetched && totalChecklistedCandidatesCount > 0 &&
                    <TableBody>
                        {slicedFilteredChecklistedCandidates
                            .map(adjustCandidateKeys)
                            .map(candidate => {
                                return (
                                    <TableRow
                                        className="candidates-checklist-table-body-row"
                                        key={candidate._id}
                                        tabIndex={-1}
                                    >
                                        {CANDIDATES_CHECKLIST_HEAD_CELLS.map((cell, index) => {
                                            return (
                                                <TableCell
                                                    className={`candidates-checklist-table-body-cell ${cell.value}`}
                                                    key={index}
                                                >
                                                    {candidate[cell.value]}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                }
            </Table>
            {dataFetchState.isDataFetched && totalChecklistedCandidatesCount > 0
                ? <TablePaging
                    totalCount={totalChecklistedCandidatesCount}
                    fromPage={fromPage}
                    setFromPage={setFromPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsOptions={CANDIDATES_CHECKLIST_TABLE_ROWS_OPTIONS}
                />
                : (!dataFetchState?.isDataFetching &&
                    <NothingToShow
                        items='Candidates'
                        tableView={true}
                    />
                )
            }
        </>
    );
};

export default CandidatesChecklistTableView;
