import React from 'react';
import { useIntl } from 'react-intl';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { TAB_ITEMS } from '../PerformanceReviewConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './PerformanceTabs.scss';

const PerformanceTabs = ({
    selectedTab,
    setSelectedTab
}) => {
    const intl = useIntl();

    return (
        <div className="performance-tabs-wrapper">
            <AppBar
                position="static"
                color="transparent"
                elevation={0}
            >
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={selectedTab.index}
                    centered
                >
                    {TAB_ITEMS.map((item) => (
                        <Tab
                            className="performance-tab"
                            key={item.index}
                            label={intl.formatMessage({ id: convertStringToLanguageKey(item.text) })}
                            onClick={() => setSelectedTab(item)}
                        />
                    ))}
                </Tabs>
            </AppBar>
        </div>
    );
};

export default PerformanceTabs;
