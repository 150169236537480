import React from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import UploadFilesDialogFormView from './UploadFilesDialogFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

const UploadFilesDialog = ({
    isDialogOpen,
    handleCloseDialog,
    agencyId,
    clientId,
    projectId,
    addProjectFiles,
    handleCloseUploadMethodDialog,
}) => {
    const intl = useIntl();

    return (
        <Formik
            initialValues={{
                filesAttached: []
            }}

            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                const { filesAttached } = values;
                const data = new FormData();

                filesAttached.forEach((file, i) => {
                    data.append(`attachedfile${i + 1}`, file);
                });

                addProjectFiles(agencyId, clientId, projectId, data);

                handleCloseUploadMethodDialog();
                handleCloseDialog();
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    className="project-upload-link-dialog"
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    maxWidth={'sm'}
                    fullWidth={true}
                    onSave={props.handleSubmit}
                    dialogTitle={intl.formatMessage({ id: "upload-documents" })}
                >
                    <UploadFilesDialogFormView {...props} />
                </CommonFormikDialog>
            }
        </Formik>
    );
}

export default UploadFilesDialog;
