import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { getEmployeePendingHolidaysCount, isAdminMode } from 'reducers';

import AppBar from '@material-ui/core/AppBar';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import './SubmenuMobile.scss';

const SubmenuMobile = ({
    path,
    items,
}) => {
    const [selectedIdx, setSelectedIdx] = useState(0);

    const exact = itemPath => itemPath === '';

    return (
        <AppBar position="static" color="default">
            <Tabs
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="on"
                textColor="primary"
                className="submenu-mobile"
                value={selectedIdx}
            >
                {items.map((item, idx) => (
                    <Tab
                        key={item.text}
                        component={NavLink}
                        exact={exact(item.path)}
                        to={`${path}${item.path}`}
                        label={item.text}
                        className="tab-mobile"
                        onClick={() => setSelectedIdx(idx)}
                    />
                ))}
            </Tabs>
        </AppBar>
    );
}

const mapStateToProps = state => ({
    pendingHolidaysCount: getEmployeePendingHolidaysCount(state),
    isAdmin: isAdminMode(state),
});

export default connect(mapStateToProps, null)(SubmenuMobile);
