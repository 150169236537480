import React from 'react';
import { useIntl } from 'react-intl';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import { CANDIDATE_STATUS_SELECT } from './AppliedCandidatesConstants';

import './AppliedCandidatesActions.scss';

const AppliedCandidatesActions = ({
    handleSearchTermChange,
    searchTerm,
    value,
    onChangeHandler
}) => {
    const intl = useIntl();

    return (
        <div className="candidates-list-actions">
            <SectionActionsWrapper.LeftAlignedActions.SelectOneFilter
                name='sortBy'
                classes={["candidates-sort-select", intl.locale]}
                value={value}
                onChangeHandler={onChangeHandler}
                items={CANDIDATE_STATUS_SELECT}
                checkSelected={true}
            />

            <SectionActionsWrapper.LeftAlignedActions.SearchFilter
                classes={["candidates-search-input"]}
                fieldValue={searchTerm}
                placeholder={intl.formatMessage({ id: "search-for-a-name" })}
                onChangeHandler={handleSearchTermChange}
            />
        </div>
    );
}

export default AppliedCandidatesActions;
