import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';

export default function CardContact({ project }) {
	return (
			<div className="contacts-container">
				<div className="contact-item-container">
					<div className="profile-container contact-item-wrapper">
						<div className="contact-item-icon-container">
							<AccountCircleIcon className="contact-item-icon" />
						</div>
						<div className="contact-item-content">
							<span>{project.contactPerson}</span>
						</div>
					</div>
				</div>
				<div className="contact-item-container">
					<div className="email-container contact-item-wrapper">
						<div className="contact-item-icon-container">
							<MailOutlineIcon className="contact-item-icon" />
						</div>
						<div className="contact-item-content">
							<span>{project.contactEmail}</span>
						</div>
					</div>
				</div>
				<div className="contact-item-container">
					<div className="phone-container contact-item-wrapper">
						<div className="contact-item-icon-container">
							<PhoneOutlinedIcon className="contact-item-icon" />
						</div>
						<div className="contact-item-content">
							<span>{project.contactPhone}</span>
						</div>
					</div>
				</div>
			</div>
	);
}
