
export const CLIENTS_TABLE_COLUMNS = [
    { name: 'Name', value: 'name', isSortable: true, langKey: "name-text" },
    { name: 'Admin', value: 'contactPerson', isSortable: true, langKey: "admin" },
    { name: 'Acc Manager', value: 'accountManagerName', isSortable: true, langKey: "acc-manager" },
    { name: 'Client Status', value: 'status', isSortable: true, langKey: "client-status" },
    { name: 'Invoice Status', value: 'flag', isSortable: true, langKey: "invoice-status" },
    { name: 'Payment Details', value: 'invoice', isSortable: true,  langKey: "payment-details" },
];

export const DEFAULT_CLIENTS_TABLE_COLUMNS = ["name", "contactPerson", "accountManagerName", "status", "flag", "invoice"];

export const EXPORT_FIELDS = {
    'accountManagerName': true,
    'contactNumber': false,
    'contactPerson': true,
    'invoice': true,
    'name': true,
    'status': true,
}

export const TABLE_FILTERS = [
    { key: 'status', name: 'Client Status', value: ["Active/Prospect", "Active (Invoicing)", "Inactive (Not Invoicing)", "Prospect"], hasTranslation: true },
    { key: 'accountManagerName', name: 'Account Manager', value: [], hasOnlyKeyTranslation: true }
];

export const REGISTRATION_TYPES = {
    INVITED_BY_ADMIN: 'Invited by admin',
    SELF_REGISTERED: 'Self-registered',
};

export const REGISTRATION_TYPE_MAPPER = {
    [REGISTRATION_TYPES.INVITED_BY_ADMIN]: 'invited-by-admin',
    [REGISTRATION_TYPES.SELF_REGISTERED]: 'self-registered',
}

export const CLIENT_TABLE_LEGEND_ITEMS = [
    { color: "#FFDD00", text: "Registered via link", langKey: "registration-link" },
    { color: "#16D696", text: "Invited by the admin", langKey: "invited-by-admin" },
];

export const CLIENTS_SORT_QUERY_SELECTOR = 'clientsSort';
export const CLIENTS_PAGE_QUERY_SELECTOR = 'clientsTablePage';
export const HOLIDAY_DATE_QUERY_SELECTOR = 'holidayDate';
export const CLIENTS_SEARCH_QUERY_SELECTOR = 'clientsSearch';

export const CLIENTS_TABLE_QUERY_MAP = {
    filters: "filters",
    page: CLIENTS_PAGE_QUERY_SELECTOR,
    search: CLIENTS_SEARCH_QUERY_SELECTOR,
    date: HOLIDAY_DATE_QUERY_SELECTOR,
    sortBy: CLIENTS_SORT_QUERY_SELECTOR,
    sortByDir: `${CLIENTS_SORT_QUERY_SELECTOR}Dir`,
};

export const CLIENTS_TABLE_INITIAL_QUERY = {
    page: 1,
    filters: JSON.stringify({}),
    search: "",
    sortBy: null,
    sortByDir: null,
    pagesToLoad: 5,
    itemsPerPage: 25,
};
