import React from 'react';
import { useIntl } from 'react-intl';

import { Tooltip } from '@material-ui/core';

import { STATUSES } from './JobStatusLabelConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './JobStatusLabel.scss';

const JobStatusLabel = ({
    status
}) => {
    const intl = useIntl();

    const getStatusClass = (status) => {
        return status?.toLowerCase().split(' ').join('-');
    }

    return (
        <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={`This position is currently ${STATUSES[status?.toLowerCase()]?.toLowerCase()}`}>
            <span className={`job-status job-status-${getStatusClass(status)}`}>{intl.formatMessage({ id: convertStringToLanguageKey(STATUSES[status?.toLowerCase()]) })}</span>
        </Tooltip>
    );
};

export default JobStatusLabel;
