import api from './api';
import requester from './requester';

const supplierService = {
    registerSupplier: (agencyId, supplierData) => requester(api.suppliers(agencyId)).create(supplierData),   
    fetchSuppliers: (agencyId, query) => requester(api.suppliers(agencyId, query)).getMany(),
    fetchSupplierEmployeeProfile: (agencyId, supplierId, employeeId) => requester(api.supplierEmployee(agencyId, supplierId, employeeId)).getOne(),
    exportSuppliers: (agencyId, exportData) => requester(api.suppliersExport(agencyId)).saveAsAction('suppliers-table', exportData),
    exportSupplierTeam: (agencyId, supplierId, query, fileName) => requester(api.supplierTeamExport(agencyId, supplierId)).saveAsAction(fileName, query),
    getSupplierEmployeesExportedCVs: (agencyId, supplierIds, fileName) => requester(api.supplierEmployeesCVs(agencyId, supplierIds)).saveAs(fileName),
    fetchSupplierEmployeesProjects: (agencyId, supplierId) => requester(api.supplierEmployeesProjects(agencyId, supplierId)).getMany(),
    fetchSupplierInformation: (agencyId, supplierId) => requester(api.supplierInformation(agencyId, supplierId)).getOne(),    
    updateAvatar: (croppedImgData, agencyId, supplierId) => requester(api.updateSupplierAvatar(agencyId, supplierId)).uploadFile(croppedImgData),
    deleteAvatar: (agencyId, supplierId) => requester(api.deleteSupplierAvatar(agencyId, supplierId)).delete(),
    fetchSupplierEmployeeViewProfileData: (userId, agencyId) => requester(api.supplierEmployeeViewProfileData(userId, agencyId)).getHTML(),
    updateSupplierBasicInformation: (agencyId, supplierId, basicInfo) => requester(api.updateSupplierBasicInformation(agencyId, supplierId)).create(basicInfo),
    updateSupplierContactInformation: (agencyId, supplierId, contactInfo) => requester(api.updateSupplierContactInformation(agencyId, supplierId)).create(contactInfo),
    updateSupplierInvoiceInformation: (agencyId, supplierId, invoceInfo) => requester(api.updateSupplierInvoiceInformation(agencyId, supplierId)).create(invoceInfo),
    updateAccountManagerInformation: (agencyId, supplierId, data) =>
        requester(api.supplierAccountManagerInformation(agencyId, supplierId)).create(data),
    fetchSupplierEmployeeSettings: (agencyId, supplierId) => requester(api.supplierEmployeeSettings(agencyId, supplierId)).getOne(),
    updateSupplierHolidayAllowance: (agencyId, supplierId, holidayAllowance) => requester(api.updateSupplierHolidayAllowance(agencyId, supplierId)).update(holidayAllowance),
    getAllTasks: (agencyId, supplierId) => requester(api.supplierTasks(agencyId, supplierId)).getMany(),
    createTask: (agencyId, supplierId, task) => requester(api.supplierTasks(agencyId, supplierId)).create(task),
    updateTask: (agencyId, supplierId, task) => requester(api.supplierTaskUpdate(agencyId, supplierId, task._id)).update(task),
    fetchSupplierAdminDashboardInformation: (agencyId, supplierId) => requester(api.supplierAdminDashboardInformation(agencyId, supplierId)).getOne(),
    updateSupplierFlag: (agencyId, supplierId, flag) => requester(api.supplierFlag(agencyId, supplierId)).create(flag),
    fetchSuppliersManagers: agencyId => requester(api.suppliersAccountManagers(agencyId)).getMany(),
};

export default supplierService;
