import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';

import ReportsPageFilters from './ReportsPageFilters';
import ReportsTable from './ReportsTable';
import ReportsKPIs from './ReportsKPIs';

import { exportReportsTableRow, exportReportsPage, fetchReportsPage, fetchKpiReportData } from 'actions/reportsActions';
import { getAgencyId, getReportsData, getKpiReportDataFetchState, getReportsPageDataFetchState, getLanguage } from 'reducers';

import { REPORTS_QUESTION_TO_TABLE_ROW_ID_MAP, REPORT_DATA_TABLE_TITLES } from './ReportsPageConstants';
import { MONTH_YEAR_DATE_FORMAT } from 'constants/commonConstants';

import { mapReportDataToTableContent } from './ReportsPageUtils';

import { parseQueryString } from 'utils/helpers';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const ReportsPage = ({
    history,
    agencyId,
    reportsData,
    fetchReportsPage,
    fetchKpiReportData,
    exportReportsPage,
    exportReportsTableRow,
    kpiDataFetchState,
    reportsPageDataFetchState,
    selectedLanguage
}) => {
    const intl = useIntl();

    const querySearch = parseQueryString(history.location.search);

    const [monthFilter, setMonthFilter] = useState(querySearch?.month ? moment(querySearch?.month, MONTH_YEAR_DATE_FORMAT) : moment());

    useEffect(() => {
        !kpiDataFetchState.isDataFetched && fetchKpiReportData(agencyId);
    }, []);

    useEffect(() => {
        fetchReportsPage(agencyId, monthFilter.format(MONTH_YEAR_DATE_FORMAT));
        history.replace({ search: `?month=${moment(monthFilter).format(MONTH_YEAR_DATE_FORMAT)}` });
    }, [monthFilter]);

    const handleMonthChange = (date) => {
        setMonthFilter(date);
    };

    const handleExportButtonClick = () => {
        const month = moment(monthFilter).format(MONTH_YEAR_DATE_FORMAT);
        const fileName = `report_for_${month}`;

        exportReportsPage(agencyId, month, fileName);
    };

    const handleSingleRowExport = (row) => {
        const rowId = REPORTS_QUESTION_TO_TABLE_ROW_ID_MAP[row.question];
        const month = moment(monthFilter).format(MONTH_YEAR_DATE_FORMAT);
        const fileName = `report_of_${rowId}_for_${month}`;

        exportReportsTableRow(agencyId, rowId, month, fileName);
    };

    return (
        <div className="reports-page-wrapper">
            {
                <>
                    <ReportsKPIs
                        {...reportsData.kpiReportData}
                        dataFetchState={kpiDataFetchState}
                    />

                    <ReportsPageFilters
                        handleExportButtonClick={handleExportButtonClick}
                        handleMonthChange={handleMonthChange}
                        monthFilter={monthFilter}
                    />

                    <ReportsTable
                        tableName={intl.formatMessage({ id: convertStringToLanguageKey(REPORT_DATA_TABLE_TITLES.REGISTERED_CANDIDATES) })}
                        data={mapReportDataToTableContent(REPORT_DATA_TABLE_TITLES.REGISTERED_CANDIDATES, reportsData.registeredCandidatesReportData, selectedLanguage)}
                        handleSingleRowExport={handleSingleRowExport}
                        dataFetchState={reportsPageDataFetchState}
                    />

                    <ReportsTable
                        tableName={intl.formatMessage({ id: convertStringToLanguageKey(REPORT_DATA_TABLE_TITLES.PARTNER_EMPLOYEES) })}
                        data={mapReportDataToTableContent(REPORT_DATA_TABLE_TITLES.PARTNER_EMPLOYEES, reportsData.partnerEmployeesReportData, selectedLanguage)}
                        handleSingleRowExport={handleSingleRowExport}
                        dataFetchState={reportsPageDataFetchState}
                    />

                    <ReportsTable
                        tableName={intl.formatMessage({ id: convertStringToLanguageKey(REPORT_DATA_TABLE_TITLES.OPEN_POSITIONS) })}
                        data={mapReportDataToTableContent(REPORT_DATA_TABLE_TITLES.OPEN_POSITIONS, reportsData.openPositionsReportData, selectedLanguage)}
                        handleSingleRowExport={handleSingleRowExport}
                        dataFetchState={reportsPageDataFetchState}
                    />

                    <ReportsTable
                        tableName={intl.formatMessage({ id: convertStringToLanguageKey(REPORT_DATA_TABLE_TITLES.JOB_APPLICATIONS) })}
                        data={mapReportDataToTableContent(REPORT_DATA_TABLE_TITLES.JOB_APPLICATIONS, reportsData.jobApplicationsReportData, selectedLanguage)}
                        handleSingleRowExport={handleSingleRowExport}
                        dataFetchState={reportsPageDataFetchState}
                    />

                    <ReportsTable
                        tableName={intl.formatMessage({ id: convertStringToLanguageKey(REPORT_DATA_TABLE_TITLES.NEW_HIRES) })}
                        data={mapReportDataToTableContent(REPORT_DATA_TABLE_TITLES.NEW_HIRES, reportsData.newHiresReportData, selectedLanguage)}
                        handleSingleRowExport={handleSingleRowExport}
                        dataFetchState={reportsPageDataFetchState}
                    />

                    <ReportsTable
                        tableName={intl.formatMessage({ id: convertStringToLanguageKey(REPORT_DATA_TABLE_TITLES.ATTRITION) })}
                        data={mapReportDataToTableContent(REPORT_DATA_TABLE_TITLES.ATTRITION, reportsData.attritionReportData, selectedLanguage)}
                        handleSingleRowExport={handleSingleRowExport}
                        dataFetchState={reportsPageDataFetchState}
                    />
                </>
            }
        </div>
    )
};

const mapStateToProps = state => ({
    reportsData: getReportsData(state),
    agencyId: getAgencyId(state),
    kpiDataFetchState: getKpiReportDataFetchState(state),
    reportsPageDataFetchState: getReportsPageDataFetchState(state),
    selectedLanguage: getLanguage(state)
});

const mapDispatchToProps = {
    fetchReportsPage,
    fetchKpiReportData,
    exportReportsPage,
    exportReportsTableRow,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
