import React from 'react';
import { useIntl } from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import PiggyBankIcon from 'assets/PiggyBankIcon';
import HourGlassIcon from 'assets/HourGlassIcon';
import styleConstants from 'style-constants.scss';

import './EmployeeProfilePopUp.scss';

const EmployeeProfilePopUp = ({
	employee,
	isClientMode,
	projectCurrency,
	handleOpenSubmitFeedbackPopup,
}) => {
	const intl = useIntl();

	return (
		<List className="popover-list-container">
			<ListItem button className="popover-list-item">
				<ListItemIcon className="popover-list-icon money-icon">
					<AttachMoneyIcon />
				</ListItemIcon>
				<ListItemText
					primary={`${Number(employee.hourRate).toFixed(2) || 0.00} ${projectCurrency}/${intl.formatMessage({ id: "hour-short" })}`}
					className="list-item-text"
				/>
			</ListItem>

			<ListItem button className="popover-list-item">
				<ListItemIcon className="popover-list-icon">
					<HourGlassIcon style={{ color: styleConstants.greyDark }} />
				</ListItemIcon>
				<ListItemText
					primary={`${employee.trackedHours || 0} ${intl.formatMessage({ id: "hours-worked" })}`}
					className="list-item-text"
				/>
			</ListItem>

			<ListItem button className="popover-list-item">
				<ListItemIcon className="popover-list-icon">
					<PiggyBankIcon style={{ color: styleConstants.greyDark }} />
				</ListItemIcon>
				<ListItemText
					primary={`${(Number(employee?.gainedMoney || 0)).toFixed(2) || 0.00} ${projectCurrency} ${isClientMode ? intl.formatMessage({ id: "spent" }) : intl.formatMessage({ id: "gained" })}`}
					className="list-item-text"
				/>
			</ListItem>

			{isClientMode && (
				<ListItem button className="popover-list-item submit-feedback-container">
					<ListItemIcon className="popover-list-icon">
						<AnnouncementIcon style={{ color: styleConstants.greyDark }} />
					</ListItemIcon>
					<ListItemText
						onClick={handleOpenSubmitFeedbackPopup}
						primary={intl.formatMessage({ id: "submit-feedback" })}
						className="list-item-text"
					/>
				</ListItem>
			)}
		</List>
	);
};

export default EmployeeProfilePopUp;
