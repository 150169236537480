import React, { useState } from 'react';

import DashboardSectionWrapper from '../../SharedDashboardComponents/DashboardSectionWrapper';
import ClientDashboardBox from '../ClientDashboardBox';

const ClientTeamMembers = ({
    contractMembersCount,
    fullTimeMembersCount,
}) => {
    const [selectedTeamMemberType, setSelectedTeamMemberType] = useState('');

    const handleClickTeamMemberType = teamMemberType => {
        setSelectedTeamMemberType(oldState => oldState === teamMemberType ? '' : teamMemberType);
    };

    return (
        <DashboardSectionWrapper>
            <DashboardSectionWrapper.SectionTitle>Team</DashboardSectionWrapper.SectionTitle>

            <DashboardSectionWrapper.SectionContent classes={['list column']}>
                <ClientDashboardBox
                    boxText="Contract"
                    boxCount={contractMembersCount}
                    handleClick={handleClickTeamMemberType}
                    selectedBox={selectedTeamMemberType}
                />
                <ClientDashboardBox
                    boxText="Full-time"
                    boxCount={fullTimeMembersCount}
                    handleClick={handleClickTeamMemberType}
                    selectedBox={selectedTeamMemberType}
                />
            </DashboardSectionWrapper.SectionContent>
        </DashboardSectionWrapper>
    );
};

export default ClientTeamMembers;
