import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import SubmenuContentLayout from 'layouts/SubmenuContentLayout';

import AgencySettings from 'components/Settings/AgencySettings';
import EmployeeSettings from 'components/Settings/EmployeeSettings';
import UserManagement from 'components/Settings/UserManagement';
import Trainings from 'components/Settings/Trainings';
import Coaching from './Coaching';
import CoachingOpportunityDetails from './CoachingOpportunityDetails';

import withUserData from 'hoc/withUserData/withUserData';
import isAuthenticated from 'hoc/isAuthenticated';
import { getDisplayMode } from 'reducers'

import { ROLES } from 'constants/userConstants';
import { settingsItems } from './SettingsConstants';

const Settings = ({
    match,
    displayMode,
}) => {
    return (
        <SubmenuContentLayout
            path={match.url}
            items={Object.values(settingsItems[displayMode])}
            isWithProfileInfo={false}
            displayMode={displayMode}
        >
            <div className='settings-content'>
                <Switch>
                    <Route path={match.path} exact component={isAuthenticated(withUserData(AgencySettings), [ROLES.ADMIN])} />
                    <Route path={`${match.path}${settingsItems.admin.trainings.path}`} exact component={isAuthenticated(Trainings, [ROLES.ADMIN])} />
                    <Route path={`${match.path}${settingsItems.admin.userManagement.path}`} exact component={UserManagement} />
                    <Route path={`${match.path}${settingsItems.admin.employeeSettings.path}`} exact component={EmployeeSettings} />
                    <Route path={`${match.path}${settingsItems.admin.coaching.path}`} exact component={isAuthenticated(Coaching, [ROLES.ADMIN])} />
                    <Route path={`${match.path}${settingsItems.admin.coaching.path}/:coachingOpportunityId`} exact component={isAuthenticated(CoachingOpportunityDetails, [ROLES.ADMIN])} />
                </Switch>
            </div>
        </SubmenuContentLayout>
    )
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
