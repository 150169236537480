import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isNotificationOpen } from 'reducers';

import { checkServerStatus } from 'actions/commonActions';
import { refetchHomeInformationWithToken } from 'actions/tokenPropActions';
import { clearNotification } from 'actions/notificationActions';

import SorryPageImg from 'assets/sorry-page.png';

import './SorryPage.scss';

const SorryPage = ({
    isNotificationOpen,
    checkServerStatus,
    refetchHomeInformationWithToken,
    clearNotification
}) => {
    const history = useHistory();

    useEffect(() => {
        checkServerStatus()
            .then(() => refetchHomeInformationWithToken())
            .then(() => history.push('/'));
    }, []);

    useEffect(() => {
        if (isNotificationOpen) {
            clearNotification();
        }
    }, [isNotificationOpen]);

    return (
        <div className="sorry-page-wrapper">
            <div className="sorry-page-content">
                <div className="sorry-page-title-img-wrapper">
                    <div className="sorry-page-title-wrapper">
                        <h2>HOLY MOLEY! SOMEONE MAY HAVE CHEWED THE POWER CABLE.</h2>
                    </div>
                    <div className="sorry-page-img-wrapper">
                        <img src={SorryPageImg} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isNotificationOpen: isNotificationOpen(state),
});

const mapDispatchToProps = {
    checkServerStatus,
    refetchHomeInformationWithToken,
    clearNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(SorryPage);
