import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import {
    CANDIDATE_APPLICATIONS_FETCH_SUCCESS,
    CANDIDATE_APPLICATION_FETCH_SUCCESS,
    APPLICATION_UPDATE_STATUS_SUCCESS,
    INTERVIEW_INVITATION_ADD_SUCCESS,
    INTERVIEW_INVITATION_FETCH_SUCCESS,
    INTERVIEW_INVITATIONS_FETCH_SUCCESS,
    INTERVIEW_DATE_UPDATE_SUCCESS,
    INTERVIEW_DATA_UPDATE_SUCCESS,
    INTERVIEW_STATUS_UPDATE_SUCCESS,
    APPLICATION_UPDATE_ASSIGNEE_SUCCESS,
    APPLICATION_UPDATE_STATUS_REQUEST_DATE,
    EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    APPLIED_CONSULTANTS_DATA_FETCH_SUCCESS,
    JOB_APPLICATION_DELETE_SUCCESS,
    APPLIED_CONSULTANTS_DATA_UPDATE_SUCCESS,
    APPLIED_CONSULTANT_APPLICATION_UPDATE_SUCCESS,
    APPLIED_CONSULTANTS_APPLICATION_UPDATE_SUCCESS,
} from 'actions/actionTypes';

import { escapeRegExp } from 'utils/regexUtils';
import { areAllDatesInThePast } from 'utils/dateUtils';

import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { INTERVIEW_INVITATION_STATUSES, INTERVIEW_INVITATION_CONTRIBUTORS } from 'constants/interviewInvitationConstants';
import { CANDIDATE_STATUS_MAPPER, APPLIED_CANDIDATE_STATUSES, SORT_SCORE } from 'components/JobPage/AppliedCandidates/AppliedCandidatesConstants';

const applicationInitialState = {
    _id: '',
    position: '',
    client: {},
    applicationDate: null,
    statusLastEdit: null,
    status: '',
    feedback: '',
};

const application = (state = applicationInitialState, action) => {
    switch (action.type) {
        case CANDIDATE_APPLICATIONS_FETCH_SUCCESS:
            return { ...applicationInitialState, ...state };
        default:
            return state;
    }
};

const applicationDetailsInitialState = {
    position: '',
    company: '',
    _id: '',
    assigneeName: null,
    assigneeId: '',
    firstName: '',
    lastName: '',
    phone: '',
    linkedIn: '',
    gitHub: '',
    candidateId: '',
    status: '',
    date: '',
    statusLastEdit: '',
    history: [],
};

const applicationDetails = (state = applicationDetailsInitialState, action) => {
    switch (action.type) {
        case CANDIDATE_APPLICATION_FETCH_SUCCESS:
            return { ...applicationDetailsInitialState, ...action.payload };
        case APPLICATION_UPDATE_ASSIGNEE_SUCCESS:
        case APPLICATION_UPDATE_STATUS_SUCCESS:
        case APPLICATION_UPDATE_STATUS_REQUEST_DATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const applicationsListInitialState = [];

const applicationsList = (state = applicationsListInitialState, action) => {
    switch (action.type) {
        case CANDIDATE_APPLICATIONS_FETCH_SUCCESS:
            return [...applicationsListInitialState, ...action.payload.map(x => application(x, action))];
        default:
            return state;
    }
};

const interviewInvitationDetailsInitialState = {
    _id: '',
    location: '',
    timezone: '',
    interviewTimeSlots: [],
    interviewer: '',
    participant: '',
    application: '',
    agencyId: '',
    dateAdded: null,
    status: null,
    proposedBy: '',
    interviewDate: { date: '', time: '' },
};

const interviewInvitationDetails = (state = interviewInvitationDetailsInitialState, action) => {
    switch (action.type) {
        case INTERVIEW_INVITATION_FETCH_SUCCESS:
        case INTERVIEW_INVITATION_ADD_SUCCESS:
        case INTERVIEW_DATE_UPDATE_SUCCESS:
        case INTERVIEW_DATA_UPDATE_SUCCESS:
            return { ...interviewInvitationDetailsInitialState, ...action.payload };
        default:
            return state;
    }
};

const interviewInvitationListInitialState = [];

const interviewInvitationList = (state = interviewInvitationListInitialState, action) => {
    switch (action.type) {
        case INTERVIEW_DATE_UPDATE_SUCCESS:
            return state.map(x => x._id === action.payload._id ? action.payload : x)
        case INTERVIEW_DATA_UPDATE_SUCCESS:
            return state.map(x => x._id === action.payload._id ? action.payload : x)
        case INTERVIEW_INVITATIONS_FETCH_SUCCESS:
            return [...interviewInvitationListInitialState, ...action.payload]
        case JOB_APPLICATION_DELETE_SUCCESS:
            return state.filter(x => x.application !== action.payload.applicationId);
        case INTERVIEW_STATUS_UPDATE_SUCCESS:
            return state.filter(interview => interview._id !== action.payload)
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return interviewInvitationListInitialState;
        default:
            return state
    }
};

const appliedConsultantsListInitialState = [];

const appliedConsultantsList = (state = appliedConsultantsListInitialState, action) => {
    switch (action.type) {
        case APPLIED_CONSULTANTS_DATA_FETCH_SUCCESS:
            return [...appliedConsultantsListInitialState, ...action.payload]
        case APPLIED_CONSULTANT_APPLICATION_UPDATE_SUCCESS:
            return state.map(x => x._id !== action.payload._id ? x : ({ ...x, ...action.payload }));
        case APPLIED_CONSULTANTS_APPLICATION_UPDATE_SUCCESS:
            return state.map(x => {
                const newConsultantData = action.payload?.find(y => y._id === x._id);
                return newConsultantData ? ({ ...x, ...newConsultantData }) : x;
            });
        case APPLIED_CONSULTANTS_DATA_UPDATE_SUCCESS:
            return [...action.payload, ...state];
        case JOB_APPLICATION_DELETE_SUCCESS:
            return state.filter(x => x.jobApplicationId !== action.payload.applicationId);
        default:
            return state
    }
};

const applications = combineReducers({
    applicationsList,
    applicationDetails,
    interviewInvitationDetails,
    interviewInvitationList,
    appliedConsultantsList,
});

export default applications;

export const getCandidateApplicationsWithCount = (state, page, count, comparer) => {
    const from = page * count;
    const to = page * count + count;

    const applications = state.applicationsList.slice().sort(comparer);

    return {
        result: applications.slice(from, to || applications.length),
        count: applications.length,
    }
};

export const getCandidateApplicationsCountByStatus = (state) => {
    return state.applicationsList.reduce((acc, applications) => {
        switch (CANDIDATE_STATUS_MAPPER[applications.status]) {
            case APPLIED_CANDIDATE_STATUSES.HIRED:
                acc.hired++;
                break;
            case APPLIED_CANDIDATE_STATUSES.INTERVIEW_STAGE:
                acc.interviewStage++;
                break;
            case APPLIED_CANDIDATE_STATUSES.PENDING_REVIEW:
                acc.pendingReview++;
                break;
            case APPLIED_CANDIDATE_STATUSES.REJECTED:
                acc.rejected++;
                break;
            case APPLIED_CANDIDATE_STATUSES.NOT_VETTED:
                acc.notVetted++;
                break;
            default:
                break;
        }

        acc.all++;

        return acc;
    }, { all: 0, notVetted: 0, pendingReview: 0, interviewStage: 0, hired: 0, rejected: 0 })
};

export const getCandidateApplications = (state, filters) => {
    let isSearchTermMatch = false;

    const applications = state.applicationsList.filter(application => {
        let isApplicationPositionMatch = true;
        if (filters?.searchTerm) {
            const regexTest = new RegExp(escapeRegExp(filters.searchTerm), 'gi');
            isApplicationPositionMatch = regexTest.test(application.position);

            if (isApplicationPositionMatch) {
                isSearchTermMatch = true;
            }
        }

        let isApplicationStatusMatch = true;
        if (filters?.status) {
            isApplicationStatusMatch = CANDIDATE_STATUS_MAPPER[application.status] === filters.status || filters.status === APPLIED_CANDIDATE_STATUSES.ALL;
        }

        return isApplicationPositionMatch && isApplicationStatusMatch;
    });

    if (filters?.sortBy === SORT_SCORE) {
        applications.sort((a, b) => b.matchingScore - a.matchingScore);
    } else if (filters?.sortBy) {
        applications.sort((a, b) => new Date(b.applicationDate) - new Date(a.applicationDate))
    }

    return {
        isSearchTermMatch,
        list: applications
    };
};

export const getCandidateApplicationDetails = state => state.applicationDetails;
export const getInterviewInvitationDetails = state => state.interviewInvitationDetails;

const interviewInvitationListSelector = (state) => state.interviewInvitationList;
export const getInterviewInvitationsList = createSelector(
    [interviewInvitationListSelector],
    (interviewInvitationList) => interviewInvitationList.filter(interview => interview.status !== INTERVIEW_INVITATION_STATUSES.APPROVED)
);

export const getParticipantInterviewInvitations = createSelector(
    [interviewInvitationListSelector],
    (interviewInvitationList) => interviewInvitationList.filter(interview => interview.proposedBy === INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER && interview.status !== INTERVIEW_INVITATION_STATUSES.APPROVED)
);

export const getUpcomingInterviewsList = createSelector(
    [interviewInvitationListSelector],
    (interviewInvitationList) => interviewInvitationList.filter(interview => {
        const isApproved = interview.status === INTERVIEW_INVITATION_STATUSES.APPROVED;
        const isUpcoming = new Date(interview.interviewDate?.date) >= new Date();
        return isApproved && isUpcoming;
    })
);

export const getParticipantPendingInterviewInvitations = createSelector(
    [interviewInvitationListSelector],
    (interviewInvitationList) => interviewInvitationList
        .filter(interview =>
            interview?.status === INTERVIEW_INVITATION_STATUSES.PENDING
            && (Array.isArray(interview?.interviewTimeSlots)
                ? !areAllDatesInThePast(interview.interviewTimeSlots?.map(x => x.date))
                : false)
            && interview?.proposedBy === INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER
        )
);

export const getAllInterviewInvitations = createSelector(
    [interviewInvitationListSelector],
    (interviewInvitationList) => interviewInvitationList
);

export const getNewApplicationsCount = state => state.applicationsList.filter(application => application.status === APPLICATION_STATUSES.NEW_APPLICATION).length;

export const getAppliedConsultantsList = (state, filters) => {
    let searchTermMatch = false;

    const result = state.appliedConsultantsList.filter(consultant => {
        let isConsultantNameMatch = true;
        if (filters?.searchTerm) {
            const regexTest = new RegExp(escapeRegExp(filters.searchTerm), 'gi');
            isConsultantNameMatch = regexTest.test(consultant.name);

            if (isConsultantNameMatch) {
                searchTermMatch = true;
            }
        }

        let isConsultantStatusMatch = true;
        if (filters?.status) {
            isConsultantStatusMatch = CANDIDATE_STATUS_MAPPER[consultant.status] === filters.status || filters.status === APPLIED_CANDIDATE_STATUSES.ALL;
        }

        return isConsultantNameMatch && isConsultantStatusMatch;
    });

    if (filters.sortBy === SORT_SCORE) {
        result.sort((a, b) => b.matchingScore - a.matchingScore);
    } else {
        result.sort((a, b) => new Date(b.appliedDate) - new Date(a.appliedDate))
    }

    return {
        searchTermMatch,
        appliedConsultants: result,
    }
}

export const getAppliedCandidatesCountByStatus = (state) => {
    return [...state.appliedConsultantsList].reduce((acc, consultant) => {
        switch (CANDIDATE_STATUS_MAPPER[consultant.status]) {
            case APPLIED_CANDIDATE_STATUSES.HIRED:
                acc.hired++;
                break;
            case APPLIED_CANDIDATE_STATUSES.INTERVIEW_STAGE:
                acc.interviewStage++;
                break;
            case APPLIED_CANDIDATE_STATUSES.PENDING_REVIEW:
                acc.pendingReview++;
                break;
            case APPLIED_CANDIDATE_STATUSES.REJECTED:
                acc.rejected++;
                break;
            case APPLIED_CANDIDATE_STATUSES.NOT_VETTED:
                acc.notVetted++;
                break;
            default:
                break;
        }

        acc.all++;

        return acc;
    }, { all: 0, notVetted: 0, pendingReview: 0, interviewStage: 0, hired: 0, rejected: 0 })
};
