import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import Paper from '@material-ui/core/Paper';

import DisplayImage from 'components/Person/PersonalInfo/DisplayImage';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import AgencySettingsBasicFormView from './AgencySettingsBasicFormView';

import { validateRequiredFields, validateCompanyName } from 'utils/formValidations';

import defaultLogo from 'assets/default-logo.svg';

import './AgencySettingsBasicForm.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const AgencySettingsBasicForm = ({
    agencyInfo,
    agencyId,
    saveSettings,
    updateAgencyBasicInfo,
    uploadAgencyLogo,
    deleteAgencyLogo,
    countries,
}) => {
    const intl = useIntl();

    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const basicSettingsFormRef = useRef();

    const discardHandlerBasicInfo = () => {
        setShowHeaderButtons(false);
        basicSettingsFormRef.current.resetForm();
    }

    return (
        <div className="agency-settings-wrapper agency-basic-settings">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "basic" })}
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "discard" })}
                            onClick={discardHandlerBasicInfo}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "save" })}
                            onClick={() => basicSettingsFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>

            <Paper className="agency-settings-content">
                <div className="agency-settings-content-wrapper">
                    <Formik
                        initialValues={{
                            name: agencyInfo?.name || '',
                            country: agencyInfo?.country || '',
                        }}
                        innerRef={basicSettingsFormRef}
                        validate={values => {
                            return { 
                                ...validateRequiredFields(values, ['name']),
                                ...validateCompanyName(values, 'name'),
                             };
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false);
                            saveSettings(values, updateAgencyBasicInfo, resetForm);
                            setShowHeaderButtons(false);
                        }}
                        enableReinitialize={true}
                    >
                        {(props) =>
                            <AgencySettingsBasicFormView {...props}
                                agencyInfo={agencyInfo}
                                countries={countries}
                                handleShowHeaderButtons={() => setShowHeaderButtons(true)}
                            />
                        }
                    </Formik>

                    <div className="basic-settings-logo">
                        <DisplayImage
                            defaultLogo={!agencyInfo.logoImgUrl}
                            imageUrl={agencyInfo.logoImgUrl || defaultLogo}
                            imageDetails={agencyInfo.logoImgDetails}
                            handleUpdateAvatar={uploadAgencyLogo}
                            handleDeleteAvatar={deleteAgencyLogo}
                            entityId={agencyId}
                            editButtonText={intl.formatMessage({ id: "edit-logo" })}
                            uploadButtonText={intl.formatMessage({ id: "upload-logo" })}
                        />
                    </div>
                </div>
            </Paper>
        </div>
    )
};

export default AgencySettingsBasicForm;
