import React from 'react';

import { formatStringAsClassName } from 'utils/helpers';

import './ClientDashboardBox.scss';

const ClientDashboardBox = ({
    boxText,
    boxCount,
    handleClick,
    selectedBox,
}) => {

    return (
        <div
            className={`client-dashboard-box-wrapper ${selectedBox === boxText ? 'active-box' : ''} ${handleClick && 'clickable'}`}
            onClick={handleClick ? () => handleClick(boxText) : null}
        >
            <div className={`box-count-wrapper ${formatStringAsClassName(boxText)}`}>
                <span>{boxCount}</span>
            </div>

            <div className="box-text-wrapper">
                <span>{boxText}</span>
            </div>
        </div>
    );
};

export default ClientDashboardBox;
