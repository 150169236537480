import api from './api';
import requester from './requester';

const projectTemplateService = {
    getProjectTemplate: (templateId, agencyId) => requester(api.templateModify(templateId, agencyId)).getOne(),
    fetchProjectTemplates: (agencyId) => requester(api.template(agencyId)).getMany(),
    createProjectTemplate: (template, agencyId) => requester(api.template(agencyId)).create(template),
    updateProjectTemplate: (template, agencyId) => requester(api.templateModify(template._id, agencyId)).update(template),
};

export default projectTemplateService;
