import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { Formik, ErrorMessage } from 'formik';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import { updateCandidateAssignee } from 'actions/candidateActions';
import { validateRequiredFields } from 'utils/formValidations';
import { getAdmins, getCandidateAssignee } from 'reducers';

const ProfileAssignee = ({
    agencyId,
    admins,
    assignee,
    updateCandidateAssignee,
}) => {

    const { id: userId } = useParams();

    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const assigneeInfoRef = useRef();

    const discardHandlerAssigneeInfo = () => {
        setShowHeaderButtons(false);
        assigneeInfoRef.current.values.assignee = assignee?._id || '';
    }

    const handleUpdateAssignee = async (values, resetForm) => {
        await updateCandidateAssignee(agencyId, userId, values);
        resetForm();
    };

    return (
        <div className="profile-info-wrapper profile-education-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Change assignee'
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={discardHandlerAssigneeInfo}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => assigneeInfoRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>

            <Paper className="profile-info-content profile-education-rows-wrapper">
                <div className="hour-rate-form-field">
                    <Formik
                        initialValues={{
                            assignee: assignee?._id || '',
                        }}
                        validate={values => {
                            return {
                                ...validateRequiredFields(values, ['assignee']),
                            };
                        }}
                        innerRef={assigneeInfoRef}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            handleUpdateAssignee(values, resetForm);
                            setShowHeaderButtons(false);
                        }}
                        enableReinitialize={true}
                    >
                        {(props) =>
                            <div>
                                <TextField
                                    variant="outlined"
                                    name="assignee"
                                    placeholder="Assignee"
                                    label="Assignee"
                                    value={props.values.assignee}
                                    onChange={(e) => {
                                        props.handleChange(e);
                                        setShowHeaderButtons(true);
                                    }}
                                    fullWidth
                                    select
                                >
                                    {
                                        admins.map((admin, i) => (
                                            <MenuItem
                                                native="true"
                                                key={i}
                                                value={admin._id}
                                            >
                                                {admin.name}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                                <ErrorMessage name="assignee" component="div" className="invalid-field-message" />
                            </div>
                        }
                    </Formik>
                </div>
            </Paper>
        </div>
    )
};

const mapStateToProps = (state) => ({
    admins: getAdmins(state),
    assignee: getCandidateAssignee(state),
});

const mapDispatchToProps = { updateCandidateAssignee };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAssignee);
