import React from 'react';
import { Link } from 'react-router-dom';

import { getStateAgencyName } from 'utils/helpers';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import { Typography } from '@material-ui/core';

import './ThankYouBanner.scss';

const ThankYouBanner = ({

}) => {
    return (
        <div className="thank-you-banner-wrapper">
            <div className="thank-you-banner-container">
                <div className="thank-you-header-message-container">
                    <Typography className="thank-you-header-message">
                        Thank you for completing your profile
                    </Typography>
                </div>
                <div className="thank-you-message-container">
                    <Typography className="thank-you-message">
                        We sent you an email with the next steps for your job application.
                    </Typography>
                </div>
                <PrimaryButton
                    component={Link}
                    text="Jobs"
                    to={`/${getStateAgencyName()}/job-opportunities`}
                    className="jobs-button"
                />
            </div>
        </div>
    )
};

export default ThankYouBanner;
