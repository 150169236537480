import React from 'react';
import { useIntl } from 'react-intl';

import Paper from '@material-ui/core/Paper';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import AccessTimeSharpIcon from '@material-ui/icons/AccessTimeSharp';

import { ErrorMessage, FieldArray } from 'formik';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PrimaryAddButton from 'components/Shared/Buttons/PrimaryAddButton';

import { ReactComponent as Upload } from 'assets/upload.svg';

import { BENCH_ACTIVITIES, SICK_LEAVE, SICK_LEAVE_ID } from '../../TimeTrackingConstants';

const EditTimeDialogFormView = ({
    projects,
    currentFileName,
    handleOpenUploadFileDialog,
    isAdmin,
    hasTrackedSickLeaveForCurrentDay,
    values,
    handleChange,
    handleEventChange,
    handleRemoveEvent,
    handleSelectProject,
    handleAddEvent,
}) => {
    const intl = useIntl();

    return (
        <>
            <div className="dates-container">
                <FieldArray name="events"
                    render={({ remove }) =>
                        <>
                            {values.events?.map((event, index) =>
                                <Paper
                                    key={index}
                                    className={event.selected ? "time-entry selected-project" : "time-entry"}
                                    onClick={() => { handleSelectProject(event) }}
                                >
                                    <div className="additional-information-block">
                                        <div className="project-name">{event.project.name === SICK_LEAVE.name ? intl.formatMessage({ id: "sick-leave" }) : event.project.name === BENCH_ACTIVITIES.name ?  intl.formatMessage({ id: "bench-activities" }) : event.project.name}</div>
                                        <div className="time-block">
                                            <AccessTimeSharpIcon className="time-icon" />
                                            <div className="hours-entry">{event.hours} {intl.formatMessage({ id: "hour-short" })}</div>
                                        </div>
                                    </div>
                                    <CancelSharpIcon className="project-remove-icon" onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveEvent(event, remove, index);
                                    }} />
                                </Paper>
                            )}
                            <div className="project-actions">
                                <PrimaryAddButton text={intl.formatMessage({ id: "add-project" })} handleClick={(e) => { handleAddEvent(values.events) }} />
                            </div>
                        </>
                    }
                />
            </div>
            <div className="input-container">
                <FormControl className="project" variant="outlined" fullWidth>
                    <InputLabel id="project-label">{intl.formatMessage({ id: "project" })}</InputLabel>
                    <Select
                        labelId="project-label"
                        id="project"
                        name="project"
                        value={values.project}
                        classes={{ root: values.project.class }}
                        onChange={(e) => handleEventChange(e)}
                        label={intl.formatMessage({ id: "project" })}
                        disabled={values.project._id === SICK_LEAVE_ID && hasTrackedSickLeaveForCurrentDay && !isAdmin}
                    >
                        {projects.map((project, i) => (
                            <MenuItem
                                key={i}
                                value={project}
                                classes={{ root: project.class }}
                            >
                                {project.name === SICK_LEAVE.name ? intl.formatMessage({ id: "sick-leave" }) : project.name === BENCH_ACTIVITIES.name ? intl.formatMessage({ id: "bench-activities" }) : project.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <ErrorMessage name="project" component="div" className="invalid-field-message" />
                </FormControl>
                {values.project?.isSickLeave &&
                    <div className="track-time-upload-file-container">
                        <TextField
                            className="track-time-upload-text-field"
                            label={intl.formatMessage({ id: "module" })}
                            type="text"
                            variant="outlined"
                            value={currentFileName}
                            disabled
                        />
                        <div className="track-time-upload-image-wrapper">
                            <Upload className="track-time-upload-image" onClick={handleOpenUploadFileDialog} />
                        </div>
                    </div>
                }
                {!values.project?.isSickLeave &&
                    <>
                        <TextField
                            className="hours"
                            type="number"
                            label={intl.formatMessage({ id: "hours-per-day" })}
                            variant="outlined"
                            name="hours"
                            onChange={(e) => { handleChange(e); handleEventChange(e); }}
                            value={values.hours}
                        />
                        <ErrorMessage name="hours" component="div" className="invalid-field-message" />

                        <TextField
                            className={'tasks-desc'}
                            label={intl.formatMessage({ id: "tasks-description" })}
                            multiline
                            minRows={5}
                            variant="outlined"
                            name="tasksDesc"
                            onChange={(e) => { handleChange(e); handleEventChange(e); }}
                            inputProps={{
                                maxLength: 190
                            }}
                            value={values.tasksDesc}
                        />
                        <ErrorMessage name="tasksDesc" component="div" className="invalid-field-message" />
                    </>
                }
            </div >
        </>
    )
};

export default EditTimeDialogFormView;
