import React from 'react';

import ProfileInfoRow from 'components/Person/Profile/ProfileInfoRow';
import { ROLES } from 'constants/userConstants';

import './BenefitInfo.scss';

const BenefitInfo = ({
    item,
    editItem,
    removeItem,
    displayMode
}) => {
    return (
        <div className={["benefit-info-wrapper", displayMode !== ROLES.ADMIN ? 'view-mode' : ''].join(' ')}>
            <div className="benefit-info-container">
                <ProfileInfoRow
                    item={item}
                    removeItem={removeItem}
                    editItem={editItem}
                    isViewMode={displayMode !== ROLES.ADMIN}
                    isPerksAndEquipmentEmployeeMode={displayMode === ROLES.EMPLOYEE}
                />
                {displayMode !== ROLES.ADMIN &&
                    <div className="benefit-info-description-wrapper">
                        <div>{item.details}</div>
                    </div>
                }
            </div>
        </div>
    );
};

export default BenefitInfo;
