import React from 'react';
import moment from 'moment';

import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import DialogActions from '@material-ui/core/DialogActions';

import { ErrorMessage } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { MONTH_YEAR_DATE_FORMAT } from 'constants/commonConstants';

import './ExportTimesheetFormView.scss';

const ExportTimesheetFormView = ({
    values,
    handleCloseDialog,
    handleSubmit,
    setFieldValue,
}) => (
    <form className="export-timesheet-form" autoComplete="off">
        <div className="date-fields-wrapper">
            <div className="start-date-container">
                <KeyboardDatePicker
                    className="start-date-field"
                    autoOk
                    required
                    fullWidth
                    views={['month', 'year']}
                    openTo='month'
                    name='startDate'
                    value={values.startDate}
                    onChange={value => {
                        setFieldValue('startDate', moment(value));
                    }}
                    label='From'
                    variant='inline'
                    inputVariant='outlined'
                    format={MONTH_YEAR_DATE_FORMAT}
                    allowKeyboardControl
                />
                <ErrorMessage
                    className="invalid-field-message"
                    name='startDate'
                    component='div'
                />
            </div>
            <div className="end-date-container">
                <KeyboardDatePicker
                    className="end-date-field"
                    autoOk
                    fullWidth
                    views={['month', 'year']}
                    openTo='month'
                    name='endDate'
                    value={values.endDate}
                    onChange={value => {
                        setFieldValue('endDate', moment(value));
                    }}
                    label='To'
                    variant='inline'
                    inputVariant='outlined'
                    format={MONTH_YEAR_DATE_FORMAT}
                    allowKeyboardControl
                />

                <ErrorMessage
                    className="invalid-field-message"
                    component='div'
                    name='endDate'
                />
            </div>
        </div>
        <div className="dialog-actions-container">
            <div className="helper-text-wrapper">
                <p>Export timesheets for custom period</p>
                <p>To download timesheets for a single month you should only enter start date</p>
            </div>
            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
                <SecondaryButton
                    buttonStyle="save"
                    handleClick={handleSubmit}
                    text="Export"
                    type="submit"
                />
            </DialogActions>
        </div>
    </form>
);

export default ExportTimesheetFormView;
