import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { getAgencyId, getActionButtonLoading } from 'reducers';

import { updateTermsAndConditionsVersion } from 'actions/agencyActions';

import ProfileCreationCard from 'components/Shared/ProfileCreationCard';
import FormControlCheckbox from 'components/Shared/FormControlCheckbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import { validateRequiredFields } from 'utils/formValidations';

import { motionRedirectLinks } from 'components/Register/RegisterConstants';

const ReviewTermsAndConditions = ({
    agencyId,
    isActionButtonLoading,
    updateTermsAndConditionsVersion,
}) => {
    return (
        <Formik
            initialValues={{
                isTermsAndConditionsAccepted: false,
            }}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['isTermsAndConditionsAccepted'])
                };
            }}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(false);
                await updateTermsAndConditionsVersion(agencyId);
            }}
        >
            {({ values, errors, touched, setFieldValue, handleSubmit }) => {
                const isTCChecked = values.isTermsAndConditionsAccepted;
                const hasTCError = errors.isTermsAndConditionsAccepted;
                const isTCFieldTouched = touched.isTermsAndConditionsAccepted;

                return (
                    <>
                        <ProfileCreationCard.ContentBody>
                            <form autoComplete="off">
                                <FormControlCheckbox
                                    className="centered"
                                    isTouched={isTCFieldTouched}
                                    hasError={hasTCError}
                                    name="isTermsAndConditionsAccepted"
                                    label={
                                        <span>
                                            I agree to the platform's <a href={motionRedirectLinks.privacy.link} target="_blank">{motionRedirectLinks.privacy.label}.</a>
                                        </span>
                                    }
                                    checked={isTCChecked}
                                    handleChange={() => setFieldValue('isTermsAndConditionsAccepted', !isTCChecked)}
                                />
                                <ProfileCreationCard.ContentAction
                                    text={isActionButtonLoading ? <CircularProgress className="white" /> : "Continue to Sign Up"}
                                    handleClick={handleSubmit}
                                />
                            </form>
                        </ProfileCreationCard.ContentBody>
                    </>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    isActionButtonLoading: getActionButtonLoading(state),
});

const mapDispatchToProps = {
    updateTermsAndConditionsVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewTermsAndConditions);
