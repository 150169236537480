import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';
import Paper from '@material-ui/core/Paper';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import { getEmployeeContractInformation, getAgencyBasicInfo } from 'reducers';
import { editContractInformation } from 'actions/employeeActions';
import { fetchAgencyEvrotrustInfo } from 'actions/agencyActions';

import { validateRequiredFields, validateEmailAddress } from 'utils/formValidations';

import EmployeeContractInformationFormView from './EmployeeContractInformationFormView';

import './ContractInformation.scss';

const EmployeeContractInformation = ({
    agencyId,
    employeeId,
    departmentId,
    contractInformation,
    editContractInformation,
    fetchAgencyEvrotrustInfo,
    agencyInfo,
    isSupplierAdminMode,
}) => {
    const intl = useIntl();

    useEffect(() => {
        fetchAgencyEvrotrustInfo(agencyId);
    }, []);

    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const contractInformationFormRef = useRef();

    const handleSubmit = async (contractInformation, resetForm) => {
        await editContractInformation(agencyId, departmentId, employeeId, contractInformation);
        
        resetForm();
        setShowHeaderButtons(false);
    }

    const handleDiscard = () => {
        contractInformationFormRef.current.handleReset();
        setShowHeaderButtons(false);
    }

    return (
        <div className="employment-info-wrapper employee-contract-information-wrapper personal-info-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "e-signature-details" })}
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "discard" })}
                            onClick={handleDiscard}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text={intl.formatMessage({ id: "save" })}
                            onClick={() => contractInformationFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>

            
                <Paper className="inner-content-wrapper">
                    <Formik
                        initialValues={{
                            legalName: contractInformation?.legalName || '',
                            position: contractInformation?.position || '',
                            evrotrustEmail: contractInformation?.evrotrustEmail || '',
                        }}
                        validate={values => {
                            return {
                                ...validateRequiredFields(values, ['legalName', 'position']),
                                ...validateEmailAddress(values, ['evrotrustEmail']),
                            }
                        }}
                        innerRef={contractInformationFormRef}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false);

                            values.evrotrustEmail.toLowerCase().trim();

                            handleSubmit(values, resetForm)
                        }}
                        enableReinitialize={true}
                    >
                        {(props) =>
                            <EmployeeContractInformationFormView
                                {...props}
                                onInputChanged={() => setShowHeaderButtons(true)}
                                isUsingEvrotrust={agencyInfo.isUsingEvrotrust}
                                contractInformation={contractInformation}
                                isSupplierAdminMode={isSupplierAdminMode}
                            />
                        }
                    </Formik>
                </Paper>
        </div >
    )
}

const mapStateToProps = state => ({
    contractInformation: getEmployeeContractInformation(state),
    agencyInfo: getAgencyBasicInfo(state),
});

const mapDispatchToProps = {
    editContractInformation,
    fetchAgencyEvrotrustInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContractInformation);
