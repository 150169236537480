export const TRAININGS_TABLE_COLUMNS = [
    { name: 'Title', value: 'title', isSortable: true, langKey: "title" },
];

export const DEFAULT_TRAININGS_TABLE_COLUMNS = ['title'];

export const TRAININGS_SORT_QUERY_SELECTOR = 'trainingsSort';
export const TRAININGS_PAGE_QUERY_SELECTOR = 'trainingsTablePage';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const INITIAL_QUERY = {
    page: 1,
    sortBy: null,
    sortByDir: null,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    pagesToLoad: 5,
};

export const QUERY_PARAMS_MAP = {
    page: TRAININGS_PAGE_QUERY_SELECTOR,
    sortBy: TRAININGS_SORT_QUERY_SELECTOR,
    sortByDir: `${TRAININGS_SORT_QUERY_SELECTOR}Dir`,
};
