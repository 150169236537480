import React, { useState } from 'react';

import FormValidationRequirement from '../FormValidationRequirement';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ButtonBase from '@material-ui/core/ButtonBase';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import './FormSelect.scss';

const FormSelect = ({
    placeholder,
    options,
    handleChange,
    isRequirementMet,
    isRequirementNotMet,
    requirementText,
    showValidationRequirement,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});

    const handleClickButton = () => setIsMenuOpen(prevState => !prevState);

    const handleCloseMenu = () => setIsMenuOpen(false);

    return (
        <>
            <ClickAwayListener onClickAway={handleCloseMenu}>
                <div className={`form-options-wrapper${isMenuOpen ? ' open' : ''}${isRequirementNotMet ? ' error' : ''}`}>
                    <ButtonBase
                        disableRipple
                        className="form-options-button"
                        onClick={handleClickButton}
                    >
                        <ListItemText
                            className={`form-options-button-text${!selectedOption.name ? ' placeholder-text': ''}`}
                            primary={selectedOption.name || placeholder}
                        />
                        {isMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    </ButtonBase>
                    <Collapse
                        unmountOnExit
                        timeout={0}
                        in={isMenuOpen}
                        className="form-options-menu"
                    >
                        <List
                            className="form-options-menu-list"
                            component="div"
                        >
                            {options.map((option, index) => {
                                return (
                                    <ButtonBase
                                        disableRipple
                                        className={`form-options-menu-item${selectedOption.value === option.value ? ' selected' : ''}`}
                                        key={`form-options-menu-item-${placeholder}-${index}`}
                                        onClick={() => {
                                            setSelectedOption(option);
                                            handleChange(option.value);
                                            handleCloseMenu();
                                        }}
                                    >
                                        <ListItemText primary={option.name} />
                                    </ButtonBase>
                                );
                            })}
                        </List>
                    </Collapse>
                </div>
            </ClickAwayListener>
            {showValidationRequirement &&
                <FormValidationRequirement
                    isRequirementMet={isRequirementMet}
                    isRequirementNotMet={isRequirementNotMet}
                    requirementText={requirementText}
                />
            }
        </>
    );
};

export default FormSelect;
