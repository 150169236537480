import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import './Switcher.scss';

const Switcher = ({
    isChecked,
    changeStatusHandler,
    label,
    initialValue=false,
    labelPlacement,
    disabled
}) => (
        label
            ?
            <FormControlLabel className="custom-switch-control"
                control={
                    <Switch className="custom-switch" disabled={disabled} checked={isChecked ? isChecked : initialValue} onChange={changeStatusHandler} />
                }
                label={label}
                labelPlacement={labelPlacement || "start"}
            />
            :
            <Switch className="custom-switch" disabled={disabled} checked={isChecked} onChange={changeStatusHandler} />
    );

export default Switcher;
