const extractPassedYearsFromDate = (date) => {
    if (!date) {
        return undefined;
    }

    const year = new Date(date).getFullYear();
    const currentYear = new Date(Date.now()).getFullYear();
    return currentYear - year;
}

const matchComparisonType = {
    direct: 'direct',
    gte: 'greaterThanOrEqual',
    lte: 'lowerThanOrEqual'
}

const similarTalentsMatchTemplates = {
    specialty: {
        points: 0.5,
        comparisonType: matchComparisonType.direct,
        selector: employee => employee.employmentInformation?.generalInfo?.profileType
    },
    availability: {
        points: 0.25,
        comparisonType: matchComparisonType.direct,
        selector: employee => employee.employmentInformation?.generalInfo?.isAvailable
    },
    experience: {
        points: 0.25,
        isArray: false,
        matchMultiple: false,
        comparisonType: matchComparisonType.gte,
        filterSelector: filter => filter?.minValue,
        selector: employee => extractPassedYearsFromDate(
            employee.employmentInformation?.generalInfo?.experienceSince
        )
    },
    languages: {
        points: 0.05,
        isArray: true,
        matchMultiple: true,
        comparisonType: matchComparisonType.direct,
        selector: employee => employee.languages?.map(language => language.name)
    },
    skills: {
        points: 0.1,
        isArray: true,
        matchMultiple: true,
        comparisonType: matchComparisonType.direct,
        selector: employee => employee.profileSkills?.map(profileSkill => {
            return profileSkill.skills?.map(skill => {
                return skill.name
            })
        }).flat(2)
    }
}

export const getSimilarTalentMatchSpecs = (filters) => {
    const similarTalentsKeys = Object.keys(similarTalentsMatchTemplates);
    const filtersNames = Object.keys(filters);
    const similarTalentsSpecs = filtersNames.map(filter => {
        if (similarTalentsKeys.includes(filter)) {
            const matchSpec = {
                name: filter,
                ...similarTalentsMatchTemplates[filter]
            };
            return matchSpec;
        }
        return filter;
    });

    return similarTalentsSpecs;
}

const formatMatchField = (field) => field?.toString().toLowerCase();

// one to one comparison
export const similarTalentSingleMatch = (match, employee, filters, results) => {
    const field = match.selector(employee);
    switch (match.comparisonType) {
        case matchComparisonType.direct: {
            if (formatMatchField(field) === formatMatchField(filters[match.name])) {
                const item = results.find(x => x._id === employee._id)
                if (item) {
                    item.points += match.points;
                } else {
                    results.push({ ...employee, points: match.points });
                }
            }
            return;
        }
        case matchComparisonType.gte: {
            const comparisonField = match.filterSelector
                ? (match.filterSelector(filters[match.name]))
                : filters[match.name];

            if (+field >= +comparisonField) {
                const item = results.find(x => x._id === employee._id)
                if (item) {
                    item.points += match.points;
                } else {
                    results.push({ ...employee, points: match.points });
                }
            }
            return;
        }
        case matchComparisonType.lte: {
            const comparisonField = match.filterSelector
                ? (match.filterSelector(filters[match.name]))
                : filters[match.name];

            if (+field <= +comparisonField) {
                const item = results.find(x => x._id === employee._id)
                if (item) {
                    item.points += match.points;
                } else {
                    results.push({ ...employee, points: match.points });
                }
            }

        }
            break;
        default:
            break;
    }
}

// search one in array for single match - covers direct match only
export const similarTalentSingleFromArrayMatch = (match, employee, filters, results) => {
    const fields = match.selector(employee)?.map(x => formatMatchField(x));
    if (fields && fields.includes(formatMatchField(filters[match.field]))) {
        const item = results.find(x => x._id === employee._id);
        if (item) {
            item.points += match.points;
        } else {
            results.push({ ...employee, points: match.points });
        }
    }
}

// search many in array for multiple matches - covers direct match only
export const similarTalentMultipleFromArrayMatch = (match, employee, filters, results) => {
    const fields = match.selector(employee)?.map(x => formatMatchField(x));
    let totalMatchPoints = 0;
    filters[match.name].forEach(x => {
        if (fields && fields.includes(formatMatchField(x))) {
            totalMatchPoints += match.points;
        }
    });
    const item = results.find(x => x._id === employee._id);
    if (item) {
        item.points += totalMatchPoints;
    } else {
        results.push({ ...employee, points: totalMatchPoints });
    }
}

export const orderSimilarTalentsByMaxPoints = (similarTalents) => {
    return similarTalents.sort((x, y) => (x.points < y.points) ? 1 : -1);
}
