import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchAllCountries } from 'actions/staticDataActions';
import { toggleBusinessTripBanner } from 'actions/uiActions';
import { createBusinessTrip } from 'actions/businessTripActions';

import { getAgencyId, getCountries, getIsBusinessTripBannerOpen, getUserId } from 'reducers';

import BusinessTripDialog from './BusinessTripDialog';

import Banner from 'components/Shared/Banner';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton/';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton/';

import { ReactComponent as BusinessTripsBannerImage } from 'assets/banner-suitcase-rolling.svg';

import { BUSINESS_TRIP_POINTS_COMPONENT, BUSINESS_TRIP_TEXT, BUSINESS_TRIP_TITLE } from 'components/Dashboard/BusinessTripBanner/BusinessTripBannerConstants';

import './BusinessTripBanner.scss';

const BusinessTripBanner = ({
    agencyId,
    userId,
    countries,
    fetchAllCountries,
    createBusinessTrip,
    toggleBusinessTripBanner,
    isBannerOpen,
}) => {
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(isBannerOpen || moment().isSame(moment().date(15)));

    useEffect(() => {
        fetchAllCountries();
    }, []);

    const handleOpenAddDialog = () => setIsAddDialogOpen(true);
    const handleCloseAddDialog = () => setIsAddDialogOpen(false);

    const handleToggleBanner = () => {
        toggleBusinessTripBanner();
        setIsOpen(state => !state);
    };

    return (
        <div className="business-trip-banner-wrapper">
            {isOpen ?
                <Banner
                    title={BUSINESS_TRIP_TITLE}
                    content={BUSINESS_TRIP_POINTS_COMPONENT}
                    icon={<BusinessTripsBannerImage />}
                    primaryButtonComponent={<PrimaryButton
                        text="Submit Report"
                        handleClick={handleOpenAddDialog}
                        className="white-border"
                    />}
                    secondaryButtonComponent={<SecondaryButton
                        text="Got It"
                        handleClick={handleToggleBanner}
                    />}
                />
                :
                <Banner
                    title={BUSINESS_TRIP_TITLE}
                    content={BUSINESS_TRIP_TEXT}
                    icon={<BusinessTripsBannerImage />}
                    primaryButtonComponent={<SecondaryButton
                        text="Learn More"
                        handleClick={handleToggleBanner}
                    />}
                />
            }

            <BusinessTripDialog
                agencyId={agencyId}
                userId={userId}
                openDialog={isAddDialogOpen}
                handleCloseDialog={handleCloseAddDialog}
                countries={countries}
                handleCreate={createBusinessTrip}
            />
        </div>
    )
};

const mapStateToProps = (state) => ({
    countries: getCountries(state),
    isBannerOpen: getIsBusinessTripBannerOpen(state),
    userId: getUserId(state),
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    fetchAllCountries,
    toggleBusinessTripBanner,
    createBusinessTrip,
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTripBanner);
