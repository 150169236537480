import React from 'react';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import './TechStackDialog.scss';

const TechStackDialog = ({
    setFieldValue,
    handleSubmit,
    values,
    onClose,
    open,
    jobsTechnologies,
    handleAddTechnology,
    handleRemoveTechnology,
}) => {
    const onFormSubmit = () => {
        handleSubmit();
    };

    return (
        <CommonFormikDialog
            className="tech-stack-dialog"
            open={open}
            onClose={onClose}
            dialogTitle="Edit Tech Stack"
            saveBtnText="Save"
            discardBtnText="Discard"
            onSave={onFormSubmit}
        >
            <form className="tech-stack-dialog-form" autoComplete="off">
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2">
                            <PaperContent classes={["tech-stack-options"]} name="techStackField">
                                {values?.technologies.map((technology, index) => (
                                    <div
                                        key={index}
                                        className={"single-technology-wrapper"}
                                        onClick={() => handleRemoveTechnology(technology, values?.technologies, setFieldValue)}
                                    >
                                        <span data-content={technology.title} className="technology-name">{technology.title}</span>
                                    </div>
                                ))}
                            </PaperContent>
                        </div>
                        <div className="col-2of2">
                            <TextField
                                select
                                value={undefined}
                                variant="outlined"
                                onChange={(value) => handleAddTechnology(value.target, values?.technologies, setFieldValue)}
                                fullWidth
                            >
                                {jobsTechnologies.filter(technology => !values?.technologies.some(x => x._id === technology._id)).map((option) => {
                                    return (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                    </div>
                </div>
            </form>
        </CommonFormikDialog>
    )
}

export default TechStackDialog;
