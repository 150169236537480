import React from 'react';

import { Typography } from '@material-ui/core';

import './Section.scss';

const Section = ({
    title,
    subTitle,
    actionComponent,
    children
}) => {
    return (
        <div className="section-wrapper">
            <div className="section-heading">
                <Typography className="title">{title}</Typography>
                {actionComponent}
            </div>

            { subTitle && <Typography className="sub-title">{subTitle}</Typography> }

            {children}
        </div>
    )
};

export default Section;
