import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import Avatar from '@material-ui/core/Avatar';

import './DefaultYourAccountManagerInformation.scss';

const DefaultYourAccountManagerInformation = ({
    accountManager,
    contactNumber,
    contactEmail,
    profilePicture,
}) => (
    <div className="default-your-account-manager-info-container">
        <SectionActionsWrapper>
            <SectionActionsWrapper.SectionTitle
                sectionTitle='Your Account Manager'
            />
        </SectionActionsWrapper>
        <Paper className="your-account-manager-info-content">
            <div className="info-section account-manager">
                <div className="info-section-title">
                    <AccountCircleIcon fontSize="small" />
                    <Typography variant="h5">
                        Account manager
                    </Typography>
                </div>
                <div className="info-section-content">
                    {profilePicture &&
                        <Avatar
                            alt="Emplyoee Avatar"
                            className="manager-avatar-image"
                            src={profilePicture}
                        />
                    }
                    <div className={`manager-name ${!accountManager ? 'is-tbc' : ""}`}>
                        {accountManager ? accountManager : <span>TBC</span>}
                    </div>
                </div>
            </div>
            <div className="info-section contact-number">
                <div className="info-section-title">
                    <PhoneOutlinedIcon fontSize="small" />
                    <Typography variant="h5">
                        Contact number
                    </Typography>
                </div>
                <span>
                    {contactNumber ? contactNumber : 'TBC'}
                </span>
            </div>
            <div className="info-section contact-email">
                <div className="info-section-title">
                    <MailOutlineIcon fontSize="small" />
                    <Typography variant="h5">
                        Contact email
                    </Typography>
                </div>
                <span>
                    {contactEmail ? contactEmail : 'TBC'}
                </span>
            </div>
        </Paper>
    </div>
)

export default DefaultYourAccountManagerInformation;
