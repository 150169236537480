import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogDiscardButton from 'components/Shared/DialogButtons/DialogDiscardButton';
import DialogSaveButton from 'components/Shared/DialogButtons/DialogSaveButton';
import DialogCloseButton from 'components/Shared/DialogButtons/DialogCloseButton'

import { DialogActions } from '@material-ui/core';

import './CommonDialog.scss';

const CommonDialog = ({
    openDialog,
    dialogTitle,
    dialogSubtitle,
    handleCloseDialog,
    secondaryButtonText,
    primaryButtonText,
    handleProceedDialog,
    className,
    children,
}) => (
    <Dialog className={`common-dialog ${className ? className : ''}`} open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
        <MuiDialogTitle disableTypography className="dialog-title">
            <Typography variant="body2" className="dialog-title-text">
                {dialogTitle}
            </Typography>
            <DialogCloseButton className="dialog-close-btn" onClick={handleCloseDialog}></DialogCloseButton>
        </MuiDialogTitle>
        <DialogContent className="dialog-content">
            {
                dialogSubtitle &&
                <Typography variant="body1" className="dialog-text dialog-subtitle">{dialogSubtitle}</Typography>
            }
            {children}
        </DialogContent>
        <DialogActions className="dialog-actions-container">
            <DialogDiscardButton onClick={handleCloseDialog}>{secondaryButtonText}</DialogDiscardButton>
            <DialogSaveButton onClick={handleProceedDialog}>{primaryButtonText}</DialogSaveButton>
        </DialogActions>
    </Dialog>
);

export default CommonDialog;
