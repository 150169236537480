import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { ROLES } from 'constants/userConstants';

import {
    isClientProjectsModuleModified,
    getEmployeeBasicInfo,
    getSupplierBasicInformation,
    getAgencyRouteName,
    getEmploymentInformation,
    getGeneralInfo,
    getCurrentUser,
    getDisplayMode,
    getEmployeeProfileCVFetchState,
    getProfileDetails,
} from 'reducers';

import { fetchProjectTemplates } from 'actions/projectTemplateActions';
import { fetchPersonalInfo, fetchProfile, updateEmployeeOccupationStatus, editGeneralInfo, exportEmployeeInformation } from 'actions/employeeActions';
import { fetchEmployeePublicHolidays } from 'actions/publicHolidaysActions';
import { disableUsers, restoreUsers } from 'actions/userActions';

import SubmenuContentLayout from 'layouts/SubmenuContentLayout';

import { submenuItems } from './SupplierEmployeeConstants';

import SupplierEmployeeWrapper from './SupplierEmployeeWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import EditStatusDialog from 'components/Employees/EditStatusDialog';
import ConfirmRestoreUserDialog from 'components/Shared/ConfirmRestoreUserDialog/ConfirmRestoreUserDialog';
import SubmenuActionButton from 'components/Shared/SubmenuActionButton';
import PrimaryExportButton from 'components/Shared/Buttons/PrimaryExportButton';

import { HISTORICAL_NOTES_TYPES } from 'components/Shared/HistoricalNotes/HistoricalNotesConstants';
import { DATE_FORMAT_WITH_DASH, DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

const SupplierEmployee = ({
    agencyId,
    match,
    departmentId,
    agencyRouteName,
    fetchPersonalInfo,
    fetchProfile,
    fetchProjectTemplates,
    fetchEmployeePublicHolidays,
    employmentInformation,
    updateEmployeeOccupationStatus,
    disableUsers,
    basicInfo,
    supplierBasicInfo,
    generalInfo,
    editGeneralInfo,
    authUserId,
    displayMode,
    dataFetchState,
    profileDetails,
    restoreUsers,
    exportEmployeeInformation,
}) => {
    const intl = useIntl();

    const supplierId = match.params.id;
    const employeeId = match.params.employeeId;

    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
    const [employeeToRemove, setEmployeeToRemove] = useState('');
    const [isEditStatusDialogOpen, setIsEditStatusDialogOpen] = useState(false);
    const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

    const history = useHistory();

    const selectedEmployee = { _id: employeeId, ...employmentInformation };

    useEffect(() => {
        fetchPersonalInfo(agencyId, employeeId, false, departmentId).then((profileData) => {
            if (displayMode === ROLES.ADMIN && (profileData.role === ROLES.ADMIN || profileData.role === ROLES.EMPLOYEE)) {
                history.push(`/${agencyRouteName}/team/people/${employeeId}`);
            }
        });
        fetchProfile(employeeId, agencyId, departmentId);
        fetchProjectTemplates(agencyId);
        fetchEmployeePublicHolidays(agencyId, employeeId);
    }, [employeeId]);

    const handleCloseConfirmRemoveDialog = () => { setOpenConfirmRemoveDialog(false); setEmployeeToRemove(''); };

    const removeEmployeeClickHandler = () => { setOpenConfirmRemoveDialog(true); setEmployeeToRemove(employeeId); };

    const handleOpenRestoreDialog = () => setOpenRestoreDialog(true);
    const handleCloseRestoreDialog = () => setOpenRestoreDialog(false);

    const handleRemoveEmployee = () => {
        disableUsers([employeeToRemove], ROLES.EMPLOYEE)
            .then(() => {
                history.push(`/${agencyRouteName}/partners/${supplierId}/team/people`);
            });
        setOpenConfirmRemoveDialog(false);
    };

    const openEditStatusDialog = () => setIsEditStatusDialogOpen(true);
    const closeEditStatusDialog = () => setIsEditStatusDialogOpen(false);

    const handleEditStatus = (employeeId, data) => {
        updateEmployeeOccupationStatus(agencyId, employeeId, data);
    };

    const changeVettedStatusHandler = () => {
        const isVettedUpdated = !generalInfo.isVetted;

        const availabilityUpdate = {
            updaterRole: displayMode,
            isVetted: isVettedUpdated
        };

        editGeneralInfo(availabilityUpdate, employeeId, agencyId, departmentId, authUserId);
    };

    const changeAvailableStatusHandler = () => {
        const isAvailableUpdated = !generalInfo.isAvailable;

        const availableFromUpdate = isAvailableUpdated ? '' : generalInfo.availableFrom;

        const availabilityUpdate = {
            updaterRole: displayMode,
            isAvailable: isAvailableUpdated,
            availableFrom: availableFromUpdate
        };

        editGeneralInfo(availabilityUpdate, employeeId, agencyId, departmentId, authUserId);
    };

    const restoreUser = () => {
        restoreUsers([employeeId], basicInfo.role);
        handleCloseRestoreDialog();
    };

    const handleEmployeeExport = async () => {
        const fileName = `${moment().format(DATE_FORMAT_WITH_DASH)}-${basicInfo?.firstName || ''} ${basicInfo?.lastName || ''}`;
        await exportEmployeeInformation(agencyId, employeeId, fileName);
    };

    const deleteButton = <SubmenuActionButton handleClick={removeEmployeeClickHandler} text={intl.formatMessage({ id: "delete-profile" })} buttonStyle="delete" />;

    const restoreSupplierEmployeeButton = <SubmenuActionButton
        handleClick={handleOpenRestoreDialog}
        text={intl.formatMessage({ id: "restore-profile" })}
        helperText={`${intl.formatMessage({ id: "deleted-on" })}: ${moment(profileDetails.dateDeleted).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`}
        buttonStyle="save"
    />;

    const exportButton = <SubmenuActionButton
        component={<PrimaryExportButton />}
        handleClick={handleEmployeeExport}
        text={intl.formatMessage({ id: "export-button-text" })}
    />;

    const actionButtons = <>
        {profileDetails.isDeleted ? restoreSupplierEmployeeButton : deleteButton}
        {exportButton}
    </>;

    return (
        <SubmenuContentLayout
            path={match.url}
            profileName={`${basicInfo.firstName} ${basicInfo.lastName}`}
            supplierName={supplierBasicInfo.name}
            avatarUrl={basicInfo.profilePictureImgUrl}
            role={basicInfo.role}
            items={Object.values(submenuItems)}
            actionButtons={actionButtons}
            displayMode={displayMode}
        >
            <Switch>
                <Route path={match.path} exact render={(props) => (
                    <SupplierEmployeeWrapper
                        agencyId={agencyId}
                        supplierId={supplierId}
                        selectedEmployee={selectedEmployee}
                        openEditStatusDialog={openEditStatusDialog}
                        employeeId={employeeId}
                        removeEmployeeClickHandler={removeEmployeeClickHandler}
                        changeVettedStatusHandler={changeVettedStatusHandler}
                        isVetted={generalInfo.isVetted}
                        isAvailable={generalInfo.isAvailable}
                        changeAvailableStatusHandler={changeAvailableStatusHandler}
                        isDeleted={profileDetails.isDeleted}
                        {...props}
                    >
                        <SupplierEmployeeWrapper.Actions />
                        <SupplierEmployeeWrapper.HistoricalNotes noteType={HISTORICAL_NOTES_TYPES.USER} targetId={employeeId} />
                    </SupplierEmployeeWrapper>
                )} />
                <Route path={`${match.path}${submenuItems.personalInformation.path}`} render={(props) => (
                    <SupplierEmployeeWrapper agencyId={agencyId} supplierId={supplierId} employeeId={employeeId} {...props}>
                        <SupplierEmployeeWrapper.BasicForm />
                        <SupplierEmployeeWrapper.ContactForm />
                    </SupplierEmployeeWrapper>
                )} />
                <Route path={`${match.path}${submenuItems.profileCv.path}`} render={(props) => (
                    <SupplierEmployeeWrapper {...props} supplierId={supplierId} employeeId={employeeId} agencyId={agencyId} departmentId={departmentId} dataFetchState={dataFetchState}>
                        <SupplierEmployeeWrapper.CvSection />
                    </SupplierEmployeeWrapper>
                )} />
                <Route exact path={`${match.path}${submenuItems.timeTracking.path}`} render={(props) => (
                    <SupplierEmployeeWrapper agencyId={agencyId} supplierId={supplierId} employeeId={employeeId} {...props}>
                        <SupplierEmployeeWrapper.TimeTracking
                            employeeId={employeeId}
                            agencyId={agencyId}
                            departmentId={departmentId} />
                    </SupplierEmployeeWrapper>
                )} />

                <Route exact path={`${match.path}${submenuItems.holidays.path}`} render={(props) => (
                    <SupplierEmployeeWrapper agencyId={agencyId} supplierId={supplierId} employeeId={employeeId} departmentId={departmentId} {...props}>
                        <SupplierEmployeeWrapper.Holidays />
                    </SupplierEmployeeWrapper>
                )} />

            </Switch>

            <ConfirmDeleteDialog
                itemToDelete={`${basicInfo.firstName} ${basicInfo.lastName}`
                }
                handleDeleteItem={handleRemoveEmployee}
                openDialog={openConfirmRemoveDialog}
                handleCloseDialog={handleCloseConfirmRemoveDialog}
            />

            <EditStatusDialog
                isOpen={isEditStatusDialogOpen}
                handleCloseDialog={closeEditStatusDialog}
                selectedEmployee={selectedEmployee}
                handleEditStatus={handleEditStatus}
            />

            <ConfirmRestoreUserDialog
                openDialog={openRestoreDialog}
                handleCloseDialog={handleCloseRestoreDialog}
                handleConfirmDialog={restoreUser}
            />
        </SubmenuContentLayout>
    )
};

const mapStateToProps = state => ({
    isClientProjectsModuleModified: isClientProjectsModuleModified(state),
    basicInfo: getEmployeeBasicInfo(state),
    supplierBasicInfo: getSupplierBasicInformation(state),
    agencyRouteName: getAgencyRouteName(state),
    employmentInformation: getEmploymentInformation(state),
    generalInfo: getGeneralInfo(state),
    authUserId: getCurrentUser(state)._id,
    displayMode: getDisplayMode(state),
    dataFetchState: getEmployeeProfileCVFetchState(state),
    profileDetails: getProfileDetails(state),
});

const mapDispatchtoProps = {
    fetchPersonalInfo,
    fetchProfile,
    fetchProjectTemplates,
    fetchEmployeePublicHolidays,
    disableUsers,
    updateEmployeeOccupationStatus,
    editGeneralInfo,
    restoreUsers,
    exportEmployeeInformation,
};

export default connect(mapStateToProps, mapDispatchtoProps)(SupplierEmployee);
