import React from 'react';
import { useIntl } from 'react-intl';

import ConfirmDialog from '../ConfirmDialog';
import { CONFIRM_DIALOG_MOVE_TO_AGENCY_CONFIRMATION_ITEMS } from '../ConfirmDialog/ConfirmDialogConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './ConfirmMoveToAgencyDialog.scss';

const ConfirmMoveToAgencyDialog = ({
    openDialog,
    handleMoveToAgency,
    handleCloseDialog
}) => {
    const intl = useIntl();

    return (
        <ConfirmDialog
            openDialog={openDialog}
            dialogTitle={intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_MOVE_TO_AGENCY_CONFIRMATION_ITEMS.GET_DIALOG_TITLE) })}
            confirmButtonName={intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_MOVE_TO_AGENCY_CONFIRMATION_ITEMS.CONFIRM_BUTTON_NAME) })}
            discardButtonName={intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_MOVE_TO_AGENCY_CONFIRMATION_ITEMS.DISCARD_BUTTON_NAME) })}
            handleConfirmDialog={handleMoveToAgency}
            handleCloseDialog={handleCloseDialog}
        />
    )
};

export default ConfirmMoveToAgencyDialog;
