import React from 'react';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

const DialogSaveButton = ({ className, children, onClick, ...rest }) => {
    return (
        <SecondaryButton
            buttonStyle="save"
            data-testid="dialog-save-btn"
            className={`dialog-save-btn ${className ? className : ''}`}
            text={children}
            handleClick={onClick}
            {...rest}
        />
    );
};

export default DialogSaveButton;
