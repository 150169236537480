import React, { useState, useRef, createContext, useContext } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { editGeneralInfo, exportCV } from 'actions/employeeActions';
import { getDisplayMode, getGeneralInfo, getCurrentUser, getEmployeeBasicInfo } from 'reducers';

import Paper from '@material-ui/core/Paper';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import ProfileGeneralFormView from './ProfileGeneralFormView';
import { Formik } from 'formik';

import { addUtcOffset } from 'utils/formatTimes';
import { validateInputLength, validateDateFields, validateDateNotInFuture } from 'utils/formValidations';
import { profileSummaryFieldMaxLength } from './ProfileGeneralFormConstants';

import './ProfileGeneralForm.scss';

export const ProfileGeneralFormContext = createContext();

const { Provider } = ProfileGeneralFormContext;

const ProfileGeneralForm = ({
    employeeId,
    agencyId,
    authUserId,
    generalInfo,
    editGeneralInfo,
    displayMode,
    children,
    exportCV,
    employeeBasicInfo,
    departmentId,
    className,
}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const profileGeneralFormRef = useRef();

    const handleExpand = () => {
        setIsExpanded(oldState => !oldState);

        if (showHeaderButtons) {
            setShowHeaderButtons(false);
        }
    };

    const handleDiscard = () => {
        profileGeneralFormRef.current.handleReset();
        setShowHeaderButtons(false);
    };

    const context = {
        handleExpand,
        isExpanded,
        showHeaderButtons,
        profileGeneralFormRef,
        handleDiscard,
        generalInfo,
        employeeId,
        agencyId,
        departmentId,
        authUserId,
        setShowHeaderButtons,
        editGeneralInfo,
        displayMode,
        exportCV,
        employeeBasicInfo,
    };
    return (
        <Provider value={context}>
            <div className={`profile-info-wrapper profile-general ${className ? className : ''}`}>
                {children}
            </div>
        </Provider>
    );
};

const ActionsWrapper = () => {
    const intl = useIntl();

    const { showHeaderButtons, profileGeneralFormRef, handleDiscard } = useContext(ProfileGeneralFormContext);

    return (

        <SectionActionsWrapper>
            <SectionActionsWrapper.SectionTitle
                sectionTitle={intl.formatMessage({ id: "general" })}
            />

            {showHeaderButtons && (
                <SectionActionsWrapper.RightAlignedActions>
                    <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                        text={intl.formatMessage({ id: "discard" })}
                        onClick={handleDiscard}
                        buttonStyle='discard'
                    />
                    <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                        text={intl.formatMessage({ id: "save" })}
                        onClick={() => profileGeneralFormRef.current.handleSubmit()}
                        buttonStyle='save'
                    />
                </SectionActionsWrapper.RightAlignedActions>
            )}
        </SectionActionsWrapper>
    );
};

const FormWrapper = () => {
    const {
        generalInfo,
        editGeneralInfo,
        employeeId,
        authUserId,
        setShowHeaderButtons,
        profileGeneralFormRef,
        agencyId,
        departmentId,
        displayMode,
        isExpanded,
    } = useContext(ProfileGeneralFormContext);

    const handleUpdategeneralInfo = async (values, resetForm) => {
        await editGeneralInfo(values, employeeId, agencyId, departmentId, authUserId);
        resetForm();
    };

    return (
        <>
            {isExpanded &&
                <Paper className="profile-info-content">
                    <Formik
                        initialValues={{
                            position: generalInfo.position || '',
                            profileSummary: generalInfo.profileSummary || '',
                            experienceSince: generalInfo?.experienceSince ? moment.utc(generalInfo.experienceSince) : null,
                            profileType: generalInfo.profileType || ''
                        }}
                        validate={values => {
                            return {
                                ...validateInputLength(values, [
                                    { property: 'profileSummary', maxLength: profileSummaryFieldMaxLength }
                                ]),
                                ...validateDateFields(values, ['experienceSince']),
                                ...validateDateNotInFuture(values, ['experienceSince']),
                            }
                        }}
                        enableReinitialize={true}
                        innerRef={profileGeneralFormRef}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false);
                            handleUpdategeneralInfo(
                                {
                                    ...values,
                                    updaterRole: displayMode,
                                    experienceSince: addUtcOffset(values.experienceSince)
                                },
                                resetForm);
                            setShowHeaderButtons(false);
                        }}
                    >
                        {(props) =>
                            <ProfileGeneralFormView {...props}
                                handleShowHeaderButtons={() => setShowHeaderButtons(true)}
                            />
                        }
                    </Formik>
                </Paper>
            }
        </>
    );
};

ProfileGeneralForm.ActionsWrapper = ActionsWrapper;
ProfileGeneralForm.FormWrapper = FormWrapper;

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
    generalInfo: getGeneralInfo(state),
    authUserId: getCurrentUser(state)._id,
    employeeBasicInfo: getEmployeeBasicInfo(state),
});

const mapDispatchtoProps = {
    editGeneralInfo,
    exportCV,
};

export default connect(mapStateToProps, mapDispatchtoProps)(ProfileGeneralForm);
