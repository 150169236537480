import React from 'react';

import './PerformanceBar.scss';

const PerformanceBar = ({
    score
}) => {

    //map each cell of the bar to a color class
    const cellColors = {
        1: "",
        2: "",
        3: "",
        4: "",
        5: ""
    }

    //apply a color to a number range of the cellColors keys
    const setCellColors = (index, color) => {
        const range = [...(new Array(index))].map((_, i) => i + 1);
        range.forEach(i => { cellColors[i] = `cell-color-${color}` });
    }

    //handle score ranges
    if (score === 5) {
        setCellColors(5, "green");
    } else if (score < 5 && score >= 4) {
        setCellColors(4, "green");
    } else if (score < 4 && score >= 3) {
        setCellColors(3, "pale-red");
    } else if (score < 3 && score >= 2) {
        setCellColors(2, "pale-red");
    } else if (score < 2 && score >= 1) {
        setCellColors(1, "red");
    }

    return (
        <div className="performance-bar">
            <div className={`bar-cell cell-pos-first ${cellColors[1]}`}></div>
            <div className={`bar-cell ${cellColors[2]}`}></div>
            <div className={`bar-cell ${cellColors[3]}`}></div>
            <div className={`bar-cell ${cellColors[4]}`}></div>
            <div className={`bar-cell cell-pos-last ${cellColors[5]}`}></div>
        </div>
    )
};

export default PerformanceBar;
