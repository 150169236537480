import { persistReducer } from 'redux-persist';

import {
    LOGIN_FETCH_SUCCESS,
    REGISTER_FETCH_SUCCESS,
    LOGOUT_FETCH_SUCCESS,
    PASSWORD_RESET_EMAIL_SEND_SUCCESS,
    PASSWORD_RESET_SUCCESS,
    TOKEN_REFRESH_SUCCESS,
    AUTH_BASIC_INFO_UPDATE_SUCCESS,
    SWITCH_MODE,
    ONBOARDING_FETCH_SUCCESS,
    ONBOARDING_UPDATE_SUCCESS,
    CLIENT_INV_STATUS_DONE_FETCH_SUCCESS,
    AGENCY_EMPLOYEE_HOME_INFORMATION_FETCH_SUCCESS,
    AUTH_USER_AVATAR_EDIT_SUCCESS,
    AUTH_USER_POSITION_EDIT_SUCCESS,
    TERMS_AND_CONDITIONS_FETCH_SUCCESS,
    TERMS_AND_CONDITIONS_UPDATE_SUCCESS,
    REGISTER_CLIENT_FETCH_SUCCESS,
    CANDIDATE_JOURNEY_START_INFORMATION_FETCH_SUCCESS,
    CANDIDATE_JOURNEY_START_INFORMATION_UPDATE_SUCCESS,
    ACCESS_AS_MANAGER_SET_SUCCESS,
    USER_INFORMATION_FETCH_SUCCESS,
    USER_HAS_NEEDED_EVROTRUST_INFO_SUCCESS,
} from 'actions/actionTypes';

import { ROLES } from 'constants/userConstants';
import { PERSIST_AUTH_CONFIG } from 'utils/persistUtils';
import { createSelector } from 'reselect';

const initialState = {
    user: {},
    idToken: '',
    displayMode: '',
    roles: [],
    agencyId: '',
    isOnboardingPassed: false,
    isClientsInvoicingStatusDone: true,
    accessAsManager: [],
    profilePictureImgUrl: '',
    position: '',
    isTermsAndConditionsAccepted: false,
    hasNeededEvrotrustInfo: false,
    hasClients: true,
    isJourneyStarted: false,
    preselectedRole: '',
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_CLIENT_FETCH_SUCCESS:
        case LOGIN_FETCH_SUCCESS:
            return { ...state, ...action.payload, displayMode: action.payload.roles[0] }
        case LOGOUT_FETCH_SUCCESS:
            return { ...initialState }
        case TOKEN_REFRESH_SUCCESS:
            return { ...state, idToken: action.payload }
        case SWITCH_MODE:
            return { ...state, displayMode: action.payload }
        case AUTH_BASIC_INFO_UPDATE_SUCCESS:
            return { ...state, user: { ...state.user, displayName: `${action.payload.firstName} ${action.payload.lastName}` } };
        case ONBOARDING_UPDATE_SUCCESS:
        case ONBOARDING_FETCH_SUCCESS:
        case CLIENT_INV_STATUS_DONE_FETCH_SUCCESS:
        case AGENCY_EMPLOYEE_HOME_INFORMATION_FETCH_SUCCESS:
        case CANDIDATE_JOURNEY_START_INFORMATION_FETCH_SUCCESS:
        case CANDIDATE_JOURNEY_START_INFORMATION_UPDATE_SUCCESS:
        case ACCESS_AS_MANAGER_SET_SUCCESS:
        case USER_HAS_NEEDED_EVROTRUST_INFO_SUCCESS:
            return { ...state, ...action.payload };
        case USER_INFORMATION_FETCH_SUCCESS:
            return {
                ...state,
                ...action.payload,
                user: { ...state.user, ...action.payload.user },
            };
        case AUTH_USER_AVATAR_EDIT_SUCCESS:
            return { ...state, profilePictureImgUrl: action.payload };
        case AUTH_USER_POSITION_EDIT_SUCCESS:
            return { ...state, position: action.payload };
        case TERMS_AND_CONDITIONS_FETCH_SUCCESS:
        case TERMS_AND_CONDITIONS_UPDATE_SUCCESS:
            return { ...state, ...action.payload }
        case REGISTER_FETCH_SUCCESS:
        case PASSWORD_RESET_EMAIL_SEND_SUCCESS:
        case PASSWORD_RESET_SUCCESS:
        default:
            return state;
    }
}

export default persistReducer(PERSIST_AUTH_CONFIG, authReducer);

export const isAuthenticated = state => Boolean(state.idToken);
export const getRoles = state => state.roles;
export const isHiddenSections = state => state.hiddenSections;
export const getEmail = state => state.user.email;
export const getDisplayName = state => state.user.displayName;
export const isAdmin = state => state.roles.includes(ROLES.ADMIN);
export const isClient = state => state.roles.includes(ROLES.CLIENT);
export const isEmployee = state => state.roles.includes(ROLES.EMPLOYEE);
export const isSupplierAdmin = state => state.roles.includes(ROLES.SUPPLIER_ADMIN);
export const getAgencyId = state => state.agencyId;
export const getDisplayMode = state => state.displayMode;
export const getUserId = state => state.user.uid;
export const getIdToken = state => state.idToken;
export const isAdminMode = state => state.displayMode === ROLES.ADMIN;
export const isCandidateMode = state => state.displayMode === ROLES.CANDIDATE;
export const isClientMode = state => state.displayMode === ROLES.CLIENT;
export const isEmployeeMode = state => state.displayMode === ROLES.EMPLOYEE;
export const isSupplierEmployeeMode = state => state.displayMode === ROLES.SUPPLIER_EMPLOYEE;
export const isSupplierAdminMode = state => state.displayMode === ROLES.SUPPLIER_ADMIN;
export const isRecruiterMode = state => state.displayMode === ROLES.RECRUITER;
export const isOnboarded = state => state.isOnboardingPassed;
export const isClientsInvoicingStatusDone = state => state.isClientsInvoicingStatusDone && state.hasClients;
export const isJourneyStarted = state => state.isJourneyStarted;
export const getAccess = state => state.accessAsManager;
export const getUserImage = state => state.profilePictureImgUrl;
export const getUserPosition = state => state.position;
export const isTermsAndConditions = state => state.isTermsAndConditionsAccepted;
export const getHasNeededEvrotrustInfo = state => state.hasNeededEvrotrustInfo;
export const getPreselectedRole = state => state.preselectedRole;

const getCurrentUserInputSelector = (state) => state.auth.user;
const getCurrentAgencyIdInputSelector = (state) => state.auth.agencyId;
export const getCurrentUser = createSelector(
    [getCurrentUserInputSelector, getCurrentAgencyIdInputSelector],
    (user, agencyId) => {
        return { _id: user.uid, name: user.displayName, agencyId: agencyId };
    }
);
