import React from 'react';

import CandidateCustomerSupportRequestForm from './CandidateCustomerSupportRequestForm';

const CandidateCustomerSupport = ({
    requestCustomerSupport,
    accountManager,
}) => {

    return (
        <div className="candidate-customer-support-wrapper">
            <CandidateCustomerSupportRequestForm
                requestCustomerSupport={requestCustomerSupport}
                accountManager={accountManager}
            />
        </div>
    );
};

export default CandidateCustomerSupport;
