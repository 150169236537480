import React, { useEffect, createContext, useContext } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import ActiveHolidaysDashboard from '../ActiveHolidaysDashboard';
import InviteEmployeeBanner from '../InviteEmployeeBanner';
import TimeOffRequestsDashboard from '../TimeOffRequestsDashboard';
import TeamStatistics from '../TeamStatistics';
import UpcomingBirthdaysDashboard from '../UpcomingBirthdaysDashboard';
import RecentEmployees from '../RecentEmployees';

import { fetchAgencyDashboardInformation } from 'actions/agencyActions';
import {
    getDashboardDataFetchState,
    getAdminDashboardRecentStarters,
    getAdminDashboardRecentLeavers,
} from 'reducers';
import { employeeRequiredFields, employeeRoles } from 'components/Employees/EmployeesConstants';
import { DATE_FORMAT_WITH_DASH } from '../../../constants/commonConstants';
import { RECENT_EMPLOYEES_TYPES } from '../RecentEmployees/RecentEmployeesConstants';

import './AgencyAdminDashboard.scss';

export const AgencyAdminDashboardContext = createContext();
const { Provider } = AgencyAdminDashboardContext;

const AgencyAdminDashboard = ({
    userId,
    agencyId,
    fetchAgencyDashboardInformation,
    children,
    dataFetchState
}) => {
    const context = { userId, agencyId, dataFetchState };

    useEffect(() => {
        fetchAgencyDashboardInformation(
            agencyId,
            moment().format(DATE_FORMAT_WITH_DASH),
            moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH),
            employeeRequiredFields,
            employeeRoles
        );
    }, []);

    return (
        <div>
            {
                dataFetchState?.isDataFetching
                    ? <CircularProgress size={50} disableShrink className="page-loader" />
                    : <Provider value={context}>
                        <div className="agency-dashboard-wrapper">
                            {children}
                        </div>
                    </Provider>
            }
        </div>
    )
}

const ActiveHolidays = () => {
    const { dataFetchState } = useContext(AgencyAdminDashboardContext);

    return <ActiveHolidaysDashboard dataFetchState={dataFetchState} />
}

const InviteEmployeeBannerWrapper = () => {
    const { agencyId, userId } = useContext(AgencyAdminDashboardContext);

    return <InviteEmployeeBanner agencyId={agencyId} userId={userId} />
};

const TeamStatisticsWrapper = () => <TeamStatistics />;

const RecentStarters = () => {
    return <RecentEmployees type={RECENT_EMPLOYEES_TYPES.STARTERS} dataSelector={getAdminDashboardRecentStarters} />;
};

const RecentLeavers = () => {
    return <RecentEmployees type={RECENT_EMPLOYEES_TYPES.LEAVERS} dataSelector={getAdminDashboardRecentLeavers} />;
};

const FlexItemsWrapper = ({
    children,
    className,
}) => (
    <div className={`flex-items-wrapper${className ? ` ${className}` : ''}`}>
        {children}
    </div>
);

const TimeOffRequests = () => <TimeOffRequestsDashboard />;

const UpcomingBirthdays = () => <UpcomingBirthdaysDashboard />;

AgencyAdminDashboard.ActiveHolidays = ActiveHolidays;
AgencyAdminDashboard.InviteEmployeeBanner = InviteEmployeeBannerWrapper;
AgencyAdminDashboard.TeamStatistics = TeamStatisticsWrapper;
AgencyAdminDashboard.RecentStarters = RecentStarters;
AgencyAdminDashboard.RecentLeavers = RecentLeavers;
AgencyAdminDashboard.FlexItemsWrapper = FlexItemsWrapper;
AgencyAdminDashboard.TimeOffRequests = TimeOffRequests;
AgencyAdminDashboard.UpcomingBirthdays = UpcomingBirthdays;

const mapStateToProps = (state, props) => ({
    dataFetchState: getDashboardDataFetchState(state),
})

const mapDispatchToProps = {
    fetchAgencyDashboardInformation
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgencyAdminDashboard));
