import moment from 'moment';

import { validateRequiredFields, validateDecimalNumbers, validateClientProjectName } from 'utils/formValidations';

import { DIALOG_TYPES, FULL_TIME_ID, CONTRACT_ID } from 'constants/clientProjectConstants';

export const dialogInitialsValues = (data, type) => {
    const dateAdded = moment.utc().toDate();

    switch (type) {
        case DIALOG_TYPES.FULL_TIME:
            return {
                employee: {
                    firstName: data.firstName || '',
                    lastName: data.lastName || '',
                    _id: data.employee?._id || '',
                    employeeId: data.employee?.employeeId || '',
                },
                dateAdded: data.isEdit ? data.dateAdded : dateAdded,
                currency: data.projectCurrency.LABEL,
                employerTotalCost: data.employerTotalCost || 0,
                monthlyServiceFee: data.monthlyServiceFee || 0,
            }
        case DIALOG_TYPES.CONTRACT:
            return {
                employee: {
                    firstName: data.firstName || '',
                    lastName: data.lastName || '',
                    _id: data.employee?._id || '',
                    employeeId: data.employee?.employeeId || '',
                },
                dateAdded: data.isEdit ? data.dateAdded : dateAdded,
                currency: data.projectCurrency.LABEL || '',
                hourRate: data.hourRate || 0,
            }
        default:
            return {
                employee: {
                    firstName: data.firstName || '',
                    lastName: data.lastName || '',
                    _id: data.employee?._id || '',
                    employeeId: data.employee?.employeeId || '',
                },
                hourRate: data.hourRate || 0,
                dateAdded: data.isEdit ? data.dateAdded : dateAdded,
                currency: data.currency || data.projectCurrency.LABEL,
                employerTotalCost: data.employerTotalCost || 0,
                monthlyServiceFee: data.monthlyServiceFee || 0,
                billingType: data.billingType || '',
            }
    }
}

export const dialogValidations = (values, type, employeeType, employees) => {

    const validateDedicatedRequiredFields = type === DIALOG_TYPES.BILLING_TYPE
        ? ['employee', 'currency', 'billingType', 'employerTotalCost', 'monthlyServiceFee']
        : ['employee', 'currency', 'employerTotalCost', 'monthlyServiceFee'];
    const validateOnDemandRequiredFields = type === DIALOG_TYPES.BILLING_TYPE
        ? ['employee', 'currency', 'hourRate', 'billingType']
        : ['employee', 'currency', 'hourRate'];

    let employeesValidations = {};

    switch (employeeType) {
        case FULL_TIME_ID:
            employeesValidations = {
                ...validateClientProjectName(values, 'employee', employees),
                ...validateRequiredFields(values, validateDedicatedRequiredFields),
                ...validateDecimalNumbers(values, ['employerTotalCost', 'monthlyServiceFee']),
            };
            break;
        case CONTRACT_ID:
            employeesValidations = {
                ...validateRequiredFields(values, validateOnDemandRequiredFields),
                ...validateDecimalNumbers(values, ['hourRate']),
                ...validateClientProjectName(values, 'employee', employees),
            };
            break;
        default:
            break;
    }

    return employeesValidations;
};
