import React from 'react';

import DesktopPageWithJob from './DesktopPageWithJob';
import DesktopPageWithoutJob from './DesktopPageWithoutJob';

import './StartCandidateJourneyDesktopPage.scss';

const StartCandidateJourneyDesktopPage = ({
    menuInformation,
    getCandidateJourneyDuration,
    START_JOURNEY_STEPS,
    handleStartCandidateJourney,
    agencyName,
    jobOpportunity,
    isWithOpportunity,
    showContent,
}) =>  (
    <>
        { isWithOpportunity
            ?
                <DesktopPageWithJob
                    menuInformation={menuInformation}
                    getCandidateJourneyDuration={getCandidateJourneyDuration}
                    START_JOURNEY_STEPS={START_JOURNEY_STEPS}
                    handleStartCandidateJourney={handleStartCandidateJourney}
                    agencyName={agencyName}
                    jobOpportunity={jobOpportunity}
                    showContent={showContent}
                />
            :
                <DesktopPageWithoutJob
                    menuInformation={menuInformation}
                    getCandidateJourneyDuration={getCandidateJourneyDuration}
                    START_JOURNEY_STEPS={START_JOURNEY_STEPS}
                    handleStartCandidateJourney={handleStartCandidateJourney}
                    agencyName={agencyName}
                    showContent={showContent}
                />
        }
    </>
);

export default StartCandidateJourneyDesktopPage;
