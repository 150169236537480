import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStateAgencyName } from 'utils/helpers';
import { getAccess, isAuthenticated as isUserAuthenticated } from 'reducers';
import { showNotification } from 'actions/notificationActions';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { AUTH_MESSAGES } from 'constants/messageConstants';

const isDepartmentManager = Component => {
    const DepartmentManager = ({
        isUserAuthenticated,
        accessAsManager,
        showNotification,
        ...props
    }) => {
        const history = useHistory();

        const { departmentId } = useParams();

        useEffect(() => {
            if (!isUserAuthenticated) {
                return history.push('/login');
            }
            
            if (accessAsManager.length === 0 || (accessAsManager.length > 0 && departmentId && !accessAsManager.some(x => x._id === departmentId))) {
                history.push(`/${getStateAgencyName()}/dashboard`);
                
                showNotification(AUTH_MESSAGES.INSUFFICIENT_PERMISSIONS, NOTIFICATION_TYPES.ERROR);
            }
        });
        
        return isUserAuthenticated && (accessAsManager.some(x => x._id === departmentId) || history.location.pathname.includes('/departments/holidays') || history.location.pathname.includes('/departments/documents'))
            ? <Component departmentId={departmentId} isAccessAsManager={true} {...props} />
            : null;
    }

    const mapStateToProps = state => ({
        isUserAuthenticated: isUserAuthenticated(state),
        accessAsManager: getAccess(state),
    });

    const mapDispatchToProps = {
        showNotification,
    };

    return connect(mapStateToProps, mapDispatchToProps)(DepartmentManager);
};

export default isDepartmentManager;
