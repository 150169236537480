import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactComponent as UploadImageIcon } from 'assets/upload.svg';
import { ErrorMessage } from 'formik';

import CategorySkills from 'components/Person/Profile/ProfileSkills/CategorySkills';

import { TEXTAREA_ROWS, DIALOG_COMMENT_INPUT } from 'constants/commonConstants';
import { JOB_OPPORTUNITIES_TYPES_ARRAY, JOB_PROFILE_FORWARD_TYPES, SKILLS_CATEGORY } from 'constants/jobOpportunitiesConstants';

import './JobFormDialogView.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const JobFormDialogView = ({
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    fetchSkillSuggestions,
    selectedItem,
    jobType,
    clients,
    isClientRequested,
    isClientDisabled,
    imageSelectedHandler,
    addCountryHandler,
    jobImgRef,
    isAdmin,
    countries,
    selectableCountries,
    handleChangeCheckbox,
}) => {
    const intl = useIntl();
    const [showJobImageActions, setShowJobImageActions] = useState(false);

    return (
        <div className="job-form-dialog-view" autoComplete="off">
            <div className="job-image-field">
                <div
                    className={`job-image-container${showJobImageActions ? ` show-actions` : ''}`}
                    onMouseEnter={() => setShowJobImageActions(true)}
                    onMouseLeave={() => setShowJobImageActions(false)}
                >
                    <input
                        accept="image/*"
                        className="upload-input"
                        id="upload-file-input"
                        type="file"
                        hidden
                        onChange={imageSelectedHandler}
                        ref={jobImgRef}
                    />
                    {values.jobImgUrl
                        ? (
                            <>
                                <Avatar
                                    className={`job-image`}
                                    alt="job-image"
                                    src={values.jobImgUrl}
                                />
                                {showJobImageActions &&
                                    <div className="job-image-actions" >
                                        <IconButton className="job-delete-button icon-button" onClick={() => setFieldValue('jobImgUrl', '')}>
                                            <Icon>
                                                <CloseIcon className="close-icon" />
                                            </Icon>
                                        </IconButton>
                                        <IconButton className="job-edit-button icon-button" onClick={() => jobImgRef.current.click()}>
                                            <Icon>
                                                <EditSharpIcon className="edit-sharp-icon" />
                                            </Icon>
                                        </IconButton>
                                    </div>
                                }
                            </>
                        )
                        : <div className="upload-image">
                            <IconButton
                                className="upload-image-button"
                                disableRipple
                                onClick={() => jobImgRef.current.click()}
                            >
                                <UploadImageIcon className="upload-image-icon" />
                            </IconButton>
                        </div>
                    }
                </div>

                {!values.jobImgUrl &&
                    <p className="assistive-text">
                        {intl.formatMessage({ id: "job-image-helper-text" })}
                    </p>
                }
            </div>
            {clients && isClientRequested ? <div className="client-field">
                <TextField
                    select
                    name="clientId"
                    value={values.clientId}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "client" })}
                    error={errors.clientId && touched.clientId}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    disabled={isClientDisabled}
                >
                    {clients.map((client, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={client._id}
                        >
                            {client.name}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="clientId" component="div" className="invalid-field-message" />
            </div> : null
            }
            <div className="job-type-field">
                <TextField
                    select
                    name="jobType"
                    value={values.jobType}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "job-type" })}
                    error={errors.jobType && touched.jobType}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    disabled={Boolean(jobType || selectedItem._id)}
                    required
                >
                    {JOB_OPPORTUNITIES_TYPES_ARRAY.map((jobType, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={jobType}
                        >
                            {intl.formatMessage({ id: convertStringToLanguageKey(jobType) })}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="jobType" component="div" className="invalid-field-message" />
            </div>

            {isAdmin &&

                <div className="forward-type-field">
                    <TextField
                        select
                        name="profileForwardType"
                        value={values.profileForwardType}
                        onChange={handleChange}
                        label={intl.formatMessage({ id: "profile-forward-type" })}
                        error={errors.profileForwardType && touched.profileForwardType}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                    >
                        {JOB_PROFILE_FORWARD_TYPES.map((item, index) => (
                            <MenuItem
                                native="true"
                                key={index}
                                value={item.key}
                            >
                                {intl.formatMessage({ id: convertStringToLanguageKey(item.value) })}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ErrorMessage name="profileForwardType" component="div" className="invalid-field-message" />
                </div>
            }
            <div>
                <TextField
                    className="job-form-field"
                    name="position"
                    value={values.position}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "position" })}
                    error={errors.position && touched.position}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                />
                <ErrorMessage name="position" component="div" className="invalid-field-message" />
            </div>

            <div className="location-section">

                <div className="location-section-title">
                    {intl.formatMessage({ id: "choose-locations" })}
                </div>

                <div className="other-countries-field">
                    <TextField
                        select
                        name="otherCountry"
                        value={''}
                        onChange={(e) => addCountryHandler(e, values.geographicLocations, setFieldValue)}
                        label={intl.formatMessage({ id: "other-countries" })}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    >
                        {countries.filter(country => !selectableCountries.includes(country.name)).map((country, i) => (
                            <MenuItem
                                native="true"
                                key={i}
                                value={country.name}
                            >
                                {intl.formatMessage({ id: convertStringToLanguageKey(country.name) })}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

                <div className="countries-container">
                    {selectableCountries.map((country, i) => (
                        <div key={country} className={country.length > 15 ? "country-wrapper grid-row-span-2" : "country-wrapper"}>
                            <Checkbox
                                className="country-checkbox"
                                name={country}
                                checked={values.geographicLocations.includes(country)}
                                onChange={(e, state) => handleChangeCheckbox(e, state, values.geographicLocations, setFieldValue)}
                            />
                            <div className="country">{intl.formatMessage({ id: convertStringToLanguageKey(country) })}</div>
                        </div>
                    ))}
                </div>

                <ErrorMessage name="geographicLocations" component="div" className="invalid-field-message" />
            </div>

            <div>
                <TextField
                    className="job-form-field"
                    name="aboutTheClient"
                    value={values.aboutTheClient}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "about-the-company" })}
                    error={errors.aboutTheClient && touched.aboutTheClient}
                    margin="normal"
                    variant="outlined"
                    multiline
                    fullWidth
                    minRows={DIALOG_COMMENT_INPUT.ROWS}
                    maxRows={DIALOG_COMMENT_INPUT.MAX_ROWS}
                    required
                />
                <ErrorMessage name="aboutTheClient" component="div" className="invalid-field-message" />
            </div>

            <div>
                <TextField
                    className="job-form-field"
                    name="jobSummary"
                    value={values.jobSummary}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "about-the-job" })}
                    error={errors.jobSummary && touched.jobSummary}
                    margin="normal"
                    variant="outlined"
                    multiline
                    fullWidth
                    minRows={DIALOG_COMMENT_INPUT.ROWS}
                    maxRows={DIALOG_COMMENT_INPUT.MAX_ROWS}
                    required
                />
                <ErrorMessage name="jobSummary" component="div" className="invalid-field-message" />
            </div>

            <div>
                <TextField
                    className="job-form-field"
                    name="aboutCandidate"
                    value={values.aboutCandidate}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "about-the-candidate" })}
                    error={errors.aboutCandidate && touched.aboutCandidate}
                    margin="normal"
                    variant="outlined"
                    multiline
                    fullWidth
                    minRows={DIALOG_COMMENT_INPUT.ROWS}
                    maxRows={DIALOG_COMMENT_INPUT.MAX_ROWS}
                    required
                />
                <ErrorMessage name="aboutCandidate" component="div" className="invalid-field-message" />
            </div>

            <div>
                <TextField
                    className="job-form-field"
                    name="requirements"
                    value={values.requirements}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "requirements" })}
                    error={errors.requirements && touched.requirements}
                    margin="normal"
                    variant="outlined"
                    multiline
                    fullWidth
                    minRows={TEXTAREA_ROWS.MIN_ROWS}
                    maxRows={TEXTAREA_ROWS.MAX_ROWS}
                    required
                />
                <ErrorMessage name="requirements" component="div" className="invalid-field-message" />
            </div>

            <div>
                <TextField
                    className="add-job-field"
                    name="benefits"
                    value={values.benefits}
                    onChange={handleChange}
                    id="add-job-benefits"
                    label={intl.formatMessage({ id: "additional-benefits" })}
                    error={errors.benefits && touched.benefits}
                    margin="normal"
                    variant="outlined"
                    multiline
                    fullWidth
                    minRows={TEXTAREA_ROWS.MIN_ROWS}
                    maxRows={TEXTAREA_ROWS.MAX_ROWS}
                />
                <ErrorMessage name="benefits" component="div" className="invalid-field-message" />
            </div>

            <div>
                <div className="project-about-technologies-block">
                    <p className="skill-label">{intl.formatMessage({ id: "mandatory-skills" })}</p>
                    <CategorySkills
                        handleSkillDrag={(newSkillsList) => setFieldValue('mandatorySkills', newSkillsList)}
                        skillCategory={SKILLS_CATEGORY.MANDATORY}
                        skills={values.mandatorySkills}
                        fetchSkillSuggestions={fetchSkillSuggestions}
                        removeSkill={(skill) =>
                            setFieldValue('mandatorySkills', values.mandatorySkills.filter(x => x.name !== skill.title))
                        }
                        addSkill={(skill) => {
                            setFieldValue('mandatorySkills', [...values.mandatorySkills, skill])
                        }
                        }
                        addSkillOnSelect
                    />
                </div>
                <div className="project-about-technologies-block">
                    <p className="skill-label">{intl.formatMessage({ id: "nice-to-have-skills" })}</p>
                    <CategorySkills
                        handleSkillDrag={(newSkillsList) => setFieldValue('niceToHaveSkills', newSkillsList)}
                        skillCategory={SKILLS_CATEGORY.NICE_TO_HAVE}
                        skills={values.niceToHaveSkills}
                        fetchSkillSuggestions={fetchSkillSuggestions}
                        removeSkill={(skill) =>
                            setFieldValue('niceToHaveSkills', values.niceToHaveSkills.filter(x => x.name !== skill.title))
                        }
                        addSkill={(skill) =>
                            setFieldValue('niceToHaveSkills', [...values.niceToHaveSkills, skill])
                        }
                        addSkillOnSelect
                    />
                </div>
            </div>
        </div>
    )
};

export default JobFormDialogView;
