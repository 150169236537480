import React from 'react';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';

import FeedbackDialogWrapper from '../FeedbackDialog/FeedbackDialog';

import { ROLES } from 'constants/userConstants';
import { DIALOG_COMMENT_INPUT } from 'constants/commonConstants';

const SubmitFeedbackDialogFormView = ({
    values,
    errors,
    touched,
    handleChange,
    feedbackCriteria,
    displayMode,
    chooseProject,
    projects,
}) => {
    return (
        <form autoComplete="off">
            <div className="rating-container">
                { chooseProject &&
                    <TextField
                            select
                            name="project"
                            value={values.project}
                            onChange={handleChange}
                            label="Project"
                            error={errors.project && touched.project}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            required
                        >
                            {projects.map((project) => (
                                <MenuItem
                                    native="true"
                                    key={project._id}
                                    value={project}
                                >
                                    {project.name}
                                </MenuItem>
                            ))}
                    </TextField>
                }
                <Typography variant="h5" className="rating-criteria-title">Rating Criteria</Typography>
                
                <div className='rating-criteria-container'>

                <FeedbackDialogWrapper.RatingCriteriaWrapper>
                    {feedbackCriteria.map((criteria, i) => (
                        <FeedbackDialogWrapper.RatingCriteriaItem id={`${i}_${criteria}`} name={criteria} title={criteria} />
                    ))}
                </FeedbackDialogWrapper.RatingCriteriaWrapper>

                {(Object.keys(errors).length > 0 && Object.keys(touched).length > 0) && <div className="error-message">All ratings are required.</div>}
                </div>
            </div>
            {displayMode === ROLES.ADMIN &&
                <p className="feedback-msg">The feedback you provide in the following field will be sent to the team. Please provide constructive and comprehensive comments.</p>
            }
            <TextField
                className="details"
                name="details"
                label="Overall Feedback"
                multiline
                minRows={DIALOG_COMMENT_INPUT.ROWS}
                variant="outlined"
                onChange={handleChange}
            />
        </form>
    );
};

export default SubmitFeedbackDialogFormView;
