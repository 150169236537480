import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    getCandidateApplications,
    getUpcomingInterviewsList,
    getCandidateApplicationsDataFetchState,
    getCandidateInterviewInvitationsDataFetchState,
    getEmployeeCvInformation,
    getEmployeeUploadedCv,
    getAgencyRouteName
} from 'reducers';

import { fetchCandidateApplications } from 'actions/jobApplicationActions';
import { fetchCandidateInterviewInvitations } from 'actions/interviewInvitationActions';

import CandidatePersonalInformation from '../CandidatePersonalInformation';
import CircularProgress from '@material-ui/core/CircularProgress';

import { checkForFilledCvSections } from 'utils/cvUtils';

import './ApplicationsAndInterviews.scss';

const ApplicationsAndInterviews = ({
    userId,
    candidateId,
    agencyId,
    candidateApplications,
    upcomingInterviews,
    applicationsDataFetchState,
    interviewsDataFetchState,
    cvInformation,
    uploadedCv,
    agencyRouteName,
    fetchCandidateApplications,
    fetchCandidateInterviewInvitations,
    ...props
}) => {
    useEffect(() => {
        fetchCandidateApplications(agencyId, candidateId);
        fetchCandidateInterviewInvitations(agencyId, candidateId);
    }, []);

    const hasFilledCvSection = checkForFilledCvSections(cvInformation);
    const isMissingCvInformation = !uploadedCv.originalFileName && !hasFilledCvSection;

    return (
        <div className="applications-and-interviews-wrapper">
            {applicationsDataFetchState.isDataFetching || interviewsDataFetchState.isDataFetching && (!applicationsDataFetchState.isDataFetched || !interviewsDataFetchState.isDataFetched)
                ? <CircularProgress size={50} disableShrink className="applications-and-interviews-loader" />
                :
                <CandidatePersonalInformation 
                    isDataAvailable={true}
                    userId={userId}
                    candidateId={candidateId}
                    agencyId={agencyId}
                    isMissingCvInformation={isMissingCvInformation}
                    agencyRouteName={agencyRouteName}
                    {...props} 
                >
                    <CandidatePersonalInformation.Applications />
                    <CandidatePersonalInformation.InterviewsView />
                </CandidatePersonalInformation>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    candidateApplications: getCandidateApplications(state).list,
    upcomingInterviews: getUpcomingInterviewsList(state),
    applicationsDataFetchState: getCandidateApplicationsDataFetchState(state),
    interviewsDataFetchState: getCandidateInterviewInvitationsDataFetchState(state),
    cvInformation: getEmployeeCvInformation(state),
    uploadedCv: getEmployeeUploadedCv(state),
    agencyRouteName: getAgencyRouteName(state),
});

const mapDispatchtoProps = {
    fetchCandidateApplications,
    fetchCandidateInterviewInvitations,
};

export default connect(mapStateToProps, mapDispatchtoProps)(ApplicationsAndInterviews);
