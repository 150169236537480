import React from 'react';

import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';
import Paper from '@material-ui/core/Paper';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import WhatToExpect from '../WhatToExpect/WhatToExpect';

import { ReactComponent as IconLocation } from 'assets/icon_location.svg';
import PlaceholderImage from 'assets/placeholder-image.jpg';

import './DesktopPageWithJob.scss';

const DesktopPageWithJob = ({
    jobOpportunity,
    menuInformation,
    getCandidateJourneyDuration,
    START_JOURNEY_STEPS,
    handleStartCandidateJourney,
    agencyName,
    showContent,
}) => (
    <PaperElement classes={["start-candidate-journey-desktop-page"]}>
        {showContent && <div className="start-candidate-journey-wrapper">
            <div className="start-candidate-journey-container">
                {menuInformation.companyLogoUrl &&
                    <div className="company-logo-wrapper">
                        <img
                            className="company-logo"
                            alt="Company Logo"
                            src={menuInformation.companyLogoUrl}
                        />
                    </div>
                }
                <div className="start-candidate-journey-content">
                    <PaperHeader title={`Thank you for joining ${agencyName}.`} classes={["start-candidate-journey-header"]} />
                    <PaperContent>
                        <p className="thank-you-for-joining-sub-header margin-bottom">
                            You can now browse and apply for remote software jobs and connect with our growing tech community. If you want to apply for this job, you must complete your profile.
                        </p>
                        {jobOpportunity.agencyId &&
                            <div>
                                <PaperHeader title="Seen Jobs" classes={["start-candidate-journey-header"]} />
                                <Paper className="seen-job-container">
                                    <div className="job-company-information">
                                        <img
                                            className="job-logo"
                                            alt="Job Logo"
                                            src={jobOpportunity.jobOpportunityImgUrl || jobOpportunity.client?.logoImgUrl || PlaceholderImage}
                                        />
                                        <h3>{jobOpportunity.client.name}</h3>
                                    </div>
                                    <div className="job-position">
                                        <p>{jobOpportunity.position}</p>
                                    </div>
                                    <div className="job-location">
                                        <div>
                                            <IconLocation />
                                        </div>
                                        <p>{`${jobOpportunity.geographicLocations?.length ? `Remote (${jobOpportunity.geographicLocations.join(", ")})` : 'Remote'}`}</p>
                                    </div>
                                </Paper>
                            </div>
                        }
                        <WhatToExpect
                            getCandidateJourneyDuration={getCandidateJourneyDuration}
                            START_JOURNEY_STEPS={START_JOURNEY_STEPS}
                        />
                    </PaperContent>
                </div>
            </div>
            <PrimaryButton
                className="start-candidate-journey-button"
                text="Start"
                handleClick={handleStartCandidateJourney}
            />
        </div>
        }
    </PaperElement>
);

export default DesktopPageWithJob;
