import React from 'react';

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import './ThreeDotsOptionsPopover.scss';

const ThreeDotsOptionsPopover = ({
    anchorEl,
    handleClose,
    listItems=[],
    item,
    closeOnClick,
}) => (
    <Popover
        className="three-dots-options-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
    >
        <List className="three-dots-popover-list">
            {listItems.map(x => (
                <ListItem button className="three-dots-popover-list-item" key={x.title} onClick={() => {
                    closeOnClick && handleClose();
                    return item ? x.handleClick(item) : x.handleClick();
                }}>
                    <ListItemText primary={x.title} className="list-item-text" />
                </ListItem>
            ))}
        </List>
    </Popover>
);

export default ThreeDotsOptionsPopover;
