import {
	NOTIFICATION_TRIGGER,
	NOTIFICATION_CLEAR,
} from './actionTypes';

const triggerNotification = (notificationInfo) => ({
	type: NOTIFICATION_TRIGGER,
	payload: notificationInfo
});

const clearNotificationData = () => ({
	type: NOTIFICATION_CLEAR
});

export const showNotification = (message, notificationType, infoList, clickaway = true) => dispatch => {
	dispatch(triggerNotification({ message, notificationType, infoList, clickaway }));
};

export const clearNotification = () => dispatch => dispatch(clearNotificationData());
