import React from 'react';

import './StatusBox.scss';

const StatusBox = ({
    title,
    classes=[],
}) => (
    <div className={`status-box ${classes.join(' ')}`}>
        <p>{title}</p>
    </div>
);

export default StatusBox;
