import { PUBLIC_HOLIDAYS_FETCH_SUCCESS } from 'actions/actionTypes';

const publicHolidaysInitialState = [];

const publicHolidays = (state = publicHolidaysInitialState, action) => {
    switch (action.type) {
        case PUBLIC_HOLIDAYS_FETCH_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
};

export default publicHolidays;

export const getPublicHolidays = state => state;
