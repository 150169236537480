import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getAgencyRouteName, getInterviewInvitationsList } from 'reducers';

import Banner from 'components/Shared/Banner';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import { ReactComponent as BannerIcon } from 'assets/banner-invitation-icon.svg';

const UpcomingInterviewBanner = ({
    interviewInvitations,
    agencyRouteName,
}) => {
    const history = useHistory();

    return (
        <>
            {interviewInvitations.length > 0 &&
                <Banner
                    title="Congratulations! You have an upcoming interview request"
                    content="Please navigate to the Upcoming Interviews section and respond to the invite in a timely manner. Do not forget you can suggest new time slots if you are not able to fit in the initially proposed."
                    icon={<BannerIcon />}
                    primaryButtonComponent={<SecondaryButton
                        text="Check Request"
                        handleClick={() => history.push(`/${agencyRouteName}/upcoming-interviews`)}
                    />}
                />
            }
        </>
    );
};

const mapStateToProps = (state) => ({
    interviewInvitations: getInterviewInvitationsList(state),
    agencyRouteName: getAgencyRouteName(state),
});

export default connect(mapStateToProps, null)(UpcomingInterviewBanner);
