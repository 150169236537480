import { SUBSTEPS } from './CandidateProfileJourneyConstants';

const getUploadResumeSubsteps = () => {
    return SUBSTEPS.UPLOAD_RESUME_FILE;
};

const getYourProfileSubsteps = () => {
    return SUBSTEPS.YOUR_PROFILE;
};

const getJobPreferencesSubsteps = () => {
    return SUBSTEPS.PREFERENCES;
};

const getVideoIntroductionSubsteps = () => {
    return SUBSTEPS.VIDEO_INTRODUCTION;
};

export const CANDIDATE_CREATE_PROFILE_STEPS = () => [
    {
        title: 'Your Profile',
        subSteps: getYourProfileSubsteps()
    },
    {
        title: 'Job Preferences',
        subSteps: getJobPreferencesSubsteps()
    },
    {
        title: 'Upload Resume',
        subSteps: getUploadResumeSubsteps()
    },
    {
        title: 'Personal Introduction',
        subSteps: getVideoIntroductionSubsteps()
    },
];

export const getCandidateInitialStepId = (journeyStepsIds) => {
    let stepId = '';

    const steps = CANDIDATE_CREATE_PROFILE_STEPS();

    for (let mainStepIndex = 0; mainStepIndex < steps.length; mainStepIndex++) {
        for (let subStepIndex = 0; subStepIndex < steps[mainStepIndex].subSteps.length; subStepIndex++) {
            if (!journeyStepsIds.includes(steps[mainStepIndex].subSteps[subStepIndex]._id)) {
                stepId = steps[mainStepIndex].subSteps[subStepIndex]._id;
                break;
            }
        }

        if (stepId) {
            break;
        }
    }

    return stepId;
};
