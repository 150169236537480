import { JOB_OPPORTUNITIES_STATUSES, JOB_PROFILE_FORWARD_TYPES } from 'constants/jobOpportunitiesConstants';

export const parseJobData = (values, isAdmin) => {
    const result = {
        jobType: values.jobType,
        mandatorySkills: values.mandatorySkills,
        niceToHaveSkills: values.niceToHaveSkills,
        privacy: values.privacy || 0,
        aboutTheClient: values.aboutTheClient,
        benefits: values.benefits,
        jobSummary: values.jobSummary,
        location: values.location,
        geographicLocations: values.geographicLocations,
        position: values.position,
        requirements: values.requirements,
        aboutCandidate: values.aboutCandidate,
        status: values.status || JOB_OPPORTUNITIES_STATUSES.OPEN,
        clientId: values.clientId,
    };

    if (isAdmin) {
        result.profileForwardType = values.profileForwardType || JOB_PROFILE_FORWARD_TYPES[0].key;
    }

    return result;
}
