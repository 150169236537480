import React, { useState } from 'react';

import MainMenu from './MainMenu';

import { getCandidateMenuItems } from '../constants/candidateMenuConstants';

const CandidateMenu = ({ agencyRouteName }) => {
    const [candidateMenuItems] = useState(getCandidateMenuItems(agencyRouteName));
    return (
        <MainMenu menuItems={candidateMenuItems} />
    );
};

export default CandidateMenu;
