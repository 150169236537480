import React from 'react';

import SearchEmployee from './SearchEmployee';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import './AssignAdminFormView.scss';

const AssignAdminFormView = ({
    handleSelectedEmployees,
    selectedEmployees,
    values,
    handleChange,
}) => {
    return (
        <form className="assign-admin-form" autoComplete="off">
            <SearchEmployee handleChange={handleChange}  searchTerm={values.searchTerm} handleSelectedEmployees={handleSelectedEmployees} />
            <List className="people-results-list">
                {selectedEmployees.map((employee, index) => (
                    <div key={employee._id} className="result-list-item">
                        <ListItem
                            alignItems="flex-start"
                        >
                            <ListItemAvatar>
                                <Avatar alt={`${employee.firstName} ${employee.lastName}`} src={employee.profilePictureImgUrl} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${employee.firstName} ${employee.lastName}`}
                                secondary={employee.position}
                            />
                        </ListItem>
                        {selectedEmployees.length !== index + 1 && <Divider component="li" />}
                    </div>
                ))}
            </List >
        </form>
    );
};

export default AssignAdminFormView;
