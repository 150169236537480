import React from 'react';
import moment from 'moment';

import { ReactComponent as CompanyIcon } from 'assets/company.svg';

import { US_TIME_FORMAT, FULL_DAY_ONLY, FULL_MONTH_ONLY } from 'constants/commonConstants';

import './MyUpcomingInterviewCard.scss';

const MyUpcomingInterviewCard = ({ interview }) => {

    return (
        <div className="my-upcoming-interview-card">
            <div>
                <div className="position">
                    <span>{interview.position}</span>
                </div>
                <div className="time-range">
                    <span>
                        {
                            `${moment(interview.interviewDate?.date).format(US_TIME_FORMAT).split(' ').join('')} - ` +
                            `${moment(interview.interviewDate?.date).add(1, 'hours').format(US_TIME_FORMAT).split(' ').join('')}`
                        }
                    </span>
                </div>
                <div className="company">
                    <CompanyIcon className="company-icon" />
                    <span>{interview.company}</span>
                </div>
                {interview.location &&
                    <div className="location">
                        Location: <span>{interview.location}</span>
                    </div>
                }
            </div>
            <div className="day-month-container">
                <div className="day">{moment(interview.interviewDate?.date).format(FULL_DAY_ONLY)}</div>
                <div className="month">{moment(interview.interviewDate?.date).format(FULL_MONTH_ONLY)}</div>
            </div>
        </div>
    );
};

export default MyUpcomingInterviewCard;
