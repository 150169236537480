import React from 'react';
import { useIntl } from 'react-intl';

import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { ErrorMessage } from 'formik';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import { DIALOG_COMMENT_INPUT } from 'constants/commonConstants';

import './HolidayRejectFormView.scss';

const HolidayRejectFormView = ({
    values,
    handleChange,
    errors,
    touched,
    handleCloseDialog,
    handleSubmit,
}) => {
    const intl = useIntl();

    return (
        <form className="request-holiday-form" autoComplete="off">
            <div className='reason-field'>
                <TextField
                    name="rejectionReason"
                    value={values.reason}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "reason" })}
                    error={errors.rejectionReason && touched.rejectionReason}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={DIALOG_COMMENT_INPUT.ROWS}
                />
                <ErrorMessage name="rejectionReason" component="div" className="invalid-field-message" />
            </div>
            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text={intl.formatMessage({ id: "discard" })} />
                <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text={intl.formatMessage({ id: "reject" })} type="submit" />
            </DialogActions>
        </form>
    )
};

export default HolidayRejectFormView;
