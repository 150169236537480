import React, { createContext } from 'react';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import PrimaryAddButton from 'components/Shared/Buttons/PrimaryAddButton';
import PrimaryExportButton from 'components/Shared/Buttons/PrimaryExportButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import './RightAlignedActions.scss';

export const RightAlignedActionsWrapperContext = createContext();
const { Provider } = RightAlignedActionsWrapperContext;

const RightAlignedActions = ({
    children,
    classes = [],
}) => {
    const context = {
    };

    return (
        <div className={`right-aligned-section-actions-container${classes.length > 0 ? ` ${classes.join(' ')}` : ''}`}>
            <Provider value={context}>
                {children}
            </Provider>
        </div>
    );
};

const SecondaryButtonWrapper = ({
    onClick,
    text,
    buttonStyle,
}) => {
    return <SecondaryButton handleClick={onClick} text={text} buttonStyle={buttonStyle} />
};

const PrimaryButtonWrapper = ({
    onClick,
    text,
    icon,
    ...props
}) => {
    return <PrimaryButton
        className="no-break-button"
        text={text}
        handleClick={onClick}
        icon={icon}
        {...props}
    />
};

const PrimaryAddButtonWrapper = ({
    onClick,
    text,
}) => {
    return <PrimaryAddButton
        text={text}
        handleClick={onClick}
    />
};

const PrimaryExportButtonWrapper = ({
    onClick,
    text,
}) => {
    return <PrimaryExportButton
        text={text}
        handleClick={onClick}
    />
};

RightAlignedActions.SecondaryButton = SecondaryButtonWrapper;
RightAlignedActions.PrimaryButton = PrimaryButtonWrapper;
RightAlignedActions.PrimaryAddButton = PrimaryAddButtonWrapper;
RightAlignedActions.PrimaryExportButton = PrimaryExportButtonWrapper;

export default RightAlignedActions;
