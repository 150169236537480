export const substepIds = {
    expertise: '61f01670a6900f5d2bdebfa9',
    experience: '61f0168fa6900f5d2bdebfaa',
    preferences: '61f0169da6900f5d2bdebfab',
    uploadResume: '61f27217cf6c853595b9fc30',
    cvCompletion: '61f2723ecf6c853595b9fc35',
    choiseSection: '61f27261cf6c853595b9fc3b',
    videoIntroduction: '61f2726acf6c853595b9fc3e'
};

export const CALENDLY_DIALOG_STATES = {
    OPEN: 'open',
    CLOSED: 'closed'
};

export const SUBSTEPS = {
    YOUR_PROFILE: [
        {
            _id: substepIds.expertise,
            title: 'expertise',
        },
        {
            _id: substepIds.experience,
            title: 'experience'
        },
    ],
    PREFERENCES: [
        {
            _id: substepIds.preferences,
            title: 'preferences'
        },
    ],
    UPLOAD_RESUME_FILE: [
        {
            _id: substepIds.uploadResume,
            title: 'uploadResume'
        },
        {
            _id: substepIds.cvCompletion,
            title: 'cvCompletion',
        }
    ],
    VIDEO_INTRODUCTION: [
        {
            _id: substepIds.choiseSection,
            title: 'choiseSection',
        },
        {
            _id: substepIds.videoIntroduction,
            title: 'videoIntroduction',
        },
    ]
};
