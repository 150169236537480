import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Section from 'components/Shared/Section';
import NothingToShow from 'components/NothingToShow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as CalendarLinesIcon } from 'assets/icon-calendar-lines.svg';

import { DATE_FORMAT_WITH_DOTS } from 'constants/commonConstants';
import { RECENT_EMPLOYEES_TYPES_TO_LABEL_MAP } from './RecentEmployeesConstants';

import { getStateAgencyName } from 'utils/helpers';
import { convertStringToLanguageKey } from 'utils/languageUtils';

import './RecentEmployees.scss';

const RecentEmployees = ({
    type,
    employees,
}) => {
    const intl = useIntl();

    return (
        <div className="recent-employees-container">
            <Section title={intl.formatMessage({ id: convertStringToLanguageKey(`Recent ${type}`)})}>
                {employees.length === 0 ?
                    <NothingToShow items={`recent ${type}`} /> :
                    <Paper className="recent-employees-paper">
                        {employees.map(employee => {
                            const labelText = RECENT_EMPLOYEES_TYPES_TO_LABEL_MAP[type];

                            return (
                                <div
                                    key={employee._id}
                                    className="employee-wrapper"
                                >
                                    <Link
                                        to={`/${getStateAgencyName()}/team/people/${employee._id}`}
                                        className="flex-wrapper name-avatar"
                                    >
                                        <Avatar
                                            className="avatar"
                                            src={employee.profilePictureImgUrl}
                                        />
                                        <Typography className="name">{employee.firstName || ''} {employee.lastName || ''}</Typography>
                                    </Link>
                                    <div className="flex-wrapper date">
                                        <CalendarLinesIcon />
                                        <Typography>{moment(employee.date).format(DATE_FORMAT_WITH_DOTS)}</Typography>
                                    </div>
                                    {moment(employee.date).isSameOrBefore(moment(), 'day') &&
                                        <div className={`label-wrapper ${labelText}`}>
                                            <Typography variant='body2'>{intl.formatMessage({ id: convertStringToLanguageKey(labelText)})}</Typography>
                                        </div>
                                    }
                                </div>
                            );
                        })}
                    </Paper>
                }
            </Section>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    employees: ownProps.dataSelector(state),
});

export default connect(mapStateToProps)(RecentEmployees);
