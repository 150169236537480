export const FILE_TYPE_MAPPER = {
    'application/pdf': 'pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/msword': 'doc',
    'text/plain': 'txt',
    'application/zip': 'zip',
    'text/csv': 'csv',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-excel': 'xls',
    'image/jpeg': 'jpeg',
    'image/png': 'png'
};

export const EMPTY_FILE_TYPE = 'type/empty';

export const URL_QUERY_ACTION_TYPES = {
    DOWNLOAD: 'download',
};

export const MEDIA_TYPES = {
    WEBM_WITH_CODECS: 'video/webm;codecs=vp8',
    WEBM_WITHOUT_CODECS: 'video/webm',
    MIMETYPE_FOR_SAFARI: 'video/mp4'
};
