import React from 'react';
import { connect } from 'react-redux';

import { requestCustomerSupport } from 'actions/userActions';

import { getDisplayMode } from 'reducers';

import { getCustomerSupportComponent } from './CustomerSupportUtils';

import './CustomerSupport.scss';

const CustomerSupport = ({
    displayMode,
    requestCustomerSupport,
    accountManager,
}) => {
    const CustomerSupportComponent = getCustomerSupportComponent(displayMode);

    return (
        <div className="customer-support-wrapper">
            <CustomerSupportComponent
                requestCustomerSupport={requestCustomerSupport}
                accountManager={accountManager}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
});

const mapDispatchToProps = {
    requestCustomerSupport,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupport);
