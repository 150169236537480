import React from 'react';

import MyUpcomingInterviewCard from './MyUpcomingInterviewCard';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import './MyUpcomingInterviewsList.scss';

const MyUpcomingInterviewsList = ({ upcomingInterviews }) => {

    return (
        <div className="my-upcoming-interviews-list-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle sectionTitle='Upcoming Interviews' />
            </SectionActionsWrapper>

            {upcomingInterviews?.length > 0 &&
                <div className="my-upcoming-interviews-list">
                    {upcomingInterviews.map(interview =>
                        <MyUpcomingInterviewCard
                            key={interview._id}
                            interview={interview}
                        />
                    )}
                </div>
            }
        </div>
    );
};

export default MyUpcomingInterviewsList;
