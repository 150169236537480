import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    fetchClientProjectEmployeeHistory,
    fetchClientProjectDedicatedEmployeeHistory
} from 'actions/clientActions';
import { getProjectEmployeeHistory } from 'reducers';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import './EmployeeHistoryDialog.scss';

const EmployeeHistoryDialog = ({
    agencyId,
    clientId,
    projectId,
    openDialog,
    handleCloseDialog,
    currentEmployee,
    employeeHistory,
    fetchClientProjectEmployeeHistory,
    fetchClientProjectDedicatedEmployeeHistory,
    isDedicated,
    subtitle,
    title,
    field,
}) => {
    useEffect(() => {
        isDedicated
            ? fetchClientProjectDedicatedEmployeeHistory(agencyId, clientId, projectId, currentEmployee._id, field)
            : fetchClientProjectEmployeeHistory(agencyId, clientId, projectId, currentEmployee._id);
    }, []);

    return (
        <Dialog className="on-demand-history-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth="md">
            <MuiDialogTitle disableTypography className="dialog-title">
                <Typography variant="body2" className="dialog-title-text">
                    {title} {
                        Object.keys(currentEmployee).length > 0
                            ? `${currentEmployee.firstName} ${currentEmployee.lastName}`
                            : ""
                    }
                </Typography>
                <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>

            <DialogContent className="dialog-content">
                <Typography variant="body2" className="dialog-content-title-text">
                    {subtitle}
                </Typography>
                <div className="employee-history-wrapper">
                    {
                        employeeHistory.map((history, index) =>
                            <div className="rate-container" key={index}>
                                <div className="rate-dates">
                                    {`${moment(history.startDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)} - ${moment(history.endDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`}
                                </div>
                                <div className="rate-text">
                                    <div className={`rate ${history.progressFromPrevious}`}>
                                        {history.fieldOfHistory}
                                    </div>
                                    <div className="currency">
                                        {history.currency}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </DialogContent>
        </Dialog>
    )
};

const mapStateToProps = (state) => ({
    employeeHistory: getProjectEmployeeHistory(state),
});

const mapDispatchtoProps = {
    fetchClientProjectEmployeeHistory,
    fetchClientProjectDedicatedEmployeeHistory,
};

export default connect(mapStateToProps, mapDispatchtoProps)(EmployeeHistoryDialog);
