import React, { createContext, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import { ExploreTalentsEmployeeCardContext } from '../ExploreTalentsEmployeeCard';

import { getViewCvLink, getProfileCvLink, getStateAgencyName } from 'utils/helpers';

const CardButtonsWrapperContext = createContext();
const { Provider } = CardButtonsWrapperContext;

const CardButtonsWrapper = ({
    children,
}) => {
    const { employee, setPersonalInfoInitialState } = useContext(ExploreTalentsEmployeeCardContext);
    const agencyRouteName = getStateAgencyName();
    const context = { employee, agencyRouteName, setPersonalInfoInitialState };

    return (
        <Provider value={context}>
            <div className="employee-profile-actions-container">
                {children}
            </div>
        </Provider>
    );
};

const ViewProfile = () => {
    const intl = useIntl();
    const { employee, agencyRouteName } = useContext(CardButtonsWrapperContext);
    return (
        <div className="button-container view-profile">
            <Link to={getViewCvLink(agencyRouteName, employee)}>
                <PrimaryButton text={intl.formatMessage({ id: "view-cv" })} className="view-profile" />
            </Link>
        </div>
    )
};

const EditProfile = () => {
    const intl = useIntl();
    const { employee, agencyRouteName, setPersonalInfoInitialState } = useContext(CardButtonsWrapperContext);

    const handleClick = () => {
        setPersonalInfoInitialState()
    }

    return (
        <div className="button-container edit-profile">
            <Link to={getProfileCvLink(agencyRouteName, employee)} onClick={handleClick}>
                <SecondaryButton text={intl.formatMessage({ id: "edit-profile" })} className="edit-profile" />
            </Link>
        </div>
    )
};

CardButtonsWrapper.ViewProfile = ViewProfile;
CardButtonsWrapper.EditProfile = EditProfile;

export default CardButtonsWrapper;
