import React from "react";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

import defaultAvatar from "assets/dummy-avatar.jpg";

import { FULL_TIME_ID, DIALOG_TYPES } from 'constants/clientProjectConstants';

const FullTime = ({
	project,
	handlePopoverOpen,
	handlePopoverClose,
	isClientMode,
	isEmployeeMode,
	isSupplierEmployeeMode,
	handleAddEmployeeDialog,
}) => {
	const handleAddDedicatedEmployeeDialog = () => {
		handleAddEmployeeDialog(DIALOG_TYPES.FULL_TIME);
	};

	return (<>
		{!project.dedicatedEmployees.length && (isClientMode || isEmployeeMode) ? null : (
			<div className="people-container dedicated-members">
				<div>
					<h2 className="people-title-container">
						Full-time Employees
					</h2>
				</div>
				<div className="people-container-members">
					{project.dedicatedEmployees.filter(x => !x.isDeleted).map((employee, i) =>  (
						<Avatar
							alt="Emplyoee Avatar"
							className="avatar2 profile-image"
							src={employee.profilePictureImgUrl}
							onError={(e) => (e.target.src = defaultAvatar)}
							key={i + "avatar"}
							id={employee._id + project._id + FULL_TIME_ID}
							onMouseEnter={() => handlePopoverOpen(employee._id, FULL_TIME_ID, employee._id + project._id + FULL_TIME_ID)}
							onMouseLeave={handlePopoverClose}
						/>
					))}
					{!isClientMode && !isEmployeeMode && !isSupplierEmployeeMode && (
						<Button
							size="small"
							variant="outlined"
							color="primary"
							className="add-person-button"
							onClick={handleAddDedicatedEmployeeDialog}>
							<AddIcon />
						</Button>
					)}
				</div>
			</div>
		)}
	</>);
}

export default FullTime;
