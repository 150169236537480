import React from 'react';
import { useIntl } from 'react-intl';
import { ErrorMessage } from 'formik';

import TextField from '@material-ui/core/TextField';

const HolidayAllowanceFormView = ({
    values,
    handleChange,
    onInputChanged,
    errors,
}) => {
    const intl = useIntl();

    return (
        <form className="holiday-allowance-form" autoComplete="off">
            <div className="holiday-allowance-input ">
                <TextField
                    name="holidayAllowance"
                    label={intl.formatMessage({ id: "holidays-per-calendar-year" })}
                    type="text"
                    fullWidth
                    value={values.holidayAllowance}
                    onChange={handleChange}
                    onInput={onInputChanged}
                    error={errors.holidayAllowance}
                    variant="outlined"
                />
                <ErrorMessage name="holidayAllowance" component="div" className="invalid-field-message" />
            </div>
        </form>
    )
};

export default HolidayAllowanceFormView;
