import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DialogCloseButton = ({ onClick, className, ...rest }) => {
    return (
        <IconButton className={className} onClick={onClick} {...rest}>
            <CloseIcon />
        </IconButton>
    );
};

export default DialogCloseButton;
