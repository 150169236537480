import React from 'react';

const Logo = ({
    className = '',
    idSuffix = '' // idSuffix needed when logo is used in several different places on one page.
}) => (
    <svg className={className} id="Group_46478" data-name="Group 46478" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="184.289" height="60.171" viewBox="0 0 184.289 60.171">
        <defs>
            <clipPath id={"clip-path-logo" + idSuffix}>
                <path id="Path_63173" data-name="Path 63173" d="M92.876,15.228,74.812,53.993a10.672,10.672,0,0,1-6.806,5.753c-.036.011-.07.023-.1.033a10.439,10.439,0,0,1-1.633.32.353.353,0,0,1-.126.018,9.828,9.828,0,0,1-1.03.054h-.054a9.827,9.827,0,0,1-1.03-.054c-.036,0-.09-.018-.126-.018a10.484,10.484,0,0,1-1.637-.32l-.1-.029a10.629,10.629,0,0,1-6.812-5.757L46.939,35.947,38.522,53.993c-.134.291-.323.531-.481.8-.067.116-.134.231-.206.345a10.515,10.515,0,0,1-1.015,1.371c-.1.119-.215.233-.325.349a10.36,10.36,0,0,1-1.127,1.021c-.244.191-.5.37-.764.542-.289.184-.58.359-.885.515-.141.072-.273.154-.417.22a9.435,9.435,0,0,1-1.1.452A11.738,11.738,0,0,1,30,60.1a.353.353,0,0,1-.126.018,9.828,9.828,0,0,1-1.03.054h-.054a9.828,9.828,0,0,1-1.03-.054c-.054,0-.09-.018-.126-.018a10.484,10.484,0,0,1-1.637-.32L25.9,59.75a10.628,10.628,0,0,1-6.814-5.757L1,15.228a10.7,10.7,0,0,1,19.383-9.05L28.8,24.242,37.221,6.178a10,10,0,0,1,.527-.883c.038-.065.076-.128.114-.191A10.322,10.322,0,0,1,38.98,3.6c.054-.061.112-.121.168-.182a10.6,10.6,0,0,1,1.286-1.167c.19-.146.388-.282.589-.415a10.828,10.828,0,0,1,1.051-.62c.13-.067.251-.141.385-.2.361-.163.741-.325,1.1-.452a11.738,11.738,0,0,1,2.2-.488.353.353,0,0,0,.126-.018A9.829,9.829,0,0,1,46.921,0h.054a9.829,9.829,0,0,1,1.03.054c.036,0,.072.018.126.018a11.739,11.739,0,0,1,2.2.488c.361.126.741.289,1.1.452.11.051.206.119.313.175a9.876,9.876,0,0,1,.936.522c.159.1.3.213.455.322a10.525,10.525,0,0,1,3.535,4.147l8.418,18.046,8.4-18.046a10.7,10.7,0,1,1,19.383,9.05" transform="translate(0)" fill="#fff" />
            </clipPath>
            <linearGradient id={"linear-gradient" + idSuffix} y1="0.582" x2="1" y2="0.582" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#fc7753" />
                <stop offset="0.006" stopColor="#2acbae" />
                <stop offset="1" stopColor="#5a4bf8" />
            </linearGradient>
            <clipPath id={"clip-path-logo-2" + idSuffix}>
                <path id="Path_63174" data-name="Path 63174" d="M88.577,15.224,70.5,53.989a10.692,10.692,0,0,1-19.383-9.032L69.195,6.174a10.7,10.7,0,1,1,19.383,9.05" transform="translate(-50.11 -0.005)" fill="#fff" />
            </clipPath>
            <clipPath id={"clip-path-logo-3" + idSuffix}>
                <path id="Path_63175" data-name="Path 63175" d="M121.553,59.159a10.6,10.6,0,0,1-4.516,1.012,10.72,10.72,0,0,1-9.7-6.178l-8.4-18.028L90.519,53.993a10.692,10.692,0,0,1-19.383-9.032L89.2,6.178a10.359,10.359,0,0,1,.571-.947l.049-.081a10.468,10.468,0,0,1,1.12-1.519c.087-.1.175-.19.264-.282a10.322,10.322,0,0,1,1.172-1.064q.363-.282.753-.533a10.279,10.279,0,0,1,.894-.52c.139-.072.271-.154.415-.22.361-.163.741-.325,1.1-.452a11.739,11.739,0,0,1,2.2-.488.353.353,0,0,0,.126-.018A9.829,9.829,0,0,1,98.9,0h.054a9.829,9.829,0,0,1,1.03.054c.036,0,.072.018.126.018a11.739,11.739,0,0,1,2.2.488c.361.126.741.289,1.1.452.1.047.186.11.284.159a10.355,10.355,0,0,1,1.017.567c.092.06.177.126.267.188a10.4,10.4,0,0,1,3.671,4.252l18.082,38.783a10.677,10.677,0,0,1-5.184,14.2" transform="translate(-70.125)" fill="#fff" />
            </clipPath>
        </defs>
        <g id="Mask_Group_12" data-name="Mask Group 12" transform="translate(0 0)" clipPath={`url(#clip-path-logo${idSuffix})`}>
            <rect id="Rectangle_5317" data-name="Rectangle 5317" width="190.418" height="76.387" transform="translate(-3.373 -10.708)" fill={`url(#linear-gradient${idSuffix})`} />
        </g>
        <g id="Mask_Group_20" data-name="Mask Group 20" transform="translate(90.518 0)" clipPath={`url(#clip-path-logo-2${idSuffix})`}>
            <rect id="Rectangle_5322" data-name="Rectangle 5322" width="190.418" height="76.387" transform="translate(-93.891 -6.385)" fill={`url(#linear-gradient${idSuffix})`} />
        </g>
        <g id="Mask_Group_19" data-name="Mask Group 19" transform="translate(126.674 0)" clipPath={`url(#clip-path-logo-3${idSuffix})`}>
            <rect id="Rectangle_5325" data-name="Rectangle 5325" width="190.418" height="76.387" transform="translate(-130.047 -4.238)" fill={`url(#linear-gradient${idSuffix})`} />
        </g>
    </svg>
);

export default Logo;
