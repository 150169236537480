
export const CANDIDATE_STATUSES = {
    NEW: 'New',
    CV_COMPLETED: 'CV Completed',
    REJECTED: 'Rejected',
    VETTED: 'Vetted',
    PENDING_VIDEO_REVIEW: 'Pending Video Review',
    PENDING_CALENDLY_REVIEW: 'Pending Calendly Review',
    PENDING: 'Pending',
    INCOMPLETE_PROFILE: 'Incomplete Profile',
    NOT_VETTED: 'Not-Vetted'
};

export const CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS = {
    EXPERTISE: '61f01670a6900f5d2bdebfa9',
    EXPERIENCE: '61f0168fa6900f5d2bdebfaa',
    PREFERENCES: '61f0169da6900f5d2bdebfab',
    UPLOAD_RESUME: '61f27217cf6c853595b9fc30',
    CV_COMPLETION: '61f2723ecf6c853595b9fc35',
    CHOISE_SECTION: '61f27261cf6c853595b9fc3b',
    VIDEO_INTRODUCTION: '61f2726acf6c853595b9fc3e'
};

export const CANDIDATE_OVERVIEW_PAGE_DIALOGS = {
    NAME: {
        TITLE: 'Edit Name',
        VALUE: 'name',
    },
    EMAIL: {
        TITLE: 'Edit Email',
        VALUE: 'email',
    },
    ASSIGNEE: {
        TITLE: 'Edit Assignee',
        VALUE: 'assignee',
    },
    PHONE: {
        TITLE: 'Edit Phone',
        VALUE: 'phone',
    },
    LINKEDIN: {
        TITLE: 'Edit LinkedIn',
        VALUE: 'linkedin',
    },
    GITHUB: {
        TITLE: 'Edit GitHub',
        VALUE: 'github',
    },
}

export const CANDIDATE_OVERVIEW_REQUIRED_FIELDS = {
    name: ['field'],
    email: ['field'],
    assignee: ['assignee'],
    phone: ['field'],
    linkedin: ['field'],
    github: ['field'],
};

export const CANDIDATE_OVERVIEW_EMAIL_FIELDS = {
    name: [],
    email: ['field'],
    assignee: [],
    phone: [],
    linkedin: [],
    github: [],
};

export const CANDIDATE_OVERVIEW_PHONE_FIELDS = {
    phone: 'field',
};

export const CANDIDATE_OVERVIEW_LINKEDIN_FIELDS = {
    linkedin: 'field',
};

export const CANDIDATE_OVERVIEW_GITHUB_FIELDS = {
    github: 'field',
};

export const VISIBLE_STATUSES = [
    CANDIDATE_STATUSES.PENDING_CALENDLY_REVIEW,
    CANDIDATE_STATUSES.PENDING_VIDEO_REVIEW,
    CANDIDATE_STATUSES.PENDING,
    CANDIDATE_STATUSES.REJECTED,
    CANDIDATE_STATUSES.INCOMPLETE_PROFILE,
    CANDIDATE_STATUSES.VETTED,
];

export const STATUSES_MAPPER = {
    [CANDIDATE_STATUSES.NEW]: CANDIDATE_STATUSES.PENDING,
    [CANDIDATE_STATUSES.CV_COMPLETED]: CANDIDATE_STATUSES.PENDING,
    [CANDIDATE_STATUSES.REJECTED]: CANDIDATE_STATUSES.REJECTED,
    [CANDIDATE_STATUSES.VETTED]: CANDIDATE_STATUSES.VETTED,
    [CANDIDATE_STATUSES.PENDING_VIDEO_REVIEW]: CANDIDATE_STATUSES.PENDING,
    [CANDIDATE_STATUSES.PENDING_CALENDLY_REVIEW]: CANDIDATE_STATUSES.PENDING,
    [CANDIDATE_STATUSES.PENDING]: CANDIDATE_STATUSES.PENDING,
    [CANDIDATE_STATUSES.INCOMPLETE_PROFILE]: CANDIDATE_STATUSES.INCOMPLETE_PROFILE,
    [CANDIDATE_STATUSES.NOT_VETTED]: CANDIDATE_STATUSES.NOT_VETTED
};

export const JOB_TYPES = {
    DESIGNER: 'Designer',
    DEVELOPER: 'Developer',
    QA: 'QA Engineer',
    DEVOPS: 'DevOps',
    PRODUCTOWNER: 'Product Manager',
};

export const PROFILE_TYPES = [
    'Developer',
    'Designer',
    'Product Manager',
    'QA Engineer',
    'DevOps Engineer',
    'Data Engineer',
    'Data Analyst',
    'Architect',
    'Team Lead'
];

export const CANDIDATE_FEEDBACK_RATING_CRITERIA = {
    SOFT_SKILLS_STEP: 'Soft skills feedback (private)',
    PERSONALITY: 'Personality',
    LANGUAGE_SKILLS: 'Language skills',
    SOFT_SKILLS: 'Soft skills',
    HARD_SKILLS_STEP: 'Hard skills feedback (private)',
    TECHNICAL_SKILLS: 'Technical skills',
};

export const CANDIDATE_PROFILE_STEPS = {
    SOFT_SKILLS: 'softSkillsStep',
    HARD_SKILLS: 'hardSkillsStep',
    SEND_FEEDBACK: 'sendFeedbackToCandidateStep',
    SENT_PROFILE: 'sentProfileToClientStep',
    UPDATE_APPLICATION_STATUS: 'updateApplicationStatusStep',
};

export const CANDIDATE_RATING_STATUSES = {
    VETTED: 'Vetted',
    REJECTED: 'Rejected',
    INCOMPLETE_PROFILE: 'Incomplete Profile',
};

export const CANDIDATE_REJECTION_REASONS_KEYS = {
    LACKING_SKILLS: 1,
    LACK_OF_CULTURAL_FIT: 2,
    UNSUITABLE_LOCATION: 3,
    UNREASONABLE_REMUNERATION: 4,
    HARD_REJECT: 5,
    OTHER: 6,
};

export const CANDIDATE_RATING_STATUSES_ARRAY = [
    { key: 'Vetted', value: 'Vetted', langKey: "vetted" },
    { key: 'Rejected', value: 'Rejected', langKey: "rejected" },
    { key: 'Incomplete Profile', value: 'Incomplete Profile', langKey: "incomplete-profile" },
];

export const CANDIDATE_REJECTION_REASONS_ARRAY = [
    { key: CANDIDATE_REJECTION_REASONS_KEYS.LACKING_SKILLS, value: 'Lacking skills / qualifications' },
    { key: CANDIDATE_REJECTION_REASONS_KEYS.LACK_OF_CULTURAL_FIT, value: 'Lack of cultural fit' },
    { key: CANDIDATE_REJECTION_REASONS_KEYS.UNSUITABLE_LOCATION, value: 'Unsuitable location' },
    { key: CANDIDATE_REJECTION_REASONS_KEYS.UNREASONABLE_REMUNERATION, value: 'Unreasonable financial expectations' },
    { key: CANDIDATE_REJECTION_REASONS_KEYS.HARD_REJECT, value: 'Hard reject' },
    { key: CANDIDATE_REJECTION_REASONS_KEYS.OTHER, value: 'Other' },
];

export const STEPS_OF_REJECTION = {
    REJECTED_BY_AGENCY: 0,
    FIRST_STEP: 1,
    SECOND_STEP: 2,
    THIRD_STEP: 3, // Agency rejects on-demand candidate application.
    FOURTH_STEP: 4, // Agency changes request response status to rejected.
};

export const INTERVIEW_CATEGORIES = {
    PERSONAL: 'personal',
    BEHAVIOURAL: 'behavioural',
    TECHNICAL: 'technical',
    SPECIALTY: 'specialty',
    JOURNEY: 'candidateJourney',
};

export const COUNTRY_OF_TAX_OTHER = 'Other';

export const SUPPORTED_COUNTRIES_LIST = [
    'Bulgaria',
    'Romania',
    'Turkey',
    'Belarus',
    'Brazil',
    'Canada',
    'Chile',
    'Colombia',
    'Georgia',
    'Germany',
    'Hungary',
    'Lithuania',
    'Poland',
    'Switzerland',
    'Ukraine',
    'United States of America',
    'Uzbekistan',
];

export const  CALENDLY_DIALOG_QUERY = `?calendlyDialogState=open`;

export const DEFAULT_PROFILE_MATCHING_JOB_FILTER_QUERY = '?location=All%20Locations';
