import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { getAgencyId, getDisplayName, getActionButtonLoading, getEmail } from 'reducers';

import { updateUserInfo } from 'actions/agencyProfileActions';

import ProfileCreationCard from 'components/Shared/ProfileCreationCard';
import FormInputField from 'components/Shared/FormInputField';
import FormSelect from 'components/Shared/FormSelect';
import CircularProgress from '@material-ui/core/CircularProgress';

import { validateRequiredFields } from 'utils/formValidations';

import { EMPLOYEE_TEAM_TYPES_ARRAY } from 'constants/employeeConstants';

const TellUsAboutYourself = ({
    displayName,
    agencyId,
    isActionButtonLoading,
    updateUserInfo,
    userEmail,
}) => {
    return (
        <Formik
            initialValues={displayName
                ? { teamType: '' }
                : { name: '', teamType: '' }
            }
            validate={values => {
                const requiredFields = displayName ? ['teamType'] : ['name', 'teamType'];
                return {
                    ...validateRequiredFields(values, requiredFields)
                };
            }}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(false);
                await updateUserInfo(agencyId, {...values, email: userEmail});
            }}
        >
            {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit }) => {
                const isNameRequirementNotMet = errors.name && touched.name;
                const isTeamRequirementNotMet = errors.teamType && touched.teamType;

                return (
                    <>
                        <ProfileCreationCard.ContentBody>
                            <form autoComplete="off">
                                {!displayName &&
                                    <FormInputField
                                        placeholder="Your Name"
                                        name="name"
                                        value={values.name}
                                        showValidationRequirement={isNameRequirementNotMet}
                                        requirementText={errors.name}
                                        isRequirementNotMet={isNameRequirementNotMet}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                }

                                <FormSelect
                                    placeholder="Team"
                                    options={EMPLOYEE_TEAM_TYPES_ARRAY}
                                    showValidationRequirement={isTeamRequirementNotMet}
                                    requirementText={errors.teamType}
                                    isRequirementNotMet={isTeamRequirementNotMet}
                                    handleChange={(value) => setFieldValue('teamType', value)}
                                />
                                <ProfileCreationCard.ContentAction
                                    text={isActionButtonLoading ? <CircularProgress className="white" /> : "Next"}
                                    handleClick={handleSubmit}
                                />
                            </form>
                        </ProfileCreationCard.ContentBody>
                    </>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    displayName: getDisplayName(state),
    isActionButtonLoading: getActionButtonLoading(state),
    userEmail: getEmail(state),
});

const mapDispatchToProps = {
    updateUserInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(TellUsAboutYourself);
