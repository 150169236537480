import React from 'react'
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';

import { ReactComponent as CompanyIcon } from 'assets/icon-company.svg';
import { ReactComponent as LocationIcon } from 'assets/icon-location.svg';
import { ReactComponent as DateAddedIcon } from 'assets/icon-date-added.svg';
import PlaceholderImage from 'assets/placeholder-image.jpg';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

const DesktopCardHeader = ({
    jobOpportunity,
}) => (
    <div className="card-header">
        <Avatar
            variant="square"
            src={jobOpportunity.jobOpportunityImgUrl || jobOpportunity.client?.logoImgUrl || PlaceholderImage}
            className="job-avatar"
        />
        <div className="header-info">
            <div className="job-title">
                <h4>{jobOpportunity.position}</h4>
                {jobOpportunity.isLastViewedJob && <span className="label seen">Seen</span>}
                {jobOpportunity.application?.hasApplied && <span className="label applied">Applied</span>}
            </div>
            <div className="job-company-date-wrapper">
                <div className="job-company">
                    <CompanyIcon className="icon" />
                    <p>{jobOpportunity.client.name}</p>
                </div>
                <div className="job-date">
                    <DateAddedIcon className="icon" />
                    <p>{moment(jobOpportunity.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>
                </div>
            </div>
            <div className="job-location">
                <LocationIcon className="icon" />
                <p>{`${jobOpportunity.geographicLocations?.length ? `Remote (${jobOpportunity.geographicLocations.join(", ")})` : 'Remote'}`}</p>
            </div>
        </div>
    </div>
);

export default DesktopCardHeader;
