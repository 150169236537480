import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as PlacedIcons } from 'assets/employee-status-banner-icons/placed-icons-group.svg';
import { ReactComponent as BenchIcons } from 'assets/employee-status-banner-icons/bench-icons-group.svg';
import { ReactComponent as NonBillableIcons } from 'assets/employee-status-banner-icons/non-billable-project-icons-group.svg';
import { ReactComponent as ManagementTeamIcons } from 'assets/employee-status-banner-icons/management-team-icons-group.svg';
import { ReactComponent as ProjectEndsSoonIcons } from 'assets/employee-status-banner-icons/project-ends-soon-icons-group.svg';
import { BULGARIAN_LABEL } from 'constants/languages';


export const getBannerContent = (occupationStatus, selectedLanguage) => {
    switch (occupationStatus) {
        case 1:
            return {
                TEXT: <Typography className="employee-status-text" variant="h2">
                    {selectedLanguage === BULGARIAN_LABEL
                        ? <Fragment>Служителят е по <span className="bold">проект с плащане</span></Fragment>
                        : <Fragment>The employee <span className="bold">is on billable project</span></Fragment>
                    }
                </Typography>,
                ICONS: <PlacedIcons />
            };
        case 2:
            return {
                TEXT: <Typography className="employee-status-text" variant="h2">
                    {selectedLanguage === BULGARIAN_LABEL
                        ? <Fragment>Служителят е <span className="bold">без проект</span></Fragment>
                        : <Fragment>The employee is currently <span className="bold">on the bench</span></Fragment>
                    }
                </Typography>,
                ICONS: <BenchIcons />
            };
        case 3:
            return {
                TEXT: <Typography className="employee-status-text" variant="h2">
                    {selectedLanguage === BULGARIAN_LABEL
                        ? <Fragment>Служителят е по <span className="bold">проект без плащане</span></Fragment>
                        : <Fragment>The employee is <span className="bold">on non-billable project</span></Fragment>
                    }
                </Typography>,
                ICONS: <NonBillableIcons />
            };
        case 4:
            return {
                TEXT: <Typography className="employee-status-text" variant="h2">
                    {selectedLanguage === BULGARIAN_LABEL
                        ? <Fragment>Служителят е в <span className="bold">лидерския екип</span></Fragment>
                        : <Fragment>The employee is <span className="bold">on the leadership team</span></Fragment>
                    }
                </Typography>,
                ICONS: <ManagementTeamIcons />
            };
        case 5:
            return {
                TEXT: <Typography className="employee-status-text" variant="h2">
                    {selectedLanguage === BULGARIAN_LABEL
                        ? <Fragment>Служителят е по <span className="bold">приключващ скоро проект</span></Fragment>
                        : <Fragment>The employee's <span className="bold">project ends soon</span></Fragment>
                    }
                </Typography>,
                ICONS: <ProjectEndsSoonIcons />
            };
        default:
            return {};
    }
};

