import React from 'react';

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as DownloadTimesheetIcon } from 'assets/download.svg';
import { ReactComponent as DownloadInvoiceIcon } from 'assets/icon-invoice.svg';

import './InvoicePopover.scss';

const InvoicePopover = ({
    handleClose,
    anchorEl,
    downloadTimesheets,
    downloadInvoice,
}) => {
    const handleDownloadTimesheet = (event) => {
        downloadTimesheets();
        handleClose(event);
    };

    const handleDownloadInvoice = (event) => {
        downloadInvoice();
        handleClose(event);
    }

    return (
        <div className="invoice-popover-container">
            <Popover
                className="invoice-options-popover"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List className="popover-list">
                    <ListItem button className="popover-list-item" onClick={handleDownloadTimesheet}>
                        <Typography className="list-item-text" />
                        <div className="invoice-popover-icon"><DownloadTimesheetIcon /></div>
                        <span className="invoice-popover-text">Download Timesheet</span>
                        <Typography />
                    </ListItem>
                    <ListItem button className="popover-list-item" onClick={handleDownloadInvoice}>
                        <Typography className="list-item-text" />
                        <div className="invoice-popover-icon"><DownloadInvoiceIcon /></div>
                        <span className="invoice-popover-text">Download Invoice</span>
                        <Typography />
                    </ListItem>
                </List>
            </Popover>
        </div>
    );
}

export default InvoicePopover;
