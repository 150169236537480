import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import AssignAdminFormView from './AssignAdminFormView';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { Formik } from 'formik';

import './AssignAdminDialog.scss';

const AssignAdminDialog = ({
    isOpen,
    handleAssignNewAdmin,
    handleCloseDialog,
}) => {
    const intl = useIntl();

    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const onDialogClose = (resetForm) => {
        handleCloseDialog()
        resetForm()
        setSelectedEmployees([])
    }

    const handleSelectedEmployees = (result) => {
        setSelectedEmployees(oldArray => {
            if (oldArray.some(x => x._id === result._id)) {
                return oldArray;
            }

            return [...oldArray, result];
        });
    };

    const dialogMaxWidth = 'md';

    return (
        <Formik
            initialValues={{
                searchTerm: ''
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false)
                handleAssignNewAdmin(selectedEmployees);
                resetForm()
                setSelectedEmployees([])
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    className="assign-admin-dialog-container"
                    open={isOpen}
                    onClose={() => onDialogClose(props.resetForm)}
                    fullWidth={true}
                    maxWidth={dialogMaxWidth}
                    dialogTitle={intl.formatMessage({ id: "assign-new-admin" })}
                    onSave={props.handleSubmit}
                >
                    <AssignAdminFormView
                        {...props}
                        handleSelectedEmployees={handleSelectedEmployees}
                        selectedEmployees={selectedEmployees}
                    />
                </CommonFormikDialog>
            }
        </Formik>
    );
};

export default AssignAdminDialog;
