import React from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import CoachingDialogFormView from './CoachingDialogFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { validateInputLength, validateRequiredFields, validateCalenldyURL } from 'utils/formValidations';
import { MAX_TITLE_LENGTH, MAX_DESCRIPTION_LENGTH } from './CoachingDialogConstants';

import './CoachingDialog.scss';

const CoachingDialog = ({
    agencyId,
    openDialog,
    handleCloseDialog,
    editMode,
    tags,
    handleAddTag,
    handleRemoveTag,
    languages,
    languagesSuggestions,
    handleAddLanguage,
    handleRemoveLanguage,
    imageSelectedHandler,
    currentImageSelected,
    setCurrentImgSelected,
    createCoachingOpportunity,
    imageFile,
    fetchTagsSuggestions,
    fetchStaticLanguagesSuggestions,
    selectedOpportunity,
    editCoachingOpportunity,
}) => {
    const intl = useIntl();

    return (
        <Formik
            initialValues={editMode ? {
                title: selectedOpportunity.title,
                description: selectedOpportunity.description,
                coachName: selectedOpportunity.coachName,
                aboutTheCoach: selectedOpportunity.aboutTheCoach,
                calendlyUrl: selectedOpportunity.calendlyUrl,
                calendlyApiKey: selectedOpportunity.calendlyApiKey,
                calendlyPersonalAccessToken: selectedOpportunity.calendlyPersonalAccessToken,
                language: '',
                tag: '',
            } : {
                title: '',
                description: '',
                coachName: '',
                aboutTheCoach: '',
                calendlyUrl: '',
                calendlyApiKey: '',
                calendlyPersonalAccessToken: '',
                language: '',
                tag: '',
            }
            }
            enableReinitialize={true}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['title', 'description', 'coachName', 'aboutTheCoach', 'calendlyUrl', 'calendlyApiKey', 'calendlyPersonalAccessToken']),
                    ...validateCalenldyURL(values, 'calendlyUrl', false),
                    ...validateInputLength(values, [
                        { property: 'title', maxLength: MAX_TITLE_LENGTH },
                        { property: 'description', maxLength: MAX_DESCRIPTION_LENGTH },
                        { property: 'coachName', maxLength: MAX_TITLE_LENGTH },
                        { property: 'aboutTheCoach', maxLength: MAX_DESCRIPTION_LENGTH },
                    ])
                }
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                values.tags = tags;
                values.languages = languages;
                delete values.tag;
                delete values.language;

                const formData = new FormData();

                formData.append('coachingOpportunityInfo', JSON.stringify({ ...values }));

                if (imageFile) {
                    formData.append('imageFile', imageFile);
                } else if (!imageFile && !currentImageSelected) {
                    formData.append('imageFile', null);
                }

                editMode ? editCoachingOpportunity(agencyId, selectedOpportunity._id, formData) : createCoachingOpportunity(agencyId, formData);

                handleCloseDialog();
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    className="add-coaching-opportunity-dialog"
                    open={openDialog}
                    onClose={handleCloseDialog}
                    fullWidth={true}
                    maxWidth='sm'
                    onSave={props.handleSubmit}
                    dialogTitle={editMode ? intl.formatMessage({ id: "edit-coaching-opportunity" }) : intl.formatMessage({ id: "add-new-coaching-opportunity" })}
                >
                    <CoachingDialogFormView
                        {...props}
                        agencyId={agencyId}
                        tags={tags}
                        handleAddTag={handleAddTag}
                        handleRemoveTag={handleRemoveTag}
                        languages={languages}
                        languagesSuggestions={languagesSuggestions}
                        handleAddLanguage={handleAddLanguage}
                        handleRemoveLanguage={handleRemoveLanguage}
                        imageSelectedHandler={imageSelectedHandler}
                        currentImageSelected={currentImageSelected}
                        setCurrentImgSelected={setCurrentImgSelected}
                        fetchTagsSuggestions={fetchTagsSuggestions}
                        fetchStaticLanguagesSuggestions={fetchStaticLanguagesSuggestions}
                    />
                </CommonFormikDialog>
            }
        </Formik>
    )
};

export default CoachingDialog;
