import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    isAdminMode,
    isCandidateMode,
    isClientMode,
    getParticipantInterviewInvitations,
    getUpcomingInterviewsList,
    getCandidateInterviewInvitationsDataFetchState
} from 'reducers';

import { fetchCandidateInterviewInvitations, updateInterviewDate } from 'actions/interviewInvitationActions';

import InterviewInvitationTable from '../Shared/InterviewInvitations/InterviewInvitationTable';
import UpcomingInterviewsTable from '../Shared/InterviewInvitations/UpcomingInterviewsTable';

const UpcomingInterviews = ({
    userId,
    agencyId,
    fetchCandidateInterviewInvitations,
    isClientMode,
    interviewInvitations,
    updateInterviewDate,
    upcomingInterviews,
    dataFetchState,
    isDataAvailable,
}) => {
    useEffect(() => {
        if (!isDataAvailable) {
            fetchCandidateInterviewInvitations(agencyId, userId);
        }
    }, []);

    return (
        <div className="my-applications-wrapper">
            {dataFetchState?.isDataFetching && !dataFetchState?.isDataFetched
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <div>
                    <InterviewInvitationTable
                        userId={userId}
                        agencyId={agencyId}
                        isClientMode={isClientMode}
                        interviewInvitations={interviewInvitations}
                        updateInterviewDate={updateInterviewDate}
                    />
                    <UpcomingInterviewsTable
                        upcomingInterviews={upcomingInterviews}
                    />
                </div>
            }
        </div>
    )
};

const mapStateToProps = state => ({
    isAdminMode: isAdminMode(state),
    isCandidateMode: isCandidateMode(state),
    isClientMode: isClientMode(state),
    interviewInvitations: getParticipantInterviewInvitations(state),
    upcomingInterviews: getUpcomingInterviewsList(state),
    dataFetchState: getCandidateInterviewInvitationsDataFetchState(state),
});

const mapDispatchToProps = {
    fetchCandidateInterviewInvitations,
    updateInterviewDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingInterviews);
