import React from 'react';

import TrainingsField from './TrainingField';

const Trainings = ({
    history
}) => {
    return (
        <div className="trainings-wrapper">
            <TrainingsField history={history}/>
        </div>
    )
};

export default Trainings;
