export const tableColumns = [
    { name: 'Contract Name', value: 'title', isSortable: true, langKey: "name-text" },
    { name: 'Period', value: 'startDate', isSortable: true, langKey: "period" },
    { name: 'Type', value: 'type', isSortable: true, langKey: "type" },
    { name: 'Upload Date', value: 'uploadDate', isSortable: true, langKey: "upload-date" },
    { name: 'Status', value: 'signatureStatus', isSortable: true, langKey: "status" },
];

export const defaultTableColumns = [
    'title',
    'startDate',
    'type',
    'uploadDate',
    'signatureStatus',
];


export const SORT_QUERY_SELECTOR = 'sort';
export const PAGE_QUERY_SELECTOR = 'page';
export const SEARCH_QUERY_SELECTOR = 'search';


export const TABLE_INITIAL_QUERY = {
    page: 1,
    search: "",
    sortBy: "uploadDate",
    sortByDir: "desc",
    pagesToLoad: 5,
    itemsPerPage: 25,
};

export const TABLE_QUERY_MAP = {
    page: PAGE_QUERY_SELECTOR,
    search: SEARCH_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};
