import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { fetchJobOpportunityPage } from 'actions/jobOpportunityActions';
import {
    getDisplayMode,
    isAdmin,
    isClient,
    isSupplierAdmin,
    getJobOpportunityPageDataFetchState,
    getJobOpportunityDetails,
    getAllInterviewInvitations,
    getAppliedConsultantsList,
    getAppliedCandidatesCountByStatus,
    getHistoricalNotes,
    getAgencyName,
    getDisplayName,
} from 'reducers';

import { getStateAgencyName } from 'utils/helpers';

import CircularProgress from '@material-ui/core/CircularProgress';
import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import JobDetails from './JobDetails';
import UpcomingInterviews from 'components/JobPage/UpcomingInterviews';
import AppliedCandidates from 'components/JobPage/AppliedCandidates';
import HistoricalNotes from 'components/Shared/HistoricalNotes';
import JobOptionsBanner from './JobOptionsBanner';

import { BACK_BUTTON_TEXT } from './JobPageConstants';
import { INITIAL_QUERY_STRING_JOBS_DASHBOARD } from 'components/JobsDashboard/JobsDashboardConstants';
import { HISTORICAL_NOTES_TYPES } from 'components/Shared/HistoricalNotes/HistoricalNotesConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './JobPage.scss';

const JobPage = ({
    match,
    agencyId,
    displayMode,
    isAdmin,
    isClient,
    isSupplierAdmin,
    dataFetchState,
    job,
    interviewInvitations,
    historicalNotes,
    fetchJobOpportunityPage,
    backPath,
    agencyName,
    displayName,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const { jobId } = match.params;

    useEffect(() => {
        fetchJobOpportunityPage(agencyId, jobId);
    }, [history.location.pathname]);

    const handlePreviousButtonClick = () => {
        if (backPath) {
            history.push(backPath);
        } else {
            history.push(`/${getStateAgencyName()}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`);
        }
    };

    return (
        <>
            {dataFetchState?.isDataFetching
                ? <CircularProgress size={50} className="page-loader" disableShrink />
                : <div className="job-page-wrapper">
                    <PreviousButton
                        handleClick={handlePreviousButtonClick}
                        text={intl.formatMessage({ id: convertStringToLanguageKey(BACK_BUTTON_TEXT[displayMode]) })}
                    />

                    <JobDetails
                        agencyId={agencyId}
                        isAdmin={isAdmin}
                        isClient={isClient}
                        job={job}
                    />

                    {isAdmin &&
                        <HistoricalNotes
                            targetId={jobId}
                            historicalNotes={historicalNotes}
                            noteType={HISTORICAL_NOTES_TYPES.JOB_OPPORTUNITY}
                        />
                    }

                    {(isAdmin || isSupplierAdmin) &&
                        <JobOptionsBanner
                            job={job}
                            jobId={jobId}
                            isAdmin={isAdmin}
                            agencyName={agencyName}
                            displayName={displayName}
                        />
                    }

                    <AppliedCandidates
                        dataSelector={getAppliedConsultantsList}
                        statusCountSelector={getAppliedCandidatesCountByStatus}
                        displayMode={displayMode}
                        isAdmin={isAdmin}
                        isSupplierAdmin={isSupplierAdmin}
                        agencyId={agencyId}
                    />

                    {!isSupplierAdmin &&
                        <UpcomingInterviews
                            interviewInvitations={interviewInvitations}
                        />
                    }
                </div>
            }
        </>
    )
};

const mapStateToProps = (state) => ({
    displayMode: getDisplayMode(state),
    isAdmin: isAdmin(state),
    isClient: isClient(state),
    isSupplierAdmin: isSupplierAdmin(state),
    dataFetchState: getJobOpportunityPageDataFetchState(state),
    job: getJobOpportunityDetails(state),
    interviewInvitations: getAllInterviewInvitations(state),
    historicalNotes: getHistoricalNotes(state).notes,
    agencyName: getAgencyName(state),
    displayName: getDisplayName(state),
});

const mapDispatchToProps = {
    fetchJobOpportunityPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobPage);
