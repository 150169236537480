export const TABLE_COLUMNS = [
    { name: 'Name', value: 'name', isSortable: true, langKey: "name-text" },
    { name: 'Contact', value: 'contactPerson', isSortable: true, langKey: "contact" },
    { name: 'Account Manager', value: 'accountManager', isSortable: true, langKey: "account-manager" },
];

export const DEFAULT_TABLE_COLUMNS = [
    'name',
    'contactPerson',
    'accountManager',
];

export const TABLE_FILTERS = [
    { key: 'accountManager', name: 'Account Manager', value: [], hasOnlyKeyTranslation: true }
];

export const SORT_QUERY_SELECTOR = 'sort';
export const PAGE_QUERY_SELECTOR = 'page';
export const SEARCH_QUERY_SELECTOR = 'search';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const TABLE_INITIAL_QUERY = {
    filters: JSON.stringify({}),
    page: 1,
    search: '',
    sortBy: 'name',
    sortByDir: 'asc',
    pagesToLoad: 5,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
};

export const TABLE_QUERY_MAP = {
    filters: 'filters',
    page: PAGE_QUERY_SELECTOR,
    search: SEARCH_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};
