import React, { useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

import ConfirmDialog from 'components/Shared/ConfirmDialog';

import MuiDialog from '@material-ui/core/Dialog';

const Dialog = ({ children, onClose, open, ...rest }) => {
    const { dirty, resetForm } = useFormikContext();
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const dialogRef = useRef(null);

    useEffect(() => {
        if (!open && dialogRef.current) {
            setTimeout(() => {
                resetForm();
            }, 100);
        }
    }, [open]);

    const handleCloseConfirmationDialog = () => setIsConfirmationDialogOpen(false);

    const handleCloseDialog = () => {
        if (dirty) {
            setIsConfirmationDialogOpen(true);
        } else {
            onClose();
        }
    }

    const handleConfirmDialog = () => {
        onClose();
        handleCloseConfirmationDialog();
    }

    return (
        <>
            <MuiDialog open={open} onClose={handleCloseDialog} ref={dialogRef} maxWidth="xl" {...rest}>
                {children}
            </MuiDialog>
            <ConfirmDialog
                openDialog={isConfirmationDialogOpen}
                dialogTitle='You have unsaved changes. Are you sure you want to continue?'
                confirmButtonName='Yes'
                discardButtonName='No'
                handleConfirmDialog={handleConfirmDialog}
                handleCloseDialog={handleCloseConfirmationDialog}
            />
        </>
    );
};

export default Dialog;
