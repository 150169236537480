import React from 'react';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';

import './VideoCard.scss';

const VideoCard = ({
    item,
    removeItem,
    isAdmin,
}) => {
    return (
        <div className="video-card">
            <iframe
                width="100%"
                height="350" 
                src={`https://www.youtube.com/embed/${item.videoId}`}
                frameBorder='0'
                allow='autoplay; encrypted-media'
                allowFullScreen
            />
            {isAdmin &&
                <CancelSharpIcon className="row-remove-icon" onClick={() => removeItem(item)} />
            }
        </div>
    )
};

export default VideoCard;
