import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Avatar, IconButton } from '@material-ui/core';
import { ReactComponent as HobbiesIcon } from 'assets/hobbies-icon.svg';
import { ReactComponent as FunFactIcon } from 'assets/fun-fact-icon.svg';

import defaultAvatar from 'assets/dummy-avatar.jpg'

import './KnowYourTeamCard.scss';

const KnowYourTeamCard = ({
    employee
}) => (
    <Paper className="team-member-card">
        <div className="team-member-card-details-container">
            <div className="team-member-header-block">
                <span className="left-semicircle"></span>
                <span className="right-semicircle"></span>
                <div className="header-block-content">
                    <Avatar
                        alt="Emplyoee Avatar"
                        className="profile-image"
                        src={employee.profilePictureImgUrl}
                        onError={e => { e.target.src = defaultAvatar }}
                    />
                    <div className="name-and-position">
                        <Typography variant="h5" className="team-member-name">{employee.firstName} {employee.lastName}</Typography>
                        <Typography variant="h5" className="team-member-position">{employee.position}</Typography>
                    </div>
                </div>
                <div className="team-member-social">
                    { employee.personalInfo?.linkedIn &&
                        <IconButton className="social-icon linkedIn" href={employee.personalInfo?.linkedIn} target="_blank" rel="noopener noreferrer">
                            <LinkedInIcon />
                        </IconButton>
                    }
                </div>
            </div>
            { employee.personalInfo?.hobbies && 
                <div className="team-member-hobbies">
                    <div className="hobbies-wrapper">
                        <HobbiesIcon />
                        <Typography variant="h6" className="title">Hobbies</Typography>
                    </div>
                    <div className="content">{employee.personalInfo?.hobbies}</div>
                </div>
            }
            { employee.personalInfo?.funFact &&
                <div className="team-member-fun-fact">
                    <div className="fun-facts-wrapper">
                        <FunFactIcon />
                        <Typography variant="h6" className="title">Fun Fact</Typography>
                    </div>
                    <div className="content">{employee.personalInfo?.funFact}</div>
                </div>
            }
        </div>
    </Paper>
);

export default KnowYourTeamCard;
