import React from 'react';
import { useIntl } from 'react-intl';

import ConfirmDialog from '../ConfirmDialog';
import { CONFIRM_DIALOG_INVITE_USER_CONFIRMATION_ITEMS } from '../ConfirmDialog/ConfirmDialogConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './ConfirmInviteUserDialog.scss';

const ConfirmInviteUserDialog = ({
    openDialog,
    invitedUser,
    handleInviteUser,
    handleCloseDialog,
}) => {
    const intl = useIntl();

    return (
        <ConfirmDialog
            openDialog={openDialog}
            dialogTitle={intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_INVITE_USER_CONFIRMATION_ITEMS.GET_DIALOG_TITLE(invitedUser)) })}
            confirmButtonName={intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_INVITE_USER_CONFIRMATION_ITEMS.CONFIRM_BUTTON_NAME) })}
            discardButtonName={intl.formatMessage({ id: convertStringToLanguageKey(CONFIRM_DIALOG_INVITE_USER_CONFIRMATION_ITEMS.DISCARD_BUTTON_NAME) })}
            handleConfirmDialog={handleInviteUser}
            handleCloseDialog={handleCloseDialog}
        />
    )
};

export default ConfirmInviteUserDialog;
