import React from 'react';
import { connect } from 'react-redux';

import {
    getAgencyId,
    getUserId,
    getAgencyDocuments,
    getOwnEmployeesDocuments,
} from 'reducers';

import {
    fetchAgencyDocuments,
    exportDocumentsData,
    downloadDocumentsFiles
} from 'actions/documentActions';
import { fetchManagerEmployeesDocuments, downloadManagersEmployeesDocumentsFiles } from 'actions/managerActions';
import { fetchContractFilesUrl } from 'actions/contractActions';
import { showNotification } from 'actions/notificationActions';

import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

import TeamDocumentsTable from './TeamDocumentsTable';

const TeamDocuments = ({
    agencyId,
    userId,
    fetchDocuments,
    downloadDocumentsFiles,
    showNotification,
    isAccessAsManager,
    fetchContractFilesUrl
}) => {
    const action = isAccessAsManager ? fetchDocuments.bind(null, agencyId, userId) : fetchDocuments.bind(null, agencyId);
    const dataSelector = (state, page) => isAccessAsManager ? getOwnEmployeesDocuments(state, page - 1, DEFAULT_ITEMS_PER_PAGE).slicedFilteredDocuments : getAgencyDocuments(state, page - 1, DEFAULT_ITEMS_PER_PAGE).slicedFilteredDocuments;
    
    return (
        <div className="team-documents-wrapper">
            <TeamDocumentsTable
                userId={userId}
                agencyId={agencyId}
                exportDocumentsData={exportDocumentsData}
                downloadDocumentsFiles={downloadDocumentsFiles}
                fetchContractFilesUrl={fetchContractFilesUrl}
                showNotification={showNotification}
                dataSelector={dataSelector}
                isAccessAsManager={isAccessAsManager}
                action={action}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    userId: getUserId(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchDocuments: (...args) => ownProps.isAccessAsManager ? dispatch(fetchManagerEmployeesDocuments(...args)) : dispatch(fetchAgencyDocuments(...args)),
    downloadDocumentsFiles: (...args) => ownProps.isAccessAsManager ? dispatch(downloadManagersEmployeesDocumentsFiles(...args)) : dispatch(downloadDocumentsFiles(...args)),
    showNotification: (...args) => dispatch(showNotification(...args)),
    fetchContractFilesUrl: (...args) => dispatch(fetchContractFilesUrl(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDocuments);
