import React from 'react';

import DashboardSectionWrapper from '../../SharedDashboardComponents/DashboardSectionWrapper';
import AppliedCandidate from 'components/JobPage/AppliedCandidates/AppliedCandidatesList/AppliedCandidate';

const ClientProfiles = ({
    appliedCandidates,
    agencyName,
}) => {

    return (
        <DashboardSectionWrapper>
            <DashboardSectionWrapper.SectionTitle>New Job Applicants</DashboardSectionWrapper.SectionTitle>

            <DashboardSectionWrapper.SectionSubTitle>
                These profiles require your review. You can invite them to an interview, or hire and reject them.
            </DashboardSectionWrapper.SectionSubTitle>

            <DashboardSectionWrapper.SectionContent classes={['list']}>
                {appliedCandidates.map((candidate, index) => {
                    return (
                        <AppliedCandidate
                            key={(candidate._id + index)}
                            candidate={candidate}
                            agencyName={agencyName}
                            showStatusLabel={false}
                            showNotes={false}
                        />
                    )
                })}
            </DashboardSectionWrapper.SectionContent>
        </DashboardSectionWrapper>
    );
};

export default ClientProfiles;
