import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getProfileCreationStepsCount, isTermsAndConditions } from 'reducers';

import ProfileCreationCard from 'components/Shared/ProfileCreationCard';
import CompletionProgressBar from 'components/Shared/CompletionProgressBar';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as ConfettiIcon } from 'assets/register-confetti-icon.svg'

import { getHomePath } from 'utils/navigationUtils';
import { useSessionStorage } from 'utils/hooks';

import { AGENCY_PROFILE_CREATION_STEPS_TO_COMPLETE } from 'constants/agencyConstants';

import './StartTrial.scss';

const StartTrial = ({
    isTermsAndConditionsAccepted,
    profileCreationStepsCount,
}) => {
    const history = useHistory();
    const [showStartTrial, , clearShowStartTrial] = useSessionStorage('showStartTrial');
    const percentage = (profileCreationStepsCount + isTermsAndConditionsAccepted) / AGENCY_PROFILE_CREATION_STEPS_TO_COMPLETE * 100;

    const redirectToHomePath = () => {
        const homePath = getHomePath(history.location.state?.gotoUrl);
        history.push({ pathname: homePath.pathname, state: { ...history.location.state, ...homePath.state } });
    };

    useEffect(() => {
        if (!showStartTrial) {
            redirectToHomePath();
        }

        return () => clearShowStartTrial();
    }, []);

    const handleSeeDashboardClick = () => {
        clearShowStartTrial();
        redirectToHomePath();
    };

    return (
        <ProfileCreationCard>
            <ProfileCreationCard.Header />
            <CompletionProgressBar percentage={percentage} />
            <ProfileCreationCard.ContentWrapper>
                <ProfileCreationCard.ContentTitle>
                    Thanks for registering!
                </ProfileCreationCard.ContentTitle>
                <ProfileCreationCard.ContentDivider />
                <ProfileCreationCard.ContentBody className="full-width">
                    <div className="start-trial-wrapper">
                        <Typography className="start-trial-text">
                            Your 14-day free trial starts now.
                            <br />
                            Experience first-hand how WIA empowers you and your team, simplifies complex processes and drives unparalleled productivity.
                        </Typography>
                        <div className="start-trial-icon-wrapper">
                            <ConfettiIcon />
                        </div>
                    </div>
                </ProfileCreationCard.ContentBody>
                <ProfileCreationCard.ContentAction text='See Dashboard' handleClick={handleSeeDashboardClick} />
            </ProfileCreationCard.ContentWrapper>
        </ProfileCreationCard>
    );
};

const mapStateToProps = (state) => ({
    isTermsAndConditionsAccepted: isTermsAndConditions(state),
    profileCreationStepsCount: getProfileCreationStepsCount(state),
});

export default connect(mapStateToProps)(StartTrial);
