import React, { useState } from 'react';

import { ReactComponent as CalendarIconAgency } from 'assets/icon-calendar.svg';
import { ReactComponent as CompanyIcon } from 'assets/icon-company.svg';
import { ReactComponent as LocationIcon } from 'assets/icon-location.svg';
import { ReactComponent as NoteIcon } from 'assets/note-icon.svg';

import { candidateStatusMapper } from 'utils/applicationUtils';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './JobCard.scss';

const JobCard = ({
    applicationDetails,
    jobDetails,
}) => {
    const [isJobInfoExpanded, setIsJobInfoExpanded] = useState(false);

    const toggleJobDetails = () => setIsJobInfoExpanded(prevState => !prevState);

    return (
        <div className="job-card-wrapper">
            <div className="card-header">
                <div className={`status ${candidateStatusMapper(applicationDetails.status)?.toLowerCase().split(' ').join('-')}`}>{candidateStatusMapper(applicationDetails.status)}</div>
            </div>
            <div className="card-content">
                <div className="row">
                    <p className="card-title">{applicationDetails.position}</p> 
                    <div className="date-wrapper">
                        <CalendarIconAgency />
                        <p className="date-value">{applicationDetails.date}</p>
                    </div>
                    <div className="job-info-toggle" onClick={toggleJobDetails}>
                        <p>{isJobInfoExpanded ? 'Show less information' : 'Show full job information'}</p>
                        {isJobInfoExpanded
                            ? <KeyboardArrowDownIcon className="toggle-arrow" />
                            : <KeyboardArrowLeftIcon className="toggle-arrow" />
                        }
                    </div>
                </div>
                <div className="row grid-wrapper">
                    <CompanyIcon className="icon" />
                    <p className="card-sub-title">{applicationDetails.company}</p>
                </div>
                <div className="row grid-wrapper">
                    <LocationIcon className="icon" />
                    <p className="card-sub-title">{jobDetails.geographicLocations?.length ? `Remote (${jobDetails.geographicLocations.join(', ')})` : 'Remote'}</p>
                </div>
                {applicationDetails.feedback?.comment &&
                    <>
                        <Divider className="section-divider" />
                        <div className="feedback-wrapper">
                            <div className="feedback-header">
                                <NoteIcon />
                                <h4>Feedback</h4>
                            </div>
                            <div className="feedback-body">
                                {applicationDetails.feedback.comment}
                            </div>
                        </div>
                    </>
                }
            </div>
            {isJobInfoExpanded &&
                <div className="full-job-details-body">
                    {
                        jobDetails.aboutTheClient &&
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2" >
                                About the company
                            </Typography>
                            <p className="job-section-text">{jobDetails.aboutTheClient}</p>
                        </div>
                    }
                    {
                        jobDetails.jobSummary &&
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2" >
                                About the job
                            </Typography>
                            <p className="job-section-text">{jobDetails.jobSummary}</p>
                        </div>
                    }
                    {jobDetails.mandatorySkills?.length > 0 && (
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2">
                                Mandatory Skills
                            </Typography>
                            <div className="skills-container">
                                {jobDetails.mandatorySkills.map((x) => (
                                    <div key={x.name} className="skill-container">
                                        <span className="skill">{x.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {jobDetails.niceToHaveSkills?.length > 0 && (
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2">
                                Nice To Have
                            </Typography>
                            <div className="skills-container">
                                {jobDetails.niceToHaveSkills.map((x) => (
                                    <div key={x.name} className="skill-container">
                                        <span className="skill">{x.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {
                        jobDetails.aboutCandidate &&
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2" >
                                About the candidate
                            </Typography>
                            <p className="job-section-text">{jobDetails.aboutCandidate}</p>
                        </div>
                    }
                    {
                        jobDetails.requirements &&
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2" >
                                Requirements
                            </Typography>
                            <p className="job-section-text">{jobDetails.requirements}</p>
                        </div>
                    }
                    {
                        jobDetails.benefits &&
                        <div className="job-section">
                            <Typography className="job-section-title" variant="body2" >
                                Additional benefits
                            </Typography>
                            <p className="job-section-text">{jobDetails.benefits}</p>
                        </div>
                    }
                </div>
            }
        </div>
    )
};

export default JobCard;
