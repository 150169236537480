import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import moment from 'moment';

import StartCandidateJourneyDesktopPage from './StartCandidateJourneyDesktopPage';
import StartCandidateJourneyMobilePage from './StartCandidateJourneyMobilePage';

import {
    getAgencyId,
    getUserId,
    getMenuInformation,
    getAgencyRouteName,
    isJourneyStarted,
    getCurrentJourneyStepId,
    getJobOpportunityDetails,
    getLastViewedJob,
    isAppLoaderActive,
} from 'reducers';

import { fetchAgencyBasicInfo } from 'actions/agencyActions';

import {
    updateCandidateJourneyStartInformation,
    updateCandidateJourneyCurrentStepId,
    fetchCandidateViewedJob
} from 'actions/candidateActions';

import { START_JOURNEY_STEPS } from './StartCandidateJourneyConstants';

const StartCandidateJourney = ({
    agencyId,
    agencyName,
    userId,
    menuInformation,
    agencyRouteName,
    isJourneyStarted,
    currentStepId,
    updateCandidateJourneyStartInformation,
    updateCandidateJourneyCurrentStepId,
    history,
    fetchCandidateViewedJob,
    jobOpportunity,
    lastViewedJob,
    isAppLoaderActive,
}) => {

    useEffect(() => {
        if (lastViewedJob === null || (lastViewedJob && lastViewedJob !== jobOpportunity?._id)) {
            fetchCandidateViewedJob(agencyId, userId, false);
        }
    }, []);

    const getCandidateJourneyDuration = () => START_JOURNEY_STEPS.reduce((a, c) => a + c.duration, 0);

    const handleStartCandidateJourney = async () => {
        if (!isJourneyStarted) {
            const updateData = { isJourneyStarted: true, startDate: moment.utc().format() }

            await updateCandidateJourneyStartInformation(agencyId, userId, updateData);
        }
        updateCandidateJourneyCurrentStepId(currentStepId);
        history.push({
            pathname: `/${agencyRouteName}/create-profile`,
            state: { comingFrom: 'start-journey' }
        });
    };

    const isWithOpportunity = (history.location.state?.jobOpportunityId || lastViewedJob) && jobOpportunity?._id;
    const showContent = !isAppLoaderActive && lastViewedJob !== null;

    return (
        <>
            {
                isMobile
                    ? <StartCandidateJourneyMobilePage
                        getCandidateJourneyDuration={getCandidateJourneyDuration}
                        START_JOURNEY_STEPS={START_JOURNEY_STEPS}
                        handleStartCandidateJourney={handleStartCandidateJourney}
                        agencyName={agencyName}
                        jobOpportunity={jobOpportunity}
                        isWithOpportunity={isWithOpportunity}
                        showContent={showContent}
                    />
                    : <StartCandidateJourneyDesktopPage
                        menuInformation={menuInformation}
                        getCandidateJourneyDuration={getCandidateJourneyDuration}
                        START_JOURNEY_STEPS={START_JOURNEY_STEPS}
                        handleStartCandidateJourney={handleStartCandidateJourney}
                        agencyName={agencyName}
                        jobOpportunity={jobOpportunity}
                        isWithOpportunity={isWithOpportunity}
                        showContent={showContent}
                    />
            }
        </>
    );
};

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    userId: getUserId(state),
    menuInformation: getMenuInformation(state),
    agencyRouteName: getAgencyRouteName(state),
    isJourneyStarted: isJourneyStarted(state),
    currentStepId: getCurrentJourneyStepId(state),
    jobOpportunity: getJobOpportunityDetails(state),
    lastViewedJob: getLastViewedJob(state),
    isAppLoaderActive: isAppLoaderActive(state),
});

const mapDispatchToProps = {
    updateCandidateJourneyStartInformation,
    updateCandidateJourneyCurrentStepId,
    fetchCandidateViewedJob,
    fetchAgencyBasicInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartCandidateJourney);
