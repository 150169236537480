import React from 'react';

import './GuestLayoutSlide.scss';

const GuestLayoutSlide = ({
    slideData,
}) => {

    return (
        <div className="slide-wrapper">
            <div className="green-square" />

            <div className="image-container">
                <slideData.image />
                <div className="purple-square" />
            </div>

            <div className="slide-content">
                <div className="title">
                    {slideData.title}
                </div>
                <div className="text">
                    {slideData.text}
                </div>
            </div>
        </div>
    )
};

export default GuestLayoutSlide;
