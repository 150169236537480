import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import RegisterAgencyCandidateFormView from './RegisterAgencyCandidateFormView';
import RegisterFooter from '../RegisterFooter';
import AuthCard from 'components/Shared/AuthCard';
import Grid from '@material-ui/core/Grid';

import { getActionButtonLoading, getSSOLoginLoading } from 'reducers';

import { registerCandidate, login, ssoLoginSuccess } from 'actions/authActions';
import { createSSOLoggingRecord } from 'actions/loggingActions';
import { showActionButtonLoader, hideActionButtonLoader } from 'actions/sectionsLoadersActions';

import { signInAgencyCandidateWithPopup } from 'utils/firebase';
import {
    validateEmailAddress,
    validatePasswordRequirements,
    validateRequiredFields
} from 'utils/formValidations';
import { logEvent } from 'utils/amplitude';
import { getHomePath } from 'utils/navigationUtils';

import { ROLES, REGISTRATION_TYPES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

import './RegisterAgencyCandidate.scss';

const RegisterAgencyCandidate = ({
    isActionButtonLoading,
    ssoLoginLoading,
    registerCandidate,
    login,
    ssoLoginSuccess,
    createSSOLoggingRecord,
    showActionButtonLoader,
    hideActionButtonLoader
}) => {
    const history = useHistory();
    const { agencyData } = useParams();

    const agencyInfo = JSON.parse(atob(decodeURIComponent(agencyData)));

    useEffect(() => {
        if (!agencyInfo || !agencyInfo.agencyName || !agencyInfo.agencyId) {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    const handleSignUp = async values => {
        try {
            showActionButtonLoader();
            const { confirmPassword, ...rest } = values;

            const userData = {
                ...rest,
                role: ROLES.CANDIDATE,
                registrationType: REGISTRATION_TYPES.SELF_REGISTERED,
                agencyId: agencyInfo.agencyId,
                email: values.email.toLowerCase().trim(),
            };

            await registerCandidate(userData);
            await login(userData.email, values.password, false);
        } finally {
            hideActionButtonLoader();
        }
    };

    const handleSSOSignIn = async event => {
        event.preventDefault();
        /* 
        This will prevent redirecting from the isGuest component,
        so we can redirect manually after successful login.
        */
        history.replace({ state: { isFromCandidateRegistration: true } });
        try {
            ssoLoginSuccess(false);
            const user = await signInAgencyCandidateWithPopup(agencyInfo.agencyId);
            user.isNew && logEvent(AMPLITUDE_EVENT_TYPES.REGISTER, null, { role: user.role, type: 'SSO' });
            await login(user.email, null, false, false, true, user.credential);
            const homePath = getHomePath(history.location.state?.gotoUrl);
            history.push({ pathname: homePath.pathname, state: user.isNew ? { ...history.location.state, ...homePath.state } : {} });
        } catch (err) {
            createSSOLoggingRecord({ message: err.message });
        } finally {
            ssoLoginSuccess(true);
        }
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="register-agency-candidate-wrapper"
        >
            <AuthCard
                title="It's free to join!"
                className="register-agency-candidate-form-wrapper"
            >
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        password: '',
                        isTermsAndConditionsAccepted: false
                    }}
                    validate={values => {
                        return {
                            ...validateRequiredFields(values, ['name', 'email', 'password', 'isTermsAndConditionsAccepted']),
                            ...validateEmailAddress(values, 'email'),
                            ...validatePasswordRequirements(values, 'password'),
                        };
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(false);
                        await handleSignUp(values);
                    }}
                >
                    {(props) =>
                        <RegisterAgencyCandidateFormView
                            {...props}
                            isActionButtonLoading={isActionButtonLoading}
                            ssoLoginLoading={ssoLoginLoading}
                            handleSSOSignIn={handleSSOSignIn}
                        />
                    }
                </Formik>
            </AuthCard>
            <RegisterFooter />
        </Grid>
    );
};

const mapStateToProps = state => ({
    isActionButtonLoading: getActionButtonLoading(state),
    ssoLoginLoading: getSSOLoginLoading(state),
});

const mapDispatchToProps = {
    registerCandidate,
    login,
    ssoLoginSuccess,
    createSSOLoggingRecord,
    showActionButtonLoader,
    hideActionButtonLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAgencyCandidate);
