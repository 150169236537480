import React from 'react';
import { useIntl } from 'react-intl';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { ErrorMessage } from 'formik';

import '../PersonalInfoContactForm.scss';

const PersonalInfoCandidateContactFormView = ({
    values,
    errors,
    touched,
    handleChange,
    handleShowHeaderButtons,
    countries,
}) => {
    const intl = useIntl();

    const onChangeInputFieldHandler = (e) => {
        handleChange(e);
        handleShowHeaderButtons();
    }
    return (
        <form className="person-contact-form" autoComplete="off">

            <div className="person-contact-block">
                <div className="personal-info-field">
                    <TextField
                        name="phone"
                        value={values.phone}
                        onChange={onChangeInputFieldHandler}
                        id="person-phone"
                        label={intl.formatMessage({ id: "phone-number" })}
                        error={errors.phone && touched.phone}
                        variant="outlined"
                        fullWidth
                    />
                    <ErrorMessage name="phone" component="div" className="invalid-field-message" />
                </div>
                <FormControl className="personal-info-field" variant="outlined" fullWidth >
                    <InputLabel id="current-location-label">Current Location</InputLabel>
                    <Select
                        labelId="current-location-label"
                        name="currentLocation"
                        value={values.currentLocation}
                        onChange={onChangeInputFieldHandler}
                        id="person-current-location"
                        label={intl.formatMessage({ id: "current-location" })}
                        error={errors.currentLocation && touched.currentLocation}
                    >
                        {countries.map((country, i) => (
                            <MenuItem native="true" key={i} value={country.name}>
                                {country.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <ErrorMessage name="currentLocation" component="div" className="invalid-field-message" />
                </FormControl>
            </div>

            <div className="personal-info-form-field">
                <TextField
                    name="linkedIn"
                    value={values.linkedIn}
                    onChange={onChangeInputFieldHandler}
                    label={intl.formatMessage({ id: "linkedin-profile-url" })}
                    error={errors.linkedIn && touched.linkedIn}
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="linkedIn" component="div" className="invalid-field-message" />
            </div>

            <div className="personal-info-form-field last last">
                <TextField
                    name="gitHub"
                    value={values.gitHub}
                    onChange={onChangeInputFieldHandler}
                    label={intl.formatMessage({ id: "github-profile-url" })}
                    error={errors.gitHub && touched.gitHub}
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="gitHub" component="div" className="invalid-field-message" />
            </div>
        </form>
    )
};

export default PersonalInfoCandidateContactFormView
