import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl';

import ButtonBase from '@material-ui/core/ButtonBase';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PerformanceReviewDialog from '../PerformanceReviewDialog';
import PerformanceReviewCard from './PerformanceReviewCard';

import { TAB_NAMES } from '../PerformanceReviewConstants';
import { ROLES } from 'constants/userConstants';

import './PerformanceReviewsHistory.scss';

const PerformanceReviewsHistory = ({
    performanceReviews,
    displayName,
    createReview,
    editReview,
    agencyId,
    performanceCriterias,
    userId,
    employeeId,
    deleteReview,
    role,
    selectedTab,
}) => {
    const intl = useIntl();

    const { id } = useParams();

    const getInitialReview = () => {
        const ratings = performanceCriterias.reduce((acc, criteria) => {
            acc[criteria] = 0;
            return acc;
        }, {});
        return { ratings, summary: "", reviewer: displayName };
    }

    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [performanceToEdit, setPerformanceToEdit] = useState(getInitialReview())

    const handleOpenCreateDialog = () => { setOpenCreateDialog(true) };
    const handleCloseCreateDialog = () => {
        setPerformanceToEdit(getInitialReview());
        setOpenCreateDialog(false)
    };

    const handleOpenEditDialog = performance => {
        if (performance.reviewerId === userId || role === ROLES.ADMIN) {
            setPerformanceToEdit(performance);
            setOpenEditDialog(true);
        }
    }

    const handleCloseEditDialog = () => {
        setPerformanceToEdit(getInitialReview());
        setOpenEditDialog(false);
    };

    const handleDeletePerformanceReview = () => {
        const performanceId = performanceToEdit._id;
        deleteReview(performanceId, id, agencyId);
        setOpenEditDialog(false);
    }

    return (
        <div className="performance-review-wrapper performance-review-history-person">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "review-history" })}
                />

                {(selectedTab.text !== TAB_NAMES.CLIENT_FEEDBACK) && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                            text={intl.formatMessage({ id: "create-review" })}
                            onClick={handleOpenCreateDialog}
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>

            <div className="review-cards-wrapper">
                {performanceReviews.map(card =>
                    <ButtonBase className="performance-review-card-button-base" key={card._id} onClick={() => handleOpenEditDialog(card)}>
                        <PerformanceReviewCard cardInfo={card} />
                    </ButtonBase>
                ).reverse()}
            </div>

            <PerformanceReviewDialog
                openDialog={openEditDialog || openCreateDialog}
                handleCloseDialog={openEditDialog ? handleCloseEditDialog : handleCloseCreateDialog}
                displayName={displayName}
                handleReview={openEditDialog ? editReview : createReview}
                agencyId={agencyId}
                performanceCriterias={performanceCriterias}
                performanceToEdit={performanceToEdit}
                handleDeletePerformanceReview={handleDeletePerformanceReview}
                editDialog={openEditDialog}
                role={role}
                userId={employeeId}
            />

        </div>
    )
};

export default PerformanceReviewsHistory;
