import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
    fetchHistoricalNotes,
    addHistoricalNote,
    editHistoricalNote,
    deleteHistoricalNote,
} from 'actions/historicalNotesActions';

import { getAgencyId, getHistoricalNotes, getUserId } from 'reducers';

import SectionActionsWrapper from '../SectionActionsWrapper';
import HistoricalNote from './HistoricalNote';
import HistoricalNoteDialog from 'components/Shared/HistoricalNotes/HistoricalNoteDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import NothingToShow from 'components/NothingToShow';

import './HistoricalNotes.scss';

const LIMIT = 100;

const HistoricalNotes = ({
    agencyId,
    userId,
    noteType,
    targetId,
    historicalNotes,
    addHistoricalNote,
    editHistoricalNote,
    deleteHistoricalNote,
    fetchHistoricalNotes,
    isDataAvailable,
    departmentId,
}) => {
    const intl = useIntl();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedHistoricalNote, setSelectedHistoricalNote] = useState({});

    useEffect(() => {
        if (!isDataAvailable) {
            fetchHistoricalNotes(agencyId, targetId, noteType, LIMIT, departmentId);
        }
    }, [])

    //Dialogs behaviour
    const handleOpenDialog = () => setIsDialogOpen(true);

    const handleCloseDialog = () => {
        setSelectedHistoricalNote({});
        setIsEditMode(false);
        setIsDialogOpen(false);
    };

    const handleEditClick = historicalNote => {
        setSelectedHistoricalNote(historicalNote);
        setIsEditMode(true);
        setIsDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setSelectedHistoricalNote({});
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteClick = historicalNote => {
        setSelectedHistoricalNote(historicalNote);
        setIsDeleteDialogOpen(true);
    };

    //Add, edit, delete action handlers
    const handleAddHistoricalNote = async noteData => {
        await addHistoricalNote(agencyId, noteData, departmentId);
    };

    const handleEditHistoricalNote = async noteData => {
        await editHistoricalNote(agencyId, selectedHistoricalNote._id, noteData, departmentId);
    };

    const handleDeleteHistoricalNote = () => {
        deleteHistoricalNote(agencyId, selectedHistoricalNote._id, departmentId);
        handleCloseDeleteDialog();
    };

    return (
        <div className="historical-notes-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.LeftAlignedActions>
                    <SectionActionsWrapper.SectionTitle sectionTitle={intl.formatMessage({ id: "historical-notes" })} />
                </SectionActionsWrapper.LeftAlignedActions>
                <SectionActionsWrapper.RightAlignedActions>
                    <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                        text={intl.formatMessage({ id: "create-new" })}
                        className="create-new-historical-note-button"
                        onClick={handleOpenDialog}
                    />
                </SectionActionsWrapper.RightAlignedActions>
            </SectionActionsWrapper>

            <div className="historical-notes-items">
                {historicalNotes?.length 
                    ? historicalNotes.map(historicalNote =>
                        <HistoricalNote
                            key={historicalNote._id}
                            historicalNote={historicalNote}
                            handleEditClick={handleEditClick}
                            handleDeleteClick={handleDeleteClick}
                        />
                    )
                    : <NothingToShow items="historical notes" />
                }
            </div>

            {isDialogOpen &&
                <HistoricalNoteDialog
                    agencyId={agencyId}
                    userId={userId}
                    noteType={noteType}
                    targetId={targetId}
                    jobHistoricalNote={selectedHistoricalNote}
                    handleAddHistoricalNote={handleAddHistoricalNote}
                    handleEditHistoricalNote={handleEditHistoricalNote}
                    handleCloseDialog={handleCloseDialog}
                    isDialogOpen={isDialogOpen}
                    isEditMode={isEditMode}
                />
            }

            {isDeleteDialogOpen &&
                <ConfirmDeleteDialog
                    itemToDelete="this note"
                    openDialog={isDeleteDialogOpen}
                    handleDeleteItem={handleDeleteHistoricalNote}
                    handleCloseDialog={handleCloseDeleteDialog}
                />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    agencyId: getAgencyId(state),
    historicalNotes: getHistoricalNotes(state).notes,
});

const mapDispatchToProps = {
    addHistoricalNote,
    editHistoricalNote,
    deleteHistoricalNote,
    fetchHistoricalNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalNotes);
