import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { deleteJob, updateJob, updateJobOpportunityPrivacy } from 'actions/jobOpportunityActions';

import { getStateAgencyName } from 'utils/helpers';

import ThreeDotsIcon from '@material-ui/icons/MoreHoriz';
import JobStatusLabel from '../../SharedComponents/JobStatusLabel';
import JobCandidatesStatusBox from '../../SharedComponents/JobCandidatesStatusBox';
import JobOptionsPopover from '../../SharedComponents/JobOptionsPopover';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import JobTypeLabel from '../../SharedComponents/JobTypeLabel';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { JOB_OPPORTUNITIES_PRIVACY } from 'constants/jobOpportunitiesConstants';

import { ReactComponent as CompanyIcon } from 'assets/icon-company.svg';
import { ReactComponent as DateAddedIcon } from 'assets/icon-date-added.svg';
import { ReactComponent as AccountIcon } from 'assets/icon-account.svg';
import { ReactComponent as LocationIcon } from 'assets/icon-location.svg';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './Job.scss'

const Job = ({
    agencyId,
    onEditClick,
    job,
    updateJobOpportunityPrivacy,
    updateJob,
    deleteJob,
    isAdmin,
    getPathToJobDetails,
    isSupplierAdmin,
    setClosePopoverFunction,
}) => {
    const intl = useIntl();
    const pathToJobDetails = getPathToJobDetails ? getPathToJobDetails(job._id) : `/${getStateAgencyName()}/job-page/${job._id}`;

    const [anchorEl, setAnchorEl] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const handleClosePopover = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
        setClosePopoverFunction(() => () => void 0);
    };

    const handleDotsClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setClosePopoverFunction(() => () => setAnchorEl(null));
    };

    const handleDeleteJob = () => {
        deleteJob(agencyId, job._id, job.jobType);
        handleCloseDeleteDialog();
    };

    const handleOpenDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
        setAnchorEl(null);
    };

    const handleCloseDeleteDialog = () => setIsDeleteDialogOpen(false);

    const jobPrivacyValue = Object.values(JOB_OPPORTUNITIES_PRIVACY).find(x => x._id === job.privacy)?.value;

    return (
        <>
            <div className={`job-box ${job.jobType.toLowerCase()}`} >
                <div className={`job-box-content`}>
                    <JobTypeLabel jobType={job.jobType} />
                    <div className="job-box-body">
                        <div className="basic-job-info-left">
                            {isAdmin
                                ? <>
                                    <h2>
                                        <Link className="job-details-link" to={pathToJobDetails}>
                                            {job.position}
                                        </Link>
                                    </h2>
                                    <div className="row-items justify-start">
                                        <div className="row-item">
                                            <CompanyIcon />
                                            <span>{job.client?.name}</span>
                                        </div>
                                        <div className="row-item">
                                            <DateAddedIcon />
                                            <span>{moment(job.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span>
                                        </div>
                                        <div className="row-item">
                                            <AccountIcon />
                                            <span>{job.creator?.name}</span>
                                        </div>
                                    </div>
                                    <div className="row-item location">
                                        <LocationIcon />
                                        <span>{intl.formatMessage({ id: "remote" })} {job.geographicLocations?.length > 0 && `(${job.geographicLocations.map(x => intl.formatMessage({ id: convertStringToLanguageKey(x) })).join(', ')})`}</span>
                                    </div>
                                </>
                                : <>
                                    <div className="row-items">
                                        <h2>
                                            <Link className="job-details-link" to={pathToJobDetails}>
                                                {job.position}
                                            </Link>
                                        </h2>
                                        <div className="row-item">
                                            <DateAddedIcon />
                                            <span>{moment(job.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span>
                                        </div>
                                        <div className="row-item">
                                            <JobStatusLabel status={job.status} />
                                        </div>
                                    </div>
                                    <div className="row-item location">
                                        <LocationIcon />
                                        <span>Remote {job.geographicLocations?.length > 0 && `(${job.geographicLocations.join(', ')})`}</span>
                                    </div>
                                </>
                            }

                        </div>

                        {!isSupplierAdmin &&
                            <div className="basic-job-info-right">
                                <ThreeDotsIcon data-testid="three-dots" className="job-three-dots-action" onClick={(event) => handleDotsClick(event, job)} />
                            </div>
                        }
                    </div>
                    <div className="job-box-footer">
                        <div className="total-profiles-count">
                            <span className="total-profiles-count-number">{job.profilesCount}</span>
                        </div>

                        <div className="candidates-statuses-count-boxes">
                            {job.rejectedApplicationsCount > 0 &&
                                <JobCandidatesStatusBox
                                    classType="rejected-applications"
                                    count={job.rejectedApplicationsCount}
                                />
                            }
                            {job.approvedApplicationsCount > 0 &&
                                <JobCandidatesStatusBox
                                    classType="approved-applications"
                                    count={job.approvedApplicationsCount}
                                />
                            }
                            {job.pendingApplicationsCount > 0 &&
                                <JobCandidatesStatusBox
                                    classType="pending-applications"
                                    count={job.pendingApplicationsCount}
                                />
                            }
                            {job.pendingAdminReviewApplicationsCount > 0 &&
                                <JobCandidatesStatusBox
                                    classType="pending-admin-applications"
                                    count={job.pendingAdminReviewApplicationsCount}
                                />
                            }
                            {job.notVettedApplicationsCount > 0 &&
                                <JobCandidatesStatusBox
                                    classType="not-vetted-applications"
                                    count={job.notVettedApplicationsCount}
                                />
                            }
                            {job.newApplicationsCount > 0 &&
                                <JobCandidatesStatusBox
                                    classType="new-applications"
                                    count={job.newApplicationsCount}
                                />
                            }
                        </div>
                    </div>
                    {isAdmin &&
                        <div className="job-details">
                            <p className={`box ${job.status.toLowerCase()}-status`}>{intl.formatMessage({id: convertStringToLanguageKey(job.status)})}</p>
                            <p className={`box ${jobPrivacyValue.toLowerCase()}-privacy`}>{intl.formatMessage({id: convertStringToLanguageKey(jobPrivacyValue)})}</p>
                            {job.priority && <p className={`box ${job.priority?.toLowerCase()}-priority`}>{intl.formatMessage({ id: convertStringToLanguageKey(job.priority) })} {intl.formatMessage({ id: "priority" })}</p>}
                        </div>
                    }
                </div>
            </div >

            {!isSupplierAdmin &&
                <>
                    <JobOptionsPopover
                        agencyId={agencyId}
                        anchorEl={anchorEl}
                        handleClose={handleClosePopover}
                        onEditClick={onEditClick}
                        updatePrivacy={updateJobOpportunityPrivacy}
                        updateJob={updateJob}
                        selectedJob={job}
                        openDeleteDialog={handleOpenDeleteDialog}
                        isAdmin={isAdmin}
                    />

                    <ConfirmDeleteDialog
                        openDialog={isDeleteDialogOpen}
                        handleCloseDialog={handleCloseDeleteDialog}
                        handleDeleteItem={handleDeleteJob}
                        itemToDelete={job.position}
                    />
                </>
            }
        </>
    );
};

const mapDispatchToProps = {
    deleteJob,
    updateJob,
    updateJobOpportunityPrivacy,
};

export default connect(null, mapDispatchToProps)(Job);
