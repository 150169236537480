import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getDisplayMode } from 'reducers';

import MainLayout from '../Main';

import { ROLES } from 'constants/userConstants';

import './ContentLayout.scss';

const ContentLayout = ({
    children,
    displayMode,
    location,
    backgroundColor = {},
}) => {
    const [isWithSubmenu, setIsWithSubmenu] = useState(false);

    const hideHeader = displayMode === ROLES.CANDIDATE || displayMode === ROLES.SUPPLIER_EMPLOYEE || displayMode === ROLES.EMPLOYEE;

    useEffect(() => {
        setIsWithSubmenu(Boolean(document.getElementsByClassName('submenu-indicator').length))
    }, [location.pathname])

    return (
        <MainLayout hideHeader={hideHeader} backgroundColor={backgroundColor[displayMode] || ''} isWithSubmenu={isWithSubmenu}>
            <div className={`${isWithSubmenu ? 'content-shell with-submenu' : 'content-shell'}`}>
                {children}
            </div>
        </MainLayout>
    )
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
});

export default connect(mapStateToProps)(ContentLayout);
