import React from 'react';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import DialogActions from '@material-ui/core/DialogActions';

import { activeInvoicingFlags } from 'components/Clients/ClientsConstants';


const ActiveInvoicingDialogContent = ({
    selectedFlag,
    handleFlagSelect,
    handleCloseDialog,
    handleFlagUpdate,
}) => (
    <>
        <TextField
            select
            name="country"
            onChange={handleFlagSelect}
            value={selectedFlag.value}
            label="Status"
            margin="normal"
            variant="outlined"
            fullWidth
        >
            {activeInvoicingFlags.map((flag, i) => (
                <MenuItem native="true" key={i} value={flag.value}>
                    {flag.name}
                </MenuItem>
            ))}
        </TextField>
        <DialogActions className="dialog-actions">
            <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text='Discard' />
            <SecondaryButton buttonStyle="save" handleClick={handleFlagUpdate} text='Save' type='submit' />
        </DialogActions>
    </>
);

export default ActiveInvoicingDialogContent;
