import React, { useState } from 'react';

import FormValidationRequirement from '../FormValidationRequirement';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as PasswordEyeIcon } from 'assets/password-eye-icon.svg';
import { ReactComponent as PasswordEyeCrossedIcon } from 'assets/password-eye-crossed-icon.svg';

import './FormInputField.scss';

const FormInputField = ({
    className,
    value,
    name,
    label,
    placeholder,
    type,
    handleChange,
    handleBlur,
    withInputLabel,
    withPasswordVisibility,
    showValidationRequirement,
    requirementText,
    isRequirementMet,
    isRequirementNotMet,
}) => {
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const togglePasswordVisibility = () => setPasswordVisibility(prevState => !prevState);

    return (
        <div className={`form-input-field-wrapper${className ? ` ${className}` : ''}`}>
            {withInputLabel &&
                <InputLabel
                    shrink={false}
                    htmlFor={name}
                >
                    <Typography className="field-label">{label}</Typography>
                </InputLabel>
            }
            <TextField
                required
                fullWidth
                className="field-input"
                name={name}
                type={withPasswordVisibility ? passwordVisibility ? 'text' : 'password' : type || 'text'}
                placeholder={placeholder}
                variant="outlined"
                value={value}
                error={isRequirementNotMet}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                    endAdornment: (
                        withPasswordVisibility && <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisibility}
                                style={{ padding: "0" }}
                            >
                                {passwordVisibility ? <PasswordEyeIcon /> : <PasswordEyeCrossedIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            {showValidationRequirement &&
                <FormValidationRequirement
                    requirementText={requirementText}
                    isRequirementMet={isRequirementMet}
                    isRequirementNotMet={isRequirementNotMet}
                />
            }
        </div>
    );
};

export default FormInputField;
