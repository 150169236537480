import React, { createContext, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import TableAlignedItems from './TableAlignedItems';
import TableCategoryFilter from './TableCategoryFilter';
import TableContent from './TableContent/TableContent';

import { parseQueryString } from 'utils/helpers';

import './TableWrapper.scss';

export const TableWrapperContext = createContext();
const { Provider } = TableWrapperContext;

const TableWrapper = ({
    title,
    columns,
    defaultColumns,
    dataSelector,
    sortQuerySelector,
    adjustKeysFunction,
    isTableDataLoading,
    addMargin,
    withPagination = false,
    pagesCount,
    pageQuerySelector,
    withLegend = false,
    legendItems,
    withActions = false,
    getActionsHandler,
    withLink = false,
    withCheckboxes=false,
    checkedRows,
    setCheckedRows,
    generateToString,
    children,
}) => {
    const [selectedColumns, setSelectedColumns] = useState(defaultColumns);
    const [page, setPage] = useState(1);
    const tableRef = useRef();
    const history = useHistory();

    useEffect(() => {
        const queryParams = parseQueryString(history.location.search, true);
        const currentTableQueryColumns = columns?.map(x => x.value).filter((column) => queryParams.columns?.includes(column));
        if (!currentTableQueryColumns?.length) {
            setSelectedColumns(defaultColumns);
        }
    }, [defaultColumns]);

    const context = {
        title,
        columns,
        selectedColumns,
        setSelectedColumns,
        defaultColumns,
        dataSelector,
        sortQuerySelector,
        adjustKeysFunction,
        isTableDataLoading,
        withPagination,
        page,
        setPage,
        pagesCount,
        pageQuerySelector,
        withLegend,
        legendItems,
        tableRef,
        withActions,
        getActionsHandler,
        withLink,
        withCheckboxes,
        checkedRows,
        setCheckedRows,
        generateToString,
    };

    return (
        <div className={`table-wrapper${addMargin ? ' with-margin' : ''}`} ref={tableRef}>
            <Provider value={context}>
                {children}
            </Provider>
        </div>
    )
};

TableWrapper.TableAlignedItems = TableAlignedItems;
TableWrapper.TableContent = TableContent;
TableWrapper.TableCategoryFilter = TableCategoryFilter;

export default TableWrapper;
