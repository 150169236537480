import React from 'react';
import { useIntl } from 'react-intl';

import EmployeeProjectCard from 'components/Dashboard/EmployeeProjects/EmployeeProjectCardList/EmployeeProjectCard';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import NothingToShow from 'components/NothingToShow';

import './Projects.scss';

const Projects = ({
    clientProjects,
    location,
    history
}) => {
    const intl = useIntl();

    const handleProjectCardClick = () => {
        history.push(`${location.pathname}/projects`)
    };

    return (
        <div className="client-overveiw-projects-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle={intl.formatMessage({ id: "projects-overview" })}
                    className='section-title'
                />
            </SectionActionsWrapper>
            <div className="projects-content">
                {clientProjects?.length 
                    ? clientProjects.map((p, i) => <EmployeeProjectCard handleProjectCardClick={handleProjectCardClick} isClientOverview={true} key={i} project={p} />)
                    : <NothingToShow items="projects" />
                }
            </div>
        </div>
    )
}

export default Projects;
