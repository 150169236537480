import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import { getUserId } from 'reducers';

import { updateUserCompanyInformation } from 'actions/userActions';

import FillInformationFormView from './FillInformationFormView';

import { validateRequiredFields, validateInputLength } from 'utils/formValidations';
import { getStateAgencyName } from 'utils/helpers';

import './FillInformation.scss';

const maxLength = 100;

const FillInformation = ({
    userId,
    updateUserCompanyInformation,
    countries,
}) => {
    const history = useHistory();

    return (
        <div className="client-wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: '',
                    country: '',
                    image: {},
                }}
                validate={(values) => {
                    return {
                        ...validateRequiredFields(values, ['name']),
                        ...validateInputLength(values, [{ property: 'name', maxLength }]),
                    }
                }}
                onSubmit={async (values) => {
                    const formData = new FormData();

                    if (values.image.file) {
                        formData.append('imageFile', values.image.file);
                        formData.append('imageInfo', JSON.stringify(values.image.info));
                    }

                    formData.append('companyData', JSON.stringify({ name: values.name, country: values.country }));

                    await updateUserCompanyInformation(userId, formData);

                    history.push(`/${getStateAgencyName()}/dashboard`);
                }}
            >
                {(props) => {
                    return (
                        <div className="client-content">
                            <FillInformationFormView {...props} countries={countries} />
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
};

const mapStatetoProps = (state) => ({
    userId: getUserId(state),
});

const mapDispatchToProps = {
    updateUserCompanyInformation,
};

export default connect(mapStatetoProps, mapDispatchToProps)(FillInformation);
