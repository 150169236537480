
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { exportAgencyTimesheets } from 'actions/agencyActions';

import TableWrapper from 'components/Shared/TableWrapper/TableWrapper';
import ExportTimeSheetDialog from '../Team/ExportTimesheetDialog/ExportTimesheetDialog';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

const TeamHistoryTable = ({
    agencyId,
    projectId,
    project,
    tableTitle,
    columns,
    defaultColumns,
    sortQuerySelector,
    pageQuerySelector,
    dataSelector,
    dataCountSelector,
    exportAgencyTimesheets,
    adjustKeysPrefix,
}) => {
    const intl = useIntl();

    //Export Timesheet
    const [employeeData, setEmployeeData] = useState({});
    const [isExportTimeSheetDialogOpen, setIsExportTimeSheetDialogOpen] = useState(false);

    const totalCount = useSelector(dataCountSelector);

    const handleExportTimesheet = async (months) => {
        const employees = [];
        const trackedProjects = [];
        const projectNames = [{ _id: projectId, name: project.name }];

        employees.push(employeeData);
        trackedProjects.push({ ...employeeData, projectNames });

        await exportAgencyTimesheets(employees, months, agencyId, { trackedProjects });
        setEmployeeData({});
    };

    const handleOpenExportTimesheetDialog = (employeeData) => {
        setIsExportTimeSheetDialogOpen(true);
        setEmployeeData({...employeeData, _id: employeeData.employeeId});
    };

    const handleCloseExportTimesheetDialog = () => {
        setIsExportTimeSheetDialogOpen(false);
        setEmployeeData({});
    };
    
    const handleClose = () => {
        handleCloseExportTimesheetDialog();
    };

    const adjustKeysFunction = employees => employees.map((employee) => ({
        _id: employee._id,
        [`${adjustKeysPrefix}Name`]: `${employee.firstName} ${employee.lastName}`,
        [`${adjustKeysPrefix}Period`]: `${moment(employee.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)} - ${moment(employee.dateDeleted).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`,
        item: employee,
    }));

    const popoverActions = [
        { title: intl.formatMessage({ id: "export-timesheet" }), handleClick: handleOpenExportTimesheetDialog },
    ];

    return (
        <TableWrapper
            title={tableTitle}
            columns={columns}
            defaultColumns={defaultColumns}
            sortQuerySelector={sortQuerySelector}
            adjustKeysFunction={adjustKeysFunction}
            isTableDataLoading={false}
            dataSelector={dataSelector}
            pageQuerySelector={pageQuerySelector}
            pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
            addMargin
            withActions
            withPagination
            getActionsHandler={() => popoverActions}
        >
            <TableWrapper.TableAlignedItems>
                <TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.TableHeader />
                </TableWrapper.TableAlignedItems.LeftAligned>
            </TableWrapper.TableAlignedItems>

            <TableWrapper.TableContent />

            <ExportTimeSheetDialog
                handleExportTimesheet={handleExportTimesheet}
                isDialogOpen={isExportTimeSheetDialogOpen}
                handleCloseDialog={handleClose}
            />
        </TableWrapper>
    );
};

const mapDispatchToProps = {
    exportAgencyTimesheets,
};

export default connect(null, mapDispatchToProps)(TeamHistoryTable);
