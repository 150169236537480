import React from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@material-ui/core';

import { ReactComponent as NothingToShowIcon } from 'assets/nothing-to-show-icon.svg';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './NothingToShow.scss';

const NothingToShow = ({
    items,
    message,
    tableView,
}) => {
    const intl = useIntl();

    const getText = () => {
        const text = intl.formatMessage({ id: "no-items-to-show" });
        const messageText = intl.formatMessage({ id: convertStringToLanguageKey(items) }).toLowerCase();

        return text.replace("{}", messageText);
    };

    return (
        <div className={`nothing-to-show-wrapper ${tableView ? 'table-view' : ''}`}>
            {!tableView && <NothingToShowIcon />}
            {message ? <Typography variant="body1">{message}</Typography> : <Typography variant="body1">{getText()}</Typography>}
        </div>
    )
};

export default NothingToShow;
