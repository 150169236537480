import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import createRootReducer from 'reducers';

import { resetEnhancer, transactions } from './storeUtils';

export const history = createBrowserHistory();

const rootReducer = createRootReducer(history);

const middlewares = [routerMiddleware(history), thunk, transactions];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    resetEnhancer(rootReducer),
    composeEnhancers(applyMiddleware(...middlewares)))
;

export default store;
export const persistor = persistStore(store);
