import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { getAgencyRouteName, getKnowYourTeamSample, getUserId } from 'reducers';
import { fetchKnowYourTeamSample } from 'actions/employeeActions';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import YourTeamCard from './KnowYourTeamCard';

import './KnowYourTeam.scss';

const KnowYourTeam = ({
    agencyId,
    employees,
    userId,
    fetchKnowYourTeamSample,
    agencyName,
}) => {
    const history = useHistory();

    const handleRefresh = () => {
        fetchKnowYourTeamSample(agencyId, userId, 2);
    };

    const handleViewAllClick = () => {
        history.push(`/${agencyName}/meet-your-team`);
    };

    return (
        <div className="know-your-team-wrapper">
            {employees && employees.length
                ? <>
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.SectionTitle
                            sectionTitle='Meet your Team'
                        />
                        <SectionActionsWrapper.LeftAlignedActions>
                            <SectionActionsWrapper.LeftAlignedActions.RefreshIconButton
                                handleRefresh={handleRefresh}
                            />
                        </SectionActionsWrapper.LeftAlignedActions>
                    </SectionActionsWrapper>

                    <div className="know-your-team-cards-wrapper">
                        {employees.map(employee => <YourTeamCard key={employee._id} employee={employee} />)}
                    </div>

                    <PrimaryButton className="view-all-btn" handleClick={handleViewAllClick} text="View All" />
                </>
                : null
            }
        </div>
    )
}

const mapStateToProps = state => ({
    employees: getKnowYourTeamSample(state),
    userId: getUserId(state),
    agencyName: getAgencyRouteName(state),
});

const mapDispatchToProps = {
    fetchKnowYourTeamSample
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowYourTeam);
