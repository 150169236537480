import React from 'react';
import moment from "moment";

import { DATE_FORMAT_WITH_DASH } from 'constants/commonConstants';

const DateWithInterviews = ({
    day,  
    isInCurrentMonth, 
    dayComponent,
    selectedDate,
    interviewsData,
}) => {
    const isWeekend = moment(day).format('E') > 5;
    const isInterviewDate = interviewsData?.find(event => moment(day).format(DATE_FORMAT_WITH_DASH) === moment(event.date).format(DATE_FORMAT_WITH_DASH)) && isInCurrentMonth;
    const isSelectedDate = moment(selectedDate).format(DATE_FORMAT_WITH_DASH) === moment(day).format(DATE_FORMAT_WITH_DASH);
    const isCurrentDay = moment().format(DATE_FORMAT_WITH_DASH) === moment(day).format(DATE_FORMAT_WITH_DASH);
    const isPastDay = (
        moment(day, DATE_FORMAT_WITH_DASH).isBefore(moment(new Date(), DATE_FORMAT_WITH_DASH)) &&
        moment(day).format(DATE_FORMAT_WITH_DASH) !== moment(new Date()).format(DATE_FORMAT_WITH_DASH)
    );

    const createClassesString = () => {
        if(isWeekend && !dayComponent.props.disabled) {
            const classedString =  `
                MuiPickersDay-day
                holidays-and-weekends
                ${isPastDay || !isInCurrentMonth ? 'jobs-calendar-past-day' : ''}
                ${isCurrentDay ? 'jobs-calendar-current-day' : ''}
                ${isSelectedDate ? 'MuiPickersDay-daySelected' : ''}
                ${isInterviewDate ? 'interview-date-box' : ''}
            `;

            return classedString.trim();
        } else {
            const classedString = `
                MuiPickersDay-day 
                ${isPastDay || !isInCurrentMonth ? 'jobs-calendar-past-day' : ''}
                ${isCurrentDay ? 'jobs-calendar-current-day' : ''}
                ${isSelectedDate ? 'MuiPickersDay-daySelected' : ''}
                ${isInterviewDate ? 'interview-date-box' : ''}
            `;

            return classedString.trim();
        }
    }

    return React.cloneElement(dayComponent, { className: createClassesString()});
}

export default DateWithInterviews;
