import React from 'react';

import { Paper, Typography } from '@material-ui/core';

import { getStateAgencyName } from 'utils/helpers';

import './TrainingCard.scss';

const TrainingCard = ({
    training,
    history,
}) => (
    <Paper className="training-card-wrapper" onClick={() => history.push(`/${getStateAgencyName()}/trainings/${training._id}`)}>
        <div className="training-card-content">
            <Typography variant="h4" className="title">{training.title}</Typography>
            <p className="description">{training.description}</p>
            <hr/>
        </div>
        <div className="training-card-footer">
            <p className="lessons-info">Lessons <span className="lessons-count">{training.topics?.length || 0}</span></p>
        </div>
    </Paper>
);

export default TrainingCard;
