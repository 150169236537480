import moment from 'moment';

import { EMPLOYEE_PROJECTS_FETCH_SUCCESS, EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS } from 'actions/actionTypes';

const employeeProjects = (state = [], action) => {
    switch (action.type) {
        case EMPLOYEE_PROJECTS_FETCH_SUCCESS:
            return action.payload ? [...action.payload] : [];
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return [];
        default:
            return state;
    }
};

export default employeeProjects;

export const getEmployeeProjects = (state, onlyCurrent = false) => state.filter(x => !onlyCurrent || moment.utc().diff(x.endDate) > 0).map(x => ({ _id: x._id, name: x.name, lastActiveDate: x.lastActiveDate, startDate: x.startDate }));

export const getEmployeeProjectsWithClientInfo = (state, onlyCurrent = false) => (
    state.filter(x => !onlyCurrent || moment.utc().diff(x.endDate) > 0)
        .map(x => ({
            // clients will always come as one item array
            // since it is matched by clientId
            client: x.client || { name: '' },
            _id: x._id,
            agencyId: x.agencyId,
            name: x.name,
            clientId: x.clientId,
            startDate: x.startDate,
            endDate: x.endDate,
            ongoing: x.ongoing,
            employees: x.employees,
            dedicatedEmployees: x.dedicatedEmployees,
        }))
)


export const getEmployeeProject = (state, projectId) => {
    const project = state.find((x) => x._id === projectId);
    return project || {};
};
