import React from 'react';

import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { ErrorMessage } from 'formik';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import './AddVideoFormView.scss';

const AddVideoFormView = ({
    values,
    handleChange,
    errors,
    touched,
    handleCloseDialog,
    handleSubmit,
    title
}) => (
    <form className="add-video-form" autoComplete="off">
    <div>
        <TextField
            required
            className="add-video-field"
            name="video"
            value={values.video}
            onChange={handleChange}
            label={title[0].toUpperCase() + title.substring(1)}
            error={errors.video && touched.video}
            margin="normal"
            variant="outlined"
        />
        <ErrorMessage name="video" component="div" className="invalid-field-message" />
    </div>
    <DialogActions className="dialog-actions">
        <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
        <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Save" type="submit" />
    </DialogActions>
</form>
    );

export default AddVideoFormView;
