import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation, useParams, useHistory, Link as RouterLink } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import ThankYouCard from './ThankYouCard';

import {
    MOTION_SOFTWARE_AGENCY_NAME,
    MOTION_SOFTWARE_WEBSITE_URL,
    ENVIRONMENTS
} from 'constants/env';
import { ROLES } from 'constants/userConstants';

import './ThankYouPage.scss';

const ThankYouPage = () => {
    const location = useLocation();
    const { role } = useParams();
    const { email, agencyName, gotoUrl } = location?.state || {};

    const history = useHistory();
    const isRoleValid = Object.values(ROLES).includes(role);

    const pathname = '/login';

    useEffect(() => {
        ReactGA.pageview(location.pathname);
        if (!email || !agencyName || !isRoleValid) {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    return (
        <ThankYouCard
            email={email}
            role={role}
            agencyName={agencyName}>
            <div className="thank-you-buttons">
                <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={{ pathname: pathname, state: { gotoUrl } }}
                >
                    Login
                </Button>
                {
                    (
                        process.env.NODE_ENV === ENVIRONMENTS.PRODUCTION &&
                        agencyName === MOTION_SOFTWARE_AGENCY_NAME
                    )
                        ?
                        <Button variant="text" color="primary" href={MOTION_SOFTWARE_WEBSITE_URL}>
                            Back to Motion Software
                        </Button>
                        :
                        null
                }
            </div>
        </ThankYouCard>
    );
};

export default ThankYouPage;
