import React, { Fragment } from 'react';
import { Formik } from 'formik';

import Dialog from 'components/Shared/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AddVideoFormView from './AddVideoFormView';

import { validateRequiredFields, validateInputLength, validateVideoURLAddress } from 'utils/formValidations';

import './AddVideo.scss';

const AddVideo = ({
    selectedVideo,
    openDialog,
    handleCloseDialog,
    handleVideoSubmit,
    title
}) => (
    <Formik
        initialValues={{
            video: selectedVideo || '',
        }}

        validate={values => {
            return {
                ...validateRequiredFields(values, ['video']),
                ...validateInputLength(values, [
                    { property: 'video' },
                ]),
                ...validateVideoURLAddress(values, 'video'),
            }
        }}

        onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            const { video } = values;
            const submitedVideo = { video };
            handleVideoSubmit(submitedVideo);
            handleCloseDialog();
        }}
    >
        {(props) =>
            <Dialog className="add-video-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
                <MuiDialogTitle disableTypography className="dialog-title">
                    <Fragment>
                        <Typography variant="body2" className="dialog-title-text">Add new Video</Typography>
                        <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </Fragment>
                </MuiDialogTitle>

                <DialogContent className="dialog-content">
                    <AddVideoFormView {...props} handleCloseDialog={handleCloseDialog} title={title} />
                </DialogContent>
            </Dialog>
        }
    </Formik>
);

export default AddVideo;
