import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
    getAdminDashboardEmployeesByCountry,
    getAdminDashboardEmployeesByUtilisation
} from 'reducers';

import Section from 'components/Shared/Section';
import PieChart from 'components/Shared/PieChart';

import { EMPLOYEE_OCCUPATION_STATUSES_MAP } from 'constants/employeeConstants';
import { EMPLOYEES_LOCATIONS_COLORS, EMPLOYEES_UTILISATION_COLORS } from './TeamStatisticsConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './TeamStatistics.scss';

const TeamStatistics = ({
    employeesByCountry,
    employeesByUtilisation,
}) => {
    const intl = useIntl();

    return (
        <div className="team-statistics-container">
            <Section title={intl.formatMessage({ id: "team-stats-section-title" })}>
                <div className="team-statistics-charts-wrapper">
                    <PieChart
                        title={intl.formatMessage({ id: "employee-locations-text" })}
                        data={employeesByCountry.map((x, index) => ({
                            name: intl.formatMessage({ id: convertStringToLanguageKey(x.country) }),
                            y: x.numberOfEmployees,
                            color: EMPLOYEES_LOCATIONS_COLORS.find(y => y.text === x.country)?.color || EMPLOYEES_LOCATIONS_COLORS[index].color
                        }
                        ))}
                    />
                    <PieChart
                        title={intl.formatMessage({ id: "utilization-text" })}
                        data={employeesByUtilisation.map(x => ({
                            name: intl.formatMessage({ id: convertStringToLanguageKey(EMPLOYEE_OCCUPATION_STATUSES_MAP[x.status]) }),
                            y: x.numberOfEmployees,
                            color: EMPLOYEES_UTILISATION_COLORS[x.status]
                        }))}
                    />
                </div>
            </Section>
        </div>
    );
};

const mapStateToProps = state => ({
    employeesByCountry: getAdminDashboardEmployeesByCountry(state),
    employeesByUtilisation: getAdminDashboardEmployeesByUtilisation(state),
});

export default connect(mapStateToProps)(TeamStatistics);
