import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import HolidayRequestFormView from './HolidayRequestFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { ARTICLE_NUMBERS, MAX_LENGTH } from './HolidayRequestDialogConstants';
import { ROLES } from 'constants/userConstants';

import { addUtcOffset } from 'utils/formatTimes';

import {
    validateRequiredFields,
    validateInputLength,
    validateDateFields,
    validateDateNotInPast,
    validateEndDateNotBeforeStartDate,
    validateStartDateNotBeforeEndDate,
} from 'utils/formValidations';

import './HolidayRequestDialog.scss';

const HolidayRequestDialog = ({
    openDialog,
    handleCloseDialog,
    handleHolidayRequest,
    displayMode,
    employmentType,
    isDepartmentView,
}) => {
    const intl = useIntl();

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                initialValues={{
                    startDate: null,
                    endDate: null,
                    type: '',
                    byArticle: '',
                    reason: '',
                }}

                validate={values => {
                    return {
                        ...validateRequiredFields(
                            values,
                            values.type === 'Paid'
                                ? ['startDate', 'endDate', 'type', 'byArticle']
                                : ['startDate', 'endDate', 'type']
                        ),
                        ...validateInputLength(values, [{ property: 'reason', maxLength: MAX_LENGTH }]),
                        ...validateDateFields(values, ['startDate', 'endDate']),
                        ...validateDateNotInPast(values, (displayMode === ROLES.ADMIN || isDepartmentView) ? [] : ['startDate']),
                        ...validateEndDateNotBeforeStartDate(values, 'startDate', 'endDate'),
                        ...validateStartDateNotBeforeEndDate(values, 'startDate', 'endDate', false),
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    let { byArticle } = values;
                    const { type, reason, startDate, endDate } = values;
                    let description = '';

                    if (type === 'Paid') {
                        const articleNumberRegex = /[0-9]+/;
                        const article = articleNumberRegex.exec(byArticle)[0];

                        if (type === 'Paid' && (+article === ARTICLE_NUMBERS.FUNERAL_WEDDING_OR_BLOOD_DONATION || +article === ARTICLE_NUMBERS.PREGNANCY_AND_CHILDBIRTH || +article === ARTICLE_NUMBERS.RAISING_YOUNG_CHILD)) {
                            const descriptionRegexParentheses = /\((.*?)\)/;

                            // If it has parentheses it will take the content inside the parentheses, otherwise it will take everything after Article N
                            description = descriptionRegexParentheses.exec(byArticle) ? descriptionRegexParentheses.exec(byArticle)[1] : byArticle.split(' - ')[1];
                        }

                        byArticle = article;
                    }

                    const request = { startDate: addUtcOffset(startDate), endDate: addUtcOffset(endDate), type, byArticle, reason, description };
                    handleHolidayRequest(request);
                    handleCloseDialog();
                }}
            >
                {(props) =>
                    <CommonFormikDialog
                        className="request-holiday-dialog"
                        open={openDialog}
                        onClose={handleCloseDialog}
                        maxWidth={'sm'}
                        fullWidth={true}
                        onSave={props.handleSubmit}
                        dialogTitle={intl.formatMessage({ id: "new-holiday-request" })}
                    >
                        <HolidayRequestFormView {...props}
                            displayMode={displayMode}
                            employmentType={employmentType}
                            isDepartmentView={isDepartmentView}
                        />
                    </CommonFormikDialog>
                }
            </Formik>
        </MuiPickersUtilsProvider>
    )
};

export default HolidayRequestDialog;
