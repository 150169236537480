import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import './SortableSkillList.scss';

const SortableSkillItem = SortableElement(({
    item,
    removeItem,
}) => (
    <div className="global-css-skill-box">
        <span className="skill-name">{item.name}</span>
        <button className="delete-skill-icon" type="button" onClick={removeItem}></button>
    </div>
));

const SortableSkillList = SortableContainer(({ items, onRemove }) => (
    <div className="skills-wrapper">
        <div className="skills-container">
            {items.map((value, index) => {
                return (
                    <SortableSkillItem
                        key={`item-${index}`}
                        index={index}
                        removeItem={() => onRemove(index)}
                        item={value}
                    />
                )
            })}
        </div>
    </div>
));

export default SortableSkillList;
