import React from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';
import moment from 'moment';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import EducationDialogView from '../FormViews/EducationDialogView';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import { addUtcOffset } from 'utils/formatTimes';
import {
    validateRequiredFields,
    validateDateFields,
    validateEndDateNotBeforeStartDate,
    validateStartDateNotBeforeEndDate,
} from 'utils/formValidations';

import './EducationDialog.scss';

const EducationDialog = ({
    open,
    onClose,
    onSubmit,
    selectedItem,
}) => {
    const intl = useIntl();

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize
                initialValues={{
                    title: selectedItem?.title || '',
                    institution: selectedItem?.institution || '',
                    currentlyStudy: selectedItem?.currentlyStudy || false,
                    datesUnknown: selectedItem?.datesUnknown || false,
                    startDate: selectedItem?.startDate ? moment.utc(selectedItem.startDate) : null,
                    endDate: selectedItem?.endDate ? moment.utc(selectedItem.endDate) : null,
                }}

                validate={values => {
                    let validateDates = values.datesUnknown
                        ? {}
                        : { ...validateDateFields(values, ['startDate', 'endDate']), };

                    if (values.startDate && values.endDate) {
                        validateDates = {
                            ...validateDates,
                            ...validateEndDateNotBeforeStartDate(values, 'startDate', 'endDate'),
                            ...validateStartDateNotBeforeEndDate(values, 'startDate', 'endDate', false)
                        }
                    }

                    return {
                        ...validateRequiredFields(values, ['title']),
                        ...validateDates
                    }
                }}
                onSubmit={(values) => {
                    const { title, institution, currentlyStudy, datesUnknown, startDate, endDate } = values;

                    const entry = {
                        title,
                        institution,
                        currentlyStudy,
                        datesUnknown,
                        startDate: datesUnknown ? '' : addUtcOffset(startDate),
                        endDate: datesUnknown || currentlyStudy ? '' : addUtcOffset(endDate),
                    };

                    selectedItem
                        ? onSubmit({ ...selectedItem, ...entry }, selectedItem?.index)
                        : onSubmit(entry);

                    onClose();
                }}
            >
                {(props) => (
                    <CommonFormikDialog
                        size="sm"
                        className="education-dialog"
                        dialogTitle={intl.formatMessage({ id: "add-education" })}
                        saveBtnText="save"
                        discardBtnText="discard"
                        open={open}
                        onClose={onClose}
                        onSave={props.handleSubmit}
                    >
                        <EducationDialogView {...props} />
                    </CommonFormikDialog>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

export default EducationDialog;
