import {
    INITIAL_CANDIDATES_CHECKLIST_FILTERS,
    CANDIDATES_CHECKLIST_STATUS_FILTER_VALUES
} from './CandidatesChecklistConstants';

export const mapQueryToFilters = (queryObj) => {
    const initFilters = { ...INITIAL_CANDIDATES_CHECKLIST_FILTERS };

    const newFilters = Object.keys(queryObj).reduce((acc, x) => {
        let foundItem;

        switch (x) {
            case 'status':
                foundItem = Object.values(CANDIDATES_CHECKLIST_STATUS_FILTER_VALUES).find((y) => y === queryObj[x]);
                acc.status = foundItem ? foundItem : acc.status;
                break;
            case 'searchTerm':
                foundItem = queryObj[x];
                acc.searchTerm = foundItem ? foundItem : acc.searchTerm;
                break;
            default:
                break;
        }
        return acc;
    }, initFilters);

    return newFilters;
};
