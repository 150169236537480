import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { SearchContext } from '../Search';

const SearchBar = () => {
    const intl = useIntl();

    const {
        searchTerm,
        handleFocus,
        handleBlur,
        handleSearchTermChange,
        setInputBaseRef,
        isMobileHeaderSearch,
    } = useContext(SearchContext)

    let inputRef = {};

    return (
        <div className='search-bar'>
            <InputBase
                id='forward-request-search-field'
                className="search-field"
                placeholder={intl.formatMessage({ id: "search" })}
                autoComplete="off"
                value={searchTerm}
                onChange={handleSearchTermChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                autoFocus={isMobileHeaderSearch}
                ref={x => {
                    if(x) {
                        setInputBaseRef(x);
                        if (x.children) {
                            // x.children[1] is the input element wrapped in the parent div
                            inputRef = x.children[1];
                        }
                    }
                }}
                startAdornment={(
                    <InputAdornment position="start">
                        <SearchIcon
                            className="search-icon icon"
                            onClick={_ => handleSearchTermChange({ target: inputRef })} />
                    </InputAdornment>
                )}
            />
        </div>
    )
};

const forwaredRef = React.forwardRef(SearchBar);

export default forwaredRef;
